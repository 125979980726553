import { REPORT_LOADED_FILES_URL } from "../../api/axios";
import { transformColumnDate } from "../taskManager/utils";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";
import { useApiQuery } from "../../hooks/useApiQuery";
import SimpleTable from "../tablesTools/SimpleTable";
import Stack from "@mui/material/Stack";
import useLanguage from "../../context/LanguageProvider";

const TasksModal = ({hasUnprocessedFiles}) => {
  const { t } = useLanguage();

  const {
    data: reportLoadedFiles,
  } = useApiQuery(
    `${REPORT_LOADED_FILES_URL}`,
    true,
    t("dataManagement.getRowsFiles.error"),
    hasUnprocessedFiles ? 5000 : undefined
  );

  return (
    <Stack width={"1200px"}>
      <SimpleTable
        columns={
          transformDataSimpleTable(reportLoadedFiles, undefined, undefined, t).columns
        }
        rows={
          transformDataSimpleTable(
            transformColumnDate(reportLoadedFiles),
            undefined,
            undefined,
            t
          ).rows
        }
        toolbar={true}
        exportButton={true}
        filterButton={true}
        rowHeight={40}
        height={"480px"}
      />
    </Stack>
  );
};

export default TasksModal;
