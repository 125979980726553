import React from 'react'
import useLanguage from '../../../../../../context/LanguageProvider';
import { Divider, Stack, Typography } from '@mui/material';
import Dialog from '../../../../../dialog/Dialog';
import { roundNumber } from '../../../../utils';

export interface ViewInfoRouteModal {
    config: any,
    open: boolean,
    handleClose: () => void,
    setConfig: any,
}

const ViewInfoRegisterRouteModal = ({
    config,
    open,
    handleClose,
    setConfig
}: ViewInfoRouteModal) => {

    const { t } = useLanguage();

    const closeAndClean = () => {
        handleClose()
        setConfig()
    }

    return (
        <Dialog
            open={open}
            handleClose={closeAndClean}
            title={t("deltaTexts.simulation.viewInfoModal.routeConfig")}
            maxWidth={"xl"}
        >
            {/* <SimpleBackdrop
        open={isLoadingGetAnalysisById}
        message={t("simulatorTexts.registerRoutes.registerRoutesAdmin.loading")}
    /> */}
            <Stack
                padding="0 50px 15px 50px"
                sx={{
                    minHeight: "300px",
                    minWidth: "400px",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    pb: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        textAlign: "left",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "-0.5px",
                        pt: 1,
                        pb: 1
                    }}
                    color="var(--text-main)"
                >
                    <span style={{ color: "var(--text-disabled)" }}>
                    {t("deltaTexts.simulation.viewInfoModal.configType")}: {" "}
                    </span>
                    {t("deltaTexts.simulation.viewInfoModal.registeredRoute")}
                </Typography>
                <Divider sx={{ margin: "5px 0", width: "100%" }} />
                <Typography
                    variant="subtitle1"
                    sx={{
                        width: "100%",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "-0.5px",
                        wordBreak: "break-word",
                        textDecoration: "underline",
                        pt: 1,
                        pb: 1
                    }}
                    color="var(--text-main)"
                >
                    {t("deltaTexts.simulation.viewInfoModal.filters")}
                </Typography>
                {config.filtros && config.filtros.requeridos.concat(config.filtros.adicionales).map((filtro, idx) => {
                    return (
                        <Typography
                            key={idx}
                            variant="subtitle1"
                            sx={{
                                width: "100%",
                                textAlign: "left",
                                textTransform: "uppercase",
                                fontWeight: "700",
                                letterSpacing: "-0.5px",
                                wordBreak: "break-word",
                            }}
                            color="var(--text-main)"
                        >
                            {filtro?.columna}: {" "}
                            <span style={{ color: "var(--text-disabled)", wordBreak: "break-all" }}>
                                {filtro?.valor}
                            </span>
                        </Typography>
                    )
                })}
                <Divider sx={{ margin: "5px 0", width: "100%" }} />
                <Typography
                    variant="subtitle1"
                    sx={{
                        textAlign: "left",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "-0.5px",
                        pt: 1,
                        pb: 1
                    }}
                    color="var(--text-main)"
                >
                    <span style={{ color: "var(--text-disabled)" }}>
                    {t("deltaTexts.simulation.viewInfoModal.projectionMode")}: {" "}
                    </span>
                    {config.modalidad_proyeccion === 1 ? t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.averageMonthlyVolumePerClient") : t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.totalRoute")}
                </Typography>
                <Divider sx={{ margin: "5px 0", width: "100%" }} />
                <Typography
                    variant="subtitle1"
                    sx={{
                        width: "100%",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "-0.5px",
                        wordBreak: "break-word",
                        textDecoration: "underline"
                    }}
                    color="var(--text-main)"
                >
                    {t("deltaTexts.simulation.viewInfoModal.params")}
                </Typography>
                {config.parametros && config.parametros.map((param, idx) => {
                    if (param.valor_ajustado !== null) {
                        return (
                            <Typography
                                key={idx}
                                variant="subtitle1"
                                sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: "700",
                                    letterSpacing: "-0.5px",
                                    wordBreak: "break-word",
                                }}
                                color="var(--text-main)"
                            >
                                {param.clave.nombre}: {" "}
                                <span style={{ color: "var(--text-disabled)", wordBreak: "break-all" }}>
                                    {roundNumber(param?.valor_ajustado, 2)}
                                </span>
                            </Typography>
                        )
                    }
                })}
                <Divider sx={{ margin: "5px 0", width: "100%", pt: 1 }} />
                <Typography
                    variant="subtitle1"
                    sx={{
                        textAlign: "left",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "-0.5px",
                        pt: 1,
                        pb: 1
                    }}
                    color="var(--text-main)"
                >
                    <span style={{ color: "var(--text-disabled)" }}>
                    {t("deltaTexts.simulation.viewInfoModal.routesToAdd")}: {" "}
                    </span>
                    {config.cantidad}
                </Typography>
            </Stack>
        </Dialog>
    )
}

export default ViewInfoRegisterRouteModal