import { card_title_container_styles } from "../../styles/app-styles";
import { useState } from "react";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";

interface KpiProps {
  kpi: string;
  lista_periodos_error?: any[];
}

const KpiError = ({ kpi, lista_periodos_error }: KpiProps) => {
  const { t } = useLanguage();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <Stack sx={{ padding: "4px 8px 4px 8px", width:"100%" }} alignItems="center">
      <Box sx={card_title_container_styles}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <CancelIcon
            sx={{ color: "var(--red)", fontSize: "26px", marginRight: "8px" }}
          />
          <Typography
            variant="h5"
            sx={{
              color: "var(--red)",
              fontWeight: "750",
              textTransform: "uppercase",
              fontSize: "1rem",
            }}
          >
            {t("reportTexts.kpiError.incorrectCalculation")}
          </Typography>
        </Stack>
      </Box>
      {!openDetail ? (
            <ExpandMoreIcon
              sx={{
                color: "var(--red)",
                fontSize: "28px",
                marginLeft: "2px",
                cursor: "pointer",
              }}
              onClick={() => setOpenDetail(true)}
            />
          ) : (
            <ExpandLessIcon
              sx={{
                color: "var(--red)",
                fontSize: "28px",
                marginLeft: "2px",
                cursor: "pointer",
              }}
              onClick={() => setOpenDetail(false)}
            />
          )}
      <Stack
        sx={{ height: openDetail ? "150px" : "0px", transition: "all 1s", width:"95%", alignItems:"center", overflowY: openDetail ? "scroll" : "hidden"}}
      >
        {openDetail &&
          lista_periodos_error?.length &&
          lista_periodos_error.map((periodo: any) => (
            <Typography
            key={periodo.periodo}
              variant="subtitle1"
              sx={{
                textAlign: "center",
                transition: "all 2s",
                width:"85%",
              }}
              color="var(--violet)"
            >
              {periodo.periodo+": "+periodo.motivo}
            </Typography>
          ))}
      </Stack>
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "left",
          textTransform: "capitalize",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
        color="var(--violet)"
      >
        {kpi}
      </Typography>
    </Stack>
  );
};

export default KpiError;
