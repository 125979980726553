import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EditCriteriaButton from "../../buttons/EditCriteriaButton";
import Dialog from "../../dialog/Dialog";
import { Company, User } from "../types";
import useLanguage from "../../../context/LanguageProvider";
import { info_section_container_styles } from "../../../styles/app-styles";

interface ViewInfoProps {
  user: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  title: string | undefined;
}

const ViewInfoModal = ({
  user,
  open,
  handleClose,
  handleEdit,
  title,
}: ViewInfoProps) => {
  const handleEditAndClose = (e: any, id: number) => {
    handleEdit && handleEdit(e, id);
    handleClose();
  };

  const { t } = useLanguage();

  return (
    <div>
      <Dialog open={open} handleClose={handleClose} title={title}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding="0 100px 30px 100px"
          position="relative"
        >
          <Stack sx={info_section_container_styles}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
              }}
            >
              {t("administratorPanel.users.userInformation")}
            </Typography>

            {user?.nombre && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("administratorPanel.users.name")}
                </span>{" "}
                {user?.nombre}
              </p>
            )}
            {user?.apellido && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("administratorPanel.users.lastName")}
                </span>{" "}
                {user?.apellido}
              </p>
            )}
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}> {t("administratorPanel.users.email")}: </span>{" "}
              {user?.email}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}> {t("administratorPanel.users.rol")}:</span> {user?.rol.nombre}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}> {t("administratorPanel.users.active")}: </span>{" "}
              {user?.is_active ? "Si" : "No"}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
              {t("administratorPanel.users.userEnabledCompanies")}:
              </span>{" "}
            </p>
            <ul
              style={{ marginRight: "30px", marginTop: "0", marginBottom: "0" }}
            >
              {user?.empresas_habilitadas.map((company: Company) => {
                return <li key={company.id}>{company.nombre_visual}</li>;
              })}
            </ul>
          </Stack>
          <EditCriteriaButton
            handleClick={handleEditAndClose}
            selectedCriteria={user}
          />
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoModal;
