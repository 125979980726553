import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { renderCellExchangeTable } from "../../utils";
import Stack from "@mui/material/Stack";
interface ITransformDataExchangeTable {
  (t: any, data: any, exchangeColumns: string[]): {
    columns: GridColDef<any, any, any>[];
    rows: any;
  };
}

export const transformDataExchangeTable: ITransformDataExchangeTable = (
  t,
  data = [],
  exchangeColumns
) => {
  const tableColumns = exchangeColumns

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      editable: columna === "PERIODO" ? false : true,
      headerName: columna,
      description: columna,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {columna}
        </strong>
      ),
      renderCell: (params: any) =>
        renderCellExchangeTable(
          params,
          Stack
        ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
