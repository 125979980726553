import Stack from "@mui/material/Stack";
import Dialog from "./Dialog";
import ConfirmationModal from "./ConfirmationModal";
import Button from "../buttons/Button";
interface BaseModalProps {
  children: React.ReactNode;
  open: boolean;
  title: string;
  cleanAndClose: () => void;
  openConfirmationModal: boolean;
  toggleConfirmationModal: () => void;
  handleAccept?: () => void;
  disabledAcceptButton: boolean;
  confirmationModalMessage: string;
  width?: string;
  height?: string;
}

const BaseModal = ({
  children,
  open,
  title,
  cleanAndClose,
  openConfirmationModal,
  toggleConfirmationModal,
  handleAccept,
  disabledAcceptButton,
  confirmationModalMessage,
  width,
  height
}: BaseModalProps) => {
  return (
    <Dialog open={open} handleClose={cleanAndClose} title={title} maxWidth="xl">
      <Stack
        sx={{
          width: width ? width : "730px",
          height: height ? height : "500px",
          marginTop:"0px"      
        }}
      >
        {children}
      </Stack>
      <Stack direction="row" sx={{ alignSelf: "center", marginTop: "auto", justifyContent: "center" }}>
        {handleAccept && <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={handleAccept}
          title={title}
          message={confirmationModalMessage}
        />}
        <Button
          title="Cancelar"
          type="button"
          onClick={cleanAndClose}
          color="light-grey"
        />
        {handleAccept && <Button
          title="Aceptar"
          type="button"
          onClick={toggleConfirmationModal}
          disabled={disabledAcceptButton}
          color="blue-greeny"
        />}
      </Stack>
    </Dialog>
  );
};

export default BaseModal;
