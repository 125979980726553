import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import FormLayout from "../../../forms/FormLayout";
import MessageBlock from "../../../forms/MessageBlock";
import OtherFilesInputs from "./OtherFilesInputs";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles2,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step4 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    isLoadingPostExpensesParams,
    setFileColumns,
    fileColumns,
    dataGetOtherFilesParams,
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetOtherFilesParams) {
      setFileColumns(
        dataGetOtherFilesParams.map((item) => {
          return {
            nombre: item.nombre,
            descripcion: {
              value: item.descripcion,
              label: item?.descripcion || "-",
            },
            //importacion: item.importacion || false
          };
        })
      );
    }
  }, [dataGetOtherFilesParams]);

  /************************************* Validación step completo **********************************/

  const isStep4Complete = () => {
    return true;
  };

  useEffect(() => {
    if (isStep4Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step4: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step4: false };
      });
    }
  }, []);

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[4]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles2}>
        <FormLayout width="90%">
          <OtherFilesInputs
            fileColumns={fileColumns}
            setFileColumns={setFileColumns}
          />
        </FormLayout>
      </Stack>
    </Stack>
  );
};

export default Step4;
