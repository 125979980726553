import React from 'react'
import useLanguage from '../../../../../../context/LanguageProvider';
import { Divider, Stack, Typography } from '@mui/material';
import Dialog from '../../../../../dialog/Dialog';
import CanceledRouteInfoModalTable from './CanceledRouteInfoModalTable';

interface ViewInfoRouteModal {
  config: any,
  open: boolean,
  handleClose: () => void,
  informationsColumns: any,
  setConfig: any
}

const ViewInfoCancelRouteModal = ({
  config,
  open,
  handleClose,
  informationsColumns,
  setConfig
}: ViewInfoRouteModal) => {

  const { t } = useLanguage();

  const closeAndClean = () => {
    handleClose()
    setConfig()
}
  
  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      title={t("deltaTexts.simulation.viewInfoModal.routeConfig")}
      maxWidth={"xl"}
    >
      {/* <SimpleBackdrop
open={isLoadingGetAnalysisById}
message={t("simulatorTexts.registerRoutes.registerRoutesAdmin.loading")}
/> */}
      <Stack
        padding="0 50px 15px 50px"
        sx={{
          minHeight: "300px",
          minWidth: "400px",
          width: "100%",
          justifyContent: "center",
          alignItems: "flex-start",
          pb: 1
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
            pt: 1,
            pb: 1
          }}
          color="var(--text-main)"
        >
          <span style={{ color: "var(--text-disabled)" }}>
            {t("deltaTexts.simulation.viewInfoModal.configType")}: {" "}
          </span>
          {t("deltaTexts.simulation.viewInfoModal.cancelRoute")}
        </Typography>
        <Divider sx={{ margin: "5px 0", width: "100%" }} />
        {config && config.rutas && config.rutas.length > 0 ?
          <CanceledRouteInfoModalTable informationsColumns={informationsColumns} data={[config]} width={"850px"} />
          :
          <Typography
            sx={{ fontWeight: "700", color: "var(--text-info)", fontSize: "15px", textAlign: "center" }}
          >
            {t("simulatorTexts.registerRoutes.configRegisterRoute.noCancelationConfigYet")}
          </Typography>
        }
      </Stack>
    </Dialog>
  )
}

export default ViewInfoCancelRouteModal