import Typography from "@mui/material/Typography";
import Dialog from "../../../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import WhiteShadowBox from "../../../../layout/WhiteShadowBox";
import { tranformArrayWithNombreToString } from "../../../utils";
import CustomizedTable from "../../../../customizedTable/CustomizedTable";
import useLanguage from "../../../../../context/LanguageProvider";
import { info_section_container_styles } from "../../../../../styles/app-styles";

interface ViewInfoProps {
  info: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  title: string | undefined;
  isModelPageModal?: boolean;
}

const ViewInfoSalesModal = ({
  info,
  open,
  handleClose,
  title,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const COLUMNS1 = ["Columna", "Operación"];
  const ROWS1 = info?.columnas_agregacion?.map((item: any) => {
    return {
      Columna: item.columna.nombre,
      Operación: item.operacion,
    };
  });

  return (
    <div>
      <Dialog open={open} handleClose={handleClose} title={t('dataModelText.measureManagement.viewInfoCompoundsModal.generalInfoMeasure')}>
        <Stack
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          padding="0 100px 30px 100px"
          position="relative"
        >
          <WhiteShadowBox>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
                textAlign: "center",
                width: "100%",
              }}
            >
              {title}
            </Typography>
            {info?.archivo?.nombre ? (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t('dataModelText.measureManagement.viewInfoSalesModal.file')}
                </span>{" "}
                {info?.archivo?.nombre}
              </p>
            ) : (
              <></>
            )}
            {info?.columnas_base_actual ? (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t('dataModelText.measureManagement.viewInfoSalesModal.currentBaseColumns')}{" "}
                </span>{" "}
                {tranformArrayWithNombreToString(info?.columnas_base_actual)}
              </p>
            ) : (
              <></>
            )}
            {info?.columnas_base_ventas ? (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t('dataModelText.measureManagement.viewInfoSalesModal.baseColumnsSales')}{" "}
                </span>{" "}
                {tranformArrayWithNombreToString(info?.columnas_base_ventas)}
              </p>
            ) : (
              <></>
            )}
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t('dataModelText.measureManagement.viewInfoSalesModal.operation')}
              </span>{" "}
              {info?.operacion}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t('dataModelText.measureManagement.viewInfoSalesModal.columns')}
              </span>{" "}
              {tranformArrayWithNombreToString(info?.columnas)}
            </p>
            {info?.columnas_denominador &&
              info?.columnas_denominador.length > 0 ? (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                {t('dataModelText.measureManagement.viewInfoSalesModal.denominatorColumns')}{" "}
                </span>{" "}
                {tranformArrayWithNombreToString(info?.columnas_denominador)}
              </p>
            ) : (
              <></>
            )}
          </WhiteShadowBox>

          {ROWS1?.length && (
            <Stack sx={info_section_container_styles}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  textTransform: "uppercase",
                }}
              >
                {t('dataModelText.measureManagement.viewInfoSalesModal.groupers')}
              </Typography>
              <CustomizedTable COLUMNS={COLUMNS1} ROWS={ROWS1} width={450} />
            </Stack>
          )}
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoSalesModal;
