import { add_button_styles } from "../../styles/app-styles";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@mui/icons-material/Add';
interface AddButtonProps {
  onClick?: (() => void) | ((e: any) => void) | undefined;
  disabled?: boolean;
  align?: boolean;
}

const AddButton = ({ onClick, disabled, align }: AddButtonProps) => {
const useStyles = makeStyles({
  button: add_button_styles(
    disabled, align
  ),
});

const classes = useStyles();

  return (
    <button type="button" onClick={onClick} disabled={disabled} className={classes.button}>
      <AddIcon />
    </button>
  );
};

export default AddButton;
