import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import ColorTabs from "../colorTabs/ColorTabs";
import { Tab, TabValue } from "../colorTabs/types";
import ProrrationReportTab from "./ProrrationReportTab/ProrrationReportTab";
import GraphicsTab from "./GraphicsTab/GraphicsTab";
import PreprorrationReportTab from "./PreprorrationReportTab/PreprorrationReportTab";
import TableChartIcon from "@mui/icons-material/TableChart";
import ComponentLayout from "../layout/ComponentLayout";
import useLanguage from "../../context/LanguageProvider";

const CalculationReport = () => {
  const { t, language } = useLanguage();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("reportTexts.calculationReport.tabs.graphics")
  );
  const tabs: Tab[] = [
    {
      value: t("reportTexts.calculationReport.tabs.graphics"),
      label: t("reportTexts.calculationReport.tabs.graphics"),
    },
    {
      value: t("reportTexts.calculationReport.tabs.approrateReport"),
      label: t("reportTexts.calculationReport.tabs.approrateReport"),
    },
    {
      value: t("reportTexts.calculationReport.tabs.preProrateReport"),
      label: t("reportTexts.calculationReport.tabs.preProrateReport"),
    },
  ];

  useEffect(()=>{
    setTabValue(t("reportTexts.calculationReport.tabs.graphics"))
  },[language])

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  return (
    <ComponentLayout
      title={t("reportTexts.calculationReport.calculationReport")}
      icon={<TableChartIcon />}
    >
      <Stack
        spacing={2.5}
        sx={{ width: "100%", alignItems: "center", marginBottom: "50px" }}
      >
        <ColorTabs
          value={tabValue}
          handleChange={handleChange}
          tabs={tabs}
          marginBottom="0px"
        />
        {tabValue === t("reportTexts.calculationReport.tabs.graphics") && (
          <GraphicsTab />
        )}
        {tabValue === t("reportTexts.calculationReport.tabs.approrateReport") && (
          <ProrrationReportTab />
        )}
        {tabValue === t("reportTexts.calculationReport.tabs.preProrateReport") && (
          <PreprorrationReportTab />
        )}
      </Stack>
    </ComponentLayout>
  );
};

export default CalculationReport;
