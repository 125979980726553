import { convertUnitAllNumber, transformColumnNameMarketBasket } from "../utils";
import { transformDataSimulationTable } from "./transformDataSimulationTable";
import { useEffect, useState } from "react";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import Button from "../../../buttons/Button";
import Dialog from "../../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import VirtualizatedTable from "../../../virtualizatedTable/VirtualizedTable";
import MessageBlock from "../../../forms/MessageBlock";

const SimulationModal = ({
  openSimulationModal,
  toggleSimulationModal,
  data,
  handleAccept,
  handleCancel,
}) => {
  const { t } = useLanguage();
  const { simulationValues, setSimulationValues } = useMarketBasketProvider();
  const [message, setMessage] = useState<{
    text: string;
    type: string;
  }>({
    text: "",
    type: "",
  });

  useEffect(() => {
    if (data && data.length > 0 && openSimulationModal) {
      const objList: any = [];
      data.forEach((prev: any) => {
        objList.push({
          ...prev,
          tasa: 0,
          valorValue: convertUnitAllNumber(
            "unidades",
            prev.VENTA_UNIDADES_PROMEDIO,
            2,
            2
          ),
        });
      });
      setSimulationValues(objList);
    }
  }, [data.length, openSimulationModal]);

  const handleChangeValue = (e, type, row) => {
    if (type === "tasa") {
      //modificar la tasa en simulationValues para la row correspondiente
      if (e.target.value < -100) {
        setMessage({
          text: t('marketBasket.view.rateNotLessMinus100'),
          type: "error",
        });
      } else {
        setMessage({
          text: "",
          type: "",
        });
      }
      setSimulationValues((prev) => {
        const objList = prev.map((item) => {
          if (item.id === row.id) {
            return {
              ...item,
              tasa: e.target.value,
              valorValue: convertUnitAllNumber(
                "unidades",
                row.VENTA_UNIDADES_PROMEDIO +
                  row.VENTA_UNIDADES_PROMEDIO * (e.target.value / 100),
                2,
                2
              ),
            };
          }
          return item;
        });
        return objList;
      });
    } else {
      if (type === "valor" && e.target.value < 0) {
        setMessage({
          text: t('marketBasket.view.valueNotLessZero'),
          type: "error",
        });
      } else {
        setMessage({
          text: "",
          type: "",
        });
      }
      setSimulationValues((prev) => {
        const objList = prev.map((item) => {
          if (item.id === row.id) {
            return {
              ...item,
              valorValue: e.target.value,
              tasa: convertUnitAllNumber(
                "unidades",
                ((e.target.value - row.VENTA_UNIDADES_PROMEDIO) * 100) /
                  row.VENTA_UNIDADES_PROMEDIO,
                2,
                2
              ),
            };
          }
          return item;
        });
        return objList;
      });
    }
  };

  return (
    <Dialog
      open={openSimulationModal}
      handleClose={toggleSimulationModal}
      title={t('marketBasket.view.simulateScenario')}
      maxWidth={"lg"}
    >
      <Stack sx={{ minWidth: "70vw", color: "var(--text-main)", gap: 1 }}>
        {t('marketBasket.view.modifyRateOrValues')}
        <MessageBlock message={message} />
        {data && (
          <VirtualizatedTable
            columns={
              transformDataSimulationTable(
                t,
                data,
                transformColumnNameMarketBasket,
                simulationValues,
                handleChangeValue,
              ).columns
            }
            rows={
              transformDataSimulationTable(
                t,
                data,
                transformColumnNameMarketBasket,
                simulationValues,
                handleChangeValue,
              ).rows
            }
            rowsPerPageOptions={[5, 10, 20]}
            rowHeight={40}
            tableHeight={"58vh"}
            hideFooter={true}
            toolbar={true}
            columnsButton={true}
            filterButton={true}
            exportButton={true}
            checkboxSelection={false}
            setSelectedRows={() => <></>}
          />
        )}
      </Stack>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.apply")}
          color="blue-greeny"
          type="button"
          onClick={() => handleAccept()}
          disabled={message?.text !== ""}
        />
      </Stack>
    </Dialog>
  );
};

export default SimulationModal;
