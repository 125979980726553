import { stepperData } from "../data";
import { useEffect } from "react";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import MessageBlock from "../../../forms/MessageBlock";
import Stack from "@mui/material/Stack";
import Step3Calculation from "./Step3Calculation";
import Step3Form from "./Step3Form";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles,
  form_subtitle_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";


const Step3 = () => {
  const { t } = useLanguage();
  const { message, setMessage } = useMarketBasketConfigProvider();

    /************************************* Limpieza inicial ********************************/
    useEffect(() => {
      setMessage({
        text: "",
        type: "",
      });
    }, []);

  return (
    <Stack sx={{ ...param_steps_container, gap: 2 }}>
      <Typography sx={step_title_styles}>{stepperData(t).steps[2]}</Typography>
      <Typography variant="h2" sx={{ ...form_subtitle_styles, margin: 0 }}>
        {t('marketBasket.config.primaryMetricsAtDestinyLevel')}
      </Typography>
      <MessageBlock message={message} />
      <Stack sx={{ ...form_layouts_container_styles, width: "98%" }}>
        <Step3Form />
      </Stack>
      <Stack
        sx={{
          ...form_layouts_container_styles,
          width: "98%",
          justifyContent: "center",    
          minHeight: "100px",
        }}
      >
        <Step3Calculation />
      </Stack>
    </Stack>
  );
};

export default Step3;
