import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { component_layout_container_styles, icon_layout_styles, title_layout_container_styles, title_layout_styles } from "../../styles/app-styles";
interface ComponentLayoutProps {
  title: string;  
  rightComponent?: JSX.Element;
  children: any;
  icon?: JSX.Element;
  marginBottom?: string;
}

const ComponentLayout = ({
  title,
  icon,
  rightComponent,
  children,
  marginBottom
}: ComponentLayoutProps) => {
  return (
    <Stack sx={component_layout_container_styles}>
      <Stack
       sx={title_layout_container_styles(rightComponent, marginBottom)}
      >
        <Stack sx={icon_layout_styles}>
          <Box>
            {icon && icon}
            </Box>
          <Typography
            sx={title_layout_styles}
          >
            {title}
          </Typography>
        </Stack>
        {rightComponent}
      </Stack>
      {children}
    </Stack>
  );
};

export default ComponentLayout;
