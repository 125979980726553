import Divider from "@mui/material/Divider";
import { Delete, Info } from "../icons";
import { FilterRowProps, IdOption } from "./types";
import useLanguage from "../../context/LanguageProvider";

const FilterRow = ({
  id,
  condition,
  column,
  values,
  filterType,
  index,
  handleDeleteFilter,
  filters,
  setFilters,
  withOutInfoIcon,
  registerRoute
}: FilterRowProps) => {
  const { t } = useLanguage();
  return (
    <>
      <Divider sx={{ width: "90%" }} />
      <div className="cc_filter_row_container">
        <div className="cc_filter_column_1">
          <Delete
            tooltipPlacement="bottom"
            onClick={() => handleDeleteFilter(id, filters, setFilters)}
          />
        </div>
        <div className="cc_filter_column_2 cc_condition_values">
          {!withOutInfoIcon && index !== 0 && (
            <>
              {condition}
              <Info text={ t("configCriteria.filterRow.filterWarning")} color="blue"/>
            </>
          )}
        </div>
        <div className="cc_filter_column_3">{column.label}</div>
        <div className="cc_filter_column_5">{filterType}</div>
        <div className="cc_filter_column_4">
          {values.map(
            (value: IdOption, index: number) =>
              `${value.label}${index !== values.length - 1 ? ", " : ""}`
          ).filter((value: string) => value !== ", ")}
        </div>
        <div className="cc_filter_column_6"></div>
      </div>
    </>
  );
};

export default FilterRow;
