import { Stack } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { convertUnit } from "../utils";

export const transformDataForSumTable = (data: any, t) => {
  const columnas = [
   t("reportTexts.transformDataForSumTable.columns.group"),
    t("reportTexts.transformDataForSumTable.columns.destination"),
    t("reportTexts.transformDataForSumTable.columns.period"),
    t("reportTexts.transformDataForSumTable.columns.amountExpense"),
    t("reportTexts.transformDataForSumTable.columns.destinationType"),
    t("reportTexts.transformDataForSumTable.columns.percentage"),
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna === t("reportTexts.transformDataForSumTable.columns.grouper")
        ? 1.3
        : columna === t("reportTexts.transformDataForSumTable.columns.transferred") || columna === t("reportTexts.transformDataForSumTable.columns.period")
        ? 0.6
        : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        {(columna === t("reportTexts.transformDataForSumTable.columns.amountExpense")) && (
          <p>{convertUnit(t("reportTexts.transformDataForSumTable.coin"), params.value)}</p>
        )}
        {columna === t("reportTexts.transformDataForSumTable.columns.percentage") && (
          <p>{params.value + "%"}</p>
        )}
        {(columna === t("reportTexts.transformDataForSumTable.columns.period") ||
          columna === t("reportTexts.transformDataForSumTable.columns.group") ||
          columna === t("reportTexts.transformDataForSumTable.columns.destination") ||
          columna === t("reportTexts.transformDataForSumTable.columns.destinationType")) && <p>{params.value}</p>}
      </Stack>
    ),
  }));

  const arrayRows =
    data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          obj["id"] = fila.id;
          for (const key in fila) {
            if (key !== "id") {
              obj[key] = fila[key];
            }
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any, index: number) => ({
    ...row, id: index
  }));

  return { columns, rows };
};

export const getColumnsForSumTable = (t) => {
  const columnas = [
   t("reportTexts.transformDataForSumTable.columns.group"),
    t("reportTexts.transformDataForSumTable.columns.destination"),
    t("reportTexts.transformDataForSumTable.columns.period"),
    t("reportTexts.transformDataForSumTable.columns.amountExpense"),
    t("reportTexts.transformDataForSumTable.columns.destinationType"),
    t("reportTexts.transformDataForSumTable.columns.percentage"),
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna === t("reportTexts.transformDataForSumTable.columns.grouper")
        ? 1.3
        : columna === t("reportTexts.transformDataForSumTable.columns.transferred") || columna === t("reportTexts.transformDataForSumTable.columns.period")
        ? 0.6
        : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
  }));

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));
 
  return columns
};


export const transformDataForErrorsTable = (data: any, t) => {
  const columnas = [
   t("reportTexts.transformDataForSumTable.columns.group"),
    t("reportTexts.transformDataForSumTable.columns.amountExpenseResult"),
    t("reportTexts.transformDataForSumTable.columns.amountExpenseOrigin"),
    "ERROR",
    t("reportTexts.transformDataForSumTable.columns.relativeError"),
    t("reportTexts.transformDataForSumTable.columns.period"),
    t("reportTexts.transformDataForSumTable.columns.destinationType"),
    t("reportTexts.transformDataForSumTable.columns.correctCalculation")
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna ===t("reportTexts.transformDataForSumTable.columns.group")
        ? 1.3
        : columna === t("reportTexts.transformDataForSumTable.columns.destinationType") 
        ? 0.6
        : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        {(columna === t("reportTexts.transformDataForSumTable.columns.amountExpenseResult") ||
          columna === t("reportTexts.transformDataForSumTable.columns.amountExpenseOrigin") || columna === "ERROR" ) && (
          <p>{convertUnit(t("reportTexts.transformDataForSumTable.coin"), params.value)}</p>
        )}
        {(columna === t("reportTexts.transformDataForSumTable.columns.relativeError")) && (
          <p>{convertUnit("porcentaje", params.value)}</p>
        )}
         {(columna === t("reportTexts.transformDataForSumTable.columns.correctCalculation")) && (
          <p>{params.value ? "Si" : "No"}</p>
        )}
        {(columna ===t("reportTexts.transformDataForSumTable.columns.group") ||
          columna === t("reportTexts.transformDataForSumTable.columns.period") ||
          columna === t("reportTexts.transformDataForSumTable.columns.destinationType")) && <p>{params.value}</p>}
      </Stack>
    ),
  }));

  const arrayRows =
    data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          obj["id"] = fila.id;
          for (const key in fila) {
            if (key !== "id") {
              obj[key] = fila[key];
            }
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any, index: number) => ({
    ...row, id: index
  }));

  return { columns, rows };
};

export const getColumnsForErrorsTable = (t) => {
  const columnas = [
   t("reportTexts.transformDataForSumTable.columns.group"),
    t("reportTexts.transformDataForSumTable.columns.amountExpenseResult"),
    t("reportTexts.transformDataForSumTable.columns.amountExpenseOrigin"),
    "ERROR",
    t("reportTexts.transformDataForSumTable.columns.relativeError"),
    t("reportTexts.transformDataForSumTable.columns.period"),
    t("reportTexts.transformDataForSumTable.columns.destinationType"),
    t("reportTexts.transformDataForSumTable.columns.correctCalculation")
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    flex:
    columna ===t("reportTexts.transformDataForSumTable.columns.group")
      ? 1.3
      : columna === t("reportTexts.transformDataForSumTable.columns.destinationType") 
      ? 0.6
      : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
  }));

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));
 
  return columns
};
