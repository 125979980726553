import Dialog from "../../../../../dialog/Dialog";
import useLanguage from "../../../../../../context/LanguageProvider";
import { Stack } from "@mui/material";
import CustomStepper from "../../../../../Stepper/CustomStepper";
import { useState, Dispatch, SetStateAction, useEffect, useContext } from "react";
import CustomButton from "../../../../../buttons/Button";
import FormLayout from "../../../../../forms/FormLayout";
import { ConfirmationModal } from "../../../../../dialog";
import { useApiQuery } from "../../../../../../hooks/useApiQuery";
import { GET_FILTERED_ROUTES_CANCEL, REQUIRE_FILTERS } from "../../../../../../api/axios";
import useApi from "../../../../../../hooks/useApi";
import SimpleBackdrop from "../../../../../backdrop/SimpleBackdrop";
import { buscarPorId, buscarPorLabel, getInformationColumn } from "../../../../utils";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import Step1NewCancelRouteModal from "./Step1NewCancelRouteModal";
import Step2NewCancelRouteModal from "./Step2NewCancelRouteModal";
import Step3NewCancelRouteModal from "./Step3NewCancelRouteModal";

interface NewCancelRouteModalProps {
    open: boolean;
    handleClose: () => void;
    dataGetAnalysisById: any;
    retentions: { ruta: string; retencion: number }[];
    setRetentions: Dispatch<SetStateAction<any>>;
    dataGetSimulationsInformation: any;
    informationsColumns: any,
}

const NewCancelRouteModal = ({
    open,
    handleClose,
    dataGetAnalysisById,
    retentions,
    setRetentions,
    dataGetSimulationsInformation,
    informationsColumns,
}: NewCancelRouteModalProps) => {

    const { t } = useLanguage();
    const analysisId = localStorage.getItem("analysisId") ? localStorage.getItem("analysisId") : undefined
    const { simulationState, simulationDispatch } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
    const [openConfirmationCloseModal, setOpenConfirmationCloseModal] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    /********************************* STEP 1 *****************************/

    const [cancelFilters, setCancelFilters] = useState<any>([])
    const [requiredCancelFilters, setRequiredCancelFilters] = useState<any>([])
    const [requiredFiltersSchema, setRequiredFiltersSchema] = useState<any>([])

    const {
        data: dataGetRequiredFilters,
        isLoading: isLoadingDataGetRequiredFilters,
    } = useApiQuery(
        REQUIRE_FILTERS("BAJA_RUTA"),
        true,
        undefined
    );

    /********************************* STEP 2 *****************************/

    const [selectedCancelRoutes, setSelectedCancelRoutes] = useState<any[]>([])

    const addSelectedCancelRoutes = (data) => {
        setSelectedCancelRoutes(data);
    }

    const {
        data: dataPostGetFilteredRoutes,
        isLoading: isLoadingpostGetFilteredRoutes,
        callApi: postGetFilteredRoutes,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        undefined,
        undefined,
        true
    );

    /********************************* STEP 3 ****************************/

    const [selectedCancelRoutesStep3, setSelectedCancelRoutesStep3] = useState<any[]>([]);

    /********************************* MANEJO DE FUNCIONES *****************************/

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        if (activeStep === 0) {
            handleGetRoutes()
        } else if (activeStep === 1) {
            const filteredData = dataPostGetFilteredRoutes && dataPostGetFilteredRoutes.filter(item => selectedCancelRoutes && selectedCancelRoutes.includes(item._id as number)).map((item) => {
                return {
                    ...item,
                    retencion: 0,
                    "_id": item._id,
                }
            });
            setSelectedCancelRoutesStep3(filteredData)
            getInformationColumn(informationsColumns, "Identificador único de ruta") && setRetentions(filteredData.map((route) => {
                return {
                    ruta: route[getInformationColumn(informationsColumns, "Identificador único de ruta")],
                    retencion: 0,
                }
            }))
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const labels = {
        steps: [
            t("simulatorTexts.registerRoutes.newRouteModal.steps.paramSelection"),
            t("simulatorTexts.registerRoutes.newRouteModal.steps.routesSelection"),
            t("simulatorTexts.registerRoutes.newRouteModal.steps.cancelRoutes"),
        ]
    }

    const validateContinueStepper = () => {
        if (activeStep === 0 && requiredCancelFilters && requiredCancelFilters?.length === 0) {
            return true
        } else if (activeStep === 1 && selectedCancelRoutes && selectedCancelRoutes.length === 0) {
            return true
        }
        else {
            return false
        }
    }

    const closeAndClean = () => {
        setActiveStep(0)
        handleClose()
        setRequiredCancelFilters([])
        setCancelFilters([])
        setOpenConfirmationCloseModal(false)
    }

    const handleConfirmationCloseModal = () => {
        if (
            cancelFilters && cancelFilters.length > 0 || requiredCancelFilters && requiredCancelFilters.length > 0
        ) {
            setOpenConfirmationCloseModal(true)
        } else {
            closeAndClean()
        }
    }

    const handleGetRoutes = () => {
        const newConfig: { filtros: { columna: string; valor: string }[] } = {
            filtros: [],
        };

        cancelFilters && cancelFilters.forEach(item => {
            const { column, values } = item;
            newConfig.filtros.push({
                columna: column.label,
                valor: values[0].value
            });
        });

        requiredCancelFilters.forEach((item) => {
            newConfig.filtros.push({
                columna: item.columna,
                valor: item.valor[0],
            });
        });

        analysisId && postGetFilteredRoutes(GET_FILTERED_ROUTES_CANCEL(analysisId), newConfig)
    }


    const handleFinish = () => {
        const retentionsMap = retentions.reduce((acc, { ruta, retencion }) => {
            acc[ruta] = retencion;
            return acc;
        }, {});

        // Agregar la bandera de retención a rowsToEditInfo
        selectedCancelRoutesStep3.forEach((row) => {
            const COD_RUTA = getInformationColumn(informationsColumns, "Identificador único de ruta") && row[getInformationColumn(informationsColumns, "Identificador único de ruta")];
            if (retentionsMap[COD_RUTA] !== undefined) {
                row.retencion = retentionsMap[COD_RUTA];
            }
        });

        const newConfig = {
            id: simulationState.configurations.length,
            tipo: "baja",
            rutas:
                selectedCancelRoutesStep3.map((ruta) => {
                    return {
                        [getInformationColumn(informationsColumns, "Clientes por Ruta")]: ruta[getInformationColumn(informationsColumns, "Clientes por Ruta")],
                        [getInformationColumn(informationsColumns, "Venta Bruta")]: ruta[getInformationColumn(informationsColumns, "Venta Bruta")],
                        [getInformationColumn(informationsColumns, "Identificador único de ruta")]: ruta[getInformationColumn(informationsColumns, "Identificador único de ruta")],
                        [getInformationColumn(informationsColumns, "Costos de Servir")]: ruta[getInformationColumn(informationsColumns, "Costos de Servir")],
                        [getInformationColumn(informationsColumns, "Profit to Serve")]: ruta[getInformationColumn(informationsColumns, "Profit to Serve")],
                        retencion: ruta.retencion.toString(), // Convertir a cadena si es necesario
                        _id: getInformationColumn(informationsColumns, "Identificador único de ruta") && ruta[getInformationColumn(informationsColumns, "Identificador único de ruta")], // Puedes generar un ID único aquí
                    }
                }
                ),
            filtros: {
                requeridos: requiredCancelFilters && requiredCancelFilters?.map((param) => {
                    return {
                        label: param.label,
                        columna: param.columna,
                        valor: param.valor[0]
                    }
                }),
                adicionales: cancelFilters && cancelFilters?.map((params) => {
                    return {
                        columna: params.column.label,
                        valor: params.values.map((values) => {
                            return values.value
                        })[0]
                    }
                }),
            }
        }

        closeAndClean();
        simulationDispatch({ type: "SET_CONFIGURATIONS", payload: [...simulationState.configurations, newConfig] });
    }

    const routesInUse: string[] = [];

    simulationState.configurations.filter(config => config.tipo === "modificacion") && simulationState.configurations.filter(config => config.tipo === "modificacion")?.flat()?.length > 0 && simulationState.configurations.filter(config => config.tipo === "modificacion").flat()?.forEach(elemento => {
        routesInUse.push(elemento.id);
    });
    simulationState.configurations.filter(config => config.tipo === "baja") && simulationState.configurations.filter(config => config.tipo === "baja").length > 0 && simulationState.configurations.filter(config => config.tipo === "baja")?.forEach(elemento => {
        elemento.rutas.map((item) => {
            routesInUse.push(item._id)
        })
    });

    const filteredRoutes = getInformationColumn(informationsColumns, "Identificador único de ruta") && dataPostGetFilteredRoutes && dataPostGetFilteredRoutes.filter(route => {
        return !routesInUse.includes(route[getInformationColumn(informationsColumns, "Identificador único de ruta")]);
    })
        && getInformationColumn(informationsColumns, "Identificador único de ruta") && dataPostGetFilteredRoutes.filter(route => {
            return !routesInUse.includes(route[getInformationColumn(informationsColumns, "Identificador único de ruta")])
        });

    useEffect(() => {
        if (simulationState && dataGetSimulationsInformation && dataGetRequiredFilters && dataGetAnalysisById) {

            const resultados = dataGetSimulationsInformation.filter(item =>
                dataGetRequiredFilters.includes(item.nombre)
            );


            // Crear el formato deseado
            const resultadoFormateado = resultados.map(item => ({
                nombre: item.nombre,
                id: item.id,
            }));

            const principalColumns = simulationState.principalColumns.map(columna => {
                if (columna.is_medida && columna.columna) {
                    const medidaEncontrada = dataGetAnalysisById && buscarPorId(columna.columna, dataGetAnalysisById.medidas);
                    if (medidaEncontrada) {
                        return {
                            ...columna,
                            columna: { value: medidaEncontrada.id, label: medidaEncontrada.nombre },
                        };
                    }
                } else if (!columna.is_medida) {
                    const agrupationColumnEncontrada = dataGetAnalysisById && buscarPorLabel(columna.columna, dataGetAnalysisById.agrupacion.columnas_a_usar)
                    if (agrupationColumnEncontrada) {
                        return {
                            ...columna,
                            columna: { value: agrupationColumnEncontrada.value, label: agrupationColumnEncontrada.label },
                        };
                    }
                }
                return columna
            });
            resultadoFormateado.forEach((itemResultado) => {
                // Buscar el elemento correspondiente en principalColumns
                const elementoCorrespondiente = principalColumns.find((itemPrincipal) => itemPrincipal.informacion === itemResultado.id);

                // Si se encuentra, agregar la propiedad columna a resultadoFormateado
                if (elementoCorrespondiente) {
                    itemResultado.columna = elementoCorrespondiente.columna;
                }
            });

            setRequiredFiltersSchema(resultadoFormateado);
        }
    }, [simulationState, dataGetRequiredFilters, dataGetAnalysisById, dataGetSimulationsInformation])

    return (
        <Dialog
            open={open}
            handleClose={handleConfirmationCloseModal}
            title={t("simulatorTexts.canceledRoutes.newCancelRouteModal.cancelRoute")}
            maxWidth={"lg"}
        >
            <SimpleBackdrop open={isLoadingpostGetFilteredRoutes || isLoadingDataGetRequiredFilters} message={t("simulatorTexts.canceledRoutes.newCancelRouteModal.loading")} />
            <CustomStepper activeStep={activeStep} data={labels} />
            <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                padding="0 30px 30px 30px"
                position="relative"
                sx={{
                    width: "100%",
                    maxHeight: "700px",
                    height: "100%",
                    pt: "20px"
                }}
            >
                <FormLayout
                    width={"100%"}
                    backgroundColor={
                        "white"
                    }
                >
                    {activeStep === 0 && <Step1NewCancelRouteModal cancelFilters={cancelFilters} setCancelFilters={setCancelFilters} dataGetAnalysisById={dataGetAnalysisById} dataGetRequiredFilters={dataGetRequiredFilters} setRequiredCancelFilters={setRequiredCancelFilters} requiredCancelFilters={requiredCancelFilters} defaultCedis={undefined} requiredFiltersSchema={requiredFiltersSchema} />}
                    {activeStep === 1 && <Step2NewCancelRouteModal setSelectedRoutes={addSelectedCancelRoutes} tableRoutesData={filteredRoutes ? filteredRoutes : []} selectedRoutes={selectedCancelRoutes} selectedCancelRoutesStep3={selectedCancelRoutesStep3} setSelectedCancelRoutesStep3={setSelectedCancelRoutesStep3} informationsColumns={informationsColumns && informationsColumns} />}
                    {activeStep === 2 && informationsColumns && <Step3NewCancelRouteModal filteredRoutes={selectedCancelRoutesStep3} retentions={retentions} setRetentions={setRetentions} informationsColumns={informationsColumns && informationsColumns} />}
                </FormLayout>
            </Stack>
            <Stack sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
            }}>
                {activeStep !== 0 &&
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.goBack")}
                        color="grey"
                        type="button"
                        onClick={handleBack}
                    />
                }
                {activeStep === 2 ?
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.finish")}
                        color="blue-greeny"
                        type="button"
                        onClick={handleFinish}
                    /> :
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.continue")}
                        color="blue"
                        type="button"
                        onClick={() => handleNext()}
                        disabled={validateContinueStepper()}
                    />
                }
            </Stack>
            <ConfirmationModal
                open={openConfirmationCloseModal}
                handleClose={() => setOpenConfirmationCloseModal(false)}
                handleAccept={closeAndClean}
                message={t("simulatorTexts.registerRoutes.newRouteModal.closeModalWithInfo")}
                title={t("simulatorTexts.canceledRoutes.newCancelRouteModal.cancelRoute")}
            />
        </Dialog>
    )
}

export default NewCancelRouteModal