import { Stack, Typography } from "@mui/material";
import { SingleValue } from "react-select";
import { Button } from "../buttons";
import FormSelectLayout from "../forms/FormSelectLayout";
import { selectSchemaStyles } from "../configCriteria/select.styles";
import { IdOption, OnChange } from "../configCriteria/types";
import { CustomizedTable } from "../customizedTable";
import { CustomSelect } from "../forms";
import "./styles.css";
import { IncomingSchemasOptions } from "./types";
import { useEffect } from "react";
import { selectedGridRowsCountSelector } from "@mui/x-data-grid";
import useLanguage from "../../context/LanguageProvider";
interface SelectSchemaContentProps {
  schemaSelectRef: any;
  schemaSelectedOptions: SingleValue<IdOption> | undefined;
  setSchemaSelectedOptions: (value: SingleValue<IdOption> | undefined) => void;
  schemasOptions: any;
  schemaSelectOptions: IncomingSchemasOptions | undefined;
  schemaOptionsChangeHandler: OnChange;
  destinyType: string;
  handleClose: () => void;
  handleApplySchemas: any;
  selectedRows: any;
}

const SelectSchemaContent = ({
  schemaSelectRef,
  schemaSelectedOptions,
  setSchemaSelectedOptions,
  schemasOptions,
  schemaSelectOptions,
  schemaOptionsChangeHandler,
  destinyType,
  handleClose,
  handleApplySchemas,
  selectedRows,
}: SelectSchemaContentProps) => {
  const { t } = useLanguage();
  const destiny_type =
    destinyType ===
    t("preprorationParameterization.selectSchemaContent.bucketExpense")
      ? "BUCKET_GASTO"
      : "AGRUPADOR";      
  useEffect(() => {
    setSchemaSelectedOptions(undefined);
    schemaSelectRef.current.clearValue();
  }, []);

  let COLUMNS: string[] = [];
  let ROWS: any[] | undefined = [];

  if (schemaSelectedOptions) {
    COLUMNS = [
      destinyType,
      t("preprorationParameterization.selectSchemaContent.criteria"),
    ];
    ROWS = schemaSelectedOptions
      ? schemaSelectOptions
          ?.filter((item: any) => {
            return item.tipo_destino === destiny_type;
          })
          ?.filter((esquema: any) => {
            return esquema.id === schemaSelectedOptions.value;
          })[0]
          ?.esquema_criterios?.map((item: any) => {
            return {
              [destinyType]: item.destino,
              Criterio:
                typeof item.criterio === "number"
                  ? item.criterio + "%"
                  : item.criterio,
            };
          })
      : [];
  }

  return (
    <Stack
      mb="5px"
      sx={{
        width: "500px",
        minHeight: "40vh",
        justifyContent: "space-between",
      }}
    >
      <FormSelectLayout
        title={`${t(
          "preprorationParameterization.selectSchemaContent.chooseExistingSchema"
        )}:`}
      >
        <CustomSelect
          reference={schemaSelectRef}
          styles={selectSchemaStyles(schemaSelectedOptions)}
          options={schemasOptions(schemaSelectOptions, destiny_type)}
          className="cc_select pp_select_schema"
          name="Select_schema"
          onChange={schemaOptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "preprorationParameterization.selectSchemaContent.selectSchema"
          )}
          defaultValue={schemaSelectedOptions}
        />
      </FormSelectLayout>
      {schemaSelectedOptions && ROWS && ROWS.length !== 0 && (
        <FormSelectLayout
          title={`${t(
            "preprorationParameterization.selectSchemaContent.detail"
          )}:`}
        >
          <CustomizedTable COLUMNS={COLUMNS} ROWS={ROWS} width={"100%"} />
        </FormSelectLayout>
      )}
      <Stack>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          *
          {t(
            "preprorationParameterization.selectSchemaContent.schemaWillAplly"
          )}{" "}
          {selectedRows.length}{" "}
          {t("preprorationParameterization.selectSchemaContent.row")}
          {selectedRows.length === 1 ? "" : "s"}{" "}
          {t("preprorationParameterization.selectSchemaContent.selected")}
          {selectedRows.length === 1 ? "" : "s"}.
        </Typography>
        <Stack direction="row" justifyContent={"space-between"} mt="10px">
          <Button
            title={t("preprorationParameterization.selectSchemaContent.cancel")}
            color="grey"
            type="button"
            onClick={() => handleClose()}
          />
          <Button
            title={t("preprorationParameterization.selectSchemaContent.apply")}
            color="blue-greeny"
            type="button"
            onClick={() => handleApplySchemas()}
            disabled={!schemaSelectedOptions}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectSchemaContent;
