import Box from "@mui/material/Box";
import React from "react";
interface FormLayoutProps {
  children: React.ReactNode;
  width?: string;
  minHeight?:string;
  backgroundColor?: string;
  boxHeight?: string;
  boxJustifyContent?: string;
  padding?: string;
}

const FormLayout = ({ children, width, backgroundColor, minHeight, boxHeight, boxJustifyContent, padding }: FormLayoutProps) => {
  return (
    <Box    
      sx={{
        width: width || "100%",
        backgroundColor: backgroundColor || "white",      
        alignItems: "flex-start",      
        borderRadius: "5px",
        boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
        color: "var(--text-main)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginBottom: "10px",
        minWidth: "300px",
        minHeight: minHeight,
        padding: padding ? padding : "10px 25px",      
      }}
    >
      <Box  
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: boxJustifyContent ? boxJustifyContent : "center",
          minWidth: "290px",
          width: "100%",
          height: boxHeight ? boxHeight : "auto",   
        }}
      >
        {children}
      </Box>
    </Box>

  );
};

export default FormLayout;
