import { PUT_ESTADO_TABLA_ARCHIVO } from "../../../api/axios";
import { tableConfigurations } from "./configurations";
import { useState } from "react";
import CardActions from "../../cards/CardActions";
import CardHeader from "../../cards/CardHeader";
import Divider from "@mui/material/Divider";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TableDetails from "./TableDetails";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";

const TableCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  refetchData,
}: any) => {
  const { t } = useLanguage();
  const [checked, setChecked] = useState(item.activo);

  const onSuccessPutState = () => {
    refetchData();
  };
  const { isLoading: isLoadingPutState, callApi: putState } = useApi(
    undefined,
    "PUT",
    t("indicatorsManagement.putState.codes"),
    undefined,
    onSuccessPutState,
    undefined,
    false
  );

  const handleChangeSwitch = () => {
    setChecked((prev: boolean) => !prev);
    putState(PUT_ESTADO_TABLA_ARCHIVO(item.id), { activo: !item.activo });
  };

  const tableConfig = tableConfigurations[item.tipo] || {};

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPutState}
        message={t("indicatorsManagementTexts.chartCard.modifyingChartState")}
      />
      <Stack
        justifyContent="space-between"
        sx={{ padding: "5px 8px 8px 8px", minHeight: "140px" }}
      >
        <Stack justifyContent="flex-start">
          <CardHeader title={item.nombre} />
          <Divider sx={{ margin: "10px 0" }} />
          <TableDetails item={item} t={t} tableConfig={tableConfig} />
        </Stack>
        <CardActions
          checked={checked}
          handleChangeSwitch={handleChangeSwitch}
          item={item}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
};

export default TableCard;
