import axios from "../../api/axios";
import { LOGIN_URL } from "../../api/axios";
interface LoginProps {
  email: string;
  password: string;
  recordar: boolean;
}

export const postLogin = async (
  userData: LoginProps,
  handlePostLogin: (status: number, data: any) => void
) => {
  try{
    const response = await axios.post(LOGIN_URL, JSON.stringify(userData), {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    handlePostLogin(response.status, response.data);
  }
  catch (error: any) {
    handlePostLogin(error.request.status, error.data);
  }
};
