import {
  formatGroupingRow,
  formatNewColumnRow,
  validateGroupingRow,
  validateNewColumnRow,
} from "../../utils";
import { GROUPING_FUNCTIONS } from "../../data";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useRef } from "react";
import AddButton from "../../../buttons/AddButton";
import DeleteForever from "../../../icons/DeleteForever";
import Grid from "@mui/material/Grid/Grid";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";
interface NewColumnSelectsProps {
  titles: string[];
  totalColumnsOptions: any[];
  rows: any[];
  setRows: any;
  setMessage: any;
}

const GroupingSelects = ({
  titles,
  totalColumnsOptions,
  rows,
  setMessage,
  setRows,
}: NewColumnSelectsProps) => {
  const { t } = useLanguage();
  const column2SelectRef: any = useRef(null);
  const columns3SelectRef: any = useRef(null);

  const [
    column2SelectedOption,
    setColumn2SelectedOption,
    column2OptionsChangeHandler,
  ] = useSingleSelect();

  const [
    columns1SelectedOptions,
    setColumns1SelectedOptions,
    columns3OptionsChangeHandler,
  ] = useMultiSelect();

  const cleanSelects = () => {
    setColumn2SelectedOption(undefined);
    setColumns1SelectedOptions(undefined);
    column2SelectRef?.current?.clearValue();
    columns3SelectRef?.current?.clearValue();
  };

  const handleAddRow = () => {
    if (
      validateGroupingRow(
        t,
        rows,
        setMessage,
        columns1SelectedOptions,
        column2SelectedOption
      )
    ) {
      const newRow = formatGroupingRow(
        columns1SelectedOptions,
        column2SelectedOption
      );
      const newRows = rows && rows.length > 0 ? [...rows] : [];
      newRows.push(newRow);
      setRows(newRows);
      setMessage({
        type: "",
        text: "",
      });
      cleanSelects();
    }
    return true;
  };

  const handleDeleteAllRows = () => {
    setRows([]);
    setMessage({
      type: "",
      text: "",
    });
    cleanSelects();
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Select
          reference={columns3SelectRef}
          styles={selectStyles(columns1SelectedOptions)}
          options={totalColumnsOptions}
          onChange={columns3OptionsChangeHandler}
          closeMenuOnSelect={false}
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={columns1SelectedOptions}
          isMulti
        />
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Select
          reference={column2SelectRef}
          styles={selectStyles(column2SelectedOption)}
          options={GROUPING_FUNCTIONS(t)}
          onChange={column2OptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={column2SelectedOption}
        />
      </Grid>
      <Grid item xs={0.5}>
        <AddButton align={true} onClick={() => handleAddRow()} />
      </Grid>
    </>
  );
};

export default GroupingSelects;
