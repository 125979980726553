import { stepper_buttons_container_styles2 } from "../../../styles/app-styles";
import { useCompanyParamProvider } from "../../../context/CompanyParamProvider";
import Button from "../../buttons/Button";
import Stack from "@mui/material/Stack";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import ExpensesCard from "./ExpensesCard";
import SalesCard from "./SalesCard";
import FileDescriptionCard from "./FileDescriptionCard";
import TypeColumnsCard from "./TypeColumnsCard";
import RelationshipsCard from "./RelationshipsCard";
import CustomAccordion from "../../accordion/CustomAccordion";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import ExchangeRateCard from "./ExchangeRateCard";
import Edit from "../../icons/Edit";
import PnlCard from "./PnlCard";

const SummaryView = ({ setIsEditOpen }) => {
  const { t } = useLanguage();
  const {
    dataExchangeParams,
    dataGetExpensesParams,
    dataGetSalesParams,
    dataGetPnlParams,
    dataGetOtherFilesParams,
    dataGetColumnsTypesParams,
    dataGetRelationshipsParams,
    dataGetExpenseTargetParams,
    isLoadingGetExpensesParams,
    isLoadingGetSalesParams,
    isLoadingGetPnlParams,
    isLoadingOtherFilesParams,
    isLoadingColumnsTypesParams,
    isLoadingRelationshipsParams,
    isLoadingExpenseTargetParams,
    setStepToEdit,
  } = useCompanyParamProvider();
  const { userRolData }: any = useInfoContext();

  const handleEdit = (step: number) => {
    setStepToEdit(step);
    setIsEditOpen(true);
  };

  const stepsElements = [
    {
      ...dataExchangeParams,
      id: 0,
      title: "Tipo de cambio",
      card: ExchangeRateCard,
    },
    { ...dataGetExpensesParams, id: 1, title: "GASTOS", card: ExpensesCard },
    { ...dataGetSalesParams, title: "VENTAS", id: 2, card: SalesCard },
    {
      data: dataGetPnlParams,
      id: 3,
      card: PnlCard,
      title: "Parametrización P&L",
    },
    {
      data: dataGetOtherFilesParams,
      id: 4,
      card: FileDescriptionCard,
      title: "Descripción de archivos",
    },
    {
      data: dataGetColumnsTypesParams,
      id: 5,
      card: TypeColumnsCard,
      title: "Tipos de columnas",
    },
    {
      data: dataGetRelationshipsParams,
      id: 6,
      card: RelationshipsCard,
      title: "Relaciones",
    },
    {
      data: dataGetExpenseTargetParams,
      id: 7,
      card: RelationshipsCard,
      title: "Receptores de gastos",
    },
  ];

  const isData =
    dataExchangeParams &&
    dataGetExpensesParams &&
    dataGetSalesParams &&
    dataGetPnlParams &&
    dataGetOtherFilesParams &&
    dataGetColumnsTypesParams &&
    dataGetRelationshipsParams &&
    dataGetExpenseTargetParams;

  const isStepAbleToEdit = (step) => {
    const expensesAndSalesCompleted =
      dataGetExpensesParams?.completo && dataGetSalesParams?.completo;
    if (step.id === 0 || step.id === 1 || step.id === 2 || step.id === 4) {
      return true;
    } else {
      return expensesAndSalesCompleted;
    }
  };

  return (
    <Stack direction={"column"} width="100%">
      <SimpleBackdrop
        open={
          isLoadingGetExpensesParams ||
          isLoadingGetSalesParams ||
          isLoadingGetPnlParams ||
          isLoadingOtherFilesParams ||
          isLoadingColumnsTypesParams ||
          isLoadingRelationshipsParams ||
          isLoadingExpenseTargetParams
        }
        message={`${t("general.loading")}`}
      />
      <Stack gap={1.5}>
        {isData &&
          stepsElements.map((step) => {
            return (
              <CustomAccordion
                key={step.id}
                title={
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span style={{ width: "100%", paddingRight: "10px" }}>
                      {step.title}
                    </span>
                    <Edit
                      onClick={() =>
                        isStepAbleToEdit(step) ? handleEdit(step.id) : <></>
                      }
                      fill={
                        isStepAbleToEdit(step)
                          ? "var(--icon-info) !important"
                          : "var(--bg-disabled) !important"
                      }
                    />
                    {/* <Button
                      title="Editar"
                      color="blue-greeny"
                      type="button"
                      onClick={handleEdit}
                      disabled={
                        !userRolData?.parametrizacion_de_la_empresa
                          .visualizacion_y_edicion
                      }
                      margin={false}
                    /> */}
                  </span>
                }
                backgroundColor="var(--cream)"
                summaryColor={"var(--text-main)"}
                arrowColor="var(--icon-main)"
              >
                {<step.card item={step} showInfo={true} />}
              </CustomAccordion>
            );
          })}
      </Stack>

      <Stack sx={{ ...stepper_buttons_container_styles2, mt: "10px" }}>
        {userRolData && (
          <Button
            title="Editar"
            color="blue-greeny"
            type="button"
            onClick={() => handleEdit(0)}
            disabled={
              !userRolData?.parametrizacion_de_la_empresa
                .visualizacion_y_edicion
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export default SummaryView;
