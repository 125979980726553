import { ActionMeta } from "react-select";
import { optionalSelectStyles } from "../../../configCriteria/select.styles";
import CreatableSelect from "react-select/creatable";
import useLanguage from "../../../../context/LanguageProvider";

const OtherFilesSingleSelect = ({
  setColumnSelectedOption,
  columnSelectedOption,
  columnSelectRef,
}) => {
  const { t } = useLanguage();
  const fileDescriptions =[
    {
      value: 1,
      label: t('companyParam.customerMaster'),
    },
    {
      value: 2,
      label: t('companyParam.routeMaster'),
    },
    {
      value: 3,
      label: t('companyParam.ProductSKUMaster'),
    },
    {
      value: 4,
      label: t('companyParam.assetAssignment'),
    },
    {
      value: 5,
      label: t('companyParam.visitLog')
    }
  ]

  const columnOptionsChangeHandler = (value: any, action: ActionMeta<any>) => {
    switch (action.action) {
      case "select-option":
        setColumnSelectedOption(value);
        break;
      case "clear":
        setColumnSelectedOption(undefined);
        break;
      default:
        break;
    }
  };

  const handleCreate = (inputValue: any) => {
    columnSelectRef.current.setValue({ value: inputValue, label: inputValue })    
    setColumnSelectedOption({ value: inputValue, label: inputValue });
  };  

  return (
    <CreatableSelect
      ref={columnSelectRef}
      isClearable
      formatCreateLabel={(userInput) =>
        `${t("expensesSettings.modalContent.add")} ${userInput}`
      }
      styles={optionalSelectStyles(columnSelectedOption)}
      options={fileDescriptions}
      className="es_form_input"
      onChange={columnOptionsChangeHandler}
      closeMenuOnSelect
      isSearchable
      placeholder={t(
        "preprorationParameterization.newSchemaContent.chooseAddValue"
      )}
      onCreateOption={handleCreate}
      createOptionPosition="first"
    />
  );
};

export default OtherFilesSingleSelect;
