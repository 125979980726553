import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizedTable from "../../virtualizatedTable/VirtualizedTable";

const FileDescriptionCard = ({ item, showInfo }: any) => {
  const { t } = useLanguage();

  const tranformObjectToTable = (keys) => {
    const rowObject: any = { id: "descripcion" };
    keys.forEach((key) => {
      const selectedItem = item?.data.find((file) => file.nombre === key);
      if (selectedItem) {
        rowObject[key] = selectedItem.descripcion;
      }
    });
    return rowObject;
  };

  const COLUMNS1 = item.data.map((file) => file.nombre);

  const ROWS1 = [tranformObjectToTable(COLUMNS1)];

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        {showInfo && ROWS1?.length && (
          <Stack width={`100%`}>
            <VirtualizedTable
              columns={
                transformDataSimpleTable(ROWS1, undefined, undefined, t, 260)
                  .columns
              }
              rows={
                transformDataSimpleTable(ROWS1, undefined, undefined, t, 260)
                  .rows
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={25}
              tableHeight={"80px"}
              hideFooter={true}
              toolbar={false}
              columnsButton={false}
              filterButton={false}
              exportButton={false}
              checkboxSelection={false}
              setSelectedRows={() => <></>}
              headerHeight={25}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default FileDescriptionCard;
