import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  GET_CRITERIA_TARGET_URL,
  GET_CRITERIA_FILE_COLUMN_URL,
  GET_CRITERIA_FUNCTIONS_URL,
  TARGET_URL,
  GET_CRITERIA_COLUMN_VALUES_URL
} from "../../api/axios";

/* export const useGetCriteriaBuckets = () => {
  const axiosPrivate = useAxiosPrivate();

  const getCriteriaBuckets = async () => {
    const response = await axiosPrivate.get(GET_BUCKETS_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getCriteriaBuckets"],
    getCriteriaBuckets,
    { refetchOnWindowFocus: false }
  );

  return info;
};  */

export const useGetCriteriaTarget = () => {
  const axiosPrivate = useAxiosPrivate();

  const getCriteriaTarget = async () => {
    const response = await axiosPrivate.get(GET_CRITERIA_TARGET_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getCriteriaTarget"],
    getCriteriaTarget,
    { refetchOnWindowFocus: false }
  );

  return info;
};

export const useGetCriteriaFileColumn = () => {
  const axiosPrivate = useAxiosPrivate();

  const getCriteriaFileColumn = async () => {
    const response = await axiosPrivate.get(GET_CRITERIA_FILE_COLUMN_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getCriteriaFileColumn"],
    getCriteriaFileColumn,
    { refetchOnWindowFocus: false }
  );

  return info;
}; 

export const useGetCriteriaFunctions = () => {
  const axiosPrivate = useAxiosPrivate();

  const getCriteriaFunctions = async () => {
    const response = await axiosPrivate.get(GET_CRITERIA_FUNCTIONS_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getCriteriaFunctions"],
    getCriteriaFunctions,
    { refetchOnWindowFocus: false }
  );

  return info;
};

/* export const postCriteria = async (
  axiosPrivate: any,
  completedInfo: any,
  handleSendCriteriaResponse: any
) => {
  if (completedInfo) {    
    const data = JSON.stringify(completedInfo);   
    try {
      const response = await axiosPrivate.post(POST_CRITERIA_URL, data);
      handleSendCriteriaResponse(response.status, response.data)    
    } catch (error: any) {
      handleSendCriteriaResponse(400, error.data)       
    }
  }
}; */

export const postNewTarget = async (
  axiosPrivate: any,
  newTargetValue: any,
  newTargetName: any,
  previousDestination: boolean,
  handleFeedback: (status: number)=>void
) => {
  if (newTargetValue && newTargetName) {
    const completedInfo = {
      nombre: newTargetName,
      columna: newTargetValue,
      validar_asignacion: previousDestination,
    };
    const data = JSON.stringify(completedInfo); 
    try {     
      const response = await axiosPrivate.post(TARGET_URL, data);
      handleFeedback(response.status)
    } catch (error: any) {   
      handleFeedback(error.res.status);
    }
  }
};

export const putNewTarget = async (
  axiosPrivate: any,
  completedInfo: any,
  setOpenSuccessCriteriaModal: any,
  setOpenErrorCriteriaModal: any
) => {
  if (completedInfo) {
    const data = JSON.stringify(completedInfo);
    try {
      const response = await axiosPrivate.put(TARGET_URL, data);
      setOpenSuccessCriteriaModal(true);
    } catch (error: any) {
      setOpenErrorCriteriaModal(true);
    }
  }
};

export const getColumnValues = async (
  axiosPrivate: any,
  columnId: number | string,
  setIncomingValuesOptions: any
) => {
  try { 
    const response = await axiosPrivate.get(
      `${GET_CRITERIA_COLUMN_VALUES_URL}?columna_id=${columnId}`    
    );    
    setIncomingValuesOptions(response.data);
  } catch (error) {
    console.log("get Values error",error);      
  }
};

/* export const getColumnValuesData = async (
  axiosPrivate: any,
  columnId: number | string
): Promise<IdOption[] | undefined>=> {
  try { 
    const response = await axiosPrivate.get(
      `${GET_CRITERIA_COLUMN_VALUES_URL}?columna_id=${columnId}`    
    );    
    return response.data;
  } catch (error) {
    console.log("get Values error",error);      
  }
}; */

export const useGetCriteriaExpensesColumn = () => {
  const axiosPrivate = useAxiosPrivate();

  const getCriteriaExpensesColumn = async () => {
    const response = await axiosPrivate.get(`${GET_CRITERIA_FILE_COLUMN_URL}?archivo=gastos`);
    return response.data;
  };
  const info: UseQueryResult<any, unknown> = useQuery(
    ["getCriteriaExpensesColumn"],
    getCriteriaExpensesColumn,
    { refetchOnWindowFocus: false }
  );
  return info;
};

/* export const putCriteria = async (
  id: number,
  axiosPrivate: any,
  completedInfo: any,
  handleSendCriteriaResponse: any
) => {
  if (completedInfo) {
    const data = JSON.stringify(completedInfo); 
    try {
      const response = await axiosPrivate.put(`${POST_CRITERIA_URL}?id=${id}`, data);
      handleSendCriteriaResponse(response.status, response.data) 
    } catch (error: any) {
      handleSendCriteriaResponse(400, error.data)      
    }
  }
}; */
