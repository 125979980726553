import { convertArrayToText } from '../../../taskManager/utils'
import { grid_container2_styles } from '../../../../styles/app-styles'
import { Item } from '../../../basicGrid/BasicGrid'
import Grid from '@mui/material/Grid/Grid'
import GridFiveTitles from '../../../marketBasket/MarketBasketConfig/Shared/GridTitles'
import GroupingRow from './GroupingRow'
import Typography from '@mui/material/Typography'
import useLanguage from '../../../../context/LanguageProvider'

const GroupingView = ({element}) => {
  const { t } = useLanguage();

  const titles= [
    t("Columnas Medida"),
    t("Función de agregación"),
  ]
  return (
    <Grid sx={{...grid_container2_styles, minHeight: "100px"}} container spacing={1}>
      <Grid item xs={0.5}>
      </Grid>
      <Grid item xs={11}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {convertArrayToText(
              element?.columnas_agrupar?.map((column: any) => {
                return column?.label;
              })
            )}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={0.5}>
      </Grid>
      <GridFiveTitles titles={titles} />
      {element &&
        element.agregaciones &&
        element.agregaciones.length > 0 &&
        element.agregaciones.map((row: any, index: number) => (
          <GroupingRow
          key={index}
          index={index}
          row={row}
          rows={element.agregaciones}
          setRows={() => <></> }
          titles={titles}
          deletable={false}
        />
        ))}
    </Grid>
  )
}

export default GroupingView