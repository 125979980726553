import { DataGrid } from "@mui/x-data-grid";
import { GENERATED_RESOURCES } from "../../api/axios";
import { sortTasksByDate } from "../taskManager/utils";
import { Stack } from "@mui/material";
import { Tab, TabValue } from "../colorTabs/types";
import { transformDataTaskManager } from "../taskManager/transformData";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useLocation } from "react-router";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import CustomToolbar from "../customToolbar/CustomToolbar";
import React, { useEffect, useState } from "react";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useApi from "../../hooks/useApi";
import useLanguage from "../../context/LanguageProvider";

const GeneratedDownloads = () => {
  const { t } = useLanguage();
  const location = useLocation();
  const [tabValue, setTabValue] = useState<TabValue>("ANALISIS_DATAEXPORT");
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const tabs: Tab[] = [
    { value: "ANALISIS_DATAEXPORT", label: "DATA EXPORT" },
    { value: "ARCHIVOS", label: "ARCHIVOS" },
  ];
  const [pageSize, setPageSize] = useState<number>(6);

  const {
    data: dataExport,
    isLoading: isLoadingDataExport,
    error: errorDataExport,
    isSuccess: isSuccessDataExport,
  } = useApiQuery(
    `${GENERATED_RESOURCES}/?tipo=ANALISIS_DATAEXPORT`,
    true,
    t("taskManager.getDownloads.error"),
    undefined
  );

  const {
    data: dataFiles,
    isLoading: isLoadingDataFiles,
    error: errorDataFiles,
    isSuccess: isSuccessDataFiles,
  } = useApiQuery(
    `${GENERATED_RESOURCES}/?tipo=ARCHIVO`,
    true,
    t("taskManager.getDownloads.error"),
    undefined
  );

  useEffect(() => {
    if (location.state && location.state.tab === "DATA EXPORT") {
      setTabValue("ANALISIS_DATAEXPORT");
    }
  }, [location]);

  useEffect(() => {
    if (dataByTabValue()) {
      const { columns, rows } = transformDataTaskManager(
        t,
        dataByTabValue(),
        tabValue,
        buttonsActions
      );
      setColumns(columns);
      setRows(rows);
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [tabValue, dataExport]);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const { isLoading: isLoadingDownloadFile, callApi: downloadFile } = useApi(
    undefined,
    "GET",
    t("dataManagement.downloadFile.codes"),
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  );

  const dataByTabValue = () => {
    switch (tabValue) {
      case "ANALISIS_DATAEXPORT":
        return dataExport;
      case "ARCHIVOS":
        return dataFiles;
      default:
        return [];
    }
  };

  const buttonsActions = {
    downloadFile,
  };

  const loadings = [
    {
      open: isLoadingDownloadFile,
      message: t("taskManager.loadingDownloadFile"),
    },    
  ];

  return (
    <ComponentLayout
      title={t("generatedDownloads.downloads")}
      icon={<BrowserUpdatedIcon />}
    >
      {loadings.map((loading, index) => (
        <SimpleBackdrop
          key={index}
          open={loading.open}
          message={loading.message}
        />
      ))}
      <ColorTabs
        value={tabValue}
        handleChange={handleChange}
        tabs={tabs}
        marginBottom="20px"
      />
      <Stack width="100%">
        {tabValue === "ANALISIS_DATAEXPORT" && isLoadingDataExport ? (
          <CustomSkeleton height={6} />
        ) : tabValue === "ANALISIS_DATAEXPORT" && errorDataExport ? (
          <p>{t("taskManager.error")}</p>
        ) : (
          <Stack style={{ height: "70vh", width: "100%", color: "red" }}>
            <DataGrid
              rows={sortTasksByDate(rows)}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[6, 15, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{
                Toolbar: () => (
                  <CustomToolbar
                    toolbar={true}
                    filterButton={true}
                    densitySelector={true}
                  />
                ),
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu={true}
              hideFooter={false}
              checkboxSelection={false}
              disableSelectionOnClick
              sx={{
                boxShadow: 3,
                fontSize: "12px",
                padding: "0 10px 10px 10px",
              }}
            />
          </Stack>
        )}
      </Stack>
    </ComponentLayout>
  );
};

export default GeneratedDownloads;
