import { form_label, form_label_asterisc } from "../../styles/app-styles";
import "./step.styles.css";
interface FormSelectProps {
  title?: string;
  children: React.ReactNode;
  required?: boolean;
  icon?: any;
  margin?: string;
  color?: string;
}

const FormSelectLayout = ({
  title,
  children,
  required,
  icon,
  margin,
  color
}: FormSelectProps) => {
  return (
    <div style={{ margin: margin ? margin : "5px 0 10px 0" }}>
      <label style={{...form_label, color: color? color : "var(--text-main)"}} htmlFor={title} aria-label={title}>
        {required && <span style={form_label_asterisc}>*</span>}
        {title && title}
        {icon && icon}
      </label>
      {children}
    </div>
  );
};

export default FormSelectLayout;
