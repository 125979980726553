export const operadores = ["(", ")", "/", "*", "-", "+", ".", "<"];
export const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const formulaRegex = /^[0-9+/*%()-.]*$/;
export const operatorsCalculator = [
  "",
  "(",
  ")",
  "/",
  "7",
  "8",
  "9",
  "*",
  "4",
  "5",
  "6",
  "-",
  "1",
  "2",
  "3",
  "+",
  "0",
  ".",
  "<",
];

export const checkParentheses = (str: string): boolean => {
  const stack: string[] = [];

  for (const char of str) {
    if (char === '(') {
      stack.push(char);
    } else if (char === ')') {
      if (stack.length === 0) {
        return true; // Hay un paréntesis de cierre sin su correspondiente paréntesis de apertura
      }
      stack.pop();
    }
  }
  return stack.length != 0; // Si la pila está vacía, significa que los paréntesis están equilibrados
}

export const getConcatenatedData = (formula: any): string => {
  let datosConcatenados = "";

  for (const obj of formula) {
    if (obj.dato) {
      datosConcatenados += obj.dato;
    }
  }

  return datosConcatenados;
};

export const handleAddOperatorToFormula = (operator: string, formula, setFormula) => {
  if (operator === "." || numbers.includes(operator)) {
    // Verificar si hay un número en el estado previo y si se debe agregar el "." o el número a ese número
    const prevData = [...formula];
    const lastIndex = prevData.length - 1;
    if (lastIndex >= 0 && prevData[lastIndex].tipo === "VALOR") {
      const prevNumber = prevData[lastIndex].dato;
      const newNumber = prevNumber + operator;
      prevData[lastIndex] = {
        tipo: "VALOR",
        dato: newNumber,
        orden: prevData.length - 1,
      };
      setFormula(prevData);
      return;
    }
  }
  // Si el carácter ingresado no es un ".", o no se pudo agregar al número anterior, proceder normalmente
  if (operadores.includes(operator)) {
    setFormula((prevData) => [
      ...prevData,
      { tipo: "OPERADOR", dato: String(operator), orden: prevData.length },
    ]);
  } else {
    setFormula((prevData) => [
      ...prevData,
      { tipo: "VALOR", dato: String(operator), orden: prevData.length },
    ]);
  }
};

export const lastItemOperator = (formula) => {
  const lastElement = formula[formula.length - 1];
  return (
    lastElement && lastElement.tipo === "OPERADOR" && lastElement.dato != ")"
  );
};

export const handleRemoveLastElement = (formula, setFormula) => {
  if (formula.length > 0) {
    setFormula((prevData) => prevData.slice(0, -1));
  }
};

export const handleValidateFormula = (formula, formulaToString) => {
  if (formula.length === 0) {
    return true;
  }
  if (checkParentheses(getConcatenatedData(formula))) {
    return true;
  }
  if (lastItemOperator(formula)) {
    return true;
  }
  const lastElement = formula[formula.length - 1];
  if (lastElement.tipo === "OPERADOR" && lastElement.dato !== ")") {
    return true;
  }
  if (formulaToString === "") {
    return true;
  }
  return false;
};

export const handleKeyDownFormula = (e, formula, setFormula) => {
  if (e.key === "Backspace") {
    // Bloquear la tecla "Backspace"
    e.preventDefault();
    handleRemoveLastElement(formula, setFormula);
  }
};


