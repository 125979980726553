import { Messages } from "../hooks/useApi";

type Option = {
  id: number | string;
  nombre: string;
};

export const convertToInternationalCurrencySystem = (
  num: number,
  digits: number
) => {
  const lookup = [
    { value: 1e3, symbol: " K" },
    { value: 1e6, symbol: " M" },
    { value: 1e9, symbol: " B" },
    { value: 1e12, symbol: " T" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? "$" + (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : num;
};

export const formatOptions = (options: Option[] | undefined) => {
  return options
    ? options.map((option: Option) => {
        return {
          value: option.id,
          label: option.nombre,
        };
      })
    : [];
};

export const formatCompanyOptions = (options: any | undefined) => {
  return options
    ? options.map((option: any) => {
        return {
          value: option.id,
          label: option.nombre_visual,
        };
      })
    : [];
};

export function getMessages(obj: { [key: number]: string }): Messages {
  // Creamos un objeto del tipo Messages con todas las claves y valores vacíos
  const messages: Messages = {
    200: "",
    201: "",
    204: "",
    400: "",
    401: "",
    404: "",
    500: "",
  };

  // Recorremos el objeto que se ha pasado como parámetro
  for (const key in obj) {
    // Si la clave existe en el objeto messages, asignamos el valor correspondiente
    if (key in messages) {
      messages[key] = obj[key];
    }
  }

  // Devolvemos el objeto messages completo
  return messages;
}

function abbreviateNumber(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
}

export const convertUnit = (unit: string, value: number) => {
  switch (unit) {
    case "moneda":
      const amount = convertToInternationalCurrencySystem(value, 2);
      return amount.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    case "porcentaje":
      return value.toLocaleString("es-ES", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    case "unidades":
      return abbreviateNumber(value);
    default:
      return value;
  }
};

//Esta función toma un array de strings como entrada y devuelve un nuevo array que contiene solo los elementos únicos del array original, eliminando los elementos duplicados.

export function removeDuplicateStrings(array) {
  return array.filter((value, index, self) => {
    // Retorna true solo si el índice de la primera aparición del elemento es igual al índice actual
    return self.indexOf(value) === index;
  });
}

//Descripción:
//Esta función toma un string como entrada y devuelve la parte del string que está antes del primer guion (-), eliminando //cualquier espacio en blanco al principio y al final.

//Parámetros:
//- str: El string del cual se desea obtener la parte antes del guion.

//Retorno:
//- Un nuevo string que contiene la parte del string original que está antes del primer guion, sin espacios en blanco al principio o al final.

export function getTextBeforeHyphen(str) {
  const indexGuion = str.indexOf("-");
  if (indexGuion !== -1) {
    return str.substring(0, indexGuion).trim();
  } else {
    return str.trim();
  }
}
