export const stepperData = (t) => {
  return {
    steps: [
      t("marketBasket.initialParameterization"),
      t("marketBasket.skuLevelMetrics"),
      t("marketBasket.destinyLevelMetrics"),
      t("marketBasket.chartsa"),
      t("marketBasket.indicators"),
    ],
  };
};

export const periodicityOptions = (t) => [
  { value: "mensual", label: t("general.monthly") },
  { value: "bimestral", label: t("general.bimonthly") },
  { value: "trimestral", label: t("general.quarterly") },
  { value: "no_consolidar", label: t("general.doNotConsolidate") },
];

export const periodicityLabels = (t) => {
  return {
    mensual: t("general.monthly"),
    bimestral: t("general.bimonthly"),
    trimestral: t("general.quarterly"),
    no_consolidar: t("general.doNotConsolidate"),
  };
};

export const objectiveTitles = (t) => [
  t("marketBasket.config.objectivesCollection"),
  t("marketBasket.config.columnTargetValue"),
  t("marketBasket.config.ownerIdentifierTarget"),
  t("marketBasket.config.targetColumnInAnalysis"),
  t("marketBasket.config.ownerIdentifierTargetInAnalysis"),
];

export const emptyMarketBasket = {
  id: undefined,
  nombre: "",
  archivo: undefined,
  archivos_merge: [],
  columna_SKU: undefined,
  canal: undefined,
  venta_moneda: undefined,
  venta_unidades: undefined,
  marca: undefined,
  nivel_de_analisis: undefined,
  periodicidad: undefined, //"mensual", "bimestral",  "trimestral", "no_consolidar"
  columna_transaccion: undefined, // Esta va solo si periodicidad === "no_consolidar"
  columnas_extra: [], //Solo cuando el archivo es tipo TEMPLATE
  parametros_avanzados: {
    soporte_minimo_apriori: 0.03,
    soporte_minimo_conjunto: 0.025,
    soporte_minimo_antecedente: 0.1,
    soporte_minimo_consecuente: 0.05,
    confianza_minima: 0.6,
    lift_minimo: 2.5,
  },
  objetivos: [],
  metricas_SKU_primarias: [],
  metricas_SKU_secundarias: [],
  metricas_destino_primarias: [],
  metricas_destino_secundarias: [],
  graficas: [],
  indicadores_destino: [],
};

type FunctionLabel = {
  [key: string]: string;
};

export const FUNCTION_LABELS: FunctionLabel = {
  SUMAR: "SUMAR",
  PROMEDIAR: "PROMEDIAR",
  MAXIMO: "MAXIMO",
  MINIMO: "MINIMO",
  CONTAR: "CONTAR",
  CONTAR_UNICO: "CONTAR VALORES UNICOS",
  PRIMERO: "PRIMERO",
  ULTIMO: "ULTIMO",
  VALORES_UNICOS: "VALORES UNICOS",
  DIVIDIR: "DIVISIÓN",
  "SUMA-PRODUCTO": "SUMA.PRODUCTO",
  MULTIPLICAR: "MULTIPLICAR",
};

export const NUMERIC_FUNCTIONS = [
  {
    value: "SUMAR",
    label: "SUMAR",
  },
  {
    value: "PROMEDIAR",
    label: "PROMEDIAR",
  },
  {
    value: "MAXIMO",
    label: "MAXIMO",
  },
  {
    value: "MINIMO",
    label: "MINIMO",
  },
  {
    value: "CONTAR",
    label: "CONTAR",
  },
  {
    value: "CONTAR_UNICO",
    label: "CONTAR VALORES UNICOS",
  },
  {
    value: "PRIMERO",
    label: "PRIMERO",
  },
  {
    value: "ULTIMO",
    label: "ULTIMO",
  },
  {
    value: "VALORES_UNICOS",
    label: "VALORES UNICOS",
  },
];

export const METRIC_FUNCTIONS = (t) => [
  {
    value: "SUMAR",
    label: t("general.add"),
  },
  {
    value: "PROMEDIAR",
    label: t("general.average"),
  },
  {
    value: "MULTIPLICAR",
    label: t("general.multiply"),
  },
  {
    value: "DIVIDIR",
    label: t("general.divide"),
  },

  {
    value: "CONTAR",
    label: t("general.count"),
  },
  {
    value: "CONTAR_UNICO",
    label: t("general.uniqueCount"),
  },
];

export const CONS_PERIODS_FUNCTIONS = (t) => [
  {
    value: "SUMAR",
    label: t("general.add"),
  },
  {
    value: "PROMEDIAR",
    label: t("general.average"),
  },
  {
    value: "MAXIMO",
    label: t("general.maximum"),
  },
  {
    value: "ULTIMO",
    label: t("general.last"),
  },
];

export const CONS_DESTINY_FUNCTIONS = (t) => [
  {
    value: "SUMAR",
    label: t("general.add"),
  },
  {
    value: "PROMEDIAR",
    label: t("general.average"),
  },
  {
    value: "MAXIMO",
    label: t("general.maximum"),
  },
];

export const CHART_FUNCTIONS = (t) => [
  {
    value: "SUMAR",
    label: t("general.add"),
  },
  {
    value: "PROMEDIAR",
    label: t("general.average"),
  },
];

export const UNIT_OPTIONS = (t) => [
  {
    value: "unidades",
    label: t("general.units"),
  },
  {
    value: "moneda",
    label: t("general.currency"),
  },
  {
    value: "porcentaje",
    label: t("general.percentage"),
  },
];
