import { Typography } from "@mui/material";
import { useState } from "react";
import BarInfiniteDrilldownChart from "./BarInfiniteDrilldownChart";
import Boxplot from "./Boxplot";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinesChart from "./LinesChart";
import PieDrilldownChart from "./PieDrilldownChart";
import Stack from "@mui/material/Stack";
import TreemapNewDrilldownChart from "./TreemapNewDrilldownChart";

type ChartCardProps = {
  chart: any;
};

const ChartCard = ({ chart }: ChartCardProps) => {
  const [minimized, setMinimized] = useState(false);

  return (
    <Stack
    alignItems= "center"
      sx={{
        width: "100%",
        backgroundColor: "var(--bg-light)",
        transition: "all 0.9s",
        height: !minimized ? "500px" : "0px",        
        padding: "10px 0px 40px 0px",
        marginBottom: "15px",
        boxShadow: "0px 18px 10px -10px rgb(0 0 0 / 30%)",
        borderRadius: "10px",
      }}
      key={chart.titulo_grafico}
    >
      <Stack     
        flexDirection= "row"
        justifyContent= "center"   
      >   
          <Typography
            variant="subtitle1"
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "1.2rem",
              color: "var(--text-main)",
              marginBottom: "10px",
              flex: 1.1,
              alignItems: "center",
            }}
          >
            {chart.titulo_grafico}
            {minimized ? (
              <ExpandMoreIcon
                sx={{
                  color: "var(--icon-main)",
                  fontSize: "28px",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => setMinimized(false)}
              />
            ) : (
              <ExpandLessIcon
                sx={{
                  color: "var(--icon-main)",
                  fontSize: "28px",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => setMinimized(true)}
              />
            )}
          </Typography>     
      </Stack>
      <Stack sx={{ width: "95%" }}>
        {chart.tipo === "BARRAS CON DRILLDOWN" && !minimized && (
          <BarInfiniteDrilldownChart
            key={chart.titulo_grafico}
            results={chart}
            stack={true}
          />
        )}
        {chart.tipo === "LINEAS" && !minimized && (
          <LinesChart key={chart.titulo_grafico} results={chart} />
        )}
        {chart.tipo === "TREEMAP CON DRILLDOWN" && !minimized && (
          <TreemapNewDrilldownChart key={chart.titulo_grafico} results={chart} />
        )}
        {chart.tipo === "TORTA CON DRILLDOWN" && !minimized && (
          <PieDrilldownChart key={chart.titulo_grafico} results={chart} />
        )}
        {chart.tipo === "BOXPLOT" && !minimized && (
          <Boxplot key={chart.titulo_grafico} results={chart} />
        )}

      </Stack>
    </Stack>
  );
};

export default ChartCard;
