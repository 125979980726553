export type LevelElement = {
  id: number;
  nombre: string;
  orden: number;
  vinculo: string | undefined;
};

export const stepperData = (t) => {
  return {
    steps: [
      t("companyParam.stepsTitles.step0"),
      t("companyParam.stepsTitles.step1"),
      t("companyParam.stepsTitles.step2"),
      t("companyParam.stepsTitles.step3"),
      t("companyParam.stepsTitles.step4"),
      t("companyParam.stepsTitles.step5"),
      t("companyParam.stepsTitles.step6"),
      t("companyParam.stepsTitles.step7"),
    ],
  };
};

export const columnsSet = [
  {
    inputLabel: "Monto gasto",
    columnKey: "monto_gasto",
  },
  {
    inputLabel: "Bucket",
    columnKey: "bucket",
  },
  {
    inputLabel: "Agrupador",
    columnKey: "agrupador",
  },
  /* {
    inputLabel: "Línea P&L (opcional)",
    optional: true,
    columnKey: "linea_pnl",
  }, */
];

export const columnsSetTransactional = [
  { inputLabel: "*Venta Bruta", columnKey: "venta_bruta" },
  { inputLabel: "Venta Kg", columnKey: "venta_kg", optional: true },
  { inputLabel: "Venta M3", columnKey: "venta_m3", optional: true },
  {
    inputLabel: "Venta Unidades",
    optional: true,
    columnKey: "venta_unidades",
  },
  { inputLabel: "Venta Cajas", optional: true, columnKey: "venta_cajas" },
  { inputLabel: "Venta Tarimas", optional: true, columnKey: "venta_tarimas" },
  { inputLabel: "COGS", optional: true, columnKey: "cogs" },
  { inputLabel: "Descuentos", optional: true, columnKey: "descuentos" },
  { inputLabel: "Devoluciones", optional: true, columnKey: "devoluciones" },
];

export const columnsSetDescriptive = [
  {
    inputLabel: "Código cliente",
    columnKey: "codigo_cliente",
    optional: true,
  },
  { inputLabel: "Código ruta", columnKey: "codigo_ruta", optional: true },
  {
    inputLabel: "Código producto (SKU)",
    columnKey: "codigo_producto",
    optional: true,
  },
  {
    inputLabel: "Categoría de producto",
    columnKey: "categoria_producto",
    optional: true,
  },
  {
    inputLabel: "Marca",
    columnKey: "marca",
    optional: true,
  },
  {
    inputLabel: "Unidad de negocio",
    columnKey: "unidad_negocio",
    optional: true,
  },
  {
    inputLabel: "Canal",
    columnKey: "canal",
    optional: true,
  },
  {
    inputLabel: "Modelo de atención",
    columnKey: "modelo_atencion",
    optional: true,
  },
  {
    inputLabel: "Tipo de ruta",
    columnKey: "tipo_ruta",
    optional: true,
  },
  {
    inputLabel: "Fecha",
    columnKey: "fecha",
    optional: true,
  },
];

export const columnsTypeOptions = [
  { value: "numerica", label: "Numérica" },
  { value: "texto", label: "Texto" },
  { value: "fecha", label: "Fecha" },
  { value: "booleano", label: "Booleano" },
];

export const monthOptions = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2014 }, (_, index) => {
    const yearValue = 2015 + index;
    return { value: yearValue, label: String(yearValue) };
  });

  return years;
};

export type ValueLabel = { value: number; label: string };
export type PnlElementType = {
  id: number;
  nombre: string;
  orden: number;
  tipo: "INGRESO" | "EGRESO" | "RESULTADO";
  subelementos: PnlElementType[] | undefined;
  nivel: number;
  columnas: ValueLabel[] | undefined;
  archivo: "GASTOS" | "VENTAS" | undefined;
  buckets: ValueLabel[] | undefined;
  agrupadores: ValueLabel[] | undefined;
  padre?: number;
  checked?: boolean;
};

export const typeOptions = [
  {
    value: "INGRESO",
    label: "INGRESO",
  },
  {
    value: "EGRESO",
    label: "EGRESO",
  },
  {
    value: "RESULTADO",
    label: "RESULTADO",
  },
];

export const groupOptions = [
  {
    value: 1,
    label: "Agrupador 1",
  },
];

export const flowTypeOptions = [
  {
    value: "CATEGORIA",
    label: "CATEGORIA",
  },
  {
    value: "COLUMNAS",
    label: "COLUMNAS",
  },
];

export const fileOptions = [
  {
    value: "GASTOS",
    label: "GASTOS",
  },
  {
    value: "VENTAS",
    label: "VENTAS",
  },
];

export const fakeLevelsData = [
  {
    id: 1,
    nombre: "P&L Line hfjk hjhjn hfuhdnfjdnj",
    orden: 1,
    vinculo: undefined,
  },
  { id: 2, nombre: "Activities", orden: 2, vinculo: undefined },
  { id: 3, nombre: "Activities 2", orden: 3, vinculo: undefined },
  { id: 4, nombre: "Activities 3", orden: 4, vinculo: undefined },
  { id: 5, nombre: "Buckets", orden: 5, vinculo: "BUCKET" },
  {
    id: 6,
    nombre: "Agrupadoresgftgdfhgfhgfhhthty",
    orden: 6,
    vinculo: "AGRUPADOR",
  },
];

const fakePnlElementData: PnlElementType[] = [
  {
    id: 1,
    nombre: "GROSS SALES",
    orden: 1,
    tipo: "INGRESO",
    subelementos: [
      {
        id: 8,
        nombre: "subelemento1",
        orden: 1,
        tipo: "INGRESO",
        subelementos: [
          {
            id: 80,
            nombre: "subsubelemento1",
            orden: 1,
            tipo: "INGRESO",
            subelementos: undefined,
            nivel: 3,
            columnas: undefined,
            archivo: "GASTOS",
            buckets: undefined,
            agrupadores: undefined,
            padre: 8,
          },
        ],
        nivel: 2,
        columnas: undefined,
        archivo: "GASTOS",
        buckets: undefined,
        agrupadores: undefined,
        padre: 1,
      },
      {
        id: 9,
        nombre: "subelemento2",
        orden: 1,
        tipo: "INGRESO",
        subelementos: [
          {
            id: 90,
            nombre: "subsubelemento2",
            orden: 1,
            tipo: "INGRESO",
            subelementos: undefined,
            nivel: 3,
            columnas: undefined,
            archivo: "GASTOS",
            buckets: undefined,
            agrupadores: undefined,
            padre: 9,
          },
        ],
        nivel: 2,
        columnas: undefined,
        archivo: "GASTOS",
        buckets: undefined,
        agrupadores: undefined,
        padre: 1,
      },
    ],
    nivel: 1,
    columnas: undefined,
    archivo: "GASTOS",
    buckets: undefined,
    agrupadores: undefined,
    padre: undefined,
  },
  {
    id: 2,
    nombre: "Discounts",
    orden: 2,
    tipo: "EGRESO",
    subelementos: [
      {
        id: 106,
        nombre: "sub Discounts",
        orden: 2,
        tipo: "EGRESO",
        subelementos: undefined,
        nivel: 2,
        columnas: undefined,
        archivo: "GASTOS",
        buckets: undefined,
        agrupadores: undefined,
        padre: 2,
      },
    ],
    nivel: 1,
    columnas: undefined,
    archivo: "GASTOS",
    buckets: undefined,
    agrupadores: undefined,
    padre: undefined,
  },
  {
    id: 3,
    nombre: "GROSS PROFIT",
    orden: 3,
    tipo: "RESULTADO",
    subelementos: undefined,
    nivel: 1,
    columnas: undefined,
    archivo: undefined,
    buckets: undefined,
    agrupadores: undefined,
  },
  {
    id: 4,
    nombre: "COGS",
    orden: 4,
    tipo: "RESULTADO",
    subelementos: undefined,
    nivel: 1,
    columnas: undefined,
    archivo: "GASTOS",
    buckets: undefined,
    agrupadores: undefined,
  },
  {
    id: 5,
    nombre: "GROSS MARGIN",
    orden: 5,
    tipo: "RESULTADO",
    subelementos: undefined,
    nivel: 1,
    columnas: undefined,
    archivo: undefined,
    buckets: undefined,
    agrupadores: undefined,
  },
  {
    id: 6,
    nombre: "S&D",
    orden: 6,
    tipo: "EGRESO",
    subelementos: undefined,
    nivel: 1,
    columnas: undefined,
    archivo: "GASTOS",
    buckets: [
      { value: 2, label: "bucket1" },
      { value: 7, label: "bucket7" },
    ],
    agrupadores: undefined,
  },
  {
    id: 7,
    nombre: "PROFIT TO SERVE",
    orden: 7,
    tipo: "EGRESO",
    subelementos: undefined,
    nivel: 6,
    columnas: [
      { value: 1, label: "col1" },
      { value: 6, label: "col6" },
    ],
    archivo: "GASTOS",
    buckets: undefined,
    agrupadores: [
      { value: 1, label: "agr1" },
      { value: 8, label: "agr8" },
    ],
  },
];

export const fakeDataGetGroupers = [
  { value: 1, label: "Bucket 1", agrupadores: [{ value: 1, label: "agr1" }] },
  { value: 2, label: "Bucket 2", agrupadores: [{ value: 2, label: "agr2" }] },
  {
    value: 3,
    label: "Bucket 3",
    agrupadores: [
      { value: 3, label: "agr3" },
      { value: 1, label: "agr1" },
    ],
  },
  {
    value: 4,
    label: "Bucket 4",
    agrupadores: [
      { value: 4, label: "agr4" },
      { value: 1, label: "agr1" },
      { value: 2, label: "agr2" },
    ],
  },
];
