import {
  Accordion,
  AccordionSummary,
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import { truncateString } from "../../parameterization/utils";
import AccordionFiles from "./AccordionFiles";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { roundNumber } from "../../delta_phi/utils";

export const handleSelectFiles = (
  archivo,
  selectedFilesPaths,
  setSelectedFilesPaths,
  selectedFolder,
  parentPath
) => {
  const archivo_ruta = {
    ubicacion: archivo.ubicacion + archivo.nombre,
    tipo: archivo.tipo,
    carpetaPadre: archivo.ubicacion,
  };
  const isSelected = selectedFilesPaths?.some(
    (file) => file.ubicacion === archivo_ruta.ubicacion
  );
  let newSelectedFilesPaths;
  if (isSelected) {
    // Deseleccionar archivo
    if (selectedFolder) {
      newSelectedFilesPaths = selectedFilesPaths?.filter(
        (file) =>
          file.ubicacion !== parentPath &&
          file.ubicacion !== archivo_ruta.ubicacion
      );
    } else {
      newSelectedFilesPaths = selectedFilesPaths?.filter(
        (file) => file.ubicacion !== archivo_ruta.ubicacion
      );
    }
  } else {
    // Seleccionar archivo
    newSelectedFilesPaths = [...new Set([...selectedFilesPaths, archivo_ruta])];
  }
  setSelectedFilesPaths(newSelectedFilesPaths);
};

// Función para manejar la selección de carpetas
const handleSelectFolders = (
  itemPath,
  selectedFilesPaths,
  setSelectedFilesPaths,
  selectedFolder,
  setSelectedFolder
) => {
  const folderPath = { ubicacion: itemPath, tipo: "carpeta" };
  if (
    selectedFilesPaths.some((file) => file.ubicacion === folderPath.ubicacion)
  ) {
    setSelectedFilesPaths(
      selectedFilesPaths.filter(
        (path) => !path.ubicacion.startsWith(`${itemPath}`)
      )
    );
  } else {
    setSelectedFilesPaths([...new Set([...selectedFilesPaths, folderPath])]);
  }
  setSelectedFolder(!selectedFolder);
};

export const renderArchivos = (
  archivos,
  selectedFilesPaths,
  setSelectedFilesPaths,
  t,
  selectedFolder,
  parentPath
) =>
  archivos.map((archivo) => {
    const archivoRuta = {
      ubicacion: `${archivo.ubicacion}${archivo.nombre}`,
      tipo: archivo.tipo,
    };
    const selectedFile = selectedFilesPaths?.some(
      (file) => file.ubicacion === archivoRuta.ubicacion
    );
    return (
      <ListItem
        key={archivoRuta.ubicacion}
        dense
        sx={{
          backgroundColor: selectedFile ? "var(--bg-main)" : "",
          cursor: "pointer",
          "& .MuiListItemText-root, & .MuiSvgIcon-root": {
            color: selectedFile ? "white" : "",
            fontWeight: selectedFile ? "bold" : "",
          },
          ", & .MuiTypography-body2": {
            color: selectedFile ? "white" : "",
          },
          "&:hover": {
            backgroundColor: "var(--bg-secondary)",
            "& .MuiListItemText-root, & .MuiSvgIcon-root, & .MuiTypography-root":
              {
                color: "white",
                fontWeight: "bold",
              },
            ", & .MuiTypography-body2": {
              color: selectedFile ? "white" : "",
              fontWeight: "bold",
            },
            "& .MuiCheckbox-root": {
              "& svg": { stroke: "white" },
            },
            "& .MuiListItemAvatar-root .MuiAvatar-root": {
              backgroundColor: selectedFile
                ? "var(--bg-secondary) !important"
                : "",
            },
          },
        }}
        onClick={() =>
          handleSelectFiles(
            archivo,
            selectedFilesPaths,
            setSelectedFilesPaths,
            selectedFolder,
            parentPath
          )
        }
      >
        <Tooltip
          placement="bottom"
          title={
            archivo.importado &&
            t(
              "dataManagementTexts.uploadModalContent.fileAlreadyExistsRestatedCalculation"
            )
          }
        >
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor:
                  selectedFile && archivo.importado
                    ? "var(--bg-main) !important"
                    : archivo.importado
                    ? "var(--light-yellow) !important"
                    : selectedFile
                    ? "var(--bg-main) !important"
                    : "var(--bg-disabled) !important",
              }}
            >
              <InsertDriveFileIcon
                sx={{
                  color:
                    selectedFile && archivo.importado
                      ? "var(--light-yellow) !important"
                      : "",
                }}
              />
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={archivo.nombre.length > 40 && archivo.nombre}
        >
          <ListItemText
            primary={truncateString(archivo.nombre, 40)}
            sx={{
              color: selectedFile ? "white" : "var(--text-main) !important",
              fontWeight: "bold",
            }}
            secondary={roundNumber(archivo.peso * 1024 * 1024, 4) + " bytes"}
          />
        </Tooltip>
        <ListItemIcon>
          <Checkbox
            sx={{
              color: selectedFile ? "white" : "",
              "& svg": { stroke: selectedFile ? "white" : "" },
            }}
            inputProps={{
              "aria-labelledby": archivo.ruta,
            }}
            checked={selectedFile}
          />
        </ListItemIcon>
      </ListItem>
    );
  });

export const renderFilesFolder = (
  data,
  selectedFilesPaths,
  setSelectedFilesPaths,
  t,
  parentPath?
) =>
  data &&
  data.map((item) => {
    const folderPath = `${item.ubicacion}${item.nombre}`;
    return (
      <AccordionFiles key={folderPath}>
        {({
          dataInfoDatalake,
          isLoadingDataInfoDatalake,
          getDataInfoDataLake,
          selectedFolder,
          setSelectedFolder,
        }) => {
          const [expanded, setExpanded] = useState(false);

          const [allChildrenSelected, setAllChildrenSelected] = useState(false);

          const files =
            (dataInfoDatalake &&
              dataInfoDatalake.filter(
                (archivo) => archivo.tipo === "archivo"
              )) ||
            [];
          const folders =
            (dataInfoDatalake &&
              dataInfoDatalake.filter(
                (archivo) => archivo.tipo === "carpeta"
              )) ||
            [];

          useEffect(() => {
            setSelectedFolder(
              !!selectedFilesPaths.find((file) => file.ubicacion === folderPath)
            );
          }, [selectedFilesPaths]);

          useEffect(() => {
            if (
              dataInfoDatalake &&
              dataInfoDatalake.length > 0 &&
              selectedFilesPaths
            ) {
              const allFilesSelected = dataInfoDatalake.every((file) =>
                selectedFilesPaths
                  ?.map((file) => file.ubicacion)
                  ?.includes(`${file.ubicacion}${file.nombre}`)
              );
              setAllChildrenSelected(allFilesSelected);
            }
          }, [dataInfoDatalake, selectedFilesPaths]);

          useEffect(() => {
            if (allChildrenSelected) {
              if (
                !selectedFilesPaths.find(
                  (file) => file.ubicacion === folderPath
                )
              ) {
                setSelectedFilesPaths([
                  ...new Set([
                    ...selectedFilesPaths,
                    { ubicacion: folderPath, tipo: item.tipo },
                  ]),
                ]);
              }
            } else {
              if (
                selectedFilesPaths.find((file) => file.ubicacion !== folderPath)
              ) {
                setSelectedFilesPaths(
                  selectedFilesPaths.filter(
                    (file) => file.ubicacion !== folderPath
                  )
                );
              }
              setSelectedFolder(false);
            }
          }, [allChildrenSelected]);

          useEffect(() => {
            if (
              dataInfoDatalake &&
              dataInfoDatalake.length > 0 &&
              selectedFolder
            ) {
              const newSelectedFiles = dataInfoDatalake.map((data) => {
                if (data.tipo === "archivo") {
                  return {
                    ubicacion: data.ubicacion + data.nombre,
                    tipo: data.tipo,
                    carpetaPadre: data.ubicacion,
                  };
                } else {
                  return {
                    ubicacion: data.ubicacion + data.nombre,
                    tipo: data.tipo,
                  };
                }
              });
              // Filtrar los archivos que ya existen en selectedFilesPaths
              const filteredNewSelectedFiles = newSelectedFiles.filter(
                (newFile) =>
                  !selectedFilesPaths.some(
                    (existingFile) =>
                      existingFile.ubicacion === newFile.ubicacion &&
                      existingFile.tipo === newFile.tipo
                  )
              );
              if (filteredNewSelectedFiles.length > 0) {
                setSelectedFilesPaths((prevSelectedFilesPaths) => [
                  ...new Set([
                    ...prevSelectedFilesPaths,
                    ...filteredNewSelectedFiles,
                  ]),
                ]);
              }
            }
          }, [dataInfoDatalake, selectedFolder]);

          const handleExpandClick = (event) => {
            event.stopPropagation();
            if (!expanded) {
              setExpanded(true);
              getDataInfoDataLake(`archivo/ver_datalake?path=${folderPath}/`);
            } else {
              setExpanded(false);
            }
          };

          return (
            <Stack
              sx={{
                padding: "2px",
                minWidth: "600px",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <Accordion
                expanded={expanded}
                sx={{
                  backgroundColor: selectedFolder ? "var(--bg-main)" : "",
                  "& .MuiListItemText-root, & .MuiSvgIcon-root": {
                    color: selectedFolder ? "white" : "",
                  },
                  // ml: parentPath ? "15px" : "",
                  p: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      onClick={(e) => {
                        handleExpandClick(e);
                      }}
                    />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    "&:hover": {
                      backgroundColor: "var(--bg-secondary)",
                      "& .MuiListItemText-root, & .MuiSvgIcon-root, & .MuiTypography-root":
                        {
                          color: "white",
                        },
                      "& .MuiCheckbox-root": {
                        "& svg": { stroke: "white" },
                      },
                      "& .MuiAvatar-root": {
                        backgroundColor: isLoadingDataInfoDatalake
                          ? "white !important"
                          : "",
                        "& .MuiSvgIcon-root": {
                          color: isLoadingDataInfoDatalake
                            ? "var(--bg-secondary)"
                            : "",
                        },
                      },
                    },
                    // ml: parentPath ? "15px" : ""
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: selectedFolder
                          ? "var(--bg-main) !important"
                          : isLoadingDataInfoDatalake
                          ? "white !important"
                          : "var(--bg-disabled) !important",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {isLoadingDataInfoDatalake ? (
                        <CircularProgress
                          sx={{
                            color: selectedFolder
                              ? "white"
                              : "var(--bg-secondary) !important",
                          }}
                        />
                      ) : (
                        <FolderIcon />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedFolder ? "white" : "var(--text-info)",
                        fontWeight: "bold",
                        flexGrow: 1,
                      }}
                    >
                      {item.nombre}
                    </Typography>
                    <Checkbox
                      sx={{
                        color: selectedFolder ? "white" : "",
                        "& svg": { stroke: selectedFolder ? "white" : "" },
                      }}
                      checked={selectedFolder}
                      onChange={() => {
                        handleSelectFolders(
                          folderPath,
                          selectedFilesPaths,
                          setSelectedFilesPaths,
                          selectedFolder,
                          setSelectedFolder
                        );
                      }}
                    />
                  </Stack>
                </AccordionSummary>
                {dataInfoDatalake &&
                  dataInfoDatalake.filter(
                    (archivo) => archivo.tipo === "carpeta"
                  ) &&
                  renderFilesFolder(
                    folders,
                    selectedFilesPaths,
                    setSelectedFilesPaths,
                    t,
                    folderPath
                  )}
                {dataInfoDatalake &&
                  dataInfoDatalake.filter(
                    (archivo) => archivo.tipo === "archivo"
                  ) &&
                  renderArchivos(
                    files,
                    selectedFilesPaths,
                    setSelectedFilesPaths,
                    t,
                    selectedFolder,
                    folderPath
                  )}
                {dataInfoDatalake && dataInfoDatalake.length === 0 && (
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedFolder ? "white" : "var(--text-info)",
                        fontWeight: "bold",
                        flexGrow: 1,
                      }}
                    >
                      {t("dataManagementTexts.alphaImportTexts.noFilesToImport").toUpperCase()}
                    </Typography>
                  </Stack>
                )}
              </Accordion>
            </Stack>
          );
        }}
      </AccordionFiles>
    );
  });
