import { Button as CustomButtom } from "../../buttons";
import { CustomCheckboxExpenses } from "../../dataModel/measureManagement/configMeasure/ConfigExpenses/CustomCheckboxExpenses";
import { PRORRATEO_GASTOS_URL } from "../../../api/axios";
import { stages_settings_table_styles } from "../../../styles/app-styles";
import { useState, useRef } from "react";
import CustomCheckbox from "../Calculation/table/CustomCheckbox";
import CustomInput from "../../forms/CustomInput";
import CustomizedDialogs from "../../dialog/Dialog";
import CustomNoRowsOverlay from "../../tablesTools/NoRows";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  getBucketIdByRowId,
  getDestinyTypeByRowId,
  getGrouperIdByRowId,
  handleGrouperSelection,
  isBucketWithStage,
  prepareEditData,
} from "../utils";
import {
  DataGridPro,
  GridSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  getColumns,
  getTreeDataPath,
  getGroupingColDef,
} from "./columnsTreeData";
import useInfoContext from "../../../hooks/useInfoContext";

export type ProrationRowType = {
  hierarchy: string[];
  agrupador: string;
  monto: number;
  etapas: string;
  id: number;
  bucket_gasto_id: number;
  agrupador_id: number;
};
interface ITreeDataCustomGroupingColumn {
  selectionModel: GridSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  rows: any;
  errorPreprorrationExpenses: any;
  refetch: () => void;
  isExpanded: boolean;
  handleExpand: () => void;
  addActions?: boolean;
  onCellClick?: (param: any) => void;
  originalSelectionModel?: any[];
  selectAllRowsMeasure?: (string: string) => void;
}

export default function TreeDataCustomGroupingColumn({
  selectionModel,
  setSelectionModel,
  rows,
  errorPreprorrationExpenses,
  refetch,
  isExpanded,
  handleExpand,
  addActions,
  onCellClick,
  originalSelectionModel,
  selectAllRowsMeasure,
}: ITreeDataCustomGroupingColumn) {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
  const [newName, setNewName] = useState<string | number>("");
  const [openEditBucketDialog, handleToggleEditBucketDialog] = useDialog();
  const [selectedBuckets, setSelectedBuckets] = useState<any[]>([]);
  const apiRef = useGridApiRef();
  const newNameRef = useRef<HTMLInputElement>(null);

  /****************************** Manejo de selección de filas *******************************/

  const handleSelection = (newSelectionModel: any) => {
    if (rows?.length) {
      const ids = new Set(newSelectionModel);
      const selectedRowData = rows.filter((row: any) => ids.has(row["id"]));
      handleGrouperSelection(
        selectedRowData,
        selectedBuckets,
        rows,
        setSelectedBuckets,
        setSelectionModel
      );
    }
  };

  const selectAllRows = (selectType: string) => {
    const bucketsWithStages: string[] = [];

    rows.forEach((row: any) => {
      if (row.etapas) {
        bucketsWithStages.push(row.hierarchy[0]);
      }
    });

    const selectedRows: number[] = [];
    rows.forEach((row: any) => {
      if (
        selectType ===
        t("modelParameterizationTexts.treeDataCustomGroupingColumn.all")
      ) {
        selectedRows.push(row.id);
      } else if (
        selectType ===
        t("modelParameterizationTexts.treeDataCustomGroupingColumn.noRows")
      ) {
        if (!row.etapas) {
          if (row.hierarchy[1]) {
            selectedRows.push(row.id);
          } else {
            if (!isBucketWithStage(row, bucketsWithStages)) {
              selectedRows.push(row.id);
            }
          }
        }
      }
    });
    setSelectionModel(selectedRows);
  };

  const handleSelectAllRowsOriginal = (string: string) => {
    selectAllRowsMeasure && selectAllRowsMeasure(string);
  };

  /******************************** Editar nombre *******************************/

  const handleOpenEditDialog = (id: number, value: string) => {
    setNewName(value);
    setIdToEdit(id);
    handleToggleEditBucketDialog();
  };

  const onSuccessPutGastosProrrateo = () => {
    setSelectionModel([]);
    setNewName("");
    refetch();
  };

  const onErrorPutGastosProrrateo = () => {
    setSelectionModel([]);
    setNewName("");
  };

  const { isLoading: editNameLoading, callApi: putGastosProrrateo } = useApi(
    PRORRATEO_GASTOS_URL,
    "PUT",
    t("stagesSettings.editName.codes"),
    undefined,
    onSuccessPutGastosProrrateo,
    onErrorPutGastosProrrateo
  );

  const handleEditName = () => {
    handleToggleEditBucketDialog();
    rows &&
      putGastosProrrateo(
        PRORRATEO_GASTOS_URL,
        prepareEditData(
          getDestinyTypeByRowId(idToEdit, rows),
          getBucketIdByRowId(idToEdit, rows),
          getGrouperIdByRowId(idToEdit, rows),
          newName
        )
      );
  };

  /************************************* Render **************************************/  

  const showError = errorPreprorrationExpenses && (
    <p>
      {t(
        "modelParameterizationTexts.treeDataCustomGroupingColumn.errorNotPossibleToDownload"
      )}
      .
    </p>
  );

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      {showError}
      <DataGridPro
        components={{
          BaseCheckbox: addActions ? CustomCheckboxExpenses : CustomCheckbox,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{
          baseCheckbox: {
            selectallrows: addActions
              ? { handleSelectAllRowsOriginal }
              : { selectAllRows },
          },
        }}
        onCellClick={onCellClick ? (e) => onCellClick(e) : undefined}
        checkboxSelection
        disableSelectionOnClick
        treeData
        apiRef={apiRef}
        rows={rows ? rows : []}
        columns={getColumns(handleOpenEditDialog, rows, userRolData, addActions)}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={getGroupingColDef(
          handleOpenEditDialog,
          handleExpand,
          isExpanded,
          rows,
          addActions
        )}
        hideFooter
        onSelectionModelChange={(newSelectionModel) => {
          handleSelection(newSelectionModel);
        }}
        selectionModel={
          originalSelectionModel ? originalSelectionModel : selectionModel
        }
        disableVirtualization
        sx={stages_settings_table_styles}
      />
      <CustomizedDialogs
        open={addActions ? false : openEditBucketDialog}
        handleClose={handleToggleEditBucketDialog}
        title={t(
          "modelParameterizationTexts.treeDataCustomGroupingColumn.editName"
        )}
        maxWidth="sm"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomInput
            value={newName}
            setValue={setNewName}
            inputRef={newNameRef}
            placeholder={t(
              "modelParameterizationTexts.treeDataCustomGroupingColumn.newName"
            )}
            maxLength={100}
            required={false}
            type="text"
            autoFocus={true}
          />
          <CustomButtom
            title={t(
              "modelParameterizationTexts.treeDataCustomGroupingColumn.apply"
            )}
            color="blue-greeny"
            type="button"
            onClick={() => handleEditName()}
            disabled={!newName}
          />
        </div>
        <div style={{ width: "80%" }}>
          <p style={{ fontSize: "14px", color: "var(--text-main)" }}>
            *
            {t(
              "modelParameterizationTexts.treeDataCustomGroupingColumn.unifiedBucketsGroupers"
            )}
            .
          </p>
        </div>
      </CustomizedDialogs>
      <SimpleBackdrop
        open={editNameLoading}
        message={t(
          "modelParameterizationTexts.treeDataCustomGroupingColumn.editingName"
        )}
      />
    </div>
  );
}
