import { Tooltip } from "@mui/material";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { truncateString } from "../../parameterization/utils";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizedTable from "../../virtualizatedTable/VirtualizedTable";
import {
  card_subtitle_styles,
  card_title_styles,
} from "../../../styles/app-styles";

const TypeColumnsCard = ({ item, showInfo }: any) => {
  const { t } = useLanguage();
  const dataGetColumnsTypesParams = item.data;
  const explainText = (theKey, value) => {
    switch (theKey) {
      case "tipo":
        return value;
      case "valores_nulos":
        return value ? "Permite valores nulos" : "No permite valores nulos";
      case "requerida":
        return value ? "Columna requerida" : "Columna opcional";
      case "reemplazar":
        return value
          ? `Reemplazar valores no numéricos por ${value}`
          : "No reemplazar valores no numéricos";
      case "valores_unicos":
        return value ? "Sólo valores únicos" : "Valores repetidos permitidos";
      default:
        return "";
    }
  };

  const tranformObjectToTable = (file, columnas, theKey) => {
    const rowObject: any = {
      id: theKey + "-" + file.nombre,
    };
    columnas.forEach((col) => {
      const selectedColumn = file?.columnas?.find((c) => c.label === col);
      if (selectedColumn) {
        rowObject[col] = explainText(theKey, selectedColumn[theKey]);
      }
    });
    return rowObject;
  };

  const prepareColumns = (file) => {
    const COLUMNS1 = file.columnas.map((col) => col.label).filter((col) => col !== "id");
    return COLUMNS1;
  };

  const resumeRows = (file) => {
    const columns = prepareColumns(file);
    const allRows: any[] = [];
    allRows.push(tranformObjectToTable(file, columns, "tipo"));
    allRows.push(tranformObjectToTable(file, columns, "valores_nulos"));
    allRows.push(tranformObjectToTable(file, columns, "requerida"));
    allRows.push(tranformObjectToTable(file, columns, "reemplazar"));
    allRows.push(tranformObjectToTable(file, columns, "valores_unicos"));
    return allRows;
  };

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        <Stack
          alignItems="center"
          sx={{ wordBreak: "break-word", display: "inline" }}
        >
          <Tooltip title={item.title}>
            <Typography sx={card_title_styles}>
              {truncateString(item.title, 100)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "6px 0" }} />
        {showInfo && (
          <>
            {dataGetColumnsTypesParams?.map((file, index) => {
              return (
                <Typography
                  key={index}
                  variant="subtitle2"
                  sx={card_subtitle_styles}
                >
                  <b>{file.nombre}</b>
                  <br />
                  <Stack width={`100%`}>
                    <VirtualizedTable
                      columns={
                        transformDataSimpleTable(
                          resumeRows(file),
                          undefined,
                          undefined,
                          t,
                          280
                        ).columns
                      }
                      rows={
                        transformDataSimpleTable(
                          resumeRows(file),
                          undefined,
                          undefined,
                          t,
                          280
                        ).rows
                      }
                      rowsPerPageOptions={[5, 10, 20]}
                      rowHeight={25}
                      tableHeight={"190px"}
                      hideFooter={true}
                      toolbar={false}
                      columnsButton={false}
                      filterButton={false}
                      exportButton={false}
                      checkboxSelection={false}
                      setSelectedRows={() => <></>}
                      headerHeight={25}
                    />
                  </Stack>
                  <br />
                </Typography>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};

export default TypeColumnsCard;
