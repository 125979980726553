import Typography from "@mui/material/Typography";
import { checkRowsWithoutStages } from "../utils";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const FormattedRows = (rows: any, t: any) => {
    const formatedRows =
      rows &&
      rows?.map((row: any) => {
        if (row.hierarchy.length === 1) {
          if (checkRowsWithoutStages(rows).includes(row.hierarchy[0])) {
            row.etapas = (
              <Typography
                component={"span"}
                sx={{
                  color: "var(--red)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {t("modelParameterizationTexts.formattedRows.missingAssingStages")}
                <CloseIcon sx={{ color: "var(--red)", marginLeft: "1px" }} />
              </Typography>
            );
          } else {
            row.etapas = (
              <Typography
                component={"span"}
                sx={{
                  color: "var(--text-success)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {t("modelParameterizationTexts.formattedRows.assignStages")}
                <CheckIcon
                  sx={{
                    color: "var(--text-success)",
                    marginLeft: "4px",
                    marginBottom: "3px",
                  }}
                />
              </Typography>
            );
          }
          return row;
        } else {
          return row;
        }
      });
    return formatedRows;
  };

  export default FormattedRows;