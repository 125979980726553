import { grid_container2_styles } from "../../../../styles/app-styles";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import FormLayout from "../../../forms/FormLayout";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../Shared/GridTitles";
import NoLinesComponent from "../../../forms/NoLinesComponent";
import PrimaryMetricsRow from "../Shared/PrimaryMetricsRow";
import PrimaryMetricsSelects from "../Shared/PrimaryMetricsSelects";
import useLanguage from "../../../../context/LanguageProvider";

const Step2Form = () => {
  const { t } = useLanguage();
  const {
    marketBasketElement,
    setMarketBasketElement,
    setMessage,
    totalColumnOptions,
    column1SKUSelectRef,
    column2SKUSelectRef,
    column3SKUSelectRef,
    column4SKUSelectRef,
    column5SKUSelectRef,
    column1SKUSelectedOption,
    setColumn1SKUSelectedOption,
    column2SKUSelectedOption,
    setColumn2SKUSelectedOption,
    column2SKUOptionsChangeHandler,
    column3SKUSelectedOption,
    setColumn3SKUSelectedOption,
    column3SKUOptionsChangeHandler,
    column4SKUSelectedOption,
    setColumn4SKUSelectedOption,
    column4SKUOptionsChangeHandler,
    column5SKUSelectedOption,
    setColumn5SKUSelectedOption,
    column5SKUOptionsChangeHandler,
    ventaMonedaSelectedOption,
    ventaUnidadesSelectedOption
  } = useMarketBasketConfigProvider();

  const totalRows = () => {
    const rows: any[] = [];
    if (
      marketBasketElement?.metricas_SKU_primarias &&
      marketBasketElement?.metricas_SKU_primarias.length > 0
    ) {
      marketBasketElement.metricas_SKU_primarias.forEach((row: any) => {
        rows.push(row);
      });
    }
    if (
      marketBasketElement?.metricas_SKU_secundarias &&
      marketBasketElement?.metricas_SKU_secundarias.length > 0
    ) {
      marketBasketElement.metricas_SKU_secundarias.forEach((row: any) => {
        rows.push(row);
      });
    }
    return rows;
  };

  const handleSetNewRows = (newRows: any) => {
    setMarketBasketElement((prev) => ({
      ...prev,
      metricas_SKU_primarias: newRows,
    }));
  };

  const handleCleanRows = () => {
    setMarketBasketElement((prev) => ({
      ...prev,
      metricas_SKU_primarias: [{
        nombre: "VENTA_UNIDADES_PROMEDIO",
        funcion: {
          value: "PROMEDIAR",
          label: "PROMEDIAR",
        },
        columna: ventaUnidadesSelectedOption,
        columna_divisora: undefined,
        consolidar_periodos: {
          value: "ULTIMO",
          label: "ULTIMO",
        },
      },
      {
        nombre: "VENTA_MONEDA_PROMEDIO",
        funcion: {
          value: "PROMEDIAR",
          label: "PROMEDIAR",
        },
        columna: ventaMonedaSelectedOption,
        columna_divisora: undefined,
        consolidar_periodos: {
          value: "ULTIMO",
          label: "ULTIMO",
        },
      },
      {
        nombre: "VENTA_UNIDADES",
        funcion: {
          value: "SUMAR",
          label: "SUMAR",
        },
        columna: ventaUnidadesSelectedOption,
        columna_divisora: undefined,
        consolidar_periodos: {
          value: "ULTIMO",
          label: "ULTIMO",
        },
      },
      {
        nombre: "VENTA_MONEDA",
        funcion: {
          value: "SUMAR",
          label: "SUMAR",
        },
        columna: ventaMonedaSelectedOption,
        columna_divisora: undefined,
        consolidar_periodos: {
          value: "ULTIMO",
          label: "ULTIMO",
        },
      },
    ],
    }));
  };

  const titles = [
    t("general.name"),
    t("general.function"),
    t("general.column"),
    t("general.dividingColumn"),
    t("general.consolidatePeriods"),
  ];

  return (
    <FormLayout width="100%">
      <Grid sx={grid_container2_styles} container spacing={1}>
        <GridFiveTitles titles={titles} />
        <PrimaryMetricsSelects
          titles={titles}
          setMessage={setMessage}
          totalColumnOptions={totalColumnOptions}
          rows={marketBasketElement?.metricas_SKU_primarias || []}
          handleSetNewRows={handleSetNewRows}
          handleCleanRows={handleCleanRows}
          column1SelectRef={column1SKUSelectRef}
          column2SelectRef={column2SKUSelectRef}
          column3SelectRef={column3SKUSelectRef}
          column4SelectRef={column4SKUSelectRef}
          column5SelectRef={column5SKUSelectRef}
          column6SelectRef={undefined}
          column1SelectedOption={column1SKUSelectedOption}
          setColumn1SelectedOption={setColumn1SKUSelectedOption}
          column2SelectedOption={column2SKUSelectedOption}
          setColumn2SelectedOption={setColumn2SKUSelectedOption}
          column2OptionsChangeHandler={column2SKUOptionsChangeHandler}
          column3SelectedOption={column3SKUSelectedOption}
          setColumn3SelectedOption={setColumn3SKUSelectedOption}
          column3OptionsChangeHandler={column3SKUOptionsChangeHandler}
          column4SelectedOption={column4SKUSelectedOption}
          setColumn4SelectedOption={setColumn4SKUSelectedOption}
          column4OptionsChangeHandler={column4SKUOptionsChangeHandler}
          column5SelectedOption={column5SKUSelectedOption}
          setColumn5SelectedOption={setColumn5SKUSelectedOption}
          column5OptionsChangeHandler={column5SKUOptionsChangeHandler}
          column6SelectedOption={undefined}
          setColumn6SelectedOption={undefined}
          column6OptionsChangeHandler={undefined}
          totalRows={totalRows()}
        />
        {marketBasketElement?.metricas_SKU_primarias &&
        marketBasketElement?.metricas_SKU_primarias.length ? (
          marketBasketElement?.metricas_SKU_primarias.map(
            (row: any, index: number) => (
              <PrimaryMetricsRow
                key={index}
                row={row}
                handleSetNewRows={handleSetNewRows}
                rows={marketBasketElement?.metricas_SKU_primarias}
                secondaryMetricsRows={
                  marketBasketElement?.metricas_SKU_secundarias
                }
                titles={titles}
                isRowDeletable={
                  row.nombre !== "VENTA_UNIDADES" &&
                  row.nombre !== "VENTA_MONEDA" &&
                  row.nombre !== "VENTA_MONEDA_PROMEDIO" &&
                  row.nombre !== "VENTA_UNIDADES_PROMEDIO"
                }
              />
            )
          )
        ) : (
          <NoLinesComponent />
        )}
      </Grid>
    </FormLayout>
  );
};

export default Step2Form;
