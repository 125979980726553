import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import useLanguage from "../../../../context/LanguageProvider";
import { useParameterization } from "../../../../hooks/useParameterization";

const CustomCheckbox = React.forwardRef((props: any, ref: any) => {
  const {
    parameterizationDispatch,
  } = useParameterization();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { selectallrows, criteria, selectrowswithoutcriteria, rowsresult } = props;

  const open = Boolean(anchorEl);
  const { t } = useLanguage();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (type: string) => {
    if (criteria) {
      selectrowswithoutcriteria.selectRowsWithoutCriteria(type, rowsresult, parameterizationDispatch, t);
    } else {
      selectallrows.selectAllRows(
        type,
        rowsresult,
        parameterizationDispatch,
        t
      );
    }
    handleClose();
  };

  return (
    <>
      <Checkbox ref={ref} {...props} sx={{ paddingRight: "0px" }} />
      <span
        onClick={handleClick}
        style={{
          padding: "0",
          paddingRight: "5px",
          display: "none",
          paddingTop: "8px",
        }}
        className="viewMoreIconCheckbox"
      >
        <ExpandMoreIcon />
      </span>
      <Menu
        id="basic-menu"
        className="checkBoxMiniSelect"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            handleSelection(
              t("modelParameterizationTexts.customCheckbox.all").toLowerCase()
            )
          }
        >
          {t("modelParameterizationTexts.customCheckbox.all")}
        </MenuItem>
        {criteria ? (
          <MenuItem
            onClick={() =>
              handleSelection(
                t("modelParameterizationTexts.customCheckbox.withoutCriteria")
              )
            }
          >
            {t("modelParameterizationTexts.customCheckbox.rowsWithoutCriteria")}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() =>
              handleSelection(
                t("modelParameterizationTexts.customCheckbox.withoutRow")
              )
            }
          >
            {t("modelParameterizationTexts.customCheckbox.rowsWithoutStages")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
});

export default CustomCheckbox;
