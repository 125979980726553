import { useState } from "react";

const useDialog: () => [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle: () => void = () => {
    setOpen((prev: boolean) => !prev);    
  }; 

  return [open, handleToggle, setOpen];
};

export default useDialog;
