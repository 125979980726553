import { Tab, TabValue } from "../../colorTabs/types";
import { useParameterization } from "../../../hooks/useParameterization";
import ColorTabs from "../../colorTabs/ColorTabs";
import React from "react";
import useLanguage from "../../../context/LanguageProvider";

const ExpensesTabs = () => {
  const { t } = useLanguage();
  const { parameterizationState, parameterizationDispatch, refetchBuckets } =
    useParameterization();
  const { selectedStage, tabValue } = parameterizationState;

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    parameterizationDispatch({ type: "SET_TAB_VALUE", payload: newValue });   
    parameterizationDispatch({ type: "SET_SELECTION_MODEL", payload: [] });  
    refetchBuckets();
  };

  const tabs: Tab[] = [
    {
      value: t("modelParameterizationTexts.step2.tabValueExpenses"),
      label: t("modelParameterizationTexts.step2.tabValueExpenses"),
    },
    {
      value: t(
        "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
      ),
      label: t(
        "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
      ),
    },
  ];

  const tab: Tab[] = [
    {
      value: t("modelParameterizationTexts.step2.tabValueExpenses"),
      label: t("modelParameterizationTexts.step2.tabValueExpenses"),
    },
  ];  

  return (
    <ColorTabs
      value={tabValue}
      handleChange={handleChange}
      tabs={selectedStage?.calculado ? tabs : tab}
      marginBottom="0px"
      fontSize="15px"
    />
  );
};

export default ExpensesTabs;
