import { delete_button2_styles } from "../../styles/app-styles";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
interface AddButtonProps {
  onClick?: (() => void) | ((e: any) => void) | undefined;
  disabled?: boolean;
  align?: boolean;
}

const DeleteButton2 = ({ onClick, disabled, align }: AddButtonProps) => {
const useStyles = makeStyles({
  button: delete_button2_styles(
    disabled, align
  ),
});

const classes = useStyles();

  return (
    <button type="button" onClick={onClick} disabled={disabled} className={classes.button}>
      <DeleteForeverIcon />
    </button>
  );
};

export default DeleteButton2;
