import {
  getElementsByItem,
  getTotalItems,
  removeDuplicatesFromElementsType,
} from "./utils";
import { repeatArray } from "../periods/utils";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListElements from "./ListElements";
import ListItem from "./ListItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import useLanguage from "../../context/LanguageProvider";

export type ElementType = {
  item: string;
  label: string;
  value: number;
};
interface IMultipleSelectCheckmarks {
  data: ElementType[];
  selectedElements: ElementType[];
  setSelectedElements: (selectedElements: ElementType[]) => void;
  width?: string;
  selectLabel?: string;
  ITEM_HEIGHT_CUSTOM?: number;
  ITEM_PADDING_TOP_CUSTOM?: number;
}

export default function CheckboxSelect({
  data,
  selectedElements,
  setSelectedElements,
  width,
  selectLabel,
  ITEM_HEIGHT_CUSTOM,
  ITEM_PADDING_TOP_CUSTOM,
}: IMultipleSelectCheckmarks) {
  const { t } = useLanguage();
  const ITEM_HEIGHT = ITEM_HEIGHT_CUSTOM ? ITEM_HEIGHT_CUSTOM : 48;
  const ITEM_PADDING_TOP = ITEM_PADDING_TOP_CUSTOM
    ? ITEM_PADDING_TOP_CUSTOM
    : 8;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
        width: 270,
      },
    },
  };

  const totalItems = () =>
    (data && data.length > 0 && getTotalItems(data)) || [];

  const handleChangeItem = (item: string) => {
    const isSelected = selectedItems.includes(item);
    const updatedSelectedItems = isSelected
      ? selectedItems.filter((it: string) => it !== item)
      : [...selectedItems, item];
    const updatedSelectedElements = isSelected
      ? selectedElements.filter((el: any) => el.item !== item)
      : removeDuplicatesFromElementsType([
          ...selectedElements,
          ...getElementsByItem(item, data),
        ]);
    setSelectedItems(updatedSelectedItems);
    setSelectedElements(updatedSelectedElements);
  };

  const handleChangeElement = (dataElement: any) => {
    const selectedElementsValues: number[] = selectedElements.map(
      (el) => el.value
    );
    const updatedElements: ElementType[] = selectedElementsValues.includes(
      dataElement.value
    )
      ? selectedElements.filter((el: any) => el.value !== dataElement.value)
      : [...selectedElements, dataElement];
    setSelectedElements(updatedElements);
  };

  const handleToggleCollapse = (item: string) => {
    setCollapsedItems(
      collapsedItems.includes(item)
        ? collapsedItems.filter((it: string) => it !== item)
        : [...collapsedItems, item]
    );
  };

  return (
    <FormControl sx={{ m: 1, width: width ? width : 300, margin: 0 }}>
      {data && data.length > 0 && (
        <>
          <InputLabel sx={{ top: "-5px", zIndex: 0 }}>
            {selectLabel ? selectLabel : "Columnas"}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            multiple
            value={selectedElements}
            input={
              <OutlinedInput
                label={selectLabel ? selectLabel : t("Columnas")}
              />
            }
            renderValue={(selected) =>
              selected.map((sel: any, index: number) =>
                selected.length > 1
                  ? sel.label + (index !== selected.length - 1 ? ", " : "")
                  : selected[0]?.label
              )
            }
            MenuProps={MenuProps}
            sx={{
              height: "45px",
              verticalAlign: "center",
            }}
          >
            {repeatArray(totalItems()).map((item: string, index: number) => {
              //Esto sirve para que se muestre una vez el item y abajo los elementos correspondientes.
              if (index % 2 === 0) {
                //entonces con esto alterno item y lista de elementos
                return (
                  <ListItem
                    key={item + index}
                    item={item}
                    handleChangeItem={handleChangeItem}
                    selectedElements={selectedElements}
                    handleToggleCollapse={handleToggleCollapse}
                  />
                );
              } else {
                return collapsedItems.includes(item)
                  ? ListElements(
                      data,
                      selectedElements,
                      item,
                      handleChangeElement
                    )
                  : null;
              }
            })}
          </Select>
        </>
      )}
    </FormControl>
  );
}
