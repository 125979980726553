import { columnOptions } from "../configCriteria/selectOptions";
import { error_text_styles } from "../../styles/app-styles";
import { selectStyles } from "../configCriteria/select.styles";
import { useEffect, useRef, useState } from "react";
import AddButton from "../buttons/AddButton";
import FormSelectLayout from "../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import Select from "../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../context/LanguageProvider";
import useSingleSelect from "../../hooks/useSingleSelect";

interface DivisionSelectsProps {
  setDivisionRows: any;
  divisionRows: any;
  fileColumnSelectOptions: any;
  fileSelectedOptions: any;
}

const DivisionSelects = ({
  setDivisionRows,
  divisionRows,
  fileColumnSelectOptions,
  fileSelectedOptions,
}: DivisionSelectsProps) => {
  const { t } = useLanguage();

  const columnSelectRef: any = useRef(null);
  const divisorSelectRef: any = useRef(null);
  const [message, setMessage] = useState<any>({});

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(undefined, undefined);

  const [
    divisorSelectedOption,
    setDivisorSelectedOption,
    divisorChangeHandler,
  ] = useSingleSelect();

  const handleAddRow = () => {
    if (!columnSelectedOption || !divisorSelectedOption) return;
    const divisorsList = divisionRows.map(
      (item: any) => item.columna.label + item.columna_divisor.label
    );
    const newDivisorPair =
      columnSelectedOption?.label + divisorSelectedOption?.label;
    if (divisorsList?.includes(newDivisorPair)) {
      setMessage({
        type: "warning",
        text: "Esta combinación ya existe",
      });
      return;
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
    setDivisionRows((prev) => [
      ...prev,
      {
        columna: columnSelectedOption,
        columna_divisor: divisorSelectedOption,
      },
    ]);
    setColumnSelectedOption(undefined);
    setDivisorSelectedOption(undefined);
    columnSelectRef.current.clearValue();
    divisorSelectRef.current.clearValue();
  };

  useEffect(() => {
    if (columnSelectedOption || divisorSelectedOption || (divisionRows && divisionRows.length !== 0)) {
      setColumnSelectedOption(undefined);
      setDivisorSelectedOption(undefined);
      columnSelectRef.current.clearValue();
      divisorSelectRef.current.clearValue();      
    }
  }, [fileSelectedOptions]);

  return (
    <Grid2 container direction={"row"} xs={12} mb={2} gap={2}>
      {message && message.text && <Grid2 xs={12} sx={error_text_styles}>{message.text}</Grid2>  }    
      <Grid2 xs={0.2}></Grid2>
      <Grid2 xs={5}>
        <Stack sx={{ width: "100%" }}>
          <FormSelectLayout title={"Columna Eje Y"} required={false}>
            <Select
              reference={columnSelectRef}
              isClearable
              styles={selectStyles(columnSelectedOption)}
              options={columnOptions(
                fileColumnSelectOptions,
                fileSelectedOptions
              )}
              className="pp_select_schema"
              onChange={columnOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={"Elegir columna"}
            />
          </FormSelectLayout>
        </Stack>
      </Grid2>
      <Grid2 xs={5}>
        {
          <Stack sx={{ width: "100%" }}>
            <FormSelectLayout title={"Divisor"} required={false}>
              <Select
                reference={divisorSelectRef}
                isClearable
                styles={selectStyles(divisorSelectedOption)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                className="pp_select_schema"
                onChange={divisorChangeHandler}
                closeMenuOnSelect
                isSearchable
                placeholder={"Elegir columna divisora"}
              />
            </FormSelectLayout>
          </Stack>
        }
      </Grid2>
      <Grid2 xs={0.5}>
        <Stack justifyContent={"flex-end"} height="85%">
          <AddButton
            onClick={() => handleAddRow()}
            disabled={
              columnSelectedOption === undefined ||
              divisorSelectedOption === undefined
            }
          />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default DivisionSelects;
