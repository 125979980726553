import { Button } from "../../buttons";
import { handleRefetchRowsData, handleRefetchRowsDataNt } from "../utils";
import { ReactElement, useEffect } from "react";
import { Stack } from "@mui/material";
import { STAGES_URL } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParameterization } from "../../../hooks/useParameterization";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalculationView from "./CalculationView";
import ComponentLayout from "../../layout/ComponentLayout";
import PeriodsFilter from "../../periods/PeriodsFilter";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";

const Calculation = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const {
    parameterizationState,
    parameterizationDispatch,
    rowsController,
    rowsNtController,
    getProrationResultsNoTrasladados,
    getProrationResults
  } = useParameterization();
  const {
    bucketSelectedOption,
    selectedStage,
    differentiatedTargets,
    selectedIteration,
  } = parameterizationState;
  const {
    hasCompletedStages,
    setHasCompletedStages,
    setWarningWithoutStages,
  }: any = useInfoContext();
  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();

  useEffect(() => {
    handleRefetchRowsData(
      parameterizationState,
      parameterizationDispatch,
      getProrationResults
    )

    return () => {
      rowsController.abort();
    };
  }, [bucketSelectedOption, selectedStage, differentiatedTargets]);

  useEffect(() => {  
    handleRefetchRowsDataNt(
      parameterizationState,
      parameterizationDispatch,
      getProrationResultsNoTrasladados
    );
    return () => {
      rowsNtController.abort();
    };
  }, [selectedStage, selectedIteration]);

  const onSuccessData = () => {
    getCompletedStages !== undefined &&
      getCompletedStages !== null &&
      setHasCompletedStages(getCompletedStages);
  };

  const {
    data: getCompletedStages,
    isLoading: isLoadingCompletedStages,
    error: isErrorCompletedStages,
    callApi: getDataStages,
  } = useApi(
    STAGES_URL,
    "GET",
    t("dataManagement.getCompletedStages.codes"),
    undefined,
    onSuccessData,
    undefined,
    false
  );

  useEffect(() => {
    getDataStages();
  }, []);

  //setea el valor de hasCompletedStages con el valor de getCompletedStages cuando se obtiene
  useEffect(() => {
    getCompletedStages !== undefined &&
      getCompletedStages !== null &&
      setHasCompletedStages(getCompletedStages);
  }, [getCompletedStages]);  

  //si no están completas las etapas, se redirige a la página de seteo de etapas
  useEffect(() => {
    if (hasCompletedStages === false || isErrorCompletedStages) {
      setWarningWithoutStages(true);
      navigate("/parametrizacion-modelo/asignacion-etapas")   
    }
  }, [hasCompletedStages]);

  const handleClosePeriodsModal = () => {
    handleTogglePeriodsDialog();
  };

  //renderiza el componente principal de cálculo o el backdrop de carga
  const renderContent = (CalculationView: ReactElement) => {
    if (isLoadingCompletedStages) {
      return (
        <SimpleBackdrop
          open={isLoadingCompletedStages}
          message={t("calculation.getCompletedStages.loading")}
        />
      );
    } else if (isErrorCompletedStages) {
      return <></>;
    } else if (hasCompletedStages) {
      return CalculationView;
    }
  };

  return (
    <ComponentLayout
      title={t("modelParameterizationTexts.calculations.title")}
      icon={<CalculateIcon />}
      rightComponent={
        <Button
          title={t("modelParameterizationTexts.calculations.periods")}
          color="blue"
          type="button"
          onClick={() => handleTogglePeriodsDialog()}
        />
      }
    >
      <Stack sx={{ width: "100%" }}>
        {renderContent(<CalculationView />)}
        <PeriodsFilter
          openPeriodsDialog={openPeriodsDialog}
          handleTogglePeriodsDialog={handleClosePeriodsModal}
        />
      </Stack>
    </ComponentLayout>
  );
};

export default Calculation;
