export const checkRepeatedName = (
  name: string,
  list: any[] | undefined,
  t,
  configMode,
  setMessage,
  element
) => {
  if (name) {
    const existingItem = list?.find(
      (item: any) => item.nombre.trim().toLowerCase() === name.trim().toLowerCase()
    );
    if (existingItem && (configMode === "CREATE" || configMode === "COPY")) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
    } else if (
      existingItem &&
      (configMode === "EDIT" || configMode === "COPY") &&
      element?.nombre.toLowerCase() !== name.toLowerCase()
    ) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
    } else {
      setMessage(undefined);
    }
  }
};

export function getRolconfigLabels(clave) {
  const textoPersonalizado = clave
    .replace(/_/g, ' ') // Reemplaza "_" con espacio
    .replace(/\b\w/, (c) => c.toUpperCase()); // Capitaliza solo la primera letra

  // Reemplazar palabras específicas con acento
  const palabrasConAcento = {
    visualizacion: 'Visualización',
    visualizacion_y_creacion: 'Visualización y Creación',
    visualizacion_y_edicion: 'Visualización, Creación y Edición',
    visualizacion_y_descargas: 'Visualización y Descargas',
  };

  return palabrasConAcento[clave] || textoPersonalizado;
}