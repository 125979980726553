import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteForeverIconProps} from "./types";
import useLanguage from "../../context/LanguageProvider";

const DeleteForever = ({ tooltipPlacement, onClick, color, width }: DeleteForeverIconProps) => {
  const { t } = useLanguage();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={t("icons.deleteAll")}
      placement={tooltipPlacement}
    >
      <DeleteForeverIcon
        onClick={onClick}
        sx={{
          color: color || "var(--text-info)",
          padding: "0",
          margin: "0",
          fontSize: width ? width : "24px",
          "& :hover": { color: "#F90D4A", cursor: "pointer" },
          textAlign: "center",
        }}
      />
    </Tooltip>
  );
};

export default DeleteForever;
