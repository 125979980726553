/**
 * Asigna colores en base a la entrada:
 * - "grey" (gris) para "-" como cadena
 * - "white" (blanco) para otras cadenas
 * - "green" (verde) para valores numéricos con una diferencia <= 0.0001 de 1
 * - "red" (rojo) para valores numéricos con una diferencia > 0.005 de 1
 * - "yellow" (amarillo) para todos los demás valores numéricos
 *
 * @param {number|string} value - El valor de entrada.
 * @returns {string} El color asignado.
 */

export const flag = (value: number | string) => {
  if (typeof value === "string") {
    if (value.trim().toLowerCase() === "error") {
      return "red";
    } else if (value.trim().toLowerCase() === "calculando") {
      return "yellow";
    } else if (value === "-") {
      return "grey";
    } else {
      return "white";
    }
  } else {
    return Math.abs(1 - value) <= 0.0005
      ? "green"
      : Math.abs(1 - value) <= 0.005
      ? "yellow"
      : Math.abs(1 - value) <= 0.015
      ? "orange"
      : "red";
  }
};

/**
 * Devuelve el color de fondo basado en la flag.
 *
 * @param {string} flag - La bandera que determina el color.
 * @returns {string} El color de fondo correspondiente.
 */

export const backgroundColorByFlag = (flag: string) => {
  switch (flag) {
    case "green":
      return "var(--bg-success)";
    case "red":
      return "var(--bg-error)";
    case "yellow":
      return "var(--bg-warning-hover)";
    case "orange":
      return "var(--bg-warning)";
    case "grey":
      return "var(--bg-disabled)";
    default:
      return "white";
  }
};

/**
 * Devuelve el color de fuente basado en la flag.
 *
 * @param {string} flag - La bandera que determina el color.
 * @returns {string} El color de fuente correspondiente.
 */

export const fontColorByFlag = (flag: string) => {
  switch (flag) {
    case "green":
      return "white";
    case "red":
      return "white";
    case "yellow":
      return "var(--text-main)";
    case "orange":
      return "var(--text-main)";
    case "grey":
      return "var(--text-main)";
    default:
      return "var(--text-main)";
  }
};

/**
 * Formatea un valor como porcentaje con un número específico de decimales si es un número, de lo contrario, lo devuelve sin formato.
 *
 * @param {number|string} value - El valor que se formateará.
 * @returns {string} El valor formateado como porcentaje o sin formato.
 */

export const formatValue = (value: number | string) => {
  const decimals = 2;
  return typeof value === "number"
    ? value.toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })
    : value;
};
