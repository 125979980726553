/* import "./styles.css"; */
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { renderCellSimulationMarketBasket } from "../utils";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ITransformDataSimulationTable {
  (
    t: any,
    data: any,
    transformColumnNames: any,
    simulationValues: any,
    handleChangeValue: any
  ): {
    columns: GridColDef<any, any, any>[];
    rows: any;
  };
}

export const transformDataSimulationTable: ITransformDataSimulationTable = (
  t,
  data = [],
  transformColumnNames,
  simulationValues,
  handleChangeValue
) => {
  const tableColumns =
    data && data.length > 0
      ? [
          ...Object.keys(data[0]).filter((col) => {
            return (
              col === "COD_SKU" || col === "MARCA" || col === "VENTA_UNIDADES_PROMEDIO"
            );
          }),
          "TASA DE AJUSTE VENTAS",
          "VALOR AJUSTADO VENTAS",
        ]
      : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(t,columna) : columna}
        </strong>
      ),
      renderCell: (params: any) =>
        renderCellSimulationMarketBasket(
          params,
          Stack,
          Typography,
          InputAdornment,
          Input,
          simulationValues,
          handleChangeValue
        ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
