import { useContext } from "react";
import InfoContext from "../context/InfoProvider";

const useInfoContext = () => {
   // const { hasCompletedStages, setHasCompletedStages }: any = useContext(InfoContext);
    return useContext(InfoContext);
}

export default useInfoContext;


