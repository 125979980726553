import { Checkbox, ListItem, ListItemText } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";

interface IListYearItem {
  yearItem: string;
  yearHandleChange: (yearItem: string) => void;
  selectedYears: string[];
  handleToggleCollapseYear: (yearItem: string) => void;
}

const ListYearItem = ({
  yearItem,
  yearHandleChange,
  selectedYears,
  handleToggleCollapseYear
}: IListYearItem) => {
  return (
    <ListItem
      divider={true}
      sx={{
        marginLeft: "0px",
        paddingLeft: "0px",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div
        style={{
          color: "var(--text-main)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={selectedYears.indexOf(yearItem) > -1}
          sx={{
            color: "var(--text-main)",
            "& svg": { stroke: "var(--text-main)" },
          }}
          onChange={() => {
            yearHandleChange(yearItem);
          }}
        />
        <ListItemText
          primary={yearItem}
          primaryTypographyProps={{ sx: { fontWeight: "bold", whiteSpace: "normal", height: "auto", } }}
        />
        <IconButton aria-label="arrow" sx={{"& svg": {fill: "grey"}}} onClick={() => handleToggleCollapseYear(yearItem)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
    </ListItem>
  );
};

export default ListYearItem;
