import "../styles.css";
import { Button } from "../../buttons";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { useState } from "react";
import CustomInput from "../../forms/CustomInput";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../../context/LanguageProvider";
interface IStage {
  handleApplyStages: (stageValue: string | number) => void;
  selectionModel: GridSelectionModel;
}

const Stages = ({ handleApplyStages, selectionModel }: IStage) => {
  const [stageValue, setStageValue] = useState<string | number>(1);
  const { t } = useLanguage();

  return (
    <div className="stages_container">
      <p className="stages_label">{t("modelParameterizationTexts.stages.stages")}: </p>
      <div style={{ width: "80px" }}>
        <CustomInput
          type="number"
          value={stageValue}
          setValue={setStageValue}
          required={false}
          placeholder={`${t("modelParameterizationTexts.stages.stages")}...`}
          autoFocus={true}
          defaultValue={1}
          minValue={1}
          maxValue={10}
        />
      </div>
      {!selectionModel || selectionModel.length === 0 ? (
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={t("modelParameterizationTexts.stages.nowRowsSelected")}
          sx={{ verticalAlign: "center", fontSize: "16px" }}
        >
          <div>
            <Button
              title={t("modelParameterizationTexts.stages.assignToSelected")}
              color="blue-greeny"
              type="button"       
              width={220}
              disabled={true}
            />
          </div>
        </Tooltip>
      ) : (
        <Button
          title={t("modelParameterizationTexts.stages.assignToSelected")}
          color="blue-greeny"
          type="button"
          onClick={() => handleApplyStages(stageValue)}
          width={220}
     
        />
      )}
    </div>
  );
};

export default Stages;
