import { useMarketBasketProvider } from "../../../context/MarketBasketProvider";
import ColorTabs from "../../colorTabs/ColorTabs";
import Stack from "@mui/material/Stack";

const MarketBasketTabs = () => {
  const {
    tabValue,
    handleChange,
    tabs,
  } = useMarketBasketProvider();

  return (
    <Stack style={{ width: "100%" }}>
      <ColorTabs
        value={tabValue}
        handleChange={handleChange}
        tabs={tabs}
        marginBottom="0px"
        scrollable={true}
      />
    </Stack>
  );
};

export default MarketBasketTabs;
