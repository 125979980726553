import { ColorTabsProps } from "./types";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export default function ColorTabs({
  value,
  handleChange,
  tabs,
  marginBottom,
  fontSize,
  scrollable,
}: ColorTabsProps) {
  return (
    <Box sx={{ width: "100%", marginBottom: marginBottom || "60px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant={scrollable ? "scrollable" : undefined}
        scrollButtons={scrollable ? "auto" : undefined}
        sx={{
          "& .MuiTab-textColorPrimary": { color: "var(--text-secondary)" },
          "& .MuiTabs-indicator": { backgroundColor: "var(--bg-error)" },
          "& .MuiTab-textColorPrimary.MuiTab-root.Mui-selected": {
            color: "var(--text-error)",
          },         
        }}
      >
        {tabs?.map((tab: any) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            sx={{ fontWeight: "bold", fontSize: fontSize ? fontSize : "16px" }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
