import { selectStyles } from "../../../configCriteria/select.styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useRef } from "react";
import AddButton from "../../../buttons/AddButton";
import Grid2 from "@mui/material/Unstable_Grid2";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import { getFileColumns, getValueLabel } from "../../utils";
interface RelationshipInputProps {
  setNewRelationship: any;
  newName: string;
  setNewName: any;
  relationshipNameRef: any;
  newRelationship: any;
}

const RelationshipInput = ({
  setNewRelationship,
  newName,
  setNewName,
  relationshipNameRef,
  newRelationship,
}: RelationshipInputProps) => {
  const { t } = useLanguage();

  const fileSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const { message, setMessage, relationships, dataGetColumnsTypesParams } = useCompanyParamProvider();

  const onFileColumn = () => {
    setColumnSelectedOption(undefined);
    columnSelectRef.current.clearValue();
  };

  const [fileSelectedOption, setFileSelectedOption, fileOptionChangeHandler] =
    useSingleSelect(() => onFileColumn(), undefined);
  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(undefined, undefined);

  useEffect(() => {
    setNewRelationship({
      nombre: newName,
      involucrados: [],
    });
  }, []);

  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
    setNewRelationship((prev: any) => {
      return { ...prev, nombre: e.target.value };
    });
    const namesList = relationships?.map((item: any) =>
      item.nombre.trim().toLowerCase()
    );
    if (namesList?.includes(e.target.value.trim().toLowerCase())) {
      setMessage({
        type: "error",
        text: t('general.repeatedName'),
      });
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  };

  const handleAddRow = () => {
    if (!fileSelectedOption || !columnSelectedOption) return;
    const fileColumnList = newRelationship.involucrados?.map(
      (item: any) => item.archivo.label + item.columna.label
    );
    const newFileColumn =
      fileSelectedOption?.label + columnSelectedOption?.label;
    if (fileColumnList?.includes(newFileColumn)) {
      setMessage({
        type: "warning",
        text: t('companyParam.existentCombination'),
      });
      return;
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }

    setNewRelationship((prev) => {
      return {
        ...prev,
        involucrados: [
          ...prev.involucrados,
          {
            archivo: fileSelectedOption,
            columna: columnSelectedOption,
          },
        ],
      };
    });
    setFileSelectedOption(undefined);
    setColumnSelectedOption(undefined);
    fileSelectRef.current.clearValue();
    columnSelectRef.current.clearValue();
  };

  return (
    <Grid2 container direction={"row"} xs={12} mt={2} mb={2} gap={2}>
      <Grid2 xs={12}>
        <TextField
          type="text"
          ref={relationshipNameRef}
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={t('companyParam.relationshipName')}
          autoFocus
          size="small"
          error={
            message.type === "error" && (message?.text !== "" || newName === "")
          }
          helperText={message.type === "error" && message?.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
      </Grid2>
      <Grid2 xs={0.3}></Grid2>
      <Grid2 xs={5.3}>
        <Stack sx={{ width: "100%" }}>
          <FormSelectLayout title={t('companyParam.file')} required={false}>
            <Select
              reference={fileSelectRef}
              isClearable
              styles={selectStyles(fileSelectedOption)}
              options={getValueLabel(dataGetColumnsTypesParams)}
              className="pp_select_schema"
              onChange={fileOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t('companyParam.chooseAnOption')}
            />
          </FormSelectLayout>
        </Stack>
      </Grid2>
      <Grid2 xs={5}>
        {
          <Stack sx={{ width: "100%" }}>
            <FormSelectLayout title={t('companyParam.column')} required={false}>
              <Select
                reference={columnSelectRef}
                isClearable
                styles={selectStyles(columnSelectedOption)}
                options={getFileColumns(dataGetColumnsTypesParams,fileSelectedOption)}
                className="pp_select_schema"
                onChange={columnOptionChangeHandler}
                closeMenuOnSelect
                isSearchable
                placeholder={t('companyParam.chooseAnOption')}
              />
            </FormSelectLayout>
          </Stack>
        }
      </Grid2>
      <Grid2 xs={0.5}>
        <Stack justifyContent={"flex-end"} height="85%">
          <AddButton
          onClick={() => handleAddRow()}
          disabled={
            columnSelectedOption === undefined ||
            fileSelectedOption === undefined
          }
        />
        </Stack>
        
      </Grid2>
    </Grid2>
  );
};

export default RelationshipInput;
