import "./styles.css";
import { DataGrid } from "@mui/x-data-grid";
import { sortTasksByDate, transformColumnDate } from "./utils";
import { Tab, TabValue } from "../colorTabs/types";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";
import { transformDataTaskManager } from "./transformData";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useLocation } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import ConfirmationModal from "../dialog/ConfirmationModal";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import CustomToolbar from "../customToolbar/CustomToolbar";
import Dialog from "../dialog/Dialog";
import React, { useEffect, useState } from "react";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import SimpleTable from "../tablesTools/SimpleTable";
import Stack from "@mui/material/Stack";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";
import {
  DELETE_TASK_URL,
  GET_TASKS_URL,
  GET_TASK_ERRORS_URL,
} from "../../api/axios";
import useInfoContext from "../../hooks/useInfoContext";

const TaskManager = () => {
  const { t, language } = useLanguage();
  const location = useLocation();
  const { userRolData }: any = useInfoContext();
  const [tabValue, setTabValue] = useState<TabValue>(t("tabs.preproration"));
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(6);
  const [openDeleteModal, toggleDeleteModal] = useDialog();
  const [idToDelete, setIdToDelete] = useState<number | undefined>();
  const [isFetchingDownloads, setIsFetchingDownloads] = useState<boolean>(true);
  const [openErrorsModal, handleToggleErrorsModal] = useDialog();

  const createTabs = (simulationRole: boolean) => {
    const baseTabs = [
      { value: t("tabs.preproration"), label: t("tabs.preproration") },
      { value: t("tabs.proration"), label: t("tabs.proration") },
      { value: t("tabs.downloads"), label: t("tabs.downloads") },
      { value: t("tabs.datamodel"), label: t("tabs.datamodel") },
      { value: t("tabs.marketBasket"), label: t("tabs.marketBasket") },
    ];

    const tabs = simulationRole
      ? baseTabs.concat(
          { value: t("tabs.salesForecast"), label: t("tabs.salesForecast") },
          { value: t("tabs.simulation"), label: t("tabs.simulation") }
        )
      : baseTabs;

    return tabs;
  };

  const tabs: Tab[] = createTabs(
    userRolData?.simulador.visualizacion ||
      userRolData?.simulador.visualizacion_y_creacion ||
      userRolData?.simulador.visualizacion_y_edicion
  );

  useEffect(() => {
    setTabValue(t("tabs.preproration"));
  }, [language]);

  const {
    data: dataPreprorrateo,
    isLoading: isLoadingPreprorrateo,
    error: errorPreprorrateo,
    isSuccess: isSuccessPreprorrateo,
    refetch: refetchPreprorrateo,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=preprorrateo`,
    true,
    t("taskManager.getTasks.error")
  );
  const {
    data: dataProrrateo,
    isLoading: isLoadingProrrateo,
    error: errorProrrateo,
    isSuccess: isSuccessProrrateo,
    refetch: refetchProrrateo,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=prorrateo`,
    true,
    t("taskManager.getTasks.error")
  );
  const {
    data: dataDownloads,
    isLoading: isLoadingDownloads,
    error: errorDownloads,
    isSuccess: isSuccessDownloads,
    refetch: refetchDownloads,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=descargas`,
    true,
    t("taskManager.getDownloads.error"),
    tabValue === t("tabs.downloads") && isFetchingDownloads ? 1000 : 0
  );

  const {
    data: datamodel,
    isLoading: isLoadingDatamodel,
    error: errorDatamodel,
    isSuccess: isSuccessDatamodel,
    refetch: refetchDatamodel,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=dataexport`,
    true,
    t("taskManager.getTasks.error")
  );

  const {
    data: salesForecast,
    isLoading: isLoadingSalesForecast,
    error: errorSalesForecast,
    isSuccess: isSuccessSalesForecast,
    refetch: refetchSalesForecast,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=sales_forecast`,
    true,
    t("taskManager.getSalesForecast.error")
  );

  const {
    data: simulation,
    isLoading: isLoadingSimulation,
    error: errorSimulation,
    isSuccess: isSuccessSimulation,
    refetch: refecthSimulation,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=simulacion`,
    true,
    t("taskManager.getSimulations.error")
  );

  const {
    data: marketBasket,
    isLoading: isLoadingMarketBasket,
    error: errorMarketBasket,
    isSuccess: isSuccessMarketBasket,
    refetch: refecthMarketBasket,
  } = useApiQuery(
    `${GET_TASKS_URL}/?tipo=market_basket`,
    true,
    t("taskManager.getTasks.error")
  );

  useEffect(() => {
    if (
      isSuccessDownloads &&
      dataDownloads &&
      dataDownloads.some((task: any) => task.estado === "Pendiente")
    ) {
      setIsFetchingDownloads(true);
    } else {
      setIsFetchingDownloads(false);
    }
  }, [dataDownloads]);

  useEffect(() => {
    if (location.state && location.state.tab === "downloads") {
      setTabValue(t("tabs.downloads"));
    } else if (location.state && location.state.tab === "datamodel") {
      refetchDatamodel();
      setTabValue("DATAMODEL");
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const saveDeleteTask = (id: number) => {
    setIdToDelete(id);
    toggleDeleteModal();
  };

  const onSuccessCancelTask = () => {
    tabValue === t("tabs.preproration") && refetchPreprorrateo();
    tabValue === t("tabs.proration") && refetchProrrateo();
    tabValue === t("tabs.downloads") && refetchDownloads();
    tabValue === t("tabs.datamodel") && refetchDatamodel();
    tabValue === t("tabs.salesForecast") && refetchSalesForecast();
    tabValue === t("tabs.simulation") && refecthSimulation();
    tabValue === t("tabs.marketBasket") && refecthMarketBasket();
    setIdToDelete(undefined);
  };

  const { isLoading: isLoadingCancelTask, callApi: cancelTask } = useApi(
    undefined,
    "PUT",
    t("taskManager.cancelTask.codes"),
    undefined,
    onSuccessCancelTask,
    undefined,
    false
  );

  const { isLoading: isLoadingDownloadFile, callApi: downloadFile } = useApi(
    undefined,
    "GET",
    t("dataManagement.downloadFile.codes"),
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  );

  const {
    data: taskDetail,
    isLoading: isLoadingTaskDetail,
    callApi: getTaskDetail,
  } = useApi(
    undefined,
    "GET",
    t("taskManager.getTaskDetails.codes"),
    undefined,
    handleToggleErrorsModal,
    undefined,
    false
  );

  const handleDeleteTask = () => {
    if (idToDelete) {
      cancelTask(DELETE_TASK_URL(idToDelete));
    }
    toggleDeleteModal();
  };

  const dataByTabValue = () => {
    switch (tabValue) {
      case t("tabs.preproration"):
        return dataPreprorrateo;
      case t("tabs.proration"):
        return dataProrrateo;
      case t("tabs.downloads"):
        return dataDownloads;
      case t("tabs.datamodel"):
        return datamodel;
      case t("tabs.salesForecast"):
        return salesForecast;
      case t("tabs.simulation"):
        return simulation;
      case t("tabs.marketBasket"):
        return marketBasket;
      default:
        return [];
    }
  };

  const handleGetTaskDetail = (id: number) => {
    getTaskDetail(GET_TASK_ERRORS_URL(id));
  };

  const buttonsActions = {
    saveDeleteTask,
    downloadFile,
    handleGetTaskDetail,
  };

  useEffect(() => {
    if (
      isSuccessPreprorrateo &&
      isSuccessProrrateo &&
      isSuccessDownloads &&
      isSuccessDatamodel &&
      isSuccessSalesForecast &&
      isSuccessSimulation &&
      isSuccessMarketBasket
    ) {
      const { columns, rows } = transformDataTaskManager(
        t,
        dataByTabValue(),
        tabValue,
        buttonsActions
      );
      setColumns(columns);
      setRows(rows);
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [
    tabValue,
    dataPreprorrateo,
    dataProrrateo,
    dataDownloads,
    datamodel,
    simulation,
    salesForecast,
    marketBasket,
  ]);

  const loadings = [
    {
      open: isLoadingCancelTask,
      message: t("taskManager.loadingCancelTask"),
    },
    {
      open: isLoadingDownloadFile,
      message: t("taskManager.loadingDownloadFile"),
    },
    {
      open: isLoadingTaskDetail,
      message: t("taskManager.getTaskDetails.loading"),
    },
  ];

  return (
    <ComponentLayout title={t("titles.taskManager")} icon={<AssignmentIcon />}>
      {loadings.map((loading, index) => (
        <SimpleBackdrop
          key={index}
          open={loading.open}
          message={loading.message}
        />
      ))}
      <ColorTabs
        value={tabValue}
        handleChange={handleChange}
        tabs={tabs}
        marginBottom="20px"
      />
      <Stack width="100%">
        {(tabValue === t("tabs.preproration") && isLoadingPreprorrateo) ||
        (tabValue === t("tabs.proration") && isLoadingProrrateo) ||
        (tabValue === t("tabs.downloads") && isLoadingDownloads) ||
        (tabValue === t("tabs.datamodel") && isLoadingDatamodel) ||
        (tabValue === t("tabs.salesForecast") && isLoadingSalesForecast) ||
        (tabValue === t("tabs.simulation") && isLoadingSimulation) ||
        (tabValue === t("tabs.marketBasket") && isLoadingMarketBasket) ? (
          <CustomSkeleton height={6} />
        ) : (tabValue === t("tabs.preproration") && errorPreprorrateo) ||
          (tabValue === t("tabs.proration") && errorProrrateo) ||
          (tabValue === t("tabs.downloads") && errorDownloads) ||
          (tabValue === t("tabs.datamodel") && errorDatamodel) ||
          (tabValue === t("tabs.salesForecast") && errorSalesForecast) ||
          (tabValue === t("tabs.simulation") && errorSimulation) ||
          (tabValue === t("tabs.marketBasket") && errorMarketBasket) ? (
          <p>{t("taskManager.error")}</p>
        ) : (
          <Stack style={{ height: "70vh", width: "100%", color: "red" }}>
            <DataGrid
              rows={sortTasksByDate(rows)}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[6, 15, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{
                Toolbar: () => (
                  <CustomToolbar
                    toolbar={true}
                    filterButton={true}
                    densitySelector={true}
                  />
                ),
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu={true}
              hideFooter={false}
              checkboxSelection={false}
              disableSelectionOnClick
              sx={{
                boxShadow: 3,
                fontSize: "12px",
                padding: "0 10px 10px 10px",
              }}
            />
          </Stack>
        )}
      </Stack>
      <Dialog
        open={openErrorsModal}
        handleClose={handleToggleErrorsModal}
        title={
          tabValue === t("tabs.datamodel")
            ? t("taskManager.ejecutionDetail")
            : t("taskManager.errorDetail")
        }
        maxWidth={"xl"}
      >
        {(taskDetail && taskDetail?.length < 1) || !taskDetail ? (
          <Stack sx={{ minWidth: "20vw", alignItems: "center" }}>
            <Typography sx={{ color: "var(--text-main)", fontWeight: "300" }}>
              {t("taskManager.noDetailYet")}
            </Typography>
          </Stack>
        ) : (
          taskDetail && (
            <Stack sx={{ minWidth: "75vw" }}>
              <SimpleTable
                columns={
                  transformDataSimpleTable(taskDetail, undefined, undefined, t)
                    .columns
                }
                rows={
                  transformDataSimpleTable(
                    transformColumnDate(taskDetail),
                    undefined,
                    undefined,
                    t
                  ).rows
                }
                toolbar={true}
                exportButton={true}
                filterButton={true}
                rowHeight={50}
              />
            </Stack>
          )
        )}
      </Dialog>
      <ConfirmationModal
        open={openDeleteModal}
        handleClose={toggleDeleteModal}
        handleAccept={handleDeleteTask}
        message={t("taskManager.cancelThisTask")}
        title={t("taskManager.cancelTitle")}
      />
    </ComponentLayout>
  );
};

export default TaskManager;
