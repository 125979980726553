import { ButtonGroup, Stack, Typography } from "@mui/material";
import { Iteration } from "../../types";
import { useEffect } from "react";
import { useParameterization } from "../../../../hooks/useParameterization";
import Button from "@mui/material/Button";
import useLanguage from "../../../../context/LanguageProvider";

const IterationsButtons = () => {
  const { t } = useLanguage();
  const { parameterizationState, parameterizationDispatch, buckets } =
    useParameterization();
  const { selectedStage, bucketSelectedOption, selectedIteration } =
    parameterizationState;

  useEffect(() => {
    if (selectedIteration === undefined) {
      parameterizationDispatch({
        type: "SET_SELECTED_ITERATION",
        payload: {
          iteracion: 1,
          calculado: false,
        },
      });
    }
  }, [selectedIteration]);

  const iterations = () => {
    if (bucketSelectedOption && buckets && selectedStage?.iteraciones) {
      return selectedStage?.iteraciones;
    }
  };

  return (
    <Stack direction="row" minWidth="80px" alignItems={"center"} spacing={2}>
      <Typography
        variant="body1"
        sx={{ color: "var(--text-disabled)", fontWeight: "bold", mr: 1 }}
      >
        {t("modelParameterizationTexts.iterationsButtons.iterations")}:
      </Typography>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        color="primary"
      >
        {selectedIteration &&
          iterations()?.map((stage: Iteration) => {
            return (
              <Button
                key={stage.iteracion}
                onClick={() =>
                  parameterizationDispatch({
                    type: "SET_SELECTED_ITERATION",
                    payload: stage,
                  })
                }
                variant={
                  selectedIteration.iteracion === stage.iteracion
                    ? "contained"
                    : "outlined"
                }
                disabled={false}
              >
                {stage.iteracion}
              </Button>
            );
          })}
      </ButtonGroup>
    </Stack>
  );
};

export default IterationsButtons;
