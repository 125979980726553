import ComponentLayout from "../layout/ComponentLayout";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import { useApiQuery } from "../../hooks/useApiQuery";
import { GET_INDICADORES_GRUPOS_DETALLE } from "../../api/axios";
import Kpis, { Kpi } from "../report/Kpis";
import Stack from "@mui/material/Stack";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Typography from "@mui/material/Typography";
import { Button } from "../buttons";
import PeriodsFilter from "../periods/PeriodsFilter";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";

const IndicatorsView = () => {
  const { t } = useLanguage();
  const {
    data: kpisData,
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
    refetch: refetchIndicators,
  } = useApiQuery(
    GET_INDICADORES_GRUPOS_DETALLE(true),
    false,
    t('report.getKpis.error')
  ); 

  type KpiGroup = {
    id: number;
    nombre: string;
    indicador_archivo: Kpi[];
  };
  
  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();

  const handleClosePeriodsModal = () => {
    refetchIndicators();
    handleTogglePeriodsDialog();
  };

  let index = 0;
  const cycleArray = (array: any[]) => {
    return function () {
      const current = array[index % array.length];
      index++;
      return current;
    };
  };

  const bgColors = ["var(--chart-22)", "var(--middle-violet)", "var(--middle-magenta)", "var(--light-yellow)", "var(--cream)"];

  return (
    <ComponentLayout
      title={t("indicatorsView.indicators")}
      icon={<DataThresholdingIcon />}
      rightComponent={
        <Button
          title={t("indicatorsView.periods")}
          color="blue"
          type="button"
          onClick={() => handleTogglePeriodsDialog()}
        />
      }
    >
      <>
        <SimpleBackdrop
          open={isLoadingKpis}
          message={t('indicatorsView.getIndicators.loading')}
        />
        {isErrorKpis && (
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "var(--text-error)",
              textAlign: "center",
            }}
          >
            {t("indicatorsView.nonPossibleGetIndicators")}
          </Typography>
        )}
        {kpisData &&
          kpisData.every((group: KpiGroup) => {
            return (
              group.indicador_archivo && group.indicador_archivo.length === 0
            );
          }) && (
            <Stack height="100%" mt="15%">
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "var(--text-info)",
                  textAlign: "center",
                }}
              >
                {t("indicatorsView.noIndicatorsCreated")}
              </Typography>
            </Stack>
          )}
        {kpisData &&
          kpisData.map((group: KpiGroup) => {
            return (
              group.indicador_archivo &&
              group.indicador_archivo.length !== 0 && (
                <Stack
                  key={group.id}
                  width="100%"
                  mb="20px"                  
                >
                  <Kpis
                    nombre={group.nombre.toUpperCase()}
                    indicadores={group.indicador_archivo}
                    bgColor={cycleArray(bgColors)}
                  />
                </Stack>
              )
            );
          })}
        <PeriodsFilter
          openPeriodsDialog={openPeriodsDialog}
          handleTogglePeriodsDialog={handleClosePeriodsModal}
        />
      </>
    </ComponentLayout>
  );
};

export default IndicatorsView;
