import { Stack } from "@mui/material";
import { Tab, TabValue } from "../../colorTabs/types";
import { useState, useEffect } from "react";
import ColorTabs from "../../colorTabs/ColorTabs";
import ProrrationDifTable from "./ProrrationDifTable";
import ProrrationErrorTable from "./ProrrationErrorTable";
import ProrrationIterationTable from "./ProrrationIterationTable";
import useLanguage from "../../../context/LanguageProvider";

const ProrrationReportTab = () => {
  const { t, language } = useLanguage();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("reportTexts.prorrationReportTab.tabs.percentageTransfer")
  );

  const tabs: Tab[] = [
    {
      value: t("reportTexts.prorrationReportTab.tabs.percentageTransfer"),
      label: t("reportTexts.prorrationReportTab.tabs.percentageTransfer"),
    },
    {
      value: t("reportTexts.prorrationReportTab.tabs.notTransferredExpenses"),
      label: t("reportTexts.prorrationReportTab.tabs.notTransferredExpenses"),
    },
    {
      value: t(
        "reportTexts.prorrationReportTab.tabs.transferredPerIterationExpenses"
      ),
      label: t(
        "reportTexts.prorrationReportTab.tabs.transferredPerIterationExpenses"
      ),
    },
  ];

  useEffect(()=>{
    setTabValue(t("reportTexts.prorrationReportTab.tabs.percentageTransfer"))
  },[language])

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  return (
    <Stack width="100%">    
        <Stack style={{ height: "70vh", width: "100%" }}>
          <ColorTabs
            value={tabValue}
            handleChange={handleChange}
            tabs={tabs}
            marginBottom="15px"
            fontSize="15px"
          />
          {tabValue ===
            t("reportTexts.prorrationReportTab.tabs.percentageTransfer") && (
            <ProrrationDifTable tabValue={tabValue} />
          )}
          {tabValue ===
            t("reportTexts.prorrationReportTab.tabs.notTransferredExpenses") && (
            <ProrrationErrorTable tabValue={tabValue} />
          )}
          {tabValue ===
            t(
              "reportTexts.prorrationReportTab.tabs.transferredPerIterationExpenses"
            ) && <ProrrationIterationTable tabValue={tabValue} />}
        </Stack>   
    </Stack>
  );
};

export default ProrrationReportTab;
