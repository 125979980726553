import { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate";
import { useSnackbar, VariantType } from "notistack";

export const useApiQuery = (
  url: any,
  refetchOnWindowFocus?: boolean,
  errorMessage?: string,
  refetchInterval?: number
) => {
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const getData = async () => {
    const response = await axiosPrivate.get(url);
    return response.data;
  };
  const info: UseQueryResult<any, Error> = useQuery([url], getData, {
    refetchOnWindowFocus: refetchOnWindowFocus === false ? false : true,
    refetchInterval: refetchInterval ? refetchInterval : false, //milisegundos
    refetchIntervalInBackground: refetchInterval ? true : false,
  });
  info.isError &&
    handleClickVariant(
      (errorMessage && errorMessage) || "Hubo un error, intentelo más tarde.",
      "error"
    );    
  return info;
};
