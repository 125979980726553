import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import Button from "../../buttons/Button";
import ColorTabs from "../../colorTabs/ColorTabs";
import Stack from "@mui/material/Stack";
import NoDataBox from "../../layout/NoDataBox";

const AnalysisDashboardTabs = ({ rolEdit, rolCreate }: any) => {
  const {
    tabValue,
    handleChange,
    tabs,
    viewTabValue,
    viewHandleChange,
    viewTabs,
    toggleNewViewModal,
    toggleDeleteViewModal,
  } = useViewDashboard();

  return (
    <Stack style={{ width: "100%" }}>
      <ColorTabs
        value={tabValue}
        handleChange={handleChange}
        tabs={tabs}
        marginBottom="0px"
        scrollable={true}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        mt="10px"
        gap={2}
        sx={{
          width:
            viewTabs?.length > 1 || viewTabs[0]?.value !== "DEFAULT"
              ? "95%"
              : "100%",
        }}
      >
        {viewTabValue &&
        (viewTabs?.length > 1 || viewTabs[0]?.value !== "DEFAULT") ? (
          <>
            <Stack
              sx={{
                width: rolEdit ? "80%" : "90%",
              }}
            >
              <ColorTabs
                value={viewTabValue}
                handleChange={viewHandleChange}
                tabs={viewTabs}
                marginBottom="0"
                fontSize="15px"
                scrollable={true}
              />
            </Stack>

            {rolEdit && (
              <Button
                title="Eliminar Vista"
                color="grey"
                type="button"
                onClick={() => toggleDeleteViewModal()}
                margin={false}
              />
            )}
            {(rolCreate || rolEdit) && (
              <Button
                title="Nueva Vista"
                color="blue-greeny"
                type="button"
                onClick={() => toggleNewViewModal()}
                margin={false}
              />
            )}
          </>
        ) : (
          <NoDataBox
            text="No hay vistas disponibles"
            component={
              (rolCreate || rolEdit) && (
                <Button
                  title="Nueva Vista"
                  color="blue-greeny"
                  type="button"
                  onClick={() => toggleNewViewModal()}
                  margin={false}
                />
              )
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export default AnalysisDashboardTabs;
