export const emptyRolConfig: RolConfig = {
  administrador: {
    visualizacion_y_edicion: false,
  },
  parametrizacion_de_la_empresa: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  administracion_de_datos: {
    visualizacion: false,
    subir_archivos: false,
    eliminar_archivos: false,
    descargar_archivos: false,
  },
  validaciones: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  administracion_de_criterios: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  configuracion_de_gastos: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  parametrizacion_de_preprorrateo_y_prorrateo: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  administracion_de_dashboard: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  dashboards: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  gestor_de_tareas: {
    visualizacion: false,
  },
  administracion_de_medidas_agrupaciones_y_analisis: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  descargar_reporte: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  descargas: {
    visualizacion_y_descargas: false,
  },
  market_basket: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  simulador: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false
  }
};

export type RolConfig = {
  administrador: {
    visualizacion_y_edicion: boolean;
  };
  parametrizacion_de_la_empresa: {
    visualizacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  administracion_de_datos: {
    visualizacion: boolean;
    subir_archivos: boolean;
    eliminar_archivos: boolean;
    descargar_archivos: boolean;
  };
  validaciones: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  administracion_de_criterios: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  configuracion_de_gastos: {
    visualizacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  parametrizacion_de_preprorrateo_y_prorrateo: {
    visualizacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  administracion_de_dashboard: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  dashboards: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  gestor_de_tareas: {
    visualizacion: boolean;
  };
  administracion_de_medidas_agrupaciones_y_analisis: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  descargar_reporte: {
    visualizacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  descargas: {
    visualizacion_y_descargas: boolean;
  };
  market_basket: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  };
  simulador: {
    visualizacion: boolean;
    visualizacion_y_creacion: boolean;
    visualizacion_y_edicion: boolean;
  }
};
