import { formatColumns2 } from "../../utils";
import { FUNCTION_LABELS } from "../../constants";
import { Stack } from "@mui/material";
import { Tab, TabValue } from "../../../colorTabs/types";
import { useRef, useState } from "react";
import ColorTabs from "../../../colorTabs/ColorTabs";
import OtherMeasureFont from "./ConfigSales/OtherMeasureFont";
import SalesMeasure from "./ConfigSales/SalesMeasure";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";
interface ConfigSalesMeasureProps {
  handleFinish: () => void;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  dataGetMeasure?: any;
  dataGetSales: any[];
  setConfigMode: (configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  dataGetExpenses: any;
  dataGetComposed: any;
}

const ConfigSalesMeasure = ({
  handleFinish,
  configMode,
  dataGetMeasure,
  dataGetSales,
  setConfigMode,
  dataGetExpenses,
  dataGetComposed,
}: ConfigSalesMeasureProps) => {
  const { t } = useLanguage();
  const columnSelectRef: any = useRef(null);
  const columnSelectNumeratorRef: any = useRef(null);
  const [tabValue, setTabValue] = useState<TabValue>(
    dataGetMeasure && dataGetMeasure.archivo
      ? t(
          "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.otherBase"
        )
      : t(
          "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.sales"
        )
  );
  const [newActionName, setNewActionName] = useState(
    configMode === "EDIT" ? dataGetMeasure?.nombre : ""
  );
  const [measureInCurrency, setMeasureInCurrency] = useState<boolean>(
    dataGetMeasure ? dataGetMeasure.medida_en_moneda : false
  );

  /******************************* PESTAÑAS ***********************************/

  const tabs: Tab[] = [
    {
      value: t(
        "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.sales"
      ),
      label: t(
        "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.sales"
      ),
    },
    {
      value: t(
        "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.otherBase"
      ),
      label: t(
        "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.otherBase"
      ),
    },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
    setNewActionName("");
    setFunctionSelectedOptions(undefined);
    setColumnSelectedOptionsNumerator(undefined);
    setColumnSelectedOptionsDenominator(undefined);
    setMeasureInCurrency(false);
    setTabValue(newValue);
  };

  const onFunctionChange = () => {
    columnSelectRef.current?.clearValue();
    columnSelectNumeratorRef.current?.clearValue();
  };

  const [
    functionSelectedOptions,
    setFunctionSelectedOptions,
    functionOptionsChangeHandler,
  ] = useSingleSelect(
    onFunctionChange,
    (configMode === "EDIT" || configMode === "COPY") && dataGetMeasure
      ? {
          value: dataGetMeasure.operacion,
          label: FUNCTION_LABELS[dataGetMeasure.operacion],
        }
      : undefined
  );  

  const [
    columnSelectedOptionsNumerator,
    setColumnSelectedOptionsNumerator,
    columnOptionsChangeHandlerNumerator,
  ] =
    functionSelectedOptions?.value === "DIVIDIR" ||
    functionSelectedOptions?.value === "SUMAR" ||
    functionSelectedOptions?.value === "SUMA-PRODUCTO"
      ? useMultiSelect(
          configMode === "EDIT" || configMode === "COPY"
            ? formatColumns2(dataGetMeasure.columnas)
            : undefined,
          undefined
        )
      : useSingleSelect(
          undefined,
          configMode === "EDIT" || configMode === "COPY"
            ? formatColumns2(dataGetMeasure.columnas)[0]
            : undefined
        );

  const [
    columnSelectedOptionsDenominator,
    setColumnSelectedOptionsDenominator,
    columnOptionsChangeHandlerDenominator,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? formatColumns2(dataGetMeasure.columnas_denominador)
      : undefined,
    undefined
  );

  const configByTabValue = () => {
    switch (tabValue) {
      case t(
        "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.sales"
      ):
        return {
          render: (
            <SalesMeasure
              tabValue={tabValue}
              handleFinish={handleFinish}
              functionSelectedOptions={functionSelectedOptions}
              setFunctionSelectedOptions={setFunctionSelectedOptions}
              functionOptionsChangeHandler={functionOptionsChangeHandler}
              columnSelectedOptionsNumerator={columnSelectedOptionsNumerator}
              setColumnSelectedOptionsNumerator={
                setColumnSelectedOptionsNumerator
              }
              columnOptionsChangeHandlerNumerator={
                columnOptionsChangeHandlerNumerator
              }
              columnSelectedOptionsDenominator={
                columnSelectedOptionsDenominator
              }
              setColumnSelectedOptionsDenominator={
                setColumnSelectedOptionsDenominator
              }
              columnOptionsChangeHandlerDenominator={
                columnOptionsChangeHandlerDenominator
              }
              newActionName={newActionName}
              setNewActionName={setNewActionName}
              configMode={configMode}
              dataGetMeasure={dataGetMeasure}
              columnSelectRef={columnSelectRef}
              columnSelectNumeratorRef={columnSelectNumeratorRef}
              dataGetSales={dataGetSales}
              setConfigMode={setConfigMode}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
              measureInCurrency={measureInCurrency}
              setMeasureInCurrency={setMeasureInCurrency}
            />
          ),
        };

      case t(
        "dataModelText.measureManagement.configMeasure.configSalesMeasure.tabs.otherBase"
      ):
        return {
          render: (
            <OtherMeasureFont
              tabValue={tabValue}
              handleFinish={handleFinish}
              functionSelectedOptions={functionSelectedOptions}
              setFunctionSelectedOptions={setFunctionSelectedOptions}
              functionOptionsChangeHandler={functionOptionsChangeHandler}
              columnSelectedOptionsNumerator={columnSelectedOptionsNumerator}
              setColumnSelectedOptionsNumerator={
                setColumnSelectedOptionsNumerator
              }
              columnOptionsChangeHandlerNumerator={
                columnOptionsChangeHandlerNumerator
              }
              columnSelectedOptionsDenominator={
                columnSelectedOptionsDenominator
              }
              setColumnSelectedOptionsDenominator={
                setColumnSelectedOptionsDenominator
              }
              columnOptionsChangeHandlerDenominator={
                columnOptionsChangeHandlerDenominator
              }
              newActionName={newActionName}
              setNewActionName={setNewActionName}
              configMode={configMode}
              dataGetMeasure={dataGetMeasure}
              columnSelectRef={columnSelectRef}
              columnSelectNumeratorRef={columnSelectNumeratorRef}
              dataGetSales={dataGetSales}
              setConfigMode={setConfigMode}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
            />
          ),
        };
      default:
        return {
          render: (
            <SalesMeasure
              tabValue={tabValue}
              handleFinish={handleFinish}
              functionSelectedOptions={functionSelectedOptions}
              setFunctionSelectedOptions={setFunctionSelectedOptions}
              functionOptionsChangeHandler={functionOptionsChangeHandler}
              columnSelectedOptionsNumerator={columnSelectedOptionsNumerator}
              setColumnSelectedOptionsNumerator={
                setColumnSelectedOptionsNumerator
              }
              columnOptionsChangeHandlerNumerator={
                columnOptionsChangeHandlerNumerator
              }
              columnSelectedOptionsDenominator={
                columnSelectedOptionsDenominator
              }
              setColumnSelectedOptionsDenominator={
                setColumnSelectedOptionsDenominator
              }
              columnOptionsChangeHandlerDenominator={
                columnOptionsChangeHandlerDenominator
              }
              newActionName={newActionName}
              setNewActionName={setNewActionName}
              configMode={configMode}
              dataGetMeasure={dataGetMeasure}
              columnSelectRef={columnSelectRef}
              columnSelectNumeratorRef={columnSelectNumeratorRef}
              dataGetSales={dataGetSales}
              setConfigMode={setConfigMode}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
              measureInCurrency={measureInCurrency}
              setMeasureInCurrency={setMeasureInCurrency}
            />
          ),
        };
    }
  };

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <ColorTabs
          value={tabValue}
          handleChange={handleChangeTab}
          tabs={tabs}
          marginBottom="5px"
        />
      </Stack>
      {configByTabValue().render}
    </>
  );
};

export default ConfigSalesMeasure;
