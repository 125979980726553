import { Box, Stack } from "@mui/material";
import { esTranslations } from "../../esTranslations";
import { useState } from "react";
import ConfirmationModal from "../dialog/ConfirmationModal";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useAuth from "../../hooks/useAuth";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useLogout from "../../hooks/useLogout";
import CustomizedDialogs from "../dialog/Dialog";
import EditPasswordModal from "../administrator/Users/EditPassWordModal";
import { CHANGE_USER_PASS_URL } from "../../api/axios";

const UserAvatar = () => {
  const { t } = useLanguage();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openConfirmLogoutModal, toggleConfirmLogoutModal] = useDialog();
  const [openEditPassword, handleToggleEditPassword] = useDialog();
  const { company }: any = useInfoContext();
  const [
    logout,
    isLoadingLogout,
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    severity,
  ] = useLogout();
  const settings = [t("avatar.changePassword"), t("avatar.logout")];
  const { auth }: any = useAuth();
  const backgroundColor =
    company?.label.toLowerCase() === "juan_test" ||
    company?.label.toLowerCase() === "abru_test"
      ? process.env.REACT_APP_MENU_COLOR_JUAN ||
        process.env.REACT_APP_MENU_COLOR
      : company?.label.toLowerCase() === "nah_test"
      ? process.env.REACT_APP_MENU_COLOR_NAHUEL2 ||
        process.env.REACT_APP_MENU_COLOR
      : company?.label.toLowerCase() === "benja_test"
      ? process.env.REACT_APP_MENU_COLOR_BENJA2 ||
        process.env.REACT_APP_MENU_COLOR
      : process.env.REACT_APP_MENU_COLOR || "var(--bg-main)";

  const handleSetting = (setting: string) => {
    switch (setting) {  
      case t("avatar.changePassword"):
        handleToggleEditPassword();
        break;
      case t("avatar.logout"):
        toggleConfirmLogoutModal();
        break;
      default:
        break;
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    toggleConfirmLogoutModal();
    logout();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={t("header.settings")}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {auth?.user && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: "30px",
                border: "1px solid var(--cream)",
                color: "var(--cream)",
                backgroundColor: backgroundColor,
                display: "flex",
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "uppercase",
                height: "40px",
                letterSpacing: "2px",
                margin: "10px",
                texTransform: "uppercase",
                transition: "all 0.4s",
                minWidth: "120px",
                padding: "0 10px",
                "&:hover": {
                  border: "none",
                  color: "var(--text-main)",
                  backgroundColor: "var(--cream)",
                },
              }}
            >
              {auth?.user.split("@")[0]}
              <ExpandMoreIcon />
            </Stack>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography
              textAlign="center"
              sx={{
                color:"var(--text-main)"
              }}
              onClick={() => handleSetting(setting)}
            >
              {setting}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <SimpleBackdrop
        open={isLoadingLogout}
        message={esTranslations.avatar.logoutLoading}
      />
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleToggleSnackbar}
        message={messageSnackbar}
        severity={severity}
      />
      <ConfirmationModal
        open={openConfirmLogoutModal}
        handleClose={toggleConfirmLogoutModal}
        handleAccept={handleLogout}
        message={esTranslations.avatar.logout.toLowerCase()}
        title={esTranslations.avatar.logout}
      />
      <CustomizedDialogs
        open={openEditPassword}
        handleClose={handleToggleEditPassword}
        title={t("administratorPanel.users.editPasswordLabel")}
        maxWidth="sm"
      >
        <EditPasswordModal
          handleClose={handleToggleEditPassword}
          NEW_PASSWORD_URL={CHANGE_USER_PASS_URL}
        />
      </CustomizedDialogs>
    </Box>
  );
};

export default UserAvatar;
