import { selectStyles } from "../../../configCriteria/select.styles";
import AddButton from "../../../buttons/AddButton";
import DeleteForever from "../../../icons/DeleteForever";
import Grid from "@mui/material/Grid/Grid";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import useLanguage from "../../../../context/LanguageProvider";
import {
  CONS_DESTINY_FUNCTIONS,
  CONS_PERIODS_FUNCTIONS,
  METRIC_FUNCTIONS,
} from "../data";
import {
  formatPrimaryMetricRow,
  isRepeatedName,
  validatePrimaryMetricRow,
} from "../utils";
import {
  form_helper_text_styles,
  row_delete_container_styles,
} from "../../../../styles/app-styles";
interface PrimaryMetricsSelectsProps {
  titles: string[];
  setMessage: any;
  totalColumnOptions: any;
  rows: any[];
  handleSetNewRows: any;
  handleCleanRows: any;
  column1SelectRef: any;
  column2SelectRef: any;
  column3SelectRef: any;
  column4SelectRef: any;
  column5SelectRef: any;
  column6SelectRef?: any;
  column1SelectedOption: any;
  setColumn1SelectedOption: any;
  column2SelectedOption: any;
  setColumn2SelectedOption: any;
  column2OptionsChangeHandler: any;
  column3SelectedOption: any;
  setColumn3SelectedOption: any;
  column3OptionsChangeHandler: any;
  column4SelectedOption: any;
  setColumn4SelectedOption: any;
  column4OptionsChangeHandler: any;
  column5SelectedOption: any;
  setColumn5SelectedOption: any;
  column5OptionsChangeHandler: any;
  column6SelectedOption?: any;
  setColumn6SelectedOption?: any;
  column6OptionsChangeHandler?: any;
  totalRows: any;
}

const PrimaryMetricsSelects = ({
  titles,
  setMessage,
  totalColumnOptions,
  rows,
  handleSetNewRows,
  handleCleanRows,
  column1SelectRef,
  column2SelectRef,
  column3SelectRef,
  column4SelectRef,
  column5SelectRef,
  column6SelectRef,
  column1SelectedOption,
  setColumn1SelectedOption,
  column2SelectedOption,
  setColumn2SelectedOption,
  column2OptionsChangeHandler,
  column3SelectedOption,
  setColumn3SelectedOption,
  column3OptionsChangeHandler,
  column4SelectedOption,
  setColumn4SelectedOption,
  column4OptionsChangeHandler,
  column5SelectedOption,
  setColumn5SelectedOption,
  column5OptionsChangeHandler,
  column6SelectedOption,
  setColumn6SelectedOption,
  column6OptionsChangeHandler,
  totalRows,
}: PrimaryMetricsSelectsProps) => {
  const { t } = useLanguage();

  const titlesQuantity = titles.length;

  const cleanSelects = () => {
    setColumn1SelectedOption("");
    setColumn2SelectedOption(undefined);
    setColumn3SelectedOption(undefined);
    setColumn4SelectedOption(undefined);
    setColumn5SelectedOption(undefined);
    setColumn6SelectedOption && setColumn6SelectedOption(undefined);
    column2SelectRef?.current?.clearValue();
    column3SelectRef?.current?.clearValue();
    column4SelectRef?.current?.clearValue();
    column5SelectRef?.current?.clearValue();
    column6SelectRef?.current?.clearValue();
  };

  const handleAddObjectiveRow = () => {
    if (
      validatePrimaryMetricRow(
        t,
        rows,
        setMessage,
        titles,
        column1SelectedOption,
        column2SelectedOption,
        column3SelectedOption,
        column4SelectedOption,
        column5SelectedOption,
        column6SelectedOption,
        totalRows
      )
    ) {
      const newRow = formatPrimaryMetricRow(
        column1SelectedOption,
        column2SelectedOption,
        column3SelectedOption,
        column4SelectedOption,
        column5SelectedOption,
        column6SelectedOption
      );
      const newRows = [...rows];
      newRows.push(newRow);
      handleSetNewRows(newRows);
      setMessage({
        type: "",
        text: "",
      });
      cleanSelects();
    }
  };

  const handleDeleteAllRows = () => {
    handleCleanRows();
    setMessage({
      type: "",
      text: "",
    });
    cleanSelects();
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <TextField
          ref={column1SelectRef}
          type="text"
          label={t("general.name")}
          variant="outlined"
          fullWidth
          value={column1SelectedOption}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setColumn1SelectedOption(event.target.value);
          }}
          error={
            column1SelectedOption === "" ||
            isRepeatedName(column1SelectedOption, totalRows)
          }
          FormHelperTextProps={{ sx: form_helper_text_styles }}
          size="small"
          required={true}
        />
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Select
          reference={column2SelectRef}
          styles={selectStyles(column2SelectedOption)}
          options={METRIC_FUNCTIONS(t)}
          onChange={column2OptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={column2SelectedOption}
        />
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Select
          reference={column3SelectRef}
          styles={selectStyles(column3SelectedOption)}
          options={totalColumnOptions}
          onChange={column3OptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={column3SelectedOption}
        />
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        {column2SelectedOption?.value === "DIVIDIR" && (
          <Select
            reference={column4SelectRef}
            styles={selectStyles(column4SelectedOption)}
            options={totalColumnOptions}
            onChange={column4OptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("general.chooseColumn")}
            isClearable
            defaultValue={column4SelectedOption}
          />
        )}
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Select
          reference={column5SelectRef}
          styles={selectStyles(column5SelectedOption)}
          options={CONS_PERIODS_FUNCTIONS(t)}
          onChange={column5OptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={column5SelectedOption}
        />
      </Grid>
      {column6SelectRef && column6OptionsChangeHandler && (
        <Grid item xs={11 / titlesQuantity}>
          <Select
            reference={column6SelectRef}
            styles={selectStyles(column6SelectedOption)}
            options={CONS_DESTINY_FUNCTIONS(t)}
            onChange={column6OptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("general.chooseColumn")}
            isClearable
            defaultValue={column6SelectedOption}
          />
        </Grid>
      )}
      <Grid item xs={0.5}>
        <AddButton align={true} onClick={() => handleAddObjectiveRow()} />
      </Grid>
    </>
  );
};

export default PrimaryMetricsSelects;
