import { column_styles } from "../../../../styles/app-styles";
import { convertArrayToText } from "../../../taskManager/utils";
import { Item } from "../../../basicGrid/BasicGrid";
import Grid from "@mui/material/Grid";
import OptionTabDummy from "./OptionTabDummy";
import useLanguage from "../../../../context/LanguageProvider";

const DummyGrouperGroup = ({
  groupKey,
  fileColumnSelectOptions,
  dummyData,
  onChangeDummyOption,
}) => {
  const { t } = useLanguage()

  const groupersText = convertArrayToText(
    dummyData.agrupadores.map((grouper) => {
      return grouper.label;
    })
  )

  return (
    <>
      <Grid item xs={12} mt="20px">
        <Item sx={column_styles("var(--text-main)")}>
          {`${t("modelParameterizationTexts.dummyGrouperGroup.groupers")}:
          ${groupersText}`}
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item sx={column_styles("var(--bg-secondary)")}>
        {t("modelParameterizationTexts.dummyGrouperGroup.expenseColumn")}
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item sx={column_styles("var(--bg-secondary)")}>
        {t("modelParameterizationTexts.dummyGrouperGroup.salesFileColumn")}
        </Item>
      </Grid>
      {dummyData.columnas_valida.map((column, index) => {
        return (
          <OptionTabDummy
            key={index}
            groupKey={groupKey}
            fileColumnSelectOptions={fileColumnSelectOptions}
            dummyData={column}
            onChangeDummyOption={onChangeDummyOption}
          />
        );
      })}
      {dummyData.destinos_anteriores.map((target, index) => {
        return (
          <OptionTabDummy
            key={index}
            groupKey={groupKey}
            fileColumnSelectOptions={fileColumnSelectOptions}
            dummyData={target.receptor}
            onChangeDummyOption={onChangeDummyOption}
            stage={target.etapa}
          />
        );
      })}
    </>
  );
};

export default DummyGrouperGroup;
