import { getPeriodsLabelsFromPeriodsIds } from "../utils";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import Chip from "@mui/material/Chip/Chip";
import Stack from "@mui/material/Stack";

const ChipFilters = () => {
  const {
    filters,
    setFilters,
    tempFilters,
    setTempFilters,
    selectedPeriods, analysisPeriods,
    setSelectedPeriods,
  } = useViewDashboard();

  const handleDeleteFilter = (e, filterToDelete) => {
    const updatedTempFilters = tempFilters?.filter(
      (filter) => filter.column.label !== filterToDelete.column.label
    );
    setTempFilters(updatedTempFilters);
    const filter = {} as any;
    updatedTempFilters?.forEach((option: any) => {
      filter[option.column.label] = option.values.map((value) => value.value);
    });
    const periodsLabels = getPeriodsLabelsFromPeriodsIds(
      selectedPeriods,
       analysisPeriods.data, 
    );
    if (filterToDelete.column.label === "PERIODO") {
      setSelectedPeriods([]);
      filter["PERIODO"] = [];
    } else {
      filter["PERIODO"] = periodsLabels ? periodsLabels : undefined;
    }
    setFilters(filter);
  };

  const transformFiltersToArray = () => {
    const filtersArray = [] as any;
    Object.keys(filters).forEach((key) => {
      filtersArray.push({
        column: {
          label: key,
          value: tempFilters.find((filter) => filter.column.label === key)
            ?.column.value,
        },
        values: filters[key].map((value: any) => ({
          label: value,
          value: value,
        })),
      });
    });
    return filtersArray;
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginTop: "15px", justifyContent: "flex-start", width: "100%" }}
    >
      {filters &&
        transformFiltersToArray()?.map((filter) => {
          return (
            filter.values.map((value) => value.label).length !== 0 && (
              <Chip
                key={filter.column.label}
                label={`${filter.column.label}: ${filter.values
                  .map((value) => value.label)
                  .join(", ")}`}
                onDelete={(e) => handleDeleteFilter(e, filter)}
              />
            )
          );
        })}
    </Stack>
  );
};

export default ChipFilters;
