import { CustomizedTable } from "../customizedTable";
import { getIcon } from "./utils";
import { info_section_container_styles } from "../../styles/app-styles";
import Dialog from "../dialog/Dialog";
import EditCriteriaButton from "../buttons/EditCriteriaButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";
import {
  convertObjectArrayToText,
  convertToText,
} from "../criteriaManagement/utils";
import { convertArrayToText } from "../taskManager/utils";
import useInfoContext from "../../hooks/useInfoContext";

interface ViewInfoProps {
  indicator: any;
  open: boolean;
  handleEdit: (e: any, id: number) => void;
  handleClose: () => void;
  title: string | undefined;
}

const AnalysisViewInfoModal = ({
  indicator,
  open,
  handleClose,
  handleEdit,
  title,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const COLUMNS0 = [
    t("indicatorsManagementTexts.viewInfoModal.columns.function"),
    t("indicatorsManagementTexts.viewInfoModal.columns.column"),
    t("indicatorsManagementTexts.viewInfoModal.columns.name"),
  ];
  const ROWS0 = indicator?.columnas?.map((column: any) => {
    return {
      Funcion: column.funcion,
      Columna: column.columna?.nombre,
      Nombre: column.nombre,
    };
  });

  const COLUMNS1 = [
    t("indicatorsManagementTexts.viewInfoModal.columns.column"),
    t("indicatorsManagementTexts.viewInfoModal.columns.values"),
    t("indicatorsManagementTexts.viewInfoModal.columns.criteria"),
  ];
  const ROWS1 = !indicator?.tipo
    ? indicator?.indicador_archivo_columna_filtra
        ?.filter((item: any) => {
          return !item.externo;
        })
        .map((item: any) => {
          return {
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.indicador_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.include"),
          };
        })
    : indicator?.grafico_archivo_columna_filtra
        ?.filter((item: any) => {
          return !item.externo;
        })
        .map((item: any) => {
          return {
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.grafico_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.include"),
          };
        });

  const COLUMNS3 = [
    t(""),
    t("indicatorsManagementTexts.viewInfoModal.columns.values"),
    t("indicatorsManagementTexts.viewInfoModal.columns.criteria"),
  ];
  const ROWS3 = !indicator?.tipo
    ? indicator?.indicador_archivo_columna_filtra
        ?.filter((item: any) => {
          return item.externo;
        })
        .map((item: any) => {
          return {
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.indicador_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.columns.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.columns.include"),
          };
        })
    : indicator?.grafico_archivo_columna_filtra
        ?.filter((item: any) => {
          return item.externo;
        })
        .map((item: any) => {
          return {
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.grafico_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.include"),
          };
        });

  const COLUMNS4 = [
    `${t("indicatorsManagementTexts.viewInfoModal.fileColumn")} ${
      indicator?.archivo_externo_validacion?.label
    }`,
    `${t("indicatorsManagementTexts.viewInfoModal.fileColumn")} ${
      indicator?.archivo?.label
    }`,
  ];
  const ROWS4 = !indicator?.tipo
    ? indicator?.indicador_archivo_columna_valida
        ?.filter((item: any) => {
          return item.externo;
        })
        .map((item: any) => {
          return {
            [t("indicatorsManagementTexts.viewInfoModal.externFileColumn")]:
              item?.indicador_columna_gastos_o_externo,
            [t("indicatorsManagementTexts.viewInfoModal.criteriaBaseColumn")]:
              item?.indicador_columna_archivo_base,
          };
        })
    : indicator?.grafico_archivo_columna_valida
        ?.filter((item: any) => {
          return item.externo;
        })
        .map((item: any) => {
          return {
            [t("indicatorsManagementTexts.viewInfoModal.externFileColumn")]:
              item?.grafico_columna_gastos_o_externo,
            [t("indicatorsManagementTexts.viewInfoModal.criteriaBaseColumn")]:
              item?.grafico_columna_archivo_base,
          };
        });

  const handleEditAndClose = (e: any, id: number) => {
    handleEdit(e, id);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} handleClose={handleClose} title={title}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          position="relative"
          alignItems="center"
          padding="0 100px 30px 100px"
        >
          <Stack
            sx={{
              alignItems: "center",
              borderRadius: "5px",
              boxShadow: "2px 2px 10px 2px rgb(0 0 0 / 20%)",
              color: "var(--text-main)",
              justifyContent: "flex-start",
              margin: "0 0 30px 0 !important",
              minWidth: "500px",
              padding: "14px 23px",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
              }}
            >
              {indicator?.tipo
                ? t("indicatorsManagementTexts.viewInfoModal.generalInfoGraph")
                : t(
                    "indicatorsManagementTexts.viewInfoModal.generalInfoIndicator"
                  )}
            </Typography>
            {!indicator?.tipo ? (
              <>
                <p style={{ color: "var(--text-main)", margin: "5px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t(
                      "indicatorsManagementTexts.viewInfoModal.unitOfMeasurement"
                    )}
                    :{" "}
                  </span>
                  <span style={{ textTransform: "capitalize" }}>
                    {indicator?.unidad_medida}
                  </span>
                </p>
              </>
            ) : (
              <>
                <Stack
                  direction="row"
                  style={{
                    color: "var(--text-main)",
                    margin: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {t("indicatorsManagementTexts.viewInfoModal.graphType")}:{" "}
                  </span>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    spacing={1}
                    textTransform="capitalize"
                    marginLeft={"10px"}
                  >
                    {getIcon(indicator.tipo)}
                    <Typography
                      sx={{
                        textAlign: "left",
                        alignItems: "center",
                        display: "flex",
                        paddingTop: "2px",
                      }}
                    >
                      {indicator.tipo}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t("indicatorsManagementTexts.viewInfoModal.analysis") + ": "}
              </span>
              {indicator?.archivo?.label}
            </p>
            {!indicator?.tipo ||
            (indicator?.tipo &&
              (indicator.tipo === "TORTA CON DRILLDOWN" ||
                indicator.tipo === "TREEMAP CON DRILLDOWN")) ? (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("indicatorsManagementTexts.viewInfoModal.baseColumn") +
                    ": "}
                </span>
                {!indicator?.tipo
                  ? indicator?.columna?.label
                  : indicator?.columna_base?.label}
              </p>
            ) : (
              indicator?.tipo !== "WATERFALL" && (
                <>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t("indicatorsManagementTexts.viewInfoModal.axisY") +
                        ": "}
                    </span>
                    {indicator?.columna_eje_y && indicator?.columna_eje_y?.length !== 0
                    ? convertArrayToText(
                        indicator?.columna_eje_y?.map(
                          (item: any) => item.columna.label
                        )
                      )
                    : indicator?.columna_eje_y && indicator?.columna_eje_y[0]?.label}
                  </p>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t("indicatorsManagementTexts.viewInfoModal.axisYName")}:
                    </span>
                    <span
                      style={{ marginLeft: "5px", textTransform: "uppercase" }}
                    >
                      {indicator?.nombre_eje_y}
                    </span>
                  </p>
                </>
              )
            )}
            {indicator?.tipo !== "WATERFALL" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t(
                    "indicatorsManagementTexts.viewInfoModal.appliedFunction"
                  ) + ": "}
                </span>
                {indicator?.funcion}
              </p>
            )}
            {indicator?.funcion === "SUMA.UNICO" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t(
                    "indicatorsManagementTexts.viewInfoModal.validateUniqueColumn"
                  )}
                  :
                </span>
                {indicator?.columna_indicador_unico?.label}
              </p>
            )}
            {indicator?.funcion === "CONTAR.UNICO" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t(
                    "indicatorsManagementTexts.viewInfoModal.validateUniqueColumn"
                  )}
                  :
                </span>
                {indicator?.columna_grafico_unico?.label}
              </p>
            )}
            {indicator?.funcion === "SUMA.PRODUCTO" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("indicatorsManagementTexts.viewInfoModal.multiplyColumn") +
                    ": "}
                </span>
                {indicator?.indicador_archivo_columna_multiplicar &&
                  convertToText(
                    indicator?.indicador_archivo_columna_multiplicar,
                    t
                  )}
              </p>
            )}
            {indicator?.funcion === "DIVISION" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("indicatorsManagementTexts.step1.denominator") + ": "}
                </span>
                {indicator?.columna_eje_y?.length !== 0
                    ? convertArrayToText(
                        indicator?.columna_eje_y.map(
                          (item: any) => item.columna_divisor.label
                        )
                      )
                    : indicator?.columna_eje_y[0]?.label}
              </p>
            )}
            {indicator?.tipo &&
              (indicator.tipo === "LINEAS" ||
                indicator.tipo === "BARRAS CON DRILLDOWN" ||
                indicator.tipo === "SCATTERPLOT") && (
                <>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t("indicatorsManagementTexts.viewInfoModal.axisX")}:{" "}
                    </span>
                    <span
                      style={{ marginLeft: "5px", textTransform: "uppercase" }}
                    >
                      {indicator?.columna_eje_x.label}
                    </span>
                  </p>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t("indicatorsManagementTexts.viewInfoModal.axisXName")}:
                    </span>
                    <span
                      style={{ marginLeft: "5px", textTransform: "uppercase" }}
                    >
                      {indicator?.nombre_eje_x}
                    </span>
                  </p>
                </>
              )}
            {indicator?.tipo &&
              indicator.columnas_drilldown &&
              indicator.columnas_drilldown.length !== 0 && (
                <>
                  <div style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t(
                        "indicatorsManagementTexts.viewInfoModal.drilldownColumn"
                      )}
                      :
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      <ol style={{ margin: 0 }}>
                        {indicator?.columnas_drilldown &&
                          indicator?.columnas_drilldown.map((col, index) => (
                            <li key={index}>{col.label}</li>
                          ))}
                      </ol>
                    </span>
                  </div>
                </>
              )}
            {indicator?.tipo && indicator.columna_series && (
              <>
                <p style={{ color: "var(--text-main)", margin: "5px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t("indicatorsManagementTexts.viewInfoModal.serialColumns")}
                    :
                  </span>
                  <span
                    style={{ marginLeft: "5px", textTransform: "uppercase" }}
                  >
                    {indicator?.columna_series.label}
                  </span>
                </p>
              </>
            )}
            {indicator?.tipo && indicator.tipo === "SCATTERPLOT" && (
              <>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t("indicatorsManagementTexts.viewInfoModal.valuesColumn")}
                    :
                  </span>
                  <span
                    style={{ marginLeft: "5px", textTransform: "uppercase" }}
                  >
                    {indicator?.columna_valores?.label}
                  </span>
                </p>
                <div style={{ color: "var(--text-main)", margin: "10px 5px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t("indicatorsManagementTexts.step1Charts.auxiliaryLines") +
                      ": "}
                    :
                  </span>
                  <span>
                    <ul style={{ margin: 0 }}>
                      {indicator?.lineas_auxiliares &&
                        indicator?.lineas_auxiliares && (
                          <>
                            {indicator?.lineas_auxiliares?.linea_cero && (
                              <li>
                                {t(
                                  "indicatorsManagementTexts.step1Charts.line0AxisY"
                                )}
                              </li>
                            )}

                            {indicator?.lineas_auxiliares?.linea_promedio_x && (
                              <li>
                                {" "}
                                {t(
                                  "indicatorsManagementTexts.step1Charts.averageLineAxisX"
                                )}
                              </li>
                            )}

                            {indicator?.lineas_auxiliares?.linea_promedio_y && (
                              <li>
                                {" "}
                                {t(
                                  "indicatorsManagementTexts.step1Charts.averageLineAxisY"
                                )}
                              </li>
                            )}
                          </>
                        )}
                    </ul>
                  </span>
                </div>
              </>
            )}

            {indicator?.tipo === "WATERFALL" && ROWS0?.length > 0 && (
              <Stack sx={info_section_container_styles}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "var(--text-main)",
                    fontWeight: "bold",
                    paddingBottom: "5px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("indicatorsManagementTexts.viewInfoModal.filtersOnFile")}{" "}
                  {indicator?.archivo?.label}
                </Typography>
                <CustomizedTable COLUMNS={COLUMNS0} ROWS={ROWS0} width={450} />
              </Stack>
            )}
            {ROWS1?.length > 0 && (
              <Stack sx={info_section_container_styles}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "var(--text-main)",
                    fontWeight: "bold",
                    paddingBottom: "5px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("indicatorsManagementTexts.viewInfoModal.filtersOnFile")}{" "}
                  {indicator?.archivo?.label}
                </Typography>
                <CustomizedTable COLUMNS={COLUMNS1} ROWS={ROWS1} width={450} />
              </Stack>
            )}
            {ROWS4?.length > 0 && (
              <Stack sx={info_section_container_styles}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "var(--text-main)",
                    fontWeight: "bold",
                    paddingBottom: "20px",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  {t("indicatorsManagementTexts.viewInfoModal.filterOf_one")}{" "}
                  {indicator?.archivo?.label}{" "}
                  {t("indicatorsManagementTexts.viewInfoModal.filterOf_two")}{" "}
                  {indicator?.archivo_externo_validacion?.label}
                </Typography>
                {ROWS3.length !== 0 && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "var(--text-main)",
                        fontWeight: "bold",
                        paddingBottom: "5px",
                        textTransform: "uppercase",
                      }}
                    >
                      {t(
                        "indicatorsManagementTexts.viewInfoModal.prefilterOnFile"
                      )}{" "}
                      {indicator?.archivo_externo_validacion?.label}
                    </Typography>
                    <CustomizedTable
                      COLUMNS={COLUMNS3}
                      ROWS={ROWS3}
                      width={450}
                    />{" "}
                  </>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    color: "var(--text-main)",
                    fontWeight: "bold",
                    paddingBottom: "5px",
                    paddingTop: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("indicatorsManagementTexts.viewInfoModal.crossoverValues")}{" "}
                  {indicator?.archivo_externo_validacion?.label}{" "}
                  {t("indicatorsManagementTexts.viewInfoModal.with")}{" "}
                  {indicator?.archivo?.label}
                </Typography>
                <CustomizedTable COLUMNS={COLUMNS4} ROWS={ROWS4} width={450} />
              </Stack>
            )}
          </Stack>
          {userRolData && userRolData.administracion_de_criterios.visualizacion_y_edicion && <EditCriteriaButton
            handleClick={handleEditAndClose}
            selectedCriteria={indicator}
          />}
        </Stack>
      </Dialog>
    </div>
  );
};

export default AnalysisViewInfoModal;
