import { GridColumns } from "@mui/x-data-grid-pro";
import { getInformationColumn, roundNumber } from "../../../../utils";
import { convertNumberToMoney } from "../../../../../parameterization/utils";

export const getColumnsAverages = (rows, informationsColumns) => {

    const uniqueFlags: string = rows.reduce((flags, row) => {
        Object.keys(row).forEach(flag => {
            if (flag !== "id") {
                flags.add(flag);
            }
        });
        return flags;
    }, new Set());

    const columnsNames = [...Array.from(uniqueFlags)]

    const columns: GridColumns = columnsNames.map((column: string) => {
        return {
            field: column,
            headerName: column,
            headerClassName: "dm_header_column",
            renderHeader: () => {
                return (
                    <strong className="dm_column_title">{column}</strong>
                )
            },
            flex: 1,
            headerAlign: "center",
            maxWidth: 400,
            align: "center",
            wrapText: true,
            renderCell: (params: any) => {
                if(getInformationColumn(informationsColumns, "Volumen en Unidades") === column){
                    return <>{roundNumber(Number(params.value),2)}</>
                }else{
                    return <>{convertNumberToMoney(params.value)}</>
                }
            },
        };
    });

    return columns;
};