import { Divider, Stack, Typography } from "@mui/material";
import { handleRemoveLastElement, operadores } from "./utils";
import useLanguage from "../../context/LanguageProvider";

interface OperatorsCalculatorProps {
  operators: string[];
  handleAddOperatorToFormula: (operator: string, formula, setFormula) => void;
  operatorInLast: boolean,
  formula: any[],
  medidaInLast: boolean,
  dotOperator: boolean,
  setFormula: any
}

const OperatorsCalculator = ({
  operators,
  handleAddOperatorToFormula,
  operatorInLast,
  formula,
  medidaInLast,
  dotOperator,
  setFormula
}: OperatorsCalculatorProps) => {
  const { t } = useLanguage();

  const shouldValidations = (operator, conditions) => {
    return conditions.some(condition => condition(operator));
  };

  const formulaIsEmpty = (operator) => !formula?.length && operadores.includes(operator) && operator !== "(" && operator !== "<";

  const dotOperatorLastItem = (operator) => {
    const previousOperator = formula[formula?.length - 1];
    return dotOperator && previousOperator?.dato?.toString()?.endsWith(".") && operadores.includes(operator) && operator !== "<";
  };

  const medidaInLastItem = (operator) => {
    return medidaInLast && (!operadores.includes(operator) || operator === "." || operator === "(");
  };

  const operatorInLastItem = (operator) => {
    return operatorInLast && (operadores.includes(operator) && operator !== "<" && operator !== "(");
  };

  const openParenthesisInLastItem = (operator) => {
    const previousOperator = formula[formula?.length - 1];
    return previousOperator?.dato === "(" && (operadores.includes(operator) && operator !== ")" && operator !== "(" && operator !== "<");
  };


  return (
    <Stack
      sx={{
        width: "350px",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
        gap: "10px",
        userSelect: "none",
      }}
    >
      <Typography variant="h5">
      {t('dataModelText.measureManagement.configMeasure.configCompounds.operatorsCalculator.operators')}
      </Typography>
      <Divider
        sx={{
          width: "100%",
          backgroundColor: "var(--bg-main)",
        }}
      />
      <Stack
        sx={{
          width: "300px",
          flexDirection: "row",
          flexWrap: "wrap",
          height: "315px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {operators.map((operator: string) => {
          const applyValidations = shouldValidations(operator, [
            formulaIsEmpty,
            dotOperatorLastItem,
            medidaInLastItem,
            openParenthesisInLastItem,
            operatorInLastItem,
          ]);

          return (
            operator === "" ?
              <Stack sx={{
                width: "60px"
              }}
              key={operator}>

              </Stack>
              :
              <Stack
                sx={{
                  width: operator === "<" ? "130px" : "60px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  border: "1px solid rgba(0,0,0,0.2)",
                  padding: "15px",
                  boxShadow: "2px 2px rgba(0,0,0,0.5)",
                  backgroundColor:
                    applyValidations ? "var(--bg-disabled)" :
                      operator === "<" ? "var(--bg-error)" :
                        "#E0E0E0",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  "&:hover": {
                    backgroundColor:
                      operator === "<" ? "#a7072d" : "var(--bg-disabled)",
                  },
                  "&:active": {
                    boxShadow:
                      applyValidations ? "2px 2px rgba(0,0,0,0.5)" :
                        0,
                  }
                }}
                onClick={() =>
                  applyValidations ? undefined :
                    operator != "<"
                      ? handleAddOperatorToFormula(operator, formula, setFormula)
                      : handleRemoveLastElement(formula, setFormula)
                }
                key={operator}
              >
                <Typography
                  sx={{
                    fontWeight: operadores.includes(operator) ? "1000" : "bold",
                    color:
                      operator === "<" ? "white" : operadores.includes(operator)
                        ? "var(--text-error)"
                        : "black",
                  }}
                  variant="subtitle1"
                >
                  {operator}
                </Typography>
              </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default OperatorsCalculator;
