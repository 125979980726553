import { FormControl, OutlinedInput, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import useLanguage from '../../context/LanguageProvider'
import { roundNumber } from './utils'

interface InputAdjustmentRateProps {
    title: string,
    actualState: number | undefined,
    adjustmentState: number | string,
    setAdjustmentState: (adjustmentState: number | string) => void,
    projectedValue: number | string | undefined,
    setProjectedValue: Dispatch<SetStateAction<string | number>>,
    adjustmentRate?: boolean,
}

const InputAdjustmentRate = ({
    title,
    actualState,
    adjustmentState,
    setAdjustmentState,
    projectedValue,
    setProjectedValue,
    adjustmentRate
}: InputAdjustmentRateProps) => {

    const { t } = useLanguage()

    const handleChangeAdjustmentValue = (e: React.ChangeEvent<HTMLInputElement>) => {

        const inputValue = e.target.value;
        const minPercentage = -100;
        const regexNumber = /^(-?\d*\.?\d*)$/ // Permitir números del 0 al 9 y un punto decimal y el -

        if (inputValue === "-" && regexNumber.test(inputValue)) {
            if (inputValue === "-") {
                setAdjustmentState("-");
                setProjectedValue("");
            } else {
                setProjectedValue(Number(actualState) * (1 + (Number(inputValue) / 100)));
            }
        } else if (Number(inputValue) < minPercentage) {
            setAdjustmentState(minPercentage)
            setProjectedValue(0)
        } else if (regexNumber.test(inputValue)) {
            if (inputValue === "") {
                setProjectedValue("")
                setAdjustmentState(inputValue)
            } else {
                setAdjustmentState(inputValue)
                setProjectedValue(Number(actualState) * (1 + (Number(inputValue) / 100)))
            }
        }
    }

    const handleChangeProjectedValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regexNumber = /^[0-9.]*$/; // Permitir números del 0 al 9 y un punto decimal
        const inputValue = e.target.value;
        if (regexNumber.test(inputValue)) {
            if (inputValue === "") {
                setAdjustmentState("")
                setProjectedValue(inputValue)
            } else {
                setProjectedValue(inputValue);
                setAdjustmentState((Number(inputValue) / Number(actualState) - 1) * 100)
            }
        }
    }

    return (
        <Stack sx={{
            width: "100%"
        }}>
            <Typography sx={{
                width: "100%",
                fontWeight: "bold",
                color: !adjustmentRate ? "var(--text-disabled)" : "var(--text-main)"
            }}>
                {title}
            </Typography>
            <Stack sx={{
                width: "100%",
                flexDirection: "row",
                gap: "20px"
            }}>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                    <Typography sx={{
                        width: "50%",
                        textDecoration: "underline",
                        color: !adjustmentRate ? "var(--text-disabled)" : "var(--text-main)"
                    }}>
                        {t("simulatorTexts.registerRoutes.inputAdjustmentRate.adjustmentRate")}:
                    </Typography>
                    <OutlinedInput
                        type='text'
                        id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        size="small"
                        value={adjustmentState !== "" ? typeof adjustmentState === "number" ? roundNumber(Number(adjustmentState), 2) : adjustmentState : ""}
                        onChange={handleChangeAdjustmentValue}
                        error={adjustmentState === "" || adjustmentState === "-"}
                        disabled={!adjustmentRate}
                    />
                </FormControl>
                <Stack sx={{
                    width: "100%"
                }}>
                    <Typography sx={{
                        width: "100%",
                        textDecoration: "underline",
                        color: !adjustmentRate ? "var(--text-disabled)" : "var(--text-main)"
                    }}>
                        {t("simulatorTexts.registerRoutes.inputAdjustmentRate.adjustedValue")}:
                    </Typography>
                    <TextField
                        size="small"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={projectedValue !== "" ? roundNumber(Number(projectedValue), 2) : ""}
                        error={projectedValue === ""}
                        onChange={handleChangeProjectedValue}
                        disabled={!adjustmentRate}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default InputAdjustmentRate