import Button from "@mui/material/Button";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import { Box } from "@mui/material";

interface CopyButtonProps {
  handleClick: (e: any, id: number) => void;
  selectedCriteria: any;
}

const CopyButton = ({ handleClick, selectedCriteria }: CopyButtonProps) => {
  return (
    <Box
      sx={{
        position: "absolute",
        marginTop: "20px !important",
        right: "32px",
        top: "50px",
        width: "35px",
      }}
    >
      <Button
        onClick={(e) => {
          handleClick(e, selectedCriteria.id);
        }}
        variant="contained"
        sx={{
          minWidth: "50px !important",
          borderRadius: "100%",
          width: "50px",
          height: "50px",
          backgroundColor: "#cccfd9",
          boxShadow: "5px 5px 10px var(--text-info)",
          transition: "all 0.4s",
          ":hover": { scale: "1.1", boxShadow: "5px 5px 10px var(--text-info)" },
          ":active": { scale: "0.95" },
        }}
      >
        <FileCopyIcon
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "var(--icon-info)",
          }}
        />
      </Button>
    </Box>
  );
};

export default CopyButton;
