import { stepperData } from "../data";
import { useEffect } from "react";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import CustomAccordion from "../../../accordion/CustomAccordion";
import FormStep1 from "./FormStep1";
import MessageBlock from "../../../forms/MessageBlock";
import ObjectivesForm from "./ObjectivesForm";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step1 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    marketBasketElement,
    setMarketBasketElement,
    dataAnalysisMBs,
    mbName,
    fileSelectedOption,
    columnSKUSelectedOption,
    columnCanalSelectedOption,
    ventaMonedaSelectedOption,
    ventaUnidadesSelectedOption,
    marcaSelectedOption,
    nivelAnalisisSelectedOption,
    periodicidadSelectedOption,
    columnaTransaccionSelectedOption,
    columnasExtraSelectedOptions,
  } = useMarketBasketConfigProvider();
  const configMode = localStorage.getItem("configMode");

  /************************************* Limpieza inicial ********************************/
  useEffect(() => {
    setMessage({
      text: "",
      type: "",
    });
  }, []);

  /************************************* Validación step completo **********************************/

  const isStep1Complete = () => {
    if (
      mbName &&
      fileSelectedOption &&
      columnSKUSelectedOption &&
      columnCanalSelectedOption &&
      ventaMonedaSelectedOption &&
      ventaUnidadesSelectedOption &&
      nivelAnalisisSelectedOption &&
      periodicidadSelectedOption
    ) {
      if (periodicidadSelectedOption?.value === "no_consolidar") {
        if (columnaTransaccionSelectedOption) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isStep1Complete()) {
      setMarketBasketElement((prev) => {
        return {
          ...prev,
          nombre: mbName,
          archivo: fileSelectedOption,
          columna_SKU: columnSKUSelectedOption,
          canal: columnCanalSelectedOption,
          venta_moneda: ventaMonedaSelectedOption,
          venta_unidades: ventaUnidadesSelectedOption,
          marca: marcaSelectedOption,
          nivel_de_analisis: nivelAnalisisSelectedOption,
          periodicidad: periodicidadSelectedOption,
          columna_transaccion: columnaTransaccionSelectedOption,
          columnas_extra: columnasExtraSelectedOptions,
        };
      });
      setIsStepComplete((prev) => {
        return { ...prev, step1: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step1: false };
      });
    }
  }, [
    mbName,
    fileSelectedOption,
    columnSKUSelectedOption,
    columnCanalSelectedOption,
    ventaMonedaSelectedOption,
    ventaUnidadesSelectedOption,
    marcaSelectedOption,
    nivelAnalisisSelectedOption,
    periodicidadSelectedOption,
    columnaTransaccionSelectedOption,
    columnasExtraSelectedOptions,
    columnasExtraSelectedOptions?.length,
  ]);

  return (
    <Stack sx={{ ...param_steps_container, gap: 2 }}>
      <Typography sx={step_title_styles}>{stepperData(t).steps[0]}</Typography>
      <MessageBlock message={message} />
      {configMode === "EDIT" || configMode === "COPY" ? (
        marketBasketElement.id &&
        dataAnalysisMBs && (
          <Stack sx={{ ...form_layouts_container_styles, width: "98%" }}>
            <FormStep1 />
          </Stack>
        )
      ) : (
        <Stack sx={{ ...form_layouts_container_styles, width: "98%" }}>
          <FormStep1 />
        </Stack>
      )}
      {/* <CustomAccordion
        key={1}
        title={
          t("marketBasket.objectives") +
          " " +
          "(" +
          t("marketBasket.optional") +
          ")"
        }
        backgroundColor="var(--cream)"
        summaryColor={"var(--text-main)"}
        arrowColor="var(--icon-main)"
      >
        <Stack sx={{ ...form_layouts_container_styles, width: "98%" }}>
          <ObjectivesForm />
        </Stack>
      </CustomAccordion> */}
    </Stack>
  );
};

export default Step1;
