import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useLanguage from "../../../../context/LanguageProvider";

interface CalculateGntOptionsI {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  handleSelection: (type: string) => void;
}

const CalculateGntOptions = ({
  anchorEl,
  open,
  handleClose,
  handleSelection,
}: CalculateGntOptionsI) => {
  const { t } = useLanguage();
  const texts = {
    currentIterationCalculation: t(
      "modelParameterizationTexts.resetButton.currentIterationCalculation"
    ),
    resetCurrentIterationCalculation: t(
      "modelParameterizationTexts.resetButton.resetCurrentIterationCalculation"
    ),
  };

  return (
    <Menu
      id="basic-menu"
      className="checkBoxMiniSelect"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={() => handleSelection(texts.currentIterationCalculation)}
      >
        {texts.resetCurrentIterationCalculation}
      </MenuItem>
    </Menu>
  );
};

export default CalculateGntOptions;
