import { MB_RESULTADOS_URL } from "../../../api/axios";
import { row_sb_100_styles } from "../../../styles/app-styles";
import { useEffect } from "react";
import { useMarketBasketProvider } from "../../../context/MarketBasketProvider";
import Box from "@mui/material/Box";
import CardsSection from "./indicators/CardsSection";
import ChartsList from "./charts/ChartsList";
import ChipFilters from "./filters/ChipFilters";
import ComponentLayout from "../../layout/ComponentLayout";
import FilterSection from "./filters/FilterSection";
import Grid2 from "@mui/material/Unstable_Grid2";
import MarketBasketTabs from "./MarketBasketTabs";
import NoCardsError from "../../error/NoCardsError";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TableSection from "./table/TableSection";
import useLanguage from "../../../context/LanguageProvider";

const MarketBasketAnalysisView = () => {
  const { t } = useLanguage();
  const {
    tabs,
    setTabs,
    tabValue,
    setTabValue,
    getMbAnalysisResults,
    filters,
    dataAnalysisViews,
  } = useMarketBasketProvider();

  useEffect(() => {
    if (tabValue && tabValue !== "SIN ANALISIS") {
      getMbAnalysisResults(MB_RESULTADOS_URL(tabValue));
    }
  }, [filters, tabValue]);

  useEffect(() => {
    if (dataAnalysisViews) {
      const firstTabValue = dataAnalysisViews[0]?.id;
      firstTabValue && setTabValue(firstTabValue);
      dataAnalysisViews &&
        dataAnalysisViews.length &&
        setTabs(
          dataAnalysisViews.map((tab: any) => {
            return {
              value: tab.id,
              label: tab.nombre,
            };
          })
        );
    }
  }, [dataAnalysisViews]);

  return (
    <ComponentLayout
      title={t("menuItemsOptions.marketBasketCollapseList.title")}
      icon={<PlaylistAddCheckCircleIcon />}
    >
      <SimpleBackdrop open={false} message={t("general.loading")} />
      <Stack sx={row_sb_100_styles}>
        {tabs && tabs.length === 1 && tabValue === "SIN ANALISIS" ? (
          <NoCardsError height={"70vh"} />
        ) : (
          <>
            <Box width="90%">
              <MarketBasketTabs />
            </Box>
            <Box width="10%">
              <FilterSection />
            </Box>
          </>
        )}
      </Stack>
      <Grid2 container direction={"column"} md={12} mt={1} gap={2}>
        <>
          <ChipFilters margin={"0 0 0 0"} />
          <CardsSection />
          <TableSection />
          <ChartsList />
        </>
      </Grid2>
    </ComponentLayout>
  );
};

export default MarketBasketAnalysisView;
