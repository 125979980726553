import Dialog from "../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import { Button } from "../../buttons";
import useLanguage from "../../../context/LanguageProvider";

interface BaseModalProps {
  children: React.ReactNode;
  open: boolean;
  title: string;
  cleanAndClose: () => void;
  openConfirmationModal: boolean;
  toggleConfirmationModal: () => void;
  handleAccept: () => void;
  disabledAcceptButton: boolean;
  confirmationModalMessage: string;
  width?: string;
  height?: string;
}

const BaseModal = ({
  children,
  open,
  title,
  cleanAndClose,
  openConfirmationModal,
  toggleConfirmationModal,
  handleAccept,
  disabledAcceptButton,
  confirmationModalMessage,
  width,
  height
}: BaseModalProps) => {
  const { t } = useLanguage();
  return (
    <Dialog open={open} handleClose={cleanAndClose} title={title} maxWidth="xl">
      <Stack
        sx={{
          width: width ? width : "730px",
          height: height ? height : "500px",
          marginTop:"0px"      
        }}
      >
        {children}
      </Stack>
      <Stack direction="row" sx={{ alignSelf: "center", marginTop: "auto", justifyContent: "center" }}>
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={handleAccept}
          title={title}
          message={confirmationModalMessage}
        />
        <Button
          title={t('dataModelText.groupManagement.baseModal.cancel')}
          type="button"
          onClick={cleanAndClose}
          color="light-grey"
        />
        <Button
          title={t('dataModelText.groupManagement.baseModal.accept')}
          type="button"
          onClick={toggleConfirmationModal}
          disabled={disabledAcceptButton}
          color="blue-greeny"
        />
      </Stack>
    </Dialog>
  );
};

export default BaseModal;
