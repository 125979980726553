import React, { useContext, useEffect, useState } from 'react'
import useLanguage from '../../../../context/LanguageProvider'
import { Stack, Typography } from '@mui/material'
import { Button } from "../../../buttons";
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../../hooks/useDialog';
import NewRegisterRouteModal from './Step2/RegisterRoute/NewRegisterRouteModal';
import SimulationProvider from '../../../../context/SimulationProvider';
import NewCancelRouteModal from './Step2/CancelRoute/NewCancelRouteModal';
import NewModificationRouteModal from './Step2/ModifyRoute/NewModificationRouteModal';
import { transformConfigurationsData } from './Step2/transformConfigurationsData';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationModal } from '../../../dialog';
import ModifyModificationRouteModal from './Step2/ModifyRoute/ModifyModificationRouteModal';
import ViewInfoRegisterRouteModal from './Step2/RegisterRoute/ViewInfoRegisterRouteModal';
import ViewInfoCancelRouteModal from './Step2/CancelRoute/ViewInfoCancelRouteModal';
import ViewInfoModifyRouteModal from './Step2/ModifyRoute/ViewInfoModifyRouteModal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ModifyCancelRouteModal from './Step2/CancelRoute/ModifyCancelRouteModal';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface Step2SimulationConfigProps {
  dataGetAnalysisById: {
    nombre: string;
    agrupacion: any;
    medidas: any[];
  },
  dataGetSimulationsInformation: {
    id: number;
    nombre: string;
    empresa: number;
    obligatorio_sales_forecast: boolean;
    obligatorio_simulacion: boolean;
    por_defecto: boolean;
    sales_forecast: boolean;
    simulacion: boolean;
    depende?: any;
  }[],
  informationsColumns: any,
  modificationTypes: any
}

const Step2SimulationConfig = ({ dataGetAnalysisById, dataGetSimulationsInformation, informationsColumns, modificationTypes }: Step2SimulationConfigProps) => {

  const { t } = useLanguage()
  const [openNewRegisterRouteModal, handleToggleNewRegisterRouteModal] = useDialog();
  const [openNewCancelRouteModal, handleToggleNewCancelRouteModal] = useDialog();
  const [openNewModifyRouteModal, handleToggleNewModifyRouteModal] = useDialog();
  const { simulationState, simulationDispatch } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
  const [retentions, setRetentions] = useState<{ ruta: string, retencion: number }[]>([]);
  const [configurationsRows, setConfigurationsRows] = useState<any[]>([]);
  const [configurationsColumns, setConfigurationsColumns] = useState<any[]>([]);
  const [selectedConfigurations, setSelectedConfigurations] = useState<any[]>([])
  const [configToEdit, setConfigToEdit] = useState<any[]>()
  const [configToView, setConfigToView] = useState<any[]>()  

  const [openModifyCanceledRouteModal, handleToggleModifyCancelRouteModal] = useDialog();
  const [openModifyModificationRouteModal, handleToggleModifyModificationRouteModal] = useDialog();

  const [openViewInfoRegisterRouteModal, handleToggleViewInfoRegisterRouteModal] = useDialog()
  const [openViewInfoCancelRouteModal, handleToggleViewInfoCancelRouteModal] = useDialog()
  const [openViewInfoModifyRouteModal, handleToggleViewInfoModifyRouteModal] = useDialog()

  /****************************** CARGA Y OBTENCIÓN DE DATOS *****************************/

  useEffect(() => {
    if (
      simulationState && simulationState.configurations
    ) {
      const { columns, rows } = transformConfigurationsData(
        t,
        simulationState.configurations,
        buttonsActions
      );
      setConfigurationsColumns(columns);
      setConfigurationsRows(rows);
    } else {
      setConfigurationsColumns([]);
      setConfigurationsRows([]);
    }
  }, [simulationState.configurations]); 

  useEffect(()=>{
    if(simulationState.configurations.length < 1){
      setSelectedConfigurations([])
    }
  },[])

  /********************************* MANEJO DE FUNCIONES *****************************/

  const handleDeleteConfig = (id: number) => {
    setSelectedConfigurations([id])
    handleDeleteConfigurationModal()
  }

  const handleInfoConfig = (id: number) => {
    const configToView = simulationState.configurations?.find((config) => config.id === id)
    if (configToView) {
      setConfigToView(configToView);
      switch (configToView.tipo) {
        case "alta":
          handleToggleViewInfoRegisterRouteModal()
          break;
        case "baja":
          handleToggleViewInfoCancelRouteModal()
          break;
        case "modificacion":
          handleToggleViewInfoModifyRouteModal()
          break;
        default:
          break;
      }
    }
  };

  const handleEditConfig = (id: number) => {
    const configToEdit = simulationState.configurations?.find((config) => config.id === id)
    if (configToEdit) {
      setConfigToEdit(configToEdit)
      switch (configToEdit.tipo) {
        case "alta":
          handleOpenNewRegisterRouteModal()
          break;
        case "baja":
          handleToggleModifyCancelRouteModal()
          break;
        case "modificacion":
          handleToggleModifyModificationRouteModal()
          break;
        default:
          break;
      }
    }
  };

  const buttonsActions = {
    handleInfoConfig,
    handleEditConfig,
    handleDeleteConfig
  }

  const handleOpenNewRegisterRouteModal = () => {
    setSelectedConfigurations([])
    handleToggleNewRegisterRouteModal()
  }

  const handleOpenNewCancelRouteModal = () => {
    setSelectedConfigurations([])
    handleToggleNewCancelRouteModal()
  }

  const handleOpenNewModifyRouteModal = () => {
    setSelectedConfigurations([])
    handleToggleNewModifyRouteModal()
  }

  const handleDeleteConfiguration = () => {
    const configurationsFiltered = simulationState.configurations.filter((config) => !selectedConfigurations.includes(config.id));
    simulationDispatch({ type: "SET_CONFIGURATIONS", payload: configurationsFiltered });
    handleDeleteConfigurationModal()
  }

  /********************************* VALIDACIÓN DE DATOS *****************************/

  /*********************************** EDITION BUTTON ***************************************/

  const [openDeleteConfigurationModal, setOpenDeleteConfigurationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleCloseAnchorEl();
  }, [openDeleteConfigurationModal])

  const handleDeleteConfigurationModal = () => {
    setOpenDeleteConfigurationModal(!openDeleteConfigurationModal);
  };

  const [retentionsToEdit, setRetentionsToEdit] = useState<any>([])

  return (
    <>
      <Stack sx={{
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        mt: -2
      }}>
        <Button
          title={<>
            <AddIcon sx={{ fontSize: "15px", fontWeight: "bold" }} />
            <Typography sx={{
              fontSize: "15px",
              ml: 0.5,
              fontWeight: "bold"
            }}>
              {t("simulatorTexts.registerRoutes.configRegisterRoute.registered")}
            </Typography>
          </>}
          width={180}
          color="blue"
          type="button"
          onClick={handleOpenNewRegisterRouteModal}
        />
        <Button
          title={<>
            <AddIcon sx={{ fontSize: "15px", fontWeight: "bold" }} />
            <Typography sx={{
              fontSize: "15px",
              ml: 0.5,
              fontWeight: "bold"
            }}>
              {t("simulatorTexts.registerRoutes.configRegisterRoute.canceled")}
            </Typography>
          </>}
          width={180}
          color="magenta"
          type="button"
          onClick={handleOpenNewCancelRouteModal}
        />
        <Button
          title={<>
            <AddIcon sx={{ fontSize: "15px", fontWeight: "bold" }} />
            <Typography sx={{
              fontSize: "15px",
              ml: 0.5,
              fontWeight: "bold"
            }}>
              {t("simulatorTexts.registerRoutes.configRegisterRoute.modification")}
            </Typography>
          </>}
          width={180}
          color="blue-greeny"
          type="button"
          onClick={handleOpenNewModifyRouteModal}
        />
        {simulationState.configurations.length > 0 && <Stack sx={{
          flexDirection: "row",
          position: "absolute",
          right: 0,
        }}>
          <Button
            title={<>
              <DeleteIcon />
              <Typography>{t("simulatorTexts.registerRoutes.configRegisterRoute.delete")}</Typography>
            </>}
            color="outlined"
            type="button"
            onClick={handleDeleteConfigurationModal}
            disabled={selectedConfigurations.length === 0}
          />

        </Stack>}
      </Stack>
      {simulationState && simulationState.configurations?.length < 1 ? (
        <Stack sx={{
          minHeight: "420px",
          width: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "700", color: "var(--text-info)", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            {t("deltaTexts.simulation.step2SimulationConfig.noConfigurationsYet")}.
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{
          height: "450px",
          pb: "20px",
          width: "100%",
          '& .headerGroup--className': {
            backgroundColor: 'var(--bg-main)',
            display: 'flex',
            width: "100%",
            justifyContent: 'center',
            alignItems: 'center',
            border: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "none",
            borderLeft: "none",
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: "solid rgba(224, 224, 224, 1) 1px !important",
          },
          '& .MuiDataGrid-columnHeader--emptyGroup': {
            backgroundColor: 'var(--bg-main)',
            border: "solid rgba(224, 224, 224, 1) 1px",
            borderBottom: "none",
            borderRight: "none"
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "var(--bg-secondary)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}>
            <DataGridPro
              rows={configurationsRows}
              columns={configurationsColumns}
              autoHeight
              disableColumnMenu={true}
              hideFooter={true}
              checkboxSelection
              selectionModel={selectedConfigurations}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectedConfigurations(newSelectionModel);
              }}
              disableSelectionOnClick
              sx={{
                boxShadow: 5
              }}
            />
          </Stack>
      )}
      {
        openNewRegisterRouteModal && <NewRegisterRouteModal
          dataGetAnalysisById={dataGetAnalysisById}
          open={openNewRegisterRouteModal}
          handleClose={handleOpenNewRegisterRouteModal}
          configToEdit={configToEdit}
          setSelectedConfig={setSelectedConfigurations}
          dataGetSimulationsInformation={dataGetSimulationsInformation}
          setConfigToEdit={setConfigToEdit}
        />
      }
      {openNewCancelRouteModal && <NewCancelRouteModal
        open={openNewCancelRouteModal}
        handleClose={handleOpenNewCancelRouteModal}
        dataGetAnalysisById={dataGetAnalysisById}
        retentions={retentions}
        setRetentions={setRetentions}
        dataGetSimulationsInformation={dataGetSimulationsInformation}
        informationsColumns={informationsColumns}
      />
      }
      {openNewModifyRouteModal && <NewModificationRouteModal
        open={openNewModifyRouteModal}
        handleClose={handleOpenNewModifyRouteModal}
        dataGetAnalysisById={dataGetAnalysisById}
        informationsColumns={informationsColumns}
        modificationTypes={modificationTypes}
      />
      }
      <ConfirmationModal
        open={openDeleteConfigurationModal}
        handleClose={handleDeleteConfigurationModal}
        handleAccept={handleDeleteConfiguration}
        message={selectedConfigurations.length > 1 ? t("deltaTexts.simulation.step2SimulationConfig.deleteConfigs") : t("simulatorTexts.registerRoutes.configRegisterRoute.deleteRegisterRouteConfiguration.message")}
        title={t("simulatorTexts.registerRoutes.configRegisterRoute.deleteRegisterRouteConfiguration.title")}
      />
      {openModifyModificationRouteModal && <ModifyModificationRouteModal
        open={openModifyModificationRouteModal}
        handleClose={handleToggleModifyModificationRouteModal}
        configToEdit={configToEdit}
        informationsColumns={informationsColumns}
        modificationTypes={modificationTypes}
      />
      }
      {openModifyCanceledRouteModal && <ModifyCancelRouteModal
        open={openModifyCanceledRouteModal}
        handleClose={handleToggleModifyCancelRouteModal}
        retentions={retentionsToEdit}
        setRetentions={setRetentionsToEdit}
        configToEdit={configToEdit}
        setConfigToEdit={setConfigToEdit}
        informationsColumns={informationsColumns}
      />
      }
      {openViewInfoRegisterRouteModal &&
        <ViewInfoRegisterRouteModal
          open={openViewInfoRegisterRouteModal}
          handleClose={handleToggleViewInfoRegisterRouteModal}
          config={configToView}
          setConfig={setConfigToView}
        />
      }
      {openViewInfoCancelRouteModal &&
        <ViewInfoCancelRouteModal
          open={openViewInfoCancelRouteModal}
          handleClose={handleToggleViewInfoCancelRouteModal}
          config={configToView}
          informationsColumns={informationsColumns}
          setConfig={setConfigToView}
        />
      }
      {openViewInfoModifyRouteModal &&
        <ViewInfoModifyRouteModal
          open={openViewInfoModifyRouteModal}
          handleClose={handleToggleViewInfoModifyRouteModal}
          config={configToView}
          setConfig={setConfigToView}
        />
      }
    </>
  )
}

export default Step2SimulationConfig