import {
  active_inactive_typography_styles,
  mb_20_styles,
  no_cards_error_typography_styles,
  no_elements_box_styles,
} from "../../../styles/app-styles";
import { Box, Typography } from "@mui/material";
import { GET_ALL_USERS_URL } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import AddCriteriaButton from "../../criteriaManagement/AddCriteriaButton";
import BasicGrid from "../../basicGrid/BasicGrid";
import React from "react";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import UserCard from "./UserCard";
import UserForm from "./UserForm";
import ViewUserModal from "./ViewUserModal";

interface UsersViewProps {
  setConfigOpen: React.Dispatch<React.SetStateAction<boolean>>;
  configOpen: boolean;
}

const UsersView = ({ setConfigOpen, configOpen }: UsersViewProps) => {
  const { t } = useLanguage();
  const {
    data: dataUsers,
    isSuccess: isSuccesUsers,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    refetch: refetchUsers,
  } = useApiQuery(
    GET_ALL_USERS_URL,
    true,
    t("administratorPanel.users.errorLoadingUsers")
  );

  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "">("");
  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [user, setUser] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();

  useEffect(() => {
    if (dataUsers) {
      setFilteredData(dataUsers);
    }
  }, [dataUsers]);

  const handleAdd = () => {
    setConfigMode("CREATE");
    setConfigOpen(true);
  };

  const handleFinish = () => {
    setConfigOpen(false);
    refetchUsers();
    setConfigMode("");
    window.location.reload();
  };

  const handleEdit = (e: any, id: number) => {
    setConfigMode("EDIT");
    setUser(dataUsers?.find((user: any) => user.id === id));
    setConfigOpen(true);
  };

  const handleInfo = (e: any, id: number) => {
    setSelectedUser(dataUsers?.find((user: any) => user.id === id));
    handleToggleViewInfoModal();
  };

  const filterData = (value: string) => {
    return dataUsers?.filter((user: any) => {
      return (
        user.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
        user.email.includes(value?.toLowerCase())
      );
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserValue(e.target.value);
    if (e.target.value) {
      setFilteredData(filterData(e.target.value));
    } else {
      setFilteredData(dataUsers);
    }
  };

  const isData = isSuccesUsers && !isLoadingUsers && filteredData;

  return (
    <>
      <SimpleBackdrop
        open={isLoadingUsers}
        message={t("administratorPanel.users.loadingUsers")}
      />
      {!configOpen ? (
        <Stack spacing={2} sx={{ width: "100%", marginTop: "0px !important" }}>
          <SearchInput
            value={searchUserValue}
            handleChange={handleSearch}
            placeholder={t("administratorPanel.users.searchUserLabel")}
          />
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.users.activeUsers")}
          </Typography>
          <Stack sx={mb_20_styles}>
            {isData &&
              filteredData.filter((user: any) => user.is_active).length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.users.noActiveUsers")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <BasicGrid
                  data={filteredData.filter((user: any) => user.is_active)}
                  Card={UserCard}
                  handleEdit={handleEdit}
                  handleInfo={handleInfo}
                  refetchData={refetchUsers}
                />
              )
            )}
          </Stack>
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.users.inactiveUsers")}
          </Typography>

          <Stack sx={mb_20_styles}>
            {isData &&
              filteredData.filter((user: any) => !user.is_active).length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.users.noInactiveUsers")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <BasicGrid
                  data={filteredData.filter((user: any) => !user.is_active)}
                  Card={UserCard}
                  showInfo={false}
                  handleEdit={handleEdit}
                  handleInfo={handleInfo}
                  refetchData={refetchUsers}
                />
              )
            )}
          </Stack>
          <AddCriteriaButton handleClick={handleAdd} />
          <ViewUserModal
            user={selectedUser}
            open={openViewInfoModal}
            handleClose={handleToggleViewInfoModal}
            title={
              selectedUser?.nombre ? selectedUser?.nombre : selectedUser?.email
            }
            handleEdit={handleEdit}
          />
        </Stack>
      ) : (
        <UserForm
          handleFinish={handleFinish}
          configMode={configMode}
          user={user}
          users={dataUsers}
        />
      )}
    </>
  );
};

export default UsersView;
