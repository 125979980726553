import { Container } from "@mui/material";
import { PARAM_OTHER_FILE_URL } from "../../../../api/axios";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import useLanguage from "../../../../context/LanguageProvider";
import useApi from "../../../../hooks/useApi";
import CustomAccordion from "../../../accordion/CustomAccordion";
import DropzoneInput from "../Shared/DropzoneInput";
import UploadedFile from "../Shared/UploadedFile";
import ListColumnsTypes from "./ListColumnsTypes";

const FilesTypesSteps = () => {
  const { t } = useLanguage();
  const {
    otherFileToUpload,
    setOtherFileToUpload,
    getOtherFilesParams,
    filesColumnsTypes,
    getColumnsTypesParams,
  } = useCompanyParamProvider();

  const onSuccessPostFile = () => {
    setOtherFileToUpload([]);
    getOtherFilesParams();
    getColumnsTypesParams();
  };

  const { isLoading: isLoadingPostFile, callApi: postFile } = useApi(
    PARAM_OTHER_FILE_URL,
    "POST",
    t("companyParam.postParamFile.codes"),
    undefined,
    onSuccessPostFile,
    undefined,
    false
  );

  const isFileCompleted = (file) => {
    const actualFile = filesColumnsTypes?.find(
      (item) => item.nombre === file.nombre
    );
    /* const isFile = !!actualFile?.archivo?.name; */

    const areAllColumnsTyped =
      actualFile?.columnas &&
      actualFile?.columnas.length !== 0 &&
      actualFile?.columnas?.every((column) => {
        return column.tipo;
      });
    return /* isFile &&  */ areAllColumnsTyped;
  };

  return (
    <>
      {filesColumnsTypes &&
        filesColumnsTypes.map((file: any) => {
          return (
            <CustomAccordion
              title={`${file.nombre} ${
                isFileCompleted(file)
                  ? "(" + t("companyParam.complete") + ")"
                  : "(" + t("companyParam.incomplete") + ")"
              }`}
              key={file.nombre}
              backgroundColor={"var(--cream)"}
              summaryColor={"var(--text-main)"}
              detailsColor={"var(--text-main)"}
              arrowColor={"var(--icon-main)"}
            >
              <Container sx={{ mt: 2, mb: 2 }}>
                {file?.nombre !== "GASTOS" && file?.nombre !== "VENTAS" && (
                  <DropzoneInput
                    fileToUpload={otherFileToUpload}
                    setFileToUpload={setOtherFileToUpload}
                    postFile={postFile}
                    url={`${PARAM_OTHER_FILE_URL(file.archivo_id)}`}
                    required={false}
                  />
                )}
                {file.archivo && file.archivo.name && (
                  <UploadedFile dataGetParams={file} />
                )}
                {file.columnas && <ListColumnsTypes file={file} />}
              </Container>
            </CustomAccordion>
          );
        })}
    </>
  );
};

export default FilesTypesSteps;
