import { EXPENSES_TARGET_URL } from "../../../../api/axios";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import Container from "@mui/material/Container";
import CustomAccordion from "../../../accordion/CustomAccordion";
import ExpenseTargetRow from "./ExpenseTargetRow";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";

const TargetLine = ({ expenseTarget }) => {  
  const { t } = useLanguage();
  const {
    expensesTargets, setExpensesTargets, getExpenseTargetParams
  } = useCompanyParamProvider();
  const {
    isLoading: isLoadingDeleteTarget,
    callApi: deleteTarget,
    data:  dataDeleteTarget,
  } = useApi(
    EXPENSES_TARGET_URL(expenseTarget.id),
    "DELETE",
    t("companyParam.getParams.codes"),
    undefined,
    () => getExpenseTargetParams(),
    undefined,
    true
  );

  const handleDeleteTarget =  () => {
    if(expenseTarget.id){
        deleteTarget();
    } else {
      const newTargets = expensesTargets.filter((r: any) => r.nombre !== expenseTarget.nombre);
      setExpensesTargets(newTargets);
    }    
  }

  return (
    <CustomAccordion
      title={expenseTarget.nombre}
      key={expenseTarget.nombre}
      backgroundColor={"var(--cream)"}
      summaryColor={"var(--text-main)"}
      detailsColor={"var(--text-main)"}
      arrowColor={"var(--icon-main)"}
      onDelete= {() => handleDeleteTarget()}
      deleteTooltipText={t('companyParam.deleteExpenseTarget')}
    >
      <Container sx={{ mt: 2, mb: 2 }}>
        {expenseTarget.involucrados &&
          expenseTarget.involucrados.map((r: any, index) => {
            return (
                <ExpenseTargetRow key={index} row={r} rows={expenseTarget.involucrados} />              
            );
          })}
      </Container>
    </CustomAccordion>
  );
};

export default TargetLine;
