import { Stack, Divider, Typography } from "@mui/material";
import { Delete } from "../../icons";
import { handleDeleteById } from "../../configCriteria/utils";
import { divider_styles } from "../../../styles/app-styles";
import { concatItemsCommas } from "../utils";
interface AddedMeasuresProps {
  addedMeasures: any[];
  setAddedMeasures: React.Dispatch<React.SetStateAction<any[]>>;
}

const AddedMeasures = ({
  addedMeasures,
  setAddedMeasures,
}: AddedMeasuresProps) => {
  const hasAddedMeasures = addedMeasures && addedMeasures.length > 0;
  
  return (
    <>
      {hasAddedMeasures &&
        addedMeasures.map((item, idx) => {
          const measures = concatItemsCommas(item.measures && item.measures);
          const operator = item.aggregationOperator.label;
          return (
            <Stack key={idx}>
              {idx === 0 && <Divider sx={divider_styles} />}
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
              >
                <Delete
                  tooltipPlacement="bottom"
                  onClick={() =>
                    handleDeleteById(item.id, addedMeasures, setAddedMeasures)
                  }
                />
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {measures}
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {operator}
                </Typography>
              </Stack>
              <Divider sx={divider_styles} />
            </Stack>
          );
        })}
    </>
  );
};

export default AddedMeasures;
