import { DataGridPro } from "@mui/x-data-grid-pro";
import { getColumnsForIterationTable,  transformDataForIterationTable } from "./transformData";
import { GridColDef } from "@mui/x-data-grid";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState, useEffect } from "react";
import {GET_PRORRATION_ITERATIONS_TABLE_URL} from "../../../api/axios";
import CustomNoRowsOverlay from "../../tablesTools/NoRows";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import CustomToolbar from "../../customToolbar/CustomToolbar";
import useLanguage from "../../../context/LanguageProvider";
interface ProrrationIterationTableProps {
  tabValue: string;
}

const ProrrationIterationTable = ({tabValue}: ProrrationIterationTableProps) => {
  const { t } = useLanguage();
  const {
    data: dataGetTable,
    isLoading: isLoadingGetTable,
    isError: isErrorGetTable,
    refetch: refetchGetTable,
    isFetching: isFetchingGetTable,
  } = useApiQuery(
    GET_PRORRATION_ITERATIONS_TABLE_URL,
    true,
    t("reportTexts.prorrationIterationTable.failedDownload")
  );

  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (dataGetTable && dataGetTable.length > 0) {
      const { rows, columns } = transformDataForIterationTable(dataGetTable, t);
      setRows(rows);
      setColumns(columns)
    }else{
      setColumns(getColumnsForIterationTable(t));
    }
  }, [dataGetTable]);

  return (
    <>
      {isLoadingGetTable ? (
        <CustomSkeleton height={6} />
      ) : (
        dataGetTable !== undefined && (
          <DataGridPro
            rows={rows ? rows : []}
            columns={columns}
            rowsPerPageOptions={[6, 15, 20, 50]}
            components={{
              Toolbar: () => (
                <CustomToolbar
                  toolbar={true}
                  filterButton={true}
                  densitySelector={true}
                  exportButton={true}
                  fileName={`${
                    t("reportTexts.prorrationIterationTable.failedDownload")
                  } - ${tabValue}`}
                />
              ),
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            hideFooter
            checkboxSelection={false}
            disableSelectionOnClick
            sx={{
              boxShadow: 3,
              fontSize: "12px",
              padding: "0 10px 10px 10px",
            }}
          />
        )
      )}
    </>
  );
};

export default ProrrationIterationTable;