import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import Box from "@mui/material/Box";
import ChartSkeleton from "../../skeleton/ChartSkeleton";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import DeleteButton from "../../buttons/DeleteButton";
import EditCriteriaButton from "../../buttons/EditCriteriaButton";
import Kpi from "../../report/Kpi";
import NoDataError from "../../error/NoDataError";
import RenderChart from "./RenderChart";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import {
  box_grid_draggable_styles,
  chart_box_draggable_styles,
  view_compact_icon_styles,
  view_compact_space_styles,
} from "../../../styles/app-styles";
import DashboardTable from "./DashboardTable";

const GridCard = ({
  item,
  index,
  dragStart,
  dragEnter,
  drop,
  isUserRolAllowed,
  refetchGetAnalysisViews,
  ELEMENT_URL,
}) => {
  const { t } = useLanguage();
  const {
    viewTabValue,
    filters,
    toggleNewElementModal,
    setElementToEdit,
    elementToEdit,
  } = useViewDashboard();
  const [onHover, setOnHover] = useState(false);
  const [openConfirmationDeleteModal, toggleConfirmationDeleteModal] =
    useDialog();

  /************************************* Llamadas a la api ************************************/

  const {
    isLoading: isLoadingGetElement,
    data: dataGetElement,
    callApi: getElement,
  } = useApi(
    ELEMENT_URL && ELEMENT_URL,
    "POST",
    undefined,
    filters ? filters : undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessDeleteElement = () => {
    toggleConfirmationDeleteModal();
    refetchGetAnalysisViews();
  };

  const { isLoading: isLoadingDeleteElement, callApi: deleteElement } = useApi(
    ELEMENT_URL && ELEMENT_URL(viewTabValue, item.id),
    "DELETE",
    t("dashboard.deleteElement.codes"),
    undefined,
    onSuccessDeleteElement,
    undefined,
    false
  );

  /********************************** Actualización de gráfico con filtros y pestañas **********************************/
  
  useEffect(() => {
    if (item && viewTabValue !== "DEFAULT" && ELEMENT_URL) {
      getElement(ELEMENT_URL(viewTabValue, item.id));
    }
  }, [filters, item.id, elementToEdit, viewTabValue]);

  /************************************************* Edición de gráfico ************************************************/

  const handleEditElement = () => {
    setElementToEdit(item);
    toggleNewElementModal();
  };

  return (
    <Box
      gridColumn={`span ${item.ancho}`}
      gridRow={`span ${item.alto}`}
      sx={box_grid_draggable_styles}
      onDragStart={(e) => dragStart(e, index)}
      onDragEnter={(e) => dragEnter(e, index)}
      onDragEnd={drop}
      draggable
      onMouseOver={!isLoadingGetElement ? () => setOnHover(true) : () => <></>}
      onMouseLeave={() => setOnHover(false)}
    >
      <SimpleBackdrop
        open={isLoadingDeleteElement}
        message={t("dashboard.deleteElement.loading")}
      />
      <Stack sx={chart_box_draggable_styles(onHover)}>
        {onHover ? (
          <Tooltip title={t('dashboard.moveElement')}>
            <ViewCompactIcon
              sx={view_compact_icon_styles}
            />
          </Tooltip>
        ) : (
          <></>
        )}
      </Stack>
      <Stack
        sx={view_compact_space_styles}
      ></Stack>
      {isLoadingGetElement && <ChartSkeleton />}
      {!dataGetElement && !isLoadingGetElement && <NoDataError />}
      {dataGetElement && !isLoadingGetElement && item.tipo === "grafico" && (
        <RenderChart dataGetElement={dataGetElement} item={item} />
      )}
      {dataGetElement && item.tipo === "indicador" && (
        <Kpi
          id={dataGetElement.id}
          valor={dataGetElement.valor}
          unidad={dataGetElement.unidad}
          kpi={dataGetElement.nombre}
          isAnalysis={true}
        />
      )}
      {dataGetElement && item.tipo === "tabla" && (
        <DashboardTable
          id={dataGetElement.id} 
          dataGetElement={dataGetElement}
        />
      )}
      {onHover ? (
        isUserRolAllowed && (
          <>
            <EditCriteriaButton
              handleClick={handleEditElement}
              selectedCriteria={item}
              top="40px"
              backgroundColor="white"
            />
            <DeleteButton
              handleClick={toggleConfirmationDeleteModal}
              selectedCriteria={item}
              top="100px"
              backgroundColor="white"
            />
          </>
        )
      ) : (
        <></>
      )}
      <ConfirmationModal
        open={openConfirmationDeleteModal}
        handleClose={toggleConfirmationDeleteModal}
        handleAccept={ELEMENT_URL && deleteElement} //parametro
        message={t("dashboard.deleteElement.confirmationMessage")}
        customMessage={true}
        title={t("dashboard.deleteElement.modalTitle")}
      />
    </Box>
  );
};

export default GridCard;
