import { Stack, Grid, Tooltip } from "@mui/material";
import Delete from "../../icons/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Item } from "../../basicGrid/BasicGrid";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { view_compact_icon_styles } from "../../../styles/app-styles";
import { useState } from "react";

interface GroupByRowProps {
  row: any;
  waterfallRows: any[];
  setWaterfallRows: any;
  index: any,
  handleToggleEditRow: any
  dragItem: any;
  dragOverItem: any;
  onDropActions: (initialItem, finalItem) => void;
}

const WaterfallRow = ({ index, row, waterfallRows, setWaterfallRows, handleToggleEditRow, dragItem, dragOverItem, onDropActions }: GroupByRowProps) => {

  const { t } = useLanguage()

  const hasGrabCursor = (target) => {
    if (!target) return false;
    const lastSpan = target.querySelector('span:last-child');
    if (lastSpan) {
      const cursor = window.getComputedStyle(lastSpan).cursor;
      if (cursor === 'grab') {
        return true;
      }
    }
    return false;
  };

  const dragStart = (e, elem) => {
    if (hasGrabCursor(e.target)) {
      dragItem.current = elem;
    } else {
      e.preventDefault();
    }
  };

  const dragEnter = (elem) => {
    if (dragOverItem) {
      dragOverItem.current = elem;
    }
  };

  const handleDeleteFilter = () => {
    const updatedRows = waterfallRows.filter((item, i) => i !== index);
    setWaterfallRows(updatedRows);
  };

  const handleDrop = () => {
    if (dragItem.current === undefined || dragOverItem.current === undefined) {
      return;
    } else {
      onDropActions(dragItem.current, dragOverItem.current);
    }
  };

  return (
    <Item
      id={index}
      sx={{
        alignItems: "center",
        border: "none",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        minHeight: "40px",
        minWidth: "auto",
        padding: "0 8px",
        mt: "10px",
        position: "relative",
        textTransform: "uppercase",
        transition: "all 0.5s",
        width: `100%`,
        "& h4": {
          color: "var(--text-main)",
          fontSize: "14px",
          fontWeight: "bold",
        },
        "&:hover": {
          backgroundColor: "var(--light-blue)",
        },
      }}
      onDragOver={(e) => e.preventDefault()}
      draggable={true}
      onDrop={() => handleDrop()}
      onDragStart={(e) => dragStart(e, row)}
      onDragEnter={(e) => dragEnter(row)}
    >
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"row"}
          gap={"5px"}
        >
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={3.5}>
        <Typography color={"primary"}>{row?.function?.label}</Typography>
      </Grid>
      <Grid item xs={3.5}>
        <Typography color={"primary"}>{row?.column?.label || row?.function?.label}</Typography>
      </Grid>
      <Grid item xs={3.5} sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row"
      }}>
        <Stack sx={{
          width: "95%"
        }}>
          <Typography color={"primary"}>{row?.columnName}</Typography>
        </Stack>
        <Stack sx={{
          width: "5%"
        }}>
          <Tooltip disableFocusListener disableTouchListener title={t("dashboard.editName")} placement="bottom">
            <EditIcon onClick={() => handleToggleEditRow(row)} cursor={"pointer"} sx={{ color: "var(--text-info)" }} />
          </Tooltip>
        </Stack>
      </Grid>
      <Grid item xs={1} justifyContent="space-around" >
        {row && row.function.value !== "suma_total" &&
          (
            <Tooltip title={t("dashboard.moveElement")}>
              <span
                className="dragIcon"
                style={{ fontSize: "20px", marginRight: "5px" }}
                onMouseOver={(e) => {
                  e.currentTarget.style.cursor = "grab";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.cursor = "default";
                }}
              >
                <DragIndicatorIcon
                  sx={{
                    ...view_compact_icon_styles,
                    fontSize: "20px",
                    zIndex: 2,
                    color: "var(--text-info)",
                  }}
                />
              </span>
            </Tooltip>
          )
        }
      </Grid>
    </Item>
  );
};

export default WaterfallRow;