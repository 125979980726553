import { criteriaOptions } from "../../../preprorationParameterization/selectOptions";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useParameterization } from "../../../../hooks/useParameterization";
import CriteriaModelContent from "./CriteriaModelContent";
import Dialog from "../../../dialog/Dialog";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  GET_CRITERIA_TARGET_URL,
  GET_PRORRATION_CRITERIA_URL,
} from "../../../../api/axios";
import {
  filterCriteriasByBucketAndExpenseTarget,
  getDataFromSelectionModel,
} from "../../utils";

const Criteria = () => {
  const { t } = useLanguage();
  const criteriaSelectRef: any = useRef(null);
  const {
    parameterizationState,
    openCriteriaModal,
    handleToggleCriteriaModal,
    rows,
    rowsNt,
  } = useParameterization();
  const { tabValue, selectionModel, bucketSelectedOption } =
    parameterizationState;
  const [criteriaSelectOptions, setCriteriaSelectOptions] = useState<any>([]);

  /************************************ Información del back *********************************/

  const { data: dataGetCriteriaTarget } = useApiQuery(
    GET_CRITERIA_TARGET_URL,
    false,
    t("modelParameterizationTexts.modelTable.getCriteriaTargetError")
  );
  const { data: getCriteria } = useApiQuery(
    GET_PRORRATION_CRITERIA_URL,
    false,
    t("modelParameterizationTexts.modelTable.getCriteriaError")
  );

  /************************************* Select de receptores y criterios ***********************************/

  const onExpenseTargetChange = () => {
    setCriteriaSelectedOption(undefined);
    criteriaSelectRef.current.clearValue();
  };

  const [
    expenseTargetSelectedOption,
    setExpenseTargetSelectedOption,
    expenseTargetChangeHandler,
  ] = useSingleSelect(() => onExpenseTargetChange());

  useEffect(() => {
    const expenseTargetSelectedOptionTypeAny: any = expenseTargetSelectedOption;
    const expenseTargetSelectedOptionByTabValue =
      tabValue === "GASTOS"
        ? expenseTargetSelectedOption
        : expenseTargetSelectedOptionTypeAny?.receptor_criterio;

    const filteredCriteria = filterCriteriasByBucketAndExpenseTarget(
      getCriteria,
      bucketSelectedOption && bucketSelectedOption.value,
      expenseTargetSelectedOptionByTabValue &&
        expenseTargetSelectedOptionByTabValue?.value
    );
    const filteredCriteriaOptions =
      getCriteria && criteriaOptions(filteredCriteria);

    if (!expenseTargetSelectedOption) {
      setCriteriaSelectOptions([]);
    } else {
      setCriteriaSelectOptions(filteredCriteriaOptions);
    }
  }, [expenseTargetSelectedOption]);

  const [
    criteriaSelectedOption,
    setCriteriaSelectedOption,
    criteriaChangeHandler,
  ] = useSingleSelect();

  /********************************** Datos según tabValue ************************************/

  const rowsData =
    tabValue ===
      t("modelParameterizationTexts.step2.tabValueExpensesNotTransferred") &&
    rowsNt
      ? rowsNt.resultado
      : rows
      ? rows.resultado
      : undefined;

  const selectedGroupers =
    tabValue ===
      t("modelParameterizationTexts.step2.tabValueExpensesNotTransferred") &&
    rowsNt
      ? getDataFromSelectionModel(selectionModel, rowsNt.resultado)
      : rows && getDataFromSelectionModel(selectionModel, rows.resultado);

  /********************************** Efectos según modelo de selección  ************************************/

  function getSelectedExpense(selectionModel) {
    const selectedRow = selectionModel
      .map((id) => rowsNt.resultado.find((row) => row.id === id))
      .find((row) => row.receptor_criterio);
    return selectedRow;
  }

  function getCriteriaOptions(selectedExpense) {
    const expenseValue = selectedExpense.value;

    const filteredCriteria = filterCriteriasByBucketAndExpenseTarget(
      getCriteria,
      bucketSelectedOption && bucketSelectedOption.value,
      expenseValue
    );
    return getCriteria && criteriaOptions(filteredCriteria);
  }

  // calcula y actualiza estados expenseTargetSelectedOption y criteriaSelectOptions basados en los elementos seleccionados del selectionModel.
  useEffect(() => {
    if (
      tabValue ===
        t("modelParameterizationTexts.step2.tabValueExpensesNotTransferred") &&
      selectionModel &&
      selectionModel.length > 0
    ) {
      const selectedExpense = getSelectedExpense(selectionModel);

      const criteriaOptions = getCriteriaOptions(selectedExpense);
      setExpenseTargetSelectedOption(selectedExpense);
      setCriteriaSelectOptions(criteriaOptions);
    }
  }, [selectionModel]);

  const handleClosePropagateModal = () => {
    handleToggleCriteriaModal();
    setCriteriaSelectedOption(undefined);
    setExpenseTargetSelectedOption(undefined);
    setCriteriaSelectOptions([]);
  };

  return (
    <Dialog
      open={openCriteriaModal}
      handleClose={handleClosePropagateModal}
      title={t("modelParameterizationTexts.step2.assignCriteria")}
      maxWidth="lg"
    >
      <CriteriaModelContent
        rowsData={rowsData}
        selectedGroupers={selectedGroupers}
        criteriaSelectedOption={criteriaSelectedOption}
        expenseTargetSelectedOption={expenseTargetSelectedOption}
        setCriteriaSelectedOption={setCriteriaSelectedOption}
        dataGetCriteriaTarget={dataGetCriteriaTarget}
        expenseTargetChangeHandler={expenseTargetChangeHandler}
        criteriaSelectRef={criteriaSelectRef}
        criteriaSelectOptions={criteriaSelectOptions}
        criteriaChangeHandler={criteriaChangeHandler}
        getCriteria={getCriteria}
        handleClosePropagateModal={handleClosePropagateModal}
      />
    </Dialog>
  );
};

export default Criteria;
