import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
interface SearchInputProps {
  value: string;
  placeholder: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  focused?: boolean;
  clearIcon?: boolean;
  handleDelete?: () => void;
  size?: any;
}

const SearchInput = ({
  value,
  handleChange,
  placeholder,
  focused,
  clearIcon,
  handleDelete,
  size,
}: SearchInputProps) => {
  return (
    <Box component="form" noValidate autoComplete="off" mb="15px" mt="15px">
      <TextField
        type="text"
        label={placeholder}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        focused={focused ? focused : false}
        InputProps={{
          endAdornment: clearIcon ? (
            <IconButton
              aria-label="clear input"
              onClick={handleDelete ? () => handleDelete() : () => <></>}
              edge="end"
            >
              <CancelIcon />
            </IconButton>
          ) : (
            <></>
          ),
        }}
        size={size ? size : "normal"}
      />
    </Box>
  );
};

export default SearchInput;
