import Stack from '@mui/material/Stack';
import MarketBasketTreemapChart from '../../../Charts/MarketBasketTreemapChart';

const TreemapCharts = ({chartsData}) => {
  return (
    <Stack
    direction="row"
    justifyContent={"space-between"}
    width="100%"
    sx={{ flexWrap: "wrap" }}
  >
    {chartsData.treemap.map((item) => {
      return (
        <Stack key={item.id} width="50%">
          <h3>{item?.titulo_grafico}</h3>
          <MarketBasketTreemapChart results={item} />
        </Stack>
      );
    })}
  </Stack>
  )
}

export default TreemapCharts