import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";

const CustomTypographyTableText = ({ children, column, isRowIdAGrouper }) => {
  const { t } = useLanguage();
  return (
    <Typography
      variant="body2"
      textAlign={"center"}
      sx={{
        overflow: "hidden",
        width: "100%",
        fontWeight:
          column ===
            t("modelParameterizationTexts.customTypographyTableText.mount") &&
          isRowIdAGrouper
            ? "800"
            : "100",
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomTypographyTableText;
