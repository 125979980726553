import { convertToInternationalCurrencySystem } from "../../utils/util";

export const convertUnit = (unit: string, value: number, minimumFractionDigits?: number) => {
  switch (unit) {    
    case "moneda":
      const amount = convertToInternationalCurrencySystem(value, 2);            
      return amount.toLocaleString("es-AR", {  //TODO: hacer que tome el locale del usuario
        style: "currency",
        currency: "ARS",
      });
    case "porcentaje":         
      return value ? value.toLocaleString("es-ES", {
        style: "percent",
        minimumFractionDigits: minimumFractionDigits ? minimumFractionDigits : 2,
      }): undefined;
    case "unidades":
      return abbreviateNumber(value);
    default:
      return value;
  }
};

function abbreviateNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num ? num.toString() : 0;
}

export function formatUnits(num) {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function formatMoney(amount: number){
  return amount.toLocaleString("es-AR", { //TODO: hacer que tome el locale del usuario
    style: "currency",
    currency: "ARS",
  });
}