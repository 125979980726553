import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import ChipsBox from "./ChipsBox";
import CloseIcon from "@mui/icons-material/Close";
import EditValueLabelNameModal from "../../../dialog/EditValueLabelNameModal";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import IconButton from "@mui/material/IconButton";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";

const AbmBuckets = () => {
  const { t } = useLanguage();
  const {
    bucketsList,
    setBucketsList,
  } = useCompanyParamProvider();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openEditNameDialog, handleToggleEditNameDialog] = useDialog();
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [configMode, setConfigMode] = useState<
    "CREATE" | "EDIT" | "INFO" | "COPY" | ""
  >("");
  const [elementToEdit, setElementToEdit] = useState<any>({
    value: "",
    label: "",
  });
  const [repeatedNameMessage, setRepeatedNameMessage] = useState<string>("");

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const handleDelete = (chipToDelete: { value: string; label: string, puede_eliminarse: boolean }) => {
    if (chipToDelete?.puede_eliminarse === false) {
      handleClickVariant("Este bucket ya está en uso y no puede eliminarse", "error");
    } else {
    setBucketsList((chips) =>
      chips.filter((chip) => chip.value !== chipToDelete.value)
    );
  }
  };

  const editBucket = (bucket) => {
    setConfigMode("EDIT");
    setElementToEdit(bucket);
    handleToggleEditNameDialog();
  };

  const onClickAddButton = () => {
    setConfigMode("CREATE");
    setElementToEdit({
      value: "",
      label: "",
    });
    handleToggleEditNameDialog();
  };

  const handleDeleteBuckets = () => {
    setBucketsList([]);
    toggleConfirmationModal();
  }

  return (
    <FormSelectLayout title={t("companyParam.abmBuckets")} required={true}>
      <ChipsBox
        chipsList={bucketsList}
        editChip={editBucket}
        handleDelete={handleDelete}   
        onClickAddButton={onClickAddButton}
        onDelete={handleDeleteBuckets}
        openConfirmationModal={openConfirmationModal} 
        toggleConfirmationModal={toggleConfirmationModal}
        draggable={false}
      />
      <EditValueLabelNameModal
        openEditNameDialog={openEditNameDialog}
        handleToggleEditNameDialog={handleToggleEditNameDialog}
        elementToEdit={elementToEdit}
        setElementToEdit={setElementToEdit}
        configMode={configMode}
        setConfigMode={setConfigMode}
        setItems={setBucketsList}
        itemsList={bucketsList}
        message={repeatedNameMessage}
        setMessage={setRepeatedNameMessage}
      />
    </FormSelectLayout>
  );
};

export default AbmBuckets;
