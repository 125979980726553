export const getTableTexts = (t) => {
    return {
        toolbarColumns: t("virtualizedTable.toolbars.columns"),
        toolbarColumnsLabel: t("virtualizedTable.toolbars.selectColumns"),
        columnsPanelTextFieldLabel: t("virtualizedTable.toolbars.findColumn"),
        columnsPanelTextFieldPlaceholder: t(
          "virtualizedTable.toolbars.columnTitle"
        ),
        columnsPanelDragIconLabel: t(
          "virtualizedTable.toolbars.reorderColum"
        ),
        columnsPanelShowAllButton: t(
          "virtualizedTable.toolbars.columnsPanelShowAllButton"
        ),
        columnsPanelHideAllButton: t(
          "virtualizedTable.toolbars.columnsPanelHideAllButton"
        ),
        toolbarFilters: t("virtualizedTable.toolbars.filters"),
        toolbarFiltersLabel: t("virtualizedTable.toolbars.filterShow"),
        toolbarFiltersTooltipHide: t("virtualizedTable.toolbars.filterHide"),
        toolbarFiltersTooltipShow: t("virtualizedTable.toolbars.filterShow"),
        filterPanelInputPlaceholder: t(
          "virtualizedTable.toolbars.filterPlaceHolder"
        ),
        filterPanelOperators: t("virtualizedTable.toolbars.filterOperator"),
        filterPanelOperatorAnd: t("virtualizedTable.toolbars.andFilter"),
        filterPanelOperatorOr: t("virtualizedTable.toolbars.orFilter"),
        filterPanelAddFilter: t("virtualizedTable.toolbars.addFilter"),
        filterPanelColumns: t("virtualizedTable.toolbars.columns"),
        filterPanelInputLabel: t(
          "virtualizedTable.toolbars.filterInputLabel"
        ),
        toolbarDensity: t("virtualizedTable.toolbars.density"),
        toolbarExport: t("virtualizedTable.toolbars.export"),
        toolbarExportPrint: t("virtualizedTable.toolbars.print"),
        toolbarExportCSV: t("virtualizedTable.toolbars.asCSV"),
        toolbarDensityCompact: t("virtualizedTable.toolbars.compact"),
        toolbarDensityStandard: t("virtualizedTable.toolbars.standard"),
        toolbarDensityComfortable: t("virtualizedTable.toolbars.comfortable"),
      }
}