import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

interface CollapseOptionProps {
  OptionIcon: any;
  optionTitle: string;
  optionUrl: string;
  handleDrawerClose: any;
  getViewTitle: any;
}

const CollapseOption = ({
  OptionIcon,
  optionTitle,
  optionUrl,
  handleDrawerClose,
  getViewTitle
}: CollapseOptionProps) => {
  const navigate = useNavigate();

  const onclickCollapseOption = () => {
    getViewTitle && getViewTitle(optionTitle)
    handleDrawerClose();
    navigate(optionUrl);
  };

  return (
    <ListItemButton
      key={optionTitle}
      sx={{ pl: 4 }}
      onClick={() => onclickCollapseOption()}
    >
      <ListItemIcon sx={{ minWidth: "35px" }}>
        <OptionIcon
          sx={{
            color:
              location.pathname === optionUrl
                ? "var(--chart-22) !important"
                : "white",
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={optionTitle}
        sx={{
          "& .MuiTypography-root": {
            fontSize: "14px",
            color:
              location.pathname === optionUrl
                ? "var(--chart-22) !important"
                : "white",
          },
        }}
      />
    </ListItemButton>
  );
};

export default CollapseOption;
