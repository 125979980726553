import {
  GET_ELEMENTS_FOR_IMPORT_URL,
  POST_IMPORT_ELEMENTS_URL,
} from "../../../../api/axios";
import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import BucketLevelSelector from "./Levels/BucketLevelSelector";
import FormLayout from "../../../forms/FormLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import ImportButton from "../../../importElements/ImportButton";
import Levels from "./Levels/Levels";
import MessageBlock from "../../../forms/MessageBlock";
import PnlElements from "./PnlElements/PnlElements";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles2,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step3 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    isLoadingPostExpensesParams,
    getPnlParams,
    getPnlLevels,
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    getPnlParams();
  }, []);

  /************************************* Validación step completo **********************************/

  const isStep3Complete = () => {
    return true;
  };

  useEffect(() => {
    if (isStep3Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step3: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step3: false };
      });
    }
  }, []);

  const onSuccessImportElements = () => {
    getPnlLevels();
    getPnlParams();
  };

  const {
    callApi: getElementsForImport,
    data: dataGetElementsForImport,
    isLoading: isLoadingGetElementsForImport,
  } = useApi(
    GET_ELEMENTS_FOR_IMPORT_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  const {
    isLoading: isLoadingPostImportElements,
    callApi: postImportElements,
  } = useApi(
    POST_IMPORT_ELEMENTS_URL,
    "POST",
    undefined,
    undefined,
    onSuccessImportElements,
    undefined,
    false
  );

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams || isLoadingPostImportElements}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[3]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={{ ...form_layouts_container_styles2, width: "100%" }}>
        <FormLayout width="100%">
          <Grid2 container direction={"row"} xs={12} gap={2} width={"98%"}>
            <Stack
              direction="row"
              alignItems={"flex-end"}
              justifyContent={"space-between"}
              width="100%"
            >
              <BucketLevelSelector />
              <ImportButton
                postImportElements={postImportElements}
                getElements={getElementsForImport}
                dataGetElements={dataGetElementsForImport}
                GET_ELEMENTS_URL={GET_ELEMENTS_FOR_IMPORT_URL}
                message={t("importElements.willDeleteAll")}
              />
            </Stack>
            <Levels />
            <PnlElements />
          </Grid2>
        </FormLayout>
      </Stack>
    </Stack>
  );
};

export default Step3;
