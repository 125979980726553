import { Stack } from "@mui/material";
import DivisionRows from "./DivisionRows";
import DivisionSelects from "./DivisionSelects";
interface DivisionRowsProps {
  divisionRows: any;
  setDivisionRows: any;
  fileColumnSelectOptions: any;
  fileSelectedOptions: any;
}

const DivisionSection = ({
  divisionRows,
  setDivisionRows,
  fileColumnSelectOptions,
  fileSelectedOptions,
}: DivisionRowsProps) => {
  return (
    <Stack
      width="100%"
      sx={{ minHeight: `${35 + divisionRows?.length * 35}px`, mb: 2} }
    >
      <DivisionSelects
        setDivisionRows={setDivisionRows}
        divisionRows={divisionRows}
        fileColumnSelectOptions={fileColumnSelectOptions}
        fileSelectedOptions={fileSelectedOptions}
      />
      {divisionRows &&
        divisionRows.map((item) => {
          return (
            <DivisionRows
              key={item.columna.label + item.columna_divisor.label}
              row={item}
              rows={divisionRows}
              setRows={setDivisionRows}
            />
          );
        })}
    </Stack>
  );
};

export default DivisionSection;
