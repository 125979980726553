import { Box, Skeleton } from "@mui/material";

const ChartSkeleton = () => {
  return (
    <Box
      sx={{
        width: "95%",
        margin: "70px auto 0",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-around",
      }}
    >
      <Skeleton variant="rectangular" width={50} height={220} />
      <Skeleton variant="rectangular" width={50} height={360} />
      <Skeleton variant="rectangular" width={50} height={180} />
      <Skeleton variant="rectangular" width={50} height={100} />
      <Skeleton variant="rectangular" width={50} height={180} />
      <Skeleton variant="rectangular" width={50} height={220} />
      <Skeleton variant="rectangular" width={50} height={360} />
      <Skeleton variant="rectangular" width={50} height={220} />
      <Skeleton variant="rectangular" width={50} height={360} />
      <Skeleton variant="rectangular" width={50} height={180} />
      <Skeleton variant="rectangular" width={50} height={100} />
      <Skeleton variant="rectangular" width={50} height={180} />
      <Skeleton variant="rectangular" width={50} height={220} />
      <Skeleton variant="rectangular" width={50} height={360} />
      <Skeleton variant="rectangular" width={50} height={220} />
      <Skeleton variant="rectangular" width={50} height={360} />
      <Skeleton variant="rectangular" width={50} height={180} />
      <Skeleton variant="rectangular" width={50} height={100} />
      <Skeleton variant="rectangular" width={50} height={180} />
      <Skeleton variant="rectangular" width={50} height={220} />
      <Skeleton variant="rectangular" width={50} height={360} />
    </Box>
  );
};

export default ChartSkeleton;
