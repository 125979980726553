import { ButtonGroup, Stack, Typography } from "@mui/material";
import { Etapa } from "../../types";
import { GET_CHARTS_RESULTS_URL } from "../../../../api/axios";
import { getBucketById } from "../../utils";
import { useEffect, useState } from "react";
import { useParameterization } from "../../../../hooks/useParameterization";
import Button from "@mui/material/Button";
import useLanguage from "../../../../context/LanguageProvider";

const StagesButtons = () => {
  const { t } = useLanguage();
  const [stages, setStages] = useState<Etapa[]>([]);
  const { parameterizationState, parameterizationDispatch, buckets, getChartsResults } =
    useParameterization();
  const { bucketSelectedOption, selectedStage, tabValue } = parameterizationState;

  useEffect(() => {
    bucketSelectedOption &&
      buckets &&
      setStages(getBucketById(bucketSelectedOption.value, buckets)?.etapas); 
  }, [bucketSelectedOption, buckets]);  

  useEffect(() => {    
    /* if (parameterizationState?.bucketSelectedOption?.value) {
      getChartsResults(
        `${GET_CHARTS_RESULTS_URL}?bucket_id=${parameterizationState?.bucketSelectedOption?.value}`
      );
    } */
    if (tabValue !== t("modelParameterizationTexts.step2.tabValueExpenses")) {  
      parameterizationDispatch({
        type: "SET_TAB_VALUE",
        payload: t("modelParameterizationTexts.step2.tabValueExpenses"),
      });
    }
  }, [selectedStage?.etapa]);

  return (
    <Stack direction="row" minWidth="80px" alignItems={"center"} p={2}>
      <Typography
        variant="body1"
        sx={{ color: "var(--text-disabled)", fontWeight: "bold", mr: 1 }}
      >
        {`${t("modelParameterizationTexts.stagesButtons.stage")}: `}
      </Typography>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        color="primary"
      >
        {selectedStage &&
          stages?.map((stage: Etapa) => {
            return (
              <Button
                key={stage.etapa}
                onClick={
                  () => 
                  parameterizationDispatch({
                    type: "HANDLE_SET_SELECTED_STAGE",
                    payload: stage,
                    })
                }
                variant={
                  selectedStage.etapa === stage.etapa ? "contained" : "outlined"
                }
                disabled={!stage.habilitado}
              >
                {stage.etapa}
              </Button>
            );
          })}
      </ButtonGroup>
    </Stack>
  );
};

export default StagesButtons;