import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import ExchangeForm from "./ExchangeForm";
import ExchangeTable from "./ExchangeTable";
import FormLayout from "../../../forms/FormLayout";
import MessageBlock from "../../../forms/MessageBlock";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step0 = () => {
  const { t } = useLanguage();
  const {
    message,
    currencySelectedOptionState,
    foreignCurrencySelectedOptionState,
    exchangeTableData,
    setIsStepComplete,
    dataExchangeParams,
    setExchangeTableData,
    setForeignCurrencySelectedOptionState,
    isLoadingExchangeParams,
    setCurrencySelectedOptionState,
    setCountrySelectedOptionState
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/


  useEffect(() => {
    setExchangeTableData(dataExchangeParams?.tabla);
    setCurrencySelectedOptionState(dataExchangeParams?.moneda);
    setCountrySelectedOptionState(dataExchangeParams?.pais);
    setForeignCurrencySelectedOptionState(dataExchangeParams?.tipo_de_cambio);
  }, [/* dataExchangeParams */]);

  

  /************************************* Validación step completo **********************************/

  const isStep0Complete = () => {   
    if (
      currencySelectedOptionState 
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isStep0Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step0: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step0: false };
      });
    }
  }, [
    currencySelectedOptionState,
    foreignCurrencySelectedOptionState,
    foreignCurrencySelectedOptionState?.length,
    exchangeTableData,
    exchangeTableData?.length,
  ]);

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingExchangeParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[0]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles}>
        <FormLayout width="100%">
          <ExchangeForm />
          <ExchangeTable />
        </FormLayout>
      </Stack>
    </Stack>
  );
};

export default Step0;
