import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RadioButtonGroup from "./RadioButtonGroup";
import { useEffect, useState } from "react";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { Typography } from "@mui/material";
import { getGrouperNameFromId } from "../../utils";
import useLanguage from "../../../../context/LanguageProvider";
import {
  blue_color_styles,
  collapse_validation_typography,
  list_validation_item_text_styles,
} from "../../../../styles/app-styles";
interface INestedList {
  fileColumnSelectOptions: any;
  fileSelectedOption: any;
  previousValidation: any;
  setValidationsToSend: any;
  validationsToSend: any;
  rows: any;
}

export default function NestedList({
  fileColumnSelectOptions,
  fileSelectedOption,
  previousValidation,
  validationsToSend,
  setValidationsToSend,
  rows,
}: INestedList) {
  const [open, setOpen] = React.useState(false);
  const { t } = useLanguage();
  const [validationValue, setValidationValue] = useState(
    t("modelParameterizationTexts.nestedList.flaw")
  );

  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useSingleSelect();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const defaultValidation = {
      agrupadores_relacionados: previousValidation?.agrupadores,
      receptor_gasto: previousValidation?.receptor.id,
      etapa: previousValidation?.etapa_previa,
      columna: previousValidation?.columna_defecto.id,
    };
    setValidationsToSend((prev: any[]) => {
      return prev ? [...prev] : [defaultValidation];
    });
  }, []);

  const getListItemText = `${t(
    "modelParameterizationTexts.validationDropdown.codeValidation"
  )}: ${previousValidation?.receptor?.nombre} ${t(
    "modelParameterizationTexts.validationDropdown.stage"
  )}: ${previousValidation?.etapa_previa}`;

  const grouperName = (agrupador: any, index: number) => {
    return (
      <span key={agrupador}>
        {getGrouperNameFromId(agrupador, rows)}
        {index === previousValidation.agrupadores.length - 1 ? "" : ", "}
      </span>
    );
  };

  const listGrouperNames = previousValidation?.agrupadores?.map(
    (agrupador: any, index: number) => grouperName(agrupador, index)
  );

  return (
    <List
      sx={{ width: "100%", maxWidth: 400 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton divider onClick={handleClick}>
        <ListItemText
          primary={getListItemText}
          sx={list_validation_item_text_styles}
        />
        {open ? (
          <ExpandLess sx={blue_color_styles} />
        ) : (
          <ExpandMore sx={blue_color_styles} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <Typography variant="body2" sx={collapse_validation_typography}>
          {t("modelParameterizationTexts.nestedList.relatedGroupers")}:
          {listGrouperNames}
        </Typography>
        <List component="div" sx={{ pl: 2, pr: 2 }}>
          <RadioButtonGroup
            columnSelectedOptions={columnSelectedOptions}
            columnOptionsChangeHandler={columnOptionsChangeHandler}
            fileColumnSelectOptions={fileColumnSelectOptions}
            fileSelectedOption={fileSelectedOption}
            validationValue={validationValue}
            setValidationValue={setValidationValue}
            previousValidation={previousValidation}
            setValidationsToSend={setValidationsToSend}
            validationsToSend={validationsToSend}
          />
        </List>
      </Collapse>
    </List>
  );
}
