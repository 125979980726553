import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Info from "../icons/Info";

interface InputTitleProps {
    title: string | React.ReactNode;
    disabled?: boolean;
    bgColor?: string;
    hasTooltip?: boolean;
    tooltipText?: string;
    tooltipColor?: "blue" | "white" | undefined;
    fontSize?: string;
}

const InputTitle = ({
  title,
  disabled,
  bgColor,
  hasTooltip,
  tooltipText,
  tooltipColor,
  fontSize
}: InputTitleProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        backgroundColor: disabled
          ? "var(--text-info)"
          : bgColor
          ? bgColor
          : "var(--bg-main)",
        padding: "6px 0",
        borderRadius: "5px",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontWeight: "bold",
          width: "100%",
          textAlign: "center",
          fontSize: fontSize ? fontSize : "inherit",
        }}
      >
        {title}
        {hasTooltip && (
          <Info text={tooltipText ? tooltipText : ""} color={tooltipColor} />
        )}
      </Typography>
    </Stack>
  );
};

export default InputTitle;
