import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useLanguage from "../../../../context/LanguageProvider";
interface CustomColumnInputProps {
  column: any;
  columns: any | undefined;
  setColumnsError: React.Dispatch<React.SetStateAction<string[]>>;
  newObjectNames: any;
  setNewObjectNames: React.Dispatch<React.SetStateAction<any>>;
}

const CustomColumnInput = ({
  column,
  columns,
  setColumnsError,
  newObjectNames,
  setNewObjectNames,
}: CustomColumnInputProps) => {
  const { t } = useLanguage();
  const [inputValue, setInputValue] = useState(column?.label);
  const [error, setError] = useState<boolean | string>(false);

  useEffect(() => {
    column && setInputValue(column?.label);
  }, [column])

  function saveValueInObject(objectArray: any, column: any, newColumnName: string) {

    const objectArrayNew = objectArray.map((object: any) => {
      if (object.value === column.value) {
        return { ...object, label: newColumnName }
      } else {
        return object
      }
    })
    return objectArrayNew
  }

  const handleChange = (newColumnName: string) => {
    setInputValue(newColumnName);
    setNewObjectNames(saveValueInObject(newObjectNames, column, newColumnName));
    if (newColumnName.length < 1) {
      setError(t('dataModelText.groupManagement.step2.customColumnInput.nameColumnEmpty'));
      setColumnsError((prev: string[]) => {
        return [...prev, column];
      });
    } else {
      if (checkIfNameIsRepeated(newColumnName)) {
        setError(t('dataModelText.groupManagement.step2.customColumnInput.columnsNameAlreadyExists'));
        setColumnsError((prev: string[]) => {
          return [...prev, column];
        });
      } else {
        setError(false);
        setColumnsError((prev: string[]) => {
          return prev.filter((columna: string) => {
            return columna !== column;
          });
        });
      };
    };

  };

  const checkIfNameIsRepeated = (name: string) => {
    return newObjectNames.some((object: any) => object.label === name);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", margin: "13px 0px" }}>
      <Box
        sx={{
          color: "white",
          backgroundColor: "var(--bg-main)",
          height: "38px",
          width: "300px",
          marginRight: "8px",
          fontSize: column?.label?.length > 17 ? "14px" : "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          borderRadius: "4px",
        }}
      >
        {column?.label}
      </Box>
      <TextField
        sx={{ height: "40px", padding: "0 !important" }}
        size="small"
        multiline
        variant="outlined"
        helperText={error}
        error={!!error}
        fullWidth
        value={inputValue}
        defaultValue={column?.label}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value);
        }}
        FormHelperTextProps={{
          sx: {
            color: "var(--text-error)",
            margin: 0,
          },
        }}
        required
      />
    </Box>
  );
};

export default CustomColumnInput;
