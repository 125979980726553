import CustomToolbar from "../customToolbar/CustomToolbar";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";
interface Props {
  columns: any;
  rows: any[];
  rowsPerPageOptions?: number[];
  rowHeight: number;
  tableHeight: number;
  hideFooter?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
}

const TableDataManagement = ({
  columns,
  rows,
  rowsPerPageOptions,
  rowHeight,
  tableHeight,
  hideFooter,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
}: Props) => {

  return (
    <Stack sx={{
      height: tableHeight, width: "100%",
      //Classname para alinear la tabla 
      '& .MuiDataGrid-columnHeaderTitleContainer':{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        rowsPerPageOptions={rowsPerPageOptions}
        rowHeight={rowHeight}
        components={{
          Toolbar: () => (
            <CustomToolbar
              columnsButton={columnsButton}
              filterButton={filterButton}
              densitySelector={densitySelector}
              exportButton={exportButton}
            />
          ),

          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        hideFooter={hideFooter}
        checkboxSelection={false}
        disableSelectionOnClick
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
        }}
      />
    </Stack>
  );
};

export default TableDataManagement;
