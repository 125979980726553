import { filterDataByName } from "../utils";
import {
  GET_GROUPS_FOR_IMPORT_URL,
  GROUPERS_URL,
  GROUPER_URL,
  POST_IMPORT_GROUPS_URL,
} from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useContext, useEffect, useState } from "react";
import BasicGrid from "../../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfigGroup from "./ConfigGroup";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import FixedAddButton from "../../buttons/FixedAddButton";
import GroupCard from "./GroupCard";
import GroupersProvider from "../../../context/GroupersProvider";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewGrouperInfo from "./ViewGrouperInfo";
import useInfoContext from "../../../hooks/useInfoContext";
import ImportButton from "../../importElements/ImportButton";

const GroupAdmin = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [openConfigGroup, setOpenConfigGroup] = useState<boolean>(false);
  const [selectedGrouper, setSelectedGrouper] = useState<any>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();
  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "COPY">(
    "CREATE"
  );
  const { groupersState, groupersDispatch } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [groupToDelete, setGroupToDelete] = useState<number | undefined>();
  const [
    openDeleteGroupConfirmationModal,
    handleToggleDeleteGroupConfirmationModal,
  ] = useDialog();
  const {
    isLoading: loadingGroups,
    data: dataGroups,
    refetch: refetchGroups,
  } = useApiQuery(GROUPERS_URL, true, t("dataModel.getGroups.error"));

  const { isLoading: isLoadingDeleteGroup, callApi: deleteGroupById } = useApi(
    GROUPER_URL(groupToDelete),
    "DELETE",
    t("dataModel.deleteGroup.codes"),
    undefined,
    refetchGroups
  );

  const onSuccesGetGrouper = (data: any) => {
    groupersDispatch({ type: "SET_GROUPER", payload: data });
    setOpenConfigGroup(true);
  };

  const onSuccesGetGroupers = (data: any) => {
    groupersDispatch({ type: "SET_GROUPER_LIST", payload: data });
  };

  useEffect(() => {
    onSuccesGetGroupers(dataGroups);
  }, [dataGroups]);

  const {
    isLoading: isLoadingGetGroup,
    callApi: getGroup,
    data: dataGetGrouper,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    onSuccesGetGrouper,
    undefined,
    false
  );

  const onSuccessImportGroups = () => {
    refetchGroups();
  };

  const { isLoading: isLoadingPostImportGroups, callApi: postImportGroups } =
    useApi(
      POST_IMPORT_GROUPS_URL,
      "POST",
      undefined,
      undefined,
      onSuccessImportGroups,
      undefined,
      true
    );

  const {
    callApi: getElements,
    data: dataGetElements,
    isLoading: isLoadingGetElements,
  } = useApi(
    GET_GROUPS_FOR_IMPORT_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccesViewInfo = (data: any) => {
    setSelectedGrouper(data);
    handleToggleViewInfoModal();
  };

  const {
    isLoading: isLoadingGetGroupInfo,
    callApi: getGroupInfo,
    data: dataGetGrouperInfo,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    onSuccesViewInfo,
    undefined,
    false
  );

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataGroups, e.target.value, setFilteredData);
  };

  const handleEdit = (e: any, id: string) => {
    setConfigMode("EDIT");
    getGroup(GROUPER_URL(parseInt(id)));
  };

  const handleCopy = (e: any, id: string) => {
    setConfigMode("COPY");
    getGroup(GROUPER_URL(parseInt(id)));
  };

  const handleInfo = (e: any, id: string) => {
    getGroupInfo(GROUPER_URL(parseInt(id)));
  };

  const handleDelete = () => {
    deleteGroupById();
    handleToggleDeleteGroupConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setGroupToDelete(id);
    handleToggleDeleteGroupConfirmationModal();
  };

  const handleAddGroup = () => {
    setConfigMode("CREATE");
    setOpenConfigGroup(true);
  };

  const handleFinish = () => {
    setOpenConfigGroup(false);
    groupersDispatch({ type: "CLEAN" });
    refetchGroups();
  };

  return (
    <>
      {openConfigGroup ? (
        <ConfigGroup configMode={configMode} handleFinish={handleFinish} />
      ) : (
        <ComponentLayout
          title={t("dataModelText.groupManagement.groupAdmin.groupsAdmin")}
          icon={<GroupWorkIcon />}
          rightComponent={
            <ImportButton
              postImportElements={postImportGroups}
              getElements={getElements}
              dataGetElements={dataGetElements}
              GET_ELEMENTS_URL={GET_GROUPS_FOR_IMPORT_URL}
            />
          }
        >
          <SimpleBackdrop
            open={
              isLoadingGetGroup || isLoadingGetGroupInfo || isLoadingGetElements
            }
            message={t("general.loading")}
          />
          <SimpleBackdrop
            open={isLoadingDeleteGroup}
            message={t("dataModel.deleteGroup.loading")}
          />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <SearchInput
              value={searchValue}
              handleChange={handleSearch}
              placeholder={t(
                "dataModelText.groupManagement.groupAdmin.searchByName"
              )}
            />
          </Stack>
          {filteredData?.length || dataGroups?.length ? (
            <BasicGrid
              data={filteredData ? filteredData : dataGroups}
              Card={GroupCard}
              handleEdit={
                userRolData &&
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion &&
                handleEdit
              }
              handleCopy={
                userRolData &&
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion &&
                handleCopy
              }
              handleInfo={handleInfo}
              handleDelete={
                userRolData &&
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion &&
                handleClickOnDelete
              }
              showInfo={true}
            />
          ) : (
            !loadingGroups && (
              <Box
                sx={{
                  width: "95%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "700", color: "var(--text-info)" }}
                >
                  {t("dataModelText.groupManagement.groupAdmin.nonGroupsYet")}
                </Typography>
              </Box>
            )
          )}
          {userRolData &&
            (userRolData.administracion_de_medidas_agrupaciones_y_analisis
              .visualizacion_y_creacion ||
              userRolData.administracion_de_medidas_agrupaciones_y_analisis
                .visualizacion_y_edicion) && (
              <FixedAddButton handleClick={handleAddGroup} />
            )}
          <ConfirmationModal
            open={openDeleteGroupConfirmationModal}
            handleClose={handleToggleDeleteGroupConfirmationModal}
            handleAccept={handleDelete}
            message={t("dataModelText.groupManagement.groupAdmin.removeGroup")}
            title={t("dataModelText.groupManagement.groupAdmin.confirm")}
          />
          <ViewGrouperInfo
            grouper={selectedGrouper}
            open={openViewInfoModal}
            handleClose={handleToggleViewInfoModal}
            handleEdit={handleEdit}
          />
        </ComponentLayout>
      )}
    </>
  );
};

export default GroupAdmin;
