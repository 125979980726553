import Grid from "@mui/material/Grid";
import InputTitle from "../forms/InputTitle";
import useLanguage from "../../context/LanguageProvider";

const SummaryRowTitles = () => {
  const { t } = useLanguage();

  return (
    <>
      <Grid item xs={0.5}></Grid>
      <Grid item xs={5.5}>
        <InputTitle title={t("basicGrid.column")}
 />
      </Grid>
      <Grid item xs={5}>
        <InputTitle title={t("basicGrid.function")}
 />
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default SummaryRowTitles;
