import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import useLanguage from "../../../context/LanguageProvider";
import { truncateString } from "../../parameterization/utils";

const GroupCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
}: any) => {
  const { t } = useLanguage();
  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--text-main)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "15px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "5px 0" }} />
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
          }}
          color="var(--text-main)"
        >
          {t('dataModelText.groupManagement.groupCard.usedColumns')}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "left", height: "65px", overflow: "hidden" }}
          color="var(--text-info)"
        >
          {item.columnas_a_usar?.map((columna: any, i: number) => {
            return `${columna}${
              i + 1 === item.columnas_a_usar.length ? "" : ", "
            }`;
          })}
        </Typography>
        <SpeedDialTooltipOpen
          id={item.id}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
};

export default GroupCard;
