import { ConfirmationModal } from "../../dialog";
import { filterDataByName } from "../../dataModel/utils";
import { Tab, TabValue } from "../../colorTabs/types";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VALIDATIONS_URL, VALIDATION_URL } from "../../../api/axios";
import BasicGrid from "../../basicGrid/BasicGrid";
import CodeMatchingCard from "./CodeMatchingCard";
import ColorTabs from "../../colorTabs/ColorTabs";
import ComponentLayout from "../../layout/ComponentLayout";
import FixedAddButton from "../../buttons/FixedAddButton";
import NoCardsError from "../../error/NoCardsError";
import RuleIcon from "@mui/icons-material/Rule";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoCodeMatchingModal from "./ViewInfoCodeMatchingModal";
import useInfoContext from "../../../hooks/useInfoContext";

const AdminValidations = () => {
  const { t, language } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("validations.validationsAdmin.tabs.codeMatching")
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [validationToDelete, setValidationToDelete] = useState<
    number | undefined
  >();
  const [
    openDeleteValidationConfirmationModal,
    toggleDeleteValidationConfirmationModal,
  ] = useDialog();
  const [openViewValidationModal, toggleViewValidationModal] = useDialog();

  useEffect(() => {
    setTabValue(t("validations.validationsAdmin.tabs.codeMatching"));
  }, [language]);

  /********************************* LLAMADAS PARA PEDIR INFO *****************************/
  const {
    data: dataGetCodeMatching,
    isLoading: isLoadingGetCodeMatching,
    refetch: refetchGetCodeMatching,
  } = useApiQuery(
    `${VALIDATIONS_URL}?tipo_validacion=CRUCE_CODIGOS`,
    true,
    t("validations.validationsAdmin.getCodeMatching.error")
  );

  const onSuccessValidationInfo = (data: any) => {
    localStorage.setItem("validationInfo", JSON.stringify(data));
    toggleInfoValidationModal();
    if (
      localStorage.getItem("configMode") === "EDIT" ||
      localStorage.getItem("configMode") === "COPY"
    ) {
      navigate("/configuracion/cruce-codigos");
    }
  };

  const {
    data: validationInfo,
    isLoading: isLoadingGetValidation,
    callApi: getValidation,
  } = useApi(
    VALIDATION_URL(""),
    "GET",
    t("validations.configValidations.getValidation.codes"),
    undefined,
    onSuccessValidationInfo,
    undefined,
    false
  );

  /*************************** OBTENER INFO SEGÚN TABVALUE ************************/

  const tabs: Tab[] = [
    {
      value: t("validations.validationsAdmin.tabs.codeMatching"),
      label: t("validations.validationsAdmin.tabs.codeMatching"),
    },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
    configByTabValue(newValue)?.setFilteredData();
  };

  /**
   * Configura y proporciona datos y funciones relacionados con el valor de la pestaña.
   *
   * @param {string} tabValue - El valor de la pestaña actual.
   * @returns {Object} - Un objeto que contiene datos y funciones relacionados con la pestaña.
   */

  const configByTabValue = (tabValue: string) => {
    switch (tabValue) {
      case t("validations.validationsAdmin.tabs.codeMatching"):
        return {
          data: () => dataGetCodeMatching,
          loading: isLoadingGetCodeMatching,
          refetch: () => refetchGetCodeMatching(),
          setFilteredData: () => setFilteredData(dataGetCodeMatching),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataGetCodeMatching, searchValue, setFilteredData),
          toggleValidationModal: () => toggleViewValidationModal(),
        };
      default:
        return {
          data: () => dataGetCodeMatching,
          loading: isLoadingGetCodeMatching,
          refetch: () => refetchGetCodeMatching(),
          setFilteredData: () => setFilteredData(dataGetCodeMatching),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataGetCodeMatching, searchValue, setFilteredData),
          toggleValidationModal: () => toggleViewValidationModal(),
        };
    }
  };

  const onSuccessDeleteValidation = () => {
    configByTabValue(tabValue).refetch();
    setFilteredData([]);
  };

  const {
    isLoading: isLoadingDeleteValidation,
    callApi: deleteValidationById,
  } = useApi(
    VALIDATION_URL(validationToDelete),
    "DELETE",
    t("validations.validationsAdmin.deleteValidation.codes"),
    undefined,
    onSuccessDeleteValidation
  );

  /*************************** FUNCIONES DE LAS CARDS ***********************************/

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    configByTabValue(tabValue)?.filterDataByName(e.target.value);
  };

  const handleEdit = (e: any, id: number) => {
    localStorage.setItem("configMode", "EDIT");
    getValidation(VALIDATION_URL(id.toString()));
  };

  const handleCopy = (e: any, id: number) => {
    localStorage.setItem("configMode", "COPY");
    getValidation(VALIDATION_URL(id.toString()));
  };

  const handleInfo = (e: any, id: number) => {
    getValidation(VALIDATION_URL(id.toString()));
  };

  const handleDelete = () => {
    deleteValidationById();
    toggleDeleteValidationConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setValidationToDelete(id);
    toggleDeleteValidationConfirmationModal();
  };

  const handleCreate = () => {
    localStorage.setItem("configMode", "CREATE");
    navigate("/configuracion/cruce-codigos");
  };

  const toggleInfoValidationModal = () => {
    configByTabValue(tabValue).toggleValidationModal();
  };

  return (
    <ComponentLayout
      title={t("validations.validationsAdmin.title")}
      icon={<RuleIcon />}
    >
      <SimpleBackdrop
        open={isLoadingGetCodeMatching}
        message={t("validations.validationsAdmin.getCodeMatching.loading")}
      />
      <SimpleBackdrop
        open={isLoadingDeleteValidation}
        message={t("validations.validationsAdmin.deleteValidation.loading")}
      />
      <SimpleBackdrop
        open={isLoadingGetValidation}
        message={t("validations.validationsAdmin.getCodeMatching.loading")}
      />
      <ColorTabs
        value={tabValue}
        handleChange={handleChangeTab}
        tabs={tabs}
        marginBottom="0px"
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder={t("general.searchByName")}
        />
      </Stack>
      {filteredData?.length || configByTabValue(tabValue)?.data()?.length ? (
        <BasicGrid
          data={
            filteredData && filteredData.length
              ? filteredData
              : configByTabValue(tabValue).data()
          }
          Card={CodeMatchingCard}
          handleEdit={userRolData && userRolData.validaciones.visualizacion_y_edicion && handleEdit}
          handleCopy={userRolData && userRolData.validaciones.visualizacion_y_edicion && handleCopy}
          handleInfo={handleInfo}
          handleDelete={userRolData && userRolData.validaciones.visualizacion_y_edicion && handleClickOnDelete}
          showInfo={true}
          xs={4}
        />
      ) : (
        !configByTabValue(tabValue).loading && <NoCardsError height={undefined}/>
      )}
      {(userRolData && (userRolData.validaciones.visualizacion_y_creacion ||
        userRolData.validaciones.visualizacion_y_edicion)) && (
        <FixedAddButton handleClick={handleCreate} />
      )}
      <ConfirmationModal
        open={openDeleteValidationConfirmationModal}
        handleClose={toggleDeleteValidationConfirmationModal}
        handleAccept={handleDelete}
        title={t(
          "validations.validationsAdmin.deleteValidation.confirmationModal.title"
        )}
        message={t(
          "validations.validationsAdmin.deleteValidation.confirmationModal.message"
        )}
        customMessage={true}
      />
      <ViewInfoCodeMatchingModal
        info={validationInfo}
        open={openViewValidationModal}
        handleClose={toggleInfoValidationModal}
        title={validationInfo?.nombre}
      />
    </ComponentLayout>
  );
};

export default AdminValidations;
