import CardDetailItem from "../../cards/CardDetailItem";
import React from "react";
import Stack from "@mui/material/Stack/Stack";

const IndicatorDetails: React.FC<{ item: any; t: any; indicatorConfig: any }> = ({
  item,
  t,
  indicatorConfig,
}) => (
  <Stack spacing={indicatorConfig.spacing || 1}>
    <CardDetailItem
      title={t("indicatorsManagementTexts.chartCard.baseFile")}
      value={item.archivo?.label || ""}
    />
    {item.unidad_medida && (
      <CardDetailItem
        title={indicatorConfig?.unitMeasureLabel}
        value={indicatorConfig?.getUnitMeasureValue && indicatorConfig.getUnitMeasureValue(item)}
      />
    )}
    {item.columna && indicatorConfig?.columnLabel && (
      <CardDetailItem
        title={indicatorConfig?.columnLabel}
        value={indicatorConfig?.getColumnValue && indicatorConfig?.getColumnValue(item)}
      />
    )}    
  </Stack>
);

export default IndicatorDetails