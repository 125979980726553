import { SingleValue } from "react-select";
import {
  IncomingBucketOption,
  IncomingTargetOption,
  IncomingFileOption,
  IncomingFile_Column,
  IncomingValueOption,
  BucketOptions,
  ExpenseTargetOptions,
  FileOptions,
  ColumnOptions,
  FunctionOptions,
  ValuesOptions,
  IdOption,
  ExpenseColumnOptions,
} from "./types";


export const expenseTargetOptions: ExpenseTargetOptions = (
  expenseTargetSelectOptions: IncomingTargetOption[] | undefined
) => {
  
  return expenseTargetSelectOptions? expenseTargetSelectOptions.map((option: IncomingTargetOption) => {
    return {
      value: option.id,
      label: option.nombre,
    };
  }): []
};

export const fileOptions: FileOptions = (
  fileColumnSelectOptions: IncomingFileOption[] | undefined
) => {  
  return fileColumnSelectOptions ? fileColumnSelectOptions.map((option: IncomingFileOption) => {
    return {
      value: option.id,
      label: option.nombre,
    };
  }): []
};

export const columnOptions: ColumnOptions = (
  fileColumnSelectOptions: IncomingFileOption[] | undefined,
  fileSelectedOptions: SingleValue<IdOption> | undefined
) => {
  const findColumnsArray: IncomingFile_Column[] | undefined =
    fileColumnSelectOptions ? fileColumnSelectOptions.find(
      (option: IncomingFileOption) => option?.id === fileSelectedOptions?.value
    )?.columna_archivo : [];    
  return findColumnsArray?.map((option: IncomingFile_Column) => {
    return {
      value: option.id,
      label: option.nombre,
    };
  });
};

export const functionOptions: FunctionOptions = (
  functionSelectOptions: string[] | undefined
) => {
  return functionSelectOptions? functionSelectOptions.map((option: string) => {
    return {
      value: option,
      label: option,
    };
  }): []
};

export const conditionOptions: FunctionOptions = (
  conditionSelectOptions: string[] | undefined
) => {
  return conditionSelectOptions ? conditionSelectOptions.map((option: string) => {
    return {
      value: option,
      label: option,
    };
  }) : []
};

export const valuesOptions: ValuesOptions = (
  valuesSelectOptions: IncomingValueOption[] | undefined
) => {
  
  return valuesSelectOptions? valuesSelectOptions.map((option: IncomingValueOption) => {
    return {
      value: option.valor,
      label: option.valor,
    };
  }) : []
};

export const expenseColumnOptions: ExpenseColumnOptions = (
  expenseColumnSelectOptions: IncomingFileOption | undefined
) => {
  return expenseColumnSelectOptions ? expenseColumnSelectOptions.columna_archivo.map(
    (option: IncomingFile_Column) => {
      return {
        value: option.id,
        label: option.nombre,
      };
    }
  ): []
};