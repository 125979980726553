import { COMPANY_PARAMETERS_PNL_LEVEL_URL } from "../../../../../api/axios";
import { LevelElement } from "../../data";
import { useCompanyParamProvider } from "../../../../../context/CompanyParamProvider";
import { useState } from "react";
import ConfirmationModal from "../../../../dialog/ConfirmationModal";
import Grid2 from "@mui/material/Unstable_Grid2";
import Level from "./Level";
import SaveNameModal from "../../../../dialog/SaveNameModal";
import Stack from "@mui/material/Stack";
import useApi from "../../../../../hooks/useApi";
import useDialog from "../../../../../hooks/useDialog";
import useLanguage from "../../../../../context/LanguageProvider";
import SimpleBackdrop from "../../../../backdrop/SimpleBackdrop";

const Levels = () => {
  const { t } = useLanguage();
  const { dataGetPnlLevels, getPnlLevels, setMessage } =
    useCompanyParamProvider();
  const [levelName, setLevelName] = useState("");
  const [openSaveNameModal, toggleSaveNameModal] = useDialog();
  const [openConfirmDeleteElement, toggleConfirmDeleteElement] = useDialog();
  const [element, setElement] = useState<LevelElement>();
  const [callType, setCallType] = useState("");

  /******************************************* Nuevo nivel ********************************************/

  const onSuccessPostPnlLevel = () => {
    setLevelName("");
    getPnlLevels();
    setCallType("");
    setElement(undefined);
  };

  const { isLoading: isLoadingPostPnlLevels, callApi: postPnlLevel } = useApi(
    COMPANY_PARAMETERS_PNL_LEVEL_URL,
    "POST",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessPostPnlLevel,
    undefined,
    false
  );

  const handleAddLevel = (event, element) => {
    setElement(element);
    setCallType("POST");
    toggleSaveNameModal();
    event.stopPropagation();
  };

  const handleSaveName = (action) => {
    setLevelName(action.payload);
  };

  const isValidName = (namesList, value) => {
    return !namesList.some((item) => item.nombre === value);
  };

  const addLevel = () => {
    postPnlLevel(undefined, {
      nombre: levelName,
      orden: element && element.orden ? element.orden + 1 : undefined,
      vinculo: undefined,
    });
    toggleSaveNameModal();
  };

  /******************************************* Editar nivel ********************************************/

  const { isLoading: isLoadingPutPnlLevel, callApi: putPnlLevel } = useApi(
    COMPANY_PARAMETERS_PNL_LEVEL_URL,
    "PUT",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessPostPnlLevel,
    undefined,
    false
  );

  const handleEditLevel = (event, element) => {
    setElement(element);
    setLevelName(element.nombre);
    setCallType("PUT");
    toggleSaveNameModal();
    event.stopPropagation();
  };

  const editLevel = () => {
    putPnlLevel(`${COMPANY_PARAMETERS_PNL_LEVEL_URL}/${element?.id}`, {
      nombre: levelName,
      orden: element && element.orden ? element.orden : undefined,
    });
    toggleSaveNameModal();
  };

  /******************************************* Eliminar nivel ********************************************/

  const { isLoading: isLoadingDeletePnlLevels, callApi: deletePnlLevel } =
    useApi(
      `${COMPANY_PARAMETERS_PNL_LEVEL_URL}/${element?.id}`,
      "DELETE",
      t("companyParam.getParams.codes"),
      undefined,
      onSuccessPostPnlLevel,
      undefined,
      false
    );

  const handleDeleteLevel = (event, element) => {
    if (dataGetPnlLevels && dataGetPnlLevels.length <= 3) {
      setMessage({
        type: "error",
        text: t("companyParam.minimunNumberLevels"),
      });
      return;
    } else {
      setMessage({ type: "", text: "" });
      setElement(element);
      toggleConfirmDeleteElement();
      event.stopPropagation();
    }
  };

  const deleteLevel = () => {
    deletePnlLevel(`${COMPANY_PARAMETERS_PNL_LEVEL_URL}/${element?.id}`);
    toggleConfirmDeleteElement();
  };

  return (
    <Grid2 xs={12}>
      <SimpleBackdrop
        open={
          isLoadingPostPnlLevels ||
          isLoadingPutPnlLevel ||
          isLoadingDeletePnlLevels
        }
        message={t("general.loading")}
      />
      <Stack direction="row" justifyContent={"space-between"} gap={2}>
        {dataGetPnlLevels &&
          dataGetPnlLevels.map((el: LevelElement) => (
            <Level
              key={el.orden}
              element={el}
              handleAddLevel={handleAddLevel}
              handleEditLevel={handleEditLevel}
              handleDeleteLevel={handleDeleteLevel}
            />
          ))}
      </Stack>
      {openSaveNameModal && callType !== "" && (
        <SaveNameModal
          open={openSaveNameModal}
          handleClose={toggleSaveNameModal}
          handleAccept={
            callType === "POST" ? () => addLevel() : () => editLevel()
          }
          title={
            callType === "POST"
              ? t("companyParam.addLevel")
              : t("companyParam.editName")
          }
          text={
            callType === "POST"
              ? t("companyParam.confirmCreateLevel")
              : t("companyParam.confirmEditLevelName")
          }
          label={t("general.name")}
          placeholder={
            callType === "POST"
              ? t("companyParam.writeNewLevelName")
              : t("companyParam.writeNewName")
          }
          dispatchFunction={(action) => handleSaveName(action)}
          dispatchType={""}
          valueState={levelName}
          namesList={
            dataGetPnlLevels && dataGetPnlLevels.length > 0
              ? dataGetPnlLevels
              : []
          }
          isValidName={isValidName}
        />
      )}
      <ConfirmationModal
        open={openConfirmDeleteElement}
        handleClose={toggleConfirmDeleteElement}
        handleAccept={deleteLevel}
        message={t("companyParam.deleteThisLevel")}
        title={t("companyParam.deleteLevel")}
      />
    </Grid2>
  );
};

export default Levels;
