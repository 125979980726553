import { stepperData } from "../data";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import BasicGrid from "../../../basicGrid/BasicGrid";
import ChartCard from "./ChartCard";
import ConfigChartStep4 from "./ConfigChartStep4";
import Dialog from "../../../dialog/Dialog";
import FixedAddButton from "../../../buttons/FixedAddButton";
import NoCardsError from "../../../error/NoCardsError";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import {
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";
import { useEffect } from "react";
import MessageBlock from "../../../forms/MessageBlock";

const Step4 = () => {
  const { t } = useLanguage();
  const { marketBasketElement, setMarketBasketElement, setMessage, message } =
    useMarketBasketConfigProvider();
  const [openNewChartModal, toggleNewChartModal] = useDialog();

  /************************************* Limpieza inicial ********************************/
  useEffect(() => {
    setMessage({
      text: "",
      type: "",
    });
  }, []);

  /*************************** FUNCIONES DE LAS CARDS ***********************************/

  const handleDelete = (e, id) => {
    setMarketBasketElement((prev) => {
      return {
        ...prev,
        graficas: prev.graficas.filter((item) => item.nombre !== id),
      };
    });
  };

  const handleApply = (chart) => {
    const updatedCharts =
      marketBasketElement?.graficas && marketBasketElement?.graficas.length > 0
        ? [...marketBasketElement.graficas]
        : [];
    updatedCharts.push(chart);
    setMarketBasketElement((prev) => {
      return { ...prev, graficas: updatedCharts };
    });
    toggleNewChartModal();
  };

  return (
    <Stack sx={{ ...param_steps_container, minHeight: "57vh" }}>
      <Typography sx={step_title_styles}>{stepperData(t).steps[3]}</Typography> 
      <MessageBlock message={message} />    
        {marketBasketElement.graficas && marketBasketElement.graficas.length ? (
          <BasicGrid
            data={marketBasketElement.graficas.map((item) => ({
              ...item,
              id: item.nombre,
            }))}
            Card={ChartCard}
            handleDelete={handleDelete}
            showInfo={true}
          />
        ) : (
          <NoCardsError height={"45vh"}/>
        )}
        {<FixedAddButton handleClick={toggleNewChartModal} />}
        <Dialog
          open={openNewChartModal}
          handleClose={toggleNewChartModal}
          title={t('marketBasket.config.newChart')}
          maxWidth="md"
        >
          <Stack sx={{ width: "800px", minHeight: "300px" }}>
            <ConfigChartStep4
              handleToggleModal={toggleNewChartModal}
              elements={marketBasketElement.graficas}
              handleApply={handleApply}
            />
          </Stack>
        </Dialog>   
    </Stack>
  );
};

export default Step4;
