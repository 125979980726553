import { Button as CustomButton } from "../../../buttons";
import { checkIfAmountIsZero } from "../../utils";
import { useParameterization } from "../../../../hooks/useParameterization";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../../../context/LanguageProvider";

const CalculateBucketButton = ({ handleConfirmation }) => {
  const { t } = useLanguage();
  const {
    parameterizationState,
    isLoadingRows,
    chartsResultsLoading,
    rows,
    rowsNt,
  } = useParameterization();
  const {
    tabValue,
    selectedStage,
    selectedIteration,
    rowsWithoutAssignedCriteria,
    ntRowsWithoutAssignedCriteria,
  } = parameterizationState;

  const TAB_GNT = t(
    "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
  );
  const TAB_EXPENSES = t("modelParameterizationTexts.step2.tabValueExpenses");

  const isCalculated: boolean =
    (tabValue === TAB_EXPENSES && selectedStage?.calculado) ||
    (tabValue === TAB_GNT && selectedIteration?.calculado);

  const areRowsWithoutAssignedCriteria =
    (tabValue === TAB_EXPENSES && rowsWithoutAssignedCriteria) ||
    (tabValue === TAB_GNT && ntRowsWithoutAssignedCriteria);

  const expenseToCalculate = () => {
    return tabValue === TAB_EXPENSES
      ? t(
          "modelParameterizationTexts.calculateBucketButton.calculateActualBucket"
        )
      : t(
          "modelParameterizationTexts.calculateBucketButton.calculateBucketWithNotTransferred"
        );
  };

  const rowsToCheckAmount = () => {
    return tabValue === TAB_GNT ? rowsNt && rowsNt : rows && rows;
  };

  const isButtonDisabled: boolean =
    isCalculated ||
    areRowsWithoutAssignedCriteria ||
    isLoadingRows ||
    chartsResultsLoading ||
    checkIfAmountIsZero(rowsToCheckAmount());

  const tooltipTitle = areRowsWithoutAssignedCriteria
    ? t(
        "modelParameterizationTexts.calculateBucketButton.rowsWithoutAssignedCriteria"
      )
    : checkIfAmountIsZero(rowsToCheckAmount())
    ? t("modelParameterizationTexts.calculateBucketButton.amountIsZero")
    : "";

  const buttonTitle = isCalculated
    ? t("modelParameterizationTexts.calculateBucketButton.calculated")
    : t("modelParameterizationTexts.calculateBucketButton.notCalculated");

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <CustomButton
          title={buttonTitle}
          color="outlined"
          type="button"
          margin={false}
          onClick={() => handleConfirmation(expenseToCalculate(), "calculate")}
          disabled={isButtonDisabled}
        />
      </div>
    </Tooltip>
  );
};

export default CalculateBucketButton;
