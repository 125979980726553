import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizedTable from "../../virtualizatedTable/VirtualizedTable";

const PnlCard = ({ item, showInfo }: any) => {
  const { t } = useLanguage();

  const ROWS1 = item?.data?.map(
    (elem) => ({
      id: elem.id,
      NOMBRE: elem.nombre,
      TIPO: elem.tipo,
      ARCHIVO: elem.archivo ? elem.archivo : "-",
    })
  )

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        Parametrización P&L
        {showInfo && ROWS1?.length && (
          <Stack width={`100%`}>
            <VirtualizedTable
              columns={
                transformDataSimpleTable(ROWS1, undefined, undefined, t, 260)
                  .columns
              }
              rows={
                transformDataSimpleTable(ROWS1, undefined, undefined, t, 260)
                  .rows
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={25}
              tableHeight={"200px"}
              hideFooter={true}
              toolbar={false}
              columnsButton={false}
              filterButton={false}
              exportButton={false}
              checkboxSelection={false}
              setSelectedRows={() => <></>}
              headerHeight={25}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default PnlCard;
