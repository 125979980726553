import useLanguage from "../../../../context/LanguageProvider";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import OtherFilesInput from "./OtherFilesInput";
import OtherFilesRow from "./OtherFilesRow";
import Stack from "@mui/material/Stack";
interface OtherFilesInputsProps {
  setFileColumns: any;
  fileColumns: any;
}

const OtherFilesInputs = ({
  setFileColumns,
  fileColumns,
}: OtherFilesInputsProps) => {
  const { t } = useLanguage();
  
  return (
    <FormSelectLayout
      title={t('companyParam.addFileAndDescription')}
      required={false}
    >
      <Stack width="100%" sx={{ minHeight: "280px" }}>
        <OtherFilesInput />
        {fileColumns &&
          fileColumns.map((item) => {
            return (
              <OtherFilesRow
                key={item.nombre}
                row={item}
                rows={fileColumns}
                setRows={setFileColumns}
              />
            );
          })}
      </Stack>
    </FormSelectLayout>
  );
};

export default OtherFilesInputs;
