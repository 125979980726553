import { Checkbox, ListItemText, MenuItem } from "@mui/material";

type ListElements = {
  data: any[];
  selectedElements: any[];
  item: any;
  handleChangeElement: (dataElement: any) => void;
};

const ListElements = (data, selectedElements, item, handleChangeElement) => {
  const filteredData = data
    ?.filter((e) => e.item === item)
    .map((dataElement) => (
      <MenuItem
        key={dataElement.id}
        dense={true}
        value={dataElement.id}
        sx={{ color: "var(--text-main)" }}
      >
        <Checkbox
        onChange={() => handleChangeElement(dataElement)}
          checked={selectedElements.map(el => el.value).includes(dataElement.value)}       
          sx={{ color: "var(--text-main)", "& svg": { stroke: "var(--text-main)" } }}
        />
        <ListItemText
          primary={dataElement.label}
          sx={{ color: "var(--text-main)", whiteSpace: "normal", height: "auto" }}
        />
      </MenuItem>
    ));
  return filteredData;
};

export default ListElements;
