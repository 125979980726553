import { Stack, Typography, styled } from "@mui/material";
import MuiInput from '@mui/material/Input';

export const roundNumber = (number: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.round(number * factor) / factor;
}

export function getCorrelationColor(medidaGasto, correlacion, correlationArray) {
    // Encontrar los datos de la medida_gasto específica
    const medidaData = correlationArray?.find(item => item.medida_gasto === medidaGasto);

    if (!medidaData) {
        // Retornar un color por defecto si no se encuentra la medida
        return '#a12e2c'; // Negro como fallback
    }

    // Extraer y ordenar las correlaciones de mayor a menor
    const sortedCorrelations = medidaData.data
        .filter(data => data.driver !== "CAGRs") // Filtrar los "CAGRs"
        .map(d => parseFloat(d.accuracy))
        .sort((a, b) => Math.abs(1 - a) - Math.abs(1 - b));

    // Encontrar la posición de la correlación actual en el orden
    const correlationIndex = sortedCorrelations.indexOf(parseFloat(correlacion));

    // Definir los colores específicos para las posiciones
    let colors: any = []

    colors = [
        `#115631`,   // Verde Oscuro
        `rgba(88, 136, 110, 1)`,  // Verde Claro
        `#E6A800`,  // Amarillo
        `#CC7A00`,   // Naranja
        `#F90D4A`,  // Rojo Claro
        `#C00535`,// Rojo
    ];
    // De mayor a menor: Green, Light Yellow, Yellow, Orange, Red

    // Retornar el color correspondiente a la posición
    return colors[correlationIndex] || '#000'; // Negro como fallback si algo falla
}

export function extractAndFormatDataAsObject(data) {
    const formattedObject = {};
    data.forEach(item => {
        item.data.forEach(detail => {
            const label = detail.driver.label;
            const cagr = detail.cagr;
            formattedObject[label] = `${roundNumber(cagr * 100, 2)}%`; // Asignar cada driver al objeto con su cagr
        });
    });
    return formattedObject;
}
// Definición de los colores extremos
const negativeColors = ["#FFF3CD", "#FFC107"]; // De amarillo claro a más claro cercano a 0
const positiveColors = ["#1F3B4D", "#A3C9E2"]; // De azul oscuro a azul claro cercano a 0
const zeroColor = "#FFFFFF"; // Blanco para correlación 0

const interpolateColor = (color1, color2, factor = 0.5) => {
    const result = color1.slice(1).match(/.{2}/g)
        .map((hex, idx) => {
            return Math.round(
                parseInt(hex, 16) + factor * (parseInt(color2.slice(1).match(/.{2}/g)[idx], 16) - parseInt(hex, 16))
            );
        });
    return `#${result.map(val => val.toString(16).padStart(2, '0')).join('')}`;
}

// Función para asignar colores en base a correlaciones con mayor gradación clara hacia el 0
export const assignColors = (correlations) => {
    const colorMap = new Map();
    const maxNegative = -1;
    const minNegative = 0;
    const minPositive = 0;
    const maxPositive = 1;

    correlations.forEach(correlation => {
        if (correlation === 0) {
            colorMap.set(correlation, zeroColor);
        } else if (correlation < 0) {
            const factor = (correlation - maxNegative) / (minNegative - maxNegative);
            const color = interpolateColor(negativeColors[1], negativeColors[0], factor); // Invertir para aclarar hacia 0
            colorMap.set(correlation, color);
        } else if (correlation > 0) {
            const factor = (correlation - minPositive) / (maxPositive - minPositive);
            const color = interpolateColor(positiveColors[1], positiveColors[0], factor); // Invertir para aclarar hacia 0
            colorMap.set(correlation, color);
        }
    });

    return colorMap;
}

// Componente de Input Estilizado
const StyledInput = styled(MuiInput)`
    width: 60px;
    margin-right: 8px;
    text-align: center;
`;

// Componente de Celda para Variabilidad
export const VariabilityCell = ({ value, onChange }) => (
    <Stack sx={{ flexDirection: 'row', alignItems: "center" }}>
        <StyledInput
            value={formatValue(value || 0)}
            onChange={onChange}
            inputProps={{
                style: {
                    textAlign: 'center', // Esto centra el texto dentro del input
                },
                step: 0.1,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
            }}
        />
        <Typography>%</Typography>
    </Stack>
);

// Componente de Celda para el Cost Driver
export const CostDriverCell = ({ variableMeasure }) => (
    <Stack sx={{ pl: 5, pr: 5, width: "100%", alignItems: "center" }}>
        {variableMeasure && variableMeasure?.option ?
            <Typography sx={{ width: "100%", display: "flex", justifyContent: "center", fontWeight: 'bold' }}>
                {variableMeasure?.option.label}
            </Typography> :
            <Typography sx={{ width: "100%", display: "flex", justifyContent: "center", color: "red", fontWeight: 'bold' }}>
                -
            </Typography>
        }
    </Stack>
);

// Formato de valores
export const formatValue = (value: number | string) => {
    return value ? String(value).replace(/^0+(?=\d)/, '') : '0';
};

/**
 * Filtra los datos por nombre y actualiza el conjunto de datos filtrados.
 *
 * @param {Array} data - El conjunto de datos original.
 * @param {string} value - El valor por el cual se va a filtrar.
 * @param {function} setFilteredData - La función para actualizar los datos filtrados.
 */

export const filterDataByName = (
    data: any,
    value: string,
    setFilteredData: (value: any) => void
) => {
    if (value && data) {
        setFilteredData(
            data?.filter((object: any) => {
                return object.nombre.toLowerCase().includes(value?.toLowerCase());
            })
        );
    } else {
        data && setFilteredData(data);
    }
};

/**
 * Combina objetos en un conjunto de datos y agrega claves faltantes con el valor "-".
 *
 * @param {Array} data - El conjunto de datos original.
 * @returns {Array} - El conjunto de datos combinado con claves faltantes.
 */

export const mergeObjectsWithDefault = (data) => {
    // se obtiene una lista de todas las claves en todos los objetos
    const allKeys = data.reduce((keys, obj) => {
        return keys.concat(Object.keys(obj));
    }, []);

    // Eliminamos duplicados de la lista de claves
    const uniqueKeys = Array.from(new Set(allKeys));

    // Recorre cada objeto en el array y agrega claves faltantes con valor "-"
    const mergedData = data.map((obj) => {
        const newObj = { ...obj };
        uniqueKeys.forEach((key: any) => {
            //hasOwnProperty devuelve true o false si existe la key que se le especifica
            if (!newObj.hasOwnProperty(key)) {
                newObj[key] = "-";
            }
        });
        return newObj;
    });

    return mergedData;
}

/**
 * Ordena un array de objetos en base a otro array de campos.
 *
 * @param {any[]} arr1 - El primer array de objetos a ordenar.
 * @param {string[]} arr2 - El segundo array que contiene los campos en el orden deseado.
 * @returns {any[]} - Un nuevo array ordenado según el orden especificado en arr2.
 */

export const orderTableColumns = (arr1: any[], arr2: string[]): any[] => {
    // nuevo array para el resultado
    const resultado: any[] = [];

    // Iteraramos a través del segundo array
    for (const campo of arr2) {
        // Buscamos el objeto en el primer array con el "campo" que queremos
        const objeto = arr1.find(item => item.field === campo);

        // Si lo encontraos el objeto, lo añadimos al resultado
        if (objeto) {
            resultado.push(objeto);
        }
    }

    // agregamos los objetos restantes alresultado
    for (const item of arr1) {
        if (!resultado.includes(item)) {
            resultado.push(item);
        }
    }

    return resultado;
}

export const columnGroupTitleStyle = (color: string) => {
    return {
        textAlign: "center",
        color: color,
        fontWeight: "bold",
        width: "200px",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}



export const prepareColumnsToUseOptions = (arrayMedidas: any, t, arrayColumns?: any) => {
    const compoundsMeasures: any[] = [];
    const salesMeasures: any[] = [];
    const expensesMeasure: any[] = [];
    const agrupationColumns: any[] = arrayColumns ? arrayColumns : []
    arrayMedidas?.map(medida => {
        if (medida.medidas) {
            compoundsMeasures.push({
                label: medida.nombre,
                value: medida.id
            })
        } else if (!medida.bucket_agrupador) {
            salesMeasures.push({
                label: medida.nombre,
                value: medida.id
            })
        } else {
            expensesMeasure.push({
                label: medida.nombre,
                value: medida.id
            })
        }
    });

    const totalOptions = [
        {
            label: "MEDIDAS GASTOS",
            options: expensesMeasure,
        },
        {
            label: t("simulatorTexts.configSimulationRule.salesMeasures"),
            options: salesMeasures
        },
        {
            label: t("simulatorTexts.configSimulationRule.compundsMeasures"),
            options: compoundsMeasures,
        },
        {
            label: "COLUMNAS AGRUPACIÓN",
            options: agrupationColumns,
        },
    ];
    return (totalOptions);
};

export const isOptionInMedidas = (columnSelected: any, array: any) => array?.map((medida) => {
    return {
        value: medida.id,
        label: medida.nombre
    }
}).some(item => item.label === columnSelected.label && item.value === columnSelected.value);

export const isOptionInOptions = (columnSelected: any, array: any) => array?.map((column) => {
    return {
        value: column.value,
        label: column.label
    }
}).some(item => item.label === columnSelected.label && item.value === columnSelected.value);

export const buscarPorId = (id, array) => array.find(item => item.id === id);

export const buscarPorLabel = (name, array) => array.find(item => item.label === name);

export const transformarMedidas = (columna, array) => {
    return columna.map(id => {
        const itemEncontrado = buscarPorId(id, array);
        if (itemEncontrado) {
            return {
                value: itemEncontrado.id,
                label: itemEncontrado.nombre,
            };
        }
    });
};

export const transformarColumnas = (columna, array) => {
    return columna.map(id => {
        const itemEncontrado = buscarPorLabel(id, array);
        if (itemEncontrado) {
            return {
                value: itemEncontrado.value,
                label: itemEncontrado.label,
            };
        }
    });
};

export const transformarInformations = (denominador, array) => {
    const result: any = []
    denominador.map(denom => {
        if (denom.columna.length > 0) {
            return denom.columna.map(id => {
                const itemEncontrado = buscarPorId(id, array);
                if (itemEncontrado) {
                    result.push({
                        value: itemEncontrado && itemEncontrado.id,
                        label: itemEncontrado && itemEncontrado.informacion,
                    });
                }
            })
        } else {
            result.push({
                value: denom?.nueva_informacion?.informacion,
                label: denom?.nueva_informacion?.informacion
            })
        }
    });
    return result
};

export const PROFIT_LOSS_OPTIONS = [
    {
        value: "s_d",
        label: "S&D"
    },
    {
        value: "d_a",
        label: "D&A",
    },
    {
        value: "g_a",
        label: "G&A"
    }
]

export const getInformationColumn = (array: any, information: string) => {
    const infoEncontrada = array?.find(info => info.informacion === information)
    if (infoEncontrada) {
        return infoEncontrada.columna
    } else {
        return null
    }
}

export const getInformationId = (array: any, columna: string | number) => {
    const infoEncontrada = array?.find(info => info.columna === columna)
    if (infoEncontrada) {
        return infoEncontrada.id
    } else {
        return null
    }
}