import { convertArrayToText } from "../../../taskManager/utils";
import { Item } from "../../../basicGrid/BasicGrid";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import Delete from "../../../icons/Delete";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
interface ObjectiveRowProps {
  rows: any[];
  setRows: any;
  row: any;
  titles: string[];
  deletable?: boolean;
}

const NewColumnRow = ({
  rows,
  setRows,
  row,
  titles,
  deletable = true,
}: ObjectiveRowProps) => {
  const handleDeleteFilter = () => {
    const newRows = rows?.filter((objRow: any) => {
      return !(objRow.nombre === row?.nombre);
    });
    setRows(newRows);
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          {deletable && <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />}
        </Stack>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.nombre}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.funcion?.label || row?.funcion}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.funcion?.value === "VALOR" || row?.funcion === "VALOR"
              ? row?.valor
              : convertArrayToText(
                  row?.columnas?.map((column: any) => {
                    return column?.label;
                  })
                )}
          </Typography>
        </Item>
      </Grid>
      {row?.columnas_secundarias && row?.columnas_secundarias.length > 0 ? (
        <Grid item xs={11 / titles.length}>
          <Item>
            <Typography color={"primary"} sx={{ fontSize: "14px" }}>
              {convertArrayToText(
                row?.columnas_secundarias?.map((column: any) => {
                  return column?.label;
                })
              )}
            </Typography>
          </Item>
        </Grid>
      ) : titles.length === 4 ? (
        <Grid item xs={11 / titles.length}>
          <Item>
            <Typography color={"primary"} sx={{ fontSize: "14px" }}>
              -
            </Typography>
          </Item>
        </Grid>
      ) : null}

      <Grid item xs={0.5}></Grid>
    </>
  );
};

export default NewColumnRow;
