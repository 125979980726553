import { useParameterization } from "../../../../hooks/useParameterization";
import IterationsButtons from "./IterationsButtons";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";

const Iterations = () => {
  const { t } = useLanguage();
  const { parameterizationState } = useParameterization();
  const { selectedStage, tabValue, selectedIteration } = parameterizationState;

  const isGntExpenses =
    selectedStage?.iteraciones &&
    selectedIteration &&
    tabValue !== t("modelParameterizationTexts.step2.tabValueExpenses");

  return (
    <>
      {isGntExpenses && (
        <Stack
          sx={{
            marginRight: "50px",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            padding: "12px 0",
          }}
        >
          <IterationsButtons />
        </Stack>
      )}
    </>
  );
};

export default Iterations;
