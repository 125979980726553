import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../configCriteria/types";
import { selectStyles } from "../../../configCriteria/select.styles";
import { transformDataStep5 } from "./transformDataStep5";
import { UNIT_OPTIONS, stepperData } from "../data";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MessageBlock from "../../../forms/MessageBlock";
import NoCardsError from "../../../error/NoCardsError";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography/Typography";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import VirtualizedTable from "../../../virtualizatedTable/VirtualizedTable";
import {
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";
import { useEffect } from "react";

const Step5 = () => {
  const { t } = useLanguage();
  const { marketBasketElement, setMarketBasketElement, message, setMessage } =
    useMarketBasketConfigProvider();
  const [openInfoModal, handleToggleInfoModal] = useDialog();
  const [openInfoModal2, handleToggleInfoModal2] = useDialog();

  /************************************* Limpieza inicial ********************************/
  useEffect(() => {
    setMessage({
      text: "",
      type: "",
    });
  }, []);

  /**************************************** Filas para tabla ************************************/

  const destinyMetrics = () => {
    let metrics: any[] = [];
    if (
      marketBasketElement?.metricas_destino_primarias &&
      marketBasketElement?.metricas_destino_primarias?.length > 0
    ) {
      metrics = [...metrics, ...marketBasketElement.metricas_destino_primarias];
    }
    if (
      marketBasketElement?.metricas_destino_secundarias &&
      marketBasketElement?.metricas_destino_secundarias?.length > 0
    ) {
      metrics = [
        ...metrics,
        ...marketBasketElement.metricas_destino_secundarias,
      ];
    }
    return metrics;
  };

  const selectedOptionsChangeHandler: any = (
    newValue: SingleValue<IdOption>,
    action: ActionMeta<IdOption>,
    nombre: string,
    col: string
  ) => {
    switch (action.action) {
      case "select-option":
        changeIndicator(newValue, nombre, col);
        break;
      case "clear":
        changeIndicator(undefined, nombre, col);
        break;
      default:
        break;
    }
  };

  const ROWS1 = destinyMetrics().map((item) => {
    const element = marketBasketElement.indicadores_destino.find(
      (indicador) => indicador.nombre === item.nombre
    );
    const elementIndex = marketBasketElement.indicadores_destino.findIndex(
      (indicador) => indicador.nombre === item.nombre
    );

    return {
      id: item.nombre,
      metrica: (
        <Stack
          width="100%"
          justifyContent={"flex-start"}
          height="125px"
          padding="5px 0"
        >
          <FormControlLabel
            key={1}
            control={
              <Checkbox
                onChange={(e) => changeIndicator(e, item.nombre, "indicador")}
              />
            }
            label={
              <span>
                {t("marketBasket.config.generateIndicatorFor")}{" "}
                <b>{item.nombre}</b>
              </span>
            }
            checked={elementIndex === -1 ? false : true}
          />
        </Stack>
      ),
      "unidad de medida":
        elementIndex !== -1 ? (
          <Stack
            width="100%"
            justifyContent={"flex-start"}
            height="125px"
            padding="5px 0"
          >
            <Select
              styles={selectStyles(
                element?.unidad,
                undefined,
                undefined,
                "0px"
              )}
              options={UNIT_OPTIONS(t)}
              onChange={(newValue, action) =>
                selectedOptionsChangeHandler(
                  newValue,
                  action,
                  item.nombre,
                  "unidad"
                )
              }
              closeMenuOnSelect
              placeholder={t("general.chooseColumn")}
              isClearable
              defaultValue={element?.unidad}
              isDisabled={elementIndex === -1}
            />
          </Stack>
        ) : (
          <></>
        ),
      "Drilldown evolución mensual": (
        <Stack
          width="100%"
          justifyContent={"flex-start"}
          height="125px"
          padding="5px 0"
        >
          <FormControlLabel
            key={1}
            control={
              <Checkbox
                onChange={(e) => changeIndicator(e, item.nombre, "drilldown")}
                checked={elementIndex === -1 ? false : element.drilldown}
                disabled={elementIndex === -1}
              />
            }
            label={t("marketBasket.config.generateDrilldownMonthlyEvolution")}
          />
        </Stack>
      ),
    };
  });

  /************************************ Gestión de checkboxes *************************************/

  const changeIndicator = (e: any, name, col) => {
    const newElement = [...marketBasketElement.indicadores_destino];
    const index = newElement.findIndex((item) => item.nombre === name);
    if (col === "indicador") {
      if (index === -1) {
        newElement.push({
          nombre: name,
          unidad: undefined,
          drilldown: false,
        });
      } else {
        newElement.splice(index, 1);
      }
      setMarketBasketElement({
        ...marketBasketElement,
        indicadores_destino: newElement,
      });
    } else if (col === "unidad") {
      newElement[index].unidad = e;
      setMarketBasketElement({
        ...marketBasketElement,
        indicadores_destino: newElement,
      });
    } else {
      newElement[index].drilldown = e.target.checked;
      setMarketBasketElement({
        ...marketBasketElement,
        indicadores_destino: newElement,
      });
    }
  };

  return (
    <Stack sx={{ ...param_steps_container, gap: 2, minHeight: "57vh" }}>
      <Typography sx={step_title_styles}>{stepperData(t).steps[4]}</Typography>
      <MessageBlock message={message} />
      <Stack minWidth="80%" justifyContent={"center"}>
        {ROWS1?.length > 0 ? (
          <VirtualizedTable
            columns={
              transformDataStep5(
                ROWS1,
                openInfoModal,
                handleToggleInfoModal,
                openInfoModal2,
                handleToggleInfoModal2,
                undefined,
                t,
                260
              ).columns
            }
            rows={
              transformDataStep5(
                ROWS1,
                openInfoModal,
                handleToggleInfoModal,
                openInfoModal2,
                handleToggleInfoModal2,
                undefined,
                t,
                260
              ).rows
            }
            rowsPerPageOptions={[5, 10, 20]}
            rowHeight={125}
            tableHeight={`${45 + ROWS1.length * 125}px`}
            hideFooter={true}
            toolbar={false}
            columnsButton={false}
            filterButton={false}
            exportButton={false}
            checkboxSelection={false}
            setSelectedRows={() => <></>}
            headerHeight={30}
          />
        ) : (
          <NoCardsError height={undefined} />
        )}
      </Stack>
    </Stack>
  );
};

export default Step5;
