import { convertArrayToTextWithoutComas } from "../../../taskManager/utils";
import { form_subtitle_styles } from "../../../../styles/app-styles";
import { isElementInUse } from "../utils";
import { transformDataSimpleTable } from "../../../tablesTools/transformDataSimpleTable";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import { useSnackbar, VariantType } from "notistack";
import AddButton from "../../../buttons/AddButton";
import Box from "@mui/material/Box";
import CalculatorModal from "../Shared/CalculatorModal";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "../../../icons/Delete";
import Dialog from "../../../dialog/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import VirtualizedTable from "../../../virtualizatedTable/VirtualizedTable";

const Step2Calculation = () => {
  const { t } = useLanguage();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    formulaSKU,
    setFormulaSKU,
    marketBasketElement,
    setMarketBasketElement,
  } = useMarketBasketConfigProvider();
  const [openCalculatorModal, toggleCalculatorModal] = useDialog();

  const indicators = () => {
    const ind: any[] = [];
    if (
      marketBasketElement?.metricas_SKU_primarias &&
      marketBasketElement?.metricas_SKU_primarias.length > 0
    ) {
      marketBasketElement.metricas_SKU_primarias.forEach((metrica) => {
        ind.push({
          ...metrica,
          tipo: "INDICADOR_PRIMARIO",
        });
      });
    }
    if (
      marketBasketElement?.metricas_SKU_secundarias &&
      marketBasketElement?.metricas_SKU_secundarias.length > 0
    ) {
      marketBasketElement.metricas_SKU_secundarias.forEach((metrica) => {
        ind.push({
          ...metrica,
          tipo: "INDICADOR_SECUNDARIO",
        });
      });
    }
    return ind;
  };

  const handleApply = (formulaName, formula) => {
    setMarketBasketElement((prev) => {
      return {
        ...prev,
        metricas_SKU_secundarias: [
          ...prev.metricas_SKU_secundarias,
          {
            nombre: formulaName,
            formula: formula,
          },
        ],
      };
    });
    toggleCalculatorModal();
    setFormulaSKU([]);
  };

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const deleteRow = (row) => {
    if (isElementInUse(row, marketBasketElement?.metricas_SKU_secundarias)) {
      handleClickVariant(
        t('general.usedElement'),
        "error"
      );
    } else {
      setMarketBasketElement((prev) => {
        return {
          ...prev,
          metricas_SKU_secundarias: prev.metricas_SKU_secundarias.filter(
            (metrica) => metrica.nombre !== row.nombre
          ),
        };
      });
    }
  };

  const ROWS1 =
    marketBasketElement?.metricas_SKU_secundarias &&
    marketBasketElement?.metricas_SKU_secundarias.length
      ? marketBasketElement?.metricas_SKU_secundarias?.map((metrica) => {
          return {
            id: metrica.nombre,
            Metrica: (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
                gap={2}
              >
                {metrica.nombre}
                <Box>
                  <Delete onClick={() => deleteRow(metrica)} />
                </Box>
              </Stack>
            ),
            Formula: convertArrayToTextWithoutComas(
              metrica.formula.map((element) => element.dato)
            ),
          };
        })
      : [];

  return (
    <Stack width="100%" alignItems={"center"}>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant="h2" sx={form_subtitle_styles}>
          {t('marketBasket.config.secondaryMetricsAtSkuLevel')}
        </Typography>
        {<AddButton onClick={toggleCalculatorModal} />}
      </Stack>
      <Stack width="90%">
        {marketBasketElement?.metricas_SKU_secundarias &&
          marketBasketElement?.metricas_SKU_secundarias?.length > 0 &&
          ROWS1?.length && (
            <VirtualizedTable
              columns={
                transformDataSimpleTable(ROWS1, undefined, undefined, t, 260)
                  .columns
              }
              rows={
                transformDataSimpleTable(ROWS1, undefined, undefined, t, 260)
                  .rows
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={30}
              tableHeight={`${
                45 + marketBasketElement?.metricas_SKU_secundarias?.length * 30
              }px`}
              hideFooter={true}
              toolbar={false}
              columnsButton={false}
              filterButton={false}
              exportButton={false}
              checkboxSelection={false}
              setSelectedRows={() => <></>}
              headerHeight={30}
            />
          )}
      </Stack>

      {openCalculatorModal && (
        <Dialog
          open={openCalculatorModal}
          handleClose={toggleCalculatorModal}
          title={t('marketBasket.config.secondaryMetricsCalculation')}
          maxWidth={"lg"}
        >
          <Stack sx={{ minHeight: "600px" }}>
            <CalculatorModal
              toggleCalculatorModal={toggleCalculatorModal}
              formula={formulaSKU}
              setFormula={setFormulaSKU}
              elements={indicators()}
              handleApply={handleApply}
            />
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};

export default Step2Calculation;
