import { AddButton } from "../../../buttons";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useRef, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import OtherFilesSingleSelect from "./OtherFilesSingleSelect";
import TextField from "@mui/material/TextField";
import useLanguage from "../../../../context/LanguageProvider";

const OtherFilesInput = ({}) => {
  const { t } = useLanguage();
  const columnSelectRef: any = useRef(null);
  const { message, setFileColumns } =
    useCompanyParamProvider();
  const fileNameRef: any = useRef(null);
  const [newName, setNewName] = useState<string>("");
  const [columnSelectedOption, setColumnSelectedOption] = useState<
    { value: string; label: string } | undefined
  >(undefined);

  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
  };

  const handleAddFile = () => {
    setFileColumns((prev: any) => [
      ...prev,
      {
        nombre: newName,
        descripcion: columnSelectedOption,
        //importacion: false
      },
    ]);
    setNewName("");
    setColumnSelectedOption(undefined);
    fileNameRef.current.value = "";
    columnSelectRef.current.clearValue();
  };

  return (
    <Grid2 container direction={"row"} xs={12} mt={2} mb={2} gap={2}>
      <Grid2 xs={0.3}></Grid2>
      <Grid2 xs={5.3}>
        <TextField
          type="text"
          ref={fileNameRef}
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={t("general.name")}
          autoFocus
          size="small"
          error={message.text !== ""}
          helperText={message.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
      </Grid2>
      <Grid2 xs={5}>
        <OtherFilesSingleSelect
          columnSelectedOption={columnSelectedOption}
          setColumnSelectedOption={setColumnSelectedOption}
          columnSelectRef={columnSelectRef}
        />
      </Grid2>
      <Grid2 xs={0.5}>
        <AddButton
          onClick={() => handleAddFile()}
          disabled={
            newName === "" ||
            newName === undefined ||
            columnSelectedOption === undefined
          }
        />
      </Grid2>
    </Grid2>
  );
};

export default OtherFilesInput;
