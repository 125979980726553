import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import React from "react";

const CardDetailItem: React.FC<{
  title: string;
  value: string;
  icon?: JSX.Element;
}> = ({ title, value, icon }) => (
  <Stack>
    <Typography
      sx={{
        textAlign: "left",
        textTransform: "uppercase",
        fontWeight: "700",
        letterSpacing: "-0.5px",
        fontSize: "13px",
      }}
      color="var(--text-main)"
    >
      {title}
    </Typography>
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.6}
      sx={{ color: "var(--icon-info)" }}
    >
      {icon}
      <Typography
        sx={{
          textAlign: "left",
          overflow: "hidden",
          alignItems: "center",
          display: "flex",
          paddingTop: "1px",
          fontSize: "13px",
          lineHeight: 1,
        }}
        color="var(--text-info)"
      >
        {value}
      </Typography>
    </Stack>
  </Stack>
);

export default CardDetailItem;
