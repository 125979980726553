import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import { LOGOUT_URL } from "../api/axios";
import useAxiosPrivate from "./useAxiosPrivate";
import { useState } from "react";
import useSnackbar from "./useSnackbar";
import useInfoContext from "./useInfoContext";
import useLanguage from "../context/LanguageProvider";

/**
 * @name useLogout
 * @description Desloguea al usuario de la aplicación, borrando los datos del estado y haciendo una llamada a la API para borrar las cookies de autenticación.
 * @returns {function}
 * @category Hooks
 */

const useLogout: () => [
  () => Promise<void>,
  boolean,
  boolean,
  () => void,
  string,
  "success" | "info" | "warning" | "error"
] = () => {
  const { t } = useLanguage();
  const { setAuth }: any = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);
  const [
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    setMessageSnackbar,
    severity,
    setSeverity,
    showSnackbar,
  ] = useSnackbar();
  const { setLoggedIn }: any = useInfoContext();
  
  const logout = async () => { 
    try {
      setIsLoadingLogout(true);
      const response = await axiosPrivate.post(LOGOUT_URL);
      if (response.status === 200) {
        window.localStorage.removeItem("selectedBucket");
        window.localStorage.removeItem("selectedStage");
        window.localStorage.removeItem("pendingTasks");
        setLoggedIn(false)
        setIsLoadingLogout(false);      
        setAuth({});
        navigate("/login/");
      } else {
        setIsLoadingLogout(false);
        setMessageSnackbar(t("useLogout.errorLogout"));
        setSeverity("error");
        showSnackbar();
      }
    } catch (error) {
      setIsLoadingLogout(false);
      setMessageSnackbar(t(t("useLogout.errorLogout")));
      setSeverity("error");
      showSnackbar();
    }
  };

  return [
    logout,
    isLoadingLogout,
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    severity,
  ];
};

export default useLogout;
