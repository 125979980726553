import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

type ErrorChartCardProps = {
 chart: any
}

const ErrorChartCard = ({chart}: ErrorChartCardProps) => {

  const [minimized, setMinimized] = useState(true);

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "var(--bg-light)",
        transition: "all 0.9s",
        height: !minimized ? "220px" : "0px",
        alignItems: "center",
        padding: "10px 0px 40px 0px",
        marginBottom: "15px",
        boxShadow: "0px 18px 10px -10px rgb(0 0 0 / 30%)",
        borderRadius: "10px",
      }}
      key={chart.titulo_grafico}
    >
        <Stack sx={{flexDirection:"row"}}>
        <CancelIcon
            sx={{ color: "var(--red)", fontSize: "26px", marginRight: "8px" }}
          />
      <Typography  variant="subtitle1"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "var(--red)",
            marginBottom:"10px"
          }}>{chart.titulo_grafico}</Typography>
         { minimized ? (
            <ExpandMoreIcon
              sx={{
                color: "var(--red)",
                fontSize: "28px",
                marginLeft: "2px",
                cursor: "pointer",
                marginTop:"3px"
              }}
              onClick={() => setMinimized(false)}
            />
          ) : (
            <ExpandLessIcon
              sx={{
                color: "var(--red)",
                fontSize: "28px",
                marginLeft: "2px",
                cursor: "pointer",
                marginTop:"3px"
              }}
              onClick={() => setMinimized(true)}
            />
          )}
          </Stack>
      <Stack sx={{ width: "95%", backgroundColor:"white", height:"100%", borderRadius:"10px", overflowY: !minimized ? "scroll" : "hidden", alignItems:"center"}}>
      {!minimized &&
          chart.lista_periodo_error?.length &&
          chart.lista_periodo_error.map((periodo: any) => (
            <Typography
            key={periodo.periodo}
              variant="subtitle1"
              sx={{
                textAlign: "center",
                transition: "all 2s",
                width:"85%",
                marginTop:"10px"
              }}
              color="var(--red)"
            >
              {periodo.periodo+": "+periodo.motivo}
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default ErrorChartCard;
