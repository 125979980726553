import { getIcon } from "../utils";
import { renderTables, titleByType } from "./ViewInfoFunctions";
import Box from "@mui/material/Box";
import Dialog from "../../dialog/Dialog";
import EditCriteriaButton from "../../buttons/EditCriteriaButton";
import SharedElements from "./SharedElements";
import SharedIndicatorsAndCharts from "./SharedIndicatorsAndCharts";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import {
  info_modal_container_styles,
  info_modal_infobox_styles,
  info_modal_subtitle_key_styles,
  info_modal_subtitle_styles,
  info_modal_subtitle_value_styles2,
  info_modal_subtitle_value_styles3,
  info_section_container_styles,
  view_info_modal_title,
  view_info_modal_value,
} from "../../../styles/app-styles";

interface ViewInfoProps {
  indicator: any;
  open: boolean;
  handleEdit: (e: any, id: number) => void;
  handleClose: () => void;
  title: string | undefined;
}

const ViewInfoModal2 = ({
  indicator,
  open,
  handleClose,
  handleEdit,
  title,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();

  const renderByType = (type) => {
    if (!type) {
      //caso indicador
      return (
        <>
          <Box sx={info_modal_subtitle_styles}>
            <Typography sx={info_modal_subtitle_key_styles}>
              {t("indicatorsManagementTexts.viewInfoModal.unitOfMeasurement")}
            </Typography>
            <Typography sx={info_modal_subtitle_value_styles2}>
              {indicator?.unidad_medida}
            </Typography>
          </Box>
          <SharedElements indicator={indicator} />
          <SharedIndicatorsAndCharts indicator={indicator} />
        </>
      );
    } else {
      switch (type) {
        case "TABLA": //caso tabla
          return <SharedElements indicator={indicator} />;
        default: //caso gráficos
          return (
            <>
              <Stack sx={info_modal_infobox_styles}>
                <Box sx={info_modal_subtitle_key_styles}>
                  {t("indicatorsManagementTexts.viewInfoModal.graphType")}:{" "}
                </Box>
                {getIcon(indicator.tipo)}
                <Typography sx={view_info_modal_value}>
                  {indicator.tipo}
                </Typography>
              </Stack>
              <SharedElements indicator={indicator} />
              <SharedIndicatorsAndCharts indicator={indicator} />
              <>
                {(indicator.tipo === "LINEAS" ||
                  indicator.tipo === "BARRAS CON DRILLDOWN" ||
                  indicator.tipo === "TREEMAP CON DRILLDOWN") && (
                  <>
                    <Box sx={info_modal_subtitle_styles}>
                      <Box sx={info_modal_subtitle_key_styles}>
                        {indicator.tipo === "TREEMAP CON DRILLDOWN"
                          ? "Columna categórica"
                          : t("indicatorsManagementTexts.viewInfoModal.axisX")}
                        :{" "}
                      </Box>
                      <Box sx={info_modal_subtitle_value_styles3}>
                        {indicator?.columna_eje_x?.label}
                      </Box>
                    </Box>
                    <Box sx={info_modal_subtitle_styles}>
                      <Box sx={info_modal_subtitle_key_styles}>
                        {indicator.tipo === "TREEMAP CON DRILLDOWN"
                          ? "Nombre columna categórica"
                          : t(
                              "indicatorsManagementTexts.viewInfoModal.axisXName"
                            )}
                        :
                      </Box>
                      <Box sx={info_modal_subtitle_value_styles3}>
                        {indicator?.nombre_eje_x}
                      </Box>
                    </Box>
                  </>
                )}
                {indicator?.columnas_drilldown &&
                  indicator?.columnas_drilldown?.length !== 0 && (
                    <Box sx={info_modal_subtitle_styles}>
                      <Box sx={info_modal_subtitle_key_styles}>
                        {t(
                          "indicatorsManagementTexts.viewInfoModal.drilldownColumn"
                        )}
                        :
                      </Box>
                      <Box sx={info_modal_subtitle_value_styles3}>
                        <ol style={{ margin: 0 }}>
                          {indicator?.columnas_drilldown.map((col, index) => (
                            <li key={index}>{col.label}</li>
                          ))}
                        </ol>
                      </Box>
                    </Box>
                  )}
                {indicator.columna_series && (
                  <Box sx={info_modal_subtitle_styles}>
                    <Box sx={info_modal_subtitle_key_styles}>
                      {t(
                        "indicatorsManagementTexts.viewInfoModal.serialColumns"
                      )}
                      :
                    </Box>
                    <Box sx={info_modal_subtitle_value_styles3}>
                      {indicator?.columna_series.label}
                    </Box>
                  </Box>
                )}
              </>
            </>
          );
      }
    }
  };

  const handleEditAndClose = (e: any, id: number) => {
    handleEdit(e, id);
    handleClose();
  };

  return (
    <Dialog open={open} handleClose={handleClose} title={title}>
      <Stack sx={info_modal_container_styles}>
        <Stack sx={info_section_container_styles}>
          <Typography sx={view_info_modal_title}>
            {titleByType(t, indicator?.tipo)}
          </Typography>
          {renderByType(indicator?.tipo)}
          {renderTables(indicator, t)}
        </Stack>
        {userRolData &&
          userRolData.administracion_de_dashboard.visualizacion_y_edicion && (
            <EditCriteriaButton
              handleClick={handleEditAndClose}
              selectedCriteria={indicator}
            />
          )}
      </Stack>
    </Dialog>
  );
};

export default ViewInfoModal2;
