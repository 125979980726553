import { checkRepeatedName, handleSetElementInLast } from "../utils";
import { InputLabel } from "@mui/material";
import { useEffect } from "react";
import { useMemo, useState } from "react";
import Button from "../../../buttons/Button";
import InfoIcon from "@mui/icons-material/Info";
import OperatorsCalculator from "../../../calculator/OperatorsCalculator";
import SelectCard from "./SelectCard";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  handleAddOperatorToFormula,
  handleKeyDownFormula,
  handleValidateFormula,
  operatorsCalculator,
} from "../../../calculator/utils";

const CalculatorModal = ({
  toggleCalculatorModal,
  formula,
  setFormula,
  elements,
  handleApply
}) => {
  const { t } = useLanguage();
  const [message, setMessage] = useState<{ text: string; type: string }>({
    text: "",
    type: "",
  });
  const [operatorInLast, setOperatorInLast] = useState<boolean>(false);
  const [medidaInLast, setMedidaInLast] = useState<boolean>(false);
  const [dotOperator, setDotOperator] = useState<boolean>(false);
  const [formulaName, setFormulaName] = useState<string>("");

  const handleAddElementToFormula = (
    id: number,
    name: string,
    tipo: string
  ) => {
    setFormula((prevData) => {
      const newMeasure = {
        tipo: tipo,
        dato: id,
        orden: prevData.length,
      };
      return [...prevData, newMeasure];
    });
  };

  // Función para armar la formula en base al objeto
  const formulaToString = useMemo(() => {
    let namesString = "";
    formula &&
      formula.length &&
      formula?.forEach((element) => {
        if (
          element.tipo === "INDICADOR_PRIMARIO" ||
          element.tipo === "INDICADOR_SECUNDARIO"
        ) {
          namesString += `'${element.dato.toUpperCase()}'`;
        } else {
          namesString += element.dato;
        }
      });
    return namesString;
  }, [formula]);

  /*************************** VALIDACIÓN DE FÓRMULA ***********************************/

  useEffect(() => {
    handleSetElementInLast(
      formula,
      setMedidaInLast,
      setDotOperator,
      setOperatorInLast
    );
  }, [formula]);

  const handleCancel = () => {
    toggleCalculatorModal();
    setFormula([]);
  };

  const handleSetName = (name: string) => {
    setFormulaName(name);
    checkRepeatedName(t, elements, name, setMessage);
  }  

  return (  
      <Stack
        component="form"
        noValidate
        autoComplete="off"
        mb="15px"
        gap="20px"
      >
        <TextField
          sx={{ wordWrap: "break-word" }}
          type="text"
          label={t("general.name")}
          variant="outlined"
          fullWidth
          value={formulaName}
          onChange={(e) => handleSetName(e.target.value)}
          error={message.text !== "" || formulaName === ""}
          helperText={message.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
        <Stack>
          <Stack sx={{ flexDirection: "row", gap: "5px", height: "25px" }}>
            <InputLabel
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                fontSize: "var(--text)",
              }}
            >
              {t(
                "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.formula"
              )}
            </InputLabel>
            <Tooltip
              title="Solo se admite: 0-9, +, -, /, *, %, (, ) y . "
              placement="right"
            >
              <InfoIcon color="primary" fontSize="medium" sx={{ pb: "2px" }} />
            </Tooltip>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "var(--text-error)",
                textAlign: "center",
              }}
            >
              {message.text}
            </Typography>
          </Stack>
          <TextField
            type="text"
            placeholder={t(
              "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.enterFormula"
            )}
            variant="outlined"
            fullWidth
            value={formulaToString}
            error={handleValidateFormula(formula, formulaToString)}
            disabled={false}
            onKeyDown={(e) => handleKeyDownFormula(e, formula, setFormula)}
          />
        </Stack>

        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            color: "var(--text-main)",
          }}
        >
          <OperatorsCalculator
            operators={operatorsCalculator}
            handleAddOperatorToFormula={handleAddOperatorToFormula}
            operatorInLast={operatorInLast}
            formula={formula}
            medidaInLast={medidaInLast}
            dotOperator={dotOperator}
            setFormula={setFormula}
          />
          <Stack sx={{ width: "50%", gap: 3, flexDirection: "row" }}>
            <SelectCard
              handleAddElementToFormula={handleAddElementToFormula}
              title={t('marketBasket.config.indicators')}
              elements={elements}
              medidaInLast={medidaInLast || dotOperator}
              formula={formula}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <Button
            title={t("general.cancel")}
            color="grey"
            type="button"
            onClick={handleCancel}
          />
          <Button
            title={t("general.apply")}
            color="blue-greeny"
            type="button"
            onClick={() => handleApply(formulaName, formula)}
            disabled={
              message.text !== "" ||
              handleValidateFormula(formula, formulaToString)
              || formulaName === ""
            }
          />
        </Stack>
      </Stack> 
  );
};

export default CalculatorModal;
