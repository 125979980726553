import { form_subtitle_styles } from "../../../../styles/app-styles";
import { MB_RESULTADOS_URL } from "../../../../api/axios";
import { Stack, Typography } from "@mui/material";
import { transformColumnNameMarketBasket } from "../utils";
import { transformDataMarketBasketTable } from "./transformDataMarketBasketTable";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import { useState } from "react";
import CustomButton from "../../../buttons/Button";
import CustomSkeleton from "../../../skeleton/CustomSkeleton";
import Dialog from "../../../dialog/Dialog";
import SimulationModal from "./SimulationModal";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import VirtualizatedTable from "../../../virtualizatedTable/VirtualizedTable";
import useApi from "../../../../hooks/useApi";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

const TableSection = () => {
  const { t } = useLanguage();
  const {
    mbAnalysisResults,
    isLoadingMbAnalysisResults,
    selectedRows,
    setSelectedRows,
    tabValue,
    setMbUpdatedResults,
    filters,
    simulationValues,
    setCounter,
  } = useMarketBasketProvider();
  const [openDetailModal, handleToggleDetailModal] = useDialog();
  const [openSimulationModal, toggleSimulationModal] = useDialog();
  const [detailInfo, setDetailInfo] = useState<any>({});
  const [sku, setSKU] = useState<any>("");

  const onClickSKU = (row: any) => {
    setSKU(row);
    setDetailInfo(mbAnalysisResults.tabla_modal[row]);
    handleToggleDetailModal();
  };

  const onSuccessgetMbAnalysisUpdatedResults = (data) => {
    setMbUpdatedResults({
      ...mbAnalysisResults,
      treemap: data.treemap,
      barras: data.barras,
    });
    setCounter((prev) => prev + 1);
  };

  const {
    data: mbAnalysisUpdatedResults,
    isLoading: isLoadingMbAnalysisUpdatedResults,
    callApi: getMbAnalysisUpdatedResults,
  } = useApi(
    MB_RESULTADOS_URL(tabValue),
    "POST",
    undefined,
    { filtro: filters, ajuste: simulationValues },
    onSuccessgetMbAnalysisUpdatedResults,
    undefined,
    false
  );

  const handleAcceptSimulation = () => {
    getMbAnalysisUpdatedResults();
    toggleSimulationModal();
  };

  const deleteSimulation = () => {
    getMbAnalysisUpdatedResults(undefined, { filtro: filters, ajuste: [] });
  };

  const selectedRowsInfo = selectedRows.map((row: any) =>
    mbAnalysisResults?.tabla.find((item: any) => item.id === row)
  );

  return (
    <Stack gap={1} alignItems={"center"}>
      <SimpleBackdrop
        open={isLoadingMbAnalysisUpdatedResults}
        message={t("general.loading")}
      />

      {isLoadingMbAnalysisResults && <CustomSkeleton height={6} />}
      {!isLoadingMbAnalysisResults &&
        mbAnalysisResults &&
        mbAnalysisResults.tabla && (
          <>
            <Typography sx={{ ...form_subtitle_styles, textAlign: "center" }}>
              {t("marketBasket.view.scenariosSimulation")}
            </Typography>
            <Stack sx={{ width: "100%", color: "var(--text-main)", gap: 1 }}>
              {t("marketBasket.view.simulationDescription")}
            </Stack>
            <Stack
              direction="row"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
            >
              <CustomButton
                title={t("marketBasket.view.simulateScenario")}
                color="blue"
                type="button"
                disabled={
                  tabValue && tabValue !== "SIN ANALISIS"
                    ? selectedRows?.length > 0
                      ? false
                      : true
                    : true
                }
                onClick={() => toggleSimulationModal()}
                margin={false}
              />
              <CustomButton
                title={t("marketBasket.view.deleteSimulation")}
                color="magenta"
                type="button"
                onClick={() => deleteSimulation()}
                disabled={
                  !simulationValues || simulationValues.length === 0
                    ? true
                    : false
                }
                margin={false}
              />
            </Stack>
            <VirtualizatedTable
              columns={
                transformDataMarketBasketTable(
                  t,
                  mbAnalysisResults.tabla,
                  onClickSKU,
                  transformColumnNameMarketBasket
                ).columns
              }
              rows={
                transformDataMarketBasketTable(
                  t,
                  mbAnalysisResults.tabla,
                  onClickSKU,
                  transformColumnNameMarketBasket
                ).rows
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={35}
              tableHeight={"75vh"}
              hideFooter={true}
              toolbar={true}
              columnsButton={true}
              filterButton={true}
              exportButton={true}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
            />
          </>
        )}
      <Dialog
        open={openDetailModal}
        handleClose={handleToggleDetailModal}
        title={`${t("marketBasket.view.oportunityForSku")} ${
          sku ? t("marketBasket.view.forSku") + sku : ""
        }`}
        maxWidth={"lg"}
      >
        <Stack sx={{ minWidth: "70vw" }}>
          {detailInfo && (
            <VirtualizatedTable
              columns={
                transformDataMarketBasketTable(
                  t,
                  detailInfo,
                  undefined,
                  transformColumnNameMarketBasket
                ).columns
              }
              rows={
                transformDataMarketBasketTable(
                  t,
                  detailInfo,
                  undefined,
                  transformColumnNameMarketBasket
                ).rows
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={35}
              tableHeight={"70vh"}
              hideFooter={true}
              toolbar={true}
              columnsButton={true}
              filterButton={true}
              exportButton={true}
              checkboxSelection={false}
              setSelectedRows={() => <></>}
            />
          )}
        </Stack>
      </Dialog>
      <SimulationModal
        openSimulationModal={openSimulationModal}
        toggleSimulationModal={toggleSimulationModal}
        data={selectedRowsInfo}
        handleAccept={handleAcceptSimulation}
        handleCancel={toggleSimulationModal}
      />
    </Stack>
  );
};

export default TableSection;
