import { createTheme } from "@mui/material";
import { esES } from "@mui/x-data-grid";

export const theme = createTheme(
  {
    typography: {
      fontFamily: "Roboto, sans-serif", 
    },
    palette: {
      primary: {
        main: "#172D40",  // var(--main-blue)
        light: "#e0ebf4", //var(--very-light-blue)
        dark: "#b4b4c7", //var(--light-violet)
      },
      secondary: {
        main: "#34d4bf", // var(--main-green)
      },
      error: {
        main: "#f90d4a", // var(--main-magenta)
      },
      warning: {
        main: "#FF9800", // var(--main-orange)
      },
      info: {
        main: "#9096ac", // var(--light-bluish-violet)
      },
      success: {
        main: "#23a392", //var(--dark-green)
      },
    },
  },

  esES
);
