import { convertNumberToMoney } from "../../../parameterization/utils";
import { convertUnit } from "../../../report/utils";
import { memo, useRef, useState} from "react";
import { Stack } from "@mui/material";
import accessibility from "highcharts/modules/accessibility";
import drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import useLanguage from "../../../../context/LanguageProvider";
import useSwitch from "../../../../hooks/useSwitch";

drilldown(Highcharts);
accessibility(Highcharts);
interface Props {
  results: any;
  tooltip?: boolean;
  stacking?: boolean;
  tooltipFormatter?: any;
}
const BarDrilldownChart = memo((props: Props) => {
  const { t } = useLanguage();
  const { results, tooltip, tooltipFormatter } = props;
  const chartRef: any = useRef(null);
  const [stacking, handleChangeStacking] = useSwitch(false);
  const [isDrilldown, setIsDrilldown] = useState(false);

  const options = {
    chart: {
      type: "column",
      events: {
        drillup: function () {
          const chart: any = this;
          setTimeout(function () {
            if (chart.series[0].options._levelNumber === 0) {
              setIsDrilldown(false);
            }
          }, 200);
        },
        drilldown: function () {
          const chart: any = this;
          setTimeout(function () {
            if (chart.series[0].options._levelNumber) {
              setIsDrilldown(true);
            }
          }, 200);
        },
      },
      style: {
        width: "100%",
      },
      zooming: {
        type: "xy",
      },
    },
    ref: chartRef,
    plotOptions: {
      series: {
        stacking: stacking ? "normal" : false,
        dataLabels: {
          enabled: false,
        },
      },
    },
    title: false,
    colors: [
      "var(--chart-1)",
      "var(--chart-2)",
      "var(--chart-3)",
      "var(--chart-4)",
      "var(--chart-5)",
      "var(--chart-6)",
      "var(--chart-7)",
      "var(--chart-8)",
      "var(--chart-9)",
      "var(--chart-10)",
      "var(--chart-11)",
      "var(--chart-12)",
      "var(--chart-13)",
      "var(--chart-14)",
      "var(--chart-15)",
      "var(--chart-16)",
      "var(--chart-17)",
      "var(--chart-18)",
      "var(--chart-19)",
      "var(--chart-20)",
    ],
    xAxis: {
      type: "category",
      title: {
        text: results?.nombre_eje_x,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
    },
    yAxis: {
      title: {
        text: results?.nombre_eje_y,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: results?.series,
    drilldown: results?.drilldown ? results?.drilldown : {},
    accessibility: {
      enabled: true,
    },
    tooltip: {
      y: 0,
      series: {
        name: "",
      },
      point: {
        porcentaje: 0,
      },
      useHTML: tooltip,
      formatter: function () {
        return tooltip
          ? `<div>  <p>${
              this.point.porcentaje
                ? `<span> ${t(
                    "charts.barInfiniteDrilldownChart.originalExpensePercent"
                  )}: ${convertUnit(
                    "porcentaje",
                    this.point.porcentaje
                  )} </span>`
                : "<span></span>"
            }</p> <b>${this.series.name}: ${convertUnit(
              "moneda",
              this.y
            )}</b> <span> (${convertNumberToMoney(this.y)})</span> </div>`
          : `<p>${this.y.toFixed(2)}</p> `;
      },
    },
  };

  return (
    <Stack sx={{ width: "100%", margin: "0px !important" }}>
      <Stack
        sx={{ alignSelf: "flex-end", height: "35px", margin: "0px !important" }}
      ></Stack>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
});

export default BarDrilldownChart;
