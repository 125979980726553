import {
  DataGridPro,
  useGridApiRef,
  GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { getTableTexts } from "../tablesTools/utils";
import { useEffect } from "react";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import CustomToolbar from "../customToolbar/CustomToolbar";
import useLanguage from "../../context/LanguageProvider";
interface Props {
  columns: any;
  rows: any[];
  rowsPerPageOptions?: number[];
  rowHeight: number;
  tableHeight: number | string;
  hideFooter?: boolean;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  setSelectedRows?: (prev: any) => void;
  checkboxSelection?: boolean;
  selectedRows?: any;
  headerHeight?: number;
  setEditData?: (prev: any) => void;
}

const EditableTable = ({
  columns,
  rows,
  rowsPerPageOptions,
  rowHeight,
  tableHeight,
  hideFooter,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  setSelectedRows,
  checkboxSelection,
  selectedRows,
  headerHeight,
  setEditData,
}: Props) => {
  const { t } = useLanguage();

  const apiRef = useGridApiRef();

  useEffect(() => {
    apiRef.current.subscribeEvent(
      "selectionChange",
      (params: GridSelectionModel) => {
        setSelectedRows && setSelectedRows(params);
      }
    );
    apiRef.current.subscribeEvent(
      "cellEditStop",
      (params: any, a, details: any) => {
        const rows: any =
          details.api.state.rows.groupingResponseBeforeRowHydration
            .idRowsLookup;   
        const orderedRows = Object.values(rows).sort(
          (a: any, b: any) => a.id - b.id
        );        
        setEditData && setEditData(orderedRows);
      }
    );
  }, [apiRef]);

  return (
    <div style={{ height: tableHeight, width: "100%" }}>
      <DataGridPro
        localeText={getTableTexts(t)}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={rowsPerPageOptions}
        rowHeight={rowHeight}
        components={{
          Toolbar: () => (
            <CustomToolbar
              columnsButton={columnsButton}
              toolbar={toolbar}
              filterButton={filterButton}
              densitySelector={densitySelector}
              exportButton={exportButton}
            />
          ),
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{
          panel: {
            sx: {
              [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                display: "none",
              },
            },
          },
        }}
        disableColumnMenu={true}
        hideFooter={hideFooter}
        checkboxSelection={checkboxSelection === false ? false : true}
        disableSelectionOnClick
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "var(--bg-secondary)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}
        columnBuffer={columns.length}
        columnThreshold={5}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "es_whiteRow"
            : "es_colorRow"
        }
        selectionModel={selectedRows}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedRows && setSelectedRows(newSelectionModel);
        }}
        headerHeight={headerHeight ? headerHeight : 50}
      />
    </div>
  );
};

export default EditableTable;
