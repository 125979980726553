export const dataGetChartFunctions = [
  "SUMA",
  "MAXIMO",
  "MINIMO",
  "PROMEDIO",
  "CONTAR",
  "CONTAR.UNICO",
];

export const dataGetChartFunctionsWithDivision = [
  "SUMA",
  "MAXIMO",
  "MINIMO",
  "PROMEDIO",
  "CONTAR",
  "CONTAR.UNICO",
  "DIVISION"
];

export const waterfallFunctionOptions = (t) => [
  {
    value: "positivo",
    label: t("dashboard.waterfall.positive"),
  },
  {
    value: "negativo",
    label: t("dashboard.waterfall.negative"),
  },
  {
    value: "suma_intermedia",
    label: t("dashboard.waterfall.intermediateSum"),
  },
  {
    value: "suma_total",
    label: t("dashboard.waterfall.totalSum"),
  },
];

export const dataGetCharts = [
  {
    id: 1,
    nombre: "Gastos vs. Períodos",
    tipo: "LINEAS",
    archivo: {
      value: 5,
      label: "GASTOS",
    },
    columna_eje_y: {
      value: 14,
      label: "CANAL",
    },
    funcion: "SUMA",
    nombre_eje_y: "suma de gastos",
    columna_eje_x: {
      value: 14,
      label: "períodos",
    },
    nombre_eje_x: "períodos",
    columnas_drilldown: [],
    columna_base: undefined,
    columna_series: {
      value: 32,
      label: "VENTA_BRUTA",
    },
    activo: true,
    columna_grafico_unico: [
      {
        value: 148,
        label: "DESCRIPCION_CLIENTE",
      },
    ],
    grafico_archivo_columna_filtra: [
      {
        columna_id: 23,
        columna: "COD_UNICO",
        excluir: false,
        externo: true,
        grafico_archivo_valor_filtra: [
          {
            valor: "asd",
          },
        ],
      },
    ],
    grafico_archivo_columna_valida: [
      {
        grafico_columna_gastos_o_externo_id: 1,
        grafico_columna_gastos_o_externo: "Operacion",
        grafico_columna_archivo_base_id: 23,
        grafico_columna_archivo_base: "COD_UNICO",
        externo: true,
      },
    ],
    archivo_externo_validacion: {
      value: 7,
      label: "GASTOS",
    },
  },
  {
    id: 2,
    nombre: "Gráficos buckets vs gastos",
    tipo: "BARRAS CON DRILLDOWN",
    archivo: {
      value: 5,
      label: "GASTOS",
    },
    columna_eje_y: {
      value: 32,
      label: "VENTA_BRUTA",
    },
    funcion: "SUMA",
    nombre_eje_y: "suma de gastos",
    columna_eje_x: {
      value: 8,
      label: "ACTIVIDADES",
    },
    nombre_eje_x: "buckets",
    columnas_drilldown: [{
      value: 14,
      label: "CANAL",
    }],
    columna_base: undefined,
    columna_series: {
      value: 14,
      label: "CANAL",
    },
    activo: true,
    columna_grafico_unico: [
      {
        value: 148,
        label: "DESCRIPCION_CLIENTE",
      },
    ],
    grafico_archivo_columna_filtra: [
      {
        columna_id: 23,
        columna: "COD_UNICO",
        excluir: false,
        externo: true,
        grafico_archivo_valor_filtra: [
          {
            valor: "asd",
          },
        ],
      },
    ],
    grafico_archivo_columna_valida: [
      {
        grafico_columna_gastos_o_externo_id: 1,
        grafico_columna_gastos_o_externo: "Operacion",
        grafico_columna_archivo_base_id: 23,
        grafico_columna_archivo_base: "COD_UNICO",
        externo: true,
      },
    ],
    archivo_externo_validacion: {
      value: 7,
      label: "GASTOS",
    },
  },
  {
    id: 3,
    nombre: "Ventas semestrales",
    tipo: "TORTA CON DRILLDOWN",
    archivo: {
      value: 5,
      label: "GASTOS",
    },
    columna_eje_y: undefined,
    funcion: "SUMA",
    nombre_eje_y: "",
    columna_eje_x: undefined,
    nombre_eje_x: "",
    columnas_drilldown: [{
      value: 14,
      label: "CANAL",
    }],
    columna_base: {
      value: 14,
      label: "CANAL",
    },
    columna_series: undefined,
    activo: true,
    columna_grafico_unico: [
      {
        value: 148,
        label: "DESCRIPCION_CLIENTE",
      },
    ],
    grafico_archivo_columna_filtra: [
      {
        columna_id: 23,
        columna: "COD_UNICO",
        excluir: false,
        externo: true,
        grafico_archivo_valor_filtra: [
          {
            valor: "asd",
          },
        ],
      },
    ],
    grafico_archivo_columna_valida: [
      {
        grafico_columna_gastos_o_externo_id: 1,
        grafico_columna_gastos_o_externo: "Operacion",
        grafico_columna_archivo_base_id: 23,
        grafico_columna_archivo_base: "COD_UNICO",
        externo: true,
      },
    ],
    archivo_externo_validacion: {
      value: 7,
      label: "GASTOS",
    },
  },
  {
    id: 4,
    nombre: "Ventas anuales",
    tipo: "TREEMAP CON DRILLDOWN",
    archivo: {
      value: 6,
      label: "VENTAS",
    },
    columna_eje_y: undefined,
    funcion: "SUMA",
    nombre_eje_y: "",
    columna_eje_x: undefined,
    nombre_eje_x: "",
    columnas_drilldown: [{
      value: 70,
      label: "COD_UNICO",
    },
    {
      value: 71,
      label: "COD_CLIENTE",
    }
  ],
    columna_base: {
      value: 71,
      label: "COD_CLIENTE",
    },
    columna_series: undefined,
    activo: true,
    columna_grafico_unico: [
      {
        value: 71,
        label: "COD_CLIENTE",
      },
    ],
    grafico_archivo_columna_filtra: [
      {
        columna_id: 70,
        columna: "COD_UNICO",
        excluir: false,
        externo: true,
        grafico_archivo_valor_filtra: [
          {
            valor: "asd",
          },
        ],
      },
    ],
    grafico_archivo_columna_valida: [
      {
        grafico_columna_gastos_o_externo_id: 1,
        grafico_columna_gastos_o_externo: "Operacion",
        grafico_columna_archivo_base_id: 70,
        grafico_columna_archivo_base: "COD_UNICO",
        externo: true,
      },
    ],
    archivo_externo_validacion: {
      value: 5,
      label: "GASTOS",
    },
  },
];

export const actionsTypes = [
  {
    value: "NUEVA_COLUMNA",
    label: "Nueva columna",
  },
  {
    value: "AGRUPAR_VALORES",
    label: "Agrupar valores",
  },
  {
    value: "ORDENAR_FILAS",
    label: "Ordenar filas",
  },
  {
    value: "FILTRAR",
    label: "Filtrar",
  },
  {
    value: "FILTRAR_COLUMNAS",
    label: "Filtrar columnas",
  },
]

export const AGREGATION_FUNCTIONS = (t) => [
  {
    value: "VALOR",
    label: t("VALOR"),
  },
  {
    value: "SUMAR",
    label: t("general.add"),
  },
  {
    value: "RESTAR",
    label: t("general.subtract"),
  },
  {
    value: "MULTIPLICAR",
    label: t("general.multiply"),
  },
  {
    value: "DIVIDIR",
    label: t("general.divide"),
  },
];

export const GROUPING_FUNCTIONS = (t) => [
  //SUMAR, PROMEDIAR, CONTAR, CONTAR_UNICO, MAXIMO, MINIMO
  {
    value: "SUMAR",
    label: t("general.add"),
  },
  {
    value: "PROMEDIAR",
    label: t("general.average"),
  },
  {
    value: "CONTAR",
    label: t("general.count"),
  },
  {
    value: "CONTAR_UNICO",
    label: t("general.countUnique"),
  },
  {
    value: "MAXIMO",
    label: t("general.maximum"),
  },
  {
    value: "MINIMO",
    label: t("general.minimum"),
  },
];

export const FILTER_FUNCTIONS = (t) => [
  //=, IN, NIN, >, >=, <, <=
  {
    value: "=",
    label: t("general.equal"),
  },
  {
    value: "IN",
    label: t("general.in"),
  },
  {
    value: "NIN",
    label: t("general.notIn"),
  },
  {
    value: ">",
    label: t("general.greaterThan"),
  },
  {
    value: ">=",
    label: t("general.greaterThanOrEqual"),
  },
  {
    value: "<",
    label: t("general.lessThan"),
  },
  {
    value: "<=",
    label: t("general.lessThanOrEqual"),
  }, 
];

