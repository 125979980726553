import {
  card_subtitle_styles,
  card_title_styles,
  left_key_text_styles,
} from "../../../../styles/app-styles";
import { truncateString } from "../../../parameterization/utils";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../../buttons/SpeedDialTooltipOpen";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import useLanguage from "../../../../context/LanguageProvider";
interface MarketBasketCardProps {
  item: any;
  handleEdit?: any;
  handleCopy?: any;
  handleInfo?: any;
  handleDelete?: any;
  showInfo?: boolean;
}

const ChartCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
}: MarketBasketCardProps) => {
  const { t } = useLanguage();
  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        <Stack
          alignItems="center"
          sx={{ wordBreak: "break-word", display: "inline" }}
        >
          <Tooltip title={item?.nombre}>
            <Typography sx={card_title_styles}>
              {truncateString(item?.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "6px 0" }} />
        {showInfo && (
          <>
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              <Box sx={left_key_text_styles}>
                <b>{t("general.type")}:</b> {item?.tipo} <br />
              </Box>
              {item?.funcion?.label && (
                <Box sx={left_key_text_styles}>
                  <b>{t("general.function")}:</b> {item?.funcion?.label} <br />
                </Box>
              )}
              {item?.variable?.label && (
                <Box sx={left_key_text_styles}>
                  <b>{t("general.variable")}:</b> {item?.variable?.label} <br />
                </Box>
              )}
              {item?.indicador?.label && (
                <Box sx={left_key_text_styles}>
                  <b>{t("general.indicator")}:</b> {item?.indicador?.label}{" "}
                  <br />
                </Box>
              )}              
            </Typography>
          </>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Box></Box>
        <SpeedDialTooltipOpen
          id={item.id}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
};

export default ChartCard;
