import { Button } from "../../buttons";
import { EDIT_ACTIVE_USER_URL } from "../../../api/axios";
import { Switch } from "../../switch";
import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import Dialog from "../../dialog/Dialog";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useAuth from "../../../hooks/useAuth";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  card_title_container_styles,
  title_card_typography_styles,
} from "../../../styles/app-styles";

const UserCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  refetchData,
}: any) => {
  const [checked, setChecked] = useState(item.is_active);
  const [open, handleToggleModal] = useDialog();

  const { auth }: any = useAuth();

  const { t } = useLanguage();

  const onSuccessPutState = () => {
    refetchData();
  };

  const { isLoading: isLoadingPutCriteria, callApi: putActive } = useApi(
    "",
    "POST",
    t("administratorPanel.editUser.codes"),
    undefined,
    onSuccessPutState,
    undefined,
    false
  );

  const handleChangeSwitch = () => {
    if (auth.user !== item.email) {
      setChecked((prev: boolean) => !prev);
      putActive(EDIT_ACTIVE_USER_URL, {
        id: item.id,
        is_active: !item.is_active,
      });
    } else {
      handleToggleModal();
    }
  };

  return (
    <>
      <Stack sx={{ padding: "5px 10px 10px 10px" }}>
        <Box sx={card_title_container_styles}>
          <Tooltip title={item.nombre ? item.nombre : item.email}>
            <Typography variant="h6" sx={title_card_typography_styles}>
              {item.nombre ? item.nombre : item.email}
            </Typography>
          </Tooltip>
        </Box>
        <Divider sx={{ margin: "10px 0" }} />
        <>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "700",
              letterSpacing: "-0.5px",
            }}
            color="var(--text-main)"
          >
            {t("administratorPanel.users.rol")}
          </Typography>
          <Typography
            sx={{
              mb: 1.5,
              textAlign: "left",
              height: "65px",
              textTransform: "capitalize",
            }}
            color="var(--text-info)"
          >
            {item.rol.nombre}
          </Typography>
        </>

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Switch
            handleChange={handleChangeSwitch}
            label={
              checked
                ? t("administratorPanel.users.active")
                : t("administratorPanel.users.inactive")
            }
            checked={checked}
            color="primary"
          />
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </Stack>
      </Stack>
      <Dialog
        open={open}
        handleClose={handleToggleModal}
        title={t("administratorPanel.users.notPossibleToDisableUserLabel")}
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          maxWidth="470px"
        >
          <Typography
            sx={{
              color: "var(--text-main)",
              fontSize: "18px",
              marginTop: "30px",
              maxWidth: "440px",
              textAlign: "center",
            }}
          >
            {t("administratorPanel.users.notPossibleToDisableUserError")}
          </Typography>
          <Stack direction="row" sx={{ marginTop: "25px" }}>
            <Button
              title={t("administratorPanel.users.accept")}
              type="button"
              onClick={handleToggleModal}
              color="blue-greeny"
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default UserCard;
