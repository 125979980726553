import {
  buckets_stages_container_styles,
  stages_calculationButtons_styles,
} from "../../../styles/app-styles";
import { useEffect, useRef, useState } from "react";
import BucketsButtons from "./bucketsButtons/BucketsButtons";
import CalculationButtons from "./calculationButtons/CalculationButtons";
import ExpensesTabs from "./ExpensesTabs";
import Grid from "@mui/material/Grid";
import Iterations from "./iterations/Iterations";
import ParameterizationChart from "./chart/ParameterizationChart";
import ParameterizationTable from "./table/ParameterizationTable";
import Stack from "@mui/material/Stack";
import StagesButtons from "./stagesButtons/StagesButtons";
import useInfoContext from "../../../hooks/useInfoContext";

const CalculationView = () => {
  const { userRolData }: any = useInfoContext();
  const [isSticky, setIsSticky] = useState(false);
  const bucketsRef: any = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const bucketsRect = bucketsRef.current.getBoundingClientRect();

      const threshold = isSticky ? 60 : 130;

      if (bucketsRect.top < threshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Agrega el evento de scroll
    window.addEventListener("scroll", handleScroll);

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={buckets_stages_container_styles(isSticky)}
          ref={bucketsRef}
        >
          <BucketsButtons isSticky={isSticky} />
          <Stack sx={stages_calculationButtons_styles(isSticky)}>
            <StagesButtons />
            {userRolData && userRolData.parametrizacion_de_preprorrateo_y_prorrateo
              .visualizacion_y_edicion && <CalculationButtons />}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ParameterizationChart />
        </Grid>
        <Grid item xs={6}>
          <ExpensesTabs />
        </Grid>
        <Grid item xs={6}>
          <Iterations />
        </Grid>
        <Grid item xs={12}>
          <ParameterizationTable />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CalculationView;
