import { Box, Stack, Typography } from "@mui/material";
import Dialog from "../../../dialog/Dialog";
import { Button } from "../../../buttons";
import CustomColumnInput from "./CustomColumnInput";
import { useEffect, useState } from "react";
import useLanguage from "../../../../context/LanguageProvider";
interface ModifyColumnsModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (columns: any) => void;
  handleResetColumns: () => void;
  columns: any | undefined;
  disabled: boolean;
}

interface ResultObject {
  file_label: string;
  columnas: any[];
}

const ModifyColumnsModal = ({
  open,
  handleClose,
  handleAccept,
  columns,
  handleResetColumns,
  disabled,
}: ModifyColumnsModalProps) => {
  const { t } = useLanguage();
  const [columnsError, setColumnsError] = useState<string[]>([]);
  const [newObjectNames, setNewObjectNames] = useState(undefined);
  const [filesWColumns, setFilesWColumns] = useState<ResultObject[]>([]);

  const transformArrayColumns = (arr: any[]): ResultObject[] => {
    const result: { [key: string]: ResultObject } = {};

    arr?.forEach((item) => {
      const { file_label } = item;
      if (!result[file_label]) {
        result[file_label] = { file_label: file_label, columnas: [] };
      }
      result[file_label].columnas.push(item);
    });

    return Object.values(result);
  }

  useEffect(() => {
    columns && setNewObjectNames(columns);
    columns && setFilesWColumns(transformArrayColumns(columns));
  }, [columns]);

  const handleCloseAndClean = () => {
    setColumnsError([]);
    handleClose();
  }


  return (
    <Dialog
      open={open}
      handleClose={handleCloseAndClean}
      maxWidth="xl"
      title={t('dataModelText.groupManagement.step2.modifyColumnsModal.renameColumns')}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "550px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                title={t('dataModelText.groupManagement.step2.modifyColumnsModal.resetColumns')}
                color="blue"
                type="button"
                onClick={handleResetColumns}
                disabled={false}
              />
            </Box>
            {filesWColumns &&
              filesWColumns.map((file) => {
                return (
                  <Stack key={file.file_label}>
                    <Typography sx={{
                      color: "var(--text-main)",
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "center",
                      alignSelf: "flex-start",
                      marginLeft: "15px",
                    }}>
                      {t('dataModelText.groupManagement.step2.modifyColumnsModal.columnsOf')} {file.file_label}
                    </Typography>
                    {file.columnas?.map((column: any) => {
                      return (
                        <CustomColumnInput
                          key={column.value + column.file_label}
                          column={column}
                          columns={columns}
                          setColumnsError={setColumnsError}
                          newObjectNames={newObjectNames}
                          setNewObjectNames={setNewObjectNames}
                        />
                      );
                    })}
                  </Stack>
                )
              })

            }
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title={t('dataModelText.groupManagement.step2.modifyColumnsModal.cancel')}
            type="button"
            onClick={handleCloseAndClean}
            color="light-grey"
          />
          <Button
            title={t('dataModelText.groupManagement.step2.modifyColumnsModal.accept')}
            type="button"
            onClick={() => {
              handleAccept(newObjectNames);
            }}
            color="blue-greeny"
            disabled={columnsError.length > 0}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ModifyColumnsModal;
