import Grid from "@mui/material/Grid/Grid";
import InputTitle from "../../../forms/InputTitle";

const GridFiveTitles = ({ titles }) => {
  const titlesQuantity = titles.length;
  
  return (
    <>
      <Grid item xs={0.5}></Grid>
      {titles.map((title, index) => (
        <Grid item xs={11 / titlesQuantity} key={index} >
          <InputTitle title={title} fontSize="12px" />
        </Grid>
      ))}
      <Grid item xs={0.5}></Grid>
    </>
  );};

export default GridFiveTitles;
