import { useContext, useEffect, useState } from "react";
import ComponentLayout from "../../layout/ComponentLayout";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CustomStepper from "../../Stepper/CustomStepper";
import { Box, Stack, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../buttons/Button";
import data from "./data.json";
import { ConfirmationModal } from "../../dialog";
import useDialog from "../../../hooks/useDialog";
import Step1 from "./Step1/Step1";
import GroupersProvider from "../../../context/GroupersProvider";
import Step2 from "./Step2/Step2";
import {
  step_container_styles,
  step_title_styles,
} from "../../../styles/app-styles";
import {
  columnIsRepeated,
  handleRepeatedName,
  isBaseDuplicated,
  isGrouperNameValid,
} from "../utils";
import useApi from "../../../hooks/useApi";
import { GROUPERS_URL, GROUPER_URL } from "../../../api/axios";
import { Base } from "../../../context/types";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";
interface ConfigGroupProps {
  handleFinish: () => void;
  configMode: "CREATE" | "EDIT" | "COPY";
}

const ConfigGroup = ({ handleFinish, configMode }: ConfigGroupProps) => {
  const { t } = useLanguage();
  const [activeStep, setActiveStep] = useState(0);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const { groupersState, groupersDispatch } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const { bases, grouperName, step2, grouperList } = groupersState;

  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useMultiSelect(step2 ? step2.columnasAUsar : undefined);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { isLoading: isLoadingPostGroup, callApi: postGroup } = useApi(
    GROUPERS_URL,
    "POST",
    t("dataModel.postNewGrouper"),
    undefined,
    handleFinish,
    undefined,
    true
  );

  const { isLoading: isLoadingPutGroup, callApi: putGroup } = useApi(
    GROUPER_URL(groupersState?.id),
    "PUT",
    t("dataModel.putGrouper"),
    undefined,
    handleFinish,
    undefined,
    true
  );

  const saveGroup = () => {
    const grouper = {
      nombre: grouperName,
      bases: bases.map((b: Base) => {
        return {
          archivo: b.archivo?.value,
          columnas: b.columnas?.map((columna: any) => {
            return columna.value;
          }),
          merge: b.merge
            ? {
                columnas_base_anterior: b.merge?.columnas_base_anterior?.map(
                  (columna: any) => {
                    return columna.value;
                  }
                ),
                columnas_base_nueva: b.merge?.columnas_base_nueva?.map(
                  (columna: any) => {
                    return columna.value;
                  }
                ),
                eliminar_duplicados: b.merge.eliminar_duplicados,
              }
            : undefined,
        };
      }),
      step2: {
        archivo: step2.archivo.value,
        columna: step2.columna.value,
        columnas_a_usar: step2.columnasAUsar.map((column: any) => {
          return { value: column.value, label: column.label };
        }),
      },
    };
    if (configMode === "CREATE" || configMode === "COPY") {
      postGroup(undefined, grouper);
    } else {
      putGroup(undefined, grouper);
    }
    toggleConfirmationModal();
  };

  const isDisabled = () => {
    return (
      !grouperName ||
      isBaseDuplicated(bases) ||
      !isGrouperNameValid(grouperName) ||
      handleRepeatedName(grouperName, grouperList, configMode) ||
      bases.length < 1 ||
      bases.some((base) => {
        return base.error;
      }) ||
      bases.some((base) => {
        return base.columnas.length === 0;
      }) ||
      (activeStep === 1 &&
        (step2.archivo === null ||
          step2.archivo === undefined ||
          step2.columna === null ||
          step2.columna === undefined ||
          step2.columnasAUsar.length === 0 ||
          step2.columnasAUsar === undefined)) ||
      columnIsRepeated(columnSelectedOptions)
    );
  };

  // El objetivo de este use effect es setear el nombre en blanco si estamos en modo copia
  useEffect(() => {
    if (configMode === "COPY") {
      groupersDispatch({ type: "SET_GROUPER_NAME", payload: "" });
    }
  }, []);

  return (
    <ComponentLayout
      title={configMode === "EDIT" ? t("dataModelText.groupManagement.configGroup.editGroup") : t("dataModelText.groupManagement.configGroup.newGroup")}
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={handleFinish}
        />
      }
    >
      <section className="cc_container">
        <SimpleBackdrop
          open={isLoadingPostGroup || isLoadingPutGroup}
          message={
            configMode === "CREATE" || configMode === "COPY"
              ? t("dataModel.postNewGrouper.loading")
              : t("dataModel.putGrouper.loading")
          }
        />
        <CustomStepper activeStep={activeStep} data={data} />
        <>
          <Box sx={step_title_styles}>{data.steps[activeStep]}</Box>
          <Stack sx={step_container_styles}>
            {activeStep === 0 && <Step1 configMode={configMode} />}
            {activeStep === 1 && (
              <Step2
                columnSelectedOptions={columnSelectedOptions}
                setColumnSelectedOptions={setColumnSelectedOptions}
                columnOptionsChangeHandler={columnOptionsChangeHandler}
              />
            )}
          </Stack>
        </>
        <div className="cc_steps_buttons">
          <Button
            title={t("dataModelText.groupManagement.configGroup.previous")}
            color="blue-greeny"
            type="button"
            onClick={handleBack}
            disabled={activeStep === 0}
          />
          <Tooltip
            title={
              isBaseDuplicated(bases)
                ? t(
                    "dataModelText.groupManagement.configGroup.nonPossibleContinueTwoDataBases"
                  )
                : columnIsRepeated(columnSelectedOptions) ? t(
                  "dataModelText.groupManagement.configGroup.columnsWithSameName"
                ) : ""
            }
          >
            <span>
              <Button
                title={
                  activeStep === 1
                    ? t("dataModelText.groupManagement.configGroup.finish")
                    : t("dataModelText.groupManagement.configGroup.next")
                }
                color="blue"
                type="button"
                onClick={
                  activeStep === 1 ? toggleConfirmationModal : handleNext
                }
                disabled={isDisabled()}
              />
            </span>
          </Tooltip>

          <ConfirmationModal
            open={openConfirmationModal}
            handleClose={toggleConfirmationModal}
            handleAccept={saveGroup}
            message={
              configMode === "EDIT"
                ? t(
                    "dataModelText.groupManagement.configGroup.editGroup"
                  ).toLowerCase()
                : t(
                    "dataModelText.groupManagement.configGroup.createGroup"
                  ).toLowerCase()
            }
            title={
              configMode === "EDIT"
                ? t("dataModelText.groupManagement.configGroup.editGroup")
                : t("dataModelText.groupManagement.configGroup.createGroup")
            }
          />
        </div>
      </section>
    </ComponentLayout>
  );
};

export default ConfigGroup;
