import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { renderCellImportElements } from "./utils";
import Stack from "@mui/material/Stack";

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla. Debe recibir la data con las key como columnas, o bien se puede enviar un array con las columnas ya predefinidas.
 * @param data
 */
export const transformDataImportElements: (
  t,
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number,
  handleOpenDetail?: any
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  t,
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number,
  handleOpenDetail?: any
) => {
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: minWidth ? minWidth : 100,
      flex: columna === "posee_errores" ? 0.3 : columna === "nombre" ? 0.8 : 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(t, columna) : columna}
        </strong>
      ),
      renderCell: (params: any) => (
        <Stack direction="row" alignItems="center" height={"auto"}>
          {renderCellImportElements(t, columna, params, handleOpenDetail)}
        </Stack>
      ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
