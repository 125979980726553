import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  COLUMNS_VALIDATIONS_URL,
  ROWS_VALIDATIONS_URL,
  DELETE_PERIOD_URL,
  ALLOWED_FILE_NAMES_URL,
} from "../../api/axios";
import { FileType } from "./types";

export const useGetColumnsValidations = () => {
  const axiosPrivate = useAxiosPrivate();

  const getColumnsValidations = async () => {
    const response = await axiosPrivate.get(COLUMNS_VALIDATIONS_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getColumnsValidations"],
    getColumnsValidations,
    {
      enabled: false,
    }
  );

  return info;
};

export const useGetRowsValidations = () => {
  const axiosPrivate = useAxiosPrivate();

  const getRowsValidations = async () => {
    const response = await axiosPrivate.get(ROWS_VALIDATIONS_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getRowsValidations"],
    getRowsValidations,
    {
      enabled: false,
    }
  );

  return info;
};

export const deletePeriod = async (
  axiosPrivate: any,
  period: string,
  handleDeletePeriodResponse: any
) => {
  if (period) {
    try {
      const response = await axiosPrivate.delete(
        `${DELETE_PERIOD_URL}/${period}`
      );
      handleDeletePeriodResponse(response.status);
    } catch (error: any) {
      handleDeletePeriodResponse(400);
    }
  }
};

export const useGetAllowedFileNames = () => {
  const axiosPrivate = useAxiosPrivate();

  const getAllowedNames = async () => {
    const response = await axiosPrivate.get(ALLOWED_FILE_NAMES_URL);
    return response.data;
  };
  const info: UseQueryResult<FileType[], Error> = useQuery(
    ["getAllowedNames"],
    getAllowedNames
  );

  return info;
};

export const deleteFileName = async (
  axiosPrivate: any,
  id: number,
  handleDeleteFileNameResponse: any
) => {
  if (id) {
    try {
      const response = await axiosPrivate.delete(
        `${ALLOWED_FILE_NAMES_URL}?id=${id}`
      );
      handleDeleteFileNameResponse(response.status);
    } catch (error: any) {
      handleDeleteFileNameResponse(400);
    }
  }
};

export const postFileName = async (
  axiosPrivate: any,
  filename: string,
  handlePostFileNameResponse: any
) => {
  if (filename) {
    const data = JSON.stringify({
      nombre: filename,
    });
    try {
      const response = await axiosPrivate.post(ALLOWED_FILE_NAMES_URL, data);
      handlePostFileNameResponse(response.status, response.data);
    } catch (error: any) {
      handlePostFileNameResponse(400, error);
    }
  }
};
