import { EDIT_HABILITADO_ROL_URL } from "../../../api/axios";
import { Switch } from "../../switch";
import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import {
  card_title_container_styles,
  title_card_typography_styles,
} from "../../../styles/app-styles";

const RolCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  refetchData,
}) => {
  const { t } = useLanguage();
  const [checked, setChecked] = useState(item.habilitado);

  const handleChangeSwitch = () => {
    setChecked((prev: boolean) => !prev);
    putRol(EDIT_HABILITADO_ROL_URL(item?.id), {
      ...item,
      habilitado: !item.habilitado,
    });
  };

  const { callApi: putRol } = useApi(
    "",
    "PUT",
    t("administratorPanel.roles.editRol.codes"),
    undefined,
    refetchData,
    undefined,
    false
  );

  return (
    <>
      <Stack sx={{ padding: "5px 10px 10px 10px" }}>
        <Box sx={card_title_container_styles}>
          <Tooltip title={item.nombre}>
            <Typography variant="h6" sx={title_card_typography_styles}>
              {item.nombre}
            </Typography>
          </Tooltip>
        </Box>

        <Divider sx={{ margin: "10px 0" }} />
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Switch
            handleChange={handleChangeSwitch}
            label={
              checked
                ? t("administratorPanel.switchLabelActive")
                : t("administratorPanel.switchLabelActive")
            }
            checked={checked}
            color="primary"
          />
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default RolCard;
