import { PERIODS_URL, PRORRAT_PERIODS_URL } from "../../api/axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const useGetPeriodsProrrat = () => {
  const axiosPrivate = useAxiosPrivate();

  const getPeriods = async () => {
    const response = await axiosPrivate.get(PRORRAT_PERIODS_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(["getPeriods"], getPeriods, {
    refetchOnWindowFocus: false,
  });

  return info;
};

export const postPeriods = async (
  axiosPrivate: any,
  periods: any,
  checked: boolean,
  handleApplyPeriodsResponse: any
) => {
  if (periods) {
    const data = JSON.stringify({ periodos: periods, exportable: checked });
    try {
      const response = await axiosPrivate.put(PERIODS_URL, data);
      handleApplyPeriodsResponse(response.status);
    } catch (error: any) {
      handleApplyPeriodsResponse(400);
    }
  }
};
