import { Container, Stack } from "@mui/material";
import { PARAM_OTHER_FILE_COLUMNS_URL } from "../../../../api/axios";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useState } from "react";
import Button from "../../../buttons/Button";
import ColumnType from "./ColumnType";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import ShadowBox from "../../../dataModel/groupManagement/ShadowBox";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";

const ListColumnsTypes = ({ file }) => {
  const { t } = useLanguage();
  const {
    filesColumnsTypes,
    dataGetColumnsTypesParams,
    getColumnsTypesParams,
  } = useCompanyParamProvider();
  const [isFileComplete, setIsFileComplete] = useState<boolean>(false);

  useEffect(() => {
    const actualFile = filesColumnsTypes?.find(
      (item) => item.nombre === file.nombre
    );
    const isFile = !!actualFile?.archivo?.name;

    const areAllColumnsTyped = actualFile?.columnas?.every((column) => {
      return column.tipo;
    });
    setIsFileComplete(isFile && areAllColumnsTyped);
  });

  const onSuccessPostFilesColumnsTypes = () => {
    getColumnsTypesParams();
  };

  const {
    isLoading: isLoadingPostFilesColumnsTypes,
    callApi: postFilesColumnsTypes,
  } = useApi(
    PARAM_OTHER_FILE_COLUMNS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPostFilesColumnsTypes,
    undefined,
    true
  );

  const saveFile = () => {
    const updatedInfo = dataGetColumnsTypesParams
      .filter((item) => {
        return item.nombre === file.nombre;
      })
      .map((item) => {
        if (item.nombre === file.nombre) {
          return {
            ...item,
            columnas: filesColumnsTypes?.find(
              (item) => item.nombre === file.nombre
            )?.columnas,
          };
        }
      });
    postFilesColumnsTypes(undefined, updatedInfo);
  };

  return (
    <FormSelectLayout title={t('companyParam.columnTyping')} required={true}>
      {file.columnas.map((column) => {
        return (
          <Container key={column.value} sx={{ mb: 2, mt: 2 }}>
            <ShadowBox>
              <ColumnType file={file} column={column} />
            </ShadowBox>
          </Container>
        );
      })}
      <Stack alignItems="center">
        <Button
          title="guardar"
          color="blue-greeny"
          type="button"
          onClick={saveFile}
          disabled={!isFileComplete}
        />
      </Stack>
    </FormSelectLayout>
  );
};

export default ListColumnsTypes;
