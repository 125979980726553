import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { convertNumberToMoney } from "../../../../../parameterization/utils";
import { convertUnit } from "../../../../../report/utils";
import { getInformationColumn, roundNumber } from "../../../../utils";

/**
 * @name transformData
 * @description Función que transforma los datos de la tabla virtualizada. Se usa DataGrid Pro de Material-UI.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformDataCancelRoutes = (
    dataColumnas: any,
    dataFilas: any,
    informationsColumns: any
) => {
    //Saco la columna _id para que no se renderice
    const columnsWithoutId = Object && Object?.keys(dataColumnas[0])?.filter(
        (key) => key !== "_id"
    );

    const arrayColumns = columnsWithoutId?.map((columna) => ({
        field: columna,
        headerClassName: "es_header_column",
        headerName: columna,
        description: columna,
        width: 120,
        renderHeader: (params: GridColumnHeaderParams) => (
            <strong className="es_column_title">
                {params.field}
            </strong>
        ),
        renderCell: (params: any) => (
            <Tooltip
                disableFocusListener
                disableTouchListener
                title={`${ isNaN(params.value) ? params.value : roundNumber(Number(params.value), 2)}`}
            >
                <p className="es_cellValue">
                    {
                        columna === getInformationColumn(informationsColumns, "Costo TOTAL de Ventas y Distribucion") ||
                            columna === getInformationColumn(informationsColumns, "Costos de Servir") ||
                            columna === getInformationColumn(informationsColumns, "Costo TOTAL de Descuentos y Acuerdos") ||
                            columna === getInformationColumn(informationsColumns, "Venta Bruta") ||
                            columna === getInformationColumn(informationsColumns, "Costo de Mercaderia Vendida") ||
                            columna === getInformationColumn(informationsColumns, "Profit to Serve") ?
                            convertNumberToMoney(params.value) :
                            columna === getInformationColumn(informationsColumns, "Volumen en Unidades") ||
                                columna === getInformationColumn(informationsColumns, "Clientes por Ruta") ?
                                Number(params.value) < 1000 ?
                                    roundNumber(Number(params.value), 2) :
                                    convertUnit("unidades", Number(params.value)) :
                                params.value
                    }
                </p>
            </Tooltip>
        ),
    }));

    const arrayRows = dataFilas.map((fila: any) => {
        const obj: any = {};
        obj["id"] = fila._id;
        for (const key in fila) {
            if (key !== "_id") {
                obj[key] = fila[key];
            }
        }
        return obj;
    });

    const columns: GridColDef[] = arrayColumns.map((col) => ({
        ...col,
        headerAlign: "center",
        align: "center",
    }));

    const rows = arrayRows.map((row: any) => ({
        ...row,
    }));

    return { columns, rows };
};