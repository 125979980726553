import { form_subtitle_styles } from "../../../../styles/app-styles";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../configCriteria/select.styles";
import { Stack, Typography } from "@mui/material";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useState } from "react";
import AddPeriodsModal from "./AddPeriodsModal";
import Button from "../../../buttons/Button";
import CurrencySelect from "./CurrencySelect";
import Dialog from "../../../dialog/Dialog";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Select from "../../../forms/Select";
import UploadFileModal from "./UploadFileModal";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";

const ExchangeForm = () => {
  const { t } = useLanguage();
  const {
    countrySelectRef,
    dataCountryCurrency,
    foreignCurrencySelectRef,
    currencySelectedOptionState,
    getExchangeParams,
    setForeignCurrencySelectedOptionState,
    dataExchangeParams,
    currencyController,
    exchangeFileToUpload,
    setExchangeFileToUpload,
    setCountrySelectedOptionState,
  } = useCompanyParamProvider();
  const [openAddPeriodsModal, toggleAddPeriodsModal] = useDialog();
  const [openUploadFileModal, toggleUploadFileModal] = useDialog();
  const [isHelpOn, setIsHelpOn] = useState(false);

  const onCountryChange = (country) => {
    setCountrySelectedOptionState(country);
    setForeignCurrencySelectedOptionState([]);
    foreignCurrencySelectRef.current.clearValue();
    setForeignCurrencySelectedOption([]);
  };

  const [
    countrySelectedOption,
    setCountrySelectedOption,
    countryOptionChangeHandler,
  ] = useSingleSelect(
    (country) => onCountryChange(country),
    dataExchangeParams && dataExchangeParams?.pais
      ? dataExchangeParams?.pais
      : undefined
  );

  const onForeignCurrencyChange = (newForeignValues) => {   
    setForeignCurrencySelectedOptionState(newForeignValues);
  }

  const [
    foreignCurrencySelectedOption,
    setForeignCurrencySelectedOption,
    foreignCurrencyOptionChangeHandler,
  ] = useMultiSelect(
    dataExchangeParams && dataExchangeParams?.tipo_de_cambio
      ? dataExchangeParams?.tipo_de_cambio
      : [],
      (newForeignValues) => onForeignCurrencyChange(newForeignValues)
  );

  const handleAddPeriodsInfo = (info) => {
    getExchangeParams(undefined, {
      pais: countrySelectedOption?.value,
      moneda: currencySelectedOptionState?.value,
      rango_periodo: info,
      tipo_de_cambio: foreignCurrencySelectedOption?.map(
        (currency) => currency.value
      ),
    });
  };

  const handleUploadFile = (info) => {
    const formData = new FormData();
    formData.append("archivo", exchangeFileToUpload[0]);
    formData.append("moneda", currencySelectedOptionState?.value);
    getExchangeParams(undefined, formData, "UPLOAD FILE");
    toggleUploadFileModal();
    setExchangeFileToUpload(undefined);
  };

  useEffect(() => {
    /* setForeignCurrencySelectedOptionState(foreignCurrencySelectedOption); */
    if (
      foreignCurrencySelectedOption &&
      foreignCurrencySelectedOption?.length > 0
    ) {      
      getExchangeParams(undefined, {
        pais: countrySelectedOption?.value,
        moneda: currencySelectedOptionState?.value,
        tipo_de_cambio: foreignCurrencySelectedOption?.map(
          (currency) => currency.value
        ),
      });
    }
    return () => {
      currencyController.abort();
    };
  }, [/* foreignCurrencySelectedOption, */ foreignCurrencySelectedOption?.length]);

  return (
    <Grid2 container direction={"row"} xs={12} gap={2}>
      <Grid2 xs={5.9}>
        <FormSelectLayout title={t("general.country")} required={true}>
          <Select
            reference={countrySelectRef}
            isClearable
            styles={selectStyles(countrySelectedOption)}
            options={dataCountryCurrency}
            className="pp_select_schema"
            onChange={countryOptionChangeHandler}
            closeMenuOnSelect
            isSearchable
            placeholder={t("general.selectOption")}
            defaultValue={
              dataExchangeParams && dataExchangeParams?.pais
                ? dataExchangeParams?.pais
                : undefined
            }
          />
        </FormSelectLayout>
      </Grid2>
      <Grid2 xs={5.9}>
        {countrySelectedOption && (
          <FormSelectLayout title={t("general.currencyy")} required={true}>
            <CurrencySelect
              setForeignCurrencySelectedOption={
                setForeignCurrencySelectedOption
              }
            />
          </FormSelectLayout>
        )}
      </Grid2>
      <Grid2 xs={12}>
        <Typography
          sx={{
            ...form_subtitle_styles,
            margin: 0,
            alignItems: "center",
            display: "flex",
            gap: 2,
          }}
        >
          {t("companyParam.foreignCurrencyExchangeRate")}
          {isHelpOn ? (
            <HelpIcon
              onClick={() => setIsHelpOn(false)}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <HelpOutlineIcon
              onClick={() => setIsHelpOn(true)}
              sx={{ cursor: "pointer", color: "var(--icon-main)" }}
            />
          )}
        </Typography>
        {isHelpOn && (
          <Typography
            variant="body2"
            sx={{
              margin: "0 30px 0 0",
              alignItems: "center",
              display: "flex",
              gap: 2,
            }}
          >
            <ul>
              <li>{t("companyParam.help1")}</li>
              <li>
                {t("companyParam.help2")}
                <ol>
                  <li>{t("companyParam.help3")}</li>
                  <li>{t("companyParam.help4")}</li>
                  <li>{t("companyParam.help5")}</li>
                  <li>{t("companyParam.help6")}</li>
                  <li>{t("companyParam.help7")}</li>
                </ol>
              </li>
              <li>{t("companyParam.help8")}</li>
            </ul>
          </Typography>
        )}
      </Grid2>

      <Grid2 xs={5.9}>
        <FormSelectLayout title={t("general.foreignCurrency")} required={false}>
          <Select
            reference={foreignCurrencySelectRef}
            isClearable
            styles={optionalSelectStyles(foreignCurrencySelectedOption)}
            options={dataCountryCurrency
              .map((item) => item?.moneda && item.moneda)
              .filter((item) => Object.keys(item).length > 0)}
            className="pp_select_schema"
            onChange={foreignCurrencyOptionChangeHandler}
            closeMenuOnSelect={false}
            isSearchable
            placeholder={t("companyParam.addCurrencies")}
            isMulti
            defaultValue={
              dataExchangeParams && dataExchangeParams?.tipo_de_cambio
                ? dataExchangeParams?.tipo_de_cambio
                : []
            }
          />
        </FormSelectLayout>
      </Grid2>
      <Grid2 xs={5.9}>
        <Stack
          sx={{ flexDirection: "row", alignItems: "flex-end", height: "80px" }}
        >
          <Button
            title={t("companyParam.addPeriods")}
            color="blue-greeny"
            type="button"
            width={180}
            onClick={toggleAddPeriodsModal}
          />
          <Button
            title={t("companyParam.addValuesFromFile")}
            color="blue"
            type="button"
            onClick={toggleUploadFileModal}
            width={185}
          />
        </Stack>
      </Grid2>
      <Dialog
        open={openAddPeriodsModal}
        handleClose={toggleAddPeriodsModal}
        title={t("companyParam.addPeriods")}
        maxWidth={"lg"}
      >
        <AddPeriodsModal
          handleToggleModal={toggleAddPeriodsModal}
          setInfo={handleAddPeriodsInfo}
        />
      </Dialog>
      <Dialog
        open={openUploadFileModal}
        handleClose={toggleUploadFileModal}
        title={t("companyParam.addValuesFromFile")}
        maxWidth={"lg"}
      >
        <UploadFileModal
          handleToggleModal={toggleUploadFileModal}
          handleApply={handleUploadFile}
        />
      </Dialog>
    </Grid2>
  );
};

export default ExchangeForm;
