import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell: any = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#32648e",
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    height: "25px",
    padding: "5px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "25px",
    padding: "7px 10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface CustomizedTablesProps {
  COLUMNS: string[];
  ROWS: any[];
  width?: string | number;
}

export default function CustomizedTables({
  COLUMNS,
  ROWS,
  width = 500,
}: CustomizedTablesProps) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: { width },
        marginTop: "5px !important",     
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {COLUMNS.map((column: string) => {
              return (
                <StyledTableCell key={column} align="center">
                  {column}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {ROWS.map((row: any, index: number) => (
            <StyledTableRow key={index}>
              {Object.keys(row).map((key: string) => {
                return (
                  <StyledTableCell key={key} align="center">
                    {row[key]}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
