import CardDetailItem from "../../cards/CardDetailItem";
import React from "react";
import Stack from "@mui/material/Stack/Stack";

const TableDetails: React.FC<{ item: any; t: any; tableConfig: any }> = ({
  item,
  t,
  tableConfig,
}) => (
  <Stack spacing={tableConfig.spacing || 1}>
    <CardDetailItem
      title={t("indicatorsManagementTexts.chartCard.baseFile")}
      value={item.archivo?.label || ""}
    />
    {/* {item.limitar_resultados && (
      <CardDetailItem
        title={tableConfig?.limitResultsLabel}
        value={tableConfig?.limitResultsValue && tableConfig.limitResultsValue(item)}
      />
    )}  */}  
  </Stack>
);

export default TableDetails