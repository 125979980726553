import { useEffect, useRef, useState } from "react";
import Button from "../../buttons/Button";
import DraggableRowElement from "./DraggableRowElement";
import MessageBlock from "../../forms/MessageBlock";
import Stack from "@mui/material/Stack/Stack";
import useLanguage from "../../../context/LanguageProvider";
import SaveNameModal from "../../dialog/SaveNameModal";
import useDialog from "../../../hooks/useDialog";

const EditColumnsModal = ({
  toggleEditColumnsModal,
  handleApply,
  handleCancel,
  columns,
  setColumns,
}) => {
  const { t } = useLanguage();
  const [openSaveNameModal, toggleSaveNameModal] = useDialog();
  const [element, setElement] = useState<any>();
  const [elementName, setElementName] = useState("");
  const dragItem: any = useRef(null);
  const dragOverItem: any = useRef(null);

  function toggleConfirmDeleteElement(): void {
    throw new Error("Function not implemented.");
  }

  /****************************************** Editar elemento ***************************************/

  const handleEditElement = (event, element) => {
    setElement(element);
    setElementName(element.label);
    toggleSaveNameModal();
    event.stopPropagation();
  };

  const handleSaveName = (action) => {
    setElementName(action.payload);
  };

  const isValidName = (namesList, value) => {
    return !namesList
      .filter((item) => item.value !== element?.value)
      .some((item) => item.label.toLowerCase() === value.toLowerCase());
  };

  const editElement = () => {
    setColumns(
      columns.map((col) =>
        col.value === element?.value ? { ...col, label: elementName } : col
      )
    );
    toggleSaveNameModal();
  };

  /*********************************************** Drag and drop ***********************************/

  const onDropActions = (initialItem, finalItem) => {
    setColumns((columns) => {
      const newColumns = [...columns];
      const initialIndex = newColumns.findIndex(
        (column) => column.value === initialItem.value
      );
      const finalIndex = newColumns.findIndex(
        (column) => column.value === finalItem.value
      );
      newColumns.splice(initialIndex, 1);
      newColumns.splice(finalIndex, 0, initialItem);
      return newColumns;
    });
  };

  const message = {
    text: "Arrastrar para reordenar las columnas, o hacer click en los íconos de editar o eliminar para modificarlas.",
    type: "text",
  };

  return (
    <Stack width="100%">
      <Stack justifyContent={"space-between"} gap={2} alignItems={"flex-end"}>
        <MessageBlock message={message} />
        {columns &&
          columns.length > 0 &&
          columns?.map((col) => (
            <DraggableRowElement
              key={col.value}
              element={col}
              toggleDeleteElement={toggleConfirmDeleteElement}
              setElement={() => <></>}
              togglePnlModal={toggleEditColumnsModal}
              handleEditElement={(e) => handleEditElement(e, col)}
              dragItem={dragItem}
              dragOverItem={dragOverItem}
              onDropActions={onDropActions}
            />
          ))}
      </Stack>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={false}
        />
      </Stack>
      <SaveNameModal
        open={openSaveNameModal}
        handleClose={toggleSaveNameModal}
        handleAccept={editElement}
        title={t("companyParam.editName")}
        text={t("companyParam.confirmEditName")}
        label={t("general.name")}
        placeholder={t("companyParam.writeNewName")}
        dispatchFunction={(action) => handleSaveName(action)}
        dispatchType={""}
        valueState={elementName}
        namesList={columns && columns.length > 0 ? columns : []}
        isValidName={isValidName}
      />
    </Stack>
  );
};

export default EditColumnsModal;
