import { formatCompanyOptions } from "../../utils/util";
import { GET_ALL_COMPANIES_URL } from "../../api/axios";
import { selectStyles } from "../configCriteria/select.styles";
import { transformColumnNameImportElements } from "./utils";
import { transformDataImportElements } from "./transformDataImportElements";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useRef, useState } from "react";
import Button from "../buttons/Button";
import ConfirmationModal from "../dialog/ConfirmationModal";
import Dialog from "../dialog/Dialog";
import FormSelectLayout from "../forms/FormSelectLayout";
import NoCardsError from "../error/NoCardsError";
import Select from "../forms/Select";
import SimpleTable from "../tablesTools/SimpleTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useSingleSelect from "../../hooks/useSingleSelect";

interface ImportElementsModalProps {
  toggleItemsListModal: any;
  openConfirmationModal: any;
  toggleConfirmationModal: any;
  postImportElements: any;
  getElements: any;
  GET_ELEMENTS_URL: (companyId: any) => string;
  dataGetElements: any;
  message?: string;
}

const ImportElementsModal = ({
  toggleItemsListModal,
  openConfirmationModal,
  toggleConfirmationModal,
  postImportElements,
  getElements,
  GET_ELEMENTS_URL,
  dataGetElements,
  message,
}: ImportElementsModalProps) => {
  const { t } = useLanguage();
  const companySelectRef: any = useRef(null);
  const { company: actualCompany }: any = useInfoContext();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [detailError, setDetailError] = useState<any>(null);
  const [openErrorsModal, handleToggleErrorsModal] = useDialog();
  const {
    data: dataGetAllCompanies,
    isLoading: isLoadingAllCompanies,
    isError: isErrorAllCompanies,
  } = useApiQuery(GET_ALL_COMPANIES_URL, true, t("company.getCompaniesError"));

  const onSelectCompany = (company) => {
    if (company) {
      getElements(GET_ELEMENTS_URL(company?.value));
    }
  };

  const [
    companySelectedOption,
    setCompanySelectedOption,
    companyOptionChangeHandler,
  ] = useSingleSelect((company) => onSelectCompany(company));

  const handleApply = () => {
    postImportElements(undefined, {
      elementos: selectedItems,
    });
    toggleConfirmationModal();
    toggleItemsListModal();
  };

  const handleOpenDetail = (motivoError) => {
    setDetailError(motivoError);
    handleToggleErrorsModal();
  };

  return (
    <>
      <Stack
        direction="column"
        width="100%"
        sx={{ overflow: "auto", gap: 2, minHeight: "400px" }}
      >
        {message && <Typography color="primary">{message}</Typography>}
        <FormSelectLayout title={t("company.chooseCompany")} required={true}>
          <Select
            options={formatCompanyOptions(
              dataGetAllCompanies?.filter((company: any) => {
                return company.habilitada && company.id !== actualCompany.value;
              })
            )}
            styles={selectStyles(companySelectedOption)}
            reference={companySelectRef}
            onChange={companyOptionChangeHandler}
            isClearable
            closeMenuOnSelect
            isSearchable
            placeholder={t("general.selectOption")}
          />
        </FormSelectLayout>

        {dataGetElements && dataGetElements.length && companySelectedOption ? (
          <SimpleTable
            columns={
              transformDataImportElements(
                t,
                dataGetElements,
                undefined,
                transformColumnNameImportElements,
                undefined,
                handleOpenDetail
              ).columns
            }
            rows={
              transformDataImportElements(
                t,
                dataGetElements,
                undefined,
                transformColumnNameImportElements,
                undefined,
                handleOpenDetail
              ).rows
            }
            selectedRows={selectedItems}
            setSelectedRows={setSelectedItems}
            checkboxSelection={true}
            height="300px"
            headerHeight={40}
            rowHeight={40}
          />
        ) : (
          <NoCardsError height="200px"/>
        )}
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleAccept={handleApply}
        message={t("importElements.importTheElements")}
        title={t("importElements.importElements")}
      />
      <Dialog
        open={openErrorsModal}
        handleClose={handleToggleErrorsModal}
        title={t("taskManager.errorDetail")}
        maxWidth={"xl"}
      >
        {!detailError ? (
          <Stack sx={{ minWidth: "20vw", alignItems: "center" }}>
            <Typography sx={{ color: "var(--text-main)", fontWeight: "300" }}>
              {t("taskManager.noDetailYet")}
            </Typography>
          </Stack>
        ) : (
          detailError && (
            <Stack
              sx={{
                whiteSpace: "normal",
                height: "auto",
                textAlign: "start",
                color: "var(--text-main)",
              }}
            >
              <ul style={{ width: "100%", color: "var(--text-main)" }}>
                {detailError.map((error: any, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Stack>
          )
        )}
      </Dialog>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", alignSelf: "flex-end" }}
      >
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={toggleItemsListModal}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={toggleConfirmationModal}
          disabled={!selectedItems || selectedItems?.length === 0}
        />
      </Stack>
    </>
  );
};

export default ImportElementsModal;
