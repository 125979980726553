import {
  blue_label_styles,
  form_helper_text_styles,
  key_column_select_styles,
  modal_container_minheight_styles,
  row_center_100_styles
} from "../../../../styles/app-styles";
import { transformarTextToLabel } from "../utils";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import { useState } from "react";
import Button from "../../../buttons/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import MessageBlock from "../../../forms/MessageBlock";

const AdvancedParametersModal = ({ handleToggleModal }) => {
  const { t } = useLanguage();
  const { marketBasketElement, setMarketBasketElement } =
    useMarketBasketConfigProvider();
  const objeto = marketBasketElement?.parametros_avanzados || {
    soporte_minimo_apriori: 0.03,
    soporte_minimo_conjunto: 0.025,
    soporte_minimo_antecedente: 0.1,
    soporte_minimo_consecuente: 0.05,
    confianza_minima: 0.6,
    lift_minimo: 2.5,
  };
  const [parametersValues, setParametersValues] = useState<any>(objeto);
  const [message, setMessage] = useState<any>({
    text: "",
    type: "",
  });

  const handleCancel = () => {
    setParametersValues(objeto);
    setMessage({ text: "", type: "" });
    handleToggleModal();
  };

  const handleApply = () => {
    setMarketBasketElement((prevState) => {
      return {
        ...prevState,
        parametros_avanzados: parametersValues,
      };
    });
    handleToggleModal();
  };

  const handleSetParametersValues = (key: string, value: any) => {
    setParametersValues((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
    if (value === "") {
      setMessage({
        text:
          `${transformarTextToLabel(t,key)}` + " - " + t("general.requiredField"),
        type: "error",
      });
    } else if (key === "lift_minimo" && value < 1) {
      setMessage({
        text:
          `${transformarTextToLabel(t,key)}` +
          " - " +
          t("general.minValueIs") +
          " 1",
        type: "error",
      });
    } else if (key !== "lift_minimo" && (value < 0 || value > 1)) {
      setMessage({
        text:
          `${transformarTextToLabel(t,key)}` +
          " - " +
          t("general.valueBetween") +
          " 0 y 1",
        type: "error",
      });
    } else {
      setMessage({ text: "", type: "" });
    }
  };

  return (
    <Stack sx={modal_container_minheight_styles("200px")}>
      <MessageBlock message={message} />
      {Object.keys(objeto).map((key) => (
        <Stack
          key={key}
          sx={{ ...key_column_select_styles, alignItems: "center" }}
        >
          <Typography sx={blue_label_styles}>
            {transformarTextToLabel(t,key)}
          </Typography>
          <Stack width="30%">
            <TextField
              type="number"
              label={t('general.value')}
              variant="outlined"
              fullWidth
              value={parametersValues[key]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSetParametersValues(key, event.target.value);
              }}
              error={
                message.text.startsWith(transformarTextToLabel(t,key)) &&
                message.text.slice("-")[0].trim() ===
                  transformarTextToLabel(t,key)
              }
              helperText={
                message.text.startsWith(transformarTextToLabel(t,key)) &&
                message.text.slice("-")[0].trim() ===
                  transformarTextToLabel(t,key) &&
                message.text
              }
              FormHelperTextProps={{ sx: form_helper_text_styles }}
              size="small"
              required={true}
            />
          </Stack>
        </Stack>
      ))}
      <Stack sx={row_center_100_styles}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.apply")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={message.text !== ""}
        />
      </Stack>
    </Stack>
  );
};

export default AdvancedParametersModal;
