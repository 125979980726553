import Stack from "@mui/material/Stack/Stack";
import VirtualizatedTable from "../../virtualizatedTable/VirtualizedTable";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import useLanguage from "../../../context/LanguageProvider";

const DashboardTable = ({ id, dataGetElement }) => {
  const { t } = useLanguage();
  const isLoadingTableResults = false;
  const dataTableResults = [
    {
      id: 1,
      MARKETING: 23.65,
      VENTAS: 23.65,
      LOGISTICA: 23.65,
      RRHH: 23.65,
    },
    {
      id: 2,
      MARKETING: 23.65,
      VENTAS: 23.65,
      LOGISTICA: 23.65,
      RRHH: 23.65,
    },
  ];
  return (
    <Stack width="100%">
      <VirtualizatedTable
        columns={
          transformDataSimpleTable(dataGetElement, undefined, undefined, t)
            .columns
        }
        rows={
          transformDataSimpleTable(dataGetElement, undefined, undefined, t)
            .rows
        }
        rowsPerPageOptions={[5, 10, 20]}
        rowHeight={35}
        tableHeight={"75vh"}
        hideFooter={true}
        toolbar={true}
        columnsButton={true}
        filterButton={true}
        exportButton={true}
        setSelectedRows={() => <></>}
        checkboxSelection={false}
      />
    </Stack>
  );
};

export default DashboardTable;
