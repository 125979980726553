import Fab from "@mui/material/Fab/Fab";
import Tooltip from "@mui/material/Tooltip";
interface CardIconProps {
  Icon: any;
  onClickAction: (e: any) => void;
  tooltipText: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}

const CardIcon = ({ onClickAction, Icon, tooltipText, backgroundColor, hoverBackgroundColor }: CardIconProps) => {
  return (
    <Tooltip title={tooltipText} placement="top">
      <Fab
        onClick={(e) => onClickAction(e)}
        sx={{
          alignSelf: "flex-end",
          width: "30px",
          height: "30px",
          backgroundColor: backgroundColor ? backgroundColor : "rgba(77,76,80, 0.25)",
          color: "rgba(23,45,64, 0.9)",
          minHeight: "auto",
          boxShadow: "none",
          zIndex: 0,
          "&:hover": {
            backgroundColor: hoverBackgroundColor ? hoverBackgroundColor : "rgba(23,45,64, 0.5)",
          },
        }}
      >
        <Icon
          sx={{
            fontSize: "18px",
            "&:hover": {
              color: "rgba(255,255,255, 0.8)",
            },
          }}
        />
      </Fab>
    </Tooltip>
  );
};

export default CardIcon;
