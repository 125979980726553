import Stack from "@mui/material/Stack";
import { useParameterization } from "../../../../hooks/useParameterization";
import BarInfiniteDrilldownChart from "../../../Charts/BarInfiniteDrilldownChart";
import ChartSkeleton from "../../../skeleton/ChartSkeleton";

const ParameterizationChart = () => {
  const { chartsResults, chartsResultsLoading } = useParameterization();

  const isChart = () => {
    if (chartsResults && chartsResults.length > 0 && !chartsResultsLoading) {
      return "loaded";
    } else if (chartsResultsLoading) {
      return "loading";
    } else if (!chartsResults || !chartsResults.length ) {
      return "noData";
    }
  };

  return (
    <Stack sx={{ width: "100%", maxWidth: "95vw !important" }}>
      {isChart() === "loaded" ? (
        chartsResults.map((chart: any) => (
          <BarInfiniteDrilldownChart
            key={chart.titulo_grafico}
            results={chart}
            tooltip={true}
          />
        ))
      ) : isChart() === "loading" ? (
        <ChartSkeleton />
      ) : isChart() === "noData" ? (
        <></>
      ) : (
        <p>
          Hubo un error al cargar los datos del gráfico. Por favor, intente
          nuevamente.
        </p>
      )}
    </Stack>
  );
};

export default ParameterizationChart;
