import { convertArrayToText } from "../../../taskManager/utils";
import { Item } from "../../../basicGrid/BasicGrid";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import Delete from "../../../icons/Delete";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
interface rowProps {
  row: any;
  marketBasketElement: any;
  setMarketBasketElement: any;
}

const MergeRow = ({
  row,
  marketBasketElement,
  setMarketBasketElement,
}: rowProps) => {
  const handleDeleteFilter = () => {
    const newRows = marketBasketElement.archivos_merge.filter((objRow: any) => {
      return !(objRow.archivo.value === row?.archivo.value);
    });
    setMarketBasketElement((prev) => ({
      ...prev,
      archivos_merge: newRows,
    }));
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Typography color={"primary"} fontSize={"11px"}>
            {row?.archivo?.label}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={7.5}>
        <Item>
          <Typography color={"primary"} fontSize={"11px"}>
            {convertArrayToText(row?.columnas?.map((col) => col?.label))}
          </Typography>
        </Item>
      </Grid>
    </>
  );
};

export default MergeRow;
