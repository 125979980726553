import { Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  card_container_styles,
  divider_styles,
  title_card_container_styles,
  title_card_typography_styles,
} from "../../styles/app-styles";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import { ReactNode } from "react";
import { truncateString } from "../parameterization/utils";

/**
 * Componente reutilizable que muestra información en una tarjeta.
 * Puede contener contenido personalizado (children) y acciones como editar, copiar, obtener información y eliminar.
 *
 * @component
 *
 * @param {CardProps} props - Las propiedades del componente.
 * @param {any} props.item - Los datos del elemento que se muestra en la tarjeta.
 * @param {(e: any, id: number) => void} props.handleEdit - Función de callback para la acción de editar.
 * @param {(e: any, id: number) => void} [props.handleCopy] - Función de callback para la acción de copiar (opcional).
 * @param {(e: any, id: number) => void} [props.handleInfo] - Función de callback para la acción de obtener información (opcional).
 * @param {(e: any, id: number) => void} [props.handleDelete] - Función de callback para la acción de eliminar (opcional).
 * @param {boolean} [props.showInfo=true] - Indica si se debe mostrar el contenido personalizado (children) en la tarjeta (opcional).
 * @param {ReactNode} props.children - Contenido personalizado que se mostrará en la tarjeta (opcional).
 *
 * @returns {JSX.Element} Un componente de tarjeta con la información proporcionada.
 */

interface CardProps {
    item: any;
    handleEdit: (e: any, id: number) => void;
    handleCopy?: (e: any, id: number) => void;
    handleInfo?: (e: any, id: number) => void;
    handleDelete?: (e: any, id: number) => void;
    showInfo?: boolean;
    //children es un componente tsx
    children: ReactNode;
}

const Card = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
  children,
}: CardProps) => {
  return (
    <Stack sx={card_container_styles}>
      <Stack sx={title_card_container_styles}>
        <Tooltip title={item.nombre}>
          <Typography sx={title_card_typography_styles}>
            {truncateString(item.nombre, 100)}
          </Typography>
        </Tooltip>
      </Stack>
      <Divider sx={divider_styles} />
      <Stack>
      {showInfo && children}
      </Stack>
      <SpeedDialTooltipOpen
        id={item.id}
        handleEdit={handleEdit}
        handleCopy={handleCopy}
        handleInfo={handleInfo}
        handleDelete={handleDelete}
      />
    </Stack>
  );
};

export default Card;
