import { CustomGridTreeDataGroupingCell } from "../../rowsTreeData";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  columnTitleStyle,
  expand_button_styles,
  useColumnStyles,
} from "../../../../styles/app-styles";

const COLUMN_HEADER_TEXT =
  "modelParameterizationTexts.getGroupingColDef.grouper";
  
export const getGroupingColDef = (
  handleOpenEditDialog: (id: number, value: string) => void,
  handleExpand: () => void,
  isExpanded: boolean,
  rows: any,
  selectedBucket: any,
  selectedStage: any
) => {
  const { t } = useLanguage();
  const classes = useColumnStyles();

  const renderExpandCollapseIcon = () =>
    isExpanded ? (
      <Tooltip
        title={t("modelParameterizationTexts.getGroupingColDef.collapse")}
      >
        <KeyboardDoubleArrowDownIcon
          sx={expand_button_styles}
          onClick={handleExpand}
        />
      </Tooltip>
    ) : (
      <Tooltip title={t("modelParameterizationTexts.getGroupingColDef.expand")}>
        <KeyboardDoubleArrowRightIcon
          sx={expand_button_styles}
          onClick={handleExpand}
        />
      </Tooltip>
    );

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: t(COLUMN_HEADER_TEXT)?.toLowerCase(),
    headerAlign: "center",
    minWidth: 290,
    renderHeader: () => (
      <>
        <Typography sx={columnTitleStyle}>{t(COLUMN_HEADER_TEXT)}:</Typography>
        {renderExpandCollapseIcon()}
      </>
    ),
    headerClassName: classes.headerColumn,
    renderCell: (params) => (
      <CustomGridTreeDataGroupingCell
        {...params}
        handleOpenEditDialog={handleOpenEditDialog}
        isExpanded={isExpanded}
        rows={rows}
        selectedBucket={selectedBucket.value}
        selectedStage={selectedStage.etapa}
      />
    ),
  };

  return groupingColDef;
};
