import { form_subtitle_styles } from "../../../../styles/app-styles";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import KeyColumnSelect from "./KeyColumnSelect";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
interface KeyColumnsProps {
  columnsSet: {
    inputLabel: string;
    columnKey: string;
    optional?: boolean;
    defaultValue?: {
      value: string;
      label: string;
    }
  }[];
  subtitle?: string;
  setKeyColumns: any;
  dataGetParams: any;
}

const KeyColumns = ({ columnsSet, subtitle, setKeyColumns, dataGetParams}: KeyColumnsProps) => {
  const { t } = useLanguage();  

  return (
    <FormSelectLayout
      title={t("companyParam.identifyKeyColumns")}
      required={true}
    >
      {subtitle && <Typography variant={"body2"} sx={form_subtitle_styles}>{subtitle}</Typography>}
      <Grid2 container direction={"column"} md={12} mt={1} gap={2}>
        {columnsSet.map((item) => {
          return (
            <Grid2 md={12} key={item.columnKey}>
              <KeyColumnSelect
                inputLabel={item.inputLabel}
                optional={item.optional}
                columnKey={item.columnKey}       
                setKeyColumns={ setKeyColumns } 
                dataGetParams={ dataGetParams }   
              />
            </Grid2>
          );
        })}
      </Grid2>
    </FormSelectLayout>
  );
};

export default KeyColumns;
