import { DataGrid } from "@mui/x-data-grid/DataGrid";
import CustomNoRowsOverlay from "./NoRows";
import CustomToolbar from "./CustomToolbar";
import Stack from "@mui/material/Stack";
interface SimpleTableProps {
  rows: any;
  columns: any;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  checkboxSelection?: boolean;
  selectedRows?: any;
  setSelectedRows?: (prev: any) => void;
  height?: string;
  headerHeight?: number;
  rowHeight?: number;
}

const SimpleTable = ({
  rows,
  columns,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  checkboxSelection,
  selectedRows,
  setSelectedRows,
  height,
  headerHeight,
  rowHeight,
}: SimpleTableProps) => {  
  const filterDisabledRows = (selectedIds) => {
    return selectedIds.filter((id) => {
      const row = rows.find((row) => row.id === id);
      return !row?.posee_errores; 
    });
  };
  return (
    <Stack style={{ height: height ? height : "500px", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: () => (
            <CustomToolbar
              toolbar={toolbar}
              columnsButton={columnsButton}
              filterButton={filterButton}
              densitySelector={densitySelector}
              exportButton={exportButton}
            />
          ),
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        hideFooter={true}
        checkboxSelection={checkboxSelection ? checkboxSelection : false}
        selectionModel={selectedRows}     
        onSelectionModelChange={(newSelectionModel) => {
          const filteredSelection = filterDisabledRows(newSelectionModel);
          setSelectedRows && setSelectedRows(filteredSelection);
        }}
        disableSelectionOnClick
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "var(--bg-secondary)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}
        headerHeight={headerHeight ? headerHeight : 50}
        rowHeight={rowHeight}
        getRowClassName={(params) =>
          params.row.posee_errores ? "row-with-errors" : ""
        }
      />
    </Stack>
  );
};

export default SimpleTable;
