
import { Button } from "../../../../../buttons";
import Dialog from "../../../../../dialog/Dialog";
import useLanguage from "../../../../../../context/LanguageProvider";
import { Stack, Typography } from "@mui/material";
import InputSlider from "../../../../InputSlider";

interface AssignRetentionsModalProps {
    open: boolean;
    handleClose: () => void;
    handleFinish: () => void;
    retention: number;
    setRetention: (value: number) => void;
}

const AssignRetentionsModal = ({ open, handleClose, handleFinish, retention, setRetention }: AssignRetentionsModalProps) => {

    const { t } = useLanguage()

    return (
        <Dialog
            open={open}
            handleClose={handleClose}
            title={`${t("simulatorTexts.canceledRoutes.assignRetentionsModal.assign")} %`}
            maxWidth="xl"
            actions={
                <>
                    <Button
                        title={t("simulatorTexts.assignVariabilityModal.cancel")}
                        color="grey"
                        type="button"
                        onClick={handleClose}
                    />
                    <Button
                        title={t("simulatorTexts.canceledRoutes.assignRetentionsModal.assign")}
                        color="blue"
                        type="button"
                        onClick={handleFinish}
                    />
                </>
            }
        >
            <Stack sx={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
            }}>
                <Stack sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    ml: 2
                }}>
                    <Typography sx={{
                        color: "var(--text-main)",
                        fontWeight: "bold",
                        mr: 1,
                        width: "100%",
                        alignItems: "right"
                    }}>
                        {t("simulatorTexts.canceledRoutes.assignRetentionsModal.salesRetentionPercentage")}:
                        :
                    </Typography>
                    <InputSlider
                        variableValue={retention}
                        setVariableValue={(newValue) => setRetention(newValue)}
                        width={"500px"}
                        nonFixedNonVariable={true}
                    />
                </Stack>
            </Stack>
        </Dialog >
    )
}

export default AssignRetentionsModal