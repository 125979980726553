import { Box, Grid, Grow, Tooltip } from "@mui/material";
import { convertUnitAllNumber } from "../utils";
import { Item } from "../../../basicGrid/BasicGrid";
import {
  kpi_cards_container_styles,
  kpi_item_box_styles,
} from "../../../../styles/app-styles";
import IndicatorCard from "./IndicatorCard";
interface CardsGroupProps {
  kpisData: any;
  size?: "small" | "medium" | "large";
}

const CardsGroup = ({ kpisData, size }: CardsGroupProps) => {
  return (
    <Box sx={kpi_cards_container_styles}>
      <Grid container spacing={3}>
        {kpisData &&
          kpisData.map((item: any, index: number) => (
            <Grid item xs={size === "large" ? 4 : 3} key={index}>
              <Grow in={true} {...{ timeout: 1000 + index * 500 }}>
                <Tooltip
                  title={convertUnitAllNumber(item.unidad, item.valor)}
                  placement="top"
                >
                  <Item
                    id="gridItem"
                    elevation={12}
                    sx={kpi_item_box_styles(item.posee_error)}
                  >                   
                      <IndicatorCard
                        key={index}
                        valor={item.valor ? item.valor : 0}
                        unidad={item.unidad}
                        kpi={item.kpi}
                        drilldown={item.drilldown}                 
                      />                   
                  </Item>
                </Tooltip>
              </Grow>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default CardsGroup;
