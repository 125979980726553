import { Paper, styled } from "@mui/material";

//Devuelve un array con lo valores de las columnas filtradas
export const filteredColumns = (columns: any) =>
  columns.filter((column) => {
    return !(column.field === "PERIODO" || column.field === "MONTO_GASTO");
  });

//Devuelve las columnas para select de columnas en el modal Modificar
export const optionsColumns: any = (columns: any) =>
  filteredColumns(columns).map((column) => {
    return {
      value: column.field,
      label: column.headerName,
    };
  });

export const noColumnsColumn = [
  {
    align: "center",
    description: "",
    field: "",
    flex: 1,
    headerAlign: "center",
    headerClassName: "es_header_column",
    headerName: "",
    minWidth: 120,
  },
];

export const transformViewData = (data: any) => {
  if (data && data.length) {
    const obj: any = {};
    data.map((columnView: any) => {
      return (obj[columnView.column] = !columnView.hide);
    });
    return obj;
  } else {
    return {};
  }
};

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export const transformDataToSend = (
  dataGetView: any,
  newVisibility: any,
  columnsPropsArray: any
) => {
  const oldVisibility = transformViewData(dataGetView);
  if (JSON.stringify(newVisibility) === "{}") {
    const newData: any = columnsPropsArray?.map((d: any) => {
      return {
        column: d.column,
        width: d.width,
        hide: false,
      };
    });
    return newData;
  } else {
    const newData: any = columnsPropsArray?.map((d: any) => {
      return {
        column: d.column,
        width: d.width,
        hide:
          newVisibility?.[d.column] === undefined
            ? oldVisibility?.[d.column] !== undefined
              ? !oldVisibility?.[d.column]
              : false
            : !newVisibility?.[d.column],
      };
    });
    return newData;
  }
};
