import { Checkbox, Tooltip } from "@mui/material";
import { Item } from "../../../basicGrid/BasicGrid";
import Delete from "../../../icons/Delete";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";
import useLanguage from "../../../../context/LanguageProvider";
interface OtherFilesRowProps {
  row: any;
  rows: any[];
  setRows: any;
}

const OtherFilesRow = ({ row, rows, setRows }: OtherFilesRowProps) => {
  
  const { t } = useLanguage()

  const handleDeleteFilter = () => {
    const newRows = [...rows];
    setRows(
      newRows.filter((r: any) => {
        return !(
          r.nombre === row?.nombre
        );
      })
    );
  };

  // const handleAutomaticImport = () => {
  //   const newRows = rows.map(r =>
  //     r.nombre === row.nombre ? { ...r, importacion: !r.importacion } : r
  //   );
  //   setRows(newRows);
  // };

  return (
    <Grid2 container direction={"row"} md={12} mt={1} gap={2} sx={{ mt: 2 }}>
      <Grid2 xs={0.6}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"row"}
          gap={2}
        >
          {/* <Tooltip title={t("companyParam.otherFilesRows.automaticImport")}>
            <Checkbox
              onClick={handleAutomaticImport}
              sx={{
                padding: "0",
                margin: "0",
              }}
              checked={row?.importacion}
            />
          </Tooltip> */}
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid2>
      <Grid2 xs={5.3}>
        <Item>
          <Typography color={"primary"}>{row?.nombre}</Typography>
        </Item>
      </Grid2>
      <Grid2 xs={5}>
        <Item>
          <Typography color={"primary"}>{row?.descripcion?.label}</Typography>
        </Item>
      </Grid2>
      <Grid2 xs={0.5}></Grid2>
    </Grid2>
  );
};

export default OtherFilesRow;
