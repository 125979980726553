import { transformData } from "./transformBucketPeriodTable";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState } from "react";
import Kpis from "../Kpis";
import NumbersTable from "../../tablesTools/NumbersTable";
import PeriodChart from "../PeriodChart";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useLanguage from "../../../context/LanguageProvider";
import {
  GET_CHARTS_RESULTS_BY_PERIOD_URL,
  GET_CHARTS_RESULTS_URL,
  GET_KPIS_URL,
  GET_PERCENTAGES_URL,
  VALIDATIONS_PRORATION_URL,
} from "../../../api/axios";

export type Point = {
  period: string;
  percentage: number;
  id: number;
};

/* Dejo comentado la funcionalidad de seleccionar periodos pero con ids para cuando este listo el back
 */
/*
export type Point = {
  id: number;
  period: string;
  percentage: number;
};
 */

const GraphicsTab = () => {
  const [point, setPoint] = useState<Point | null>(null);
  const { t } = useLanguage()
  const {
    data: periodsChartData,
    isLoading: isLoadingPeriodsChartData,
    isError: isErrorPeriodsChartData,
  } = useApiQuery(
    GET_PERCENTAGES_URL,
    false,
    t('report.getPercentages.error')
  );
  const {
    data: dataGetKpis,
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
  } = useApiQuery(GET_KPIS_URL, false, t('report.getKpis.error'));

  const {
    data: dataValidations,
    isLoading: isLoadingValidations,
    isError: isErrorValidations,
  } = useApiQuery(
    VALIDATIONS_PRORATION_URL,
    false,
    t("dataManagement.getColumnsFiles.error")
  );
  
  return (
    <>
      <SimpleBackdrop
        open={isLoadingKpis}
        message={t("reportTexts.graphicsTab.downloadingIndicators")}
      />

      {/* {!isLoadingKpis && !isErrorKpis && dataGetKpis && (
        <Kpis nombre={t("reportTexts.graphicsTab.resultsIndicators")} indicadores={dataGetKpis} />
      )} */}
      <NumbersTable
        data={dataValidations}
        isLoading={isLoadingValidations}
        isError={isErrorValidations}
        transformData={transformData}
      />
      {/* {!isLoadingPeriodsChartData &&
        !isErrorPeriodsChartData &&
        (periodsChartData.length > 0 ? (
          <Stack sx={{ width: "100%", maxWidth: "95vw !important" }}>
            <PeriodChart setPoint={setPoint} data={periodsChartData} />
          </Stack>
        ) : (
          !isLoadingKpis && <></>
        ))} */}

      {/* <Stack
        sx={{
          alignSelf: "flex-start",
          marginLeft: "42px !important",
          flexDirection: "row",
          alignItems: "center",
          height: "20px",
        }}
      >
        {point && (
          <>
            <Typography
              sx={{
                fontWeight: "600",
                color: "var(--text-main)",
                fontSize: "18px",
                marginRight: "5px",
              }}
            >
              {t("reportTexts.graphicsTab.period")}:
            </Typography>
            <Chip
              sx={{
                padding: "2px 3px 2px 1px",
                backgroundColor: "var(--bg-main)",
                color: "white",
                borderRadius: "4px",
                fontSize: "15px",
                "& .MuiChip-deleteIcon": {
                  color: "var(--bg-light)",
                },
                "& .MuiChip-deleteIcon:hover": {
                  color: "var(--icon-disabled)",
                },
              }}
              label={point?.period}
              onDelete={() => {
                setPoint(null);
              }}
            />
          </>
        )}
      </Stack> */}
      {/* {dataGetCharts &&
        dataGetCharts.length > 0 &&
        !isLoadingCharts &&
        !isErrorCharts && (
          <Stack sx={{ width: "100%", maxWidth: "95vw !important" }}>
            {dataGetCharts.map((chart: any, index: number) => (
              <BarInfiniteDrilldownChart
                key={index}
                results={chart}
                tooltip={true}
              />
            ))}
          </Stack>
        )}
      {isLoadingCharts && <ChartSkeleton />} */}
    </>
  );
};

export default GraphicsTab;
