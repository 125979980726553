import {
    Stack, Typography
  } from '@mui/material'
  import { useEffect, useState, Dispatch, SetStateAction } from 'react'
  import FormSelectLayout from '../../../../../forms/FormSelectLayout'
  import Select from "../../../../../forms/Select";
  import { selectStyles } from '../../../../../configCriteria/select.styles';
  import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
  import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
  import CustomSkeleton from '../../../../../skeleton/CustomSkeleton';
  import { IdOption, OnChange } from '../../../../../configCriteria/types';
  import { SingleValue } from 'react-select';
  import useLanguage from '../../../../../../context/LanguageProvider';
import { roundNumber } from '../../../../utils';
import InputAdjustmentRate from '../../../../InputAdjustmentRate';
  interface Step2NewRegisterRouteModalProps {
    averageVolumePerRouteAdjustment: number | string,
    setAverageVolumePerRouteAdjustment: Dispatch<
      SetStateAction<number | string>>,
    vpoState: number | string,
    setVpoState: Dispatch<
      SetStateAction<number | string>>,
    clientsPerRoute: number | string,
    setClientsPerRoute: Dispatch<
      SetStateAction<number | string>>,
    volumenProjectionModeSelectedOptions: SingleValue<IdOption> | undefined,
    volumenProjectionModeSelectedChangeHandler: OnChange,
    averageVolumePerRouteProjectedValue: number | string,
    setAverageVolumePerRouteProjectedValue: Dispatch<
      SetStateAction<number | string>>,
    vpoStateProjectedValue: number | string,
    setVpoStateProjectedValue: Dispatch<
      SetStateAction<number | string>>,
    clientsPerRouteProjectedValue: number | string,
    setClientsPerRouteProjectedValue: Dispatch<
      SetStateAction<number | string>>,
    dataGetParams: any,
    averageVolumePerRouteActualValue: number | undefined,
    vpoActualValue: number | undefined,
    clientsPerRouteActualValue: number | undefined,
    rowToEdit: any,
  }
  
  const Step2NewRegisterRouteModal = ({
    averageVolumePerRouteAdjustment,
    setAverageVolumePerRouteAdjustment,
    vpoState,
    setVpoState,
    clientsPerRoute,
    setClientsPerRoute,
    volumenProjectionModeSelectedOptions,
    volumenProjectionModeSelectedChangeHandler,
    averageVolumePerRouteProjectedValue,
    setAverageVolumePerRouteProjectedValue,
    vpoStateProjectedValue,
    setVpoStateProjectedValue,
    clientsPerRouteProjectedValue,
    setClientsPerRouteProjectedValue,
    dataGetParams,
    averageVolumePerRouteActualValue,
    vpoActualValue,
    clientsPerRouteActualValue,
    rowToEdit,
  }: Step2NewRegisterRouteModalProps) => {
  
    const { t } = useLanguage()
  
    /*************************** MANEJO DE TABLA DEL MODAL **************************/
  
    const [rows, setRows] = useState<any>([])
  
    useEffect(() => {
      if (dataGetParams) {
        const rowsData = dataGetParams.reduce((obj, item) => {
          obj[item.clave.nombre] = roundNumber(item.valor,1);
          return obj;
        }, {});
        rowsData.id = 1
        rowsData && setRows([rowsData])
      }
    }, [dataGetParams])
  
    const columns: GridColDef[] = [
      {
        field: 'volumenMensualPromedioPorRuta',
        headerName: t("simulatorTexts.registerRoutes.step2NewRouteModal.totalAverageMonthlyVolumePerRoute"),
        width: 240,
        headerClassName: "dm_header_column",
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <strong className="dm_column_title_route_log" style={{ width: 240 }}>{params.colDef.headerName}</strong>
        ),
        renderCell: (params: GridRenderCellParams) => {        
          return (
            <Typography sx={{
              display: 'flex',
              width: "100%",
              justifyContent: 'center',
              fontWeight: "600"
            }}>
              {params.row["Volumen mensual promedio Total por Ruta"] || ''} uds
            </Typography>
          )
        }
      },
      {
        field: 'vpo',
        headerName: t("simulatorTexts.registerRoutes.step2NewRouteModal.averageMonthlyVolumePerClient"),
        width: 220,
        headerAlign: 'center',
        headerClassName: "dm_header_column",
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <strong className="dm_column_title_route_log" style={{ width: 220 }}>{params.colDef.headerName}</strong>
        ),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography sx={{
              display: 'flex',
              width: "100%",
              justifyContent: 'center',
              fontWeight: "600"
            }}>
              {params.row["Volumen mensual promedio por Cliente"] || ''} uds
            </Typography>
          )
        }
      },
      {
        field: 'precioPromedio',
        headerName: t("simulatorTexts.registerRoutes.step2NewRouteModal.averagePrice"),
        width: 180,
        headerClassName: "dm_header_column",
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <strong className="dm_column_title_route_log" style={{ width: 180 }}>{params.colDef.headerName}</strong>
        ),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography sx={{
              display: 'flex',
              width: "100%",
              justifyContent: 'center',
              fontWeight: "600"
            }}>
              $ {params.row["Precio Promedio"] || ''} 
            </Typography>
          )
        }
      },
      {
        field: 'clientesPorRuta',
        headerName: t("simulatorTexts.registerRoutes.step2NewRouteModal.clientsPerRoute"),
        width: 190,
        headerClassName: "dm_header_column",
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => (
          <strong className="dm_column_title_route_log" style={{ width: 190 }}>{params.colDef.headerName}</strong>
        ),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography sx={{
              display: 'flex',
              width: "100%",
              justifyContent: 'center',
              fontWeight: "600"
            }}>
              {params.row["Clientes por Ruta"] || ''}
            </Typography>
          )
        }
      },
    ];
  
    /*************************** MANEJO DE DATOS **************************/
  
    useEffect(() => {
      if (volumenProjectionModeSelectedOptions?.label === "Volumen mensual promedio por Cliente") {
        setAverageVolumePerRouteAdjustment("")
        setAverageVolumePerRouteProjectedValue("")
      } else if (volumenProjectionModeSelectedOptions?.label === "Total Ruta") {
        setVpoState("")
        setVpoStateProjectedValue("")
      } else if (!volumenProjectionModeSelectedOptions) {
        setAverageVolumePerRouteAdjustment("")
        setAverageVolumePerRouteProjectedValue("")
        setVpoState("")
        setVpoStateProjectedValue("")
        setClientsPerRoute("")
        setClientsPerRouteProjectedValue("")
      }
    }, [volumenProjectionModeSelectedOptions])
  
    const volumenProjectionModeOptions = [
      {
        label: t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.averageMonthlyVolumePerClient"),
        value: 1
      },
      {
        label: t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.totalRoute"),
        value: 2
      },
    ]
  
    return (
      <Stack sx={{
        minWidth: "600px",
        width: "100%",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        pb: "30px"
      }}>
        <Typography sx={{
          width: "100%",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          {t("simulatorTexts.registerRoutes.step2NewRouteModal.adjustments")}
        </Typography>
        <Stack sx={{
          width: "100%",
          gap: "20px"
        }}>
          {dataGetParams && rows.length > 0 ?
            <Stack sx={{
              height: `${95 + rows.length * 50}px`, maxHeight: "800px", width: '855px'
            }}>
              <DataGrid
                rows={rows}
                columns={columns}
                hideFooter
                disableSelectionOnClick
                sx={{
                  boxShadow: 3,
                  padding: "0 10px 10px 10px",
                }}
                headerHeight={80}
                disableColumnMenu={true}
              />
            </Stack>
            :
            <Stack sx={{ width: '855px'}}>
            <CustomSkeleton height={2} />
            </Stack>
          }
          <FormSelectLayout title={t("simulatorTexts.registerRoutes.step2NewRouteModal.volumeProjectionMode")} required={true}>
            <Select
              styles={selectStyles(volumenProjectionModeSelectedOptions)}
              options={volumenProjectionModeOptions}
              className="cc_select"
              name="volumenProjectionModeToUse"
              onChange={volumenProjectionModeSelectedChangeHandler}
              closeMenuOnSelect
              placeholder={t("simulatorTexts.registerRoutes.step2NewRouteModal.chooseProjectionMode")}
              value={rowToEdit ?
                {
                  label: rowToEdit.modalidad_proyeccion === 1 ? t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.averageMonthlyVolumePerClient") : t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.totalRoute"),
                  value: rowToEdit.modalidad_proyeccion
                } : volumenProjectionModeSelectedOptions}
              defaultValue={rowToEdit ?
                {
                  label: rowToEdit.modalidad_proyeccion === 1 ? t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.averageMonthlyVolumePerClient") : t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.totalRoute"),
                  value: rowToEdit.modalidad_proyeccion
                } : volumenProjectionModeSelectedOptions}
              isClearable
            />
          </FormSelectLayout>
          <InputAdjustmentRate
            title={t("simulatorTexts.registerRoutes.step2NewRouteModal.totalAverageMonthlyVolumePerRoute")}
            actualState={averageVolumePerRouteActualValue && averageVolumePerRouteActualValue}
            adjustmentState={averageVolumePerRouteAdjustment}
            setAdjustmentState={setAverageVolumePerRouteAdjustment}
            adjustmentRate={volumenProjectionModeSelectedOptions?.label ===  t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.totalRoute")}
            projectedValue={averageVolumePerRouteProjectedValue}
            setProjectedValue={setAverageVolumePerRouteProjectedValue}
          />
          <InputAdjustmentRate
            title={"Volumen mensual promedio por Cliente (uds)"}
            actualState={vpoActualValue && vpoActualValue}
            adjustmentState={vpoState}
            setAdjustmentState={setVpoState}
            adjustmentRate={volumenProjectionModeSelectedOptions?.label === t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.averageMonthlyVolumePerClient")}
            projectedValue={vpoStateProjectedValue}
            setProjectedValue={setVpoStateProjectedValue}
          />
          <InputAdjustmentRate
            title={t("simulatorTexts.registerRoutes.step2NewRouteModal.clientsPerRoute")}
            actualState={clientsPerRouteActualValue && clientsPerRouteActualValue}
            adjustmentState={clientsPerRoute}
            setAdjustmentState={setClientsPerRoute}
            adjustmentRate={volumenProjectionModeSelectedOptions?.label === t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.averageMonthlyVolumePerClient") || volumenProjectionModeSelectedOptions?.label ===  t("simulatorTexts.registerRoutes.step2NewRouteModal.volumenProjectionModeOptions.totalRoute")}
            projectedValue={clientsPerRouteProjectedValue}
            setProjectedValue={setClientsPerRouteProjectedValue}
          />
        </Stack>
      </Stack>
    )
  }
  
  export default Step2NewRegisterRouteModal