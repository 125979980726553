import { columnsSet, stepperData } from "../data";
import { POST_PARAM_EXPENSES_FILE } from "../../../../api/axios";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import AbmBuckets from "./Abmbuckets";
import DropzoneInput from "../Shared/DropzoneInput";
import FileNameInput from "../Shared/FileNameInput";
import FormLayout from "../../../forms/FormLayout";
import KeyColumns from "../Shared/KeyColumns";
import MessageBlock from "../../../forms/MessageBlock";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadedFile from "../Shared/UploadedFile";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";
import AbmGroupers from "./AbmGroupers";

const Step1 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    expensesFileName,
    keyExpensesColumns,
    setKeyExpensesColumns,
    bucketsList,
    setBucketsList,
    setIsStepComplete,
    expensesFileNameRef,
    setExpensesFileName,
    dataFilesList,
    expensesFileToUpload,
    setExpensesFileToUpload,
    dataGetExpensesParams,
    isLoadingPostExpensesParams,
    getExpensesParams,
    postExpensesParams,
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetExpensesParams) {
      setExpensesFileName(
        dataGetExpensesParams?.nombre ? dataGetExpensesParams.nombre : "GASTOS"
      );
      setKeyExpensesColumns({
        monto_gasto: dataGetExpensesParams?.columnas_clave?.monto_gasto,
        bucket: dataGetExpensesParams?.columnas_clave?.bucket,
        agrupador: dataGetExpensesParams?.columnas_clave?.agrupador,
      });     
      setBucketsList(dataGetExpensesParams?.buckets);
    }
  }, [
    dataGetExpensesParams
  ]);

  /************************************* Carga de archivo **************************************/

  const onSuccessPostFile = () => {
    setExpensesFileToUpload([]);
    getExpensesParams();   
  };

  const { isLoading: isLoadingPostFile, callApi: postFile } = useApi(
    POST_PARAM_EXPENSES_FILE,
    "POST",
    t("companyParam.postParamFile.codes"),
    undefined,
    onSuccessPostFile,
    undefined,
    true
  );

  /*********************************** Posteo columnas clave ********************************************/

  useEffect(() => {
    if (dataGetExpensesParams) {
      postExpensesParams(undefined, {
        columnas_clave: keyExpensesColumns,
      });
    }
  }, [keyExpensesColumns.bucket, keyExpensesColumns.monto_gasto, keyExpensesColumns.agrupador]);

  /************************************* Validación step completo **********************************/

  const isStep1Complete = () => {
    if (
      expensesFileName &&
      expensesFileName !== "" &&
      dataGetExpensesParams?.archivo?.name &&
      keyExpensesColumns.monto_gasto &&
      keyExpensesColumns.bucket &&
      keyExpensesColumns.agrupador &&
      bucketsList &&
      bucketsList.length !== 0 
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isStep1Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step1: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step1: false };
      });
    }
  }, [
    expensesFileName,
    dataGetExpensesParams?.archivo?.name,
    keyExpensesColumns,
    bucketsList,
  ]);

  const isFileColumnKey =
    dataGetExpensesParams &&
    dataGetExpensesParams.archivo &&
    dataGetExpensesParams.archivo.columnas &&
    dataGetExpensesParams.archivo.columnas.length;

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostFile || isLoadingPostExpensesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[1]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles}>
        <FormLayout width="48%" padding="10px 25px 120px 25px">
          <FileNameInput
            fileNameRef={expensesFileNameRef}
            fileName={expensesFileName}
            setfileName={setExpensesFileName}
            setMessage={setMessage}
            dataFilesList={dataFilesList}
          />
          <DropzoneInput
            fileToUpload={expensesFileToUpload}
            setFileToUpload={setExpensesFileToUpload}
            postFile={postFile}
          />
          {isFileColumnKey && (
            <UploadedFile dataGetParams={dataGetExpensesParams} />
          )}
          <KeyColumns
            columnsSet={columnsSet}
            setKeyColumns={setKeyExpensesColumns}
            dataGetParams={dataGetExpensesParams}
          />
        </FormLayout>
        <FormLayout width="48%">
          <AbmBuckets />
          <AbmGroupers />
        </FormLayout>
      </Stack>
    </Stack>
  );
};

export default Step1;
