import Grid from "@mui/material/Grid";
import useLanguage from "../../../context/LanguageProvider";
import InputTitle from "../../forms/InputTitle";

const SummaryRowTitles = () => {
  const { t } = useLanguage();

  return (
    <>
      <Grid item xs={0.5}></Grid>
      <Grid item xs={3.5}>
        <InputTitle title={t("basicGrid.function")} />
      </Grid>
      <Grid item xs={3.5}>
        <InputTitle title={t("basicGrid.column")} />
      </Grid>      
      <Grid item xs={3.5}>
        <InputTitle title={t("basicGrid.name")} />
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default SummaryRowTitles;
