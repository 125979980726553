import { useState } from "react";
import { Stack } from "@mui/material";
import { Tab, TabValue } from "../../colorTabs/types";
import ColorTabs from "../../colorTabs/ColorTabs";
import PreprorrationSumTable from "./PreprorrationSumTable";
import PreprorrationErrorsTable from "./PreprorrationErrorsTable";
import useLanguage from "../../../context/LanguageProvider";

const PreprorrationReportTab = () => {
  const { t } = useLanguage();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("reportTexts.preprorrationReportTab.tabs.transferredPercentage")
  );

  const tabs: Tab[] = [
    {
      value: t("reportTexts.preprorrationReportTab.tabs.transferredPercentage"),
      label: t("reportTexts.preprorrationReportTab.tabs.transferredPercentage"),
    },
    {
      value: t("reportTexts.preprorrationReportTab.tabs.destinationsByGroup"),
      label: t("reportTexts.preprorrationReportTab.tabs.destinationsByGroup"),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  return (
    <Stack width="100%">
      <Stack style={{ height: "70vh", width: "100%" }}>
        <ColorTabs
          value={tabValue}
          handleChange={handleChange}
          tabs={tabs}
          marginBottom="15px"
          fontSize="15px"
        />
        {tabValue ===
          t("reportTexts.preprorrationReportTab.tabs.destinationsByGroup") && (
          <PreprorrationSumTable tabValue={tabValue} />
        )}
        {tabValue ===
          t(
            "reportTexts.preprorrationReportTab.tabs.transferredPercentage"
          ) && <PreprorrationErrorsTable tabValue={tabValue} />}
      </Stack>
    </Stack>
  );
};

export default PreprorrationReportTab;
