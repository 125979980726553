import { grid_container2_styles } from "../../../../styles/app-styles";
import { Message } from "../../../configCriteria/types";
import { selectStyles } from "../../../configCriteria/select.styles";
import { ITEM_TABLA_URL, TABLAS_URL } from "../../../../api/axios";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import FormLayout from "../../../forms/FormLayout";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../../../marketBasket/MarketBasketConfig/Shared/GridTitles";
import GroupingRow from "./GroupingRow";
import GroupingSelects from "./GroupingSelects";
import MessageBlock from "../../../forms/MessageBlock";
import NoLinesComponent from "../../../forms/NoLinesComponent";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import { GROUPING_FUNCTIONS } from "../../data";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

const GroupingElement = ({
  dataGetTable,
  handleToggleModal,
  element,
  getTable,
  setElementToEdit
}) => {
  const { t } = useLanguage();
  const [message, setMessage] = useState<Message>({
    type: "success",
    text: "",
  });
  const [titles, setTitles] = useState([
    t("Columnas Medida"),
    t("Función de agregación"),
  ]);
  const [rows, setRows] = useState(element?.agregaciones?.map(
    (row) => {
      return {
        columnas: row.columnas,
        funcion: GROUPING_FUNCTIONS(t).find(
          (func) => func.value === row.funcion
          ),
      };
    }
  ));
  const columnsAgrupationSelectRef: any = useRef(null);

  const [
    columnsAgrupationSelectedOptions,
    setColumnsAgrupationSelectedOptions,
    columnsAgrupationOptionsChangeHandler,
  ] = useMultiSelect(element?.columnas_agrupar? element.columnas_agrupar : undefined);

  const onSuccessPost = () => {
    getTable(`${TABLAS_URL}${dataGetTable.id}`);
    setElementToEdit(undefined);
    handleToggleModal();
  };

  const { isLoading: isLoadingPostItem, callApi: postItem } = useApi(
    `${TABLAS_URL}${dataGetTable.id}/item`, //?tipo=ANALISIS
    "POST",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPutItem, callApi: putItem } = useApi(
    ITEM_TABLA_URL(dataGetTable?.id,element?.id), //?tipo=ANALISIS
    "PUT",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const handleApply = () => {
    const completedInfo = {
      tipo: "AGRUPAR_VALORES",
      columnas_agrupar: columnsAgrupationSelectedOptions?.map(
        (col) => col.value
      ),
      agregaciones: rows.map((row: any) => {
        return {
          columnas: row?.columnas?.map((column) => column.value),
          funcion: row.funcion.value,
        };
      }),
    };
    if(element && element.id) {
      putItem(undefined, completedInfo)
    } else {
    postItem(undefined, completedInfo);
    }
  };

  const isValidApply = () => {
    return columnsAgrupationSelectedOptions && rows && rows.length > 0;
  };

  const handleCancel = () => {
    setElementToEdit(undefined);
    handleToggleModal()
  }

  return (
    <>
    <SimpleBackdrop
        open={isLoadingPostItem || isLoadingPutItem}
        message={t("general.loading")}
      />
      <FormLayout width="100%" minHeight="200px">
        <MessageBlock message={message} />
        <Grid sx={grid_container2_styles} container spacing={1}>
          <Grid item xs={12}>
            <FormSelectLayout
              title={t("Columnas de agrupación")}
              required={true}
              margin={"0px"}
            >
              <Select
                reference={columnsAgrupationSelectRef}
                styles={selectStyles(columnsAgrupationSelectedOptions)}
                options={dataGetTable?.columnas_disponibles?.agrupacion}
                onChange={columnsAgrupationOptionsChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t("general.chooseColumn")}
                isClearable
                defaultValue={columnsAgrupationSelectedOptions}
                isMulti
              />
            </FormSelectLayout>
          </Grid>
          <GridFiveTitles titles={titles} />
          <GroupingSelects
            titles={titles}
            totalColumnsOptions={dataGetTable?.columnas_disponibles?.medidas}
            rows={rows}
            setRows={setRows}
            setMessage={setMessage}
          />
          {rows && rows.length ? (
            rows.map((row: any, index: number) => (
              <GroupingRow
                key={index}
                index={index}
                row={row}
                rows={rows}
                setRows={setRows}
                titles={titles}
              />
            ))
          ) : (
            <NoLinesComponent height={"150px"} />
          )}
        </Grid>
      </FormLayout>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!isValidApply()}
        />
      </Stack>
    </>
  );
};
export default GroupingElement;
