import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";
interface SchemaGridProps {
  info: any;
  RowTitles: any;
  RowSelects: any;
  RowInfo: any;
  columns?: any;
  columnSelectRef?: any;
  columnSelectedOption?: any;
  columnOptionsChangeHandler?: any;
  functionSelectRef?: any;
  functionSelectedOption?: any;
  functionOptionsChangeHandler?: any;
  handleAddGroupByRow?: any;
  handleDeleteAllRows?: any;
  groupByRows?: any;
  setGroupByRows?: any;
}

const InfoGrid = ({
  info,
  RowTitles,
  RowSelects,
  RowInfo,
  columns,
  columnSelectRef,
  columnSelectedOption,
  columnOptionsChangeHandler,
  functionSelectRef,
  functionSelectedOption,
  functionOptionsChangeHandler,
  handleAddGroupByRow,
  handleDeleteAllRows,
  groupByRows,
  setGroupByRows,
}: SchemaGridProps) => {
  const { t } = useLanguage();  

  return (
    <Stack
      sx={{
        marginLeft: "0px",
      }}
      alignItems={"center"}
      width="100%"
    >
      <Grid
        sx={{
          marginLeft: "0px",
        }}
        container
        spacing={2}
      >
        <RowTitles />
        <RowSelects       
          columns={columns}
          columnSelectRef={columnSelectRef}
          columnSelectedOption={columnSelectedOption}     
          columnOptionsChangeHandler={columnOptionsChangeHandler}
          functionSelectRef={functionSelectRef}
          functionSelectedOption={functionSelectedOption}
          functionOptionsChangeHandler={functionOptionsChangeHandler}
          handleAddGroupByRow={handleAddGroupByRow}
          handleDeleteAllRows={handleDeleteAllRows}
          groupByRows={groupByRows}
        />
        {info && info.length ? (
          info.map((row: any, index: number) => (
            <RowInfo
              key={index}
              row={row}
              groupByRows={groupByRows}
              setGroupByRows={setGroupByRows}
            />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--text-info)" }}
            >
              {t("basicGrid.noLinesLabel")}
            </Typography>
          </Box>
        )}
      </Grid>
    </Stack>
  );
};

export default InfoGrid;
