import Box from "@mui/material/Box";
import useLanguage from "../../../context/LanguageProvider";
import {
  info_modal_subtitle_key_styles,
  info_modal_subtitle_styles,
} from "../../../styles/app-styles";

const SharedElements = ({indicator}) => {
  const { t } = useLanguage();
  
  return <>
    <Box sx={info_modal_subtitle_styles}>
      <Box sx={info_modal_subtitle_key_styles}>
        {t("indicatorsManagementTexts.viewInfoModal.group") + ": "}
      </Box>
      {indicator?.grupo ? indicator?.grupo?.label : "Otros"}
    </Box>
    <Box sx={info_modal_subtitle_styles}>
      <Box sx={info_modal_subtitle_key_styles}>
        {t("indicatorsManagementTexts.viewInfoModal.baseFile") + ": "}
      </Box>
      {indicator?.archivo?.label}
    </Box>
  </>;
};

export default SharedElements;
