import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useLanguage from "../../../../context/LanguageProvider";
import { useParameterization } from "../../../../hooks/useParameterization";
import { reset_button_item_styles } from "../../../../styles/app-styles";

interface CalculateExpensesoptionsI {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  handleSelection: (type: string) => void;
}

const CalculateExpensesOptions = ({
  anchorEl,
  open,
  handleClose,
  handleSelection,
}: CalculateExpensesoptionsI) => {
  const { t } = useLanguage();
  const { parameterizationState } =
    useParameterization();
  const { selectedStage } = parameterizationState;
  const texts = {
    currentStageCalculation: t(
      "modelParameterizationTexts.resetButton.currentStageCalculation"
    ),
    allCalculation: t("modelParameterizationTexts.resetButton.allCalculation"),
    calculationStageBucket: t(
      "modelParameterizationTexts.resetButton.calculationStageBucket"
    ),
    allCalculationBucketStages: t(
      "modelParameterizationTexts.resetButton.allCalculationBucketStages"
    ),
    resetCurrentStageCalculation: t(
      "modelParameterizationTexts.resetButton.resetCurrentStageCalculation"
    ),
    resetAllCalculation: t(
      "modelParameterizationTexts.resetButton.resetAllCalculation"
    ),
    resetCalculationStageBucket: t(
      "modelParameterizationTexts.resetButton.resetCalculationStageBucket"
    ),
    resetAllCalculationStages: t(
      "modelParameterizationTexts.resetButton.resetAllCalculationStages"
    ),
  };

  return (
    <Menu
      id="basic-menu"
      className="checkBoxMiniSelect"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={
          selectedStage && selectedStage.etapa !== 0
            ? () => handleSelection(texts.currentStageCalculation)
            : () => <></>
        }
        sx={reset_button_item_styles(selectedStage)}
      >
        {texts.resetCurrentStageCalculation}
      </MenuItem>
      <MenuItem onClick={() => handleSelection(texts.allCalculation)}>
        {texts.resetAllCalculation}
      </MenuItem>
      <MenuItem onClick={() => handleSelection(texts.calculationStageBucket)}>
        {texts.resetCalculationStageBucket}
      </MenuItem>
      <MenuItem
        onClick={() => handleSelection(texts.allCalculationBucketStages)}
      >
        {texts.resetAllCalculationStages}
      </MenuItem>
    </Menu>
  );
};

export default CalculateExpensesOptions;
