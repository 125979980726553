import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "../dialog/Dialog";
import SimpleTable from "../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";
import useLanguage from "../../context/LanguageProvider";

interface ViewInfoModal {
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  title: string | undefined;
  data: any;
}

const TableModal = ({ open, handleClose, title, data }: ViewInfoModal) => {
  const { t } = useLanguage();

  return (
    <div>
      <Dialog open={open} handleClose={handleClose} title={title} maxWidth="xl">
        <Stack sx={{ width: "800px" }}>
          <SimpleTable
            columns={
              transformDataSimpleTable(data, undefined, undefined, t).columns
            }
            rows={transformDataSimpleTable(data, undefined, undefined, t).rows}
            toolbar={true}
            exportButton={true}
          />
        </Stack>
      </Dialog>
    </div>
  );
};

export default TableModal;
