import { Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import Dialog from "./Dialog";
import useLanguage from "../../context/LanguageProvider";

interface ConfirmationModalProps {
  open: boolean;
  customMessage?: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  message: string;
  title: string;
  children?: React.ReactNode;
  disabledAcceptButton?: boolean;
  greyButtonText?: string;
}

const ConfirmationModal = ({
  open,
  handleClose,
  handleAccept,
  message,
  title,
  customMessage,
  children,
  disabledAcceptButton,
  greyButtonText
}: ConfirmationModalProps) => {
  const {t } = useLanguage();
  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="xl" title={title}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "var(--text-main)",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          {customMessage ? message : t("dialog.cofirmThat") + message + "?"}
        </Typography>
        {children}
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title={greyButtonText ? greyButtonText : t("dialog.cancel")}
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title={t("dialog.accept")}
            type="button"
            onClick={handleAccept}
            color="blue-greeny"
            disabled={disabledAcceptButton}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmationModal;
