import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "../../../../dialog/Dialog";
import WhiteShadowBox from "../../../../layout/WhiteShadowBox";
import { tranformArrayWithNombreToString } from "../../../utils";
import CustomizedTable from "../../../../customizedTable/CustomizedTable";
import useLanguage from "../../../../../context/LanguageProvider";
interface ViewInfoProps {
  info: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  title: string | undefined;
  isModelPageModal?: boolean;
}

const ViewInfoExpensesModal = ({
  info,
  open,
  handleClose,
  title,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const COLUMNS1 = ["bucket", "agrupadores"];
  const ROWS1 = info?.bucket_agrupador?.map((item: any) => {
    return {
      bucket: item.bucket,
      agrupadores: tranformArrayWithNombreToString(item.agrupadores), // usar nombre
    };
  });

  return (
    <div>
      <Dialog open={open} handleClose={handleClose} title={title}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding="0 100px 30px 100px"
          position="relative"
        >
          <WhiteShadowBox>
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {t('dataModelText.measureManagement.viewInfoCompoundsModal.generalInfoMeasure')}
              </Typography>

              {ROWS1?.length && (                            
                  <CustomizedTable
                    COLUMNS={COLUMNS1}
                    ROWS={ROWS1}
                    width={450}
                  />             
              )}
            </>
          </WhiteShadowBox>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoExpensesModal;
