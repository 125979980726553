import React from 'react'
import Tooltip from "@mui/material/Tooltip";
import useLanguage from '../../../context/LanguageProvider';
import { criteria_buttons_tooltip_styles } from '../../../styles/app-styles';
import { Stack } from '@mui/material';
import Button from "@mui/material/Button";

interface RuleButtunsProps {
    selectionModel: any;
    handleToggleAssignDriver: any;
    handleToggleDeleteDriverModal: any;
    selectionModelHasDrivers
}

const RuleButtons = ({ selectionModel, handleToggleAssignDriver, handleToggleDeleteDriverModal, selectionModelHasDrivers }: RuleButtunsProps) => {

    const { t } = useLanguage()

    const RenderDeleteButton = ({ children }) => {
        return (!selectionModelHasDrivers) || (selectionModel && selectionModel.length === 0) ? (
            <Tooltip
                placement="top-start"
                title={
                    t("deltaTexts.simulationRules.ruleButtons.noRowsWithDriverToDelete")
                }
                sx={criteria_buttons_tooltip_styles}
            >
                <div>{children}</div>
            </Tooltip>
        ) : (children);
    };

    const RenderAssignDriverButton = ({ children }) => {
        return selectionModel && selectionModel.length === 0 ? (
            <Tooltip
                placement="top"
                title={t("modelParameterizationTexts.customToolbar.noRowsSelected")}
                sx={criteria_buttons_tooltip_styles}
            >
                <div>{children}</div>
            </Tooltip>
        ) : (children);
    };


    return (
        <Stack sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
            spacing: 1,
        }}>
            <RenderDeleteButton>
                <Button
                    variant="text"
                    onClick={
                        () => (!selectionModelHasDrivers) || (selectionModel && selectionModel.length < 1) ? <> </> : handleToggleDeleteDriverModal()
                    }
                    sx={{
                        cursor: (!selectionModelHasDrivers) || (selectionModel && selectionModel.length < 1) ?  "default" :"pointer",
                        alignItems: "center",
                        background: "transparent",
                        color: (!selectionModelHasDrivers) || (selectionModel && selectionModel.length < 1) ? "var(--middle-grey)" : "white",
                        display: "flex",
                        fontSize: "14px",
                        fontWeight: "bold",
                        justifyContent: "center",
                        ":hover": {
                            color: (!selectionModelHasDrivers) || (selectionModel && selectionModel.length < 1) ? "var(--middle-grey)" : "#F90D4A",
                        },
                    }}
                >
                    {t("deltaTexts.simulationRules.ruleButtons.deleteDrivers")}
                </Button>
            </RenderDeleteButton>
            <RenderAssignDriverButton>
                <Button
                    variant="text"
                    onClick={
                        () => selectionModel && selectionModel.length < 1 ? <> </> : handleToggleAssignDriver()
                    }
                    sx={{
                        cursor: selectionModel && selectionModel.length < 1 ?  "default" :"pointer",
                        alignItems: "center",
                        background: "transparent",
                        color: selectionModel && selectionModel.length < 1 ? "var(--middle-grey)" : "white",
                        display: "flex",
                        fontSize: "14px",
                        fontWeight: "bold",
                        justifyContent: "center",
                        ":hover": {
                            color: selectionModel && selectionModel.length < 1 ? "var(--middle-grey)" : "#F90D4A",
                        },
                    }}
                >
                    {t("deltaTexts.simulationRules.ruleButtons.assignDrivers")}
                </Button>
            </RenderAssignDriverButton >
        </Stack >
    )
}

export default RuleButtons