import useLanguage from "../../../context/LanguageProvider"
import ComponentLayout from "../../layout/ComponentLayout";
import SearchInput from "../../forms/SearchInput";
import React, { useEffect, useState } from "react";
import BasicGrid from "../../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import FixedAddButton from "../../buttons/FixedAddButton";
import useDialog from "../../../hooks/useDialog";
import { ConfirmationModal } from "../../dialog";
import useInfoContext from "../../../hooks/useInfoContext";
import SalesForecastCard from "./SalesForecastCard";
import { Button } from "../../buttons";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { GET_SALES_FORECAST_URL, SALES_FORECAST_ID_URL } from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import NewSalesForecastModal from "./NewSalesForecastModal";
import useApi from "../../../hooks/useApi";
import ViewInfoSalesForecastModal from "./ViewInfoSalesForecastModal";
import { filterDataByName } from "../utils";
import InfoIcon from "@mui/icons-material/Info";

const SalesForecastAdmin = () => {

    const { userRolData }: any = useInfoContext()
    const { t } = useLanguage()
    const [searchValue, setSearchValue] = useState<string>("");
    const [filteredData, setFilteredData] = useState<any>();
    const [openAddSalesForecastModal, toggleAddSalesForecastModal] = useDialog();
    const [openViewInfoSalesForecastModal, toggleViewInfoSalesForecastModal] = useDialog();
    const [forecastToDelete, setForecastToDelete] = useState<number>();
    const [openDeleteForecastConfirmationModal, handleToggleDeleteForecastConfirmationModal] = useDialog();
    const [dataToShow, setDataToShow] = useState<any>();

    /********************** CARGA Y OBTENCIÓN DE DATOS *****************************/

    useEffect(() => {
        localStorage.removeItem("simulationId")
        localStorage.removeItem("salesForecastId")
        localStorage.removeItem("analysisId")
        localStorage.removeItem("newElementConfig")
        localStorage.removeItem("salesForecastView")
        localStorage.removeItem("simulationView")
        localStorage.removeItem("tabValueEndpoint")
        localStorage.removeItem("previousUrl")
    })

    const {
        isLoading: isLoadingGetAnalysis,
        data: dataGetAnalysis,
    } = useApiQuery("/simulador/sales_forecast/analisis/", true, t("dataModel.getAnalysis.error"));

    const {
        isLoading: isLoadingGetSalesForecasts,
        data: dataGetSalesForecast,
        refetch: refetchSalesForecast,
    } = useApiQuery(GET_SALES_FORECAST_URL, true, t("dataModel.getAnalysis.error"));

    const { isLoading: isLoadingDeleteSalesForecast, callApi: deleteSalesForecastById } =
        useApi(
            SALES_FORECAST_ID_URL(forecastToDelete ? forecastToDelete : undefined),
            "DELETE",
            t("delta.deleteSalesForecast.codes"),
            undefined,
            refetchSalesForecast
        );

    const onSuccessGetSalesForecastById = (data: any) => {
        setDataToShow(data)
    };

    const {
        isLoading: isLoadingGetSalesForecastById,
        callApi: getSalesForecastById,
    } = useApi(
        undefined,
        "GET",
        undefined,
        undefined,
        onSuccessGetSalesForecastById,
        undefined,
        false
    );

    const onSuccessGetSalesForecastByIdEdit = (data: any) => {
        setDataToShow(data)
        toggleAddSalesForecastModal()
    };

    const {
        isLoading: isLoadingGetSalesForecastByIdEdit,
        callApi: getSalesForecastByIdEdit,
    } = useApi(
        undefined,
        "GET",
        undefined,
        undefined,
        onSuccessGetSalesForecastByIdEdit,
        undefined,
        false
    );

    /****************************** MANEJO DE FUNCIONES ******************************/

    const handleToggleViewInfoSalesForecastModal = () => {
        toggleViewInfoSalesForecastModal()
    }

    const handleCreate = () => {
        toggleAddSalesForecastModal()
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        filterDataByName(dataGetSalesForecast, e.target.value, setFilteredData);
    };

    const handleEdit = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        getSalesForecastByIdEdit(SALES_FORECAST_ID_URL(id) + "?only_forecast=true")
        localStorage.setItem("configMode", "EDIT")
    };

    // const handleCopy = (e: React.ChangeEvent<HTMLInputElement>, id: number | undefined) => {
    //     localStorage.setItem("configMode", "COPY");
    // };

    const handleInfo = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        getSalesForecastById(SALES_FORECAST_ID_URL(id))
        handleToggleViewInfoSalesForecastModal()
    };

    const handleClickOnDelete = (e: any, id: number) => {
        setForecastToDelete(id)
        handleToggleDeleteForecastConfirmationModal();
    };

    const handleDelete = () => {
        deleteSalesForecastById()
        handleToggleDeleteForecastConfirmationModal()
    };

    return (
        <ComponentLayout title={t("deltaTexts.salesForecast.title")} icon={<SmartDisplayIcon />}>
            <SimpleBackdrop
                open={isLoadingGetSalesForecastById || isLoadingGetAnalysis || isLoadingGetSalesForecasts || isLoadingDeleteSalesForecast || isLoadingGetSalesForecastByIdEdit}
                message={t("general.loading")}
            />
            {
                dataGetSalesForecast && dataGetSalesForecast.length > 0 &&
                <>
                    <Stack spacing={2} sx={{ width: "100%" }}>
                        <SearchInput
                            value={searchValue}
                            handleChange={handleSearch}
                            placeholder={t("simulatorTexts.simulationsRulesAdmin.searchByName")}
                        />
                    </Stack>
                    {userRolData && (userRolData.simulador.visualizacion_y_edicion || userRolData.simulador.visualizacion_y_creacion) &&
                        <FixedAddButton handleClick={handleCreate} />}
                </>
            }
            {filteredData?.length || dataGetSalesForecast?.length ? (
                <>
                    <BasicGrid
                        data={filteredData ? filteredData : dataGetSalesForecast}
                        Card={SalesForecastCard}
                        refetchData={refetchSalesForecast}
                        handleEdit={userRolData && userRolData.simulador.visualizacion_y_edicion && handleEdit}
                        // handleCopy={handleCopy}
                        handleInfo={handleInfo}
                        handleDelete={userRolData && userRolData.simulador.visualizacion_y_edicion && handleClickOnDelete}
                        showInfo={true}
                        xs={2}
                    />
                </>
            ) : (
                true && (
                    <Box
                        sx={{
                            width: "95%",
                            height: "80vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "700", color: "var(--text-info)", textAlign: "center" }}
                        >
                            {t("deltaTexts.salesForecast.salesForecastAdmin.noPredictionsYet")}<br />
                            {t("deltaTexts.salesForecast.salesForecastAdmin.startCreatingOne")}
                        </Typography>
                        <Button
                            title={
                                <Typography sx={{
                                    fontSize: "15px",
                                    ml: 0.5,
                                    fontWeight: "bold"
                                }}>
                                    {t("deltaTexts.salesForecast.salesForecastAdmin.newSalesForecast")}
                                </Typography>
                            }
                            width={250}
                            color="blue-greeny"
                            type="button"
                            onClick={handleCreate}
                        />
                    </Box>
                )
            )}
            {dataToShow &&
                <ViewInfoSalesForecastModal
                    data={dataToShow}
                    open={openViewInfoSalesForecastModal}
                    handleClose={toggleViewInfoSalesForecastModal}
                />
            }
            <NewSalesForecastModal
                open={openAddSalesForecastModal}
                handleClose={handleCreate}
                dataGetAnalysis={dataGetAnalysis}
                dataToShow={dataToShow}
                refetchSalesForecast={refetchSalesForecast}
                setDataToShow={setDataToShow}
                dataGetSalesForecast={dataGetSalesForecast}
            />
            <ConfirmationModal
                open={openDeleteForecastConfirmationModal}
                handleClose={handleToggleDeleteForecastConfirmationModal}
                handleAccept={handleDelete}
                message={t("deltaTexts.salesForecast.salesForecastAdmin.deleteSalesForecast")}
                title={t("general.confirm")}
            >
                <Stack
                    sx={{
                        width: "100%",
                        flexDirection: "row",
                        gap: "10px",
                    }}
                >
                    <InfoIcon color="primary" fontSize="medium" />
                    <Typography
                        style={{ color: "var(--text-main)", fontWeight: "600" }}
                    >
                        {t("deltaTexts.salesForecast.salesForecastAdmin.deleteAssociatedSimulationsAndRules")}
                    </Typography>
                </Stack>
            </ConfirmationModal>
        </ComponentLayout>
    )
}

export default SalesForecastAdmin