import { chip_filters_container } from "../../../../styles/app-styles";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import Chip from "@mui/material/Chip/Chip";
import Stack from "@mui/material/Stack";
interface ChipFilterProps {
  margin?: string;
}

const ChipFilters = ({ margin }: ChipFilterProps) => {
  const { filters, setFilters, tempFilters, setTempFilters } =
    useMarketBasketProvider();

  const handleDeleteFilter = (e, filterToDelete) => {
    const updatedTempFilters = tempFilters?.filter(
      (filter) => filter.column.label !== filterToDelete.column.label
    );
    setTempFilters(updatedTempFilters);
    const filter = {} as any;
    updatedTempFilters?.forEach((option: any) => {
      filter[option.column.label] = option.values.map((value) => value.value);
    });
    setFilters(filter);
  };

  const transformFiltersToArray = () => {
    const filtersArray = [] as any;
    Object.keys(filters).forEach((key) => {
      filtersArray.push({
        column: {
          label: key,
          value: tempFilters.find((filter) => filter.column.label === key)
            ?.column.value,
        },
        values: filters[key].map((value: any) => ({
          label: value,
          value: value,
        })),
      });
    });
    return filtersArray;
  };

  const labelsArray = (filter) => filter.values.map((value) => value.label)

  return (
    <Stack sx={chip_filters_container(margin)}>
      {filters &&
        transformFiltersToArray()?.map((filter) => {
          return (
            labelsArray(filter)?.length !== 0 && (
              <Chip
                key={filter.column.label}
                label={`${filter.column.label}: ${labelsArray(filter).join(", ")}`}
                onDelete={(e) => handleDeleteFilter(e, filter)}
              />
            )
          );
        })}
    </Stack>
  );
};

export default ChipFilters;
