import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

/**
 * @name transformViewModificationTable
 * @description Función que transforma los datos de la tabla virtualizada. Se usa DataGrid Pro de Material-UI.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformViewModificationTable = (
    dataColumnas: any,
    dataFilas: any,
) => {
    //Saco la columna _id para que no se renderice
    const columnsWithoutId = Object && Object?.keys(dataColumnas[0])?.filter(
        (key) => key !== "_id"
    );

    const arrayColumns = columnsWithoutId?.map((columna) => ({
        field: columna,
        headerClassName: "es_header_column",
        headerName: columna,
        description: columna,
        width: 180,
        renderHeader: (params: GridColumnHeaderParams) => (
            <strong className="es_column_title">
                {params.field}
            </strong>
        ),
        renderCell: (params: any) => (
            <Tooltip
                disableFocusListener
                disableTouchListener
                title={`${params.value}`}
            >
                <p className="es_cellValue">
                    {params.value}
                </p>
            </Tooltip>
        ),
    }));

    const arrayRows = dataFilas.map((fila: any) => {
        const obj: any = {};
        obj["id"] = fila._id;
        for (const key in fila) {
            if (key !== "_id") {
                obj[key] = fila[key];
            }
        }
        return obj;
    });

    const columns: GridColDef[] = arrayColumns.map((col) => ({
        ...col,
        headerAlign: "center",
        align: "center",
    }));

    const rows = arrayRows.map((row: any) => ({
        ...row,
    }));

    return { columns, rows };
};
