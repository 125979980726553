import { GridColDef } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import {
  backgroundColorByFlag,
  flag,
  fontColorByFlag,
  formatValue,
} from "./utils";
import {
  header_report_table_styles,
  rendercell_container_report_table_styles,
  rendercell_report_table_styles,
} from "../../../styles/app-styles";

const transformBucketPeriodTable = (data: any, onNumberClick?: any) => {
  const arrayColumns =
    data &&
    Object.keys(data[0])
      .filter((column: string) => column !== "id")
      .map((column: any) => ({
        field: column,
        headerName: column,
        minWidth: column === "PERIODO" ? 100 : 150,
        overflow: "visible",
        resize: true,
        flex: column === "PERIODO" ? 0.4 : 1,
        renderHeader: (params: any) => (
          <Stack sx={header_report_table_styles}>{params.field}</Stack>
        ),
        renderCell: (params: any) => (
          <Stack sx={rendercell_container_report_table_styles}>
            <Stack
              sx={rendercell_report_table_styles(
                fontColorByFlag(flag(params.value)),
                backgroundColorByFlag(flag(params.value)),
                onNumberClick ? "pointer" : ""
              )}
              onClick={
                onNumberClick
                  ? () => onNumberClick(params.value, params.field, params.row)
                  : undefined
              }
            >
              {formatValue(params.value)}
            </Stack>
          </Stack>
        ),
      }));

  /**
   * @name columns
   * @description Genera el array de todas las columnas que se renderizarán en la tabla, y es el valor de retorno del componente para las columnas.
   * @memberof BucketPerioTable
   * @category BucketPeriodTable
   */
  const columns: GridColDef[] = arrayColumns?.map((col: any) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  /**
   * @name rows
   * @description Genera el array de todas las filas que se renderizarán en la tabla, y es el valor de retorno del componente para las filas.
   * @memberof BucketPerioTable
   * @category BucketPeriodTable
   */

  const rows = data?.map((row: any) => ({
    ...row,
  }));
  
  return { columns, rows };
};

export const transformData = transformBucketPeriodTable;
