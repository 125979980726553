import { Box, Button, Stack, Tooltip } from "@mui/material";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Select from "../../../forms/Select";
import { selectStyles } from "../../../configCriteria/select.styles";
import {
  columnOptions,
  fileOptions,
} from "../../../configCriteria/selectOptions";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { GET_CRITERIA_FILE_COLUMN_URL } from "../../../../api/axios";
import { useContext, useEffect, useRef, useState } from "react";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import {
  IdOnChange,
  IdOption,
  IsMulti,
  OnChangeValue,
} from "../../../configCriteria/types";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import GroupersProvider from "../../../../context/GroupersProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../../../dialog";
import useDialog from "../../../../hooks/useDialog";
import MergeModal from "./MergeModal";
import { useSnackbar, VariantType } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Base } from "../../../../context/types";
import useLanguage from "../../../../context/LanguageProvider";

interface NewBaseFileProps {
  base?: any;
  openMerge?: boolean;
}

const NewBaseFile = ({ base, openMerge }: NewBaseFileProps) => {
  const { t } = useLanguage();
  const fileSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const [
    fileSelectedOptions,
    setFileSelectedOptions,
    fileOptionsChangeHandler,
  ] = useSingleSelect(() => onFileColumn(), base ? base.archivo : undefined);

  const [selectedFile, setSelectedFile] = useState(fileSelectedOptions);
  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useMultiSelect(base ? base.columnas : undefined);

  const [selectedColumns, setSelectedColumns] = useState(columnSelectedOptions);
  const { groupersState, groupersDispatch } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const { bases, step2 } = groupersState;
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [openMergeDialog, toggleMergeDialog] = useDialog();
  const [openEditFileConfirmationModal, handleToggleEditFileConfirmationLocal] =
    useDialog();
  const [actionToChange, setActionToChange] = useState<ActionMeta<IdOption>>();
  const [fileToChange, setFileToChange] = useState<SingleValue<IdOption>>();
  const [columnsToChange, setColumnsToChange] =
    useState<MultiValue<IdOption>>();
  const [
    openEditColumnConfirmationModal,
    handleToggleEditColumnsConfirmationLocal,
  ] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Vacia el select de columnas cuando se borra o cambia el archivo
  const onFileColumn = () => {
    if (
      columnSelectedOptions?.length !== 0 &&
      columnSelectedOptions !== undefined
    ) {
      setColumnSelectedOptions(undefined);
      setSelectedColumns(undefined);
      columnSelectRef.current.clearValue();
    }
  };

  // Chequea si en el step 2 se utiliza el archivo que acabamos de cambiar y en caso de que si, abre una alerta antes de editar el archivo, caso contrario edita el archivo.
  const onFileChange = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (
      step2?.archivo?.value !== undefined ||
      (step2?.columnasAUsar.length &&
        (step2?.archivo?.value === base?.archivo?.value ||
          step2?.columnasAUsar?.some((columna: any) => {
            return columna.file_id === base?.archivo.value;
          })))
    ) {
      setActionToChange(actionMeta);
      setFileToChange(newValue);
      handleToggleEditFileConfirmationLocal();
    } else {
      fileOptionsChangeHandler(newValue, actionMeta);
      setSelectedFile(newValue);
      setFileSelectedOptions(newValue);
    }
  };

  // Se encarga de resetear el step 2 si se cambia el archivo utilizado
  const handleAcceptEditFileConfirmationModal = () => {
    handleToggleEditFileConfirmationLocal();
    if (actionToChange) {
      fileOptionsChangeHandler(
        fileToChange as SingleValue<IdOption>,
        actionToChange
      );
      setSelectedFile(fileToChange);
      setFileSelectedOptions(fileToChange);
      groupersDispatch({
        type: "SET_STEP_2",
        payload: { archivo: undefined, columna: undefined, columnasAUsar: [] },
      });
    }
  };

  // Chequea si en el step 2 se utiliza la columna que acabamos de cambiar y en caso de que si, abre una alerta antes de editar la columna, caso contrario edita la columna.
  const onColumnsChange = (
    newValue: MultiValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (step2?.columna?.value !== undefined && step2?.columnasAUsar.length) {
      if (actionMeta.action === "remove-value") {
        if (
          step2?.columna?.value === actionMeta.removedValue.value ||
          step2?.columnasAUsar.some((columna: any) => {
            return columna.value === actionMeta.removedValue.value;
          })
        ) {
          setActionToChange(actionMeta);
          setColumnsToChange(newValue);
          handleToggleEditColumnsConfirmationLocal();
        } else {
          handleEditBaseColumns(newValue, actionMeta);
          setColumnSelectedOptions(newValue);
        }
      }
      if (actionMeta.action === "clear") {
        if (
          actionMeta.removedValues.some((column: any) => {
            return column.value === step2?.columna?.value;
          }) ||
          step2?.columnasAUsar.some((columna: any) => {
            return actionMeta.removedValues.some((c: any) => {
              c.value === columna.value;
            });
          })
        ) {
          setActionToChange(actionMeta);
          setColumnsToChange(newValue);
          handleToggleEditColumnsConfirmationLocal();
        } else {
          handleEditBaseColumns(newValue, actionMeta);
          setColumnSelectedOptions(newValue);
        }
      } else {
        handleEditBaseColumns(newValue, actionMeta);
        setColumnSelectedOptions(newValue);
      }
    } else {
      handleEditBaseColumns(newValue, actionMeta);
      setColumnSelectedOptions(newValue);
    }
  };

  // Se encarga de resetear el step 2 si se cambian las columnas
  const handleAcceptEditColumnConfirmationModal = () => {
    handleToggleEditColumnsConfirmationLocal();
    if (actionToChange) {
      handleEditBaseColumns(
        columnsToChange as MultiValue<IdOption>,
        actionToChange
      );
      setColumnSelectedOptions(columnsToChange);
      groupersDispatch({
        type: "SET_STEP_2",
        payload: {
          archivo: step2.archivo,
          columna: undefined,
          columnasAUsar: [],
        },
      });
    }
  };

  useEffect(() => {
    if (base) {
      setColumnSelectedOptions(base.columnas);
    }
  }, [base?.columnas]);

  const { data: dataGetFileColumn } = useApiQuery(
    GET_CRITERIA_FILE_COLUMN_URL,
    false,
    t("configCriteria.getCriteriaFileColumn.error")
  );

  const handleEditBaseColumns: IdOnChange = (
    newValue: OnChangeValue<IdOption, IsMulti>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    // Disparamos el evento del cambio de columna
    columnOptionsChangeHandler(newValue, actionMeta);
    setSelectedColumns(newValue);
    //Chequeamos si es que unas de las columnas eliminadas (en caso de que se haya eliminado una), era utilizada en algun merge, y en caso de que si, seteamos a la base que la utilizaba en error:true pra que sea reconfigurada
    const basesWithError = checkIfColumnIsUsedInMerge(actionMeta);
    //Mostramos la snackbar con el error
    if (basesWithError && basesWithError.length) {
      showError("error");
    }
    // Armamos el array de las nuevas bases seteando las que tienen error
    const actualBases =
      basesWithError && basesWithError.length > 0
        ? bases.map((b) => {
            if (findBaseById(b.id, basesWithError)) {
              return findBaseById(b.id, basesWithError);
            } else {
              return b;
            }
          })
        : bases;
    if (bases.length > 0 && base !== undefined) {
      groupersDispatch({
        type: "SET_BASES",
        payload: actualBases.map((b: any) =>
          b.id === base.id
            ? {
                archivo: fileSelectedOptions,
                columnas: newValue,
                id: base.id,
                error: base.error,
                merge: base.merge,
              }
            : b
        ),
      });
    } else {
      if (bases.length === 0) {
        // Si aun no tenemos ninguna base porque estamos agregando la primera, la seteamos generandole el id
        groupersDispatch({
          type: "SET_BASES",
          payload: [
            {
              archivo: fileSelectedOptions,
              columnas: newValue,
              error: false,
              id: Math.random(),
            },
          ],
        });
      } else {
        groupersDispatch({
          type: "SET_BASES",
          payload: [
            {
              archivo: fileSelectedOptions,
              columnas: newValue,
              error: false,
              id: bases[0].id,
            },
            ...actualBases.filter((b: any) => b.id !== bases[0].id),
          ],
        });
      }
    }
  };

  const handleDelete = () => {
    toggleConfirmationModal();
    const index = bases.findIndex((element) => element.id === base.id);
    groupersDispatch({
      type: "SET_BASES",
      payload: bases.slice(0, index),
    });
    const deletedBases = bases.slice(index);
    if (
      step2?.archivo?.value !== undefined &&
      deletedBases.some((base: Base) => {
        return base.archivo?.value === step2.archivo.value;
      })
    ) {
      groupersDispatch({
        type: "SET_STEP_2",
        payload: { archivo: undefined, columna: undefined, columnasAUsar: [] },
      });
    } else if (
      step2?.columnasAUsar?.length > 0 &&
      step2.columnasAUsar.some((column: any) => {
        return deletedBases.some((base: Base) => {
          return base.archivo?.value === column.file_id;
        });
      })
    ) {
      groupersDispatch({
        type: "SET_STEP_2",
        payload: { ...step2, columnasAUsar: [] },
      });
    }
  };

  // VALIDACIONES FORMULARIO

  //Esta función chequea si la columna eliminida se utiliza en alguna base y en caso de que si, devuelve la base con el error en true
  const checkIfColumnIsUsedInMerge = (actionMeta: ActionMeta<IdOption>) => {
    if (actionMeta.action === "remove-value") {
      const basesWithRemovedColumn = bases.filter((base: any) => {
        return base.merge?.columnas_base_anterior.some((column: any) => {
          return column.value === actionMeta.removedValue.value;
        });
      });
      if (basesWithRemovedColumn.length > 0) {
        return basesWithRemovedColumn.map((base: any) => {
          return {
            ...base,
            error: true,
            merge: {
              ...base.merge,
              columnas_base_anterior: base.merge.columnas_base_anterior.filter(
                (column: any) => {
                  return column.value !== actionMeta.removedValue.value;
                }
              ),
            },
          };
        });
      } else {
        return basesWithRemovedColumn;
      }
    } else if (actionMeta.action === "clear") {
      const basesWithRemovedColumn = bases.filter((b: any) => {
        return b.merge?.columnas_base_anterior.some((column: any) => {
          return base?.columnas.some((columna: any) => {
            return columna.value === column.value;
          });
        });
      });
      return basesWithRemovedColumn.map((base: any) => {
        return {
          ...base,
          error: true,
          merge: {
            ...base.merge,
            columnas_base_anterior: base.merge.columnas_base_anterior.filter(
              (column: any) => {
                return base?.columnas.some((columna: any) => {
                  return columna.value === column.value;
                });
              }
            ),
          },
        };
      });
    }
  };

  const findBaseById = (id, bases) => bases.find((obj) => obj.id === id);

  //Esta función muestra el snackbar con el error
  const showError = (variant: VariantType) => {
    enqueueSnackbar(
      t("dataModelText.groupManagement.step1.newBaseFile.columnBeingUsed"),
      {
        variant,
        action: (key) => (
          <IconButton
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        ),
      }
    );
  };

  return (
    <Box
      sx={{
        margin: "10px 0px",
        marginTop: "0px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "20px",
        boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
        padding: "18px 30px",
        backgroundColor: base?.error ? "var(--background-error)" : "white",
      }}
    >
      <Box sx={{ width: "37%", marginRight: "50px" }}>
        <FormSelectLayout
          title={t("dataModelText.groupManagement.step1.newBaseFile.file")}
          required={true}
        >
          <Select
            reference={fileSelectRef}
            value={selectedFile}
            styles={selectStyles(fileSelectedOptions)}
            options={fileOptions(dataGetFileColumn)}
            className="cc_select"
            name="ArchivoBase"
            onChange={onFileChange}
            closeMenuOnSelect
            placeholder={t(
              "dataModelText.groupManagement.step1.newBaseFile.chooseFile"
            )}
            defaultValue={fileSelectedOptions}
            isClearable
          />
        </FormSelectLayout>
      </Box>
      {!openMergeDialog && (
        <Box sx={{ width: "37%" }}>
          <FormSelectLayout
            title={t("dataModelText.groupManagement.step1.newBaseFile.columns")}
            required={true}
          >
            <Select
              value={selectedColumns}
              isMulti={true}
              reference={columnSelectRef}
              styles={selectStyles(columnSelectedOptions)}
              options={columnOptions(dataGetFileColumn, fileSelectedOptions)}
              closeMenuOnSelect={false}
              className="cc_select"
              name="ColumnaBase"
              onChange={onColumnsChange}
              placeholder={t(
                "dataModelText.groupManagement.step1.newBaseFile.chooseColumns"
              )}
              defaultValue={base ? base.columnas : columnSelectedOptions}
              isClearable
            />
          </FormSelectLayout>
        </Box>
      )}

      {openMerge !== undefined && (
        <>
          <Button
            onClick={toggleMergeDialog}
            sx={{
              color: "var(--text-main)",
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "none",
              marginLeft: "auto",
              marginTop: "10px",
            }}
          >
            Merge
          </Button>

          <Stack
            sx={{ marginLeft: "auto", marginRight: "30px", marginTop: "5px" }}
          >
            <Tooltip
              title={t(
                "dataModelText.groupManagement.step1.newBaseFile.removeBase"
              )}
            >
              <DeleteIcon
                onClick={toggleConfirmationModal}
                sx={{
                  color: "var(--icon-info) !important",
                  cursor: "pointer",
                  fontSize: "30px",
                  transition: "all 1s linear",
                  "& :hover": { color: "var(--icon-error)" },
                }}
              />
            </Tooltip>
          </Stack>
          <MergeModal
            open={openMergeDialog}
            handleClose={toggleMergeDialog}
            base={base}
          />
        </>
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleAccept={handleDelete}
        title={t("dataModelText.groupManagement.step1.newBaseFile.removeBase")}
        message={t(
          "dataModelText.groupManagement.step1.newBaseFile.confirmRemoveBase"
        )}
        customMessage={true}
      />
      <ConfirmationModal
        open={openEditFileConfirmationModal}
        handleClose={handleToggleEditFileConfirmationLocal}
        handleAccept={handleAcceptEditFileConfirmationModal}
        message={t(
          "dataModelText.groupManagement.step1.newBaseFile.confirmEditFiles"
        )}
        customMessage
        title={t(
          "dataModelText.groupManagement.step1.newBaseFile.editBaseColumns"
        )}
      />
      <ConfirmationModal
        open={openEditColumnConfirmationModal}
        handleClose={handleToggleEditColumnsConfirmationLocal}
        handleAccept={handleAcceptEditColumnConfirmationModal}
        message={t(
          "dataModelText.groupManagement.step1.newBaseFile.confirmEditColumns"
        )}
        customMessage
        title={t(
          "dataModelText.groupManagement.step1.newBaseFile.editBaseColumns"
        )}
      />
    </Box>
  );
};

export default NewBaseFile;
