import { form_label } from "../../../styles/app-styles";
import { selectStyles } from "../../configCriteria/select.styles";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CheckboxOption from "../../forms/CheckboxOption";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormSelectLayout from "../../forms/FormSelectLayout";
import React from "react";
import Select from "../../forms/Select";
import ShadowBox from "../groupManagement/ShadowBox";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";

const Dedynamicize = ({
  defaultInfo,
  desdinamizar,
  setDesdinamizar,
  selectedIndexColumns,
  selectedDesdynamizeColumns,
  indexColumnsRef,
  desdynamizeColumnsRef,
  indexColumnsOptions,
  indexColumnsOptionsChangeHandler,
  desdynamizeColumnsOptions,
  desdynamizeColumnsOptionsChangeHandler,
  setSelectedIndexColumnsOptions,
  setSelectedDesdynamizeColumns,
  setUpdatedColumns,
}) => {
  const { t } = useLanguage();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const toggleDesdinamizar = (e) => {
    e.stopPropagation();
    if (desdinamizar) {
      setSelectedIndexColumnsOptions([]);
      setSelectedDesdynamizeColumns([]);
      indexColumnsRef.current.clearValue();
      desdynamizeColumnsRef.current.clearValue();
      setUpdatedColumns([]);
    } else {
      setExpanded("panel1");
    }
    setDesdinamizar(!desdinamizar);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        width: "100%",
        m: 0,
        ...form_label,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panel1bh-header"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: 0,
        }}
      >
        <span style={{ width: "100%", color: "var(--text-main)" }}>
          <div onClick={(e) => e.stopPropagation()}>
            <CheckboxOption
              toggleCheckbox={(e) => {
                e.stopPropagation();
                toggleDesdinamizar(e);
              }}
              checked={desdinamizar}
              labelText={"Desdinamizar"}
              id={"Desdinamizar"}
              labelStyles={{ ...form_label, marginLeft: "8px" }}
              marginBottom={"0"}
              marginTop={"0"}
            />
          </div>
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <ShadowBox>
          <Stack
            direction="row"
            width="100%"
            justifyContent={"space-between"}
            gap={2}
          >
            <Box width="100%">
              <FormSelectLayout title={t("Columnas índice")} required={true}>
                <Select
                  reference={indexColumnsRef}
                  styles={selectStyles(selectedIndexColumns)}
                  options={indexColumnsOptions}
                  onChange={indexColumnsOptionsChangeHandler}
                  closeMenuOnSelect={false}
                  placeholder={t("general.selectOption")}
                  isClearable
                  isDisabled={desdinamizar ? false : true}
                  defaultValue={
                    defaultInfo &&
                    defaultInfo.desdinamizar &&
                    defaultInfo.desdinamizar.columnas_indices &&
                    defaultInfo.desdinamizar.columnas_indices.length > 0
                      ? defaultInfo.desdinamizar.columnas_indices
                      : undefined
                  }
                  isMulti
                />
              </FormSelectLayout>
            </Box>
            <Box width="100%">
              <FormSelectLayout
                title={t("Columnas a desdinamizar")}
                required={true}
              >
                <Select
                  reference={desdynamizeColumnsRef}
                  styles={selectStyles(selectedDesdynamizeColumns)}
                  options={desdynamizeColumnsOptions}
                  onChange={desdynamizeColumnsOptionsChangeHandler}
                  closeMenuOnSelect={false}
                  placeholder={t("general.selectOption")}
                  isClearable
                  isDisabled={desdinamizar ? false : true}
                  defaultValue={
                    defaultInfo &&
                    defaultInfo.desdinamizar &&
                    defaultInfo.desdinamizar.columnas_indices &&
                    defaultInfo.desdinamizar.columnas_indices.length > 0
                      ? defaultInfo.desdinamizar.columnas_desdinamizar
                      : undefined
                  }
                  isMulti
                />
              </FormSelectLayout>
            </Box>
          </Stack>
        </ShadowBox>
      </AccordionDetails>
    </Accordion>
  );
};

export default Dedynamicize;
