import { columnTitleStyle, useColumnStyles } from "../../../../styles/app-styles";
import { getColumnTitle } from "../../utils";
import { GridColumns, DataGridProProps } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import CustomModelCell from "./CustomModelCell";
import useLanguage from "../../../../context/LanguageProvider";

export const getModelColumns = (
  rows: any,
  selectedStage: any, 
  handleCopyCriteria: any,
  setRowCopyCriteria: any,
  tabValue?: any
) => {
  const { t } = useLanguage();
  const classes = useColumnStyles();
  const columnsNames: string[] =
    tabValue ===
    t("modelParameterizationTexts.calculationTabValue.nonTransferredExpenses")
      ? selectedStage.etapa !== 0
        ? [
            t("columns.cause_error"),
            t("columns.previous_destination"),
            t("columns.destination_receiver"),
            t("columns.destination"),
            t("columns.mount"),
            t("columns.expenses_transferred"),
            t("columns.receiver_criteria"),
            t("columns.apportionment_criteria"),
          ]
        : [
            t("columns.cause_error"),
            t("columns.previous_destination"),
            t("columns.destination_receiver"),
            t("columns.destination"),
            t("columns.mount"),
            t("columns.expenses_transferred"),
          ]
      : selectedStage.etapa !== 0
      ? [
          t("columns.previous_destination"),
          t("columns.destination_receiver"),
          t("columns.destination"),
          t("columns.mount"),
          t("columns.receiver_criteria"),
          t("columns.apportionment_criteria"),
        ]
      : [
          t("columns.previous_destination"),
          t("columns.destination_receiver"),
          t("columns.destination"),
          t("columns.mount"),
        ];

  const getFlexValue = (column: string) => {
    if (
      column === t("columns.destination_receiver") ||
      column === t("columns.previous_destination") ||
      column === t("columns.destination")
    ) {
      return 1.2;
    }
    if (column === t("columns.apportionment_criteria")) {
      return 1.5;
    }
    return 1;
  };

  const columns: GridColumns = columnsNames.map((column: any) => {
    return {
      field: column,
      headerClassName: classes.headerColumn,
      renderHeader: () => (
        <Typography sx={columnTitleStyle}>{getColumnTitle(column)}</Typography>
      ),
      flex: getFlexValue(column),
      headerAlign: "center",
      minWidth: 150,
      align: "center",
      wrapText: true,
      renderCell: (params: any) => (
        <CustomModelCell
          {...params}
          rows={rows}
          column={column}         
          handleCopyCriteria={handleCopyCriteria}
          setRowCopyCriteria={setRowCopyCriteria}
        />
      ),
    };
  });

  return columns;
};

export const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
  row.hierarchy;
