import { convertArrayToTextWithoutComas } from "../../taskManager/utils";
import CustomizedTable from "../../customizedTable/CustomizedTable";
import Dialog from "../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import WhiteShadowBox from "../../layout/WhiteShadowBox";
import {
  info_modal_container_styles,
  info_modal_subtitle_styles,
  info_modal_subtitle_value_styles,
  info_modal_title_styles,
  info_section_container_styles,
} from "../../../styles/app-styles";
import Box from "@mui/material/Box";
interface ViewInfoProps {
  info: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  title: string | undefined;
  isModelPageModal?: boolean;
}

const ViewInfoMarketBasketModal = ({
  info,
  open,
  handleClose,
  title,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const COLUMNS = [
    "soporte minimo apriori",
    "soporte minimo conjunto",
    "soporte minimo antecedente",
    "soporte minimo consecuente",
    "confianza minima",
    "lift minimo",
  ];
  const objetivosArray = info?.parametros_avanzados
    ? [info?.parametros_avanzados]
    : [];
  const ROWS = objetivosArray?.map((col: any) => {
    return {
      "soporte minimo apriori": col.soporte_minimo_apriori,
      "soporte minimo conjunto": col.soporte_minimo_conjunto,
      "soporte minimo antecedente": col.soporte_minimo_antecedente,
      "soporte minimo consecuente": col.soporte_minimo_consecuente,
      "confianza minima": col.confianza_minima,
      "lift minimo": col.lift_minimo,
    };
  });
  const COLUMNS0 = [
    "coleccion objetivos",
    "columna valor objetivo",
    "identificador dueño objetivo",
    "columna objetivo analisis",
    "identificador dueño objetivo analisis",
  ];
  const ROWS0 = info?.objetivos.map((col: any) => {
    return {
      "coleccion objetivos": col.coleccion_objetivos.label,
      "columna valor objetivo": col.columna_valor_objetivo.label,
      "identificador dueño objetivo": col.identificador_dueño_objetivo.label,
      "columna objetivo analisis": col.columna_objetivo_analisis.label,
      "identificador dueño objetivo analisis":
        col.identificador_dueño_objetivo_analisis.label,
    };
  });
  const COLUMNS1 = ["nombre", "tipo", "funcion", "indicador", "variable"];
  const ROWS1 = info?.graficas.map((grafica: any) => {
    return {
      nombre: grafica.nombre,
      tipo: grafica.tipo,
      funcion: grafica.funcion.label,
      indicador: grafica?.indicador ? grafica.indicador.label : "-",
      variable: grafica?.variable ? grafica.variable.label : "-",
    };
  });

  const COLUMNS2 = [
    "nombre",
    "funcion",
    "columna",
    "columna divisora",
    "consolidar periodos",
  ];
  const ROWS2 = info?.metricas_SKU_primarias.map((metrica: any) => {
    return {
      nombre: metrica.nombre,
      funcion: metrica.funcion.label,
      columna: metrica.columna.label,
      "columna divisora": metrica?.columna_divisora
        ? metrica.columna_divisora.label
        : "-",
      "consolidar periodos": metrica.consolidar_periodos.label,
    };
  });

  const COLUMNS3 = ["nombre", "formula"];
  const ROWS3 = info?.metricas_SKU_secundarias.map((metrica: any) => {
    return {
      nombre: metrica.nombre,
      formula: convertArrayToTextWithoutComas(
        metrica.formula.map((f: any) => f.dato)
      ),
    };
  });

  const COLUMNS4 = [
    "nombre",
    "funcion",
    "columna",
    "columna divisora",
    "consolidar destinos",
    "consolidar periodos",
  ];
  const ROWS4 = info?.metricas_destino_primarias.map((metrica: any) => {
    return {
      nombre: metrica.nombre,
      funcion: metrica.funcion.label,
      columna: metrica.columna.label,
      "columna divisora": metrica?.columna_divisora
        ? metrica.columna_divisora.label
        : "-",
      "consolidar destinos": metrica.consolidar_destinos.label,
      "consolidar periodos": metrica.consolidar_periodos.label,
    };
  });

  const COLUMNS5 = ["nombre", "formula"];
  const ROWS5 = info?.metricas_destino_secundarias.map((metrica: any) => {
    return {
      nombre: metrica.nombre,
      formula: convertArrayToTextWithoutComas(
        metrica.formula.map((f: any) => f.dato)
      ),
    };
  });

  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t("marketBasket.admin.viewInfoModal.title")}
        maxWidth="md"
      >
        <Stack sx={info_modal_container_styles}>
          <WhiteShadowBox>
            <Typography variant="subtitle1" sx={info_modal_title_styles}>
              {title}
            </Typography>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("general.file")}:
              </span>{" "}
              {info?.archivo?.label}
            </p>
            {info?.archivos_merge && info?.archivos_merge.length > 0 && (
              <p style={info_modal_subtitle_styles}>
                <span style={info_modal_subtitle_value_styles}>
                  {t("marketBasket.admin.viewInfoModal.mergeFiles")}:
                </span>{" "}
                {info?.archivos_merge?.map(
                  (archivo: any, index: number) =>
                    `${archivo.archivo.label}${
                      index < info.archivos_merge.length - 1 ? ", " : ""
                    }`
                )}
              </p>
            )}
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.channel")}:
              </span>{" "}
              {info?.canal?.label}
            </p>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.skuColumn")}:
              </span>{" "}
              {info?.columna_SKU?.label}
            </p>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.currencySales")}:
              </span>{" "}
              {info?.venta_moneda?.label}
            </p>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.unitSales")}:
              </span>{" "}
              {info?.venta_unidades?.label}
            </p>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.brand")}:
              </span>{" "}
              {info?.marca?.label}
            </p>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.analysisLevel")}:
              </span>{" "}
              {info?.nivel_de_analisis?.label} ({info?.tipo_nivel_de_analisis})
            </p>
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.periodicity")}:
              </span>{" "}
              {info?.periodicidad}
            </p>
            {info?.columna_transaccion && (
              <p style={info_modal_subtitle_styles}>
                <span style={info_modal_subtitle_value_styles}>
                  {t("marketBasket.admin.viewInfoModal.transactionColumn")}:
                </span>{" "}
                {info?.columna_transaccion?.label}
              </p>
            )}
            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.extraColumns")}:
              </span>{" "}
              {info?.columnas_extra.map(
                (columna: any, index: number) =>
                  `${columna.label}${
                    index < info.columnas_extra.length - 1 ? ", " : ""
                  }`
              )}
            </p>
            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.advancedParameters")}:
              </span>{" "}
              {ROWS?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable COLUMNS={COLUMNS} ROWS={ROWS} width={750} />
                </Stack>
              ) : (
                " -"
              )}
            </Box>
            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.objectives")}:
              </span>

              {ROWS0?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable
                    COLUMNS={COLUMNS0}
                    ROWS={ROWS0}
                    width={750}
                  />
                </Stack>
              ) : (
                " -"
              )}
            </Box>

            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.primarySkuMetrics")}:
              </span>{" "}
              {ROWS2?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable
                    COLUMNS={COLUMNS2}
                    ROWS={ROWS2}
                    width={750}
                  />
                </Stack>
              ) : (
                " -"
              )}
            </Box>
            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.secondarySkuMetrics")}:
              </span>{" "}
              {ROWS3?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable
                    COLUMNS={COLUMNS3}
                    ROWS={ROWS3}
                    width={750}
                  />
                </Stack>
              ) : (
                " -"
              )}
            </Box>

            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.primaryDestinyMetrics")}:
              </span>{" "}
              {ROWS4?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable
                    COLUMNS={COLUMNS4}
                    ROWS={ROWS4}
                    width={750}
                  />
                </Stack>
              ) : (
                " -"
              )}
            </Box>
            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.secondaryDestinyMetrics")}:
              </span>{" "}
              {ROWS5?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable
                    COLUMNS={COLUMNS5}
                    ROWS={ROWS5}
                    width={750}
                  />
                </Stack>
              ) : (
                " -"
              )}
            </Box>
            <Box style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t("marketBasket.admin.viewInfoModal.charts")}:
              </span>{" "}
              {ROWS1?.length > 0 ? (
                <Stack sx={info_section_container_styles}>
                  <CustomizedTable
                    COLUMNS={COLUMNS1}
                    ROWS={ROWS1}
                    width={750}
                  />
                </Stack>
              ) : (
                " -"
              )}
            </Box>
            {info?.indicadores_destino.length > 0 ? (
              <p style={info_modal_subtitle_styles}>
                <span style={info_modal_subtitle_value_styles}>
                  {t("marketBasket.admin.viewInfoModal.destinyIndicators")}:
                </span>{" "}
                {info?.indicadores_destino.map(
                  (ind: any, index: number) =>
                    `${ind.nombre}${
                      index < info.indicadores_destino.length - 1 ? ", " : ""
                    }`
                )}
              </p>
            ) : (
              " -"
            )}
          </WhiteShadowBox>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoMarketBasketModal;
