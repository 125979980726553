import { Stack, Typography } from "@mui/material";
import { Button } from "../../buttons";
import Dialog from "../../dialog/Dialog";
import useLanguage from "../../../context/LanguageProvider";
interface NotAllowedToAddFileModalProps {
  open: boolean;
  handleClose: () => void;
}

const NotAllowedToAddFileModal = ({
  open,
  handleClose,
}: NotAllowedToAddFileModalProps) => {
  const { t } = useLanguage();
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title={t("dataManagementTexts.notAllowedToAddFileModal.cannotAddName")}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "var(--text-main)",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          {t(
            "dataManagementTexts.notAllowedToAddFileModal.notPossibleExpensesName"
          )}
        </Typography>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title={t("dataManagementTexts.notAllowedToAddFileModal.accept")}
            type="button"
            onClick={handleClose}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default NotAllowedToAddFileModal;
