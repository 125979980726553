import "../../../tablesTools/styles.css";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Dialog from "../../../dialog/Dialog";
import InfoModal from "./InfoModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla. Debe recibir la data con las key como columnas, o bien se puede enviar un array con las columnas ya predefinidas.
 * @param data
 */
export const transformDataStep5: (
  data: any,
  openInfoModal: boolean,
  handleToggleInfoModal: any,
  openInfoModal2: boolean,
  handleToggleInfoModal2: any,
  transformColumnNames?: any,
  t?: any,
  minWidth?: number
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  data: any,
  openInfoModal: boolean,
  handleToggleInfoModal: any,
  openInfoModal2: boolean,
  handleToggleInfoModal2: any,
  transformColumnNames?: any,
  t?: any,
  minWidth?: number
) => {
  const tableColumns = data && data.length > 0 ? Object.keys(data[0]) : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: minWidth ? minWidth : 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack
          direction={"row"}
          sx={{
            color: "white",
            textTransform: "uppercase",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          {transformColumnNames ? transformColumnNames(columna) : columna}
          <InfoOutlinedIcon
            sx={{ fontSize: "1.1rem" }}
            onClick={
              columna.trim().toLowerCase() === "metrica"
                ? handleToggleInfoModal
                : handleToggleInfoModal2
            }
          />
          {columna.trim().toLowerCase() === "metrica" ? (
            <Dialog
              open={openInfoModal}
              handleClose={handleToggleInfoModal}
              title={"Métrica"}
              maxWidth={"sm"}
            >
              <Stack sx={{ minHeight: "200px" }}>
                <InfoModal
                  handleToggleModal={handleToggleInfoModal}
                  column={columna}
                />
              </Stack>
            </Dialog>
          ) : (
            <Dialog
              open={openInfoModal2}
              handleClose={handleToggleInfoModal2}
              title={t('marketBasket.config.monthlyEvolutionDrilldown')}
              maxWidth={"sm"}
            >
              <Stack sx={{ minHeight: "200px" }}>
                <InfoModal
                  handleToggleModal={handleToggleInfoModal2}
                  column={columna}
                />
              </Stack>
            </Dialog>
          )}
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack direction="row" alignItems="center" justifyContent={"flex-start"} width={"90%"}>
          {params.value}
        </Stack>
      ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
