import { SingleValue } from "react-select";
import { IdOption } from "../../configCriteria/types";

export const transformarTextToLabel = (t, text: string) => {
  switch (text) {
    case "soporte_minimo_apriori":
      return t("marketBasket.config.minimumSupportApriori");
    case "soporte_minimo_conjunto":
      return t("marketBasket.config.minimumSupportSet");
    case "soporte_minimo_antecedente":
      return t("marketBasket.config.minimumSupportAntecedent");
    case "soporte_minimo_consecuente":
      return t("marketBasket.config.minimumSupportConsequent");
    case "confianza_minima":
      return t("marketBasket.config.minimumConfidence");
    case "lift_minimo":
      return t("marketBasket.config.minimumLift");
    default:
      return "";
  }
};

export const handleElementRepeatedNameWithObject = (
  t,
  name,
  elementsList,
  elementId,
  configMode,
  setMessage
) => {
  if (elementsList && name) {
    const elementInfo = elementsList?.find((an: any) => an.id === elementId);
    const elementNameExists = elementsList?.find(
      (an: any) =>
        an?.nombre?.trim().toLowerCase() === name?.trim()?.toLowerCase()
    );
    if (
      elementNameExists &&
      (configMode === "CREATE" || configMode === "COPY")
    ) {
      setMessage({ type: "error", text: t("general.repeatedName") });
    } else if (
      elementNameExists &&
      configMode === "EDIT" &&
      elementInfo?.nombre?.trim().toLowerCase() !== name?.trim().toLowerCase()
    ) {
      setMessage({ type: "error", text: t("general.repeatedName") });
    } else {
      setMessage({ type: "", text: "" });
    }
  }
};

export const formatObjectiveRow = (
  columna1: SingleValue<IdOption> | undefined,
  columna2: SingleValue<IdOption> | undefined,
  columna3: SingleValue<IdOption> | undefined,
  columna4: SingleValue<IdOption> | undefined,
  columna5: SingleValue<IdOption> | undefined
) => {
  return {
    coleccion_objetivos: columna1,
    columna_valor_objetivo: columna2,
    identificador_dueño_objetivo: columna3,
    columna_objetivo_analisis: columna4,
    identificador_dueño_objetivo_analisis: columna5,
  };
};

export const validateObjectiveRow = (
  t,
  column1: SingleValue<IdOption> | undefined,
  column2: SingleValue<IdOption> | undefined,
  column3: SingleValue<IdOption> | undefined,
  column4: SingleValue<IdOption> | undefined,
  column5: SingleValue<IdOption> | undefined,
  objectiveRows: any[],
  setMessage: any
) => {
  let result = false;
  if (column1 && column2 && column3 && column4 && column5) {
    const repeatedRows =
      objectiveRows !== undefined && objectiveRows.length > 0
        ? objectiveRows?.filter((row) => {
            return (
              row.coleccion_objetivos.value === column1?.value &&
              row.columna_valor_objetivo.value === column2?.value &&
              row.identificador_dueño_objetivo.value === column3?.value &&
              row.columna_objetivo_analisis.value === column4?.value &&
              row.identificador_dueño_objetivo_analisis.value === column5?.value
            );
          })
        : [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage({
        type: "error",
        text: t("general.repeatedRowsError"),
      });
    } else {
      result = true;
    }
  } else {
    setMessage({ type: "error", text: t("general.emptyInputs") });
  }

  return result;
};

export const formatPrimaryMetricRow = (
  columna1: SingleValue<IdOption> | undefined,
  columna2: SingleValue<IdOption> | undefined,
  columna3: SingleValue<IdOption> | undefined,
  columna4: SingleValue<IdOption> | undefined,
  columna5: SingleValue<IdOption> | undefined,
  columna6?: SingleValue<IdOption> | undefined
) => {
  return columna6
    ? {
        nombre: columna1,
        funcion: columna2,
        columna: columna3,
        columna_divisora: columna4,
        consolidar_periodos: columna5,
        consolidar_destinos: columna6,
      }
    : {
        nombre: columna1,
        funcion: columna2,
        columna: columna3,
        columna_divisora: columna4,
        consolidar_periodos: columna5,
      };
};

export const isRepeatedName = (name: string, rows: any[]) => {
  return !!rows?.find((row) => {
    return row.nombre.trim().toLowerCase() === name.trim().toLowerCase();
  });
};

export const validatePrimaryMetricRow = (
  t,
  rows: any[],
  setMessage: any,
  titles: string[],
  column1: string,
  column2: SingleValue<IdOption> | undefined,
  column3: SingleValue<IdOption> | undefined,
  column4: SingleValue<IdOption> | undefined,
  column5: SingleValue<IdOption> | undefined,
  column6?: SingleValue<IdOption> | undefined,
  totalRows?: any[]
) => {
  const titlesQuantity = titles.length;
  let result = false;
  if (column1 !== "" && totalRows && totalRows.length > 0) {
    const repeatedName = isRepeatedName(column1, totalRows);
    if (repeatedName) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
      return false;
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  }
  if (column1 !== "" && column2 && column3 && column5) {
    if (titlesQuantity === 6 && !column6) {
      setMessage({
        type: "error",
        text: t("general.emptyInputs"),
      });
      result = false;
      return false;
    } else {
      if (column3.value === "DIVIDIR" && !column4) {
        setMessage({
          type: "error",
          text: t("general.requiredDividingColumn"),
        });
        result = false;
        return false;
      }
    }
    const repeatedName = isRepeatedName(column1, rows);
    const repeatedRows =
      rows !== undefined && rows.length > 0 && titlesQuantity === 5
        ? rows?.filter((row) => {
            return (
              row.funcion.value === column2?.value &&
              row.columna.value === column3?.value &&
              row?.columna_divisora?.value === column4?.value &&
              row.consolidar_periodos.value === column5?.value
            );
          })
        : rows !== undefined && rows.length > 0 && titlesQuantity === 6
        ? rows?.filter((row) => {
            return (
              row.funcion.value === column2?.value &&
              row.columna.value === column3?.value &&
              row?.columna_divisora?.value === column4?.value &&
              row.consolidar_periodos.value === column5?.value &&
              row.consolidar_destinos.value === column6?.value
            );
          })
        : [];
    if (repeatedName) {
      setMessage({
        type: "error",
        text: t("general.repeatedRowsName"),
      });
      return false;
    }
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage({
        type: "error",
        text: t("general.repeatedRowsError"),
      });
    } else {
      result = true;
    }
  } else {
    setMessage({ type: "error", text: t("general.emptyInputs") });
  }
  return result;
};

export const handleSetElementInLast = (
  formula,
  setMedidaInLast,
  setDotOperator,
  setOperatorInLast
) => {
  const lastElement = formula[formula.length - 1];

  if (lastElement && lastElement.dato === ")") {
    setMedidaInLast(true);
  } else if (
    lastElement &&
    (lastElement.tipo === "INDICADOR_PRIMARIO" ||
      lastElement.tipo === "INDICADOR_SECUNDARIO")
  ) {
    setMedidaInLast(true);
  } else {
    setMedidaInLast(false);
  }
  if (lastElement?.dato?.toString()?.endsWith(".")) {
    setDotOperator(true);
  } else {
    setDotOperator(false);
  }
  if (
    lastElement &&
    lastElement.tipo === "OPERADOR" &&
    lastElement.dato != ")" &&
    lastElement.dato != "("
  ) {
    setOperatorInLast(true);
  } else {
    setOperatorInLast(false);
  }
};

export const checkRepeatedName = (t, elements, name: string, setMessage) => {
  const repeated = elements?.find(
    (element) =>
      element.nombre.trim().toLowerCase() === name.trim().toLowerCase()
  );
  if (repeated) {
    setMessage({
      text: t("general.repeatedName"),
      type: "error",
    });
  } else {
    setMessage({
      text: "",
      type: "",
    });
  }
};

export const deleteDuplicatedElements = (array) => {
  const uniqueValues = {};
  const uniqueArray: any[] = [];

  array.forEach((item: any) => {
    // Si el valor no está en uniqueValues, se añade al objeto y al array único
    if (!uniqueValues[item.value]) {
      uniqueValues[item.value] = true;
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

export const isElementInUse = (row, metricasArray) => {
  let elementInUse = false;
  metricasArray?.forEach((metrica) => {
    if (metrica.formula) {
      metrica.formula.forEach((element) => {
        if (element.dato === row.nombre) {
          elementInUse = true;
        }
      });
    }
  });
  return elementInUse;
};
