import AnalyticsIcon from "@mui/icons-material/Analytics";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalculateIcon from "@mui/icons-material/Calculate";
import ConstructionIcon from "@mui/icons-material/Construction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import IsoIcon from "@mui/icons-material/Iso";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import MonitorIcon from "@mui/icons-material/Monitor";
import RuleIcon from "@mui/icons-material/Rule";
import SchemaIcon from "@mui/icons-material/Schema";
import SettingsIcon from "@mui/icons-material/Settings";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import SpeedIcon from "@mui/icons-material/Speed";
import StraightenIcon from "@mui/icons-material/Straighten";
import TableChartIcon from "@mui/icons-material/TableChart";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import DownloadIcon from "@mui/icons-material/Download";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import AddchartIcon from "@mui/icons-material/Addchart";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";

export const isOptionSelected = (page: string, t: any) => {
  if (page === t("menuItemsOptions.companyParameterization.title") &&
    location.pathname === "/" + t("menuItemsOptions.companyParameterization.url")) {
    return true
  }
  if (
    page === t("menuItemsOptions.dataManagement.title") &&
    location.pathname === "/" + t("menuItemsOptions.dataManagement.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.validations.title") &&
    (location.pathname ===
      "/" + t("menuItemsOptions.validations.adminValidations.url") ||
      location.pathname ===
      "/" + t("menuItemsOptions.validations.matchesDashboard.url"))
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.criteriaManagement.title") &&
    location.pathname === "/" + t("menuItemsOptions.criteriaManagement.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.configPreProratedExpenses.title") &&
    location.pathname ===
    "/" + t("menuItemsOptions.configPreProratedExpenses.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.configProRataExpenses.title") &&
    location.pathname === "/" + t("menuItemsOptions.configProRataExpenses.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.preProrationParameterization.title") &&
    location.pathname ===
    "/" + t("menuItemsOptions.preProrationParameterization.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.modelParameterization.title") &&
    (location.pathname === "/" +
      t("menuItemsOptions.parameterizationCollapseList.assignStages.url") ||
      location.pathname === "/" +
      t(
        "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.url"
      ) ||
      location.pathname === "/" +
      t("menuItemsOptions.parameterizationCollapseList.calculationReport.url"))
  ) {
    return true;
  }
  if (
    page === "Dashboard" &&
    (location.pathname === "/" +
      t("menuItemsOptions.dashboardCollapseList.indicators.url") ||
      location.pathname === "/" +
      t("menuItemsOptions.dashboardCollapseList.graphics.url") ||
      location.pathname === "/" + t("menuItemsOptions.dashboardCollapseList.powerBi.url") ||
      location.pathname === "/" + t("menuItemsOptions.dashboardCollapseList.analysis.url"))
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.controlPanel.title") &&
    location.pathname === "/" + t("menuItemsOptions.controlPanel.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.taskManager.title") &&
    location.pathname === "/" + t("menuItemsOptions.taskManager.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.indicatorManagement.title") &&
    location.pathname === "/" + t("menuItemsOptions.indicatorManagement.url")
  ) {
    return true;
  }
  if (
    page === "Data Export Modelling" &&
    (location.pathname === "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.measureManagement.url"
      ) ||
      location.pathname === "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.url"
      ) ||
      location.pathname === "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.url"
      ) ||
      location.pathname === "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.url"
      ) ||
      location.pathname === "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.downloadReport.url"
      ))
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.simulator.title") &&
    (
      location.pathname === t("menuItemsOptions.simulatorCollapseList.salesForecast.url") ||
      location.pathname === t("menuItemsOptions.simulatorCollapseList.simulationRules.url") ||
      location.pathname === `${t("menuItemsOptions.simulatorCollapseList.simulationRules.url")}/configuracion-regla` ||
      location.pathname === t("menuItemsOptions.simulatorCollapseList.simulations.url") ||
      location.pathname === "/delta/configuracion-simulaciones" ||
      location.pathname === "/delta/sales-forecast-resultado" ||
      location.pathname === "/delta/simulacion-resultado"
    )) {
    return true;
  }
  if (
    page === t("menuItemsOptions.generatedDownloads.title") &&
    location.pathname === "/" + t("menuItemsOptions.generatedDownloads.url")
  ) {
    return true;
  }
  if (
    page === t("menuItemsOptions.marketBasketCollapseList.title") &&
    (location.pathname === "/" +
      t("menuItemsOptions.marketBasketCollapseList.admin.url") ||
      location.pathname === "/" +
      t("menuItemsOptions.marketBasketCollapseList.execution.url") ||
      location.pathname === "/" +
      t("menuItemsOptions.marketBasketCollapseList.analysis.url"))
  ) {
    return true;
  }
};

export const menuItemsOptions = (t: any) => [
  {
    title: t("menuItemsOptions.companyParameterization.title"),
    url: t("menuItemsOptions.companyParameterization.url"),
    icon: AssignmentTurnedInIcon,
  },
  {
    title: t("menuItemsOptions.dataManagement.title"),
    url: t("menuItemsOptions.dataManagement.url"),
    icon: UploadFileIcon,
  },
  {
    title: t("menuItemsOptions.validations.title"),
    url: "",
    icon: FactCheckIcon,
  },
  {
    title: t("menuItemsOptions.criteriaManagement.title"),
    url: t("menuItemsOptions.criteriaManagement.url"),
    icon: ConstructionIcon,
  },
  {
    title: t("menuItemsOptions.preProrationParameterization.title"),
    url: t("menuItemsOptions.preProrationParameterization.url"),
    icon: FormatListBulletedIcon,
  },
  {
    title: t("menuItemsOptions.configProRataExpenses.title"),
    url: t("menuItemsOptions.configProRataExpenses.url"),
    icon: SettingsIcon,
  },
  {
    title: t("menuItemsOptions.modelParameterization.title"),
    url: "",
    icon: IsoIcon,
  },
  {
    title: t("menuItemsOptions.indicatorManagement.title"),
    url: t("menuItemsOptions.indicatorManagement.url"),
    icon: AnalyticsIcon,
  },
  {
    title: t("menuItemsOptions.dashboard.title"),
    url: "",
    icon: AutoAwesomeMosaicIcon,
  },
  {
    title: t("menuItemsOptions.taskManager.title"),
    url: t("menuItemsOptions.taskManager.url"),
    icon: AssignmentIcon, //SettingsIcon
  },
  {
    title: t("menuItemsOptions.dataExportModelling.title"),
    url: "",
    icon: SchemaIcon,
  },
  {
    title: t("menuItemsOptions.simulator.title"),
    url: "",
    icon: MonitorIcon,
  },
  {
    title: t("menuItemsOptions.generatedDownloads.title"),
    url: "descargas-generadas",
    icon: BrowserUpdatedIcon,
  },

  {
    title: t("menuItemsOptions.marketBasketCollapseList.title"),
    url: "",
    icon: TroubleshootIcon,
  },
];

export const parameterModelCollapseList = (t: any) => [
  {
    title: t(
      "menuItemsOptions.parameterizationCollapseList.assignStages.title"
    ),
    url: "/" + t("menuItemsOptions.parameterizationCollapseList.assignStages.url"),
    icon: LinearScaleIcon,
  },
  {
    title: t(
      "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.title"
    ),
    url: "/" + t(
      "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.url"
    ),
    icon: CalculateIcon,
  },
  {
    title: t(
      "menuItemsOptions.parameterizationCollapseList.calculationReport.title"
    ),
    url: "/" + t(
      "menuItemsOptions.parameterizationCollapseList.calculationReport.url"
    ),
    icon: TableChartIcon,
  },
];

export const dashboardCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.dashboardCollapseList.indicators.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.indicators.url"),
    icon: DataThresholdingIcon,
  },
  {
    title: t("menuItemsOptions.dashboardCollapseList.graphics.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.graphics.url"),
    icon: BarChartIcon,
  },
  {
    title: t("menuItemsOptions.dashboardCollapseList.powerBi.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.powerBi.url"),
    icon: DashboardIcon,
  },
  {
    title: t("menuItemsOptions.dashboardCollapseList.analysis.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.analysis.url"),
    icon: AddchartIcon,
  },
];

export const dataExportModellingCollapseList = (t: any) => [
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.title"
    ),
    url: "/" + t(
      "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.url"
    ),
    icon: GroupWorkIcon,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.measureManagement.title"
    ),
    url: "/" + t(
      "menuItemsOptions.dataExportModellingCollapseList.measureManagement.url"
    ),
    icon: StraightenIcon,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.title"
    ),
    url: "/" + t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.url"
    ),
    icon: AnalyticsIcon,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.title"
    ),
    url: "/" + t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.url"
    ),
    icon: SmartDisplayIcon,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.downloadReport.title"
    ),
    url: "/" + t(
      "menuItemsOptions.dataExportModellingCollapseList.downloadReport.url"
    ),
    icon: DownloadIcon,
  },
];

export const simulatorCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.simulatorCollapseList.salesForecast.title"),
    url: t("menuItemsOptions.simulatorCollapseList.salesForecast.url"),
    icon: SmartDisplayIcon,
  },
  {
    title: t("menuItemsOptions.simulatorCollapseList.simulationRules.title"),
    url: t("menuItemsOptions.simulatorCollapseList.simulationRules.url"),
    icon: SpeedIcon,
  },
  {
    title: t("menuItemsOptions.simulatorCollapseList.simulations.title"),
    url: t("menuItemsOptions.simulatorCollapseList.simulations.url"),
    icon: AutoGraphIcon
  },
  // {
  //   title: t("menuItemsOptions.simulatorCollapseList.simulationSchema.title"),
  //   url: t("menuItemsOptions.simulatorCollapseList.simulationSchema.url"),
  //   icon: SchemaIcon,
  // },
  // {
  //   title: t("menuItemsOptions.simulatorCollapseList.registeredRoutes.title"),
  //   url: t("menuItemsOptions.simulatorCollapseList.registeredRoutes.url"),
  //   icon: RouteIcon,
  // },
  // {
  //   title: t("menuItemsOptions.simulatorCollapseList.simulationsResults.title"),
  //   url: t("menuItemsOptions.simulatorCollapseList.simulationsResults.url"),
  //   icon: AutoGraphIcon,
  // },
];

export const validationsCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.validations.adminValidations.title"),
    url: "/" + t("menuItemsOptions.validations.adminValidations.url"),
    icon: RuleIcon,
  },
  {
    title: t("menuItemsOptions.validations.matchesDashboard.title"),
    url: "/" + t("menuItemsOptions.validations.matchesDashboard.url"),
    icon: JoinLeftIcon,
  },
];

export const marketBasketCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.marketBasketCollapseList.admin.title"),
    url: "/" + t("menuItemsOptions.marketBasketCollapseList.admin.url"),
    icon: DataSaverOnIcon,
  },
  {
    title: t("menuItemsOptions.marketBasketCollapseList.analysis.title"),
    url: "/" + t("menuItemsOptions.marketBasketCollapseList.analysis.url"),
    icon: PlaylistAddCheckCircleIcon,
  },
];

export const redirectMenuItem = (
  item: any,
  navigate: any,
  handleDrawerClose: any,
  getViewTitle?: any
) => {
  item.url !== "" && handleDrawerClose();

  if (item.url !== "") {
    getViewTitle && getViewTitle();
    navigate(`/${item.url}`);
  }
};

export const returnItemRole = (item: any, userRolData: any, t) => {
  switch (item.title) {
    case t("menuItemsOptions.companyParameterization.title"):
      return (
        userRolData?.parametrizacion_de_la_empresa.visualizacion ||
        userRolData?.parametrizacion_de_la_empresa.visualizacion_y_edicion
      );
    case t("menuItemsOptions.dataManagement.title"):
      return (
        userRolData?.administracion_de_datos.visualizacion ||
        userRolData?.administracion_de_datos.subir_archivos ||
        userRolData?.administracion_de_datos.eliminar_archivos ||
        userRolData?.administracion_de_datos.descargar_archivos
      );
    case t("menuItemsOptions.validations.title"):
      return (
        userRolData?.validaciones.visualizacion ||
        userRolData?.validaciones.visualizacion_y_edicion
      );
    case t("menuItemsOptions.criteriaManagement.title"):
      return (
        userRolData?.administracion_de_criterios.visualizacion ||
        userRolData?.administracion_de_criterios.visualizacion_y_edicion
      );
    case t("menuItemsOptions.preProrationParameterization.title"):
      return (
        userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
          .visualizacion ||
        userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
          .visualizacion_y_edicion
      );
    case t("menuItemsOptions.configProRataExpenses.title"):
      return (
        userRolData?.configuracion_de_gastos.visualizacion ||
        userRolData?.configuracion_de_gastos.visualizacion_y_edicion
      );
    case t("menuItemsOptions.modelParameterization.title"):
      return (
        userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
          .visualizacion ||
        userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
          .visualizacion_y_edicion
      );
    case t("menuItemsOptions.indicatorManagement.title"):
      return (
        userRolData?.administracion_de_dashboard.visualizacion ||
        userRolData?.administracion_de_dashboard.visualizacion_y_edicion
      );
    case t("menuItemsOptions.dashboard.title"):
      return (
        userRolData?.dashboards.visualizacion ||
        userRolData?.dashboards.visualizacion_y_edicion
      );
    case t("menuItemsOptions.taskManager.title"):
      return userRolData?.gestor_de_tareas.visualizacion;
    case t("menuItemsOptions.dataExportModelling.title"):
      return (
        userRolData?.administracion_de_medidas_agrupaciones_y_analisis
          .visualizacion ||
        userRolData?.descargar_reporte.visualizacion_y_edicion ||
        userRolData?.administracion_de_medidas_agrupaciones_y_analisis
          .visualizacion_y_edicion
      );
    case t("menuItemsOptions.generatedDownloads.title"):
      return userRolData?.descargas.visualizacion_y_descargas;
    case t("menuItemsOptions.marketBasketCollapseList.title"):
      return (
        userRolData?.market_basket.visualizacion ||
        userRolData?.market_basket.visualizacion_y_creacion ||
        userRolData?.market_basket.visualizacion_y_edicion
      );
    case t("menuItemsOptions.simulator.title"):
      return (
        userRolData?.simulador.visualizacion ||
        userRolData?.simulador.visualizacion_y_creacion ||
        userRolData?.simulador.visualizacion_y_edicion
      );
    default:
      return false;
  }
};
