import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { SwitchComponentProps } from "./types";

const SwitchComponent = ({
  checked,
  handleChange,
  label,
  disabled,
  color,
  labelPlacement
}: SwitchComponentProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled ? disabled : false}
            color={color ? color : "error"}
          />
        }
        label={label}
        checked={checked}
        onChange={(e: any) => handleChange(e)}
        sx={{ "& .MuiFormControlLabel-label": { color: "var(--text-main)" } }}
        labelPlacement={labelPlacement? labelPlacement : "end"}
      />
    </FormGroup>
  );
};

export default SwitchComponent;
