import { Stack } from "@mui/material";
import drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import useLanguage from "../../context/LanguageProvider";
import accessibility from "highcharts/modules/accessibility";

interface Props {
  results: any;
}

drilldown(Highcharts);
accessibility(Highcharts);

const Boxplot = (props: Props) => {
  const { t } = useLanguage();
  const { results } = props;

  const options = {
    chart: {
      type: "boxplot",
    },
    title: results?.titulo_grafico,
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },

    xAxis: {
      categories: results?.categorias,
      title: {
        text: results?.nombre_eje_x,
      },
    },
    yAxis: {
      title: {
        text: results?.nombre_eje_y,
      },
      /* plotLines: [
        {
          value: 932,
          color: "red",
          width: 1,
          label: {
            text: "Theoretical mean: 932",
            align: "center",
            style: {
              color: "gray",
            },
          },
        },
      ], */
    },
    series: [
      {
        name: results.nombre_eje_y,
        data: results?.series,
        /* tooltip: {
          headerFormat: "<em>Experiment No {point.key}</em><br/>",
        }, */
      },
      {
        name: "Outliers (Se toma una muestra de hasta 10 elementos incluyendo el maximo y minimo)",
        color: "var(--text-error)",
        type: "scatter",
        data: results?.outliers,
        marker: {
          fillColor: "var(--text-error)",
          lineWidth: 1,
          lineColor: "white",
        },
        /* tooltip: {
          pointFormat: "Observation: {point.y}",
        }, */
      },
    ],
  };

  return (
    <Stack sx={{ width: "100%", margin: "0px !important" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
};

export default Boxplot;
