import React, { useState } from "react";
import { TabValue } from "../components/colorTabs/types";

type UseTabsType = [
    TabValue,
    (event: React.SyntheticEvent | null, newValue: TabValue) => void,
    React.Dispatch<React.SetStateAction<TabValue>>
]

const useTabs = (defaultValue: string): UseTabsType => {
  const [tabValue, setTabValue] = useState<TabValue>(defaultValue);

  const handleChange = (event: React.SyntheticEvent | null, newValue: TabValue ) => {
    setTabValue(newValue);
  };

  return [tabValue, handleChange, setTabValue];
};

export default useTabs;
