export const returnItemRole = (location: any, userRolData: any, t) => {
    switch (location.pathname.slice(1)) {
      case "":
        return true;
      case "inicio":
        return true;
      case "empresa":
        return true;
      case t("menuItemsOptions.administrator.url"):
        return userRolData?.administrador.visualizacion_y_edicion;
      case t("menuItemsOptions.companyParameterization.url"):
        return (
          userRolData?.parametrizacion_de_la_empresa.visualizacion ||
          userRolData.parametrizacion_de_la_empresa.visualizacion_y_edicion
        );
      case t("menuItemsOptions.dataManagement.url"):
        return (
          userRolData?.administracion_de_datos.visualizacion ||
          userRolData.administracion_de_datos.subir_archivos ||
          userRolData.administracion_de_datos.eliminar_archivos ||
          userRolData.administracion_de_datos.descargar_archivos
        );
      case t("menuItemsOptions.validations.adminValidations.url"):
        return (
          userRolData?.validaciones.visualizacion ||
          userRolData?.validaciones.visualizacion_y_creacion ||
          userRolData?.validaciones.visualizacion_y_edicion
        );
      case t("menuItemsOptions.validations.matchesDashboard.url"):
        return (
          userRolData?.validaciones.visualizacion ||
          userRolData?.validaciones.visualizacion_y_creacion ||
          userRolData?.validaciones.visualizacion_y_edicion
        );
      case t("menuItemsOptions.criteriaManagement.url"):
        return (
          userRolData?.administracion_de_criterios.visualizacion ||
          userRolData?.administracion_de_criterios.visualizacion_y_creacion ||
          userRolData?.administracion_de_criterios.visualizacion_y_edicion
        );
      case t("menuItemsOptions.preProrationParameterization.url"):
        return (
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion ||
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion_y_edicion
        );
      case t("menuItemsOptions.configProRataExpenses.url"):
        return (
          userRolData?.configuracion_de_gastos.visualizacion ||
          userRolData?.configuracion_de_gastos.visualizacion_y_edicion
        );
      case t("menuItemsOptions.parameterizationCollapseList.assignStages.url"):
        return (
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion ||
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion_y_edicion
        );
      case t(
        "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.url"
      ):
        return (
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion ||
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion_y_edicion
        );
      case t(
        "menuItemsOptions.parameterizationCollapseList.calculationReport.url"
      ):
        return (
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion ||
          userRolData?.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion_y_edicion
        );
      case t("menuItemsOptions.indicatorManagement.url"):
        return (
          userRolData?.administracion_de_dashboard.visualizacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_edicion
        );
      case t("menuItemsOptions.dashboardCollapseList.indicators.url"):
        return (
          userRolData?.dashboards.visualizacion ||
          userRolData?.dashboards.visualizacion_y_creacion ||
          userRolData?.dashboards.visualizacion_y_edicion
        );
      case t("menuItemsOptions.dashboardCollapseList.graphics.url"):
        return (
          userRolData?.dashboards.visualizacion ||
          userRolData?.dashboards.visualizacion_y_creacion ||
          userRolData?.dashboards.visualizacion_y_edicion
        );
      case t("menuItemsOptions.dashboardCollapseList.analysis.url"):
        return (
          userRolData?.dashboards.visualizacion ||
          userRolData?.dashboards.visualizacion_y_creacion ||
          userRolData?.dashboards.visualizacion_y_edicion
        );
      case t("menuItemsOptions.dashboardCollapseList.powerBi.url"):
        return (
          userRolData?.dashboards.visualizacion ||
          userRolData?.dashboards.visualizacion_y_creacion ||
          userRolData?.dashboards.visualizacion_y_edicion
        );
      case t("menuItemsOptions.taskManager.url"):
        return userRolData?.gestor_de_tareas.visualizacion;
      case t(
        "menuItemsOptions.dataExportModellingCollapseList.measureManagement.url"
      ):
        return (
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_creacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_edicion
        );
      case t(
        "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.url"
      ):
        return (
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_creacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_edicion
        );
      case t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.url"
      ):
        return (
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_creacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_edicion
        );
      case t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.url"
      ):
        return (
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_creacion ||
          userRolData?.administracion_de_medidas_agrupaciones_y_analisis
            .visualizacion_y_edicion
        );
      case t(
        "menuItemsOptions.dataExportModellingCollapseList.downloadReport.url"
      ):
        return userRolData?.descargar_reporte.visualizacion_y_edicion;
      case t("menuItemsOptions.generatedDownloads.url"):
        return userRolData?.descargas.visualizacion_y_descargas;
      case t("menuItemsOptions.marketBasketCollapseList.analysis.url"):
        return (
          userRolData?.market_basket.visualizacion ||
          userRolData?.market_basket.visualizacion_y_creacion ||
          userRolData?.market_basket.visualizacion_y_edicion
        );
      case t("menuItemsOptions.marketBasketCollapseList.admin.url"):
        return (
          userRolData?.market_basket.visualizacion ||
          userRolData?.market_basket.visualizacion_y_creacion ||
          userRolData?.market_basket.visualizacion_y_edicion
        );
      case "configuracion/archivo/grafico":
        return (
          userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_edicion
        );
      case "configuracion/archivo/indicador":
        return (
          userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_edicion
        );
        case "configuracion/archivo/tabla":
          return (
            userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
            userRolData?.administracion_de_dashboard.visualizacion_y_edicion
          );
      case "configuracion/analisis/indicador":
        return (
          userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_edicion
        );
      case "configuracion/analisis/grafico":
        return (
          userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_edicion
        );
        case "configuracion/analisis/tabla":
        return (
          userRolData?.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData?.administracion_de_dashboard.visualizacion_y_edicion
        );
      case "configuracion/cruce-codigos":
        return (
          userRolData?.validaciones.visualizacion_y_creacion ||
          userRolData?.validaciones.visualizacion_y_edicion
        );
      case "market-basket/configuracion-analisis":
        return (
          userRolData?.market_basket.visualizacion_y_creacion ||
          userRolData?.market_basket.visualizacion_y_edicion
        );
      case "delta/sales-forecast":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        );
      case "delta/sales-forecast-resultado":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        );
      case "delta/administracion-reglas":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        );
      case "delta/administracion-reglas/configuracion-regla":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion
        )
      case "delta/simulaciones":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        )
      case "delta/configuracion-simulaciones":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        )
      case "delta/simulacion-resultado":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        )
      case "delta/administracion-rutas":
        return (
          userRolData?.simulador.visualizacion ||
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        );
      case "delta/administracion-rutas/configuracion-ruta":
        return (
          userRolData?.simulador.visualizacion_y_creacion ||
          userRolData?.simulador.visualizacion_y_edicion
        );
      default:
        return false;
    }
};
