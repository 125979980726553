import { Stack } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { roundNumber } from '../utils'
import useLanguage from '../../../context/LanguageProvider'
import { getColumnsRegisteredRoutes } from './getColumnsRegisteredRoutes'

interface RegisteredRouteInfoModalTableProps {
    data: any
}

const RegisteredRouteInfoModalTable = ({ data }: RegisteredRouteInfoModalTableProps) => {

    const [rows, setRows] = useState<any>()
    const { t } = useLanguage()

    useEffect(() => {
        if (data) {
            const transformedRegisteredRoutesData: any = [];

            data?.forEach((item, idx) => {
                const { filtros, modalidad_proyeccion, parametros, cantidad } = item;
                const filtrosAdicionales = {};
                filtros.adicionales.forEach(filtro => {
                    filtrosAdicionales[filtro.columna] === filtro.valor;
                })
                const filtrosRequeridos = {};
                filtros.requeridos.forEach(filtro => {
                    filtrosRequeridos[filtro.label] === filtro.valor;
                })
                const params = {};
                parametros.forEach(param => {
                    if (modalidad_proyeccion === "Total Ruta") {
                        if (param.clave.nombre !== "Volumen mensual promedio por Cliente") {
                            params[`${param.clave.nombre}_actual`] = roundNumber(param.valor, 2)
                            if (param.clave.nombre !== "Precio Promedio") {
                                params[`${param.clave.nombre}_ajustado`] = roundNumber(param.valor_ajustado, 2)
                            }
                        }
                    } else {
                        if (param.clave.nombre !== "Volumen mensual promedio Total por Ruta") {
                            params[`${param.clave.nombre}_actual`] = roundNumber(param.valor, 2)
                            if (param.clave.nombre !== "Precio Promedio") {
                                params[`${param.clave.nombre}_ajustado`] = roundNumber(param.valor_ajustado, 2)
                            }
                        }
                    }

                })
                const transformedItem = {
                    id: idx,
                    ...filtrosRequeridos,
                    ...filtrosAdicionales,
                    modalidad_proyeccion: modalidad_proyeccion,
                    ...params,
                    cantidad: cantidad
                }

                transformedRegisteredRoutesData.push(transformedItem);
            });
            setRows(transformedRegisteredRoutesData)
        };
    }, [data])

    return (
        <>
            {rows ?
                <Stack sx={{
                    height: 80 + rows.length * 45 + "px",
                    '& .headerGroup--className': {
                        border: "1px solid white",
                        backgroundColor: 'var(--bg-main)',
                        display: 'flex',
                        width: "100%",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }, '& .headerSubGroup--className': {
                        backgroundColor: 'var(--chart-22)',
                        border: "1px solid white",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    //Classname para alinear la tabla 
                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    overflow: "auto",
                    minWidth: "850px",
                    width: "100%"
                }}>
                    <DataGridPro
                        rows={rows}
                        columns={getColumnsRegisteredRoutes(rows, t)}
                        rowHeight={35}
                        disableColumnMenu={true}
                        hideFooter={true}
                        checkboxSelection={false}
                        disableSelectionOnClick
                        sx={{
                            boxShadow: 3,
                            fontSize: "12px",
                            padding: "0 10px 10px 10px",
                            // checkbox background and checkbox color
                            "& .MuiDataGrid-columnHeadersInner": {
                                backgroundColor: "var(--bg-secondary)",
                            },
                            "& .MuiDataGrid-columnHeadersInner span": {
                                color: "white",
                            },
                        }}
                    />
                </Stack>
                : <></>}
        </>
    )
}

export default RegisteredRouteInfoModalTable