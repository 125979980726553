import Dialog from "../../dialog/Dialog";
import useLanguage from "../../../context/LanguageProvider";
import { Typography, Stack } from "@mui/material";
import { useApiQuery } from "../../..//hooks/useApiQuery";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { useEffect, useState } from "react";
import { GET_ANALYSIS_URL, GET_RULE_URL } from '../../../api/axios';
import useApi from '../../../hooks/useApi';
import Divider from "@mui/material/Divider";
import { getInformationColumn } from "../utils";
import RegisteredRouteInfoModalTable from "./RegisteredRouteInfoModalTable ";
import CanceledRouteInfoModalTable from "./Steps/Step2/CancelRoute/CanceledRouteInfoModalTable";
import ModifiedRouteInfoModalTable from "./Steps/Step2/ModifyRoute/ModifiedRouteInfoModalTable";

interface ViewInfoSimulationModalProps {
    simulation: any;
    open: boolean;
    handleClose: () => void;
    modificationTypes?: any;
    dataGetSimulationsInformation?: any,
}

const ViewInfoSimulationModal = ({
    simulation,
    open,
    handleClose,
    modificationTypes,
    dataGetSimulationsInformation,
}: ViewInfoSimulationModalProps) => {

    const { t } = useLanguage();
    const [modificationRoutes, setModificationRoutes] = useState<any[]>([])
    const [informationsColumns, setInformationColumns] = useState<any>([])

    const {
        isLoading: isLoadingGetRuleById,
        data: dataGetRuleById,
        callApi: getRuleById,
    } = useApi(
        undefined,
        "GET",
        t("simulator.getRules.error"),
        undefined,
        undefined,
        undefined,
        false
    );

    const {
        isLoading: isLoadingGetAnalysisById,
        data: dataGetAnalysisById,
        callApi: getAnalysisById,
    } = useApi(
        undefined,
        "GET",
        t("dataModel.getAnalysis.error"),
        undefined,
        undefined,
        undefined,
        false
    );

    const {
        isLoading: isLoadingGetSalesForecasts,
        data: dataGetSalesForecast,
    } = useApiQuery("simulador/sales_forecast/", true, t("dataModel.getAnalysis.error"));

    const {
        isLoading: isLoadingGetSalesForecastById,
        callApi: getSalesForecastById,
        data: dataGetSalesForecastById,
    } = useApi(
        undefined,
        "GET",
        t("simulator.getSchemas.error"),
        undefined,
        undefined,
        undefined,
        false
    );

    useEffect(() => {
        if (simulation) {
            getRuleById(GET_RULE_URL(simulation.regla.value))
        }
    }, [simulation])

    useEffect(() => {
        if (dataGetRuleById) {
            getAnalysisById(GET_ANALYSIS_URL(dataGetRuleById.analisis.id, true))
        }
    }, [dataGetRuleById])
    
    useEffect(() => {
        if (dataGetAnalysisById && dataGetSalesForecast) {
            const salesForecast = dataGetSalesForecast?.find((salesForecast) => salesForecast.analisis === dataGetAnalysisById.nombre)
            salesForecast && getSalesForecastById(`simulador/sales_forecast/${salesForecast?.id}`)
        }
    }, [dataGetAnalysisById, dataGetSalesForecast])

    useEffect(() => {
        if (dataGetSimulationsInformation && dataGetSalesForecastById) {
            const resultadoCruzado = dataGetSimulationsInformation?.map((item) => {
                const columnOrMedida = dataGetSalesForecastById?.columnas_principales.find(element => element.informacion === item.id)?.columna
                return {
                    id: item.id,
                    informacion: item.nombre,
                    columna: typeof columnOrMedida === "string" ? columnOrMedida : dataGetAnalysisById?.medidas?.find(item => item.id === columnOrMedida)?.nombre || null
                }
            });
            resultadoCruzado && setInformationColumns(resultadoCruzado)
        }
    }, [dataGetSimulationsInformation, dataGetSalesForecastById])

    useEffect(() => {
        if (informationsColumns && simulation && simulation.rutas_modificacion && simulation.rutas_modificacion.length > 0) {
            // Objeto para almacenar las rutas agrupadas por tipo_modificacion y valor
            const groupedModifiedRoutes: { [key: string]: any[] } =
                simulation.rutas_modificacion.reduce((acc, curr) => {
                    // Generar una clave única basada en tipo_modificacion, valor y filtros
                    const key = `${curr.valor}_${JSON.stringify(curr.filtros)}`;

                    // Verificar si ya existe una entrada para esta clave en el objeto
                    if (!acc[key]) {
                        // Si no existe, crear una nueva entrada con un array que contiene el objeto actual
                        acc[key] = [curr];
                    } else {
                        // Si ya existe, agregar el objeto actual al array existente
                        acc[key].push(curr);
                    }

                    return acc;
                }, {});

            // Transformar el objeto agrupado en un arreglo de arreglos de rutas
            const configModifiedDataEdit = groupedModifiedRoutes && Object.values(groupedModifiedRoutes).map((group: any) => {
                // Retornar un objeto con la información agrupada de la ruta y los filtros
                return group.map((route) => {
                    return {
                        id: route.ruta,
                        [`${getInformationColumn(informationsColumns, "Identificador único de ruta")}`]: route.ruta,
                        [`${getInformationColumn(informationsColumns, modificationTypes.find(type => type.id === route.tipo_modificacion)?.nombre)}_OLD`]: route.valor_original,
                        [`${getInformationColumn(informationsColumns, modificationTypes.find(type => type.id === route.tipo_modificacion)?.nombre)}`]: route.valor,
                        filtros: route.filtros
                    }
                });
            });
            // Iterar sobre cada subarray en dataModifiedRoutes
            const idMap = new Map<string, any>();

            // Iterar sobre cada subarray en dataModifiedRoutes
            configModifiedDataEdit.forEach((subarray, index) => {
                const groupedArray: any[] = [];
                // Iterar sobre cada objeto en el subarray
                subarray.forEach(obj => {
                    // Obtener el id del objeto
                    const id = obj.id;

                    // Verificar si ya existe un objeto con el mismo id en el mapa
                    if (idMap.has(id)) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const existingObj = idMap.get(id)!;

                        // Comparar las propiedades del objeto actual con las del objeto existente
                        Object.keys(obj).forEach(key => {
                            if (obj[key] !== existingObj[key]) {
                                // Si las propiedades son diferentes, actualizar el objeto existente
                                existingObj[key] = obj[key];
                            }
                        });
                    } else {
                        // Si el objeto no existe en el mapa, agregarlo
                        idMap.set(id, obj);
                    }
                });

                // Agregar los objetos del mapa al array agrupado
                idMap.forEach(value => {
                    groupedArray.push(value);
                });

                // Limpiar el mapa para la siguiente iteración
                idMap.clear();

                // Reemplazar el subarray original con el nuevo subarray agrupado
                configModifiedDataEdit[index] = groupedArray;
            });

            setModificationRoutes(configModifiedDataEdit)
        }
    }, [informationsColumns, simulation])

    return (
        <Dialog
            open={open}
            handleClose={handleClose}
            title={t("simulatorTexts.registerRoutes.viewInfoRegisterRouteModal.registerRouteInfo")}
            maxWidth={"lg"}
        >
            <SimpleBackdrop
                open={isLoadingGetAnalysisById || isLoadingGetRuleById || isLoadingGetSalesForecasts || isLoadingGetSalesForecastById}
                message={t("simulatorTexts.registerRoutes.registerRoutesAdmin.loading")}
            />
            <Stack
                padding="0 50px 15px 50px"
                sx={{
                    minHeight: "620px"
                }}
            >
                <Stack sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    pb: 1
                }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                        }}
                        color="var(--text-main)"
                    >
                        {t("deltaTexts.simulation.viewInfoModal.simulationName")}: {" "}
                        <span style={{ color: "var(--text-disabled)" }}>
                            {simulation?.nombre}
                        </span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                            wordBreak: "break-word"
                        }}
                        color="var(--text-main)"
                    >
                        {t("deltaTexts.simulation.viewInfoModal.rule")}: {" "}
                        <span style={{ color: "var(--text-disabled)" }}>
                            {simulation.regla.label}
                        </span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                        }}
                        color="var(--text-main)"
                    >
                        {t("deltaTexts.simulation.simulationCard.autoRun")}: {" "}
                        <span style={{ color: "var(--text-disabled)" }}>
                            {simulation?.ejecucion_automatica ? t("general.yes") + "." : t("general.no") + "."}
                        </span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                            wordBreak: "break-word"
                        }}
                        color="var(--text-main)"
                    >
                        {t("deltaTexts.salesForecast.salesForecastCard.enabledPeriodsNonExecuted")}: {" "}
                        <span style={{ color: "var(--text-disabled)", wordBreak: "break-all" }}>
                            {simulation.periodos_faltantes && simulation.periodos_faltantes.length > 0 ? simulation.periodos_faltantes.map((period, idx) => {
                                if (simulation.periodos_faltantes.length - 1 === idx) {
                                    return period + "."
                                } else {
                                    return period + ", "
                                }
                            }) : "Ninguno"}
                        </span>
                    </Typography>
                </Stack>
                <Divider sx={{ margin: "6px 0" }} />
                <Stack sx={{
                    width: "100%"
                }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                            wordBreak: "break-word",
                            pb: 2
                        }}
                        color="var(--text-main)"
                    >
                        {t("simulatorTexts.registerRoutes.configRegisterRoute.registeredRoute")}
                    </Typography>
                    {simulation && simulation.rutas_alta && simulation.rutas_alta.length > 0 ?
                        <RegisteredRouteInfoModalTable data={simulation.rutas_alta} />
                        :
                        <Typography
                            sx={{ fontWeight: "700", color: "var(--text-info)", fontSize: "15px", textAlign: "center" }}
                        >
                            {t("simulatorTexts.registerRoutes.configRegisterRoute.noRegistrationConfigYet")}
                        </Typography>
                    }
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                            wordBreak: "break-word",
                            pt: 3,
                            pb: 3
                        }}
                        color="var(--text-main)"
                    >
                        {t("simulatorTexts.registerRoutes.configRegisterRoute.canceledRoute")}
                    </Typography>
                    {simulation && simulation.rutas_baja && simulation.rutas_baja.length > 0 ?
                        <CanceledRouteInfoModalTable informationsColumns={informationsColumns} data={simulation.rutas_baja} width={"850px"} />
                        :
                        <Typography
                            sx={{ fontWeight: "700", color: "var(--text-info)", fontSize: "15px", textAlign: "center" }}
                        >
                            {t("simulatorTexts.registerRoutes.configRegisterRoute.noCancelationConfigYet")}
                        </Typography>
                    }
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                            wordBreak: "break-word",
                            pt: 3,
                            pb: 3
                        }}
                        color="var(--text-main)"
                    >
                        {t("simulatorTexts.registerRoutes.configRegisterRoute.modifiedRoute")}
                    </Typography>
                    {simulation && simulation.rutas_modificacion && modificationRoutes.length > 0 ?
                        <ModifiedRouteInfoModalTable data={modificationRoutes} width={"850px"} />
                        :
                        <Typography
                            sx={{ fontWeight: "700", color: "var(--text-info)", fontSize: "15px", textAlign: "center" }}
                        >
                            {t("simulatorTexts.registerRoutes.configRegisterRoute.noModificationConfigYet")}
                        </Typography>
                    }
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default ViewInfoSimulationModal