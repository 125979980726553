import { Button } from "../buttons";
import { useState } from "react";
import Dialog from "../dialog/Dialog";
import useLanguage from "../../context/LanguageProvider";
interface AddGroupProps {
  openAddGroupModal: boolean;
  setOpenAddGroupModal: (open: boolean) => void;
  groupSelectOptions: any;
  postNewGroup: any;
  newGroupName: any;
  setNewGroupName: any;
  newGroupNameRef: any;
}

const AddTargetModal = ({
  openAddGroupModal,
  setOpenAddGroupModal,
  groupSelectOptions,
  postNewGroup,
  newGroupName,
  setNewGroupName,
  newGroupNameRef,
}: AddGroupProps) => {
  const { t } = useLanguage();
  const [newGroupError, setNewGroupError] = useState<string>("");

  const handleCloseAddGroupModal = () => {
    setOpenAddGroupModal(false);
  };

  const handleAddNewGroup = () => {
    const newGroupNameExists = groupSelectOptions
      ? groupSelectOptions?.find(
          (option: any) => option.nombre === newGroupName
        )
      : false;
    if (newGroupNameExists) {
      setNewGroupError(t("indicatorsManagementTexts.addGroupModal.groupNameAlreadyExists"));
    } else if (newGroupName) {
      setNewGroupError("");
      postNewGroup(undefined, { nombre: newGroupName });
    } else {
      setNewGroupError(t("indicatorsManagementTexts.addGroupModal.fieldCannotEmpty"));
    }
  };

  return (
    <>
      <Dialog
        open={openAddGroupModal}
        handleClose={handleCloseAddGroupModal}
        title={t("indicatorsManagementTexts.addGroupModal.newGroup")}
        actions={
          <>
            <Button
              title={t("indicatorsManagementTexts.addGroupModal.cancel")}
              color="light-grey"
              type="button"
              onClick={handleCloseAddGroupModal}
            />
            <Button
              title={t("indicatorsManagementTexts.addGroupModal.accept")}
              color="blue-greeny"
              type="button"
              onClick={handleAddNewGroup}
            />
          </>
        }
      >
        <form className="cc_form_addTarget_box" id="es-form">
          <div className="cc_addTarget_inputs_container">
            <div className="cc_addTarget_column">
              <label className="cc_step_form_label" htmlFor="newGroupName">
              {t("indicatorsManagementTexts.addGroupModal.name")}
              </label>
              <input
                className={"cc_step_form_input"}
                type="text"
                name="newGroupName"
                ref={newGroupNameRef}
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                autoComplete="on"
                required
                placeholder={t("indicatorsManagementTexts.addGroupModal.newGroupName")}
              />
            </div>
          </div>
        </form>
        <p className="cc_newTargetError">{newGroupError}</p>
      </Dialog>
    </>
  );
};

export default AddTargetModal;
