import { arrow_back_icon_styles } from "../../../styles/app-styles";
import { ConfirmationModal } from "../../dialog";
import { periodicityLabels, stepperData } from "./data";
import { SINGLE_MARKET_BASKET_URL } from "../../../api/axios";
import { useEffect } from "react";
import { useMarketBasketConfigProvider } from "../../../context/MarketBasketConfigProvider";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../buttons/Button";
import ComponentLayout from "../../layout/ComponentLayout";
import CustomStepper from "../../Stepper/CustomStepper";
import Dialog from "../../dialog/Dialog";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import StepperButtons from "../../forms/StepperButtons";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";

const MarketBasketAnalysisConfig = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const {
    activeStep,
    setActiveStep,
    isStepComplete,
    marketBasketElement,
    setMarketBasketElement,
    postMarketBasket,
    putMarketBasket,
    isLoadingPostMarketBasket,
    isLoadingPutMarketBasket,
    setMessage,
    column1SelectedOption,
    column2SelectedOption,
    column3SelectedOption,
    column4SelectedOption,
    column5SelectedOption,
    column1SKUSelectedOption,
    column2SKUSelectedOption,
    column3SKUSelectedOption,
    column5SKUSelectedOption,
    column1DestinySelectedOption,
    column2DestinySelectedOption,
    column3DestinySelectedOption,
    column5DestinySelectedOption,
    column6DestinySelectedOption,
    onFileChange,
    setMbName,
    setFileSelectedOption,
    fileSelectRef,
  } = useMarketBasketConfigProvider();
  const configMode = localStorage.getItem("configMode");
  const marketBasketInfo = JSON.parse(
    localStorage.getItem("Analisis Market Basket Info") as string
  );
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [openRestartConfirmationModal, toggleRestartConfirmationModal] =
    useDialog();
  const [openNoFiltersModal, toggleNoFiltersModal] = useDialog();

  const onSuccessGetMarketBasket = (data: any) => {
    setMarketBasketElement({
      ...data,
      periodicidad: {
        value: data.periodicidad,
        label: periodicityLabels(t)[data.periodicidad],
      },
      archivo: {
        value: data.archivo?.value,
        label: data.archivo?.label,
      },
    });
  };

  const { isLoading: isLoadingGetMarketBasket, callApi: getMarketBasket } =
    useApi(
      SINGLE_MARKET_BASKET_URL,
      "GET",
      undefined,
      undefined,
      onSuccessGetMarketBasket,
      undefined,
      false
    );

  useEffect(() => {
    setActiveStep(0);
    setMessage({ type: "", text: "" });
    if (configMode === "EDIT" || configMode === "COPY") {
      getMarketBasket(SINGLE_MARKET_BASKET_URL(marketBasketInfo));
    }
  }, []);

  /**************************************** Handlers de botones *************************************************/

  const nonAppliedFilters = () => {
    let returnValue = false;
    if (
      (activeStep === 0 &&
        column1SelectedOption &&
        column2SelectedOption &&
        column3SelectedOption &&
        column4SelectedOption &&
        column5SelectedOption) ||
      (activeStep === 1 &&
        column1SKUSelectedOption &&
        column2SKUSelectedOption &&
        column3SKUSelectedOption &&
        column5SKUSelectedOption) ||
      (activeStep === 2 &&
        column1DestinySelectedOption &&
        column2DestinySelectedOption &&
        column3DestinySelectedOption &&
        column5DestinySelectedOption &&
        column6DestinySelectedOption)
    ) {
      returnValue = true;
    }
    return returnValue;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (nonAppliedFilters()) {
      toggleNoFiltersModal();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleContinueNoFilters = () => {
    toggleNoFiltersModal();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleConfirmRestart = () => {
    toggleRestartConfirmationModal();
  };

  const handleRestart = () => {
    setActiveStep(0);
    setMbName("");
    fileSelectRef?.current?.clearValue();
    setFileSelectedOption(undefined);
    onFileChange();
    setMarketBasketElement((prev) => {
      return {
        ...prev,
        nombre: "",
        archivo: undefined,
        parametros_avanzados: {
          soporte_minimo_apriori: 0.03,
          soporte_minimo_conjunto: 0.025,
          soporte_minimo_antecedente: 0.1,
          soporte_minimo_consecuente: 0.05,
          confianza_minima: 0.6,
          lift_minimo: 2.5,
        },
      };
    });
    toggleRestartConfirmationModal();
  };

  const onFinishClick = () => {
    if (
      marketBasketElement?.indicadores_destino &&
      marketBasketElement?.indicadores_destino.length > 0 &&
      marketBasketElement?.indicadores_destino.some(
        (e: any) => e.unidad === undefined
      )
    ) {
      setMessage({
        type: "error",
        text: t("marketBasket.config.unitError"),
      });
    } else {
      setMessage({
        type: "",
        text: "",
      });
      toggleConfirmationModal();
    }
  };

  /***************************************** Validaciones de botones **********************************************/

  const isBackButtonDisabled = activeStep === 0;

  const isFinishButtonDisabled = Object.values(isStepComplete).includes(false);

  const isNextButtonDisabled =
    activeStep === stepperData(t).steps.length - 1 || !isStepComplete["step1"];

  /**************************************** POST Y PUT ********************************************************/

  const handleAccept = () => {
    toggleConfirmationModal();
    if (configMode === "EDIT") {
      putMarketBasket(`${SINGLE_MARKET_BASKET_URL(marketBasketInfo)}`);
    } else {
      postMarketBasket();
    }
  };

  /************************************ CIERRE *************************************/

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("Analisis Market Basket Info");
    navigate("/market-basket/administracion-analisis");
  };

  return (
    <ComponentLayout
      title={t("marketBasket.config.title")}
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <CustomStepper activeStep={activeStep} data={stepperData(t)} />
      <SimpleBackdrop
        open={
          isLoadingPostMarketBasket ||
          isLoadingPutMarketBasket ||
          isLoadingGetMarketBasket
        }
        message={t("general.loading")}
      />
      {activeStep === 0 && <Step1 />}
      {activeStep === 1 && <Step2 />}
      {activeStep === 2 && <Step3 />}
      {activeStep === 3 && <Step4 />}
      {activeStep === 4 && <Step5 />}
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleAccept={handleAccept}
        title={t("validations.configValidations.confirmationModal.title")}
        customMessage={true}
        message={t("validations.configValidations.confirmationModal.message")}
      />
      <StepperButtons
        handleBack={handleBack}
        handleNext={handleNext}
        handleRestart={handleConfirmRestart}
        onFinishClick={onFinishClick}
        isBackButtonDisabled={isBackButtonDisabled}
        isFinishButtonDisabled={isFinishButtonDisabled}
        isNextButtonDisabled={isNextButtonDisabled}
      />
      <Dialog
        open={openNoFiltersModal}
        handleClose={toggleNoFiltersModal}
        title={t("marketBasket.config.noFieldsTitle")}
        maxWidth="lg"
        actions={
          <>
            <Button
              title={t("configCriteria.configCriteriaComponent.goBack")}
              color="grey"
              type="button"
              onClick={toggleNoFiltersModal}
            />
            <Button
              title={t("configCriteria.configCriteriaComponent.continue")}
              color="blue"
              type="button"
              onClick={() => handleContinueNoFilters()}
            />
          </>
        }
      >
        <Typography variant="h6" color="primary">
          {t("marketBasket.config.noFieldsText")}
        </Typography>
      </Dialog>
      <ConfirmationModal
        open={openRestartConfirmationModal}
        handleClose={toggleRestartConfirmationModal}
        handleAccept={handleRestart}
        title={t("general.cleanAll")}
        customMessage={true}
        message={t("marketBasket.config.confirmCleanAll")}
      />
    </ComponentLayout>
  );
};

export default MarketBasketAnalysisConfig;
