import Divider from "@mui/material/Divider";
import { Delete } from "../icons";
import { SchemaRow } from "./types";

type SchemaItemRowProps = {
  row: SchemaRow;
  schemas: SchemaRow[];
  setSchemas: (schemas: SchemaRow[]) => void;
};

const SchemaItemRow = ({ row, schemas, setSchemas }: SchemaItemRowProps) => {
  const handleDeleteSchema = () => {
    setSchemas(
      schemas.filter((line: SchemaRow) => line.destino !== row.destino)
    );
  };

  return (
    <>
      <Divider sx={{ width: "90%" }} />
      <div className="cc_external_row_container">
        <div className="pp_schema_1_item_row">{row.destino}</div>
        <div className="pp_schema_2_item_row">
          {row.criterio ? row.criterio.label : row.porcentaje + "%"}
        </div>
        <div className="pp_schema_3_item_row">
          <Delete
            tooltipPlacement="bottom"
            onClick={() => handleDeleteSchema()}
          />
        </div>
      </div>
    </>
  );
};

export default SchemaItemRow;
