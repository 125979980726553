import { Box } from "@mui/material";
import { convertArrayToText } from "../../taskManager/utils";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizedTable from "../../virtualizatedTable/VirtualizedTable";
import {
  card_subtitle_styles,
  left_key_text_styles,
} from "../../../styles/app-styles";

const ExchangeRateCard = ({ item, showInfo }: any) => {
  const { t } = useLanguage();

  /* const ROWS1 = [
    {
      id: item?.nombre || 1,
      Bucket: item?.columnas_clave["bucket"],
      Agrupador: item?.columnas_clave["agrupador"],
      Monto_gasto: item?.columnas_clave["monto_gasto"],
      Linea_pnl: item?.columnas_clave["linea_pnl"],
    },
  ];

  const COLUMNS2 = Object.keys(item.estructura_pnl);

  const tranformObjectToTable = () => {
    const rowObject: any = {
      id: item?.nombre || 1,
    };
    COLUMNS2.forEach((key) => {
      rowObject[key] = convertArrayToText(
        item?.estructura_pnl[key]?.map((columna) => columna.label)
      );
    });    
    return rowObject;
  };

  const ROWS2: any[] = [tranformObjectToTable()];  */ 

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        {showInfo && (
          <>
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Pais: <Box sx={left_key_text_styles}>{item?.pais?.label}</Box>
            </Typography>
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Moneda:{" "}
              <Box sx={left_key_text_styles}>{item?.moneda?.label}</Box>{" "}
            </Typography>
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Monedas extranjeras:{" "}
              <Box sx={left_key_text_styles}>{ item?.tipo_de_cambio && item?.tipo_de_cambio.length ? convertArrayToText(item?.tipo_de_cambio?.map(
                (moneda) => moneda?.label
              )): "-"}</Box>
            </Typography>
           {/*  {ROWS1 && ROWS1.length && (
              <Stack>
                <Typography variant="subtitle2" sx={card_subtitle_styles}>
                  Columnas Clave:
                </Typography>
                <VirtualizedTable
                  columns={
                    transformDataSimpleTable(
                      ROWS1,
                      undefined,
                      undefined,
                      t,
                      260
                    ).columns
                  }
                  rows={
                    transformDataSimpleTable(
                      ROWS1,
                      undefined,
                      undefined,
                      t,
                      260
                    ).rows
                  }
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={25}
                  tableHeight={"70px"}
                  hideFooter={true}
                  toolbar={false}
                  columnsButton={false}
                  filterButton={false}
                  exportButton={false}
                  checkboxSelection={false}
                  setSelectedRows={() => <></>}
                  headerHeight={25}
                />
              </Stack>
            )} */}
            <br />           
          </>
        )}
      </Stack>
    </>
  );
};
export default ExchangeRateCard;
