import Grid from "@mui/material/Grid/Grid";
import useLanguage from "../../../../context/LanguageProvider";
import { grid_container2_styles } from "../../../../styles/app-styles";
import GridFiveTitles from "../../../marketBasket/MarketBasketConfig/Shared/GridTitles";
import FilterRowsRow from "./FilterRowsRow";

const FilterRowsView = ({element}) => {
  const { t } = useLanguage();

  const titles = [
    t("general.column"),
    t("Operador"),
    t("Valores"),
  ];
  return (
    <Grid sx={{...grid_container2_styles, minHeight: "100px"}} container spacing={1}>
      <GridFiveTitles titles={titles} />
      {element &&
        element.filtros &&
        element.filtros.length > 0 &&
        element.filtros.map((row: any, index: number) => (
          <FilterRowsRow
            key={index}
            index={index}
            row={row}
            rows={element.filtros}
            setRows={() => <></>}
            titles={titles}
            deletable={false}
          />
        ))}
    </Grid>
  )
}

export default FilterRowsView