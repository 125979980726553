import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import Button from "../../../buttons/Button";
import Dialog from "../../../dialog/Dialog";
import FilesTypesSteps from "./FilesTypesSteps";
import FormLayout from "../../../forms/FormLayout";
import MessageBlock from "../../../forms/MessageBlock";
import MultipleAssignModal from "./MultipleAssignModal";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles2,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step5 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    isLoadingPostExpensesParams,
    setFilesColumnsTypes,
    filesColumnsTypes,
    dataGetColumnsTypesParams,
    getColumnsTypesParams,
  } = useCompanyParamProvider();
  const [openAssignModal, handleToggleAssignModal] = useDialog();
  
  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    getColumnsTypesParams();
  }, []);

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetColumnsTypesParams) {
      setFilesColumnsTypes(dataGetColumnsTypesParams);
    }
  }, [dataGetColumnsTypesParams]);

  /************************************* Validación step completo **********************************/
  const isFileCompleted = (file) => {
    const actualFile = filesColumnsTypes?.find(
      (item) => item.nombre === file.nombre
    );
    const isFile = !!actualFile?.archivo?.name;

    const areAllColumnsTyped = actualFile?.columnas?.every((column) => {
      return column.tipo;
    });
    return isFile && areAllColumnsTyped;
  };

  const isStep5Complete = () => {
    const areAllFilesCompleted = filesColumnsTypes?.every((file) => {
      return isFileCompleted(file);
    });
    return areAllFilesCompleted;
  };

  useEffect(() => {
    if (isStep5Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step5: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step5: false };
      });
    }
  }, [filesColumnsTypes]);

  const transformDataForCheckboxSelect = (data:
    | {
        nombre: string;
        columnas: { label: string; value: number }[];
      }[]
    | []
  ) => {    
    const newColumns: { item: string; label: string; value: number;  }[] =
      [];
    data.map((item) => {
      item.columnas.forEach((subitem) => {
        newColumns.push({
          item: item.nombre,
          value: subitem.value,
          label: subitem.label,
        });
      });
    });
    return newColumns;
  };

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[5]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles2}>
        <FormLayout width="90%">
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              title={t('companyParam.assignToMultiple')}
              color="blue-greeny"
              type="button"
              onClick={handleToggleAssignModal}
              disabled={false}
              width={200}
            />
          </Stack>
          {!openAssignModal && filesColumnsTypes && <FilesTypesSteps />}
        </FormLayout>
      </Stack>
      <Dialog
        open={openAssignModal}
        handleClose={handleToggleAssignModal}
        title={t('companyParam.assignToMultiple')}
        maxWidth={"lg"}
      >
        <Stack sx={{ width: "50vw", minHeight: "200px" }}>
          <MultipleAssignModal
            handleToggleModal={handleToggleAssignModal}
            columnsOptions={transformDataForCheckboxSelect(
              dataGetColumnsTypesParams
            )}
            setFilesColumnsTypes={setFilesColumnsTypes}
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Step5;
