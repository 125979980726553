import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { Info } from "../icons";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { Button } from "../buttons";
import { FileType } from "./types";
import useLanguage from "../../context/LanguageProvider";
import { Box } from "@material-ui/core";
import SwitchComponent from "../switch/Switch.component";
import {
  checkDuplicatedFiles,
  checkFileInList,
  checkFileInUploadList,
  checkNotAllowedFiles,
  getFileName,
  hasRepeatedFiles,
  isDateFormatCorrect,
} from "./utils";
import { upload_file_icon_styles } from "../../styles/app-styles";
/* eslint-disable no-useless-escape */
interface IUploadModalContent {
  allowedFileNames: FileType[] | undefined;
  filesToUpload: File[] | null;
  setFilesToUpload: (files: File[]) => void;
  toggleAllowedFilesModal: () => void;
  handleCloseUploadFileModal: () => void;
  handleInsertFiles: () => void;
  setFileNameDefaultValue: (name: string) => void;
  isErrorAllowedFileNames: boolean;
  dataRowsFiles: any;
  isRestartChecked: boolean;
  setIsRestartChecked: any;
}

const UploadModalContent = ({
  allowedFileNames,
  setFilesToUpload,
  filesToUpload,
  toggleAllowedFilesModal,
  handleCloseUploadFileModal,
  handleInsertFiles,
  setFileNameDefaultValue,
  isErrorAllowedFileNames,
  dataRowsFiles,
  isRestartChecked,
  setIsRestartChecked
}: IUploadModalContent) => {
  const { t } = useLanguage();
  const [notAllowedFiles, setNotAllowedFiles] = useState<boolean>(false);
  const [repeatedFiles, setRepeatedFiles] = useState<boolean>(false); 
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv", ".parquet"],
    },
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
    },
  });

  useEffect(() => {
    checkNotAllowedFiles(filesToUpload, allowedFileNames, setNotAllowedFiles);
    if(hasRepeatedFiles(
      filesToUpload,
      allowedFileNames,
      repeatedFiles,
      setRepeatedFiles,
      dataRowsFiles
    )){
      setIsRestartChecked(true);
    } else {
      setIsRestartChecked(false);
    }
  }, [filesToUpload, allowedFileNames]);

  const handleDeleteFile = (fileName: File) => {
    const newFiles = filesToUpload?.filter((f: any) => f.path !== fileName);
    setRepeatedFiles(checkDuplicatedFiles(filesToUpload));
    setFilesToUpload(newFiles || []);
  };

  const handleAddName = (name: string) => {
    setFileNameDefaultValue(name);
    toggleAllowedFilesModal();
  };

  return (
    <Stack alignItems={"center"} width={"680px"}>
      <Stack alignItems={"center"} width={"680px"}>
        <Typography
          color="primary"
          sx={{ width: "100%", marginBottom: "10px", fontSize: "14px" }}
        >
          {t("dataManagementTexts.uploadModalContent.fileNameFormat_1")}:
          <br />
          <b>{t("dataManagementTexts.uploadModalContent.fileNameFormat_2")}</b>
          {" "} ({t("dataManagementTexts.uploadModalContent.fileNameFormat_3")})
          <Info
            text={
              !isErrorAllowedFileNames
                ? `${t(
                    "dataManagementTexts.uploadModalContent.allowedName"
                  )} ${allowedFileNames?.map((file: any) => " " + file.nombre)}`
                : t("dataManagementTexts.uploadModalContent.cannotUploadNames")
            }
            color="blue"
          />
        </Typography>
        <div {...getRootProps({ className: "dropzone1" })}>
          <input {...getInputProps()} multiple />
          <p>{t("dataManagementTexts.uploadModalContent.dragSelectFiles")}</p>
          <UploadFileIcon
            sx={upload_file_icon_styles}
          />
        </div>
        <Stack sx={{ width: "680px", overflow: "auto", mt: "10px", }}>
          {filesToUpload && filesToUpload.length && !isErrorAllowedFileNames && (
            <>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: "10px",
                  width: "97%",
                }}
              >
                <Typography
                  variant="button"
                  gutterBottom
                  color="primary"
                  mt="10px"
                  sx={{ fontWeight: "bold" }}
                >
                  {t("dataManagementTexts.uploadModalContent.filesToUpload")}
                </Typography>
                <Box>
                  {hasRepeatedFiles(
                    filesToUpload,
                    allowedFileNames,
                    repeatedFiles,
                    setRepeatedFiles,
                    dataRowsFiles
                  ) && (
                    <SwitchComponent
                      checked={isRestartChecked}
                      handleChange={() => setIsRestartChecked((prev: boolean) => !prev)}
                      label={t("dataManagement.uploadModal.restartCalculation")}
                      color="primary"
                      labelPlacement="start"
                    />
                  )}
                </Box>
              </Stack>

              <List dense={true} sx={{ height: "150px" }}>
                {filesToUpload?.map((file: any, index: number) => {
                  return (
                    <ListItem
                      key={file.path}
                      secondaryAction={
                        <>
                          {!allowedFileNames
                            ?.map((fileName: any) => fileName.nombre)
                            ?.includes(getFileName(file.path)) && (
                            <Tooltip
                              disableFocusListener
                              disableTouchListener
                              title={t(
                                "dataManagementTexts.uploadModalContent.addAllowedFilesName"
                              )}
                              sx={{ verticalAlign: "center", fontSize: "16px" }}
                            >
                              <IconButton
                                edge="end"
                                aria-label="add"
                                onClick={() => {
                                  handleAddName(getFileName(file.path));
                                }}
                              >
                                <ControlPointIcon
                                  sx={{
                                    color: "var(--icon-secondary) !important",
                                    cursor: "pointer",
                                    transition: "all 1s linear",
                                    "& :hover": { color: "var(--icon-main)" },
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={t(
                              "dataManagementTexts.uploadModalContent.deleteFileFromListing"
                            )}
                            sx={{ verticalAlign: "center", fontSize: "16px" }}
                          >
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(file.path)}
                            >
                              <DeleteIcon
                                sx={{
                                  color: "var(--bg-neutral-hover) !important",
                                  cursor: "pointer",
                                  transition: "all 1s linear",
                                  "& :hover": { color: "var(--icon-error)" },
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor:
                              allowedFileNames
                                ?.map((fileName: any) => fileName.nombre)
                                ?.includes(getFileName(file.path)) &&
                              isDateFormatCorrect(file.path)
                                ? !checkFileInUploadList(
                                    file,
                                    index,
                                    filesToUpload,
                                    repeatedFiles,
                                    setRepeatedFiles
                                  )
                                  ? !checkFileInList(file, dataRowsFiles)
                                    ? "var(--bg-disabled) !important"
                                    : "var(--light-yellow) !important"
                                  : "var(--bg-error-hover) !important"
                                : "var(--bg-error-hover) !important",
                          }}
                        >
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          color:
                            allowedFileNames
                              ?.map((fileName: any) => fileName.nombre)
                              ?.includes(getFileName(file.path)) &&
                            isDateFormatCorrect(file.path)
                              ? !checkFileInUploadList(
                                  file,
                                  index,
                                  filesToUpload,
                                  repeatedFiles,
                                  setRepeatedFiles
                                )
                                ? !checkFileInList(file, dataRowsFiles)
                                  ? "var(--text-main) !important"
                                  : "var(--text-info) !important"
                                : "var(--text-error) !important"
                              : "var(--text-error) !important",
                          fontWeight: "bold",
                          maxWidth: "500px",
                        }}
                        primary={
                          <p
                            style={{
                              fontWeight: "bold",
                              margin: 0,
                              padding: 0,
                            }}
                          >{`${
                            file.path.split(".")[0].toUpperCase() +
                            "." +
                            file.path.split(".")[1]
                          } ${
                            allowedFileNames
                              ?.map((fileName: any) => fileName.nombre)
                              ?.includes(getFileName(file.path)) &&
                            isDateFormatCorrect(file.path)
                              ? !checkFileInUploadList(
                                  file,
                                  index,
                                  filesToUpload,
                                  repeatedFiles,
                                  setRepeatedFiles
                                )
                                ? !checkFileInList(file, dataRowsFiles)
                                  ? ""
                                  : `- ${t(
                                      "dataManagementTexts.uploadModalContent.fileAlreadyExistsRestatedCalculation"
                                    )} ${isRestartChecked ? t("dataManagementTexts.uploadModalContent.itWillBeRestarted"): ""}`
                                : t(
                                    "dataManagementTexts.uploadModalContent.fileAlreadyIncluded"
                                  )
                              : t(
                                  "dataManagementTexts.uploadModalContent.fileNameNotAllowed"
                                )
                          }`}</p>
                        }
                        secondary={file.size + " bytes"}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
          {isErrorAllowedFileNames && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "var(--text-error)",
                textAlign: "center",
              }}
            >
              {t(
                "dataManagementTexts.uploadModalContent.cannotGetAllowedFiles"
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          title={t("dataManagementTexts.uploadModalContent.cancel")}
          color="grey"
          type="button"
          onClick={handleCloseUploadFileModal}
        />
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={
            notAllowedFiles === true
              ? t("dataManagementTexts.uploadModalContent.filledIllegalNames")
              : ""
          }
          placement="top"
        >
          <div>
            <Button
              title={t("dataManagementTexts.uploadModalContent.accept")}
              color="blue"
              type="button"
              onClick={handleInsertFiles}
              disabled={
                notAllowedFiles === true ||
                !filesToUpload?.length ||
                isErrorAllowedFileNames ||
                repeatedFiles
              }
            />
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default UploadModalContent;
