import { ElementType } from "./CheckboxSelect";

//Función que recibe como parametro un item en string, y un array de todos los dataelements y devuelve los elementos que contiene ese item
export const getElementsByItem: (item: string, data: any) => any[] = (
  item: string,
  data
) => {
  return data.filter((dataElement) => {
    return dataElement.item === item;
  });
};

// Función para eliminar duplicados de un array del tipo ElementsType
export const removeDuplicatesFromElementsType = (
  array: ElementType[]
): ElementType[] => {
  // Utilizamos un objeto temporal para rastrear elementos únicos
  const uniqueMap: { [key: string]: boolean } = {};

  // Filtramos el array para mantener solo elementos únicos
  const uniqueArray = array.filter((element) => {
    const key = JSON.stringify(element);
    const isDuplicate = uniqueMap[key];
    uniqueMap[key] = true;
    return !isDuplicate;
  });

  return uniqueArray;
};

export const isItemInArray = (item: string, items: string[]) => {
  return items.includes(item);
};

export const getTotalItems = (data) => {
  const items: string[] = [];
  data?.forEach((dataElement) => {
    if (!isItemInArray(dataElement.item, items)) {
      items.push(dataElement.item);
    }
  });
  return items;
};
