import { stepperData } from "../data";
import { useEffect } from "react";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import MessageBlock from "../../../forms/MessageBlock";
import Stack from "@mui/material/Stack";
import Step2Calculation from "./Step2Calculation";
import Step2Form from "./Step2Form";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles,
  form_subtitle_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step2 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    marketBasketElement,
    setMarketBasketElement,
    ventaUnidadesSelectedOption,
    ventaMonedaSelectedOption,
  } = useMarketBasketConfigProvider();

  /************************************* Limpieza y seteo inicial ********************************/
  useEffect(() => {
    setMessage({
      text: "",
      type: "",
    });

    if (
      !marketBasketElement.metricas_SKU_primarias.find(
        (element) =>
          element.nombre.trim().toLowerCase() === "venta_unidades_promedio"
      ) &&
      !marketBasketElement.metricas_SKU_primarias.find(
        (element) =>
          element.nombre.trim().toLowerCase() === "venta_moneda_promedio"
      ) &&
      !marketBasketElement.metricas_SKU_primarias.find(
        (element) =>
          element.nombre.trim().toLowerCase() === "venta_unidades"
      ) &&
      !marketBasketElement.metricas_SKU_primarias.find(
        (element) =>
          element.nombre.trim().toLowerCase() === "venta_moneda"
      )
    ) {
      setMarketBasketElement((prev) => ({
        ...prev,
        metricas_SKU_primarias: [
          ...prev.metricas_SKU_primarias,
          {
            nombre: "VENTA_UNIDADES_PROMEDIO",
            funcion: {
              value: "PROMEDIAR",
              label: "PROMEDIAR",
            },
            columna: ventaUnidadesSelectedOption,
            columna_divisora: undefined,
            consolidar_periodos: {
              value: "ULTIMO",
              label: "ULTIMO",
            },
          },
          {
            nombre: "VENTA_MONEDA_PROMEDIO",
            funcion: {
              value: "PROMEDIAR",
              label: "PROMEDIAR",
            },
            columna: ventaMonedaSelectedOption,
            columna_divisora: undefined,
            consolidar_periodos: {
              value: "ULTIMO",
              label: "ULTIMO",
            },
          },
          {
            nombre: "VENTA_UNIDADES",
            funcion: {
              value: "SUMAR",
              label: "SUMAR",
            },
            columna: ventaUnidadesSelectedOption,
            columna_divisora: undefined,
            consolidar_periodos: {
              value: "ULTIMO",
              label: "ULTIMO",
            },
          },
          {
            nombre: "VENTA_MONEDA",
            funcion: {
              value: "SUMAR",
              label: "SUMAR",
            },
            columna: ventaMonedaSelectedOption,
            columna_divisora: undefined,
            consolidar_periodos: {
              value: "ULTIMO",
              label: "ULTIMO",
            },
          },
        ],
      }));
    }
  }, []);

  return (
    <Stack sx={{ ...param_steps_container, gap: 2 }}>
      <Typography sx={step_title_styles}>{stepperData(t).steps[1]}</Typography>
      <Typography variant="h2" sx={{ ...form_subtitle_styles, margin: 0 }}>
        {t("marketBasket.config.primaryMetricsAtSkuLevel")}
      </Typography>
      <MessageBlock message={message} />
      <Stack sx={{ ...form_layouts_container_styles, width: "98%" }}>
        <Step2Form />
      </Stack>
      <Stack
        sx={{
          ...form_layouts_container_styles,
          width: "98%",
          justifyContent: "center",
          minHeight: "100px",
        }}
      >
        <Step2Calculation />
      </Stack>
    </Stack>
  );
};

export default Step2;
