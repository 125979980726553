import { columnsTypeOptions } from "../data";
import { IdOption } from "../../../configCriteria/types";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useRef, useState } from "react";
import CheckboxOption from "../../../forms/CheckboxOption";
import CustomInput from "../../../forms/CustomInput";
import Radio from "@mui/material/Radio";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  column_type_checkbox_container,
  column_type_replace_container,
  key_column_label_styles,
  key_column_select_styles,
} from "../../../../styles/app-styles";

const ColumnType = ({ column, file }) => {
  const { t } = useLanguage();
  const { setFilesColumnsTypes } = useCompanyParamProvider();
  const columnSelectRef: any = useRef(null);
  const defaultValue = column.tipo
    ? ({
        value: column.tipo,
        label: columnsTypeOptions.find((item) => item.value === column.tipo)
          ?.label,
      } as IdOption)
    : undefined;
  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(undefined, defaultValue);
  const [columnValidations, setColumnValidations] = useState<any>({
    requerida: column.requerida ? column.requerida : false,
    valores_nulos: column.valores_nulos ? column.valores_nulos : true,
    valores_unicos: column.valores_unicos ? column.valores_unicos : false,
  });
  const [replace, setReplace] = useState<boolean>(
    column.reemplazar ? true : false
  );
  const [numericValue, setNumericValue] = useState<string | number>(
    column.reemplazar ? column.reemplazar : ""
  );

  const toggleColumnValidations = (value: any) => {
    if (value === "requerida") {
      setColumnValidations((prev) => {
        return { ...prev, requerida: !columnValidations.requerida };
      });
    } else if (value === "valoresNulos") {
      setColumnValidations((prev) => {
        return { ...prev, valores_nulos: !columnValidations.valores_nulos };
      });
    } else if (value === "valoresUnicos") {
      setColumnValidations((prev) => {
        return { ...prev, valores_unicos: !columnValidations.valores_unicos };
      });
    }
  };
  const handleChange = (event: any) => {
    setNumericValue(event.target.value);
  };

  useEffect(() => {
    const updatedColumn = {
      value: column.value,
      label: column.label,
      tipo: columnSelectedOption?.value,
      reemplazar: replace ? numericValue : "",
      requerida: columnValidations.requerida,
      valores_nulos: columnValidations.valores_nulos,
      valores_unicos: columnValidations.valores_unicos,
    };
    setFilesColumnsTypes((prev: any) => {
      return prev.map((f: any) => {
        if (f.archivo_id === file.archivo_id) {
          return {
            ...f,
            columnas: f.columnas.map((item: any) => {
              if (item.value === updatedColumn.value) {
                return updatedColumn;
              } else {
                return item;
              }
            }),
          };
        } else {
          return f;
        }
      });
    });
  }, [
    columnSelectedOption,
    columnSelectedOption?.value,
    columnValidations.requerida,
    columnValidations.valores_nulos,
    columnValidations.valores_unicos,
    numericValue,
    replace,
  ]);

  return (
    <Stack sx={{ width: "100%" }}>
      <Stack sx={key_column_select_styles}>
        <Typography sx={key_column_label_styles}>{column.label}</Typography>
        <Stack sx={{ width: "100%" }}>
          <Select
            reference={columnSelectRef}
            isClearable
            styles={selectStyles(columnSelectedOption)}
            options={columnsTypeOptions}
            className="pp_select_schema"
            onChange={columnOptionChangeHandler}
            closeMenuOnSelect
            isSearchable
            placeholder={t(
              "preprorationParameterization.newSchemaContent.chooseAddValue"
            )}
            defaultValue={defaultValue}
          />
        </Stack>
      </Stack>
      <Stack
        sx={column_type_checkbox_container}
      >
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("requerida")}
          checked={columnValidations.requerida}
          id={`requerida-${column.label}-${file.nombre}`}
          labelText={t('companyParam.required')}
        />
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("valoresNulos")}
          checked={columnValidations.valores_nulos}
          id={`valoresNulos-${column.label}-${file.nombre}`}
          labelText={t('companyParam.allowNullValues')}
        />
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("valoresUnicos")}
          checked={columnValidations.valores_unicos}
          id={`valoresUnicos-${column.label}-${file.nombre}`}
          labelText={t('companyParam.onlyUniqueValues')}
        />
      </Stack>
      {columnSelectedOption && columnSelectedOption.value === "numerica" && (
        <Stack
          sx={column_type_replace_container}
        >
          <CheckboxOption
            toggleCheckbox={() => setReplace(!replace)}
            checked={replace}
            id="reemplazar"
            labelText={t('companyParam.replaceNoNumeric')}
            minWidth={"250px"}
          />
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Radio
              checked={numericValue === "PROMEDIO"}
              onChange={handleChange}
              value={"PROMEDIO"}
              name="radio-buttons"
              id="promedio"
              disabled={!replace}
            />
            <label
              style={{
                color: "var(--text-main)",
                fontSize: "14px",
              }}
              htmlFor={"promedio"}
            >
              {t('companyParam.average')}
            </label>
          </Stack>
          <Radio
            checked={numericValue !== "PROMEDIO"}
            onChange={handleChange}
            value={numericValue !== "PROMEDIO" ? numericValue : ""}
            name="radio-buttons"
            disabled={!replace}
          />
          <CustomInput
            value={numericValue !== "PROMEDIO" ? numericValue : ""}
            setValue={setNumericValue}
            placeholder={t('companyParam.value')}
            disabled={!replace}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ColumnType;
