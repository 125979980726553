import { IdOption } from "../../../configCriteria/types";
import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import CustomButton from "../../../buttons/Button";
import Dialog from "../../../dialog/Dialog";
import FilterForm from "../../../dataModel/downloadReport/FilterForm";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  MARKET_BASKET_COLUMNS_URL,
  MARKET_BASKET_VALUES_URL,
} from "../../../../api/axios";
import { stepper_buttons_container_styles2 } from "../../../../styles/app-styles";

const FiltersModal = ({ openFilterModal, toggleFilterModal }) => {
  const { t } = useLanguage();
  const {
    tabValue,
    setFilters,
    tempFilters,
    setTempFilters,
    dataColumnsMarketBasket,
    getColumnsMarketBasket,
    setSelectedRows
  } = useMarketBasketProvider();
  const columnFilterSelectedRef: any = useRef(null);
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);

  /********************************** Querys *************************************************/

  useEffect(() => {
    if (tabValue && tabValue !== "SIN ANALISIS") {
      getColumnsMarketBasket(MARKET_BASKET_COLUMNS_URL(tabValue));
    }
  }, [tabValue]);

  const onSuccessGetColumnValues = (data: any) => {
    setIncomingValuesOptions(data);
    setIsLoadingIncomingValuesOptions(false);
  };

  const { callApi: getColumnsValues } = useApi(
    MARKET_BASKET_VALUES_URL,
    "GET",
    undefined,
    undefined,
    onSuccessGetColumnValues,
    undefined,
    false
  );

  /************************************ Carga de datos ***************************************/

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const [
    columnFilterSelectedOptions,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());

  const columnLabels =
    dataColumnsMarketBasket &&
    tempFilters &&
    dataColumnsMarketBasket?.filter((option) => {
      return !tempFilters.some((param) => param.column.label === option.label);
    });

  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
  const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
    useState(false);

  useEffect(() => {
    if (columnFilterSelectedOptions && tabValue) {
      setIsLoadingIncomingValuesOptions(true);
      const filtros = {};
      tempFilters &&
        tempFilters.forEach((item) => {
          const { column, values } = item;
          filtros[column.label] = values.map((value) => value.value);
        });
      getColumnsValues(
        MARKET_BASKET_VALUES_URL(tabValue, columnFilterSelectedOptions?.value)
      );
    } else {
      setIncomingValuesOptions([]);
    }
  }, [columnFilterSelectedOptions, tabValue]);

  const handleCancel = () => {
    handleCleanColumnsSelects();
    toggleFilterModal();
  };

  const handleCleanColumnsSelects = () => {
    setTempFilters([]);
  };

  useEffect(() => {
    setTempFilters([]);
    setFilters({});
  }, [tabValue]);

  const postBody = () => {
    const filter = {} as any;
    tempFilters?.forEach((option: any) => {
      filter[option.column.label] = option.values.map((value) => value.value);
    });
    setFilters(filter);
    setSelectedRows([])
    toggleFilterModal();
  };

  return (
    <Dialog
      open={openFilterModal}
      handleClose={toggleFilterModal}
      title={"Filtros"}
      maxWidth={"xl"}
    >
      <Stack sx={{ width: "1000px", padding: 3, margin: "0 30px" }}>
        <FilterForm
          filters={tempFilters}
          setFilters={setTempFilters}
          setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
          columnFilterSelectedRef={columnFilterSelectedRef}
          columnLabels={columnLabels}
          columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler}
          columnFilterSelectedOption={columnFilterSelectedOptions}
          analysisSelectedOption={tabValue}
          selectedValues={selectedValues}
          incomingValuesOptions={incomingValuesOptions}
          setValuesSelectedOptions={setValuesSelectedOptions}
          setSelectedValues={setSelectedValues}
          isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions}
          valuesSelectedOptions={valuesSelectedOptions}
          selectOneOption={false}
        />
      </Stack>
      <Stack sx={stepper_buttons_container_styles2}>
        <CustomButton
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={() => handleCancel()}
        />
        <CustomButton
          title={t("general.clean")}
          color="magenta"
          type="button"
          onClick={() => {
            handleCleanColumnsSelects();
          }}
        />
        <CustomButton
          title={t("general.filter")}
          color="blue"
          type="button"
          disabled={tabValue && tabValue !== "SIN ANALISIS" ? false : true}
          onClick={() => postBody()}
        />
      </Stack>
    </Dialog>
  );
};

export default FiltersModal;
