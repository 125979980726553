import Grow from "@mui/material/Grow";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface BasicGridProps {
  data: any;
  Card: any;
  handleEdit?: any;
  handleCopy?: any;
  handleInfo?: any;
  handleDelete?: any;
  showInfo?: any;
  refetchData?: any;
  xs?: number;
  isAnalysis?: boolean;
}

export default function BasicGrid({
  data,
  Card,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
  refetchData,
  xs,
  isAnalysis
}: BasicGridProps) {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2.5}>
        {data &&
          data.map((item: any, index: number) => (
            <Grid item xs={xs ? 4 : 2.4} key={item.id}>
              <Grow in={true} {...{ timeout: 1000 + index * 500 }}>
                <Item
                  id="gridItem"
                  elevation={12}
                  sx={{
                    backgroundColor: "white",
                    color: "var(--text-main)",
                    borderRadius: "20px",
                    padding: "10px",
                  }}
                >
                  <Card
                    item={item}
                    handleEdit={handleEdit}
                    handleCopy={handleCopy}
                    handleInfo={handleInfo}
                    handleDelete={handleDelete}
                    showInfo={showInfo}
                    refetchData={refetchData}
                    isAnalysis={isAnalysis}
                  />
                </Item>
              </Grow>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
