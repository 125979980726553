import { useState } from "react";

type useSwitchType = (defaultValue: boolean) => [
  boolean,
  () => void
];

const useSwitch: useSwitchType = (defaultValue: boolean) => {
  const [checked, setChecked] = useState<boolean>(defaultValue); 

  const handleChange: () => void = () => {
    setChecked((prev: boolean) => !prev);
  };

  return [checked, handleChange];
};

export default useSwitch;
