import Dialog from "../../../../../dialog/Dialog";
import useLanguage from "../../../../../../context/LanguageProvider";
import { Stack } from "@mui/material";
import CustomStepper from "../../../../../Stepper/CustomStepper";
import { useContext, useState } from "react";
import CustomButton from "../../../../../buttons/Button";
import FormLayout from "../../../../../forms/FormLayout";
import { ConfirmationModal } from "../../../../../dialog";
import { GET_FILTERED_ROUTES_CANCEL, POST_GET_AVERAGE_VALUES } from "../../../../../../api/axios";
import useApi from "../../../../../../hooks/useApi";
import SimpleBackdrop from "../../../../../backdrop/SimpleBackdrop";
import { getInformationColumn } from "../../../../utils";
import Step2NewCancelRouteModal from "../CancelRoute/Step2NewCancelRouteModal";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import Step1NewModifyRouteModal from "./Step1NewModifyRouteModal";
import Step3NewModifyRouteModal from "./Step3NewModifyRouteModal";

interface NewCancelRouteModalProps {
    open: boolean;
    handleClose: () => void;
    dataGetAnalysisById,
    informationsColumns: any;
    modificationTypes: any[]
}

const NewModificationRouteModal = ({
    open,
    handleClose,
    dataGetAnalysisById,
    informationsColumns,
    modificationTypes
}: NewCancelRouteModalProps) => {

    const { t } = useLanguage();
    const analysisId = localStorage.getItem("analysisId") ? localStorage.getItem("analysisId") : undefined
    const { simulationState, simulationDispatch } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
    const [openConfirmationCloseModal, setOpenConfirmationCloseModal] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    /********************************* STEP 1 *****************************/

    const [filters, setFilters] = useState<any>([])

    /********************************* STEP 2 *****************************/

    const [selectedModifyRoutes, setSelectedModifyRoutes] = useState<any[]>([])


    const addSelectedToModifyRoutes = (data) => {
        setSelectedModifyRoutes(data);
    }

    const {
        data: dataPostGetFilteredRoutes,
        isLoading: isLoadingpostGetFilteredRoutes,
        callApi: postGetFilteredRoutes,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        undefined,
        undefined,
        true
    );

    /********************************* STEP 3 *****************************/
    const [selectedToModifyRoutesStep3, setSelectedToModifyRoutesStep3] = useState<any[]>([]);
    const [columnsValues, setColumnsValues] = useState<any[]>([]);
    const [rowsAveragesValues, setRowsAveragesValues] = useState<any[]>();

    const onSuccessData = (data: any) => {
        if (data) {
            setRowsAveragesValues([data]);
            data.id = data._id;
            delete data._id;
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const {
        isLoading: isLoadingPostGetAverageValues,
        callApi: postGetAverageValues,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        onSuccessData,
        undefined,
        false
    );

    /********************************* MANEJO DE FUNCIONES *****************************/

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        if (activeStep === 0) {
            handleGetRoutes()
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 1) {
            const modificationTypeNames = modificationTypes.map(modType => modType.nombre);
            const codRuta = informationsColumns && informationsColumns?.find(info => info.informacion === "Identificador único de ruta")?.columna
            const filteredData = dataPostGetFilteredRoutes && dataPostGetFilteredRoutes.filter(item => selectedModifyRoutes && selectedModifyRoutes.includes(item._id as number)).map((item) => {
                const newItem = {
                    _id: item[codRuta],
                    [codRuta]: item[codRuta]
                }
                modificationTypeNames.forEach((typeName) => {
                    newItem[`${getInformationColumn(informationsColumns, typeName)}_OLD`] = item[getInformationColumn(informationsColumns, typeName)]
                })
                return newItem;
            });
            setSelectedToModifyRoutesStep3(filteredData)
            const getAveragesValuesConfig = {
                analisis: analysisId,
                [informationsColumns?.find(info => info.informacion === "Identificador único de ruta")?.columna]: filteredData.map(item => item._id),
            }
            postGetAverageValues(POST_GET_AVERAGE_VALUES, getAveragesValuesConfig)
        }
    };

    const labels = {
        steps: [
            t("simulatorTexts.registerRoutes.newRouteModal.steps.paramSelection"),
            t("simulatorTexts.registerRoutes.newRouteModal.steps.routesSelection"),
            t("simulatorTexts.registerRoutes.newRouteModal.steps.routesToModify"),
        ]
    }

    const validateContinueStepper = () => {
        if (activeStep === 0 && filters && filters?.length === 0) {
            return true
        } else if (activeStep === 1 && selectedModifyRoutes && selectedModifyRoutes.length === 0) {
            return true
        } else if (activeStep === 2 && columnsValues && columnsValues.length === 0) {
            return true
        } else {
            return false
        }
    }

    const closeAndClean = () => {
        setActiveStep(0)
        handleClose()
        setFilters([])
        setSelectedModifyRoutes([])
        setOpenConfirmationCloseModal(false)
        setColumnsValues([])
        setSelectedToModifyRoutesStep3([])
    }

    const handleConfirmationCloseModal = () => {
        if (
            filters && filters.length > 0
        ) {
            setOpenConfirmationCloseModal(true)
        } else {
            closeAndClean()
        }
    }

    const handleGetRoutes = () => {
        const newConfig: { filtros: { columna: string; valor: string }[] } = {
            filtros: [],
        };

        filters && filters.forEach(item => {
            const { column, values } = item;
            newConfig.filtros.push({
                columna: column.label,
                valor: values[0].value
            });
        });

        analysisId && postGetFilteredRoutes(GET_FILTERED_ROUTES_CANCEL(analysisId), newConfig)
    }

    const handleFinish = () => {
        const newConfig: any = {
            tipo: "modificacion",
            rutas: [],
            id: simulationState.configurations.length
        }
        selectedToModifyRoutesStep3.forEach(obj => {
            const valores = columnsValues.map((item) => ({
                [item.id]: item.values[0].value
            })).reduce((resultado, objeto) => {
                return { ...resultado, ...objeto };
            }, {});
            const filtros = filters.map((filter) => {
                return {
                    columna: filter.column.label,
                    valor: filter.values[0].value
                }
            })
            const newObj = {
                ...obj,
                ...valores,
                filtros
            };
            newConfig.rutas.push(newObj);
        });
        closeAndClean();
        simulationDispatch({
            type: "SET_CONFIGURATIONS", payload: [
                ...simulationState.configurations,
                newConfig
            ]
        });
    }

    const routesInUse: string[] = [];

    simulationState.configurations.filter(config => config.tipo === "modificacion") && simulationState.configurations.filter(config => config.tipo === "modificacion")?.flat()?.length > 0 && simulationState.configurations.filter(config => config.tipo === "modificacion").flat()?.forEach(elemento => {
        routesInUse.push(elemento.id);
    });
    simulationState.configurations.filter(config => config.tipo === "baja") && simulationState.configurations.filter(config => config.tipo === "baja").length > 0 && simulationState.configurations.filter(config => config.tipo === "baja")?.forEach(elemento => {
        elemento.rutas.map((item) => {
            routesInUse.push(item._id)
        })
    });

    const filteredRoutes = getInformationColumn(informationsColumns, "Identificador único de ruta") && dataPostGetFilteredRoutes && dataPostGetFilteredRoutes.filter(route => {
        return !routesInUse.includes(route[getInformationColumn(informationsColumns, "Identificador único de ruta")]);
    })
        && getInformationColumn(informationsColumns, "Identificador único de ruta") && dataPostGetFilteredRoutes.filter(route => {
            return !routesInUse.includes(route[getInformationColumn(informationsColumns, "Identificador único de ruta")])
        });

    return (
        <Dialog
            open={open}
            handleClose={handleConfirmationCloseModal}
            title={t("simulatorTexts.modifiedRoutes.modifiedRoute")}
            maxWidth={"lg"}
        >
            <SimpleBackdrop open={isLoadingpostGetFilteredRoutes || isLoadingPostGetAverageValues} message={t("simulatorTexts.canceledRoutes.newCancelRouteModal.loading")} />
            <CustomStepper activeStep={activeStep} data={labels} />
            <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                padding="0 30px 30px 30px"
                position="relative"
                sx={{
                    width: "100%",
                    maxHeight: "700px",
                    height: "100%",
                    pt: "20px"
                }}
            >
                <FormLayout
                    width={"100%"}
                    backgroundColor={
                        "white"
                    }
                >
                    {activeStep === 0 && <Step1NewModifyRouteModal modifyFilters={filters} setModifyFilters={setFilters} dataGetAnalysisById={dataGetAnalysisById} />}
                    {activeStep === 1 && <Step2NewCancelRouteModal setSelectedRoutes={addSelectedToModifyRoutes} tableRoutesData={filteredRoutes ? filteredRoutes : []} selectedRoutes={selectedModifyRoutes} selectedCancelRoutesStep3={selectedToModifyRoutesStep3} setSelectedCancelRoutesStep3={setSelectedToModifyRoutesStep3} informationsColumns={informationsColumns} />}
                    {activeStep === 2 && <Step3NewModifyRouteModal modificationTypes={modificationTypes} informationsColumns={informationsColumns} columnsValues={columnsValues} setColumnsValues={setColumnsValues} rowsAveragesValues={rowsAveragesValues} dataPostGetFilteredRoutes={dataPostGetFilteredRoutes} selectedModifyRoutes={selectedModifyRoutes} />}
                </FormLayout>
            </Stack>
            <Stack sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
            }}>
                {activeStep !== 0 &&
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.goBack")}
                        color="grey"
                        type="button"
                        onClick={handleBack}
                    />
                }
                {activeStep === 2 ?
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.finish")}
                        color="blue-greeny"
                        type="button"
                        disabled={validateContinueStepper()}
                        onClick={handleFinish}
                    /> :
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.continue")}
                        color="blue"
                        type="button"
                        onClick={() => handleNext()}
                        disabled={validateContinueStepper()}
                    />
                }
            </Stack>
            <ConfirmationModal
                open={openConfirmationCloseModal}
                handleClose={() => setOpenConfirmationCloseModal(false)}
                handleAccept={closeAndClean}
                message={t("simulatorTexts.registerRoutes.newRouteModal.closeModalWithInfo")}
                title={t("simulatorTexts.modifiedRoutes.modifiedRoute")}
            />
        </Dialog>
    )
}

export default NewModificationRouteModal