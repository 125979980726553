import Highcharts from "highcharts";
import VennModule from "highcharts/modules/venn.js";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { convertUnit } from "../../utils/util";
import useDialog from "../../hooks/useDialog";
import TableModal from "../tablesTools/TableModal";

VennModule(Highcharts);
Highcharts.AST.allowedAttributes.push("onclick");

const VennChart = (data: any) => {
  const [dataChart, setDataChart] = useState<any>(null);
  const [tableData, setTableData] = useState<any>({ set: null, data: null });
  const [openModal, handleToggleOpenModal] = useDialog();
  const [modalTitle, setModalTitle] = useState<string>("");

  useEffect(() => {
    if (data) {
      setDataChart(data.data);
    }
  }, [data]);

  (window as any).dataAmbos = dataChart?.ambos?.data;
  (window as any).dataDerecha = dataChart?.derecha?.data;
  (window as any).dataIzquierda = dataChart?.izquierda?.data;

  (window as any).handleOpenTable = function (set: string, data: any) {
    if (set === "Presentes en ambos") {
      setModalTitle("Presentes en ambos");
      setTableData({ data: dataChart?.ambos?.data, set: set });
    } else if (set === "Presentes sólo en archivo 1") {
      setModalTitle(`Presentes solo en ${dataChart?.izquierda?.archivo}`);
      setTableData({ data: dataChart?.izquierda?.data, set: set });
      handleToggleOpenModal();
    } else {
      setModalTitle(`Presentes solo en ${dataChart?.derecha?.archivo}`);
      setTableData({ data: dataChart?.derecha?.data, set: set });
      handleToggleOpenModal();
    }
  };

  const options = dataChart
    ? {
        chart: {
          backgroundColor: "#f2f2f2",
        },
        tooltip: {
          useHTML: true,
          style: {
            pointerEvents: "auto",
          },
          hideDelay: 1000,
          point: {
            sets: [""],
            percentage: "",
            dataTable: "",
          },
          formatter: function () {
            if (this.point.sets.length > 1) {
              return "No hay detalles";
            } else {
              if (this.point.sets[0] === "Presentes sólo en archivo 1") {
                return `<div class="tooltipContainerStyles"> <b class="tooltipLabel"> ${this.point.sets[0]}: ${this.point?.percentage}</b>
            <button type="button" class="buttonTooltip" onclick="handleOpenTable('Presentes sólo en archivo 1', dataIzquierda)">Ver detalle</button> <div>`;
              } else {
                return `<div class="tooltipContainerStyles"> <b class="tooltipLabel"> ${this.point.sets[0]}: ${this.point?.percentage}</b>
            <button type="button" class="buttonTooltip" onclick="handleOpenTable('Presentes sólo en archivo 2', dataDerecha)">Ver detalle</button> <div>`;
              }
            }
          },
        },
        plotOptions: {
          venn: {
            dataLabels: {
              enabled: true,
              style: {
                width: 50,
                fontSize: "24px",
                color: "#0D3940",
              },
            },
          },
        },
        legend: {
          layout: "vertical",
          options: {
            sets: [0],
          },
          labelFormatter: function () {
            if (this.options.sets.length > 1) {
              return "Presentes en ambos";
            } else if (
              `${this.options.sets[0]}` === "Presentes sólo en archivo 1"
            ) {
              return `Presentes solo en ${dataChart?.izquierda?.archivo}` /* this.options.sets[0] */;
            } else if (
              `${this.options.sets[0]}` === "Presentes sólo en archivo 2"
            ) {
              return `Presentes solo en ${dataChart?.derecha?.archivo}`;
            }
          },
          align: "left",
          verticalAlign: "middle",
          itemMarginTop: 10,
          itemMarginBottom: 10,
        },
        accessibility: {
          enabled: false,
        },
        series: [
          {
            type: "venn",
            showInLegend: true,
            name: null,
            data: [
              {
                sets: ["Presentes sólo en archivo 1"],
                percentage: convertUnit(
                  "porcentaje",
                  dataChart.izquierda.porcentaje
                ),
                value: 2,
                name: dataChart.izquierda.filas.toString(),
                color: "var(--light-violet)",
                dataTable: dataChart.izquierda.data,
                events: {
                  series: {
                    points: [
                      {
                        name: "",
                        graphic: {
                          hide: () => {
                            console.log("prueba");
                          },
                          show: () => {
                            console.log("prueba");
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                events: {
                  series: {
                    points: [
                      {
                        name: "",
                        graphic: {
                          hide: () => {
                            console.log("prueba");
                          },
                          show: () => {
                            console.log("prueba");
                          },
                        },
                      },
                    ],
                  },
                },
                sets: ["Presentes sólo en archivo 2"],
                percentage: convertUnit(
                  "porcentaje",
                  dataChart.derecha.porcentaje
                ),
                color: "var(--text-secondary)",
                value: 2,
                name: dataChart.derecha.filas.toString(),
                dataTable: dataChart.derecha.data,
              },
              {
                sets: [
                  "Presentes sólo en archivo 1",
                  "Presentes sólo en archivo 2",
                ],
                percentage: convertUnit(
                  "porcentaje",
                  dataChart.ambos.porcentaje
                ),
                value: 1,
                color: "var(--bg-error-hover)",
                name: dataChart.ambos.filas.toString(),
                dataTable: dataChart.ambos.data,
              },
            ],
          },
        ],
        title: {
          text: null,
        },
      }
    : null;

  return (
    <div style={{ width: "100%" }}>
      {dataChart && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
      <TableModal
        data={tableData.data}
        open={openModal}
        handleClose={handleToggleOpenModal}
        title={modalTitle}
      />
    </div>
  );
};

export default VennChart;
