import { isDuplicatedName } from "../../utils";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import TextField from "@mui/material/TextField";
import useLanguage from "../../../../context/LanguageProvider";

interface FileNameInputProps {
  fileNameRef: any;
  fileName: string;
  setfileName: any;
  setMessage: any;
  dataFilesList: any;
  label?: string;
  placeholder?: string;
}

const FileNameInput = ({
  fileNameRef,
  fileName,
  setfileName,
  setMessage,
  dataFilesList,
  label,
  placeholder
}: FileNameInputProps) => {
  const { t } = useLanguage();

  const handleChangeName = (e: any) => {
    setfileName(e.target.value);
    if (e.target.value === "") {
      setMessage({ type: "error", text: t("general.requiredField") });
    } else if (isDuplicatedName(e.target.value, dataFilesList)) {
      setMessage({ type: "error", text: t("general.repeatedName") });
    } else {
      setMessage({ type: "success", text: "" });
    }
  };

  return (
    <FormSelectLayout title={label ? label : t("companyParam.fileNameToUse")} required={true}>
      <TextField
        type="text"
        ref={fileNameRef}
        variant="outlined"
        fullWidth
        placeholder={placeholder ? placeholder : t("companyParam.fileNameToUse")}
        value={fileName}
        onChange={handleChangeName}
        size="small"
        required={true}
        error={isDuplicatedName(fileName, dataFilesList) || fileName === ""}
        FormHelperTextProps={{
          sx: {
            color: "var(--text-error)",
            margin: 0,
          },
        }}
      />
    </FormSelectLayout>
  );
};

export default FileNameInput;
