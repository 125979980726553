import { button_styles } from "../../styles/app-styles";
import { makeStyles } from "@material-ui/core/styles";
interface ButtonProps {
  title: string | any;
  color:
    | "blue"
    | "magenta"
    | "grey"
    | "blue-greeny"
    | "light-grey"
    | "outlined";
  type: "button" | "submit" | "reset" | undefined;
  onClick?: (() => void) | ((e: any) => void) | undefined;
  width?: number | undefined;
  margin?: boolean;
  disabled?: boolean;
}

const CustomButton = ({
  title,
  color,
  type,
  onClick,
  width,
  margin,
  disabled,
}: ButtonProps) => {
  const getColor = (
    color:
      | "blue"
      | "magenta"
      | "grey"
      | "blue-greeny"
      | "light-grey"
      | "outlined"
  ) => {
    switch (color) {
      case "blue":
        return "var(--bg-main)";
      case "magenta":
        return "var(--bg-error)";
      case "grey":
        return "var(--bg-info)";
      case "blue-greeny":
        return "var(--bg-success)";
      case "light-grey":
        return "var(--bg-neutral-hover)";
      case "outlined":
        return "outlined";
      default:
        return "var(--bg-main)";
    }
  };

  const useStyles = makeStyles({
    button: button_styles(
      width ? `${width}px` : "150px",
      color ? getColor(color) : "var(--text-main)",
      disabled,
      margin,
    ),
  });

  const classes = useStyles();

  return (
    <button className={classes.button} type={type} onClick={onClick} disabled={disabled}>
      {title}
    </button>
  );
};

export default CustomButton;
