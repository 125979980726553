import { useContext, useDebugValue } from "react";
import AuthContext from "../context/AuthProvider";

/**
 * @name useAuth
 * @description Hook que devuelve el estado de autenticación
 * @returns {function}
 * @category Hooks
 */

const useAuth = () => {
    const { auth }: any = useContext(AuthContext);
    useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out")
   
    return useContext(AuthContext);
}

export default useAuth;