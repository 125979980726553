import { createContext, useState } from "react";

const AuthContext = createContext({});

/**
 * @name AuthProvider
 * @description Contexto de autenticación
 * @param param0
 * @returns {JSX.Element}
 */

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(
    /* JSON.parse(localStorage.getItem("persist") || '""') || false */ true
  ); //Para futuro, descomentar

  
  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
