import Card from "../../card/Card";
import CodeMatchingCardContent from "./CodeMatchingCardContent";

const CodeMatchingCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
}: any) => {
  return (
    <Card
      item={item}
      handleEdit={handleEdit}
      handleCopy={handleCopy}
      handleInfo={handleInfo}
      handleDelete={handleDelete}
      showInfo={showInfo}
    >
      <CodeMatchingCardContent item={item} />
    </Card>
  );
};

export default CodeMatchingCard;
