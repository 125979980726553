import { Box, Tooltip } from "@mui/material";
/* import { card_title_container_styles } from "../../styles/app-styles";
import { convertNumberToMoney } from "../parameterization/utils";
import { convertUnit, formatUnits } from "./utils"; */
/* import { GET_INDICADOR_CHART } from "../../api/axios";
import Dialog from "../dialog/Dialog";
import LinesChart from "../Charts/LinesChart"; */
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import useDialog from "../../../../hooks/useDialog";
import { card_title_container_styles } from "../../../../styles/app-styles";
import { convertNumberToMoney } from "../../../parameterization/utils";
import { convertUnit, formatUnits } from "../../../report/utils";
import Dialog from "../../../dialog/Dialog";
import LinesChart from "../../../Charts/LinesChart";
/* import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider"; */

interface IndicatorProps {
  valor: number;
  unidad: string;
  kpi: string;
  drilldown?: any;
  minHeight?: string;
  padding?: string;
}

const IndicatorCard = ({
  valor,
  unidad,
  kpi,
  drilldown,
  minHeight,
  padding,
}: IndicatorProps) => {
  const { t } = useLanguage();
  const [openChartsModal, handleToggleChartsModal] = useDialog();
  
  return (
    <>
      <Stack
        sx={{
          padding: "2px 4px 2px 4px",
          minHeight: "130px",
          ":hover": {
            cursor: drilldown ? "pointer" : "default",
          },
        }}
        alignItems="center"
        justifyContent={"flex-start"}
        onClick={drilldown ? () => handleToggleChartsModal() : () => <></>}
      >
        <Box sx={card_title_container_styles}>
          <Tooltip
            placement="top"
            title={
              unidad === t("kpi.coin")
                ? convertNumberToMoney(valor)
                : unidad === t("kpi.units")
                ? formatUnits(valor)
                : ""
            }
            sx={{ verticalAlign: "center", fontSize: "16px", zIndex: "100" }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "var(--text-success)",
                fontWeight: "750",
                textTransform: "uppercase",
                fontSize: "1.8rem",
              }}
            >
              {convertUnit(unidad, valor)}
            </Typography>
          </Tooltip>
        </Box>

        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "1.2rem",
            lineHeight: 1.4,
            ":hover": {
              cursor: drilldown ? "pointer" : "default",
              color: drilldown ? "var(--text-success)" : "default",
            },
          }}
          color="var(--violet)"
        >
          {kpi}
        </Typography>
      </Stack>
      {drilldown && (
        <Dialog
          sx={{ width: "90vw" }}
          open={openChartsModal}
          handleClose={handleToggleChartsModal}
          maxWidth="xl"
          title={drilldown?.titulo_grafico}
        >
          <Stack sx={{ width: "100%" }}>
            <LinesChart results={drilldown} />
          </Stack>
        </Dialog>
      )}
    </>
  );
};

export default IndicatorCard;
