import { Button } from "../buttons";
import { Tab } from "../colorTabs/types";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChartCard from "./ChartCard";
import ChartSkeleton from "../skeleton/ChartSkeleton";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import ErrorChartCard from "./ErrorChartCard";
import PeriodsFilter from "../periods/PeriodsFilter";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";
import useTabs from "../../hooks/useTabs";
import { GET_GRAFICOS, GRUPOS_GRAFICOS } from "../../api/axios";
import Boxplot from "./Boxplot";

const AllCharts = () => {
  const { t } = useLanguage();

  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();
  const {
    data: dataGetGroups,
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
    refetch: refetchGroups,
  } = useApiQuery(GRUPOS_GRAFICOS, true, t("charts.allCharts.groupsError"));

  const {
    data: dataGetCharts,
    error: errorCharts,
    isLoading: isLoadingCharts,
    callApi: callGetCharts,
  } = useApi(
    undefined,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const [tabValue, handleChange, setTabValue] = useTabs("");
  const [tabs, setTabs] = useState<Tab[]>([{ value: "", label: "" }]);

  useEffect(() => {
    if (dataGetGroups && tabValue) {
      callGetCharts(GET_GRAFICOS(tabValue));
    }
  }, [dataGetGroups, tabValue]);

  useEffect(() => {
    if (dataGetGroups && dataGetGroups.length > 0) {
      const groups = dataGetGroups || [];
      const tabs: Tab[] = groups.map((group: Tab) => {
        return {
          value: group.value.toString(),
          label: group.label,
        };
      });
      setTabs(tabs);
      setTabValue(tabs[0].value);
    }
  }, [dataGetGroups]);

  const handleClosePeriodsModal = () => {
    refetchGroups();
    handleTogglePeriodsDialog();
  };

  return (
    <ComponentLayout
      title={t("charts.allCharts.chartsLabel")}
      icon={<BarChartIcon />}
      rightComponent={
        <Button
          title={t("charts.allCharts.periodsLabel")}
          color="blue"
          type="button"
          onClick={() => handleTogglePeriodsDialog()}
        />
      }
    >
      <>
        <SimpleBackdrop
          open={isLoadingGroups || isLoadingCharts}
          message={
            isLoadingGroups
              ? t("charts.allCharts.downloadingGroups")
              : t("charts.allCharts.downloadingCharts")
          }
        />
        {dataGetGroups && dataGetGroups.length > 0 && (
          <ColorTabs
            value={tabValue}
            handleChange={handleChange}
            tabs={tabs}
            marginBottom="10px"
          />
        )}
        {!isLoadingCharts &&
        !errorCharts &&
        dataGetCharts &&
        dataGetCharts.length > 0 ? (
          <Stack
            sx={{
              width: "100%",
              maxWidth: "95vw !important",
              alignItems: "center",
            }}
          >
            {dataGetCharts?.map((chart: any) =>
              chart.posee_error ? (
                <ErrorChartCard key={chart.titulo_grafico} chart={chart} />
              ) : (
                <ChartCard key={chart.titulo_grafico} chart={chart} />
              )
            )}
          </Stack>
        ) : isLoadingCharts ? (
          <ChartSkeleton />
        ) : (
          <Stack
            sx={{
              width: "95%",
              height: "60vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--text-info)" }}
            >
              {isErrorGroups
                ? t("charts.allCharts.groupsDownloadingError")
                : errorCharts
                ? t("charts.allCharts.chartsDownloadingError")
                : !isLoadingGroups
                ? t("charts.allCharts.noActiveChartsError")
                : ""}
            </Typography>
          </Stack>
        )} 
        <PeriodsFilter
          openPeriodsDialog={openPeriodsDialog}
          handleTogglePeriodsDialog={handleClosePeriodsModal}
        />
      </>
    </ComponentLayout>
  );
};

export default AllCharts;
