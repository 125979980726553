import { Button as CustomButtom } from "../../../buttons";
import { getGrouperIdByRowId, prepareEditData } from "../../utils";
import { GET_CHARTS_RESULTS_URL, PRORRATEO_GASTOS_URL } from "../../../../api/axios";
import { useParameterization } from "../../../../hooks/useParameterization";
import CustomInput from "../../../forms/CustomInput";
import CustomizedDialogs from "../../../dialog/Dialog";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import { useRef } from "react";

interface IEditNameModal {
  openEditNameDialog: boolean;
  handleToggleEditNameDialog: () => void;
  refetchRowsData: () => void;
  idToEdit: number | undefined;
  rowsResult: any[];
}

const EditNameModal = ({
  openEditNameDialog,
  handleToggleEditNameDialog,
  refetchRowsData,
  idToEdit,
  rowsResult
}: IEditNameModal) => {
  const { t } = useLanguage();
  const newNameRef = useRef<HTMLInputElement>(null);
  const {
    parameterizationState,
    parameterizationDispatch,
    getChartsResults
  } = useParameterization();
  const { 
    bucketSelectedOption,
    newName 
  } = parameterizationState;

  const onSuccessPutGastosProrrateo = () => {   
    if (parameterizationState?.bucketSelectedOption?.value) {
      getChartsResults(
        `${GET_CHARTS_RESULTS_URL}?bucket_id=${parameterizationState?.bucketSelectedOption?.value}`
      );
    }
    refetchRowsData();
    parameterizationDispatch({
      type: "SET_SELECTION_MODEL",
      payload: [],
    });
    parameterizationDispatch({
      type: "SET_NEW_NAME",
      payload: "",
    });
  };

  const onErrorPutGastosProrrateo = () => {
    parameterizationDispatch({
      type: "SET_SELECTION_MODEL",
      payload: [],
    });
    parameterizationDispatch({
      type: "SET_NEW_NAME",
      payload: "",
    });
  };

  const {
    callApi: putGastosProrrateo,
    isLoading: isLoadingPutGastosProrrateo,
  } = useApi(
    PRORRATEO_GASTOS_URL,
    "PUT",
    t("modelParameterizationTexts.modelTable.codes"),
    undefined,
    onSuccessPutGastosProrrateo,
    onErrorPutGastosProrrateo
  );

  const handleEditName = () => {
    handleToggleEditNameDialog();
    bucketSelectedOption &&
      putGastosProrrateo(
        PRORRATEO_GASTOS_URL,
        prepareEditData(
          t("modelParameterizationTexts.modelTable.grouper"),
          bucketSelectedOption.value,
          getGrouperIdByRowId(idToEdit, rowsResult),
          newName
        )
      );
  };
  return (
    <CustomizedDialogs
      open={openEditNameDialog}
      handleClose={handleToggleEditNameDialog}
      title={t("modelParameterizationTexts.modelTable.editName")}
      maxWidth="sm"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomInput
          value={newName}
          setValue={(value) =>
            parameterizationDispatch({
              type: "SET_NEW_NAME",
              payload: value,
            })
          }
          inputRef={newNameRef}
          placeholder={t("modelParameterizationTexts.modelTable.newName")}
          required={false}
          type="text"
          autoFocus={true}
          maxLength={100}
        />
        <CustomButtom
          title={t("modelParameterizationTexts.modelTable.apply")}
          color="blue-greeny"
          type="button"
          onClick={() => handleEditName()}
          disabled={!newName}
        />
      </div>
      <SimpleBackdrop
        open={isLoadingPutGastosProrrateo}
        message={t("modelParameterizationTexts.modelTable.editingName")}
      />
    </CustomizedDialogs>
  );
};

export default EditNameModal;
