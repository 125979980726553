import { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";

const InfoContext = createContext({});

export const InfoProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useAuth() as any;
  const [hasCompletedStages, setHasCompletedStages] = useState<
    boolean | undefined | "loading"
  >(undefined);
  const [warningWithoutStages, setWarningWithoutStages] =
    useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [company, setCompany] = useState<any>();
  const [userRolData, setUserRolData] = useState<any>();
  const [companyData, setCompanyData] = useState<any>();

  useEffect(() => {    
    auth.roles && setUserRolData(auth.roles);     
  }, [auth, auth.roles]);

  return (
    <InfoContext.Provider
      value={{
        hasCompletedStages,
        setHasCompletedStages,
        warningWithoutStages,
        setWarningWithoutStages,
        loggedIn,
        setLoggedIn,
        company,
        setCompany,
        userRolData,
        setUserRolData,
        companyData, setCompanyData
      }}
    >
      {children}
    </InfoContext.Provider>
  );
};

export default InfoContext;
