import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { renderCellMarketBasket } from "../utils";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

interface ITransformDataMarketBasketTable {
  (t?: any, data?: any, onClickSKU?: any, transformColumnNames?: any): {
    columns: GridColDef<any, any, any>[];
    rows: any;
  };
}

export const transformDataMarketBasketTable: ITransformDataMarketBasketTable = (
  t,
  data = [],
  onClickSKU,
  transformColumnNames
) => {
  const tableColumns = data && data.length > 0 ? Object.keys(data[0]) : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(t, columna) : columna}
        </strong>
      ),
      renderCell: (params: any) =>
        renderCellMarketBasket(
          params,
          Stack,
          Typography,
          onClickSKU,
          SearchIcon,
          t
        ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
