import "./styles.css";
import { Button } from "../buttons";
import { convertNumberToMoney } from "../parameterization/utils";
import { EXPENSES_URL, EXPENSES_VIEW_URL } from "../../api/axios";
import { GridColDef } from "@mui/x-data-grid";
import { Transformation } from "./types";
import { transformData } from "../virtualizatedTable/transformData";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useState, useEffect, useRef } from "react";
import ComponentLayout from "../layout/ComponentLayout";
import ConfirmationModal from "../dialog/ConfirmationModal";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import Dialog from "../dialog/Dialog";
import ModalContent from "./ModalContent";
import SettingsIcon from "@mui/icons-material/Settings";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import VirtualizatedTable from "../virtualizatedTable/VirtualizedTable";
import {
  Item,
  noColumnsColumn,
  optionsColumns,
  transformDataToSend,
  transformViewData,
} from "./utils";

/**
 * @component
 * @name ExpensesSettings
 * @description - Componente que muestra las tablas archivos por períodos ni bien se renderiza el componente, validaciones por período al accionar el switch y la previsualización de un archivo al hacer click en el ícono de previsualizar.  También permite descargar un archivo al hacer click en el ícono para descarga de cada archivo.  El botón de correr validaciones acciona el proceso de validación de todos los archivos por período, y el de subir archivo permite cargar otro archivo a la tabla de archivos por período, siendo posible seleccionar más d euno a la vez.
 * @returns {JSX.Element}
 * @category Data Management
 */

const ExpensesSettings = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [transformedValues, setTransformedValues] = useState<Transformation[]>(
    []
  );
  const columnSelectRef: any = useRef();
  const valueSelectRef: any = useRef();
  const [columnSelected, setColumnSelected] = useState<string>("");
  const [valueSelected, setValueSelected] = useState<string>("");
  const [newValueError, setNewValueError] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [columnsPropsArray, setColumnsPropsArray] = useState<any[]>([]);
  const [openTransformModal, handleToggleTransformModal] = useDialog();
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();
  const [newVisibility, setNewVisibility] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalFilteredAmount, setTotalFilteredAmount] = useState(0);
  const {
    data: dataGetExpenses,
    isLoading: isLoadingGetExpenses,
    isSuccess: isSuccessGetExpenses,
    isError: isErrorGetExpenses,
    refetch: refetchGetExpenses,
  } = useApiQuery(EXPENSES_URL, false, t("expensesSettings.getExpenses.error"));
  const { data: dataGetView, refetch: refetchGetView } = useApiQuery(
    EXPENSES_VIEW_URL,
    false,
    t("expensesSettings.getView.error")
  );

  const onSuccessSendTransformations = () => {
    refetchGetExpenses();
    setSelectedRows([]);
    cleanValues();
  };
  
  const {
    isLoading: isLoadingSendTransformations,
    callApi: sendTransformations,
  } = useApi(
    EXPENSES_URL,
    "PUT",
    t("expensesSettings.sendTransformations.codes"),
    undefined,
    onSuccessSendTransformations,
    undefined,
    true
  );

  const { isLoading: isLoadingSaveView, callApi: saveView } = useApi(
    EXPENSES_VIEW_URL,
    "POST",
    t("expensesSettings.saveView.codes"),
    undefined,
    refetchGetView,
    undefined,
    true
  );

  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (isSuccessGetExpenses && dataGetExpenses.length) {
      const { columns, rows } = transformData(
        dataGetExpenses,
        dataGetExpenses,
        dataGetView
      );
      setColumns(columns);
      setRows(rows);
      setTotalAmount(
        dataGetExpenses.reduce((total, gasto) => total + gasto.MONTO_GASTO, 0)
      );
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [dataGetExpenses]);

  const cleanValues = () => {
    valueSelectRef?.current?.clearValue();
    columnSelectRef?.current?.clearValue();
    setTransformedValues([]);
    setColumnSelected("");
    setValueSelected("");
    setNewValueError("");
  };

  const handleTransform = () => {
    handleToggleConfirmationModal();
    handleToggleTransformModal();
    sendTransformations(undefined, {
      registros_id: selectedRows,
      modificacion: transformedValues,
    });
  };

  const saveViewHandler = () => {
    saveView(
      undefined,
      transformDataToSend(dataGetView, newVisibility, columnsPropsArray)
    );
  };

  return (
    <ComponentLayout
      title={t("expensesSettings.expensesSettingsTexts.expensesConfig")}
      icon={<SettingsIcon />}
    >
      <section className="cc_container">
        <SimpleBackdrop
          open={isLoadingSendTransformations}
          message={t("expensesSettings.expensesSettingsTexts.applyingTrans")}
        />
        <SimpleBackdrop
          open={isLoadingSaveView}
          message={t("expensesSettings.expensesSettingsTexts.savingView")}
        />
        <Stack
          spacing={1}
          sx={{
            width: "100%",
          }}
          mb="15px"
        >
          <Stack
            direction="row"
            spacing={4}
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            {totalFilteredAmount !== 0 && (
              <Item
                elevation={6}
                sx={{ padding: "8px", height: "50px", minWidth: "180px" }}
              >
                <Typography
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "var(--text-main)",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {convertNumberToMoney(totalFilteredAmount)}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "var(--text-disabled)",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "15px",
                  }}
                >
                  {t("expensesSettings.expensesSettingsTexts.filteredExpense")}
                </Typography>
              </Item>
            )}
            {totalAmount !== 0 && (
              <Item
                elevation={6}
                sx={{ padding: "8px", height: "50px", minWidth: "180px" }}
              >
                <Typography
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "var(--text-main)",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {convertNumberToMoney(totalAmount)}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "var(--text-disabled)",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "15px",
                  }}
                >
                  {t("expensesSettings.expensesSettingsTexts.totalExpense")}
                </Typography>
              </Item>
            )}

            {userRolData && userRolData.configuracion_de_gastos.visualizacion_y_edicion &&
              (selectedRows.length === 0 ? (
                <Stack
                  sx={{
                    verticalAlign: "center",
                    fontSize: "16px",
                    position: "absolute",
                    right: "2.5%",
                  }}
                >
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={t(
                      "expensesSettings.expensesSettingsTexts.noRowsSelected"
                    )}
                    sx={{ fontSize: "16px" }}
                  >
                    <Stack>
                      {!isLoadingGetExpenses && (
                        <Button
                          title={t(
                            "expensesSettings.expensesSettingsTexts.modify"
                          )}
                          color="blue-greeny"
                          type="button"
                          onClick={() => handleToggleTransformModal()}
                          disabled={!selectedRows.length}
                        />
                      )}
                    </Stack>
                  </Tooltip>
                </Stack>
              ) : (
                <Stack sx={{ position: "absolute", right: "2.5%" }}>
                  <Button
                    title={t("expensesSettings.expensesSettingsTexts.modify")}
                    color="blue-greeny"
                    type="button"
                    onClick={() => handleToggleTransformModal()}
                  />
                </Stack>
              ))}
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Stack sx={{ width: "100%" }}>
            {isLoadingGetExpenses ? (
              <CustomSkeleton height={6} />
            ) : isErrorGetExpenses ? (
              <p>{t("expensesSettings.expensesSettingsTexts.errorDownload")}</p>
            ) : (
              dataGetExpenses.length > 0 && (
                <VirtualizatedTable
                  columns={columns}
                  rows={rows}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={35}
                  tableHeight={"75vh"}
                  hideFooter={true}
                  toolbar={true}
                  columnsButton={true}
                  filterButton={true}
                  densitySelector={true}
                  exportButton={true}
                  saveViewButton={true}
                  setSelectedRows={setSelectedRows}
                  setColumnsPropsArray={setColumnsPropsArray}
                  saveViewHandler={saveViewHandler}
                  columnVisibility={transformViewData(dataGetView)}
                  setNewVisibility={setNewVisibility}
                  setTotalFilteredAmount={setTotalFilteredAmount}
                  selectedRows={selectedRows}
                />
              )
            )}
            {dataGetExpenses?.length === 0 && (
              <VirtualizatedTable
                columns={noColumnsColumn}
                rows={rows}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={35}
                tableHeight={550}
                hideFooter={true}
                toolbar={true}
                columnsButton={true}
                filterButton={true}
                densitySelector={true}
                exportButton={true}
                saveViewButton={true}
                setSelectedRows={setSelectedRows}
                setColumnsPropsArray={setColumnsPropsArray}
                saveViewHandler={saveViewHandler}
              />
            )}
          </Stack>
        </Stack>
        <Dialog
          open={openTransformModal}
          handleClose={handleToggleTransformModal}
          title={t("expensesSettings.expensesSettingsTexts.modify")}
          maxWidth="lg"
        >
          <ModalContent
            selectedRows={selectedRows}
            handleToggleTransformModal={handleToggleTransformModal}
            handleToggleConfirmationModal={handleToggleConfirmationModal}
            optionsColumns={optionsColumns(columns)}
            transformedValues={transformedValues}
            setTransformedValues={setTransformedValues}
            rows={rows}
            columnSelectRef={columnSelectRef}
            valueSelectRef={valueSelectRef}
            columnSelected={columnSelected}
            setColumnSelected={setColumnSelected}
            valueSelected={valueSelected}
            setValueSelected={setValueSelected}
            newValueError={newValueError}
            setNewValueError={setNewValueError}
          />
        </Dialog>
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={handleToggleConfirmationModal}
          handleAccept={handleTransform}
          customMessage={true}
          message={
            columnSelected && valueSelected
              ? t("expensesSettings.expensesSettingsTexts.unaddedTransf")
              : t("expensesSettings.expensesSettingsTexts.wantToApply")
          }
          title={t("expensesSettings.expensesSettingsTexts.modify")}
        />
      </section>
    </ComponentLayout>
  );
};

export default ExpensesSettings;
