import { StateCell } from "../taskManager/utils";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Stack from "@mui/material/Stack";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

export const renderCellImportElements = (
  t: any,
  column: any,
  params,
  handleOpenDetail
) => {
  switch (column?.toLowerCase()) {
    case "posee_errores":
      return params.row.posee_errores ? (
        <StateCell
          startIcon={<ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />}
          text={""}
        />
      ) : (
        <StateCell
          startIcon={<DoneIcon sx={{ color: "var(--icon-success)" }} />}
          text={""}
        />
      );
    case "motivo_error":
      return params.row.posee_errores ? (
        <Button
          startIcon={<ZoomInIcon />}
          variant="text"
          sx={{ ":hover": { color: "var(--text-success)" } }}
          onClick={() => handleOpenDetail(params.row.motivo_error)}
        >
          {t("taskManager.actions.viewDetail")}
        </Button>
      ) : (
        <></>
      );
    default:
      return (
        <Stack
          sx={{ whiteSpace: "normal", height: "auto", textAlign: "center" }}
        >
          {params.value}
        </Stack>
      );
  }
};

export const transformColumnNameImportElements = (t, columna) => {
  switch (columna) {
    case "posee_errores":
      return "Estado";
    case "motivo_error":
      return "Motivo del error";
    default:
      return columna;
  }
};
