import Stack from "@mui/material/Stack";
import Dialog from "../../dialog/Dialog";
import useLanguage from "../../../context/LanguageProvider";
import { Divider, Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import {
  GET_ANALYSIS_URL,
  GET_SIMULATION_INFORMATION,
} from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import { buscarPorId, buscarPorLabel } from "../utils";

interface ViewInfoSalesForecastModalProps {
  data: any;
  open: boolean;
  handleClose: () => void;
}

const ViewInfoSalesForecastModal = ({
  data,
  open,
  handleClose,
}: ViewInfoSalesForecastModalProps) => {
  const { t } = useLanguage();
  const [isDataLoading, setIsDataLoading] = useState(false);

  /********************** CARGA Y OBTENCIÓN DE DATOS *****************************/

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    data &&
      open &&
      getAnalysisById(GET_ANALYSIS_URL(Number(data.analisis_id), true));
  }, [data, open]);

  const {
    isLoading: isLoadingSimulationsInformation,
    data: dataGetSimulationsInformation,
  } = useApiQuery(GET_SIMULATION_INFORMATION, true, undefined);

  const principalColumns =
    dataGetAnalysisById &&
    dataGetSimulationsInformation &&
    data &&
    data.columnas_principales?.map((columna) => {
      if (columna.is_medida && columna.columna) {
        const medidaEncontrada =
          dataGetAnalysisById &&
          buscarPorId(columna.columna, dataGetAnalysisById.medidas);
        const informationEncontrada = dataGetSimulationsInformation.find(
          (info) => info.id === columna.informacion
        );
        if (medidaEncontrada && informationEncontrada) {
          return {
            informacion: informationEncontrada?.nombre,
            columna: {
              value: medidaEncontrada?.id,
              label: medidaEncontrada?.nombre,
            },
          };
        }
      } else if (!columna.is_medida && columna.columna) {
        const columnaEncontrada =
          dataGetAnalysisById &&
          buscarPorLabel(
            columna.columna,
            dataGetAnalysisById.agrupacion.columnas_a_usar
          );
        const informationEncontrada = dataGetSimulationsInformation.find(
          (info) => info.id === columna.informacion
        );
        return {
          informacion: informationEncontrada?.nombre,
          columna: {
            value: columnaEncontrada?.value,
            label: columnaEncontrada?.label,
          },
        };
      } else if (columna.valor) {
        const valor = columna.valor;
        const informationEncontrada = dataGetSimulationsInformation.find(
          (info) => info.id === columna.informacion
        );
        return {
          informacion: informationEncontrada?.nombre,
          columna: { value: valor, label: valor },
        };
      }
    });

  useEffect(() => {
    if (principalColumns) {
      setIsDataLoading(false);
    } else {
      setIsDataLoading(true);
    }
  });

  return (
    <>
      <SimpleBackdrop
        open={
          isDataLoading ||
          isLoadingGetAnalysisById ||
          isLoadingSimulationsInformation
        }
        message={t("general.loading")}
      />
      {data && dataGetAnalysisById && dataGetSimulationsInformation && (
        <Dialog
          open={open}
          handleClose={handleClose}
          title={t("deltaTexts.salesForecast.title")}
          maxWidth={"lg"}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            padding="0 50px 30px 50px"
            position="relative"
            sx={{
              width: "720px",
              height: "100%",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t("general.analysis")}:{" "}
              <span
                style={{
                  color: "var(--text-disabled)",
                  wordBreak: "break-all",
                }}
              >
                {data && data.analisis}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.salesForecastCard.enabledPeriodsNonExecuted"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.periodos_faltantes && data.periodos_faltantes.length > 0
                    ? data.periodos_faltantes.map((period, idx) => {
                        if (data.periodos_faltantes.length - 1 === idx) {
                          return period + ".";
                        } else {
                          return period + ", ";
                        }
                      })
                    : t("deltaTexts.salesForecast.salesForecastCard.none")}
                </span>
              }
            </Typography>
            <Divider sx={{ margin: 2, width: "100%" }} />
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.newSalesForecastModal.initialPeriod"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.periodo_inicial.label || "1"}
                </span>
              }
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.newSalesForecastModal.periodsToForecast"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.cantidad_periodos || "1"}
                </span>
              }
            </Typography>
            <Divider sx={{ margin: 2, width: "100%" }} />
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.newSalesForecastModal.salesMeasuresToForecast"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.medidas_ventas.map(() => {
                    return;
                  })}
                  {data.medidas_ventas &&
                    data.medidas_ventas.map((measure, idx) => {
                      if (data.medidas_ventas.length - 1 === idx) {
                        return measure.label + ".";
                      } else {
                        return measure.label + ", ";
                      }
                    })}
                </span>
              }
            </Typography>
            <Divider sx={{ margin: "5px 0", width: "100%" }} />
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                pt: 2,
                wordBreak: "break-word",
                textDecoration: "underline",
              }}
              color="var(--text-main)"
            >
              {t(
                "simulatorTexts.simulationSchema.viewInfoSchemaModal.principalColumns"
              )}
            </Typography>
            <Stack
              sx={{
                width: "100%",
                maxHeight: "400px",
                overflowX: "auto",
                pt: 2,
                pb: 2,
              }}
            >
              {principalColumns &&
                principalColumns.map((column, idx) => {
                  return (
                    <Typography
                      key={idx}
                      variant="subtitle1"
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "-0.5px",
                        wordBreak: "break-word",
                      }}
                      color="var(--text-main)"
                    >
                      {column?.informacion}:{" "}
                      <span
                        style={{
                          color: "var(--text-disabled)",
                          wordBreak: "break-all",
                        }}
                      >
                        {column?.columna?.label}
                      </span>
                    </Typography>
                  );
                })}
            </Stack>
          </Stack>
        </Dialog>
      )}
    </>
  );
};

export default ViewInfoSalesForecastModal;
