import { POST_PARAM_SALES_FILE } from "../../../../api/axios";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import DropzoneInput from "../Shared/DropzoneInput";
import FileNameInput from "../Shared/FileNameInput";
import FormLayout from "../../../forms/FormLayout";
import KeyColumns from "../Shared/KeyColumns";
import MaxLevelDetail from "./MaxLevelDetail";
import MessageBlock from "../../../forms/MessageBlock";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadedFile from "../Shared/UploadedFile";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";
import {
  columnsSetDescriptive,
  columnsSetTransactional,
  stepperData,
} from "../data";

const Step2 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    salesFileNameRef,
    setIsStepComplete,
    salesFileName,
    setSalesFileName,
    dataFilesList,
    salesFileToUpload,
    setSalesFileToUpload,
    setKeyTransactionalColumns,
    setKeyDescriptiveColumns,
    dataGetSalesParams,
    maxLevelDetail,
    setMaxLevelDetail,
    getSalesParams,
    keyTransactionalColumns
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetSalesParams) {
      setSalesFileName(dataGetSalesParams.nombre ? dataGetSalesParams.nombre : "VENTAS");
      setKeyTransactionalColumns({
        venta_bruta: dataGetSalesParams?.columnas_clave?.venta_bruta,
        venta_kg: dataGetSalesParams?.columnas_clave?.venta_kg,
        venta_m3: dataGetSalesParams?.columnas_clave?.venta_m3,
        venta_unidades: dataGetSalesParams?.columnas_clave?.venta_unidades,
        venta_cajas: dataGetSalesParams?.columnas_clave?.venta_cajas,
        venta_tarimas: dataGetSalesParams?.columnas_clave?.venta_tarimas,
        cogs: dataGetSalesParams?.columnas_clave?.cogs,
        descuentos: dataGetSalesParams?.columnas_clave?.descuentos,
        devoluciones: dataGetSalesParams?.columnas_clave?.devoluciones,
      });
      setKeyDescriptiveColumns({
        codigo_cliente: dataGetSalesParams?.columnas_clave?.codigo_cliente,
        codigo_ruta: dataGetSalesParams?.columnas_clave?.codigo_ruta,
        codigo_producto: dataGetSalesParams?.columnas_clave?.codigo_producto,
        categoria_producto: dataGetSalesParams?.columnas_clave?.categoria_producto,
        marca: dataGetSalesParams?.columnas_clave?.marca,
        unidad_negocio: dataGetSalesParams?.columnas_clave?.unidad_negocio,
        canal: dataGetSalesParams?.columnas_clave?.canal,
        modelo_atencion: dataGetSalesParams?.columnas_clave?.modelo_atencion,
        tipo_ruta: dataGetSalesParams?.columnas_clave?.tipo_ruta,
        fecha: dataGetSalesParams?.columnas_clave?.fecha,
      });
      if (dataGetSalesParams?.nivel_detalle) {
        setMaxLevelDetail(dataGetSalesParams?.nivel_detalle);
      }
    }
  }, [dataGetSalesParams]);

  /******************************* Carga de archivo y post del nombre ********************************/

  const onSuccessPostFile = () => {
    setSalesFileToUpload([]);
    getSalesParams()
  };

  const { isLoading: isLoadingPostFile, callApi: postFile } = useApi(
    POST_PARAM_SALES_FILE,
    "POST",
    t("companyParam.postParamFile.codes"),
    undefined,
    onSuccessPostFile,
    undefined,
    false
  );

  /************************************* Validación step completo **********************************/

  const isStep2Complete = () => {    
    if (
      salesFileName &&
      salesFileName !== "" &&
      keyTransactionalColumns?.venta_bruta &&
      maxLevelDetail
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isStep2Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step2: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step2: false };
      });
    }
  }, [salesFileName, keyTransactionalColumns.venta_bruta, maxLevelDetail]);

  const isFileColumnKey =
    dataGetSalesParams &&
    dataGetSalesParams.archivo &&
    dataGetSalesParams.archivo.columnas &&
    dataGetSalesParams.archivo.columnas.length;

  return (
    <Stack sx={param_steps_container}
    >
      <SimpleBackdrop
        open={isLoadingPostFile}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[2]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={{...form_layouts_container_styles}}>
        <FormLayout width="48%" padding="10px 25px 120px 25px">
          <FileNameInput
            fileNameRef={salesFileNameRef}
            fileName={salesFileName}
            setfileName={setSalesFileName}
            setMessage={setMessage}
            dataFilesList={dataFilesList}
          />
          <DropzoneInput
            fileToUpload={salesFileToUpload}
            setFileToUpload={setSalesFileToUpload}
            postFile={postFile}
          />
          {isFileColumnKey && (
            <UploadedFile dataGetParams={dataGetSalesParams} />
          )}
          <KeyColumns
            columnsSet={columnsSetTransactional}
            subtitle={t("companyParam.transactional")}
            setKeyColumns={setKeyTransactionalColumns}
            dataGetParams={dataGetSalesParams}
          />
        </FormLayout>
        <FormLayout width="48%">
          <KeyColumns
            columnsSet={columnsSetDescriptive}
            subtitle={t("companyParam.descriptive")}
            setKeyColumns={setKeyDescriptiveColumns}
            dataGetParams={dataGetSalesParams}
          />
          {isFileColumnKey && (
            <MaxLevelDetail
              defaultValue={dataGetSalesParams.maximo_detalle}
              dataGetParams={dataGetSalesParams}
            />
          )}
        </FormLayout>
      </Stack>
    </Stack>
  );
};

export default Step2;
