import ComponentLayout from "../../layout/ComponentLayout";
import SearchInput from "../../forms/SearchInput";
import React, { useEffect, useState } from "react";
import BasicGrid from "../../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { filterDataByName } from "../utils";
import SimulationRuleCard from "./SimulationRuleCard";
import SpeedIcon from '@mui/icons-material/Speed';
import useLanguage from "../../../context/LanguageProvider";
import FixedAddButton from "../../buttons/FixedAddButton";
import { useNavigate } from "react-router-dom";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useDialog from "../../../hooks/useDialog";
import ViewInfoRuleModal from "./ViewInfoRuleModal";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { GET_RULE_URL, RULES_URL } from "../../../api/axios";
import useApi from "../../../hooks/useApi";
import { ConfirmationModal } from "../../dialog";
import { VariantType, useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useInfoContext from "../../../hooks/useInfoContext";
import InfoIcon from "@mui/icons-material/Info";
interface RuleProps {
    nombre: string,
    analisis: {
        nombre: string,
        medidas: {
            id: number,
            nombre: string,
            variabilidad: number,
            variable_dependiente: {
                id: number,
                nombre: string
            }
        }[]
    }
}

const SimulationsRulesAdmin = () => {

    const { userRolData }: any = useInfoContext()
    const navigate = useNavigate();
    const { t } = useLanguage();
    const [searchValue, setSearchValue] = useState<string>("");
    const [filteredData, setFilteredData] = useState<any>();
    const [openViewInfoRuleModal, toggleViewInfoRuleModal] = useDialog();
    const [ruleToDelete, setRuleToDelete] = useState<number>();
    const [openDeleteRuleConfirmationModal, handleToggleDeleteRuleConfirmationModal] = useDialog();
    const [dataToShow, setDataToShow] = useState<RuleProps>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    /************************** CARGA Y OBTENCIÓN DE DATOS **************************/

    useEffect(() => {
        localStorage.removeItem("simulationRuleId")
        localStorage.removeItem("configMode")
    }, [])

    const {
        isLoading: loadingSimulationsRules,
        data: dataGetSimulationsRules,
        refetch: refetchSimulationsRules,
    } = useApiQuery(RULES_URL, true, t("simulator.getRules.error"));

    const onSuccessGetRuleById = (data: any) => {
        setDataToShow(data);
    };

    const {
        callApi: getRuleById,
    } = useApi(
        undefined,
        "GET",
        t("simulator.getRules.error"),
        undefined,
        onSuccessGetRuleById,
        undefined,
        false
    );

    const { isLoading: isLoadingDeleteSimulationRule, callApi: deleteSimulationRuleById } =
        useApi(
            GET_RULE_URL(ruleToDelete ? ruleToDelete : undefined),
            "DELETE",
            t("simulator.deleteRules.codes"),
            undefined,
            refetchSimulationsRules
        );


    const checkIfRuleIsBeingUsed = (id: number) => {
        return id ? false : true; 
        // return dataGetRegisterRoutes && dataGetRegisterRoutes?.some((route: any) => {
        //     return route.regla_simulacion === dataGetSimulationsRules.find(rule => rule.id === id)?.nombre
        // }) || dataGetSchemas.map(schema => schema.regla_simulacion).includes(id);
    };

    /****************************** MANEJO DE FUNCIONES ******************************/

    const handleToggleViewInfoRuleModal = () => {
        setDataToShow(undefined)
        toggleViewInfoRuleModal()
    }

    const handleCreate = () => {
        localStorage.setItem("configMode", "CREATE");
        navigate("configuracion-regla")
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        filterDataByName(dataGetSimulationsRules, e.target.value, setFilteredData);
    };

    const handleEdit = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        localStorage.setItem("configMode", "EDIT");
        localStorage.setItem("simulationRuleId", String(id));
        navigate("configuracion-regla")
    };

    const handleInfo = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        getRuleById(GET_RULE_URL(id))
        handleToggleViewInfoRuleModal()
    };

    const handleCopy = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        localStorage.setItem("configMode", "COPY");
        localStorage.setItem("simulationRuleId", String(id));
        navigate("configuracion-regla")
    } 

    const handleClickVariant = (message: string, variant: VariantType) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, {
            variant,
            action: (key) => (
                <IconButton
                    onClick={() => {
                        closeSnackbar(key);
                    }}
                >
                    <CloseIcon sx={{ color: "white" }} />
                </IconButton>
            ),
        });
    };

    const handleClickOnDelete = (e: any, id: number) => {
        setRuleToDelete(id)
        if (checkIfRuleIsBeingUsed(id)) {
            handleClickVariant(
                t('simulatorTexts.simulationsRulesAdmin.cannotDeleteRuleAsBeingUsed'),
                "error"
            );
        } else {
            handleToggleDeleteRuleConfirmationModal();
        }
    };

    const handleDelete = () => {
        deleteSimulationRuleById()
        handleToggleDeleteRuleConfirmationModal()
    };

    return (
        <ComponentLayout title={t("simulatorTexts.simulationsRulesAdmin.simulationAdmin")} icon={<SpeedIcon />}>
            <SimpleBackdrop
                open={loadingSimulationsRules
                    // || isLoadingDataGetSchemas
                }
                message={t("simulatorTexts.simulationsRulesAdmin.loading")}
            />
            <SimpleBackdrop
                open={isLoadingDeleteSimulationRule}
                message={t("simulatorTexts.simulationsRulesAdmin.deletingRule")}
            />
            <Stack spacing={2} sx={{ width: "100%" }}>
                <SearchInput
                    value={searchValue}
                    handleChange={handleSearch}
                    placeholder={t("simulatorTexts.simulationsRulesAdmin.searchByName")}
                />
            </Stack>
            {
                userRolData && (userRolData.simulador.visualizacion_y_edicion || userRolData.simulador.visualizacion_y_creacion) &&
                <FixedAddButton handleClick={handleCreate} />
            }
            {filteredData?.length || dataGetSimulationsRules?.length ? (
                <>

                    <BasicGrid
                        data={filteredData ? filteredData : dataGetSimulationsRules}
                        Card={SimulationRuleCard}
                        handleEdit={userRolData && userRolData.simulador.visualizacion_y_edicion && handleEdit}
                        handleCopy={handleCopy}
                        handleInfo={handleInfo}
                        handleDelete={userRolData && userRolData.simulador.visualizacion_y_edicion && handleClickOnDelete}
                        showInfo={true}
                    />
                </>
            ) : (
                true && (
                    <Box
                        sx={{
                            width: "95%",
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "700", color: "var(--text-info)" }}
                        >
                            {t("simulatorTexts.simulationsRulesAdmin.noSimulationsRulesYet")}
                        </Typography>
                    </Box>
                )
            )}
            {dataToShow &&
                <ViewInfoRuleModal
                    rule={dataToShow}
                    open={openViewInfoRuleModal}
                    handleClose={toggleViewInfoRuleModal}
                />
            }
            <ConfirmationModal
                open={openDeleteRuleConfirmationModal}
                handleClose={handleToggleDeleteRuleConfirmationModal}
                handleAccept={handleDelete}
                message={t("simulatorTexts.simulationsRulesAdmin.deleteSimulationRule")}
                title={t("simulatorTexts.simulationsRulesAdmin.confirm")}
            >
                <Stack
                    sx={{
                        width: "100%",
                        flexDirection: "row",
                        gap: "10px",
                    }}
                >
                    <InfoIcon color="primary" fontSize="medium" />
                    <Typography
                        style={{ color: "var(--text-main)", fontWeight: "600" }}
                    >
                        {t("simulatorTexts.simulationsRulesAdmin.deleteAssociatedSimulation")}
                    </Typography>
                </Stack>
            </ConfirmationModal>
        </ComponentLayout>
    );
};

export default SimulationsRulesAdmin;
