import { GridColumns } from "@mui/x-data-grid-pro";
import { convertNumberToMoney } from "../../parameterization/utils";

export const getColumnTitle = (column, t) => {
    switch (column) {
        case "modalidad_proyeccion":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.projectionMode")}
                </>
            );
        case "cantidad":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.routeRegistrations")}
                </>
            );
        case "Clientes por Ruta_actual":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.customersByCurrentRoute")}
                </>
            );
        case "Clientes por Ruta_ajustado":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.customersByAdjustedRoute")}
                </>
            );
        case "Precio Promedio_actual":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.averagePrice")}
                </>
            );
        case "Volumen mensual promedio Total por Ruta_actual":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.averageMonthlyVolumePerCurrentRoute")}
                </>
            );
        case "Volumen mensual promedio Total por Ruta_ajustado":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.averageMonthlyVolumePerAdjustedRoute")}
                </>
            );
        case "Volumen mensual promedio por Cliente_actual":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.currentAverageVolumePerClient")}
                </>
            );
        case "Volumen mensual promedio por Cliente_ajustado":
            return (
                <>
                    {t("simulatorTexts.registerRoutes.getColumnsRegisteredRoutes.adjustedAverageVolumePerClient")}
                </>
            );
        default:
            return "";

    }
};

export const getColumnsRegisteredRoutes = (rows, t: any) => {

    const uniqueFlags: string = rows.reduce((flags, row) => {
        Object.keys(row).forEach(flag => {
            if (flag !== "id") {
                flags.add(flag);
            }
        });
        return flags;
    }, new Set());

    const columnsNames = [...Array.from(uniqueFlags)].sort((a, b) => {
        if (a === 'cantidad') return 1;
        if (b === 'cantidad') return -1;
        return 0;
    });

    const columns: GridColumns = columnsNames.map((column: string) => {
        return {
            field: column,
            headerName: column,
            headerClassName: "dm_header_column",
            renderHeader: () => {
                return (
                    <strong className="dm_column_title">{getColumnTitle(column, t)}</strong>
                )
            },
            flex: 1,
            headerAlign: "center",
            maxWidth: 400,
            align: "center",
            wrapText: true,
            renderCell: (params: any) => {
                if (params.row.modalidad_proyeccion === "Total Ruta") {
                    if (column === "Volumen mensual promedio Total por Ruta_actual" || column === "Volumen mensual promedio Total por Ruta_ajustado" || column === "Precio Promedio_actual") {
                        return <>{convertNumberToMoney(params.value)}</>
                    } else if (column === "Volumen mensual promedio por Cliente_actual" || column === "Volumen mensual promedio por Cliente_ajustado") {
                        return <>-</>
                    } else {
                        return <>{params.value}</>
                    }
                } else {
                    if (column === "Precio Promedio_actual") {
                        return <>{convertNumberToMoney(params.value)}</>
                    } else if (column === "Volumen mensual promedio Total por Ruta_actual" || column === "Volumen mensual promedio Total por Ruta_ajustado") {
                        return <>-</>
                    } else {
                        return <>{params.value}</>
                    }
                }
            },
        };
    });

    return columns;
};