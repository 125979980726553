import { Stack, TextField, Typography } from '@mui/material'
import { useEffect, Dispatch, SetStateAction } from 'react'
import useLanguage from '../../../../../../context/LanguageProvider'
interface Step3NewRegisterRouteModalProps {
  newRoutes: number,
  setNewRoutes: Dispatch<
    SetStateAction<number>
  >,
  balanceRoutes: number,
  setBalanceRoutes: Dispatch<
    SetStateAction<number>>,
  actualRoutes: number
}

const Step3NewRegisterRouteModal = ({
  newRoutes,
  setNewRoutes,
  balanceRoutes,
  setBalanceRoutes,
  actualRoutes
}: Step3NewRegisterRouteModalProps) => {

  const { t } = useLanguage()

  const handleChangeNewRoutes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexNumber = /^([0-9])*$/
    if (regexNumber.test(e.target.value)) {
      setNewRoutes(Number(e.target.value));
    }
  };

  useEffect(() => {
    setBalanceRoutes(actualRoutes + newRoutes)
  }, [newRoutes])

  return (
    <Stack sx={{
      width: "420px",
      padding: "10px",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
    }}>
      <Typography sx={{
        width: "100%",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "underline"
      }}>
        {t("simulatorTexts.registerRoutes.step3NewRouteModal.totalRoutesResult")}
      </Typography>
      <Stack sx={{
        width: "100%",
        gap: "20px"
      }}>
        <Stack sx={{
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          border: "1px solid var(--bg-light)",
          p: 2,
          borderRadius: "8px"
        }}>
          <Typography sx={{
            width: "100%",
            fontWeight: "bold",

          }}>
            {t("simulatorTexts.registerRoutes.step3NewRouteModal.actualRoutes")}:
          </Typography>
          <Typography sx={{
            width: "40%",
            fontWeight: "bold",
            border: "1px solid var(--bg-disabled)",
            p: 2,
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "5px 5px 10px var(--text-info)",
            backgroundColor: "var(--bg-disabled)",
          }}>
            {actualRoutes}
          </Typography>
        </Stack>
        <Stack sx={{
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          border: "1px solid var(--bg-light)",
          p: 2,
          borderRadius: "8px"
        }}>
          <Typography sx={{
            width: "100%",
            fontWeight: "bold",
          }}>
            {t("simulatorTexts.registerRoutes.step3NewRouteModal.routesToAdd")}:
          </Typography>
          <Stack sx={{
            width: "40%",
            fontWeight: "bold",
            border: "1px solid var(--bg-disabled)",
            p: 2,
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "5px 5px 10px var(--text-info)",
            backgroundColor: "var(--bg-light)"
          }}>
            <TextField
              type="text"
              sx={{
                borderRadius: "50px"
              }}
              variant="standard"
              fullWidth
              value={newRoutes}
              onChange={handleChangeNewRoutes}
              error={newRoutes === 0}
              inputProps={{
                style: {
                  textAlign: 'center',
                  color: "var(--text-main)",
                  fontWeight: "bold"
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          border: "1px solid var(--bg-light)",
          p: 2,
          borderRadius: "8px"
        }}>
          <Typography sx={{
            width: "100%",
            fontWeight: "bold",
          }}>
            {t("simulatorTexts.registerRoutes.step3NewRouteModal.newRoutes")}:
          </Typography>
          <Typography sx={{
            width: "40%",
            fontWeight: "bold",
            border: "1px solid var(--bg-disabled)",
            p: 2,
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "5px 5px 10px var(--text-info)",
            backgroundColor: "var(--bg-disabled)",
          }}>
            {balanceRoutes}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Step3NewRegisterRouteModal