import { Chip, Fab, Stack, Tooltip } from "@mui/material";
import { getBackgroundColor } from "../../../utils";
import { Item } from "../../../../basicGrid/BasicGrid";
import { PnlElementType } from "../../data";
import { truncateString } from "../../../../parameterization/utils";
import { useCompanyParamProvider } from "../../../../../context/CompanyParamProvider";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import {
  button_right_position_styles,
  element_typography_styles,
  grid_item_element_styles,
  view_compact_icon_styles,
} from "../../../../../styles/app-styles";
import AddIcon from "@mui/icons-material/Add";
import CardIcon from "../shared/CardIcon";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import useLanguage from "../../../../../context/LanguageProvider";
interface PnlElementProps {
  element: PnlElementType;
  toggleDeleteElement: () => void;
  setElement: (element) => void;
  togglePnlModal: () => void;
  handleEditElement: (event, element) => void;
  dragItem: any;
  dragOverItem: any;
  onDropActions: (initialItem, finalItem) => void;
}

const PnlElement = ({
  element,
  toggleDeleteElement,
  setElement,
  togglePnlModal,
  handleEditElement,
  dragItem,
  dragOverItem,
  onDropActions,
}: PnlElementProps) => {
  const { t } = useLanguage();
  const { dataGetPnlLevels, setPnlElementConfig } = useCompanyParamProvider();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [hovered, setHovered] = useState(false);
  const levelsQuantity = dataGetPnlLevels?.length;

  /**************************************** Setear valores por default **********************************/

  const onClickAddElement = (e) => {
    setPnlElementConfig({
      id: undefined,
      nombre: "",
      orden: element.orden + 1,
      tipo: element.padre ? element.tipo : undefined,
      subelementos: undefined,
      nivel: element.nivel,
      columnas: undefined,
      archivo: element.padre ? element.archivo : undefined,
      buckets: undefined,
      agrupadores: undefined,
      padre: element.padre ? element.padre : undefined,
    });
    togglePnlModal();
    e.stopPropagation();
  };

  const addSubelement = (e) => {
    setPnlElementConfig({
      id: undefined,
      nombre: "",
      orden: 1,
      tipo: element.tipo,
      subelementos: undefined,
      nivel: element.nivel + 1,
      columnas: undefined,
      archivo: element.archivo,
      buckets: undefined,
      agrupadores: undefined,
      padre: element.id,
    });
    togglePnlModal();
    e.stopPropagation();
  };

  const deleteElement = (e) => {
    setElement(element);
    toggleDeleteElement();
    e.stopPropagation();
  };

  /********************************************** Gestión DRAG & DROP **************************************************/

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const onMouseLeave = () => {
    setTimeout(() => {
      setHovered(false);
    }, 4000);
  };

  const hasGrabCursor = (target) => {
    const firstChild = target.firstChild;
    if (firstChild && firstChild.nodeName === "SPAN") {
      const cursor = window.getComputedStyle(firstChild).cursor;
      if (cursor === "grab") return true;
    }
    return false;
  };

  const dragStart = (e, elem) => {
    if (hasGrabCursor(e.target)) {
      dragItem.current = elem;
    } else {
      e.preventDefault();
    }
  };

  const dragEnter = (elem) => {
    if (dragOverItem) {
      dragOverItem.current = elem;
    }
  };

  const handleDrop = () => {
    //dragItem == initialItem
    //dragOverItem == finalItem
    if (dragItem.current === undefined || dragOverItem.current === undefined) {
      return;
    } else {
      const isValidLevel =
        dragOverItem.current.nivel === dragItem.current.nivel ||
        dragOverItem.current.nivel === dragItem.current.nivel - 1;
      const isValidType =
        (!dragOverItem.current.padre && !dragItem.current.padre) ||
        dragOverItem.current.tipo === dragItem.current.tipo;
      const isValidFile =
        (!dragOverItem.current.padre && !dragItem.current.padre) ||
        dragOverItem.current.archivo === dragItem.current.archivo;

      if (isValidLevel && isValidType && isValidFile) {
        onDropActions(dragItem.current, dragOverItem.current);
      } else if (!isValidLevel) {
        handleClickVariant(t("companyParam.differentLevelError"), "error");
      } else if (!isValidType) {
        handleClickVariant(t("companyParam.differentTypeError"), "error");
      } else if (!isValidFile) {
        handleClickVariant(t("companyParam.differentFileError"), "error");
      }
    }
  };

  const handleWidth = () => {
    return (100 * (levelsQuantity - (element.nivel - 1))) / levelsQuantity;
  };

  return (
    <Item
      id="gridItem"
      elevation={1}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => onMouseLeave()}
      sx={grid_item_element_styles(getBackgroundColor, element, handleWidth)}
      onDragStart={(e) => dragStart(e, element)}
      onDragEnter={(e) => dragEnter(element)}
      onDrop={(e) => handleDrop()}
      onDragOver={(e) => e.preventDefault()}
      draggable={true}
    >
      <Tooltip title={t("dashboard.moveElement")}>
        <span
          className="dragIcon"
          style={{ fontSize: "20px", marginRight: "5px" }}
          onMouseOver={(e) => {
            e.currentTarget.style.cursor = "grab";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.cursor = "default";
          }}
        >
          <DragIndicatorIcon
            sx={{
              ...view_compact_icon_styles,
              fontSize: "20px",
              zIndex: 2,
            }}
          />
        </span>
      </Tooltip>
      {hovered && (
        <Fab
          size="small"
          color="primary"
          onClick={(e) => onClickAddElement(e)}
          sx={button_right_position_styles}
        >
          <Tooltip title={t("companyParam.addElement")} placement="top">
            <AddIcon />
          </Tooltip>
        </Fab>
      )}
      <Tooltip title={element.nombre} placement="top-start">
        <Stack sx={element_typography_styles}>
          <span>
            <Chip
              label={
                element?.archivo
                  ? element?.archivo?.charAt(0)?.toUpperCase()
                  : "R"
              }
              sx={{ color: "var(--bg-neutral-hover)", mr: "3px" }}
            />
            {element.nivel < 5
              ? truncateString(element.nombre, 100)
              : truncateString(element.nombre, 24 / element.nivel)}
          </span>
        </Stack>
      </Tooltip>
      <Stack direction="row" gap={0.3}>
        {dataGetPnlLevels && element.tipo !== "RESULTADO" &&
          element.nivel !== dataGetPnlLevels.length && (
            <CardIcon
              Icon={AddIcon}
              onClickAction={addSubelement}
              tooltipText={t('companyParam.addSubelement')}
            />
          )}
        <CardIcon
          Icon={EditIcon}
          onClickAction={(e) => handleEditElement(e, element)}
          tooltipText={t('companyParam.editName')}
        />
        <CardIcon
          Icon={DeleteIcon}
          onClickAction={deleteElement}
          tooltipText={t('general.delete')}
        />
      </Stack>
    </Item>
  );
};

export default PnlElement;
