import { Button } from "../buttons";
import { DELETE_VIEW_URL, POST_NEW_VIEW } from "../../api/axios";
import { fileOptions } from "../configCriteria/selectOptions";
import { selectStyles } from "../configCriteria/select.styles";
import { useRef } from "react";
import Box from "@mui/material/Box";
import Dialog from "../dialog/Dialog";
import Select from "../forms/Select";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import useLanguage from "../../context/LanguageProvider";
import useSingleSelect from "../../hooks/useSingleSelect";
import { useViewDashboard } from "../../context/ViewDashboardProvider";

const DeleteViewModal = () => {
  const { t } = useLanguage();
  const {
    tabValue,
    analysisViews,
    openDeleteViewModal,
    toggleDeleteViewModal,
  } = useViewDashboard();
  const viewRef = useRef<any>(null);
  const viewsByAnalysis =
    analysisViews?.data?.find((analysis) => analysis.id === tabValue)?.vistas ||
    [];

  const onSuccessDelete = () => {
    window.location.reload();
  };

  const [viewSelectedOption, setViewSelectedOption, viewOptionsChangeHandler] =
    useSingleSelect();

  const { isLoading: isLoadingDeleteView, callApi: deleteView } = useApi(
    DELETE_VIEW_URL(viewSelectedOption?.value),
    "DELETE",
    t("dashboard.deleteView.codes"),
    undefined,
    onSuccessDelete
  );

  const confirmSendView = () => {
    toggleDeleteViewModal();
    deleteView(POST_NEW_VIEW(tabValue));
  };

  return (
    <Dialog
      open={openDeleteViewModal}
      handleClose={toggleDeleteViewModal}
      maxWidth="md"
      title={t("dashboard.deleteView.modalTitle")}
    >
      <SimpleBackdrop
        open={isLoadingDeleteView}
        message={t("dashboard.deleteView.loading")}
      />
      <Stack
        sx={{
          margin: "0 15px",
          width: "400px",
          alignItems: "center",
          minHeight: "250px",
          justifyContent: "space-between",
        }}
      >
        <Box>

          <Typography
          variant="h6"
          sx={{
            marginBottom: "20px",
            color: "var(--text-main)",
            maxWidth: "400px",
            textAlign: "start",
          }}
        >
          {t("dashboard.deleteView.selectViewToDelete")}
        </Typography>
   
          <Select
            reference={viewRef}
            styles={selectStyles(viewSelectedOption)}
            options={fileOptions(viewsByAnalysis)}
            className="cc_select"
            name="Archivo"
            onChange={viewOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("dashboard.deleteView.selectView")}
            defaultValue={viewSelectedOption}
            isClearable
          />
    
        </Box>
        

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={toggleDeleteViewModal}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={confirmSendView}
            color="blue-greeny"
            disabled={false}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DeleteViewModal;
