import { Button } from "../../buttons";
import { checkIfAllRowsHaveStages, preparePostStagesData } from "../utils";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { Stack, Tooltip, Typography } from "@mui/material";
import { STAGES_URL } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import { useGetGastosProrrateo } from "../api";
import { useNavigate } from "react-router-dom";
import { useSnackbar as useNotistackSnackbar, VariantType } from "notistack";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import FormattedRows from "./FormattedRows";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stages from "./Stages";
import TreeDataCustomGroupingColumn from "./TreedataTable";
import useApi from "../../../hooks/useApi";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import {
  stages_buttons_container,
  stages_settings_container,
  stages_typography_styles,
} from "../../../styles/app-styles";

const StagesSettingsView = () => {
  const { t, language } = useLanguage();
  const { enqueueSnackbar } = useNotistackSnackbar();
  const {
    setHasCompletedStages,
    warningWithoutStages,
    setWarningWithoutStages,
    userRolData,
  }: any = useInfoContext();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>();
  const {
    data: rows,
    isLoading: isLoadingPreprorrationExpenses,
    error: errorPreprorrationExpenses,
    refetch: refetch,
    isFetching: isFetchingPreprorrationExpenses,
  } = useGetGastosProrrateo();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };
  const navigate = useNavigate();
  const { data: getCompletedStages, refetch: refetchCompletedStages } =
    useApiQuery(
      STAGES_URL,
      false,
      t("dataManagement.getCompletedStages.error")
    );

  /***************************** Advertencias etapas ***************************************/

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    const shouldShowWarning =
      warningWithoutStages && (!rows || rows.length === 0);
    if (
      shouldShowWarning &&
      !isLoadingPreprorrationExpenses &&
      !isFetchingPreprorrationExpenses
    ) {
      handleClickVariant(t("stagesSettings.noFiles"), "warning");
    } else if (
      warningWithoutStages &&
      !isLoadingPreprorrationExpenses &&
      !isFetchingPreprorrationExpenses
    ) {
      handleClickVariant(t("stagesSettings.withoutStages"), "warning");
    }
    if (
      !isLoadingPreprorrationExpenses &&
      !isFetchingPreprorrationExpenses &&
      warningWithoutStages
    ) {
      setWarningWithoutStages(false);
    }
  }, [rows]);

  /********************************* Gestión de escucha de etapas ***************************/

  useEffect(() => {
    getCompletedStages !== undefined &&
      getCompletedStages !== null &&
      setHasCompletedStages(checkIfAllRowsHaveStages(rows));
  }, [getCompletedStages]);

  useEffect(() => {
    rows && setHasCompletedStages(checkIfAllRowsHaveStages(rows));
  }, [rows]);

  /************************************* Gestión de envío etapas **********************************/

  const onSuccessSendStages = () => {
    refetchCompletedStages();
    setSelectionModel([]);
    refetch();
    setHasCompletedStages(checkIfAllRowsHaveStages(rows));
  };

  const { isLoading: postStagesLoading, callApi: postSendStages } = useApi(
    undefined,
    "POST",
    t("modelParameterization.sendStages.codes"),
    undefined,
    onSuccessSendStages
  );

  const handleApplyStages = async (stageValue: string | number) => {
    if (rows) {
      if (parseInt(stageValue as string) > 10) {
        handleClickVariant(
          t("modelParameterizationTexts.step1.stagesGreaterThan10"),
          "error"
        );
        return;
      }
      const stagesData = preparePostStagesData(
        stageValue,
        selectionModel,
        rows
      );
      if (!stagesData) {
        handleClickVariant(
          t("modelParameterizationTexts.step1.stagesGreaterThan0"),
          "error"
        );
        return;
      } else {
        postSendStages(STAGES_URL, stagesData);
      }
    }
  };

  /********************************** Filtros ****************************************/

  useEffect(() => {
    setFilteredRows(FormattedRows(rows, t));
  }, [rows, language]);

  /*********************************** Render ****************************************/

  const hasStages = checkIfAllRowsHaveStages(rows);

  const RenderButton = (children) => {
    return hasStages ? (
      children
    ) : (
      <Tooltip
        title={`${t(
          "modelParameterizationTexts.step1.missingToAssingStages"
        )}.`}
        sx={{ verticalAlign: "center", fontSize: "16px" }}
      >
        <span>{children}</span>
      </Tooltip>
    );
  };

  const goToCalculationView = () => {
    navigate(
      t("/parametrizacion-modelo/calculo")
    );
  };

  return (
    <Stack sx={stages_settings_container}>
      <Stack spacing={1} sx={stages_buttons_container}>
        {userRolData && userRolData.parametrizacion_de_preprorrateo_y_prorrateo
          .visualizacion_y_edicion ? (
          <Stages
            handleApplyStages={handleApplyStages}
            selectionModel={selectionModel}
          />
        ) : (
          <div></div>
        )}
        {RenderButton(
          <Button
            title="Ir a Cálculo"
            color="blue-greeny"
            type="button"
            disabled={!hasStages}
            onClick={hasStages ? goToCalculationView : () => <></>}
          />
        )}
      </Stack>
      <Typography sx={stages_typography_styles}>
        {userRolData && userRolData.parametrizacion_de_preprorrateo_y_prorrateo
          .visualizacion_y_edicion ?
          t("modelParameterizationTexts.step1.editingCauseCleared") : ""}
      </Typography>
      {postStagesLoading ? (
        <SimpleBackdrop
          open={true}
          message={`${t(
            "modelParameterizationTexts.step1.assigningStages"
          )}...`}
        />
      ) : isLoadingPreprorrationExpenses || isFetchingPreprorrationExpenses ? (
        <CustomSkeleton height={5} />
      ) : (
        <TreeDataCustomGroupingColumn
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          rows={filteredRows}
          errorPreprorrationExpenses={errorPreprorrationExpenses}
          refetch={refetch}
          isExpanded={isExpanded}
          handleExpand={handleExpand}
        />
      )}
    </Stack>
  );
};

export default StagesSettingsView;
