import { convertArrayToText } from "../../taskManager/utils";
import { specialFunctions } from "./ViewInfoFunctions";
import Box from "@mui/material/Box";
import useLanguage from "../../../context/LanguageProvider";
import {
  info_modal_subtitle_key_styles,
  info_modal_subtitle_styles,
  info_modal_subtitle_value_styles3,
} from "../../../styles/app-styles";

const SharedIndicatorsAndCharts = ({ indicator }) => {
  const { t } = useLanguage();
  const isIndicator = !indicator?.tipo;
  const isChart = indicator?.tipo;
  const isTorta = indicator?.tipo === "TORTA CON DRILLDOWN";
  const isTreemap = indicator?.tipo === "TREEMAP CON DRILLDOWN";
  const isBoxplot = indicator?.tipo === "BOXPLOT";
  const isBarras = indicator?.tipo === "BARRAS CON DRILLDOWN";
  const isLineas = indicator?.tipo === "LINEAS";
  const isWaterfall = indicator?.tipo === "WATERFALL";

  const renderSpecialFunction = (key, value) => {
    return (
      <Box sx={info_modal_subtitle_styles}>
        <Box sx={info_modal_subtitle_key_styles}>{key}:</Box>
        {value}
      </Box>
    );
  };

  return (
    <>
      {isIndicator ||
        (isChart && (isTorta || isTreemap || isBoxplot) && (
          <Box sx={info_modal_subtitle_styles}>
            <Box sx={info_modal_subtitle_key_styles}>
              {t("indicatorsManagementTexts.viewInfoModal.baseColumn") + ": "}
            </Box>
            {!indicator?.tipo
              ? indicator?.columna?.label
              : indicator?.columna_base?.label}
          </Box>
        ))}
      {isChart && (isBarras || isLineas || isWaterfall) && (
        <>
          <Box sx={info_modal_subtitle_styles}>
            <Box sx={info_modal_subtitle_key_styles}>
              {t("indicatorsManagementTexts.viewInfoModal.axisY") + ": "}
            </Box>
            {indicator?.columna_eje_y && indicator?.columna_eje_y?.length !== 0
              ? convertArrayToText(
                  indicator?.columna_eje_y?.map(
                    (item: any) => item.columna.label
                  )
                )
              : indicator?.columna_eje_y && indicator?.columna_eje_y[0]?.label}
          </Box>
          <Box sx={info_modal_subtitle_styles}>
            <Box sx={info_modal_subtitle_key_styles}>
              {t("indicatorsManagementTexts.viewInfoModal.axisYName")}:
            </Box>
            <Box sx={info_modal_subtitle_value_styles3}>
              {indicator?.nombre_eje_y ? indicator?.nombre_eje_y : "-"}
            </Box>
          </Box>{" "}
        </>
      )}
      {!isBoxplot && (
        <Box sx={info_modal_subtitle_styles}>
          <Box sx={info_modal_subtitle_key_styles}>
            {t("indicatorsManagementTexts.viewInfoModal.appliedFunction") +
              ": "}
          </Box>
          {indicator?.funcion}
        </Box>
        
      )}
      {!isBoxplot && specialFunctions(t, indicator).map(
        (item) => item.condition && renderSpecialFunction(item.key, item.value)
      )}
    </>
  );
};

export default SharedIndicatorsAndCharts;
