import { getIcon } from "../utils";
import CardDetailItem from "../../cards/CardDetailItem";
import React from "react";
import Stack from "@mui/material/Stack/Stack";

const ChartDetails: React.FC<{ item: any; t: any; chartConfig: any }> = ({
  item,
  t,
  chartConfig,
}) => (
  <Stack spacing={chartConfig.spacing || 1}>
    <CardDetailItem
      title={t("indicatorsManagementTexts.chartCard.graphicType")}
      value={item.tipo}
      icon={getIcon(item.tipo)}
    />
    <CardDetailItem
      title={t("indicatorsManagementTexts.chartCard.baseFile")}
      value={item.archivo?.label || ""}
    />
    {item.columna_eje_x && chartConfig?.xAxisLabel &&(
      <CardDetailItem
        title={chartConfig?.xAxisLabel}
        value={chartConfig?.getXAxisValue && chartConfig.getXAxisValue(item)}
      />
    )}

    {item.columna_eje_y && chartConfig?.yAxisLabel && (
      <CardDetailItem
        title={chartConfig?.yAxisLabel}
        value={chartConfig?.getYAxisValue && chartConfig?.getYAxisValue(item)}
      />
    )}
    {item.columna_base && chartConfig.columnBaseLabel && (
      <CardDetailItem
        title={chartConfig.columnBaseLabel}
        value={
          chartConfig?.getColumnBaseValue &&
          chartConfig?.getColumnBaseValue(item)
        }
      />
    )}    
  </Stack>
);

export default ChartDetails;
