import { convertArrayToText } from "../../taskManager/utils";

export function getTextByLengthAxisY(item): string {
  return `${item?.funcion} de ${
    item?.columna_eje_y?.length !== 0
      ? convertArrayToText(item?.columna_eje_y?.map((col) => col?.columna?.label))
      : item?.columna_eje_y[0]?.columna?.label || ""
  }`;
}

export function getTextByLengthColumn(item): string {
  return `${item?.funcion} de ${item?.columna?.label || ""}`;
}

export const chartConfigurations: { [key: string]: any } = {
  "BARRAS CON DRILLDOWN": {
    xAxisLabel: "Eje X",
    getXAxisValue: (item: any) => item.columna_eje_x?.label || "",
    yAxisLabel: "Eje Y", //t("indicatorsManagementTexts.chartCard.axisY")
    getYAxisValue: (item: any) => getTextByLengthAxisY(item),
  },
  LINEAS: {
    xAxisLabel: "Eje X",
    getXAxisValue: (item: any) => item.columna_eje_x?.label || "",
    yAxisLabel: "Eje Y",
    getYAxisValue: (item: any) => getTextByLengthAxisY(item),
  },
  BOXPLOT: {
    xAxisLabel: "Eje X",
    getXAxisValue: (item: any) => item.columna_eje_x?.label || "",
    columnBaseLabel: "Columna base",
    getColumnBaseValue: (item: any) => item.columna_base?.label || "",
  },
  "TORTA CON DRILLDOWN": {
    xAxisLabel: "Eje X",
    getXAxisValue: (item: any) => item.columna_eje_x?.label || "",
    columnBaseLabel: "Columna base",
    getColumnBaseValue: (item: any) =>
      `${item.funcion} de ${item.columna_base?.label}`,
  },
  "TREEMAP CON DRILLDOWN": {
    xAxisLabel: "Columna categórica",
    getXAxisValue: (item: any) => item.columna_eje_x?.label || "",
    columnBaseLabel: "Columna base operación",
    getColumnBaseValue: (item: any) =>
      `${item.funcion} de ${item.columna_base?.label}`,
  },
  WATERFALL: {
    xAxisLabel: "Columnas",
    getXAxisValue: (item: any) =>
      convertArrayToText(item.columnas.map((columna) => columna.nombre)),
  },
  SCATTERPLOT: {
    xAxisLabel: "Eje X",
    getXAxisValue: (item: any) => item.columna_eje_x?.label || "",
    yAxisLabel: "Eje Y",
    getYAxisValue: (item: any) => getTextByLengthAxisY(item),
  },
};

export const indicatorConfigurations: { [key: string]: any } = {
  INDICADOR: {
    unitMeasureLabel: "Unidad de medida", //t("indicatorsManagementTexts.indicatorCard.unitOfMeasurement"
    getUnitMeasureValue: (item: any) => item.unidad_de_medida || "",
    columnLabel: "Columna",
    getColumnValue: (item: any) => getTextByLengthColumn(item),
  },
};

export const tableConfigurations: { [key: string]: any } = {
  TABLA: {
    limitResultsLabel: "Limitar resultados",
    limitResultsValue: (item: any) => item.limitar_resultados ? item.limitar_resultados : "Sin limitar",
  },
};
