import { ANALYSIS_URL, PUT_ANALYSIS_URL } from "../../../../api/axios";
import { Button } from "../../../buttons";
import { ConfirmationModal } from "../../../dialog";
import { formatOptions } from "../../../../utils/util";
import { selectStyles } from "../../../configCriteria/select.styles";
import { Stack, TextField, InputLabel, Typography } from "@mui/material";
import { useRef, useState, useEffect, SetStateAction, Dispatch } from "react";
import Select from "../../../forms/Select";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import TransferList from "./TransferList";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { handleElementRepeatedName } from "../../utils";
interface ConfigAnalysisProps {
  newAnalysisName: string;
  setNewAnalysisName?: (string: string) => void;
  handleToggleOpenConfigAnalysis: () => void;
  dataGetExpenses: any[];
  dataGetSales: any[];
  dataGetComposed: any[];
  dataGetAgrupations: any[];
  dataGetAnalysis: any[];
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY";
  dataGetAnalysisById: any;
  refetchGetAnalysis: () => void;
  analysisToEdit: number | undefined;
  setConfigMode: Dispatch<SetStateAction<"CREATE" | "EDIT" | "INFO" | "COPY">>;
  isCalculated: boolean;
}

const ConfigAnalysis = ({
  setNewAnalysisName,
  newAnalysisName,
  handleToggleOpenConfigAnalysis,
  dataGetExpenses,
  dataGetSales,
  dataGetComposed,
  dataGetAgrupations,
  dataGetAnalysis,
  configMode,
  dataGetAnalysisById,
  refetchGetAnalysis,
  analysisToEdit,
  setConfigMode,
  isCalculated,
}: ConfigAnalysisProps) => {
  const { t } = useLanguage();
  const selectAgrupationRef: any = useRef(null);
  const [selectedAgrupation, setSelectedAgrupation] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [rightData, setRightData] = useState<any[]>(
    configMode === "EDIT" || configMode === "COPY"
      ? dataGetAnalysisById?.medidas
          ?.map((dataEdit: any) => {
            const rightInfo: any = [];
            const combinedData = [
              ...dataGetExpenses,
              ...dataGetSales,
              ...dataGetComposed,
            ];
            combinedData.map((combined: any) => {
              if (dataEdit.id === combined.id) {
                rightInfo.push(combined);
              }
            });
            return rightInfo;
          })
          .flat()
      : []
  );
  const [leftData, setLeftData] = useState<any[]>([]);

  /********************************* CARGA Y OBTENCIÓN DE DATOS *****************************/

  useEffect(() => {
    if (configMode === "COPY" || configMode === "EDIT") {
      if (dataGetExpenses || dataGetSales || dataGetComposed) {
        const combinedData = [
          ...dataGetExpenses,
          ...dataGetSales,
          ...dataGetComposed,
        ];

        const commonData = combinedData.filter((combined) =>
          rightData.some((itemRight) => itemRight.id === combined.id)
        );
        const differentData = combinedData.filter(
          (combined) =>
            !rightData.some((itemRight) => itemRight.id === combined.id)
        );

        setRightData(commonData);
        setLeftData(differentData);
      }
    } else if (dataGetExpenses || dataGetSales || dataGetComposed) {
      const combinedData = [
        ...dataGetExpenses,
        ...dataGetSales,
        ...dataGetComposed,
      ];

      setLeftData(combinedData);
      setRightData([]);
    }
  }, [dataGetExpenses, dataGetSales, dataGetComposed]);

  const [
    agrupationSelectedOptions,
    setAgrupationSelectedOptions,
    agrupationSelectedChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" ||
      (configMode === "COPY" && dataGetAnalysisById?.agrupacion)
      ? {
          value: dataGetAnalysisById.agrupacion.value,
          label: dataGetAnalysisById.agrupacion.label,
        }
      : undefined
  );

  /********************************* MANEJO DE DATOS *****************************/

  const handleChangeName = (e: any) => {
    setNewAnalysisName && setNewAnalysisName(e.target.value);
  };

  useEffect(() => {
    handleElementRepeatedName(
      t,
      newAnalysisName,
      dataGetAnalysis,
      analysisToEdit,
      configMode,
      setMessage
    );
  }, [newAnalysisName]);

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const closeAndClean = () => {
    setSelectedAgrupation(undefined);
    setConfigMode("CREATE");
    setNewAnalysisName && setNewAnalysisName("");
    selectAgrupationRef.current?.clearValue();
    if (rightData.length > 0) {
      if (dataGetExpenses && dataGetSales && dataGetComposed) {
        const combinedData = [
          ...dataGetExpenses,
          ...dataGetSales,
          ...dataGetComposed,
        ];
        setLeftData(combinedData);
      }
      setRightData([]);
    }
    setAgrupationSelectedOptions(undefined);
    handleToggleOpenConfigAnalysis();
  };

  useEffect(() => {
    if (configMode === "EDIT" || configMode === "COPY") {
      setNewAnalysisName &&
        dataGetAnalysisById &&
        setNewAnalysisName(dataGetAnalysisById.nombre);
    }
  }, [dataGetAnalysisById]);

  /********************************* VALIDACIÓN DE DATOS *****************************/

  const validateMatchesBucketGroupers = (array) => {
    if (array) {
      const arr = array.map((e) => e.bucket_agrupador);
      const flattennedArray: number[] = [];
      arr.map((bucket_agrupador) => {
        // Recorro los bucket_agrupadores bucke a bucket
        bucket_agrupador.map((bucket) => {
          // Recorro los buckets de ese agrupador
          bucket.agrupadores.map((ag) => {
            // pusheo un join entre los ids de los buckets, y agrupadores, de modo de hacer mas eficiente el set
            flattennedArray.push(
              parseInt(`${String(bucket.id)}${String(ag.id).padStart(8, "0")}`)
            );
          });
        });
      });      
      // Si hay mas bucket_agrupadores en una lista que en un set, entonces esta mal
      return flattennedArray.length === new Set(flattennedArray).size;
    } else {
      return true;
    }
  };

  const isDataInvalid = () => {
    //Checkeamos si en rightData existe una medida compuesta sin sus medidas primarias
    const isMedidaMissing = rightData.some(
      (data) =>
        data.medidas &&
        data.medidas.length > 0 &&
        !data.medidas.every((medidaId) =>
          rightData.some((data) => data.id === medidaId)
        )
    );
    const medidasConBuckets = rightData.filter((obj) => obj.bucket_agrupador);
    if (
      newAnalysisName !== "" &&
      agrupationSelectedOptions &&
      rightData.length > 0 &&
      !isMedidaMissing &&
      validateMatchesBucketGroupers(medidasConBuckets)
    ) {
      return false;
    } else {
      return true;
    }
  };

  /********************************* POST DE DATOS *****************************/

  const onSuccessPostAnalysis = () => {
    closeAndClean();
    refetchGetAnalysis();
  };

  const onSuccessPutAnalysis = () => {
    closeAndClean();
    refetchGetAnalysis();
  };

  const { isLoading: isLoadingPostAnalyisis, callApi: postAnalysis } = useApi(
    ANALYSIS_URL,
    "POST",
    t("dataModel.postNewAnalysis.codes"),
    undefined,
    onSuccessPostAnalysis,
    undefined,
    true
  );

  const { isLoading: isLoadingPutAnalysis, callApi: putAnalysis } = useApi(
    PUT_ANALYSIS_URL(analysisToEdit),
    "PUT",
    t("dataModel.putGrouper"),
    undefined,
    onSuccessPutAnalysis,
    undefined,
    true
  );

  const handleFinish = () => {
    const completedInfo = {
      nombre: newAnalysisName,
      agrupacion: agrupationSelectedOptions?.value,
      medidas: rightData.map((data: any) => {
        return data.id;
      }),
    };
    if (configMode === "EDIT") {
      putAnalysis(undefined, completedInfo);
    } else {
      postAnalysis(ANALYSIS_URL, completedInfo);
    }
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <SimpleBackdrop
        open={isLoadingPostAnalyisis}
        message={t(
          "dataModelText.analysisManagement.configAnalysis.creatingAnalysis"
        )}
      />
      <SimpleBackdrop
        open={isLoadingPutAnalysis}
        message={t(
          "dataModelText.analysisManagement.configAnalysis.editingAnalysis"
        )}
      />
      {isCalculated && configMode === "EDIT" && (
        <Typography
          sx={{
            color: "var(--text-error)",
            width: "90%",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {t(
            "dataModelText.analysisManagement.configAnalysis.analysisAlreadyCalculated"
          )}
        </Typography>
      )}
      <Stack component="form" noValidate autoComplete="off" mb="15px" mt="15px">
        <TextField
          type="text"
          label={t(
            "dataModelText.analysisManagement.configAnalysis.analysisName"
          )}
          variant="outlined"
          fullWidth
          value={newAnalysisName}
          onChange={handleChangeName}
          error={newAnalysisName === "" || message !== ""}
          helperText={message}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
      </Stack>
      <InputLabel
        sx={{
          color: "var(--text-main)",
          fontWeight: "bold",
          fontSize: "var(--text)",
          mb: 1,
        }}
      >
        {t("dataModelText.analysisManagement.configAnalysis.groupers")}
      </InputLabel>
      <Select
        reference={selectAgrupationRef}
        value={selectedAgrupation}
        styles={selectStyles(agrupationSelectedOptions)}
        options={dataGetAgrupations && formatOptions(dataGetAgrupations)}
        className="cc_select"
        name="ArchivoBase"
        onChange={agrupationSelectedChangeHandler}
        closeMenuOnSelect
        placeholder={t(
          "dataModelText.analysisManagement.configAnalysis.chooseGrouper"
        )}
        defaultValue={agrupationSelectedOptions}
        isClearable
      />
      <InputLabel
        sx={{
          color: "var(--text-main)",
          fontWeight: "bold",
          fontSize: "var(--text)",
          mt: 2,
        }}
      >
        {t("dataModelText.analysisManagement.configAnalysis.measures")}
      </InputLabel>
      <TransferList
        setLeft={setLeftData}
        left={leftData}
        setRight={setRightData}
        right={rightData}
      />
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          title={t("dataModelText.analysisManagement.configAnalysis.cancel")}
          color="light-grey"
          type="button"
          onClick={closeAndClean}
        />
        <Button
          title={t("dataModelText.analysisManagement.configAnalysis.accept")}
          color="blue-greeny"
          type="submit"
          onClick={handleConfirmationModal}
          disabled={isDataInvalid()}
        />
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleConfirmationModal}
        handleAccept={handleFinish}
        message={
          configMode === "EDIT"
            ? t(
                "dataModelText.analysisManagement.configAnalysis.modifyAnalysis"
              )
            : t(
                "dataModelText.analysisManagement.configAnalysis.createNewAnalysis"
              )
        }
        title={t("dataModelText.analysisManagement.configAnalysis.newAnalysis")}
      />
    </Stack>
  );
};

export default ConfigAnalysis;
