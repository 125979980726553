import { Stack } from "@mui/material";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import CustomButton from "../../../buttons/Button";
import FiltersModal from "./FiltersModal";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";

const FilterSection = () => {
  const { t } = useLanguage();
  const { tabValue } = useMarketBasketProvider();
  const [openFilterModal, toggleFilterModal] = useDialog();

  return (
    <Stack width="100%" alignItems={"flex-end"}>
      <FiltersModal
        openFilterModal={openFilterModal}
        toggleFilterModal={toggleFilterModal}
      />
        <CustomButton
          title={t("highCharts.filters")}
          color="blue"
          type="button"
          disabled={tabValue && tabValue !== "SIN ANALISIS" ? false : true}
          onClick={() => toggleFilterModal()}
          margin={false}
        />
    </Stack>
  );
};

export default FilterSection;
