import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export const CustomCheckboxExpenses = React.forwardRef((props: any, ref: any) => {
  const { selectallrows } = props;

  const handleSelection = (e: any) => {
    if (e.target.ariaLabel === "Select all rows") {
      selectallrows?.handleSelectAllRowsOriginal("todas")
    }
    else if (e.target.ariaLabel === "Unselect all rows") {
      selectallrows?.handleSelectAllRowsOriginal("ninguna")
    }
  }


  return (
    <>
      <Checkbox ref={ref} {...props} sx={{ paddingRight: "0px" }} onClick={handleSelection} />
    </>
  );
});