import { Box } from "@mui/material";
import { charts_grid_box_styles } from "../../../styles/app-styles";
import { useRef } from "react";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import GridCard from "./GridCard";
import useLanguage from "../../../context/LanguageProvider";
import NoDataBox from "../../layout/NoDataBox";

const ChartsGrid = ({
  isUserRolAllowed,
  refetchGetAnalysisViews,
  ELEMENT_URL,
  putOrder,
  PUT_ORDEN_ELEMENT_URL,
  dashboardType
}) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const { t } = useLanguage();

  const { viewTabValue, data, setData, viewTabs } = useViewDashboard();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    if (dragItem.current === undefined || dragOverItem.current === undefined) {
      return;
    } else {
      const copyListItems = [...data];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = undefined;
      dragOverItem.current = undefined;
      setData(copyListItems);
      const indexList = copyListItems.map((item: any) => item.id);
      putOrder(PUT_ORDEN_ELEMENT_URL(viewTabValue), indexList);
    }
  };

  return (
    <>
      {data && data.length > 0 ? (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          sx={charts_grid_box_styles}
          gap={2}
        >
          {data?.map((item, index) => {
            return (
              <GridCard
                key={index}
                item={item}
                index={index}
                dragStart={dragStart}
                dragEnter={dragEnter}
                drop={drop}
                isUserRolAllowed={isUserRolAllowed}
                refetchGetAnalysisViews={refetchGetAnalysisViews}
                ELEMENT_URL={ELEMENT_URL}
              />
            );
          })}
        </Box>
      ) : (
        (dashboardType !== "ANALISIS" || viewTabs?.length > 1 || viewTabs[0]?.value !== "DEFAULT")  && <NoDataBox text={t("dashboard.noElementsYet")} />
      )}
    </>
  );
};

export default ChartsGrid;
