import {esTranslations} from "../../esTranslations";
/*import enTranslations from "data/i18n.en";*/
import Languages from "../../context/types";
import { enTranslations } from "../../enTranslations";
import { ptTranslations } from "../../ptTranslations";

const translate = (language: Languages, key: string) => {
  const keys = key.split(".");

  if (!translationObject(language)) {
    return key;
  }

  let result = translationObject(language) || "";
  let finalResult = ""
  for (const nestedKey of keys) {
    finalResult = result[nestedKey];
    result = result[nestedKey] 
    
    if (finalResult === undefined) {
      return key;
    }
  }
  return finalResult;
};

const translationObject = (language: string) => {
  if (language === "ESPAÑOL") {
    return esTranslations;
  } else if (language === "ENGLISH") {
    return enTranslations;
  } else if (language === "PORTUGUÊS") {
    return ptTranslations;
  } 
};

export default translate;
