import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  card_subtitle_styles,
  left_key_text_styles,
} from "../../../styles/app-styles";

const RelationshipsCard = ({ item, showInfo }: any) => {
  const dataGetRelationshipsParams = item?.data;

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        {showInfo && (
          <>
            {dataGetRelationshipsParams?.map((file, index) => {
              return (
                <Typography
                  key={index}
                  variant="subtitle2"
                  sx={card_subtitle_styles}
                >
                  <b>{file?.nombre}</b>
                  <br />
                  {file?.involucrados?.map((relacion, index) => {
                    return (
                      <Box sx={left_key_text_styles} key={index}>
                        <b>{relacion.archivo.label}:</b>{" "}
                        {relacion.columna.label}
                        <br />
                      </Box>
                    );
                  })}
                  <br />
                </Typography>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};

export default RelationshipsCard;
