import { createContext, useContext, useState } from "react";
import { Filter } from "../components/configCriteria/types";
import { Tab } from "../components/colorTabs/types";
import { useApiQuery } from "../hooks/useApiQuery";
import useApi from "../hooks/useApi";
import useLanguage from "./LanguageProvider";
import useTabs from "../hooks/useTabs";

import {
  MARKET_BASKET_COLUMNS_URL,
  MARKET_BASKET_URL,
  MB_INDICATORS_PRECALCULATED_URL,
  MB_INDICATORS_URL,
  MB_TABLE_URL,
  MB_BARRAS_URL,
  MB_RESULTADOS_URL,
} from "../api/axios";

const MarketBasketContext = createContext<any>({});

const MarketBasketProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useLanguage();

  /************************************** Estados generales ************************************/
  const [columnSelectKey, setColumnSelectKey] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [tabValue, handleChange, setTabValue] = useTabs("SIN ANALISIS");
  const [filters, setFilters] = useState<any>({});
  const [tempFilters, setTempFilters] = useState<Filter[] | undefined>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [tabs, setTabs] = useState<Tab[]>([
    { value: "SIN ANALISIS", label: "SIN ANALISIS" },
  ]);
  const [simulationValues, setSimulationValues] = useState(0);
  const [mbUpdatedResults, setMbUpdatedResults] = useState<any>([]);
  const [counter, setCounter] = useState(0);

  /**************************** Api Querys **********************************/

  const {
    isLoading: isLoadingGetAnalysisViews,
    data: dataAnalysisViews,
    refetch: refetchGetAnalysisViews,
  } = useApiQuery(MARKET_BASKET_URL, false, t("dataModel.getAnalysis.error"));

  const {
    data: mbAnalysisResults,
    isLoading: isLoadingMbAnalysisResults,
    callApi: getMbAnalysisResults,
  } = useApi(
    MB_RESULTADOS_URL,
    "POST",
    undefined,
    { filtro: filters },
    undefined,
    undefined,
    false
  );  

  const {
    data: dataColumnsMarketBasket,
    isLoading: isLoadingColumnsMarketBasket,
    callApi: getColumnsMarketBasket,
  } = useApi(
    MARKET_BASKET_COLUMNS_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    data: kpisDataPrecalculated,
    isLoading: isLoadingKpisPrecalculated,
    callApi: getKpisPrecalculated,
  } = useApi(
    MB_INDICATORS_PRECALCULATED_URL,
    "POST",
    undefined,
    { filtro: filters },
    undefined,
    undefined,
    false
  );

  const {
    data: kpisData,
    isLoading: isLoadingKpis,
    callApi: getKpis,
  } = useApi(
    MB_INDICATORS_URL,
    "POST",
    undefined,
    { filtro: filters },
    undefined,
    undefined,
    false
  );

  const {
    data: tableData,
    isLoading: isLoadingTableData,
    callApi: getTableData,
  } = useApi(
    MB_TABLE_URL,
    "POST",
    undefined,
    { filtro: filters },
    undefined,
    undefined,
    false
  );

  const {
    data: chartsBarData,
    isLoading: isLoadingChartsBarData,
    callApi: getChartsBarData,
  } = useApi(
    MB_BARRAS_URL,
    "POST",
    undefined,
    { filtro: filters },
    undefined,
    undefined,
    false
  );

  return (
    <MarketBasketContext.Provider
      value={{
        tabValue,
        setTabValue,
        mbAnalysisResults,
        isLoadingMbAnalysisResults,
        mbUpdatedResults,
        getMbAnalysisResults,
        setMbUpdatedResults,
        counter, setCounter,
        columnSelectKey,
        setColumnSelectKey,
        selectedOptions,
        setSelectedOptions,
        openFilterModal,
        setOpenFilterModal,
        handleChange,
        tabs,
        setTabs,
        filters,
        setFilters,
        tempFilters,
        setTempFilters,
        dataAnalysisViews,
        dataColumnsMarketBasket,
        isLoadingColumnsMarketBasket,
        getColumnsMarketBasket,
        kpisDataPrecalculated,
        isLoadingKpisPrecalculated,
        getKpisPrecalculated,
        kpisData,
        isLoadingKpis,
        getKpis,
        tableData,
        isLoadingTableData,
        getTableData,
        selectedRows,
        setSelectedRows,
        chartsBarData,
        isLoadingChartsBarData,
        getChartsBarData,
        simulationValues,
        setSimulationValues,
      }}
    >
      {children}
    </MarketBasketContext.Provider>
  );
};

export default MarketBasketProvider;

export const useMarketBasketProvider = (): any => {
  const context = useContext(MarketBasketContext);
  if (!context) {
    throw new Error(
      "useMarketBasketProvider must be used within a MarketBasketProvider"
    );
  }
  return context;
};
