import { Box, Typography } from "@mui/material";
import { columnTitleStyle, editIconStyle } from "../../../styles/app-styles";
import { convertNumberToMoney, isRowIdABucket } from "../utils";
import { CustomGridTreeDataGroupingCell } from "../rowsTreeData";
import { GridColumns, DataGridProProps } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../../context/LanguageProvider";
import { RolConfig } from "../../administrator/data";

export const getColumns = (
  handleOpenEditDialog: (id: number, value: string) => void,
  rows: any,
  userRolData: RolConfig,
  addActions?: boolean
  
) => {
  const { t } = useLanguage();
  const columnsNames: string[] = [
    t("modelParameterizationTexts.columnsTreeData.getColumns.grouper"),
    t("modelParameterizationTexts.columnsTreeData.getColumns.amount"),
    t("modelParameterizationTexts.columnsTreeData.getColumns.stages"),
  ];

  const filteredColumnsNames = addActions
    ? columnsNames.filter(
        (column) =>
          column ===
          t("modelParameterizationTexts.columnsTreeData.getColumns.grouper")
      )
    : columnsNames;
  const columns: GridColumns = filteredColumnsNames.map((column: any) => {
    return {
      field: column,
      headerClassName: "dm_header_column",
      renderHeader: () => (
        <Typography sx={columnTitleStyle}>
          {column ===
          t("modelParameterizationTexts.columnsTreeData.getColumns.amount")
            ? t(
                "modelParameterizationTexts.columnsTreeData.getColumns.amountExpense"
              )
            : column.toUpperCase()}
        </Typography>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <Box
          sx={{
            width: "100%",
            marginLeft:
              column ===
              t("modelParameterizationTexts.columnsTreeData.getColumns.grouper")
                ? "20px"
                : 0,
          }}
        >
          {
            <Box
              style={{
                display: "flex",
                justifyContent:
                  column ===
                  t(
                    "modelParameterizationTexts.columnsTreeData.getColumns.grouper"
                  )
                    ? "space-between"
                    : "center",
                width: "100%",
              }}
            >
              <Typography
                variant="body2"
                textAlign={
                  column ===
                  t(
                    "modelParameterizationTexts.columnsTreeData.getColumns.grouper"
                  )
                    ? "left"
                    : "center"
                }
                sx={{
                  width:
                    column ===
                    t(
                      "modelParameterizationTexts.columnsTreeData.getColumns.grouper"
                    )
                      ? "75%"
                      : "100%",
                  fontWeight:
                    column ===
                      t(
                        "modelParameterizationTexts.columnsTreeData.getColumns.amount"
                      ) && isRowIdABucket(params.id, rows)
                      ? "800"
                      : "100",
                  overflow: "hidden",
                }}
              >
                {column ===
                t(
                  "modelParameterizationTexts.columnsTreeData.getColumns.amount"
                )
                  ? params.value === 0
                    ? "$ 0,00"
                    : convertNumberToMoney(params.value)
                  : params.value}
              </Typography>
              {userRolData && userRolData.parametrizacion_de_preprorrateo_y_prorrateo.visualizacion_y_edicion && (
                addActions
                ? column ===
                    t(
                      "modelParameterizationTexts.columnsTreeData.getColumns.grouper"
                    ) &&
                  params.value && (
                    <Tooltip title="Editar nombre">
                      <EditIcon
                        fontSize="small"
                        sx={editIconStyle}
                        onClick={() =>
                          handleOpenEditDialog(params.id, params.formattedValue)
                        }
                      />
                    </Tooltip>
                  )
                : ""
              )}
              
            </Box>
          }
        </Box>
      ),
    };
  });

  return columns;
};

export const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
  row.hierarchy;

export const getGroupingColDef = (
  handleOpenEditDialog: (id: number, value: string) => void,
  handleExpand: () => void,
  isExpanded: boolean,
  rows: any,
  addActions?: boolean
) => {
  const { t } = useLanguage();
  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: t(
      "modelParameterizationTexts.columnsTreeData.getGroupingColDef.bucketExpense"
    ),
    flex: 1,
    headerAlign: "center",
    minWidth: 335,
    renderHeader: () => (
      <>
        <Typography sx={columnTitleStyle}>
          {t(
            "modelParameterizationTexts.columnsTreeData.getGroupingColDef.bucketExpense"
          )}
        </Typography>
        {isExpanded && (
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={t(
              "modelParameterizationTexts.columnsTreeData.getGroupingColDef.collapse"
            )}
          >
            <KeyboardDoubleArrowDownIcon
              sx={{ color: "white", marginLeft: "7px", cursor: "pointer" }}
              onClick={() => handleExpand()}
            />
          </Tooltip>
        )}
        {!isExpanded && (
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={t(
              "modelParameterizationTexts.columnsTreeData.getGroupingColDef.expand"
            )}
          >
            <KeyboardDoubleArrowRightIcon
              sx={{ color: "white", marginLeft: "7px", cursor: "pointer" }}
              onClick={() => handleExpand()}
            />
          </Tooltip>
        )}
      </>
    ),
    headerClassName: "dm_header_column",
    renderCell: (params) => (
      <CustomGridTreeDataGroupingCell
        {...params}
        handleOpenEditDialog={handleOpenEditDialog}
        isExpanded={isExpanded}
        rows={rows}
        addActions={addActions}
      />
    ),
  };

  return groupingColDef;
};
