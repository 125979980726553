import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import { memo } from "react";
interface Props {
  results: any;
}
const PieDrilldownChart = memo((props: Props) => {
  const { results } = props;

  drilldown(Highcharts);

  const options = {
    chart: {
      type: "pie",
      style: {
        width: "100%",
      },
      zooming: {
        type: "xy",
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
      },
    },
    title: false, 
    colors: [
      "var(--chart-1)",
      "var(--chart-2)",
      "var(--chart-3)",
      "var(--chart-4)",
      "var(--chart-5)",
      "var(--chart-6)",
      "var(--chart-7)",
      "var(--chart-8)",
      "var(--chart-9)",
      "var(--chart-10)",
      "var(--chart-11)",
      "var(--chart-12)",
      "var(--chart-13)",
      "var(--chart-14)",
      "var(--chart-15)",
      "var(--chart-16)",
      "var(--chart-17)",
      "var(--chart-18)",
      "var(--chart-19)",
      "var(--chart-20)",
      "var(--chart-21)",
    ],
    series: results.series,
    drilldown: results.drilldown,
    accessibility: {
      enabled: true,
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
});

export default PieDrilldownChart;
