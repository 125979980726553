import Box from "@mui/material/Box";
import { shadow_box_styles } from "../../../styles/app-styles";

interface ShadowBoxProps {
  children: React.ReactNode;
  margin?: boolean
}

const ShadowBox = ({ children, margin }: ShadowBoxProps) => {
  return <Box sx={shadow_box_styles(margin)}>{children}</Box>;
};

export default ShadowBox;
