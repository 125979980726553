import { Stack, Typography } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import useLanguage from "../../context/LanguageProvider";

const PageInProgress = () => {
  const { t } = useLanguage();

  return (
    <Stack
      sx={{
        width: "100%",
        height: "90vh",
        marginTop: "10px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ConstructionIcon sx={{ color: "var(--icon-info)", fontSize: "140px" }} />
      <Typography
        sx={{
          fontSize: "25px",
          fontWeight: "600",
          color: "var(--text-info)",
          marginTop: "30px",
        }}
      >
        {t("pageinProgress.underDevelopment")}
      </Typography>
    </Stack>
  );
};

export default PageInProgress;
