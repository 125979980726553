import { convertArrayToText } from "../../../taskManager/utils";
import { FILTER_FUNCTIONS } from "../../data";
import { Item } from "../../../basicGrid/BasicGrid";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import Delete from "../../../icons/Delete";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";

interface RowProps {
  index: number;
  rows: any[];
  setRows: any;
  row: any;
  titles: string[];
  deletable?: boolean;
}

const FilterRowsRow = ({
  rows,
  setRows,
  row,
  titles,
  index,
  deletable = true,
}: RowProps) => {
  const { t } = useLanguage();

  const handleDeleteFilter = () => {
    const newRows = rows?.filter((r: any, i) => {
      return i !== index;
    });
    setRows(newRows);
  };

  console.log(row?.valores, "row?.valores");

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          {deletable && (
            <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
          )}
        </Stack>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.columna?.label}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.operador?.label ||
              FILTER_FUNCTIONS(t)?.find((f) => f.value === row?.operador)
                ?.label}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.valores && row.valores.length > 0
              ? row.valores[0]?.label || row.valores[0]?.label === 0
                ? convertArrayToText(
                    row?.valores?.map((valor: any) => {
                      return valor?.label;
                    })
                  )
                : convertArrayToText(
                    row?.valores?.map((valor: any) => {
                      return valor;
                    })
                  )
              : "-"}
          </Typography>
        </Item>
      </Grid>{" "}
      <Grid item xs={0.5}></Grid>
    </>
  );
};
export default FilterRowsRow;
