import Typography from "@mui/material/Typography";
import Dialog from "../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import WhiteShadowBox from "../../layout/WhiteShadowBox";
import useLanguage from "../../../context/LanguageProvider";

interface ViewAnalysisProps {
  info: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  title: string | undefined;
}

const ViewInfoAnalysisModal = ({
  info,
  open,
  handleClose,
  title,
}: ViewAnalysisProps) => {
  const { t } = useLanguage();

  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t(
          "dataModelText.analysisManagement.viewInfoAnalysisModal.analysisInfo"
        )}
      >
        <Stack
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          padding="10px 100px 0px 100px"
          position="relative"
        >
          <WhiteShadowBox>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
                textAlign: "center",
                width: "100%",
              }}
            >
              {title}
            </Typography>
            <p
              style={{
                color: "var(--text-main)",
                margin: "5px",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t(
                  "dataModelText.analysisManagement.viewInfoAnalysisModal.grouper"
                )}
              </span>{" "}
              {info?.agrupacion.label}
            </p>
            <p
              style={{
                color: "var(--text-main)",
                margin: "5px",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t(
                  "dataModelText.analysisManagement.viewInfoAnalysisModal.measures"
                )}
              </span>{" "}
              {info?.medidas
                ?.map((medida: any) => `'${medida.nombre?.toUpperCase()}'`)
                .join(", ")}
            </p>
          </WhiteShadowBox>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoAnalysisModal;
