import Stack from "@mui/material/Stack";
import { CustomizedTable } from "../customizedTable";
import CheckIcon from "@mui/icons-material/Check";
import { getPeriodsByYear } from "./utils";
import CloseIcon from "@mui/icons-material/Close";
import { Period, Periods } from "./PeriodsFilter";
import useLanguage from "../../context/LanguageProvider";
interface IEnabledPeriodsTable {
  totalYears: string[];
  periods: Periods | undefined;
  totalPeriodsData: Periods;
}

const EnabledPeriodsTable = ({
  totalYears,
  periods,
  totalPeriodsData,
}: IEnabledPeriodsTable) => {
  if (!periods) {
    return <></>;
  }

  const { t } = useLanguage()

  const COLUMNS = [
    t("periods.enabledPeriodsTable.year"),
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const COLUMNS1 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const ROWS1 = totalYears.map((year: string) => {
    const columns = COLUMNS1.map((month: string) => {
      return getPeriodsByYear(year, periods).some((period: Period) =>
        period.fecha.split("-")[0].includes(month)
      ) ? (
        <CheckIcon key={`${month}-${year}`} sx={{ color: "#32648e" }} />
      ) : totalPeriodsData.some((period: Period) =>
          period.fecha.includes(`${month}-${year}`)
        ) ? (
        <CloseIcon key={`${month}-${year}`} sx={{ color: "#f90d4a" }} />
      ) : (
        "-"
      );
    });
    columns.unshift(<strong>{year}</strong>);
    return columns;
  });

  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {ROWS1 && ROWS1.length && (
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            backgroundColor: "white",
            borderRadius: "5px",
            boxShadow: "2px 2px 10px 2px rgb(0 0 0 / 20%)",
            color: "var(--text-main)",
            margin: "0 0 30px 0 !important",
            minWidth: "500px",
            padding: "14px 23px",
          }}
        >
          <CustomizedTable COLUMNS={COLUMNS} ROWS={ROWS1} width={680} />
          <Stack
            direction="row"
            mt="7px"
            width="100%"
            justifyContent="space-evenly"
          >
            <Stack direction="row">
              <CheckIcon sx={{ color: "#32648e" }} /> {t("periods.enabledPeriodsTable.enabledPeriods")}
            </Stack>
            <Stack direction="row">
              <CloseIcon sx={{ color: "#f90d4a" }} /> {t("periods.enabledPeriodsTable.notEnabledPeriods")}
            </Stack>
            <Stack direction="row">- {t("periods.enabledPeriodsTable.nonExistingPeriods")}</Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default EnabledPeriodsTable;
