import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SpeedDialTooltipOpen from '../../buttons/SpeedDialTooltipOpen';
import { truncateString } from '../../parameterization/utils';

const MeasureCard = ({
    item,
    handleEdit,
    handleCopy,
    handleInfo,
    handleDelete,
    showInfo,
  }: any) => {
  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--text-main)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "15px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "5px 0 10px 0" }} /> 
        <SpeedDialTooltipOpen
          id={item.id}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
}

export default MeasureCard