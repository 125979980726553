import { convertToText, transformText } from "./utils";
import { IconButton, Tooltip } from "@mui/material";
import { truncateString } from "../parameterization/utils";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";
import { tooltip_text_styles } from "../../styles/app-styles";

const CriteriaCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
}: any) => {
  const { t } = useLanguage();
  const [openBucketsTooltip, setOpenBucketstooltip] = useState(false);

  const handleBucketsTooltipClose = () => {
    setOpenBucketstooltip(false);
  };

  const handleBucketsTooltipOpen = () => {
    setOpenBucketstooltip((prev: boolean) => !prev);
  };

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        <Stack
          alignItems="center"
          sx={{ wordBreak: "break-word", display: "inline" }}
        >
          <Tooltip title={item.nombre}>
            <Typography sx={tooltip_text_styles}>
              {truncateString(item.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "6px 0" }} />
        {showInfo && (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                display: "inline",
              }}
              color="var(--text-main)"
            >
              Buckets:{" "}
              {
                <span
                  style={{
                    color: "var(--text-info)",
                    textAlign: "left",
                    display: "inline",
                    textTransform: "capitalize",
                    fontWeight: "300",
                  }}
                >
                  {transformText(convertToText(item.criterio_bucket, t), 40, t)}
                </span>
              }
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
              }}
              color="var(--text-main)"
            >
              {t("criteriaManagementTexts.criteriaCard.receivers")}:{" "}
              {
                <span
                  style={{
                    color: "var(--text-info)",
                    textAlign: "left",
                    display: "inline",
                    textTransform: "capitalize",
                    fontWeight: "300",
                  }}
                >
                  {transformText(
                    convertToText(item.criterio_receptor_gasto, t),
                    40,
                    t
                  )}
                </span>
              }
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                display: "flex",
                alignItems: "center",
                lineHeight: "1.3",
              }}
              color="var(--text-main)"
            >
              {t("criteriaManagementTexts.criteriaCard.filters")}
              {item.criterio_columna_filtra?.length ? (
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: "20px",
                    color: "var(--text-success)",
                    ml: 0.5,
                    mr: 1.5,
                  }}
                />
              ) : (
                <HighlightOffIcon
                  sx={{
                    fontSize: "20px",
                    color: "var(--icon-error)",
                    ml: 0.5,
                    mr: 1.5,
                  }}
                />
              )}
              {t("criteriaManagementTexts.criteriaCard.validations")}
              {item.criterio_columna_valida?.length ? (
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: "20px",
                    color: "var(--icon-success)",
                    ml: 0.5,
                    mr: 1.5,
                  }}
                />
              ) : (
                <HighlightOffIcon
                  sx={{
                    fontSize: "20px",
                    color: "var(--icon-error)",
                    ml: 0.5,
                    mr: 1.5,
                  }}
                />
              )}
            </Typography>
          </>
        )}
        <Stack direction="row" justifyContent={"space-between"}>
          <ClickAwayListener onClickAway={handleBucketsTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleBucketsTooltipClose}
                open={openBucketsTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                placement="right"
                title={
                  item.buckets_usados?.length
                    ? item.buckets_usados?.length > 1
                      ? `${t(
                          "criteriaManagementTexts.criteriaCard.inUseBuckets"
                        )} ` + convertToText(item.buckets_usados, t)
                      : `${t(
                          "criteriaManagementTexts.criteriaCard.inUseBucket"
                        )} ` + convertToText(item.buckets_usados, t)
                    : t("criteriaManagementTexts.criteriaCard.notUsed")
                }
              >
                <IconButton
                  onClick={handleBucketsTooltipOpen}
                  sx={{
                    backgroundColor: "var(--cream)",
                  }}
                >
                  {item.buckets_usados?.length ? (
                    <DoneOutlineIcon
                      sx={{
                        color: "var(--icon-success)",
                      }}
                    />
                  ) : (
                    <CloseIcon
                      sx={{
                        color: "var(--icon-error)",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CriteriaCard;
