import { useState } from 'react';
import useApi from '../../../hooks/useApi';

interface AccordionFilesProps {
    children: any
}

export default function AccordionFiles({ children }: AccordionFilesProps) {

    const [selectedFolder, setSelectedFolder] = useState(false);

    const {
        data: dataInfoDatalake,
        isLoading: isLoadingDataInfoDatalake,
        callApi: getDataInfoDataLake
    } = useApi(
        "",
        "GET",
        undefined,
        undefined,
        undefined,
        undefined,
        false
    );

    return children({
        dataInfoDatalake,
        isLoadingDataInfoDatalake,
        getDataInfoDataLake,
        selectedFolder,
        setSelectedFolder
    });
}
