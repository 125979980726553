import { isElementInUse } from "../utils";
import { Item } from "../../../basicGrid/BasicGrid";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import { useSnackbar, VariantType } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "../../../icons/Delete";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
interface ObjectiveRowProps {
  row: any;
  handleSetNewRows: any;
  rows: any[];
  secondaryMetricsRows: any[];
  titles: string[];
  isRowDeletable?: boolean;
}

const PrimaryMetricsRow = ({
  row,
  handleSetNewRows,
  rows,
  secondaryMetricsRows,
  titles,
  isRowDeletable
}: ObjectiveRowProps) => {
  const { t } = useLanguage();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const titlesQuantity = titles.length;

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const handleDeleteFilter = () => {
    if (isElementInUse(row, secondaryMetricsRows)) {
      handleClickVariant(t("general.usedElement"), "error");
    } else {
      const newRows = rows?.filter((objRow: any) => {
        return !(objRow.nombre === row?.nombre);
      });
      handleSetNewRows(newRows);
    }
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          {isRowDeletable ? <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} /> : <></>}
        </Stack>
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Item>
          <Typography color={"primary"} sx={{fontSize: "14px"}}>{row?.nombre}</Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Item>
          <Typography color={"primary"} sx={{fontSize: "14px"}}>
            {typeof row?.funcion === "string"
              ? row?.funcion
              : row?.funcion?.label}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Item>
          <Typography color={"primary"} sx={{fontSize: "14px"}}>{row?.columna?.label}</Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Item>
          <Typography color={"primary"} sx={{fontSize: "14px"}}>
            {row?.columna_divisora?.label ? row?.columna_divisora?.label : "-"}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={11 / titlesQuantity}>
        <Item>
          <Typography color={"primary"} sx={{fontSize: "14px"}}>
            {typeof row?.consolidar_periodos === "string"
              ? row?.consolidar_periodos
              : row?.consolidar_periodos?.label}
          </Typography>
        </Item>
      </Grid>
      {row?.consolidar_destinos && (
        <Grid item xs={11 / titlesQuantity}>
          <Item>
            <Typography color={"primary"} sx={{fontSize: "14px"}}>
              {typeof row?.consolidar_destinos === "string"
                ? row?.consolidar_destinos
                : row?.consolidar_destinos?.label}
            </Typography>
          </Item>
        </Grid>
      )}

      <Grid item xs={0.5}></Grid>
    </>
  );
};

export default PrimaryMetricsRow;
