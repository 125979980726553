import { ROLES_URL } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import AddCriteriaButton from "../../criteriaManagement/AddCriteriaButton";
import BasicGrid from "../../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import RolCard from "./RolCard";
import RolForm from "./RolForm";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import { ConfirmationModal as DeleteIndicatorConfirmationModal } from "../../dialog";
import {
  active_inactive_typography_styles,
  mb_20_styles,
  no_cards_error_typography_styles,
  no_elements_box_styles,
} from "../../../styles/app-styles";
import useDialog from "../../../hooks/useDialog";
import useApi from "../../../hooks/useApi";
interface RolesViewProps {
  setConfigOpen: (value: boolean) => void;
  configOpen: boolean;
}

const RolesView = ({ setConfigOpen, configOpen }: RolesViewProps) => {
  const { t } = useLanguage();
  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "COPY" | "">(
    ""
  );
  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [rol, setRol] = useState<any>();
  const [idToDelete, setIdToDelete] = useState<number>(0);
  const [openDeleteConfirmationModal, toggleDeleteConfirmationModal] =
    useDialog();

  const {
    data: dataRoles,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useApiQuery(ROLES_URL, false);

  useEffect(() => {
    if (dataRoles) {
      setFilteredData(dataRoles);
    }
  }, [dataRoles]);

  const handleAdd = () => {
    setConfigMode("CREATE");
    setConfigOpen(true);
  };

  const handleFinish = () => {
    setConfigOpen(false);
    refetchRoles();
    setConfigMode("");
    location.reload();
  };

  const handleEdit = (e: any, id: number) => {
    setConfigMode("EDIT");
    setRol(dataRoles?.find((r: any) => r.id === id));
    setConfigOpen(true);
  };

  const handleCopy = (e: any, id: number) => {
    setConfigMode("COPY");
    setRol(dataRoles?.find((r: any) => r.id === id));
    setConfigOpen(true);
  };

  const filterData = (value: string) => {
    return dataRoles?.filter((r: any) => {
      return r.nombre?.toLowerCase().includes(value?.toLowerCase());
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserValue(e.target.value);
    if (e.target.value) {
      setFilteredData(filterData(e.target.value));
    } else {
      setFilteredData(dataRoles);
    }
  };

  const handleDelete = (e: any, id: number) => {
    setIdToDelete(id);
    toggleDeleteConfirmationModal();
  };

  const handleDeleteRole = () => {
    toggleDeleteConfirmationModal();
    deleteRol();
  };

  const { isLoading: isLoadingDeleteRol, callApi: deleteRol } = useApi(
    `${ROLES_URL}${idToDelete}`,
    "DELETE",
    t("administratorPanel.roles.deleteRole.codes"),
    undefined,
    refetchRoles
  );

  const isData = dataRoles && !isLoadingRoles && filteredData;

  return (
    <>
      <SimpleBackdrop open={isLoadingRoles} message={t("general.loading")} />
      <SimpleBackdrop
        open={isLoadingDeleteRol}
        message={t("administratorPanel.roles.deleteRole.loading")}
      />
      {!configOpen ? (
        <Stack spacing={2} sx={{ width: "100%", marginTop: "0px !important" }}>
          <SearchInput
            value={searchUserValue}
            handleChange={handleSearch}
            placeholder={t("administratorPanel.roles.searchRolePlaceholder")}
          />
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.roles.activeRoles")}
          </Typography>
          <Stack sx={mb_20_styles}>
            {isData &&
            filteredData.filter((r: any) => r.habilitado).length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.roles.noActiveRoles")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <BasicGrid
                  data={filteredData.filter((r: any) => r.habilitado)}
                  Card={RolCard}
                  showInfo={false}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  handleDelete={handleDelete}
                  refetchData={refetchRoles}
                />
              )
            )}
          </Stack>
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.roles.inactiveRoles")}
          </Typography>

          <Stack sx={mb_20_styles}>
            {isData &&
            filteredData.filter((r: any) => !r.habilitado).length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.roles.noInactiveRoles")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <BasicGrid
                  data={filteredData.filter((r: any) => !r.habilitado)}
                  Card={RolCard}
                  showInfo={false}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  handleDelete={handleDelete}
                  refetchData={refetchRoles}
                />
              )
            )}
          </Stack>
          <AddCriteriaButton handleClick={() => handleAdd()} />
        </Stack>
      ) : (
        <RolForm
          configMode={configMode}
          rol={rol}
          handleFinish={handleFinish}
        />
      )}
      <DeleteIndicatorConfirmationModal
        open={openDeleteConfirmationModal}
        handleClose={toggleDeleteConfirmationModal}
        handleAccept={handleDeleteRole}
        message={t("administratorPanel.roles.deleteRol")}
        title={t("general.confirm")}
      />
    </>
  );
};

export default RolesView;
