import {
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Checkbox,
  Paper,
  Tooltip,
} from "@mui/material";
import useLanguage from "../../../../context/LanguageProvider";

interface RightCustomListProps {
  items: any;
  isMeasureEnabled: (value: any, left: any[], right: any[]) => boolean;
  left: any[];
  checked: readonly number[];
  handleToggle: (value: any) => () => void;
  hasCommonGroupers: (isRightList: boolean, items: any[], value: any) => string;
}

const RightCustomList = ({
  items,
  isMeasureEnabled,
  left,
  checked,
  handleToggle,
  hasCommonGroupers,
}: RightCustomListProps) => {
  const { t } = useLanguage();
  return (
    <Paper
      sx={{
        width: 600,
        height: 390,
        overflow: "auto",
        marginBottom: "15px",
        border: items.length === 0 ? "1px solid var(--bg-error)" : "",
      }}
    >
      <List dense component="div" role="list">
        {items?.map((value: any, idx: number) => {
          const labelId = `transfer-list-item-${value}-label`;
          const isEnabled = isMeasureEnabled(value, left, items);
          return (
            <Tooltip
              title={
                isEnabled
                  ? ""
                  : t(
                      "dataModelText.analysisManagement.transferList.measureDisabled"
                    )
              }
              key={idx}
            >
              <ListItem
                key={idx}
                role="listitem"
                onClick={isEnabled ? handleToggle(value) : handleToggle(value)}
                sx={{
                  "&:hover": {
                    backgroundColor: isEnabled ? "var(--bg-secondary)" : "",
                    "& .typographyList": {
                      color: isEnabled ? "white" : "",
                    },
                  },
                  background:
                    checked.indexOf(value) !== -1 && !isEnabled
                      ? "var(--bg-light)"
                      : checked.indexOf(value) !== -1
                      ? "var(--bg-main)"
                      : "",
                  borderTop: "1px solid var(--bg-light)",
                  borderBottom:
                    idx === items.length - 1
                      ? "1px solid var(--bg-light)"
                      : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    disabled={isEnabled ? false : true}
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    sx={{
                      background: !isEnabled
                        ? "var(--bg-disabled)"
                        : hasCommonGroupers(true, items, value),
                    }}
                  />
                </ListItemIcon>
                <Typography
                  className="typographyList"
                  sx={{
                    color: !isEnabled
                      ? "var(--text-disabled)"
                      : checked.indexOf(value) !== -1
                      ? "white"
                      : hasCommonGroupers(true, items, value),
                    fontWeight: "bold",
                  }}
                >
                  {`${value?.nombre?.toUpperCase()}`}
                </Typography>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );
};

export default RightCustomList;
