import {
  column_type_checkbox_container,
  column_type_replace_container,
  key_column_select_styles,
} from "../../../../styles/app-styles";
import { columnsTypeOptions } from "../data";
import { Radio } from "@mui/material";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import CheckboxOption from "../../../forms/CheckboxOption";
import CheckboxSelect, { ElementType } from "../../../forms/CheckboxSelect";
import CustomInput from "../../../forms/CustomInput";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";

const MultipleAssignModal = ({
  handleToggleModal,
  columnsOptions,
  setFilesColumnsTypes,
}) => {
  const { t } = useLanguage();
  const columnSelectRef: any = useRef(null);
  const [selectedColumns, setSelectedColumns] = useState<ElementType[]>([]);
  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect();
  const [replace, setReplace] = useState<boolean>(false);
  const [numericValue, setNumericValue] = useState<string | number>("");
  const handleChange = (event: any) => {
    setNumericValue(event.target.value);
  };
  const [columnValidations, setColumnValidations] = useState<any>({
    requerida: false,
    valores_nulos: true,
    valores_unicos: false,
  });
  const toggleColumnValidations = (value: any) => {
    if (value === "requerida") {
      setColumnValidations((prev) => {
        return { ...prev, requerida: !columnValidations.requerida };
      });
    } else if (value === "valoresNulos") {
      setColumnValidations((prev) => {
        return { ...prev, valores_nulos: !columnValidations.valores_nulos };
      });
    } else if (value === "valoresUnicos") {
      setColumnValidations((prev) => {
        return { ...prev, valores_unicos: !columnValidations.valores_unicos };
      });
    }
  };

  const handleApply = () => {
    setFilesColumnsTypes((prev) => {
      return prev.map((file) => {
        return {
          ...file,
          columnas: file.columnas.map((column) => {
            if (selectedColumns?.map(selCol => selCol.value).includes(column.value)) {
              return {
                ...column,
                tipo: columnSelectedOption?.value,
                requerida: columnValidations.requerida,
                valores_nulos: columnValidations.valores_nulos,
                valores_unicos: columnValidations.valores_unicos,
                reemplazar: replace ? numericValue : "",
                valor_reemplazo: numericValue,
              };
            } else {
              return column;
            }
          }),
        };
      });
    });
    handleToggleModal();
  };

  const selectAll = () => {
    setSelectedColumns(columnsOptions);
  }

  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "space-between",
        height: "100%",
        minHeight: "200px",
      }}
    >
      <Stack sx={{...key_column_select_styles, alignItems: "center"}}>
        <Stack width="70%" direction="row" alignItems={"center"}>
          <CheckboxSelect
            data={columnsOptions}
            selectedElements={selectedColumns}
            setSelectedElements={setSelectedColumns}
            width="100%"
            selectLabel={t("general.chooseColumns")}
          />
          <Button
              title={t('companyParam.selectAll')}
              color="blue"
              type="button"
              onClick={selectAll}
              disabled={false}
              width={200}
            />
        </Stack>
        <Stack sx={{ width: "30%" }}>
          <Select
            reference={columnSelectRef}
            isClearable
            styles={selectStyles(columnSelectedOption)}
            options={columnsTypeOptions}
            className="pp_select_schema"
            onChange={columnOptionChangeHandler}
            closeMenuOnSelect
            isSearchable
            placeholder={t(
              "preprorationParameterization.newSchemaContent.chooseAddValue"
            )}
          />
        </Stack>
      </Stack>
      <Stack sx={column_type_checkbox_container}>
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("requerida")}
          checked={columnValidations.requerida}
          id={`requerida-asignacion-multiple`}
          labelText={t('companyParam.required')}
        />
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("valoresNulos")}
          checked={columnValidations.valores_nulos}
          id={`valoresNulos-asignacion-multiple`}
          labelText={t('companyParam.allowNullValues')}
        />
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("valoresUnicos")}
          checked={columnValidations.valores_unicos}
          id={`valoresUnicos-asignacion-multiple`}
          labelText={t('companyParam.onlyUniqueValues')}
        />
      </Stack>
      {columnSelectedOption && columnSelectedOption.value === "numerica" && (
        <Stack sx={column_type_replace_container}>
          <CheckboxOption
            toggleCheckbox={() => setReplace(!replace)}
            checked={replace}
            id="reemplazar"
            labelText={t("companyParam.replaceNoNumeric")}
            minWidth={"250px"}
          />
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Radio
              checked={numericValue === "PROMEDIO"}
              onChange={handleChange}
              value={"PROMEDIO"}
              name="radio-buttons"
              id="promedio"
              disabled={!replace}
            />
            <label
              style={{
                color: "var(--text-main)",
                fontSize: "14px",
              }}
              htmlFor={"promedio"}
            >
              {t('companyParam.average')}
            </label>
          </Stack>
          <Radio
            checked={numericValue !== "PROMEDIO"}
            onChange={handleChange}
            value={numericValue !== "PROMEDIO" ? numericValue : ""}
            name="radio-buttons"
            disabled={!replace}
          />
          <CustomInput
            value={numericValue !== "PROMEDIO" ? numericValue : ""}
            setValue={setNumericValue}
            disabled={!replace}
            placeholder={t('companyParam.value')}
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("periods.periodsDialogContent.cancel")}
          color="grey"
          type="button"
          onClick={handleToggleModal}
        />
        <Button
          title={t("periods.periodsDialogContent.apply")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={false}
        />
      </Stack>
    </Stack>
  );
};

export default MultipleAssignModal;
