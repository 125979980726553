import { Checkbox, ListItem, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface IListItem {
  item: any;
  handleChangeItem: (item: any) => void;
  selectedElements: any[];
  handleToggleCollapse;
}

const Listitem = ({
  item,
  handleChangeItem,
  selectedElements,
  handleToggleCollapse,
}: IListItem) => {
  return (
    <ListItem
      divider={true}
      sx={{
        marginLeft: "0px",
        paddingLeft: "0px",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div
        style={{
          color: "var(--text-main)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={selectedElements.map((el: any) => el.item).includes(item)}
          sx={{
            color: "var(--text-main)",
            "& svg": { stroke: "var(--text-main)" },
          }}
          onChange={() => {
            handleChangeItem(item);
          }}
        />
        <ListItemText
          primary={<p>{item}</p>}
          primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
        />
        <IconButton
          aria-label="arrow"
          sx={{ "& svg": { fill: "grey" } }}
          onClick={() => handleToggleCollapse(item)}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
    </ListItem>
  );
};

export default Listitem;
