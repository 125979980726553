import { useContext } from "react";
import { ParameterizationContext } from "../context/ParameterizationProvider";

export const useParameterization = () => {
    const context = useContext(ParameterizationContext);
    if (context === undefined) {
      throw new Error('useParameterization must be used within a ParameterizationProvider');
    }
    return context;
  };

