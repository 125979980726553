import { Avatar, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import Dialog from '../../dialog/Dialog';
import CustomButton from '../../buttons/Button';
import useLanguage from '../../../context/LanguageProvider';
import { truncateString } from '../../parameterization/utils';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from "@mui/icons-material/Delete";
import { delete_icon_styles } from '../../../styles/app-styles';

interface SelectedPathsDialogProps{
    open: boolean; 
    handleClose: () => void, 
    deletePath: (path : string) => void;
    selectedFilesPaths: string[],
    title: string
}

const SelectedPathsDialog = ({ open, handleClose, deletePath, selectedFilesPaths, title }: SelectedPathsDialogProps) => {

    const { t } = useLanguage()

    useEffect(() => {
        open && selectedFilesPaths.length < 1 && handleClose()
    }, [selectedFilesPaths, open])

    return (
        <Dialog maxWidth={"xl"} open={open} handleClose={handleClose} title={title} sx={{
            maxHeight: "800px"
        }}>
            <Stack sx={{
                width: "600px",
                height: "400px"
            }}>
                <Stack sx={{
                    alignItems: "center",
                    padding: "10px 30px 10px 30px",
                    width: "600px",
                    height: "100%",
                    overflowY: "auto",

                }}>
                    {selectedFilesPaths.map((path) => {
                        const label = path.split('/').pop(); // Obtener la parte después del último "/"
                        return (
                            <ListItem key={path} dense
                                sx={{
                                    border: "1px solid var(--bg-light)", // Borde de color
                                    borderRadius: "8px", // Bordes redondeados
                                    marginBottom: "8px", // Espacio inferior entre elementos
                                    padding: "8px" // Padding interno
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: "var(--bg-main) !important"
                                        }}
                                    >
                                        <InsertDriveFileIcon sx={{
                                            color: "white"
                                        }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <Tooltip placement="bottom" title={label && label.length > 40 ? label : ""}>
                                    <ListItemText primary={truncateString(label, 40)}
                                        sx={{
                                            color: "var(--text-main)",
                                            fontWeight: "1000"
                                        }}
                                    />
                                </Tooltip>
                                <ListItemIcon>
                                <Tooltip placement="bottom" title={t("general.delete")}>
                                    <DeleteIcon sx={delete_icon_styles} onClick={() => deletePath(path)} />
                                    </Tooltip>
                                </ListItemIcon>
                            </ListItem>
                        )
                    })}
                </Stack>
            </Stack>
            <Stack sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
            }}>
                <CustomButton
                    title={t("general.close")}
                    color="blue-greeny"
                    type="button"
                    onClick={handleClose}
                />
            </Stack>
        </Dialog >
    )
}

export default SelectedPathsDialog