import { COMPANY_URL, PERIODS_URL } from "../../api/axios";
import { Stack } from "@mui/material";
import { useApiQuery } from "../../hooks/useApiQuery";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import Dialog from "../dialog/Dialog";
import PeriodsDialogContent from "./PeriodsDialogContent";
import useLanguage from "../../context/LanguageProvider";

export type Period = {
  id: number;
  fecha: string;
  prorrateado: boolean;
  preprorrateado: boolean;
  exportable: boolean;
  posee_gastos: boolean;
  habilitado: boolean;
  empresa: number;
};

export type Periods = Period[];

export type Company = {
  id: number;
  nombre: string;
  ver_exportados: boolean;
};

interface IPeriodsFilter {
  openPeriodsDialog: boolean;
  handleTogglePeriodsDialog: () => void;
}

const PeriodsFilter = ({
  openPeriodsDialog,
  handleTogglePeriodsDialog,
}: IPeriodsFilter) => {
  const { t } = useLanguage();
  const { data: totalPeriodsData, isLoading: isLoadingTotalPeriodsData } =
    useApiQuery(PERIODS_URL);
  const { data: dataGetEnabledPeriods } =
    useApiQuery(`${PERIODS_URL}?habilitados=true&gastos=true`);
  const { data: dataGetCompany } = useApiQuery(COMPANY_URL)

  return (
    <Dialog
      title={t("periods.periodsFilter.periods")}
      open={openPeriodsDialog}
      handleClose={handleTogglePeriodsDialog}
      maxWidth="lg"
    >
      {isLoadingTotalPeriodsData ? (
        <Stack
          justifyContent="space-between"
          sx={{ minWidth: "30vw", margin: "5px 12px", height: "100%" }}
        >
          <CustomSkeleton height={5} />
        </Stack>
      ) : (
        dataGetEnabledPeriods &&
        totalPeriodsData && (
          <PeriodsDialogContent
            totalPeriodsData={totalPeriodsData as Periods}
            handleTogglePeriodsDialog={handleTogglePeriodsDialog}
            dataGetEnabledPeriods={dataGetEnabledPeriods}
            dataGetCompany={dataGetCompany}
          />
        )
      )}
    </Dialog>
  );
};

export default PeriodsFilter;
