import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { renderTaskCell } from "./utils";
import Stack from "@mui/material/Stack";

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformDataTaskManager = (
  t: any,
  data: any,
  tabValue: string,
  buttonsActions: any
) => {

  const columnsPreProrrateo = [t('columns.period'), t('columns.state'), t('columns.action')];
  const columnsProrrateo = [
    t('columns.date'),
    t('columns.bucket'),
    t('columns.stage'),
    t('columns.iteration'),
    t('columns.period'),
    t('columns.state'),
    t('columns.action'),
  ];
  const columnsDescargas = [
    t('columns.file'),
    t('columns.period'),
    t('columns.state'),
    t('columns.action'),
  ]
  const columnsDatamodel = [
    t('columns.date'),
    t('columns.state'),
    t('columns.action'),
  ]
  const columnsDataExport = [
    t('columns.date'),
    t('columns.file'),
    t('columns.action'),
  ]
  const columnsSalesForecast = [
    t('columns.date'),
    t('columns.analysis'),
    t('columns.state'),
    t('columns.action'),
  ]
  const columnsSimulation = [
    t('columns.date'),
    t('columns.referenceName'),
    t('columns.state'),
    t('columns.action'),
  ]
  const columnsMarketBasket = [
    t('columns.date'),
    t('columns.referenceName'),
    t('columns.lastPeriod'),
    t('columns.state'),
    t('columns.action'),
  ]
  
  const tasksColumns: any[] =
    tabValue === t('tabs.preproration')
      ? columnsPreProrrateo
      : tabValue === t('tabs.proration')
        ? columnsProrrateo
        : tabValue === t('tabs.downloads')
          ? columnsDescargas
          : tabValue === "ANALISIS_DATAEXPORT" || tabValue === "ARCHIVOS"
            ? columnsDataExport
            : tabValue === t("tabs.salesForecast") ?
              columnsSalesForecast
              : tabValue === t('tabs.simulation')
                ? columnsSimulation
                : tabValue === t('tabs.marketBasket')
                  ? columnsMarketBasket
                  : columnsDatamodel;

  const arrayColumns = tasksColumns?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna === t('columns.file') ? 2 :
        columna === t('columns.period') ||
          columna === t('columns.lastPeriod') ||
          columna === t('columns.state') ||
          columna === t('columns.action') || columna === t('columns.file')
          ? 1.5
          : columna === t('columns.stage') || columna === t('columns.iteration')
            ? 0.5
            : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        {renderTaskCell(t, tabValue, columna, params, buttonsActions)}
      </Stack>
    ),
  }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
        const obj: any = {};
        obj["id"] = fila.id;
        for (const key in fila) {
          if (key !== "id") {
            obj[key] = fila[key];
          }
        }
        return obj;
      })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
