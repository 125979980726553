import { Box, Stack, TextField } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import MergeModal from "./MergeModal";
import NewBaseFile from "./NewBaseFile";
import { ChangeEvent, useContext } from "react";
import GroupersProvider from "../../../../context/GroupersProvider";
import AddBaseButton from "./AddBaseButton";
import { handleRepeatedName, isGrouperNameValid } from "../../utils";
import useLanguage from "../../../../context/LanguageProvider";

interface Step1Props {
  configMode: "CREATE" | "EDIT" | "COPY";
}
const Step1 = ({ configMode }: Step1Props) => {
  const { t } = useLanguage();
  const [openMergeDialog, toggleMergeDialog] = useDialog();
  const { groupersState, groupersDispatch } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const { bases, grouperName, grouperList } = groupersState;

  const handleChangeName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    groupersDispatch({ type: "SET_GROUPER_NAME", payload: event.target.value });
  };

  const handleAddBase = () => {
    toggleMergeDialog();
  };

  return (
    <Stack sx={{ width: "90%" }}>
      <Box component="form" noValidate autoComplete="off" mb="15px" mt="15px">
        <TextField
          type="text"
          label={t("dataModelText.groupManagement.step1.step1.grouperName")}
          variant="outlined"
          fullWidth
          value={grouperName}
          helperText={
            isGrouperNameValid(grouperName) || grouperName === ""
              ? handleRepeatedName(grouperName, grouperList, configMode)
                ? t(
                    "dataModelText.groupManagement.step1.step1.grouperNameAlreadyExists"
                  )
                : ""
              : t("dataModelText.groupManagement.step1.step1.nameRules")
          }
          error={
            !isGrouperNameValid(grouperName) ||
            grouperName === "" ||
            handleRepeatedName(grouperName, grouperList, configMode)
          }
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
            },
          }}
          onChange={handleChangeName}
        />
      </Box>
      <NewBaseFile base={bases.length > 0 ? bases[0] : undefined} />
      {bases && bases.length > 1 && (
        <Stack sx={{ height: "100%", width: "100%" }}>
          {bases.slice(1).map((base: any) => {
            return <NewBaseFile key={base.id} base={base} openMerge={true} />;
          })}
        </Stack>
      )}
      <AddBaseButton handleAddBase={handleAddBase} />
      <MergeModal open={openMergeDialog} handleClose={toggleMergeDialog} />
    </Stack>
  );
};

export default Step1;
