import Stack from "@mui/material/Stack";

interface CheckboxOptionProps {
  toggleCheckbox: (value: any) => void;
  checked: boolean;
  labelText: string;
  id: string;
  minWidth?: string;
  labelStyles?: any;
  marginBottom?: string;
  marginTop?: string;
}

const CheckboxOption = ({
  toggleCheckbox,
  checked,
  labelText,
  id,
  minWidth,
  labelStyles,
  marginBottom,
  marginTop,
}: CheckboxOptionProps) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: marginBottom ? marginBottom : "10px",
        marginTop: marginTop ? marginTop : "10px",
      }}
    >
      <input
        type="checkbox"
        id={id}
        onChange={toggleCheckbox}
        checked={checked}
      />
      <label
        style={
          labelStyles || {
            color: "var(--text-main)",
            fontSize: "14px",
            minWidth: minWidth ? minWidth : "auto",
            marginLeft: "8px",
          }
        }
        htmlFor={id}
      >
        {labelText}
      </label>
    </Stack>
  );
};

export default CheckboxOption;
