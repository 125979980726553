import { CustomizedTable } from "../../../customizedTable";
import {
  bold_styles,
  info_section_container_styles,
  view_dummy_container_styles,
  view_dummy_table_title,
  view_dummy_value_styles,
} from "../../../../styles/app-styles";
import Dialog from "../../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
interface ViewDummyProps {
  dummyData: any;
  open: boolean;
  handleClose: () => void;
}

const ViewDummyModal = ({ dummyData, open, handleClose }: ViewDummyProps) => {
  const { t } = useLanguage();
  const COLUMNS1 = [
    t("modelParameterizationTexts.viewDummyModal.expensesColumn"),
    t("modelParameterizationTexts.viewDummyModal.fileSalesColumn"),
  ];
  const ROWS1 = dummyData?.columnas_valida?.map((column) => {
    return {
      [t("modelParameterizationTexts.viewDummyModal.expensesColumn")]:
        column.columna_gasto,
      [t("modelParameterizationTexts.viewDummyModal.fileSalesColumn")]:
        column.columna_venta,
    };
  });

  const ROWS2 = dummyData?.destinos_anteriores?.map((column) => {
    return {
      [t("modelParameterizationTexts.viewDummyModal.expensesColumn")]: `${
        column.receptor
      } (${t("modelParameterizationTexts.stages.stage")} ${column.etapa})`,
      [t("modelParameterizationTexts.viewDummyModal.fileSalesColumn")]:
        column.columna_venta,
    };
  });

  const rows = ROWS1?.concat(ROWS2);

  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t(
          "modelParameterizationTexts.viewDummyModal.dummyDirectTransfer"
        )}
      >
        <Stack sx={view_dummy_container_styles}>
          <Stack sx={info_section_container_styles}>
            <Typography
              variant="subtitle1"
              sx={view_dummy_table_title}
            >
              {t("modelParameterizationTexts.viewDummyModal.criteriaInfo")}
            </Typography>

            <p style={view_dummy_value_styles}>
              <span style={bold_styles}>
                {t(
                  "modelParameterizationTexts.viewDummyModal.finalRecieverExpense"
                )}
                :
              </span>
              {dummyData?.receptor_final}
            </p>
            <CustomizedTable COLUMNS={COLUMNS1} ROWS={rows} width={450} />
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewDummyModal;
