import {
  Stack,
  Typography,
} from "@mui/material";
import { Button } from "../buttons";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../context/LanguageProvider";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const Error = () => {
  const navigate = useNavigate();
  const { t } = useLanguage();
  return (
    
    <Stack sx={{ width: "100%", height: "90vh", marginTop: "10px", alignItems: "center", justifyContent: "center" }}>
     <WarningAmberIcon sx={{color:"var(--icon-info)", fontSize:"140px"}}/>
     <Typography sx={{fontSize:"25px", fontWeight:"600", color:"var(--text-info)", marginTop:"30px"}}>
      {t("errorTexts.pageNotExist")}
     </Typography>
     <Button title={t("errorTexts.backToHome")} color="blue" type="button" onClick={() => navigate("/inicio")} />
    </Stack>
  );
};

export default Error;