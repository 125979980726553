import { arrow_back_icon_styles } from "../../../styles/app-styles";
import { ChartType } from "../types";
import { columnOptions } from "../../configCriteria/selectOptions";
import { Filter, IdOption, Message } from "../../configCriteria/types";
import { optionalSelectStyles } from "../../configCriteria/select.styles";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../buttons/Button";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import CustomStepper from "../../Stepper/CustomStepper";
import data from "./data.json";
import DrilldownColumnSelect from "../DrilldownColumnSelect";
import Select from "../../forms/Select";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Step0Charts from "../shared/Step0Charts";
import Step1Charts from "../shared/Step1Charts";
import Step1Waterfall from "./Step1Waterfall";
import Step2 from "../../configCriteria/Step2.component";
import Tooltip from "@mui/material/Tooltip";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  prepareFilters,
  handleRepeatedName,
  handleAxisYName,
  handleAxisXName,
  completedInfo,
  chartListAnalysis,
  completedWaterfallInfo,
  validateWaterfallRow,
  formatRow,
} from "../utils";
import {
  ELEMENTS_URL,
  GET_CRITERIA_FILE_COLUMN_URL,
  GRAFICOS_ARCHIVOS,
  GRAFICO_ARCHIVO,
  GRUPOS_GRAFICOS,
} from "../../../api/axios";
import {
  dataGetChartFunctions,
  dataGetChartFunctionsWithDivision,
  waterfallFunctionOptions,
} from "../data";

const ConfigChartsAnalysis = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const tabValueEndpoint = localStorage.getItem("tabValueEndpoint")
    ? localStorage.getItem("tabValueEndpoint")
    : undefined;
  const { data: dataGetFileColumn } = useApiQuery(
    `${GET_CRITERIA_FILE_COLUMN_URL}?tipo=${tabValueEndpoint}`,
    false,
    t("report.getAnalysis.error")
  );
  const { data: dataGetGroups, refetch: refetchGetGroups } = useApiQuery(
    GRUPOS_GRAFICOS,
    false,
    t("chartsManagement.getGroup.error")
  );

  const configMode = localStorage.getItem("configMode");
  const chart = JSON.parse(localStorage.getItem("cardInfo") as string);
  const newElementConfig = localStorage.getItem("newElementConfig");
  const salesForecastView = localStorage.getItem("salesForecastView")
    ? localStorage.getItem("salesForecastView")
    : undefined;
  const simulationView = localStorage.getItem("simulationView")
    ? localStorage.getItem("simulationView")
    : undefined;

  const { data: charts } = useApiQuery(
    `${GRAFICOS_ARCHIVOS}?tipo=${tabValueEndpoint}`,
    false,
    t("report.getCharts.error")
  );

  const onSuccessPostElement = () => {
    if (newElementConfig && JSON.parse(newElementConfig).url) {
      navigate(JSON.parse(newElementConfig).url);
    }
  };

  const { isLoading: isLoadingPostNewElement, callApi: postNewElement } =
    useApi(
      "",
      "POST",
      t("dashboard.newElement.codes"),
      undefined,
      onSuccessPostElement
    );

  const onSuccessPost = (data: any) => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    localStorage.removeItem("previousUrl");
    if (newElementConfig && JSON.parse(newElementConfig).url && data) {
      const element = JSON.parse(newElementConfig);
      const newElement = {
        tipo: element.tipo.value,
        alto: element.alto,
        ancho: element.ancho,
        pk_elemento: data.id,
      };
      if (tabValueEndpoint && (salesForecastView || simulationView)) {
        postNewElement(
          `simulador/vistas/${salesForecastView || simulationView}/elementos`,
          newElement
        );
      } else {
        postNewElement(ELEMENTS_URL(element.viewTabValue), newElement);
      }
    } else {
      navigate(t("/administracion-dashboard"), {
        state: { tab: "analisis/graficos" },
        replace: false,
      });
    }
  };

  const { isLoading: isLoadingPostChart, callApi: postChart } = useApi(
    `${GRAFICOS_ARCHIVOS}?tipo=${tabValueEndpoint}`,
    "POST",
    t("chartsManagement.addChart.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPutChart, callApi: putChart } = useApi(
    GRAFICO_ARCHIVO,
    "PUT",
    t("chartsManagement.editChart.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const [message, setMessage] = useState<Message>();

  //Step 1
  const columnAxisYSelectRef: any = useRef(null);
  const columnAxisXSelectRef: any = useRef(null);
  const columnBaseSelectRef: any = useRef(null);
  const columnSeriesSelectRef: any = useRef(null);
  const columnValuesScatterSelectRef: any = useRef(null);
  const chartNameRef: any = useRef(null);
  const waterfallColumnNameRef: any = useRef(null);
  const groupRef: any = useRef(null);
  const axisYNameRef: any = useRef(null);
  const axisXNameRef: any = useRef(null);
  const fileSelectRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);
  const [chartFormComplete, setChartFormComplete] = useState(false);
  const [chartName, setChartName] = useState(
    configMode === "EDIT" ? chart?.nombre : ""
  );
  const [waterfallColumnName, setWaterfallColumnName] = useState("");

  const [axisXName, setAxisXName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_x : ""
  );
  const [activeStep, setActiveStep] = useState(0);
  const [waterfallRows, setWaterfallRows] = useState<any[]>(
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columnas?.map((column: any) => {
          return {
            column: {
              value: column?.columna?.id,
              label: column?.columna?.nombre,
            },
            columnName: column?.nombre,
            function: {
              value: column?.funcion,
              label: waterfallFunctionOptions(t)?.find(
                (option: any) => option.value === column.funcion
              )?.label,
            },
          };
        })
      : []
  );

  const [divisionRows, setDivisionRows] = useState<any>(
    (configMode === "EDIT" || configMode === "COPY") &&
      chart.funcion === "DIVISION"
      ? chart.columna_eje_y
      : ([] as any[])
  );

  const selectRefs = useRef<Array<any>>([]);

  const onFileColumn = () => {
    setColumnAxisYSelectedOptions(undefined);
    setColumnAxisXSelectedOptions(undefined);
    setColumnSeriesSelectedOptions(undefined);
    columnAxisYSelectRef?.current?.clearValue();
    columnAxisXSelectRef?.current?.clearValue();
    columnSeriesSelectRef?.current?.clearValue();
    columnValuesScatterSelectRef?.current?.clearValue();
    selectRefs.current.map((ref) => ref?.clearValue());
    setColumnsDrilldownQuantity(1);
    setSelectedOptions([]);
    setDivisionRows([]);
  };

  const [
    fileSelectedOptions,
    setFileSelectedOptions,
    fileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileColumn(),
    newElementConfig
      ? JSON.parse(newElementConfig).archivo
      : configMode === "EDIT" || configMode === "COPY"
      ? chart?.archivo
      : undefined
  );

  const [openChartModal, setOpenChartModal] = useState(false);
  const [chartType, setChartType] = useState<ChartType | undefined>();

  const [groupSelectedOptions, setGroupSelectedOptions, groupChangeHandler] =
    useSingleSelect(
      undefined,
      configMode === "EDIT" || configMode === "COPY"
        ? {
            value: chart?.grupo.value,
            label: chart?.grupo.label,
          }
        : undefined
    );

  const [
    columnAxisYSelectedOptions,
    setColumnAxisYSelectedOptions,
    columnAxisYOptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.funcion !== "DIVISON"
        ? chart?.columna_eje_y?.map((column: any) => column?.columna)
        : undefined
      : undefined,
    undefined
  );

  const [
    columnBaseSelectedOptions,
    setColumnBaseSelectedOptions,
    columnBaseOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_base
      : undefined
  );
  const [
    columnAxisXSelectedOptions,
    setColumnAxisXSelectedOptions,
    columnAxisXOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_eje_x
      : undefined
  );

  const [
    columnSeriesSelectedOptions,
    setColumnSeriesSelectedOptions,
    columnSeriesOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_series
      : undefined
  );
  const [
    columnValuesScatterSelectedOption,
    setColumnValuesScatterSelectedOption,
    columnValuesScatterChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_valores
      : undefined
  );
  const [
    functionSelectedOptions,
    setFunctionSelectedOptions,
    functionOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    (configMode === "EDIT" || configMode === "COPY") &&
      chart?.tipo !== "WATERFALL"
      ? { value: chart?.funcion, label: chart?.funcion }
      : undefined
  );

  const [columnsDrilldownQuantity, setColumnsDrilldownQuantity] = useState(
    (configMode === "EDIT" || configMode === "COPY") &&
      chart?.columnas_drilldown?.length > 0
      ? chart?.columnas_drilldown?.length
      : 1
  );
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleOptionChange = (index: number, newOption: any) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = newOption;
    setSelectedOptions(newSelectedOptions);
  };

  const columnSelects: any[] = [];

  for (let i = 0; i < columnsDrilldownQuantity; i++) {
    const columnSelect = (
      <DrilldownColumnSelect
        initialOption={
          configMode === "EDIT" || configMode === "COPY"
            ? chart?.columnas_drilldown && chart?.columnas_drilldown[i]
            : undefined
        }
        key={i}
      >
        {({
          selectedOption,
          setSelectedOption,
          columnMultiDrilldownSelectRef,
        }) => {
          useEffect(() => {
            selectRefs.current[i] = columnMultiDrilldownSelectRef.current;
          }, [columnMultiDrilldownSelectRef, i]);

          return (
            <Select
              reference={columnMultiDrilldownSelectRef}
              styles={optionalSelectStyles(selectedOption)}
              options={columnOptions(dataGetFileColumn, fileSelectedOptions)}
              className="cc_select"
              name="Columna"
              onChange={(newOption) => handleOptionChange(i, newOption)}
              closeMenuOnSelect
              placeholder={t("indicatorsManagementTexts.configCharts.none")}
              defaultValue={selectedOptions[i]}
            />
          );
        }}
      </DrilldownColumnSelect>
    );
    columnSelects.push(columnSelect);
  }

  const [axisYName, setAxisYName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_y : ""
  );
  const [settedYName, setSettedYName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_y : ""
  );
  const [settedXName, setSettedXName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_y : ""
  );

  useEffect(() => {
    handleAxisYName(
      chartType,
      axisYName,
      settedYName,
      columnAxisYSelectedOptions,
      functionSelectedOptions,
      setAxisYName,
      setSettedYName,
      axisYNameRef
    );
  }, [columnAxisYSelectedOptions, functionSelectedOptions]);

  useEffect(() => {
    handleAxisXName(
      axisXName,
      settedXName,
      columnAxisXSelectedOptions,
      setAxisXName,
      setSettedXName,
      axisXNameRef
    );
  }, [columnAxisXSelectedOptions]);

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const [activeIndicator, setActiveIndicator] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.activo : true
  );

  const [auxiliaryLines, setAuxiliaryLines] = useState(
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.lineas_auxiliares
      : {
          linea_cero: false,
          linea_promedio_x: false,
          linea_promedio_y: false,
        }
  );
  const [auxiliaryLinesColor, setAuxiliaryLinesColor] = useState(
    configMode === "EDIT" || configMode === "COPY"
      ? {
          linea_cero: chart?.lineas_auxiliares?.linea_cero || "#000000",
          linea_promedio_x:
            chart?.lineas_auxiliares?.linea_promedio_x || "#000000",
          linea_promedio_y:
            chart?.lineas_auxiliares?.linea_promedio_y || "#000000",
        }
      : {
          linea_cero: "#000000",
          linea_promedio_x: "#000000",
          linea_promedio_y: "#000000",
        }
  );

  /* Step 2 */
  const [
    conditionSelectedOptions,
    setConditionSelectedOptions,
    conditionOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterSelectedOptions,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [
    filterTypeSelectedOptions,
    setFilterTypeSelectedOptions,
    filterTypeOptionsChangeHandler,
  ] = useSingleSelect();
  const [filters, setFilters] = useState<Filter[]>();

  useEffect(() => {
    if (configMode === "EDIT" || configMode === "COPY") {
      setSelectedOptions(chart.columnas_drilldown);
      setFilters(prepareFilters(false, chart));
    } else {
      handleRestart();
    }
  }, []);

  //useEffect que está a la escucha de chartName, y si ya existe ese nombre en la lista de gráficos, entonces muestra un mensaje de error
  useEffect(() => {
    setMessage(undefined);
    handleRepeatedName(
      chartName,
      charts,
      configMode,
      setMessage,
      chart,
      chartType,
      selectedOptions,
      columnBaseSelectedOptions,
      columnAxisXSelectedOptions,
      columnAxisYSelectedOptions,
      columnSeriesSelectedOptions
    );
  }, [
    chartName,
    columnBaseSelectedOptions,
    selectedOptions,
    columnAxisYSelectedOptions,
    columnAxisXSelectedOptions,
    columnSeriesSelectedOptions,
  ]);

  const handleRestart = () => {
    if (!newElementConfig || newElementConfig === null) {
      setFileSelectedOptions(undefined);
    }
    setChartName("");
    setAxisYName("");
    setAxisXName("");
    setChartType(undefined);
    setGroupSelectedOptions(undefined);
    setColumnAxisYSelectedOptions(undefined);
    setColumnAxisXSelectedOptions(undefined);
    setColumnBaseSelectedOptions(undefined);
    setColumnSeriesSelectedOptions(undefined);
    setColumnValuesScatterSelectedOption(undefined);
    setFunctionSelectedOptions(undefined);
    setChartFormComplete(false);
    setActiveStep(0);
    setSelectedOptions([]);
    selectRefs.current = [];
    setColumnsDrilldownQuantity(1);
    groupRef.current?.clearValue();
    chartNameRef.value = "";
    axisYNameRef.value = "";
    axisXNameRef.value = "";
    fileSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
    columnAxisYSelectRef.current?.clearValue();
    columnAxisXSelectRef.current?.clearValue();
    columnSeriesSelectRef.current?.clearValue();
    columnValuesScatterSelectRef.current?.clearValue();
    columnBaseSelectRef.current?.clearValue();
    setAuxiliaryLines({
      linea_cero: false,
      linea_promedio_x: false,
      linea_promedio_y: false,
    });
    setAuxiliaryLinesColor({
      linea_cero: "#000000",
      linea_promedio_x: "#000000",
      linea_promedio_y: "#000000",
    });
    setWaterfallRows([]);
    setWaterfallColumnName("");
    setDivisionRows([]);
    setSettedYName("");
    setSettedXName("");

    //Step 2
    setConditionSelectedOptions(undefined);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions(undefined);
    setFilterTypeSelectedOptions(undefined);
    setFilters(undefined);
  };

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sendChart = () => {
    const infoToSend =
      chartType === "WATERFALL"
        ? completedWaterfallInfo(
            chartName,
            fileSelectedOptions,
            waterfallRows,
            activeIndicator
          )
        : completedInfo(
            chartName,
            chartType,
            fileSelectedOptions,
            groupSelectedOptions,
            columnAxisYSelectedOptions,
            divisionRows,
            functionSelectedOptions,
            axisYName,
            columnAxisXSelectedOptions,
            axisXName,
            columnBaseSelectedOptions,
            selectedOptions,
            columnSeriesSelectedOptions,
            activeIndicator,
            filters,
            undefined,
            undefined,
            auxiliaryLines,
            auxiliaryLinesColor,
            columnValuesScatterSelectedOption
          );
    if (configMode === "CREATE" || configMode === "COPY") {
      postChart(undefined, infoToSend);
    } else {
      putChart(GRAFICO_ARCHIVO(chart.id), infoToSend);
    }
    handleCloseChartModal();
  };

  const handleAddWaterfallRow = () => {
    if (
      validateWaterfallRow(
        columnBaseSelectedOptions,
        functionSelectedOptions,
        waterfallRows,
        waterfallColumnName,
        setMessage,
        t
      )
    ) {
      const newRow = formatRow(
        columnBaseSelectedOptions,
        functionSelectedOptions,
        waterfallColumnName,
      );
      const newWaterfallRows = waterfallRows;
      newWaterfallRows.push(newRow);
      setWaterfallRows(newWaterfallRows);
      setMessage(undefined);
      cleanRowsSelects();
    }
  };

  const cleanRowsSelects = () => {
    columnBaseSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
    setColumnBaseSelectedOptions(undefined);
    setFunctionSelectedOptions(undefined);
    setWaterfallColumnName("");
    waterfallColumnNameRef.current.value = "";
  };

  const handleDeleteAllRows = () => {
    setWaterfallRows([]);
    setMessage(undefined);
    cleanRowsSelects();
  };

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    if (newElementConfig && JSON.parse(newElementConfig).url) {
      localStorage.setItem("previousUrl", "/configuracion/analisis/grafico");
      navigate(JSON.parse(newElementConfig).url);
    } else {
      navigate(t("/administracion-dashboard"), {
        state: { tab: "analisis/graficos" },
        replace: false,
      });
    }
  };

  useEffect(() => {
    if (chartType === "WATERFALL") {
      if (waterfallRows.length > 0 && chartName !== "" && fileSelectedOptions) {
        setChartFormComplete(true);
      } else {
        setChartFormComplete(false);
      }
    }
  }, [
    chartName,
    waterfallRows,
    fileSelectedOptions,
    columnBaseSelectedOptions,
    functionSelectedOptions,
    waterfallColumnName,
    activeStep,
  ]);

  return (
    <ComponentLayout
      title={
        tabValueEndpoint === "ANALISIS"
          ? t(
              "indicatorsManagementTexts.indicatorsManagement.indicatorConfigAnalysis"
            )
          : tabValueEndpoint === "SIMULACION"
          ? t(
              "indicatorsManagementTexts.indicatorsManagement.graphicConfigAnalysisSimulation"
            )
          : tabValueEndpoint === "SALES_FORECAST"
          ? t(
              "indicatorsManagementTexts.indicatorsManagement.graphicConfigAnalysisSalesForecast"
            )
          : ""
      }
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <SimpleBackdrop
        open={
          isLoadingPostChart || isLoadingPutChart || isLoadingPostNewElement
        }
        message={
          configMode === "CREATE" || configMode === "COPY"
            ? t("indicatorsManagementTexts.configCharts.creatingGraphic")
            : t("indicatorsManagementTexts.configCharts.modifyingGraphic")
        }
      />
      <ConfirmationModal
        open={openChartModal}
        handleClose={handleCloseChartModal}
        handleAccept={sendChart}
        message={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configCharts.editTheGraphic")
            : t("indicatorsManagementTexts.configCharts.createTheGraphic")
        }
        title={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configCharts.editGraphic")
            : t("indicatorsManagementTexts.configCharts.createGraphic")
        }
      />
      <CustomStepper activeStep={activeStep} data={data.chartSteps} />
      {activeStep === 0 && (
        <>
          <div className="cc_step_title">{data.chartSteps.steps[0]}</div>
          <Step0Charts
            selectedChart={chart}
            chartType={chartType}
            setChartType={setChartType}
            handleRestart={handleRestart}
            chartList={chartListAnalysis}
          />
        </>
      )}
      {activeStep === 1 && chartType !== "WATERFALL" && (
        <>
          <div className="cc_step_title">{data.chartSteps.steps[1]}</div>
          {message && (
            <div className="cc_info_filters_box">
              <p className={`cc_info_filters_${message.type}`}>
                {message.text}
              </p>
            </div>
          )}
          <Step1Charts
            chartType={chartType}
            chartName={chartName}
            setChartName={setChartName}
            chartNameRef={chartNameRef}
            groupRef={groupRef}
            groupSelectedOptions={groupSelectedOptions}
            groupChangeHandler={groupChangeHandler}
            groupSelectOptions={dataGetGroups}
            refetchGetGroups={refetchGetGroups}
            axisYName={axisYName}
            setAxisYName={setAxisYName}
            axisYNameRef={axisYNameRef}
            axisXName={axisXName}
            setAxisXName={setAxisXName}
            axisXNameRef={axisXNameRef}
            setChartFormComplete={setChartFormComplete}
            fileColumnSelectOptions={dataGetFileColumn}
            functionSelectOptions={
              chartType === "LINEAS" ||
              chartType === "BARRAS CON DRILLDOWN" ||
              chartType === "SCATTERPLOT"
                ? dataGetChartFunctionsWithDivision
                : dataGetChartFunctions
            }
            fileSelectedOptions={fileSelectedOptions}
            fileOptionsChangeHandler={fileOptionsChangeHandler}
            columnAxisYSelectedOptions={columnAxisYSelectedOptions}
            columnAxisYOptionsChangeHandler={columnAxisYOptionsChangeHandler}
            columnAxisXSelectedOptions={columnAxisXSelectedOptions}
            columnAxisXOptionsChangeHandler={columnAxisXOptionsChangeHandler}
            columnBaseSelectedOptions={columnBaseSelectedOptions}
            columnBaseOptionsChangeHandler={columnBaseOptionsChangeHandler}
            columnSeriesSelectedOptions={columnSeriesSelectedOptions}
            columnSeriesOptionsChangeHandler={columnSeriesOptionsChangeHandler}
            columnValuesScatterSelectedOption={
              columnValuesScatterSelectedOption
            }
            columnValuesScatterChangeHandler={columnValuesScatterChangeHandler}
            functionSelectedOptions={functionSelectedOptions}
            functionOptionsChangeHandler={functionOptionsChangeHandler}
            columnAxisYSelectRef={columnAxisYSelectRef}
            columnAxisXSelectRef={columnAxisXSelectRef}
            columnBaseSelectRef={columnBaseSelectRef}
            columnSeriesSelectRef={columnSeriesSelectRef}
            columnValuesScatterSelectRef={columnValuesScatterSelectRef}
            fileSelectRef={fileSelectRef}
            functionSelectRef={functionSelectRef}
            activeIndicator={activeIndicator}
            setActiveIndicator={setActiveIndicator}
            columnsDrilldownQuantity={columnsDrilldownQuantity}
            setColumnsDrilldownQuantity={setColumnsDrilldownQuantity}
            columnSelects={columnSelects}
            setSelectedOptions={setSelectedOptions}
            selectRefs={selectRefs}
            selectedOptions={selectedOptions}
            message={message}
            fileType={"ANALISIS"}
            auxiliaryLines={auxiliaryLines}
            setAuxiliaryLines={setAuxiliaryLines}
            auxiliaryLinesColor={auxiliaryLinesColor}
            setAuxiliaryLinesColor={setAuxiliaryLinesColor}
            divisionRows={divisionRows}
            setDivisionRows={setDivisionRows}
          />
        </>
      )}
      {activeStep === 1 && chartType === "WATERFALL" && (
        <Step1Waterfall
          chartName={chartName}
          setChartName={setChartName}
          chartNameRef={chartNameRef}
          setChartFormComplete={setChartFormComplete}
          dataGetFileColumn={dataGetFileColumn}
          functionSelectOptions={dataGetChartFunctions}
          fileSelectedOptions={fileSelectedOptions}
          fileOptionsChangeHandler={fileOptionsChangeHandler}
          columnBaseSelectedOptions={columnBaseSelectedOptions}
          columnBaseOptionsChangeHandler={columnBaseOptionsChangeHandler}
          functionSelectedOptions={functionSelectedOptions}
          functionOptionsChangeHandler={functionOptionsChangeHandler}
          columnBaseSelectRef={columnBaseSelectRef}
          fileSelectRef={fileSelectRef}
          functionSelectRef={functionSelectRef}
          activeIndicator={activeIndicator}
          setActiveIndicator={setActiveIndicator}
          waterfallColumnNameRef={waterfallColumnNameRef}
          waterfallColumnName={waterfallColumnName}
          setWaterfallColumnName={setWaterfallColumnName}
          handleAddWaterfallRow={handleAddWaterfallRow}
          waterfallRows={waterfallRows}
          message={message}
          handleDeleteAllRows={handleDeleteAllRows}
          setWaterfallRows={setWaterfallRows}
          setFunctionSelectedOptions={setFunctionSelectedOptions}
          setColumnBaseSelectedOptions={setColumnBaseSelectedOptions}
        />
      )}
      {activeStep === 2 && (
        <>
          <div className="cc_step_title">{`${data.chartSteps.steps[2]}: ${fileSelectedOptions?.label}`}</div>
          <Step2
            fileColumnSelectOptions={dataGetFileColumn}
            fileSelectedOptions={fileSelectedOptions}
            columnFilterSelectedOptions={columnFilterSelectedOptions}
            setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
            columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler}
            conditionSelectedOptions={conditionSelectedOptions}
            setConditionSelectedOptions={setConditionSelectedOptions}
            conditionOptionsChangeHandler={conditionOptionsChangeHandler}
            valuesSelectedOptions={valuesSelectedOptions}
            setValuesSelectedOptions={setValuesSelectedOptions}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            filterTypeSelectedOptions={filterTypeSelectedOptions}
            setFilterTypeSelectedOptions={setFilterTypeSelectedOptions}
            filterTypeOptionsChangeHandler={filterTypeOptionsChangeHandler}
            filters={filters}
            setFilters={setFilters}
            external={false}
            width="90%"
            externalFileSelectedOptions={undefined}
            isAnalysis={true}
          />
        </>
      )}
      <div className="cc_steps_buttons">
        <Button
          title="Anterior"
          color="blue-greeny"
          type="button"
          onClick={handleBack}
          disabled={activeStep === 0}
        />
        <Tooltip
          title={t("indicatorsManagementTexts.configCharts.resetAllGraphSteps")}
          arrow
        >
          <span>
            <Button
              title={t("indicatorsManagementTexts.configCharts.cleanAll")}
              color="grey"
              type="button"
              onClick={handleRestart}
            />
          </span>
        </Tooltip>
        <Button
          title={t("indicatorsManagementTexts.configCharts.finish")}
          color="blue"
          type="button"
          onClick={() => setOpenChartModal(true)}
          disabled={!chartFormComplete}
        />
        <Button
          title={t("indicatorsManagementTexts.configCharts.next")}
          color="blue-greeny"
          type="button"
          onClick={handleNext}
          disabled={
            activeStep === 2 ||
            !chartType ||
            (activeStep === 1 && !chartFormComplete)
          }
        />
      </div>
    </ComponentLayout>
  );
};

export default ConfigChartsAnalysis;
