export const updateAuthAndCompany = (newCompany, setAuth, setCompanyContext) => {
    if (typeof newCompany !== "string" && newCompany?.value) {
      setAuth((prev) => ({
        ...prev,
        empresa: newCompany.value,
      }));
      setCompanyContext(newCompany);
    }
  };

  export const handleMenuAndNavigation = (menu, auth, handleCloseBasicModal, navigate) => {
    if (menu) {
      handleCloseBasicModal();
      location.reload();    
    } else {
      navigate("/inicio");
    }
  };