import "./styles.css";
import { Button } from "../buttons";
import { ExternalFilter, Filter, IdOption, Message, Union } from "./types";
import { Stack, Typography } from "@mui/material";
import { TabValue } from "../colorTabs/types";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationModal from "../dialog/ConfirmationModal";
import CustomStepper from "../Stepper/CustomStepper";
import Dialog from "../dialog/Dialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Step1 from "./Step1.component";
import Step2 from "./Step2.component";
import Step3 from "./Step3.component";
import Step4 from "./Step4.component";
import Tooltip from "@mui/material/Tooltip";
import useDialog from "../../hooks/useDialog";
import useMultiSelect from "../../hooks/useMultiSelect";
import useSingleSelect from "../../hooks/useSingleSelect";
import {
  GET_BUCKETS_URL,
  GET_CRITERIA_FILE_COLUMN_URL,
  GET_CRITERIA_FUNCTIONS_URL,
  GET_CRITERIA_TARGET_URL,
  CRITERIA_URL,
} from "../../api/axios";
import useApi from "../../hooks/useApi";
import ComponentLayout from "../layout/ComponentLayout";
import useLanguage from "../../context/LanguageProvider";
interface ConfigCriteriaProps {
  criteriaType: TabValue;
  handleFinish: (() => void) | ((status: number | string | undefined) => void);
  configMode: "CREATE" | "EDIT" | "COPY";
  criteria: any;
  criteriaList: any;
  parameterization?: boolean;
}

const ConfigCriteria = ({
  criteriaType,
  handleFinish,
  configMode,
  criteria,
  criteriaList,
  parameterization,
}: ConfigCriteriaProps) => {
  const { t } = useLanguage();

  const { data: dataGetCriteriaBucket, refetch: refetchCriteriaBucket } =
    useApiQuery(
      GET_BUCKETS_URL,
      false,
      t("configCriteria.getCriteriaBucket.error")
    );
  const { data: dataGetCriteriaTarget, refetch: refetchCriteriaTarget } =
    useApiQuery(
      GET_CRITERIA_TARGET_URL,
      false,
      t("configCriteria.getCriteriaTarget.error")
    );
  const { data: dataGetFileColumn, refetch: refetchFileColumn } = useApiQuery(
    GET_CRITERIA_FILE_COLUMN_URL,
    false,
    t("configCriteria.getCriteriaFileColumn.error")
  );
  const { data: dataGetCriteriaFunctions, refetch: refetchCriteriaFunctions } =
    useApiQuery(
      GET_CRITERIA_FUNCTIONS_URL,
      false,
      t("configCriteria.getCriteriaFunctions.error")
    );

  const { data: dataGetExpensesColumn } = useApiQuery(
    `${GET_CRITERIA_FILE_COLUMN_URL}?archivo=gastos`,
    false,
    t("configCriteria.getExpensesColumn.error")
  );

  const onFileColumn = () => {
    setColumnSelectedOptions(undefined);
    columnSelectRef.current.clearValue();
  };

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const onExternalColumnChange = () => {
    setSelectedExternalValues([]);
    setValuesExternalSelectedOptions(undefined);
  };

  const [activeStep, setActiveStep] = useState(0);

  /* Step 1 */
  const columnSelectRef: any = useRef(null);
  const criteriaNameRef: any = useRef(null);
  const bucketSelectRef: any = useRef(null);
  const expenseTargetSelectRef: any = useRef(null);
  const fileSelectRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);
  const uniqueSelectRef: any = useRef(null);
  const multiplySelectRef: any = useRef(null);
  const [step1Complete, setStep1Complete] = useState(false);
  const [criteriaName, setCriteriaName] = useState(
    configMode === "EDIT"
      ? criteria?.nombre
      : configMode === "COPY"
      ? `${criteria?.nombre}_copia`
      : ""
  );
  const [
    bucketSelectedOptions,
    setBucketSelectedOptions,
    bucketSelectedOptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.criterio_bucket
      : undefined
  );
  const [
    expenseTargetSelectedOptions,
    setExpenseTargetSelectedOptions,
    expenseTargetChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.criterio_receptor_gasto
      : undefined
  );
  const [
    fileSelectedOptions,
    setFileSelectedOptions,
    fileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileColumn(),
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.archivo
      : undefined
  );
  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.columna
      : undefined
  );
  const [
    functionSelectedOptions,
    setFunctionSelectedOptions,
    functionOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? { value: criteria?.funcion, label: criteria?.funcion }
      : undefined
  );

  const [getAbsoluteValues, setGetAbsoluteValues] = useState(
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.valores_absolutos
      : false
  );
  const [convertNegativeToZero, setConvertNegativeToZero] = useState(
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.negativos_a_cero
      : false
  );
  const [getNegativeValues, setGetNegativeValues] = useState(
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.valores_absolutos || criteria?.negativos_a_cero
        ? false
        : true
      : true
  );
  const [
    uniqueSelectedOptions,
    setUniqueSelectedOptions,
    uniqueOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.columna_criterio_unico && criteria?.columna_criterio_unico
      : undefined
  );
  const [
    multiplySelectedOptions,
    setMultiplySelectedOptions,
    multiplyOptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.criterio_columna_multiplicar
      : undefined
  );
  const [openCriteriaModal, setOpenCriteriaModal] = useState(false);

  /* Step 2 */
  const valueFilterStep2Ref: any = useRef(null);
  const [valueFilterStep2, setvalueFilterStep2] = useState(
    configMode === "EDIT" ? criteria?.nombre : ""
  ); //CHEQUEAR QUE IRIA EN EL MODO EDITAR
  const [
    conditionSelectedOptions,
    setConditionSelectedOptions,
    conditionOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterSelectedOptions,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [
    filterTypeSelectedOptions,
    setFilterTypeSelectedOptions,
    filterTypeOptionsChangeHandler,
  ] = useSingleSelect();
  const [filters, setFilters] = useState<Filter[]>();

  /* Step 3 */
  const [
    expensesColumnSelectedOptions,
    setExpensesColumnSelectedOptions,
    expensesColumnOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnValidationSelectedOptions,
    setColumnValidationSelectedOptions,
    columnValidationOptionsChangeHandler,
  ] = useSingleSelect();
  const [unions, setUnions] = useState<Union[]>();

  /* Step 4 */
  const externalFileSelectRef: any = useRef(null);
  const externalColumnSelectRef: any = useRef(null);
  const columnFileSelectRef: any = useRef(null);
  const valueFilterStep4Ref: any = useRef(null);
  const [valueFilterStep4, setvalueFilterStep4] = useState(
    configMode === "EDIT" ? criteria?.nombre : ""
  ); //chequeaaaaar que va aca
  const onFileExternalColumnChange = () => {
    setExternalFilters([]);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    externalColumnSelectRef.current.clearValue();
    columnFileSelectRef.current.clearValue();
  };

  const [
    externalFileSelectedOptions,
    setExternalFileSelectedOptions,
    externalFileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileExternalColumnChange(),
    configMode === "EDIT" || configMode === "COPY"
      ? criteria?.archivo_externo_validacion
      : undefined
  );
  const [
    externalColumnSelectedOptions,
    setExternalColumnSelectedOptions,
    externalColumnOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFileSelectedOptions,
    setColumnFileSelectedOptions,
    columnFileOptionsChangeHandler,
  ] = useSingleSelect();
  const [externalFilters, setExternalFilters] = useState<ExternalFilter[]>();
  const [
    conditionExternalSelectedOptions,
    setConditionExternalSelectedOptions,
    conditionExternalOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterExternalSelectedOptions,
    setColumnFilterExternalSelectedOptions,
    columnFilterExternalOptionsChangeHandler,
  ] = useSingleSelect(() => onExternalColumnChange());
  const [valuesExternalSelectedOptions, setValuesExternalSelectedOptions] =
    useState<IdOption[]>();
  const [selectedExternalValues, setSelectedExternalValues] = useState<
    string[] | null
  >([]);
  const [
    filterTypeExternalSelectedOptions,
    setFilterTypeExternalSelectedOptions,
    filterTypeExternalOptionsChangeHandler,
  ] = useSingleSelect();
  const [filtersExternal, setFiltersExternal] = useState<Filter[]>();
  const [validationsStep4Completed, setValidationsStep4Completed] = useState(
    configMode === "EDIT" || configMode === "COPY" ? true : false
  );
  const [message, setMessage] = useState<Message>();
  const [openNoFiltersModal, toggleNoFiltersModal] = useDialog();

  useEffect(() => {
    if (configMode === "EDIT" || configMode === "COPY") {
      const prepareFilters = (externo: boolean) =>
        criteria?.criterio_columna_filtra
          .filter((item: any) => item.externo === externo)
          .map((columnCriteria: any) => {
            return {
              id: columnCriteria.columna_id,
              condition: criteria.operacion_filtro_aplicacion,
              column: {
                value: columnCriteria.columna_id,
                label: columnCriteria.columna,
              },
              values: columnCriteria.criterio_valor_filtra.map((valor: any) => {
                return { value: valor.valor, label: valor.valor };
              }),
              filterType: columnCriteria.tipo_de_filtro,
            };
          });

      const prepareUnions = (externo: boolean) =>
        criteria?.criterio_columna_valida
          ?.filter((item: any) => item.externo === externo)
          .map((columnCriteria: any) => {
            if (externo) {
              return {
                id: `${columnCriteria.columna_archivo_base_id}-${columnCriteria.columna_gastos_o_externo_id}`,
                columnFile: {
                  value: columnCriteria.columna_archivo_base_id,
                  label: columnCriteria.columna_archivo_base,
                },
                externalColumn: {
                  value: columnCriteria.columna_gastos_o_externo_id,
                  label: columnCriteria.columna_gastos_o_externo,
                },
                externalFile: criteria.archivo_externo,
              };
            } else {
              return {
                id: `${columnCriteria.columna_archivo_base_id}-${columnCriteria.columna_gastos_o_externo_id}`,
                expensesColumn: {
                  value: columnCriteria.columna_gastos_o_externo_id,
                  label: columnCriteria.columna_gastos_o_externo,
                },
                column: {
                  value: columnCriteria.columna_archivo_base_id,
                  label: columnCriteria.columna_archivo_base,
                },
              };
            }
          });

      setFilters(prepareFilters(false));
      setFiltersExternal(prepareFilters(true));
      setUnions(prepareUnions(false));
      setExternalFilters(prepareUnions(true));
    } else {
      handleRestart();
    }
  }, []);

  //crear un useEffect que esté a la escucha de criteriaName, y si ya existe ese nombre en la lista de criterios, entonces mostrar un mensaje de error
  useEffect(() => {
    setMessage(undefined);
    if (criteriaName) {
      const criteriaNameExists = criteriaList?.find(
        (criteria: any) =>
          criteria.nombre.toLowerCase() === criteriaName.toLowerCase()
      );
      if (
        criteriaNameExists &&
        (configMode === "CREATE" || configMode === "COPY")
      ) {
        setMessage({
          type: "error",
          text: t(
            "configCriteria.configCriteriaComponent.filterMessages.duplicatedCriteriaNameError"
          ),
        });
      } else if (
        criteriaNameExists &&
        configMode === "EDIT" &&
        criteria?.nombre.toLowerCase() !== criteriaName.toLowerCase()
      ) {
        setMessage({
          type: "error",
          text: t(
            "configCriteria.configCriteriaComponent.filterMessages.duplicatedCriteriaNameError"
          ),
        });
      } else {
        setMessage(undefined);
      }
    }
  }, [criteriaName]);

  const nonAppliedFilters = () => {
    let returnValue = false;
    if (
      (activeStep === 1 &&
        columnFilterSelectedOptions &&
        valuesSelectedOptions &&
        selectedValues &&
        filterTypeSelectedOptions) ||
      (activeStep === 2 &&
        expensesColumnSelectedOptions &&
        columnValidationSelectedOptions) ||
      (activeStep === 3 &&
        ((externalColumnSelectedOptions && columnFileSelectedOptions) ||
          (columnFilterExternalSelectedOptions &&
            valuesExternalSelectedOptions &&
            selectedExternalValues &&
            filterTypeExternalSelectedOptions)))
    ) {
      returnValue = true;
    }
    return returnValue;
  };

  const handleNext = () => {
    if (nonAppliedFilters()) {
      toggleNoFiltersModal();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleContinueNoFilters = () => {
    toggleNoFiltersModal();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeBaseFileOnEdit = () => {
    //Step 2
    setConditionSelectedOptions(undefined);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions(undefined);
    setFilterTypeSelectedOptions(undefined);
    setFilters(undefined);
    //Step 3
    setExpensesColumnSelectedOptions(undefined);
    setColumnValidationSelectedOptions(undefined);
    setUnions(undefined);
    //Step4
    setExternalFileSelectedOptions(undefined);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    setExternalFilters(undefined);
    setConditionExternalSelectedOptions(undefined);
    setColumnFilterExternalSelectedOptions(undefined);
    setValuesExternalSelectedOptions(undefined);
    setFilterTypeExternalSelectedOptions(undefined);
    setFiltersExternal(undefined);
  };

  const handleRestart = () => {
    //Step 1
    setCriteriaName("");
    setBucketSelectedOptions(undefined);
    setExpenseTargetSelectedOptions(undefined);
    setFileSelectedOptions(undefined);
    setColumnSelectedOptions(undefined);
    setFunctionSelectedOptions(undefined);
    setGetAbsoluteValues(false);
    setUniqueSelectedOptions(undefined);
    setMultiplySelectedOptions(undefined);
    setActiveStep(0);
    criteriaNameRef.value = "";
    bucketSelectRef.current?.clearValue();
    expenseTargetSelectRef.current?.clearValue();
    fileSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
    uniqueSelectRef.current?.clearValue();
    multiplySelectRef.current?.clearValue();

    //Step 2
    setConditionSelectedOptions(undefined);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions(undefined);
    setFilterTypeSelectedOptions(undefined);
    setFilters(undefined);
    //Step 3
    setExpensesColumnSelectedOptions(undefined);
    setColumnValidationSelectedOptions(undefined);
    setUnions(undefined);
    //Step4
    setExternalFileSelectedOptions(undefined);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    setExternalFilters(undefined);
    setConditionExternalSelectedOptions(undefined);
    setColumnFilterExternalSelectedOptions(undefined);
    setValuesExternalSelectedOptions(undefined);
    setFilterTypeExternalSelectedOptions(undefined);
    setFiltersExternal(undefined);
  };

  const handleCloseCriteriaModal = () => {
    setOpenCriteriaModal(false);
  };

  //crear o modificar criterio

  const { isLoading: isLoadingPostCriteria, callApi: postCriteria } = useApi(
    "",
    "POST",
    t("configCriteria.sendCriteria.codes"),
    undefined,
    handleFinish
  );

  const { isLoading: isLoadingPutCriteria, callApi: putCriteria } = useApi(
    "",
    "PUT",
    t("configCriteria.putCriteria.codes"),
    undefined,
    handleFinish
  );

  const sendCriteria = () => {
    const step2_criteria = filters
      ? filters.map((uniqueFilter: Filter) => {
          return {
            columna: uniqueFilter.column.value,
            tipo_de_filtro: uniqueFilter.filterType,
            externo: false,
            criterio_valor_filtra: uniqueFilter.values
              .map((value) => {
                if (value.value !== "") {
                  return { valor: value.value };
                } else {
                  return null;
                }
              })
              .filter((value) => value !== null),
          };
        })
      : [];
    const step4_criteria = filtersExternal
      ? filtersExternal.map((uniqueFilter: Filter) => {
          return {
            columna: uniqueFilter.column.value,
            tipo_de_filtro: uniqueFilter.filterType,
            externo: true,
            criterio_valor_filtra: uniqueFilter.values
              .map((value) => {
                if (value.value !== "") {
                  return { valor: value.value };
                } else {
                  return null;
                }
              })
              .filter((value) => value !== null),
          };
        })
      : [];
    const step3_validation = unions
      ? unions.map((union) => {
          return {
            columna_gastos_o_externo: union.expensesColumn.value,
            columna_archivo_base: union.column.value,
            externo: false,
          };
        })
      : [];
    const step4_validation = externalFilters
      ? externalFilters.map((filter) => {
          return {
            columna_gastos_o_externo: filter.externalColumn.value,
            columna_archivo_base: filter.columnFile.value,
            externo: true,
          };
        })
      : [];
    const completedInfo = {
      nombre: criteriaName,
      criterio_receptor_gasto: expenseTargetSelectedOptions
        ? expenseTargetSelectedOptions?.map((option) => {
            return { receptor_gasto: option.value };
          })
        : [],
      archivo: fileSelectedOptions?.value,
      columna: columnSelectedOptions?.value,
      funcion: functionSelectedOptions?.value,
      criterio_bucket: bucketSelectedOptions
        ? bucketSelectedOptions.map((option: IdOption) => {
            return { bucket: option.value };
          })
        : [],
      columna_criterio_unico: uniqueSelectedOptions?.value,
      valores_absolutos: getAbsoluteValues,
      negativos_a_cero: convertNegativeToZero,
      criterio_columna_multiplicar: multiplySelectedOptions
        ? multiplySelectedOptions.map((option) => {
            return { columna: option.value };
          })
        : [],
      tipo_criterio: criteriaType,
      operacion_filtro_aplicacion: filters ? filters[0]?.condition : "",
      operacion_filtro_externo: filtersExternal
        ? filtersExternal[0]?.condition
        : "",
      criterio_columna_filtra: [...step2_criteria, ...step4_criteria],
      criterio_columna_valida: [...step3_validation, ...step4_validation],
    };
    if (configMode === "CREATE" || configMode === "COPY") {
      postCriteria(CRITERIA_URL, completedInfo);
    } else {
      putCriteria(`${CRITERIA_URL}?id=${criteria.id}`, completedInfo);
    }
    handleCloseCriteriaModal();
  };

  const refetchCriteriaData = () => {
    refetchCriteriaBucket();
    refetchCriteriaTarget();
    refetchFileColumn();
    refetchCriteriaFunctions();
  };

  return (
    <ComponentLayout
      title={t("configCriteria.configCriteriaComponent.criteriaAdmin")}
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={() => handleFinish("cerrar")}
        />
      }
    >
      <section className="cc_container">
        <SimpleBackdrop
          open={isLoadingPostCriteria || isLoadingPutCriteria}
          message={
            configMode === "CREATE" || configMode === "COPY"
              ? t("configCriteria.sendCriteria.loading")
              : t("configCriteria.putCriteria.loading")
          }
        />
        <CustomStepper
          activeStep={activeStep}
          data={t("configCriteria.configCriteriaComponent")}
        />
        <ConfirmationModal
          open={openCriteriaModal}
          handleClose={handleCloseCriteriaModal}
          handleAccept={sendCriteria}
          customMessage={nonAppliedFilters() || parameterization}
          message={
            nonAppliedFilters()
              ? t("configCriteria.configCriteriaComponent.nonAppliedFilters")
              : configMode === "EDIT"
              ? t("configCriteria.configCriteriaComponent.editCriteria")
              : parameterization
              ? t("configCriteria.configCriteriaComponent.createAndAssignCriteria")
              : t("configCriteria.configCriteriaComponent.createCriteria")
          }
          title={
            configMode === "EDIT"
              ? t("configCriteria.configCriteriaComponent.editCriteriaTitle")
              : t("configCriteria.configCriteriaComponent.createCriteriaTitle")
          }          
        />

        {activeStep === 0 && (
          <>
            <div className="cc_step_title">
              {t("configCriteria.configCriteriaComponent.steps")[0]}
            </div>
            {message && (
              <div className="cc_info_filters_box">
                <p className={`cc_info_filters_${message.type}`}>
                  {message.text}
                </p>
              </div>
            )}
            <Stack
              sx={{
                height: "64vh",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                overflowY: "scroll",
              }}
            >
              <Step1
                configMode={configMode}
                handleChangeBaseFileOnEdit={handleChangeBaseFileOnEdit}
                setStep1Complete={setStep1Complete}
                bucketSelectOptions={dataGetCriteriaBucket}
                expenseTargetSelectOptions={dataGetCriteriaTarget}
                fileColumnSelectOptions={dataGetFileColumn}
                functionSelectOptions={dataGetCriteriaFunctions}
                refetchCriteriaData={refetchCriteriaData}
                fileSelectedOptions={fileSelectedOptions}
                fileOptionsChangeHandler={fileOptionsChangeHandler}
                bucketSelectedOptions={bucketSelectedOptions}
                bucketSelectedOptionsChangeHandler={
                  bucketSelectedOptionsChangeHandler
                }
                criteriaName={criteriaName}
                setCriteriaName={setCriteriaName}
                expenseTargetSelectedOptions={expenseTargetSelectedOptions}
                expenseTargetChangeHandler={expenseTargetChangeHandler}
                columnSelectedOptions={columnSelectedOptions}
                columnOptionsChangeHandler={columnOptionsChangeHandler}
                functionSelectedOptions={functionSelectedOptions}
                functionOptionsChangeHandler={functionOptionsChangeHandler}
                getAbsoluteValues={getAbsoluteValues}
                setGetAbsoluteValues={setGetAbsoluteValues}
                uniqueSelectedOptions={uniqueSelectedOptions}
                uniqueOptionsChangeHandler={uniqueOptionsChangeHandler}
                multiplySelectedOptions={multiplySelectedOptions}
                multiplyOptionsChangeHandler={multiplyOptionsChangeHandler}
                columnSelectRef={columnSelectRef}
                criteriaNameRef={criteriaNameRef}
                bucketSelectRef={bucketSelectRef}
                expenseTargetSelectRef={expenseTargetSelectRef}
                fileSelectRef={fileSelectRef}
                functionSelectRef={functionSelectRef}
                uniqueSelectRef={uniqueSelectRef}
                multiplySelectRef={multiplySelectRef}
                criteriaType={criteriaType}
                refetchCriteriaTarget={refetchCriteriaTarget}
                convertNegativeToZero={convertNegativeToZero}
                setConvertNegativeToZero={setConvertNegativeToZero}
                getNegativeValues={getNegativeValues}
                setGetNegativeValues={setGetNegativeValues}
              />
            </Stack>
          </>
        )}
        {activeStep === 1 && (
          <>
            <div className="cc_step_title">{`${
              t("configCriteria.configCriteriaComponent.steps")[1]
            }: ${fileSelectedOptions?.label}`}</div>
            <Stack
              sx={{
                height: "64vh",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start",
                overflowY: "scroll",
              }}
            >
              <Step2
                isConfigCriteria
                fileColumnSelectOptions={dataGetFileColumn}
                fileSelectedOptions={fileSelectedOptions}
                columnFilterSelectedOptions={columnFilterSelectedOptions}
                setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
                columnFilterOptionsChangeHandler={
                  columnFilterOptionsChangeHandler
                }
                valueFilter={valueFilterStep2}
                setValueFilter={setvalueFilterStep2}
                valueFilterRef={valueFilterStep2Ref}
                conditionSelectedOptions={conditionSelectedOptions}
                setConditionSelectedOptions={setConditionSelectedOptions}
                conditionOptionsChangeHandler={conditionOptionsChangeHandler}
                valuesSelectedOptions={valuesSelectedOptions}
                setValuesSelectedOptions={setValuesSelectedOptions}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                filterTypeSelectedOptions={filterTypeSelectedOptions}
                setFilterTypeSelectedOptions={setFilterTypeSelectedOptions}
                filterTypeOptionsChangeHandler={filterTypeOptionsChangeHandler}
                filters={filters}
                setFilters={setFilters}
                external={false}
                width="90%"
                externalFileSelectedOptions={externalFileSelectedOptions}
              />
            </Stack>
          </>
        )}
        {activeStep === 2 && (
          <>
            <div className="cc_step_title">{`${
              t("configCriteria.configCriteriaComponent.steps")[2]
            } ${fileSelectedOptions?.label}`}</div>
            <Stack
              sx={{
                height: "64vh",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start",
                overflowY: "scroll",
              }}
            >
              <Step3
                expensesColumnSelectOptions={dataGetExpensesColumn}
                fileColumnSelectOptions={dataGetFileColumn}
                fileSelectedOptions={fileSelectedOptions}
                columnValidationSelectedOptions={
                  columnValidationSelectedOptions
                }
                setColumnValidationSelectedOptions={
                  setColumnValidationSelectedOptions
                }
                columnValidationOptionsChangeHandler={
                  columnValidationOptionsChangeHandler
                }
                expensesColumnSelectedOptions={expensesColumnSelectedOptions}
                setExpensesColumnSelectedOptions={
                  setExpensesColumnSelectedOptions
                }
                expensesColumnOptionsChangeHandler={
                  expensesColumnOptionsChangeHandler
                }
                unions={unions}
                setUnions={setUnions}
              />
            </Stack>
          </>
        )}
        {activeStep === 3 && (
          <>
            <div className="cc_step_title">{`${
              t("configCriteria.configCriteriaComponent.steps")[3]
            }`}</div>
            <Stack
              sx={{
                height: "64vh",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start",
                overflowY: "scroll",
              }}
            >
              <div className="cc_step4_container">
                <Step4
                  fileColumnSelectOptions={dataGetFileColumn}
                  fileSelectedOptions={fileSelectedOptions}
                  externalFileSelectedOptions={externalFileSelectedOptions}
                  setExternalFileSelectedOptions={
                    setExternalFileSelectedOptions
                  }
                  externalFileOptionsChangeHandler={
                    externalFileOptionsChangeHandler
                  }
                  externalColumnSelectedOptions={externalColumnSelectedOptions}
                  setExternalColumnSelectedOptions={
                    setExternalColumnSelectedOptions
                  }
                  externalColumnOptionsChangeHandler={
                    externalColumnOptionsChangeHandler
                  }
                  columnFileSelectedOptions={columnFileSelectedOptions}
                  setColumnFileSelectedOptions={setColumnFileSelectedOptions}
                  columnFileOptionsChangeHandler={
                    columnFileOptionsChangeHandler
                  }
                  filters={externalFilters}
                  setFilters={setExternalFilters}
                  setValidationsStep4Completed={setValidationsStep4Completed}
                  externalFileSelectRef={externalFileSelectRef}
                  externalColumnSelectRef={externalColumnSelectRef}
                  columnFileSelectRef={columnFileSelectRef}
                />
                <Step2
                  isConfigCriteria
                  fileColumnSelectOptions={dataGetFileColumn}
                  fileSelectedOptions={fileSelectedOptions}
                  columnFilterSelectedOptions={
                    columnFilterExternalSelectedOptions
                  }
                  valueFilter={valueFilterStep4}
                  setValueFilter={setvalueFilterStep4}
                  valueFilterRef={valueFilterStep4Ref}
                  setColumnFilterSelectedOptions={
                    setColumnFilterExternalSelectedOptions
                  }
                  columnFilterOptionsChangeHandler={
                    columnFilterExternalOptionsChangeHandler
                  }
                  conditionSelectedOptions={conditionExternalSelectedOptions}
                  setConditionSelectedOptions={
                    setConditionExternalSelectedOptions
                  }
                  conditionOptionsChangeHandler={
                    conditionExternalOptionsChangeHandler
                  }
                  valuesSelectedOptions={valuesExternalSelectedOptions}
                  setValuesSelectedOptions={setValuesExternalSelectedOptions}
                  selectedValues={selectedExternalValues}
                  setSelectedValues={setSelectedExternalValues}
                  filterTypeSelectedOptions={filterTypeExternalSelectedOptions}
                  setFilterTypeSelectedOptions={
                    setFilterTypeExternalSelectedOptions
                  }
                  filterTypeOptionsChangeHandler={
                    filterTypeExternalOptionsChangeHandler
                  }
                  filters={filtersExternal}
                  setFilters={setFiltersExternal}
                  external={true}
                  width="60%"
                  validationsStep4Completed={validationsStep4Completed}
                  externalFileSelectedOptions={externalFileSelectedOptions}
                />
              </div>
            </Stack>
          </>
        )}

        <Dialog
          open={openNoFiltersModal}
          handleClose={toggleNoFiltersModal}
          title={t("configCriteria.configCriteriaComponent.noFilters")}
          maxWidth="lg"
          actions={
            <>
              <Button
                title={t("configCriteria.configCriteriaComponent.goBack")}
                color="grey"
                type="button"
                onClick={toggleNoFiltersModal}
              />
              <Button
                title={t("configCriteria.configCriteriaComponent.continue")}
                color="blue"
                type="button"
                onClick={() => handleContinueNoFilters()}
              />
            </>
          }
        >
          <Typography variant="h6" color="primary">
            {t(
              "configCriteria.configCriteriaComponent.nonAppliedFilterWarning"
            )}
          </Typography>
        </Dialog>
        <div className="cc_steps_buttons">
          <Button
            title={t("configCriteria.configCriteriaComponent.previous")}
            color="blue-greeny"
            type="button"
            onClick={handleBack}
            disabled={activeStep === 0}
          />
          <Tooltip
            title={t("configCriteria.configCriteriaComponent.resetSteps")}
            arrow
          >
            <span>
              <Button
                title={t("configCriteria.configCriteriaComponent.cleanAll")}
                color="grey"
                type="button"
                onClick={handleRestart}
              />
            </span>
          </Tooltip>
          <Button
            title={t("configCriteria.configCriteriaComponent.finish")}
            color="blue"
            type="button"
            onClick={() => setOpenCriteriaModal(true)}
            disabled={!step1Complete || message?.type === "error"}
          />
          <Button
            title={t("configCriteria.configCriteriaComponent.next")}
            color="blue-greeny"
            type="button"
            onClick={handleNext}
            disabled={
              activeStep === 3 || !step1Complete || message?.type === "error"
            }
          />
        </div>
      </section>
    </ComponentLayout>
  );
};

export default ConfigCriteria;
