import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "../../../../dialog/Dialog";
import WhiteShadowBox from "../../../../layout/WhiteShadowBox";
import { useMemo } from "react";
import useLanguage from "../../../../../context/LanguageProvider";
interface ViewInfoProps {
  info: any;
  open: boolean;
  handleClose: () => void;
  title: string | undefined;
  dataGetExpenses?: any;
  dataGetSales?: any;
  dataGetComposed?: any;
}

const ViewInfoCompoundsModal = ({
  info,
  open,
  handleClose,
  title,
  dataGetExpenses,
  dataGetSales,
  dataGetComposed,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const formulaToString = useMemo(() => {
    let namesString = "";
    let lastType = "";
    info?.formula?.forEach((element) => {
      if (element.tipo === "MEDIDA") {
        const { dato } = element;
        const measureExpense =
          dataGetExpenses &&
          dataGetExpenses?.find((expense) => expense.id === dato);
        const measureSale = dataGetSales?.find((sale) => sale.id === dato);
        const measureComposed =
          dato === -1
            ? { nombre: "GASTOS-NO-CONTEMPLADOS", id: -1 }
            : dataGetComposed?.find((composed) => composed.id === dato);
        if (measureExpense) {
          // Agregar coma antes solo si el anterior también es una medida
          if (lastType === "MEDIDA") {
            namesString += ",";
          }
          namesString += `'${measureExpense.nombre.toUpperCase()}'`;
        } else if (measureSale) {
          if (lastType === "MEDIDA") {
            namesString += ",";
          }
          namesString += `'${measureSale.nombre.toUpperCase()}'`;
        } else if (measureComposed) {
          if (lastType === "MEDIDA") {
            namesString += ",";
          }
          namesString += `'${measureComposed.nombre.toUpperCase()}'`;
        }
        lastType = "MEDIDA";
      } else {
        namesString += element.dato;
        lastType = "OTRO"; // Actualizamos el tipo para indicar que no es una medida
      }
    });
    return namesString;
  }, [info?.formula, dataGetExpenses, dataGetSales]);

  return (
    <div>
      <Dialog open={open} handleClose={handleClose} title={title}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding="0 100px 0px 100px"
          position="relative"
        >
          <WhiteShadowBox>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
                pt: "10px",
              }}
            >
              {t(
                "dataModelText.measureManagement.viewInfoCompoundsModal.generalInfoMeasure"
              )}
            </Typography>
            <Stack sx={{ pt: "10px", width: "100%", wordBreak: "break-all" }}>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t(
                    "dataModelText.measureManagement.viewInfoCompoundsModal.formula"
                  )}
                </span>{" "}
                {formulaToString}
              </p>
            </Stack>
          </WhiteShadowBox>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoCompoundsModal;
