import { useParameterization } from "../../../../hooks/useParameterization";
import CustomButton from "../../../buttons/Button";
import useLanguage from "../../../../context/LanguageProvider";

const CalculateEverythingButton = ({ handleConfirmation }) => {
  const { t } = useLanguage();
  const { isLoadingRows, chartsResultsLoading } =
    useParameterization();

  return (
    <CustomButton
      title={t("modelParameterizationTexts.step2.calculateEverythingTitle")}
      color="blue"
      type="button"
      onClick={() =>
        handleConfirmation(
          t("modelParameterizationTexts.step2.calculateEverything"),
          "calculate"
        )
      }
      disabled={isLoadingRows || chartsResultsLoading}
    />
  );
};

export default CalculateEverythingButton;
