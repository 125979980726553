import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";

interface NoLinesComponentProps {
  height?: string;
}

const NoLinesComponent = ({height}: NoLinesComponentProps) => {
  const { t } = useLanguage();
  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: height ? height : "50%",
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "700", color: "var(--text-info)" }}
      >
        {t("basicGrid.noLinesLabel")}
      </Typography>
    </Stack>
  );
};

export default NoLinesComponent;
