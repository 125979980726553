import { Item } from "../basicGrid/BasicGrid";
import Grid2 from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Delete from "../icons/Delete";
interface DivisionRowsProps {
  row: any;
  rows: any;
  setRows: any;
}

const DivisionRows = ({ row, rows, setRows }: DivisionRowsProps) => {
  const handleDeleteFilter = () => {
    setRows &&
      setRows((prev) => {
        return [
          ...prev.filter((r: any) => {
            return !(
              r.columna.value === row?.columna.value &&
              r.columna_divisor.value === row?.columna_divisor.value
            );
          }),
        ];
      });
  };

  return (
    <Grid2 container direction={"row"} mb={1} md={12} gap={2}>
      <Grid2 xs={0.2}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {setRows && (
            <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
          )}
        </Stack>
      </Grid2>
      <Grid2 xs={5}>
        <Item>
          <Typography color={"primary"}>{row?.columna?.label}</Typography>
        </Item>
      </Grid2>
      <Grid2 xs={5}>
        <Item>
          <Typography color={"primary"}>
            {row?.columna_divisor?.label}
          </Typography>
        </Item>
      </Grid2>
      <Grid2 xs={0.5}></Grid2>
    </Grid2>
  );
};

export default DivisionRows;
