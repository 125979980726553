import { grid_container2_styles } from "../../../../styles/app-styles";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import FormLayout from "../../../forms/FormLayout";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../Shared/GridTitles";
import NoLinesComponent from "../../../forms/NoLinesComponent";
import PrimaryMetricsRow from "../Shared/PrimaryMetricsRow";
import PrimaryMetricsSelects from "../Shared/PrimaryMetricsSelects";
import useLanguage from "../../../../context/LanguageProvider";

const Step3Form = () => {
  const { t } = useLanguage();
  const {
    marketBasketElement,
    setMarketBasketElement,
    setMessage,
    totalColumnOptions,
    column1DestinySelectRef,
    column2DestinySelectRef,
    column3DestinySelectRef,
    column4DestinySelectRef,
    column5DestinySelectRef,
    column6DestinySelectRef,
    column1DestinySelectedOption,
    setColumn1DestinySelectedOption,
    column2DestinySelectedOption,
    setColumn2DestinySelectedOption,
    column2DestinyOptionsChangeHandler,
    column3DestinySelectedOption,
    setColumn3DestinySelectedOption,
    column3DestinyOptionsChangeHandler,
    column4DestinySelectedOption,
    setColumn4DestinySelectedOption,
    column4DestinyOptionsChangeHandler,
    column5DestinySelectedOption,
    setColumn5DestinySelectedOption,
    column5DestinyOptionsChangeHandler,
    column6DestinySelectedOption,
    setColumn6DestinySelectedOption,
    column6OptionsChangeHandler,
  } = useMarketBasketConfigProvider();

  const totalRows = [
    ...marketBasketElement.metricas_destino_primarias,
    ...marketBasketElement.metricas_destino_secundarias,
  ];

  const handleSetNewRows = (newRows: any) => {
    setMarketBasketElement((prev) => ({
      ...prev,
      metricas_destino_primarias: newRows,
    }));
  };

  const handleCleanRows = () => {
    setMarketBasketElement((prev) => ({
      ...prev,
      metricas_destino_primarias: [],
    }));
  };

  const titles = [
    t("general.name"),
    t("general.function"),
    t("general.column"),
    t("general.dividingColumn"),
    t("general.consolidatePeriods"),
    t("general.consolidateDestinations"),
  ];

  return (
    <FormLayout width="100%">
      <Grid sx={grid_container2_styles} container spacing={1}>
        <GridFiveTitles titles={titles} />
        <PrimaryMetricsSelects
          titles={titles}
          setMessage={setMessage}
          totalColumnOptions={totalColumnOptions}
          rows={marketBasketElement?.metricas_destino_primarias || []}
          handleSetNewRows={handleSetNewRows}
          handleCleanRows={handleCleanRows}
          column1SelectRef={column1DestinySelectRef}
          column2SelectRef={column2DestinySelectRef}
          column3SelectRef={column3DestinySelectRef}
          column4SelectRef={column4DestinySelectRef}
          column5SelectRef={column5DestinySelectRef}
          column6SelectRef={column6DestinySelectRef}
          column1SelectedOption={column1DestinySelectedOption}
          setColumn1SelectedOption={setColumn1DestinySelectedOption}
          column2SelectedOption={column2DestinySelectedOption}
          setColumn2SelectedOption={setColumn2DestinySelectedOption}
          column2OptionsChangeHandler={column2DestinyOptionsChangeHandler}
          column3SelectedOption={column3DestinySelectedOption}
          setColumn3SelectedOption={setColumn3DestinySelectedOption}
          column3OptionsChangeHandler={column3DestinyOptionsChangeHandler}
          column4SelectedOption={column4DestinySelectedOption}
          setColumn4SelectedOption={setColumn4DestinySelectedOption}
          column4OptionsChangeHandler={column4DestinyOptionsChangeHandler}
          column5SelectedOption={column5DestinySelectedOption}
          setColumn5SelectedOption={setColumn5DestinySelectedOption}
          column5OptionsChangeHandler={column5DestinyOptionsChangeHandler}
          column6SelectedOption={column6DestinySelectedOption}
          setColumn6SelectedOption={setColumn6DestinySelectedOption}
          column6OptionsChangeHandler={column6OptionsChangeHandler}
          totalRows={totalRows}
        />
        {marketBasketElement?.metricas_destino_primarias &&
        marketBasketElement?.metricas_destino_primarias.length ? (
          marketBasketElement?.metricas_destino_primarias.map(
            (row: any, index: number) => (
              <PrimaryMetricsRow
                key={index}
                row={row}
                handleSetNewRows={handleSetNewRows}
                rows={marketBasketElement?.metricas_destino_primarias}
                secondaryMetricsRows={
                  marketBasketElement?.metricas_destino_secundarias
                }
                titles={titles}
                isRowDeletable={true}
              />
            )
          )
        ) : (
          <NoLinesComponent />
        )}
      </Grid>
    </FormLayout>
  );
};
export default Step3Form;
