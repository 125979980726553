import { Stack } from "@mui/material";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import CardsGroup from "./CardsGroup";
import CustomSkeleton from "../../../skeleton/CustomSkeleton";
import LinesChart from "../../../Charts/LinesChart";

const CardsSection = () => {
  const { chartsBarData, mbAnalysisResults, isLoadingMbAnalysisResults } =
    useMarketBasketProvider();

  return (
    <Stack width="100%" gap={2} sx={{ justifyContent: "center" }}>
      <Stack width="100%" gap={2}>
        {isLoadingMbAnalysisResults && <CustomSkeleton height={6} />}
        {mbAnalysisResults && !isLoadingMbAnalysisResults && (
          <CardsGroup kpisData={mbAnalysisResults.indicadores} />
        )}
      </Stack>
      {mbAnalysisResults &&
        mbAnalysisResults.lineas &&
        mbAnalysisResults.lineas.map((line, index) => (
          <LinesChart key={index} results={line} unit="moneda" />
        ))}
    </Stack>
  );
};

export default CardsSection;
