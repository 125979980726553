import Tooltip from "@mui/material/Tooltip";

export const convertToText = (array: any, t: any): string => {
  return array.length !== 0
    ? array
        ?.map((item: any) => {
          return " " + item.label;
        })
        .toString() || ""
    : t('general.all');
};

export const transformText = (text: string, length = 83, t) => {

  return text.length > length ? (
    <>
      {text.slice(0, length)}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={text}
        placement="top-end"
        enterDelay={50}
      >
        <span          
          style={{ color: "var(--text-secondary)", fontWeight: "bold" }}
          onMouseOver={(e) => {
            e.currentTarget.style.color = "var(--text-error)";
            e.currentTarget.style.cursor = "pointer";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.color = "var(--text-secondary)";
          }}
        >
          ...{t('general.seeMore')}
        </span>
      </Tooltip>
    </>
  ) : (
    text
  );
};

export const convertObjectArrayToText = (array: any, t: any): string => {
  return array.length !== 0
    ? array
        ?.map((item: any) => {
          return " " + item.valor;
        })
        .toString() || ""
    : t('general.all');
};

export const sortByName = (array: any[]) => {
  return array.sort((a: any, b: any) => {
    return a.nombre.localeCompare(b.nombre);
  });
}

export const sortByIdDesc = (array : any[]) => {
  return array.sort((a: any, b: any) => {
      return b.id - a.id;
    });
}
