import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import RelationshipInput from "./RelationshipInput";
import RelationshipRow from "./RelationshipRow";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";

const RelationshipsFilesInputs = () => {
  const { t } = useLanguage();
  const { message, setRelationships } = useCompanyParamProvider();
  const [newRelationship, setNewRelationship] = useState<any>({});
  const relationshipNameRef: any = useRef(null);
  const [newName, setNewName] = useState<string>("");

  const handleAddRelationShip = () => {
    setRelationships((prev: any) => [...prev, newRelationship]);
    setNewRelationship({ nombre: "", involucrados: [] });
    setNewName("");
    relationshipNameRef.current.value = "";
  };

  return (
    <FormSelectLayout title={""} required={false}>
      <Stack width="100%" sx={{ minHeight: "200px" }}>
        <RelationshipInput
          setNewRelationship={setNewRelationship}
          newName={newName}
          setNewName={setNewName}
          relationshipNameRef={relationshipNameRef}
          newRelationship={newRelationship}
        />
        {newRelationship &&
          newRelationship.involucrados &&
          newRelationship.involucrados.map((item, index) => {
            return (
              <RelationshipRow
                key={index}
                row={item}
                rows={newRelationship.involucrados}
                setRows={setNewRelationship}
              />
            );
          })}
      </Stack>
      <Stack alignItems="center">
        <Button
          title={`+ ${t('companyParam.newRelationship')}`}
          color="blue-greeny"
          type="button"
          onClick={() => handleAddRelationShip()}
          disabled={
            newRelationship.nombre === "" ||
            !newRelationship.involucrados ||
            newRelationship.involucrados?.length === 0 ||
            message.text !== ""
          }
          width={200}
        />
      </Stack>
    </FormSelectLayout>
  );
};

export default RelationshipsFilesInputs;
