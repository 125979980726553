import { Stack } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { convertUnit, formatMoney } from "../utils";

export const transformDataForDifTable = (data: any, t) => {
  const columnas = [
    t("reportTexts.transformDataForDifTable.columns.period"),
    t("reportTexts.transformDataForDifTable.columns.bucketExpense"),
    t("reportTexts.transformDataForDifTable.columns.grouper"),
    t("reportTexts.transformDataForDifTable.columns.mount"),
    t("reportTexts.transformDataForDifTable.columns.result"),
    t("reportTexts.transformDataForDifTable.columns.difference"),
    t("reportTexts.transformDataForDifTable.columns.transferred"),
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna === t("reportTexts.transformDataForDifTable.columns.grouper")
        ? 1.3
        : columna === t("reportTexts.transformDataForDifTable.columns.transferred") || columna === t("reportTexts.transformDataForDifTable.columns.period")
        ? 0.6
        : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        {(columna === t("reportTexts.transformDataForDifTable.columns.mount") || columna === t("reportTexts.transformDataForDifTable.columns.result")) && (
          <p>{convertUnit("moneda", params.value)}</p>
        )}
        {columna === t("reportTexts.transformDataForDifTable.columns.difference") && <p>{formatMoney(params.value)}</p>}
        {columna === t("reportTexts.transformDataForDifTable.columns.transferred") && (
          <p>{convertUnit("porcentaje", params.value)}</p>
        )}
        {(columna === t("reportTexts.transformDataForDifTable.columns.period") ||
          columna === t("reportTexts.transformDataForDifTable.columns.bucketExpense") ||
          columna === t("reportTexts.transformDataForDifTable.columns.grouper")) && <p>{params.value}</p>}
      </Stack>
    ),
  }));

  const arrayRows =
    data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          obj["id"] = fila.id;
          for (const key in fila) {
            if (key !== "id") {
              obj[key] = fila[key];
            }
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any, index: number) => ({
    ...row,
    id: index,
  }));

  return { columns, rows };
};

export const getColumnsForDifTable = (t) => {
  const columnas = [
    t("reportTexts.transformDataForDifTable.columns.period"),
    t("reportTexts.transformDataForDifTable.columns.bucketExpense"),
    t("reportTexts.transformDataForDifTable.columns.grouper"),
    t("reportTexts.transformDataForDifTable.columns.mount"),
    t("reportTexts.transformDataForDifTable.columns.result"),
    t("reportTexts.transformDataForDifTable.columns.difference"),
    t("reportTexts.transformDataForDifTable.columns.transferred"),
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna === t("reportTexts.transformDataForDifTable.columns.grouper")
        ? 1.3
        : columna === t("reportTexts.transformDataForDifTable.columns.transferred") || columna === t("reportTexts.transformDataForDifTable.columns.period")
        ? 0.6
        : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
  }));

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  return columns;
};

export const transformDataForErrorsTable = (data: any, t) => {
  const columnas = Object.keys(data[0]);

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field === t("transformDataForErrorsTable.destination1") ? t("transformDataForErrorsTable.destination")  : params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
          {(columna === t("transformDataForErrorsTable.expenseMount") ) ? (
          <p>{convertUnit(t("transformDataForErrorsTable.coin"), params.value)}</p>
        ) : <p>{params.value}</p>}
      </Stack>
    ),
  }));

  const arrayRows =
    data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          obj["id"] = fila.id;
          for (const key in fila) {
            if (key !== "id") {
              obj[key] = fila[key];
            }
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any, index: number) => ({
    ...row,
    id: index,
  }));

  return { columns, rows };
};

export const getColumnsForErrorsTable = (t) => {
  const columnas = [t("reportTexts.getColumnsForErrorsTable.columns.bucket"), t("reportTexts.getColumnsForErrorsTable.columns.destination"), t("reportTexts.getColumnsForErrorsTable.columns.stage"), t("reportTexts.getColumnsForErrorsTable.columns.period")];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
  }));

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  return columns;
};

export const transformDataForIterationTable = (data: any, t) => {
  
  const columnas = Object.keys(data[0]).filter(columna => columna != t("transformDataForIterationTable.columns.solved"));

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
         {(columna === t("transformDataForIterationTable.columns.expenseMount")) ? (
          <p>{convertUnit(t("transformDataForIterationTable.coin"), params.value)}</p>
        ): <p>{params.value}</p>}
      </Stack>
    ),
  }));

  const arrayRows =
    data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          obj["id"] = fila.id;
          for (const key in fila) {
            if (key !== "id") {
              obj[key] = fila[key];
            }
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any, index: number) => ({
    ...row,
    id: index,
  }));

  return { columns, rows };
};

export const getColumnsForIterationTable = (t) => {
  const columnas = [
    t("reportTexts.getColumnsForIterationTable.columns.mount"),
    t("reportTexts.getColumnsForIterationTable.columns.grouper"),
    t("reportTexts.getColumnsForIterationTable.columns.channel"),
    t("reportTexts.getColumnsForIterationTable.columns.period"),
    t("reportTexts.getColumnsForIterationTable.columns.stage"),
    t("reportTexts.getColumnsForIterationTable.columns.bucket"),
    t("reportTexts.getColumnsForIterationTable.columns.iteration")
  ];

  const arrayColumns = columnas?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
  }));

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  return columns;
};
