import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";
import {
  no_cards_error_container_styles,
  no_cards_error_typography_styles,
} from "../../styles/app-styles";

interface NoCardsErrorProps {
  height?: string;
}

const NoCardsError = ({height}: NoCardsErrorProps) => {
  const { t } = useLanguage();

  return (
    <Box sx={no_cards_error_container_styles(height)}>
      <Typography variant="h6" sx={no_cards_error_typography_styles}>
        {t("general.noCardsYet")}
      </Typography>
    </Box>
  );
};

export default NoCardsError;
