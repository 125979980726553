import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Delete from "../icons/Delete";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  margin: 0,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface CustomAccordionProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  backgroundColor?: string;
  summaryColor?: string;
  arrowColor?: string;
  detailsColor?: string;
  onDelete?: (title: any) => void;
  deleteTooltipText?: string;
  handleSelectAll?: (group: string | undefined, allItemsChecked: boolean | undefined) => void;
  allItemsChecked?: boolean | undefined;
  group?: string
}

export default function CustomAccordion({
  title,
  children,
  backgroundColor,
  summaryColor,
  arrowColor,
  detailsColor,
  onDelete,
  deleteTooltipText,
  handleSelectAll,
  allItemsChecked,
  group,
}: CustomAccordionProps) {
  const { t } = useLanguage()
  const [expanded, setExpanded] = useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };    

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{           
          "& div svg": {
            fill: arrowColor ? arrowColor : "var(--cream)",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            backgroundColor: backgroundColor ? backgroundColor : "var(--bg-main)",
            color: summaryColor ? summaryColor : "var(--cream)",
          }}
        >
          <Stack sx={{
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "row",
            alignItems: "center"
          }}>
            <Typography>{title}</Typography>
            {handleSelectAll && allItemsChecked !== undefined ? <Stack sx={{
              flexDirection: "row",
              alignItems: "center"
            }}>
              <Checkbox
                disabled={false}
                checked={allItemsChecked}
                tabIndex={10}
                disableRipple
                onClick={
                  (event) => {
                    event.stopPropagation() // Detener la propagación del evento ACCORDION
                    handleSelectAll(group, allItemsChecked)
                  }
                }
                sx={{
                  background: "var(--bg-main)",
                }}
              />
              <Typography>{t("modelParameterizationTexts.customCheckbox.all")}</Typography>
            </Stack>
              : <></>
            }
          </Stack>
          <Stack width="10%" sx={{ position: "relative", left: "2%" }}>
            {onDelete && (
              <Delete
                tooltipPlacement={"right"}
                onClick={onDelete}
                tooltipText={deleteTooltipText}
                fill="var(--icon-info) !important"
              />
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
            margin: 0,
            color: detailsColor ? detailsColor : "var(--cream)",
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
