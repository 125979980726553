import { CHART_FUNCTIONS } from "../data";
import { ChartOfList, ChartType } from "../../../dashboardManagement/types";
import { checkRepeatedName, deleteDuplicatedElements } from "../utils";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import { useRef, useState } from "react";
import BarrasDrilldown from "../../../../assets/BarrasDrilldown.png";
import Button from "../../../buttons/Button";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Lines from "../../../../assets/Lines.png";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import Step0Charts from "../../../dashboardManagement/shared/Step0Charts";
import TextField from "@mui/material/TextField";
import TreeDataDrilldown from "../../../../assets/TreeDataDrilldown.png";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { form_helper_text_styles } from "../../../../styles/app-styles";

const ConfigChartStep4 = ({ handleToggleModal, elements, handleApply }) => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    dataGetFileColumnsType,
    fileSelectedOption,
    columnSKUSelectedOption,
    marcaSelectedOption,
    columnasExtraSelectedOptions,
    marketBasketElement,
    totalColumnOptions,
    ventaMonedaSelectedOption,
    ventaUnidadesSelectedOption,
  } = useMarketBasketConfigProvider();
  const [chart, setChart] = useState<any>({
    nombre: "",
    tipo: "",
    funcion: undefined,
    variable: undefined, //solo para LINEAS o TREEMAP, para lineas es un solo elemento
    indicador: "", // Barras o TReemap
  });
  const functionSelectRef: any = useRef(null);
  const variableSelectRef: any = useRef(null);
  const indicatorSelectRef: any = useRef(null);
  const [
    functionSelectedOption,
    setfunctionSelectedOption,
    functionOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    variableSelectedOption,
    setVariableSelectedOption,
    variableOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    indicatorSelectedOption,
    setIndicatorSelectedOption,
    indicatorOptionsChangeHandler,
  ] = useSingleSelect();

  const chartList: ChartOfList[] = [
    { image: BarrasDrilldown, type: "BARRAS CON DRILLDOWN" },
    { image: Lines, type: "LINEAS" },
    { image: TreeDataDrilldown, type: "TREEMAP CON DRILLDOWN" },
  ];

  const handleCancel = () => {
    handleToggleModal();
    setChart({
      nombre: "",
      tipo: "",
      funcion: undefined,
      variable: undefined, //solo para LINEAS o TREEMAP, para lineas es un solo elemento
      indicador: "", // Barras o TReemap
    });
  };

  const handleSetName = (name: string) => {
    setChart((prev) => {
      return { ...prev, nombre: name };
    });
    checkRepeatedName(t, elements, name, setMessage);
  };

  const handleSetTypeChart = (type: ChartType) => {
    setChart((prev) => {
      return { ...prev, tipo: type };
    });
  };

  const handleRestart = () => {
    setfunctionSelectedOption(undefined);
    setVariableSelectedOption(undefined);
    setIndicatorSelectedOption(undefined);
    functionSelectRef?.current?.clearValue();
    variableSelectRef?.current?.clearValue();
    indicatorSelectRef?.current?.clearValue();
    setChart((prev) => {
      return {
        ...prev,
        tipo: "",
        funcion: undefined,
        variable: undefined,
        indicador: "",
      };
    });
  };

  const findFileType = dataGetFileColumnsType
    ? dataGetFileColumnsType.find(
        (option) => option?.id === fileSelectedOption?.value
      ).tipo
    : [];

  const templateOptions = () => {
    const options: any[] = [];
    if (columnSKUSelectedOption) {
      options.push(columnSKUSelectedOption);
    }
    if (marcaSelectedOption) {
      options.push(marcaSelectedOption);
    }
    if (
      columnasExtraSelectedOptions &&
      columnasExtraSelectedOptions.length > 0
    ) {
      options.push(...columnasExtraSelectedOptions);
    }
    return deleteDuplicatedElements(options);
  };

  const getTreemapVariableOptions = () => {
    const treemapOptions: any[] = [];
    if (marcaSelectedOption) {
      treemapOptions.push(marcaSelectedOption);
    }
    if (columnSKUSelectedOption) {
      treemapOptions.push(columnSKUSelectedOption);
    }
    return deleteDuplicatedElements(treemapOptions);
    /* findFileType === "TEMPLATE" ? templateOptions() : totalColumnOptions; */
  };

  const getSkuIndicatorsOptions = () => {
    const skuIndicators: any[] = [];
    if (marketBasketElement.metricas_SKU_primarias.length > 0) {
      skuIndicators.push(
        ...marketBasketElement.metricas_SKU_primarias.map((metrica) => ({
          value: metrica.nombre,
          label: metrica.nombre,
        }))
      );
    }
    if (marketBasketElement.metricas_SKU_secundarias.length > 0) {
      skuIndicators.push(
        ...marketBasketElement.metricas_SKU_secundarias.map((metrica) => ({
          value: metrica.nombre,
          label: metrica.nombre,
        }))
      );
    }
    return skuIndicators;
  };

  const getTreemapIndicatorsOptions = () => {
    const treemapIndicators: any[] = [{
          value: "VENTA_MONEDA",
          label: "VENTA_MONEDA",
        }, 
        {
          value: "VENTA_UNIDADES",
          label: "VENTA_UNIDADES",
          }];
    return treemapIndicators;
  };

  const handleAccept = () => {
    const updatedChart = {
      ...chart,
      funcion: functionSelectedOption,
      variable: variableSelectedOption,
      indicador: indicatorSelectedOption,
    };
    handleApply(updatedChart);
  };

  return (
    <Stack component="form" noValidate autoComplete="off"  gap="20px">
      <TextField
        sx={{ wordWrap: "break-word" }}
        type="text"
        label={t("general.name")}
        variant="outlined"
        fullWidth
        value={chart.nombre}
        onChange={(e) => handleSetName(e.target.value)}
        error={message.text !== "" || chart.nombre === ""}
        helperText={message.text}
        FormHelperTextProps={{ sx: form_helper_text_styles }}
        size="small"
      />
      <Step0Charts
        selectedChart={chart}
        chartType={chart.tipo}
        setChartType={handleSetTypeChart}
        handleRestart={handleRestart}
        chartList={chartList}
        cols={3}
        height="220px"
      />
      {chart.tipo === "LINEAS" && (
        <FormSelectLayout
          title={t("general.variable")}
          required={true}
          margin="0px"
        >
          <Select
            reference={variableSelectRef}
            styles={selectStyles(variableSelectedOption)}
            options={totalColumnOptions}
            onChange={variableOptionsChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            isClearable
          />
        </FormSelectLayout>
      )}
      {chart.tipo === "TREEMAP CON DRILLDOWN" && (
        <FormSelectLayout
          title={t("marketBasket.config.groupingVariable")}
          required={true}
          margin="0px"
        >
          <Select
            reference={variableSelectRef}
            styles={selectStyles(variableSelectedOption)}
            options={getTreemapVariableOptions()}
            onChange={variableOptionsChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            isClearable
          />
        </FormSelectLayout>
      )}
      {(chart.tipo === "LINEAS" || chart.tipo === "BARRAS CON DRILLDOWN") && (
        <FormSelectLayout
          title={t("general.function")}
          required={true}
          margin="0px"
        >
          <Select
            reference={functionSelectRef}
            styles={selectStyles(functionSelectedOption)}
            options={CHART_FUNCTIONS(t)}
            onChange={functionOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("general.chooseColumn")}
            isClearable
          />
        </FormSelectLayout>
      )}

      {(chart.tipo === "TREEMAP CON DRILLDOWN" ||
        chart.tipo === "BARRAS CON DRILLDOWN") && (
        <FormSelectLayout
          title={t("general.indicator")}
          required={true}
          margin="0 0 50px 0"
        >
          <Select
            reference={indicatorSelectRef}
            styles={selectStyles(indicatorSelectedOption)}
            options={
              chart.tipo === "BARRAS CON DRILLDOWN"
                ? getSkuIndicatorsOptions()
                : getTreemapIndicatorsOptions()
            }
            onChange={indicatorOptionsChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("marketBasket.config.chooseIndicator")}
            isClearable
          />
        </FormSelectLayout>
      )}
      <Stack direction="row" justifyContent="center" sx={{ width: "100%", mb: 0 }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.apply")}
          color="blue-greeny"
          type="button"
          onClick={() => handleAccept()}
          disabled={
            message.text !== "" ||
            !chart.tipo ||
            chart.nombre === "" ||
            (chart.tipo !== "TREEMAP CON DRILLDOWN" && !functionSelectedOption) ||
            (chart.tipo === "LINEAS" && !variableSelectedOption) ||
            (chart.tipo === "TREEMAP CON DRILLDOWN" &&
              !variableSelectedOption) ||
            (chart.tipo === "TREEMAP CON DRILLDOWN" &&
              !indicatorSelectedOption) ||
            (chart.tipo === "BARRAS CON DRILLDOWN" && !indicatorSelectedOption)
          }
        />
      </Stack>
    </Stack>
  );
};

export default ConfigChartStep4;
