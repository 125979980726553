import { useEffect } from "react";
import useSingleSelect from "../../hooks/useSingleSelect";

interface PrincipalColumnsSelectsProps {
    children: any,
    defaultValue: any,
}

export default function PrincipalColumnsSelects({ children, defaultValue }: PrincipalColumnsSelectsProps) {

    useEffect(() => {
        defaultValue && setPrincipalColumnSelectedOptions(defaultValue);
    }, [defaultValue])

    const [
        principalColumnFilterSelectedOptions,
        setPrincipalColumnSelectedOptions,
        principalColumnFilterOptionsChangeHandler,
    ] = useSingleSelect(undefined, defaultValue ? defaultValue : undefined);


    return children({
        principalColumnFilterSelectedOptions,
        principalColumnFilterOptionsChangeHandler,
        setPrincipalColumnSelectedOptions
    });
}