import { ActionMeta } from "react-select";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { Button } from "../buttons";
import { ConfirmationModal as CalculateConfirmationModal } from "../dialog";
import { ConfirmationModal as ResetConfirmationModal } from "../dialog";
import { criteriaOptions, schemasOptions } from "./selectOptions";
import { GridColDef } from "@mui/x-data-grid";
import { IncomingSchemaOption, SchemaRow } from "./types";
import { transformData } from "../virtualizatedTable/transformDataForPreproration";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useRef, useState, useEffect } from "react";
import { VariantType, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import Dialog from "../dialog/Dialog";
import IconButton from "@mui/material/IconButton";
import NewSchemaContent from "./NewSchemaContent";
import PeriodsFilter from "../periods/PeriodsFilter";
import SchemaInfoModal from "./showSchemeInfoModal/SchemaInfoModal";
import SelectSchemaContent from "./SelectSchemaContent";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import TuneIcon from "@mui/icons-material/Tune";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useSingleSelect from "../../hooks/useSingleSelect";
import useTabs from "../../hooks/useTabs";
import VirtualizatedTable from "../virtualizatedTable/VirtualizedTable";
import {
  CRITERIA_URL,
  GET_BUCKETS_URL,
  GET_GROUPERS_URL,
  GET_PREPRORRATEO_GASTOS_URL,
  POST_APPLY_SCHEMA_URL,
  POST_PREPRORATION_CALCULATE_URL,
  POST_REVERSE_CALCULATION_URL,
  SCHEMAS_URL,
} from "../../api/axios";

const PreprorationParameterization = () => {
  const { t, language } = useLanguage();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userRolData }: any = useInfoContext();
  const criteriaSelectRef: any = useRef();
  const schemaSelectRef = useRef<HTMLDivElement>(null);
  const inputSchemaNameRef = useRef<HTMLInputElement>(null);
  const percentageRef = useRef<HTMLInputElement>(null);
  const destinityCreatableSelectRef: any = useRef();
  const [tabValue, handleChange] = useTabs(
    t(
      "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
    )
  );
  const [schemaTabValue, handleChangeSchemaTabValue] = useTabs(
    t(
      "preprorationParameterization.preprorationParameterization.schemaTabs.selectSchema"
    )
  );
  const [openEsquemaDialog, handleToggleSchemaDialog] = useDialog();
  const [openEsquemaInfoModal, handleToggleEsquemaInfoModal] = useDialog();
  const [
    openCalculateConfirmationModal,
    handleToggleCalculateConfirmationModal,
  ] = useDialog();
  const [openResetConfirmationModal, handleToggleResetConfirmationModal] =
    useDialog();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedSchemaInfo, setSelectedSchemaInfo] = useState<any>();
  const [schemaName, setSchemaName] = useState<string>("");
  const [percentage, setPercentage] = useState<string | number>(100);
  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [newSchemaRows, setNewSchemaRows] = useState<SchemaRow[]>([]);
  const [destinitySelectOptions, setDestinitySelectOptions] = useState<any[]>(
    []
  );
  const [creatableDestinityValueSelected, setCreatableDestinityValueSelected] =
    useState<string>("");
  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();

  /*************************************** Tabs **************************************************/

  const tabs = [
    {
      value: t(
        "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
      ),
      label: t(
        "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
      ),
    },
    {
      value: t(
        "preprorationParameterization.preprorationParameterization.tabs.grouper"
      ),
      label: t(
        "preprorationParameterization.preprorationParameterization.tabs.grouper"
      ),
    },
  ];

  const schemaTabs = [
    {
      value: t(
        "preprorationParameterization.preprorationParameterization.schemaTabs.selectSchema"
      ),
      label: t(
        "preprorationParameterization.preprorationParameterization.schemaTabs.selectSchema"
      ),
    },
    {
      value: t(
        "preprorationParameterization.preprorationParameterization.schemaTabs.createSchema"
      ),
      label: t(
        "preprorationParameterization.preprorationParameterization.schemaTabs.createSchema"
      ),
    },
  ];

  /*************************************** Seteos iniciales ***************************************/

  useEffect(() => {
    handleChange(
      null,
      t(
        "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
      )
    );
  }, [language]);

  useEffect(() => {
    getPreprorationCriterias();

    const preprorrationTab = localStorage.getItem("preprorrationTab");
    if (preprorrationTab !== null) {
      handleChange(null, preprorrationTab);
    } else {
      handleChange(
        null,
        t(
          "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
        )
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("preprorrationTab", tabValue);
    return () => {
      // Eliminar preprorrationTab del localStorage al desmontar el componente
      localStorage.removeItem("preprorrationTab");
    };
  }, [tabValue]);

  useEffect(() => {
    if (language === "ESPAÑOL") {
      handleChangeSchemaTabValue(null, "Seleccionar Esquema");
    } else if (language === "ENGLISH") {
      handleChangeSchemaTabValue(null, "Select Schema");
    } else if (language === "PORTUGUÊS") {
      handleChangeSchemaTabValue(null, "Selecionar Esquema");
    }
  }, [language]);

  const handleClickVariant = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  /************************************** Selects ***********************************************/

  const [
    schemaSelectedOptions,
    setSchemaSelectedOptions,
    schemaOptionsChangeHandler,
  ] = useSingleSelect(undefined);

  const [
    criteriaSelectedOption,
    setCriteriaSelectedOption,
    criteriaSelectedOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    selectedCriteriaType,
    setSelectedCriteriaType,
    selectedCriteriaTypeChangeHandler,
  ] = useSingleSelect(() => <></>, {
    value: t(
      "preprorationParameterization.preprorationParameterization.criteria"
    ),
    label: t(
      "preprorationParameterization.preprorationParameterization.criteria"
    ).toUpperCase(),
  });

  /*********************************** Api Querys **********************************/

  const {
    data: criteria,
    callApi: getPreprorationCriterias,
    isLoading: criteriasLoading,
  } = useApi(
    `${CRITERIA_URL}?tipo=pre_prorrateo`,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    data: dataPreprorationBucket,
    isLoading: isLoadingPreprorationBucket,
    isSuccess: isSuccessPreprorationBucket,
    isError: isErrorPreprorationBucket,
    refetch: refetchPreprorationBucket,
  } = useApiQuery(
    `${GET_PREPRORRATEO_GASTOS_URL}?destino=bucket_gasto`,
    true,
    t("preprorationParameterization.getPreprorationBuckets.error")
  );

  const {
    data: dataPreprorationGrouper,
    isLoading: isLoadingPreprorationGrouper,
    isSuccess: isSuccessPreprorationGrouper,
    isError: isErrorPreprorationGrouper,
    refetch: refetchPreprorationGrouper,
  } = useApiQuery(
    `${GET_PREPRORRATEO_GASTOS_URL}?destino=agrupador`,
    true,
    t("preprorationParameterization.getPreprorationGrouper.error")
  );

  const {
    data: dataGetCriteriaBucket,
    isSuccess: isSuccessCriteriaBucket,
    isLoading: loadingDataGetCriteriaBucket,
  } = useApiQuery(GET_BUCKETS_URL, false, undefined);

  const {
    data: dataSchemas,
    refetch: refetchSchemas,
    isLoading: dataSchemasLoading,
  } = useApiQuery(SCHEMAS_URL, false, undefined);

  const {
    data: dataGroupers,
    isSuccess: isSuccessGroupers,
    isLoading: loadingDataGroupers,
  } = useApiQuery(GET_GROUPERS_URL, false, undefined);

  const onSuccessPreprorationCalculate = () => {
    refetchPreprorationBucket();
    refetchPreprorationGrouper();
  };

  const {
    isLoading: isLoadingPreprorationCalculate,
    callApi: preprorationCalculate,
  } = useApi(
    undefined,
    "POST",
    t("preprorration.preprorrationCalculate.codes"),
    undefined,
    onSuccessPreprorationCalculate,
    undefined,
    true
  );

  const onSuccessApplySchemas = () => {
    refetchPreprorationBucket();
    refetchPreprorationGrouper();
  };

  const { callApi: applySchemas, isLoading: isLoadingApplySchemas } = useApi(
    POST_APPLY_SCHEMA_URL,
    "POST",
    t(
      "preprorationParameterization.preprorationParameterization.applySchemas.codes"
    ),
    { grupo: selectedRows, esquema: schemaSelectedOptions?.value },
    onSuccessApplySchemas,
    undefined,
    true
  );

  const onSuccessCreateSchema = () => {
    refetchSchemas();
    setNewSchemaRows([]);
    setSchemaName("");
    handleToggleSchemaDialog();
    handleChangeSchemaTabValue(
      null,
      t(
        "preprorationParameterization.preprorationParameterization.schemaTabs.selectSchema"
      )
    );
  };

  const { callApi: postNewSchema, isLoading: isLoadingPostNewSchema } = useApi(
    SCHEMAS_URL,
    "POST",
    t(
      "preprorationParameterization.preprorationParameterization.createSchema.codes"
    ),
    undefined,
    onSuccessCreateSchema,
    undefined,
    true
  );

  const onSuccessRevserseCalculation = () => {
    refetchPreprorationBucket();
    refetchPreprorationGrouper();
  };

  const {
    callApi: reverseCalculation,
    isLoading: isLoadingReverseCalculation,
  } = useApi(
    POST_REVERSE_CALCULATION_URL,
    "POST",
    t(
      "preprorationParameterization.preprorationParameterization.reverseCalculationMessages.codes"
    ),
    undefined,
    onSuccessRevserseCalculation,
    undefined,
    true
  );

  /************************************** Transform Data para tablas *****************************/

  useEffect(() => {
    if (isSuccessPreprorationGrouper && isSuccessPreprorationBucket) {
      if (
        tabValue ===
        t(
          "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
        )
      ) {
        if (
          dataPreprorationBucket &&
          dataPreprorationBucket.data &&
          dataPreprorationBucket.data.length > 0
        ) {
          const { columns, rows } = transformData(
            dataPreprorationBucket.data,
            handleClickOnSchemaName
          );
          setColumns(columns);
          setRows(rows);
        } else {
          setColumns([]);
          setRows([]);
        }
      } else {
        if (
          dataPreprorationGrouper &&
          dataPreprorationGrouper.data &&
          dataPreprorationGrouper.data.length > 0
        ) {
          const { columns, rows } = transformData(
            dataPreprorationGrouper.data,
            handleClickOnSchemaName
          );
          setColumns(columns);
          setRows(rows);
        } else {
          setColumns([]);
          setRows([]);
        }
      }
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [dataPreprorationGrouper, dataPreprorationBucket, tabValue]);

  useEffect(() => {
    if (isSuccessGroupers && isSuccessCriteriaBucket) {
      if (
        tabValue ===
        t(
          "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
        )
      ) {
        setDestinitySelectOptions(dataGetCriteriaBucket);
      } else {
        setDestinitySelectOptions(dataGroupers);
      }
    } else {
      setDestinitySelectOptions([]);
    }
  }, [dataGroupers, dataGetCriteriaBucket, tabValue]);

  const handleSelectedRows = (rows: any) => {
    setSelectedRows(rows);
  };

  const handleClickOnSchemaName = (schemaName: string) => {
    const schema = dataSchemas?.find((schema: IncomingSchemaOption) => {
      return schema.nombre === schemaName;
    });
    if (schema) {
      setSelectedSchemaInfo(schema);
      handleToggleEsquemaInfoModal();
    }
  };

  const handleApplySchemas = async () => {
    handleToggleSchemaDialog();
    applySchemas();
  };

  const handleCreateSchema = async () => {
    const schema = {
      nombre: schemaName,
      tipo_destino:
        tabValue ===
        t(
          "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
        )
          ? "BUCKET_GASTO"
          : "AGRUPADOR",
      esquema_criterios: newSchemaRows.map((row) => {
        let newRow = {};
        row.porcentaje
          ? (newRow = {
              destino: row.destino,
              porcentaje: parseFloat(row.porcentaje as string),
            })
          : (newRow = { destino: row.destino, criterio: row.criterio?.value });
        return newRow;
      }),
    };
    handleToggleSchemaDialog();
    postNewSchema(undefined, schema);
  };

  const handleChangeDestinityCreatableSelect = (
    value: any,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        setCreatableDestinityValueSelected(value.label);
        break;
      case "clear":
        setCreatableDestinityValueSelected("");
        break;
      case "create-option":
        destinityCreatableSelectRef.current.value = value.label;
        setCreatableDestinityValueSelected(value.label);
        break;
      default:
        break;
    }
  };

  const closeDialog = () => {
    handleToggleSchemaDialog();
    setNewSchemaRows([]);
  };

  const handleReverseCalculation = async () => {
    handleToggleResetConfirmationModal();
    reverseCalculation();
  };

  const checkIfAllRowsHasSchemasApplied = () => {
    if (
      tabValue ===
      t(
        "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
      )
    ) {
      if (
        dataPreprorationBucket &&
        dataPreprorationBucket.data &&
        dataPreprorationBucket.data.length > 0
      ) {
        return dataPreprorationBucket.data.every(
          (item: any) => !!item.ESQUEMA_APLICADO.ID
        );
      } else {
        return false;
      }
    } else {
      if (
        dataPreprorationGrouper &&
        dataPreprorationGrouper.data &&
        dataPreprorationGrouper.data.length > 0
      ) {
        return dataPreprorationGrouper.data.every(
          (item: any) => !!item.ESQUEMA_APLICADO.ID
        );
      } else {
        return false;
      }
    }
  };

  const handlePreprorationCalculate = async () => {
    handleToggleCalculateConfirmationModal();
    const destination_type =
      tabValue ===
      t(
        "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
      )
        ? "bucket_gasto"
        : "agrupador";
    if (
      tabValue ===
      t(
        "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
      )
    ) {
      if (
        dataPreprorationBucket &&
        dataPreprorationBucket.data &&
        dataPreprorationBucket.data.length > 0
      ) {
        if (
          dataPreprorationBucket.data.every(
            (item: any) => !!item.ESQUEMA_APLICADO.ID
          )
        ) {
          preprorationCalculate(
            POST_PREPRORATION_CALCULATE_URL(destination_type)
          );
        } else {
          handleClickVariant(
            t(
              "preprorationParameterization.preprorationParameterization.schemasToApplyMissing"
            ),
            "error"
          );
        }
      } else {
        handleClickVariant(
          t(
            "preprorationParameterization.preprorationParameterization.noDataToCalculation"
          ),
          "error"
        );
      }
    } else {
      if (
        dataPreprorationGrouper &&
        dataPreprorationGrouper.data &&
        dataPreprorationGrouper.data.length > 0
      ) {
        if (
          dataPreprorationGrouper.data.every(
            (item: any) => !!item.ESQUEMA_APLICADO.ID
          )
        ) {
          preprorationCalculate(
            POST_PREPRORATION_CALCULATE_URL(destination_type)
          );
        } else {
          handleClickVariant(
            t(
              "preprorationParameterization.preprorationParameterization.schemasToApplyMissing"
            ),
            "error"
          );
        }
      } else {
        handleClickVariant(
          t(
            "preprorationParameterization.preprorationParameterization.noDataToCalculation"
          ),
          "error"
        );
      }
    }
  };

  const handleClosePeriodsModal = () => {
    refetchPreprorationBucket();
    refetchPreprorationGrouper();
    handleTogglePeriodsDialog();
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingApplySchemas}
        message={
          selectedRows.length !== 1
            ? t(
                "preprorationParameterization.preprorationParameterization.applyingSchemas"
              )
            : t(
                "preprorationParameterization.preprorationParameterization.applyingSchema"
              )
        }
      />
      <SimpleBackdrop
        open={isLoadingPostNewSchema}
        message={t(
          "preprorationParameterization.preprorationParameterization.creatingSchema"
        )}
      />
      <SimpleBackdrop
        open={isLoadingReverseCalculation}
        message={t(
          "preprorationParameterization.preprorationParameterization.reversingCalculation"
        )}
      />
      <SimpleBackdrop
        open={isLoadingPreprorationCalculate}
        message={t("preprorration.preprorrationCalculate.loading")}
      />
      <SimpleBackdrop
        open={
          dataSchemasLoading ||
          criteriasLoading ||
          loadingDataGroupers ||
          loadingDataGetCriteriaBucket
        }
        message={t("general.loading")}
      />
      <ComponentLayout
        title={t(
          "preprorationParameterization.preprorationParameterization.settingUpPreproration"
        )}
        icon={<TuneIcon />}
        rightComponent={
          <Button
            title={t(
              "preprorationParameterization.preprorationParameterization.periods"
            )}
            color="blue"
            type="button"
            onClick={() => handleTogglePeriodsDialog()}
          />
        }
      >
        <Stack spacing={2} sx={{ width: "100%", margin: "0px !important" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0px !important",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <ColorTabs
                value={tabValue}
                handleChange={handleChange}
                tabs={tabs}
                marginBottom="0px"
              />
            </Box>
            {userRolData &&
              userRolData.parametrizacion_de_preprorrateo_y_prorrateo
                .visualizacion_y_edicion && (
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row">
                    <Tooltip
                      title={
                        !checkIfAllRowsHasSchemasApplied()
                          ? t(
                              "preprorationParameterization.preprorationParameterization.allRowsNeedSchemas"
                            )
                          : ""
                      }
                    >
                      <div>
                        <Button
                          title={t(
                            "preprorationParameterization.preprorationParameterization.calculate"
                          )}
                          color="blue-greeny"
                          type="button"
                          disabled={
                            !checkIfAllRowsHasSchemasApplied() ||
                            (tabValue ===
                              t(
                                "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
                              ) &&
                              dataPreprorationBucket.deshabilitar_botones ===
                                true) ||
                            (tabValue ===
                              t(
                                "preprorationParameterization.preprorationParameterization.tabs.grouper"
                              ) &&
                              dataPreprorationGrouper.deshabilitar_botones ===
                                true)
                          }
                          onClick={() =>
                            handleToggleCalculateConfirmationModal()
                          }
                        />
                      </div>
                    </Tooltip>
                    <Button
                      title={t(
                        "preprorationParameterization.preprorationParameterization.reverseCalculation"
                      )}
                      color="grey"
                      type="button"
                      onClick={() => handleToggleResetConfirmationModal()}
                    />
                    <Button
                      title={t(
                        "preprorationParameterization.preprorationParameterization.assingSchema"
                      )}
                      color="magenta"
                      type="button"
                      onClick={() => handleToggleSchemaDialog()}
                      disabled={
                        selectedRows.length === 0 ||
                        (tabValue ===
                          t(
                            "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
                          ) &&
                          dataPreprorationBucket.deshabilitar_botones ===
                            true) ||
                        (tabValue ===
                          t(
                            "preprorationParameterization.preprorationParameterization.tabs.grouper"
                          ) &&
                          dataPreprorationGrouper.deshabilitar_botones === true)
                      }
                    />
                  </Stack>
                </Stack>
              )}
          </Box>
          <Stack width="100%">
            {isLoadingPreprorationBucket || isLoadingPreprorationGrouper ? (
              <CustomSkeleton height={5} />
            ) : isErrorPreprorationBucket || isErrorPreprorationGrouper ? (
              <p>
                {t(
                  "preprorationParameterization.preprorationParameterization.tabs.errorDownloadingInfo"
                )}
              </p>
            ) : rows.length > 0 ? (
              <VirtualizatedTable
                columns={columns}
                rows={rows}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={35}
                tableHeight={"65vh"}
                hideFooter={true}
                toolbar={true}
                columnsButton={true}
                filterButton={true}
                densitySelector={false}
                exportButton={false}
                setSelectedRows={handleSelectedRows}
                isPreproration={true}
              />
            ) : (
              <Box
                sx={{
                  width: "95%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "700", color: "var(--text-info)" }}
                >
                  {t(
                    "preprorationParameterization.preprorationParameterization.tabs.noExpensesToPreprorate"
                  )}{" "}
                  {tabValue}.
                </Typography>
              </Box>
            )}
          </Stack>
          <Dialog
            title={t(
              "preprorationParameterization.preprorationParameterization.criteriaOutline"
            )}
            open={openEsquemaDialog}
            handleClose={closeDialog}
            maxWidth="xl"
          >
            <Stack sx={{ margin: "0 15px 5px 15px" }}>
              <ColorTabs
                value={schemaTabValue}
                handleChange={handleChangeSchemaTabValue}
                tabs={schemaTabs}
                marginBottom={"20px"}
              />
              {schemaTabValue ===
                t(
                  "preprorationParameterization.preprorationParameterization.schemaTabs.selectSchema"
                ) && (
                <SelectSchemaContent
                  schemaSelectRef={schemaSelectRef}
                  schemaSelectedOptions={schemaSelectedOptions}
                  setSchemaSelectedOptions={setSchemaSelectedOptions}
                  schemasOptions={schemasOptions}
                  schemaSelectOptions={dataSchemas}
                  schemaOptionsChangeHandler={schemaOptionsChangeHandler}
                  destinyType={tabValue}
                  handleClose={closeDialog}
                  handleApplySchemas={handleApplySchemas}
                  selectedRows={selectedRows}
                />
              )}
              {schemaTabValue ===
                t(
                  "preprorationParameterization.preprorationParameterization.schemaTabs.createSchema"
                ) && (
                <NewSchemaContent
                  inputRef={inputSchemaNameRef}
                  handleClose={closeDialog}
                  handleCreateSchema={handleCreateSchema}
                  dataSchemas={dataSchemas}
                  destinityCreatableSelectRef={destinityCreatableSelectRef}
                  handleChangeDestinityCreatableSelect={
                    handleChangeDestinityCreatableSelect
                  }
                  setCreatableDestinityValueSelected={
                    setCreatableDestinityValueSelected
                  }
                  setSchemaName={setSchemaName}
                  schemaName={schemaName}
                  destinitySelectOptions={destinitySelectOptions}
                  tab={tabValue}
                  criteriaSelectRef={criteriaSelectRef}
                  criteriaSelectedOptionsChangeHandler={
                    criteriaSelectedOptionsChangeHandler
                  }
                  criteriaSelectedOption={criteriaSelectedOption}
                  criteriaOptions={criteriaOptions(criteria)}
                  selectedCriteriaTypeChangeHandler={
                    selectedCriteriaTypeChangeHandler
                  }
                  selectedCriteriaType={selectedCriteriaType}
                  percentageRef={percentageRef}
                  percentage={percentage}
                  setPercentage={setPercentage}
                  setNewSchemaRows={setNewSchemaRows}
                  newSchemaRows={newSchemaRows}
                  creatableDestinityValueSelected={
                    creatableDestinityValueSelected
                  }
                />
              )}
            </Stack>
          </Dialog>
          {dataSchemas && selectedSchemaInfo && (
            <SchemaInfoModal
              open={openEsquemaInfoModal}
              handleClose={handleToggleEsquemaInfoModal}
              schema={selectedSchemaInfo}
            />
          )}
          <CalculateConfirmationModal
            open={openCalculateConfirmationModal}
            handleClose={handleToggleCalculateConfirmationModal}
            handleAccept={handlePreprorationCalculate}
            message={t(
              "preprorationParameterization.preprorationParameterization.startCalculation"
            )}
            title={t(
              "preprorationParameterization.preprorationParameterization.resetCalculation"
            )}
          />
          <ResetConfirmationModal
            open={openResetConfirmationModal}
            handleClose={handleToggleResetConfirmationModal}
            handleAccept={handleReverseCalculation}
            message={t(
              "preprorationParameterization.preprorationParameterization.resetCalculation"
            )}
            title={t(
              "preprorationParameterization.preprorationParameterization.confirmReset"
            )}
          />
        </Stack>
        <PeriodsFilter
          openPeriodsDialog={openPeriodsDialog}
          handleTogglePeriodsDialog={handleClosePeriodsModal}
        />
      </ComponentLayout>
    </>
  );
};

export default PreprorationParameterization;
