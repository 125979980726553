/**
 * Obtiene una etiqueta de coincidencia de archivos en función del código proporcionado.
 *
 * @param {object} info - La información relacionada con la coincidencia de archivos.
 * @param {function} t - Una función de traducción que se utiliza para obtener texto localizado.
 * @returns {string} - La etiqueta descriptiva de la coincidencia de archivos.
 */

export const getMatchLabelByCode = (info: any, t: any) => {
  return info?.tipo_match_codigo === 1
    ? `${t(
        "validations.configValidations.textIndicatorMatchSelect.percentaje"
      )} ${info?.izquierda.archivo.label} ${t(
        "validations.configValidations.textIndicatorMatchSelect.in"
      )} ${info?.derecha.archivo.label}`
    : `${t(
        "validations.configValidations.textIndicatorMatchSelect.percentaje"
      )} ${info?.derecha.archivo.label} ${t(
        "validations.configValidations.textIndicatorMatchSelect.in"
      )} ${info?.izquierda.archivo.label}`;
};


export const isNameDuplicated = (data: any[], name: string) => {
  return (
    !!data &&
    !!data?.find(
      (element: any) =>
        element.nombre.toLowerCase().trim() === name.toLowerCase().trim()
    )
  );
};