import { getPeriodsLabelsFromPeriodsIds } from "../viewDashboardNew/utils";
import { useEffect, useState } from "react";
import { useViewDashboard } from "../../context/ViewDashboardProvider";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
interface ScatterPlotChartProps {
  results: any;
}

const ScatterPlotChart = ({ results }: ScatterPlotChartProps) => {
  const {
    tempFilters,
    setTempFilters,
    setFilters,
    selectedPeriods,
    dataGetAnalysisPeriod,
  } = useViewDashboard();

  const [clickedPoint, setClickedPoint] = useState<any | null>(null);

  const handlePointClick = (event: any) => {
    setClickedPoint({
      valor: event.point.nombre,
      columna_valores: results?.columna_valores,
    });
  };

  useEffect(() => {
    if (clickedPoint) {
      handleAddFilter();
    }
  }, [clickedPoint]);

  const handleAddFilter = () => {
    if (!clickedPoint.columna_valores || !results || !results.columna_valores)
      return;
    const newFilter = {
      id: clickedPoint.columna_valores.value,
      column: clickedPoint.columna_valores,
      values: [{ value: clickedPoint.valor, label: clickedPoint.valor }],
    };

    const updatedFilters = tempFilters
      ? [
          ...tempFilters.filter(
            (filter) => filter.column.value !== newFilter.id
          ),
          newFilter,
        ]
      : [newFilter];

    setTempFilters(updatedFilters);
    const filter = {} as any;
    updatedFilters?.forEach((option: any) => {
      filter[option.column.label] = option.values.map((value) => value.value);
    });
    const periodsLabels = getPeriodsLabelsFromPeriodsIds(
      selectedPeriods,
      dataGetAnalysisPeriod
    );
    filter["PERIODO"] = periodsLabels ? periodsLabels : undefined;
    setFilters(filter);
  };

  const linea_cero = results?.lineas_auxiliares?.linea_cero
    ? {
        color: results?.lineas_auxiliares?.linea_cero.color,
        width: 2,
        value: 0,
        zIndex: 5,
        label: {
          text: "Línea 0",
          align: "left",
        },
      }
    : undefined;

  const promedio_eje_x = results?.lineas_auxiliares?.linea_promedio_x
    ? {
        color: results?.lineas_auxiliares?.linea_promedio_x.color,
        width: 2,
        value: results?.lineas_auxiliares?.linea_promedio_x.valor,
        zIndex: 5,
        label: {
          text: "Promedio Eje X",
          align: "left",
        },
      }
    : undefined;

  const promedio_eje_y = results?.lineas_auxiliares?.linea_promedio_y
    ? {
        color: results?.lineas_auxiliares?.linea_promedio_y.color,
        width: 2,
        value: results?.lineas_auxiliares?.linea_promedio_y.valor,
        zIndex: 5,
        label: {
          text: "Promedio Eje Y",
          align: "center",
        },
      }
    : undefined;

  const options = {
    chart: {
      type: "scatter",
      height: "auto",
      zoomType: "xy",
      style: {
        height: "auto",
      },
    },
    accessibility: {
      enabled: false,
    },
    series: results?.series,
    title: {
      text: results?.titulo_grafico,
      style: {
        color: "var(--text-main)",
        fontSize: "18px",
        fontWeight: "bold",
        textShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      },
    },
    xAxis: {
      title: {
        text: results?.nombre_eje_x,
        labels: {
          format: "{value}",
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      plotLines: promedio_eje_x ? [promedio_eje_x] : [],
    },
    yAxis: [
      {
        title: {
          text: results?.nombre_eje_y,
        },
        labels: {
          format: "{value}",
        },
        showFirstLabel: false,
        plotLines:
          linea_cero && promedio_eje_y
            ? [linea_cero, promedio_eje_y]
            : linea_cero
            ? [linea_cero]
            : promedio_eje_y
            ? [promedio_eje_y]
            : [],
      },
    ],
    legend: {
      enabled: true,
    },
    tooltip: {
      pointFormat: results?.columna_valores
        ? `${results?.columna_valores?.label}: {point.nombre} <br /> x: {point.x} <br/> y: {point.y} <br /> 
          Click para filtrar por ${results?.columna_valores?.label}: {point.nombre}`
        : `x: {point.x} <br/> y: {point.y}`,
    },
    plotOptions: {
      series: {
        cursor: results?.columna_valores ? "pointer" : undefined,
      },
      scatter: {
        point: {
          events: {
            click: handlePointClick,
          },
        },
        marker: {
          radius: 2.5,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",

              style: {
                cursor: "pointer",
              },
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: true,
            },
          },
        },
        jitter: {
          x: 0.005,
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ScatterPlotChart;
