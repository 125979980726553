import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetCompaniesAdmin } from "../../company/api";
import AddCriteriaButton from "../../criteriaManagement/AddCriteriaButton";
import BasicGrid from "../../basicGrid/BasicGrid";
import CompanyCard from "./CompanyCard";
import CompanyForm from "./CompanyForm";
import React from "react";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import {
  active_inactive_typography_styles,
  mb_20_styles,
  no_cards_error_typography_styles,
  no_elements_box_styles,
} from "../../../styles/app-styles";
interface CompaniesViewProps {
  setConfigOpen: React.Dispatch<React.SetStateAction<boolean>>;
  configOpen: boolean;
}

const CompaniesView = ({ setConfigOpen, configOpen }: CompaniesViewProps) => {
  const { t } = useLanguage();
  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "COPY" | "">(
    ""
  );

  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [company, setCompany] = useState<any>();
  const {
    data: dataCompanies,
    isLoading: isLoadingAllCompanies,
    isSuccess: isSuccesCompanies,
    refetch: refetchCompanies,
  } = useGetCompaniesAdmin();

  useEffect(() => {
    if (dataCompanies) {
      setFilteredData(dataCompanies);
    }
  }, [dataCompanies]);

  const handleAdd = () => {
    setConfigMode("CREATE");
    setConfigOpen(true);
  };

  const handleFinish = () => {
    setConfigOpen(false);
    refetchCompanies();
    setConfigMode("");
  };

  const handleEdit = (e: any, id: number) => {
    setConfigMode("EDIT");
    setCompany(dataCompanies?.find((company: any) => company.id === id));
    setConfigOpen(true);
  };

  const handleCopy = (e: any, id: number) => {
    setConfigMode("COPY");
    setCompany(dataCompanies?.find((company: any) => company.id === id));
    setConfigOpen(true);
  };

  const filterData = (value: string) => {
    return dataCompanies?.filter((company: any) => {
      return (
        company.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
        company.nombre_visual?.includes(value?.toLowerCase())
      );
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserValue(e.target.value);
    if (e.target.value) {
      setFilteredData(filterData(e.target.value));
    } else {
      setFilteredData(dataCompanies);
    }
  };

  const isData = isSuccesCompanies && !isLoadingAllCompanies && filteredData;

  return (
    <>
      <SimpleBackdrop
        open={isLoadingAllCompanies}
        message={t("administratorPanel.companies.loadingCompanies")}
      />
      {!configOpen ? (
        <Stack spacing={2} sx={{ width: "100%", marginTop: "0px !important" }}>
          <SearchInput
            value={searchUserValue}
            handleChange={handleSearch}
            placeholder={t(
              "administratorPanel.companies.searchCompanyPlaceholder"
            )}
          />
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.companies.activeCompanies")}
          </Typography>
          <Stack sx={mb_20_styles}>
            {isData &&
            filteredData.filter((company: any) => company.habilitada).length ===
              0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.companies.noActiveCompanies")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <BasicGrid
                  data={filteredData.filter(
                    (company: any) => company.habilitada
                  )}
                  Card={CompanyCard}
                  showInfo={false}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  refetchData={refetchCompanies}
                />
              )
            )}
          </Stack>
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.companies.inactiveCompanies")}
          </Typography>

          <Stack sx={mb_20_styles}>
            {isData &&
            filteredData.filter((company: any) => !company.habilitada)
              .length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.companies.noInactiveCompanies")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <BasicGrid
                  data={filteredData.filter(
                    (company: any) => !company.habilitada
                  )}
                  Card={CompanyCard}
                  showInfo={false}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  refetchData={refetchCompanies}
                />
              )
            )}
          </Stack>
          <AddCriteriaButton handleClick={handleAdd} />
        </Stack>
      ) : (
        <CompanyForm
          configMode={configMode}
          company={company}
          handleFinish={handleFinish}
        />
      )}
    </>
  );
};

export default CompaniesView;
