import { useState } from "react";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../components/configCriteria/types";

declare type OnChange = (
  newValue: SingleValue<IdOption>,
  actionMeta: ActionMeta<IdOption>
) => void;

const useSingleSelect = (
  onCustomOnChange?: (newValue?) => void,
  defaultValue?: IdOption
): [
  SingleValue<IdOption> | undefined,
  React.Dispatch<React.SetStateAction<SingleValue<IdOption> | undefined>>,
  OnChange
] => {
  const [selectedOptions, setSelectedOptions] =
    useState<SingleValue<IdOption> | undefined>(defaultValue);

  const selectedOptionsChangeHandler: OnChange = (
    newValue: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    switch (action.action) {
      case "select-option":
        setSelectedOptions(newValue);
        onCustomOnChange && onCustomOnChange(newValue);
        break;
      case "clear":
        setSelectedOptions(undefined);
        onCustomOnChange && onCustomOnChange();
        break;
      default:
        break;
    }
  };

  return [selectedOptions, setSelectedOptions, selectedOptionsChangeHandler];
};

export default useSingleSelect;
