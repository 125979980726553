import { Box, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import argentinaFlag from "../../../assets/languageFlags/argentina.png"
import englishFlag from "../../../assets/languageFlags/english.png"
import portugueseFlag from "../../../assets/languageFlags/portuguese.png"
import useLanguage from "../../../context/LanguageProvider";

const LanguageWrapper = () => {
    const { language, setLanguage, t } = useLanguage();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const settings = ['ESPAÑOL', 'ENGLISH', 'PORTUGUÊS'];
    const [languageView, setLanguageView] = useState(language);
    const [languageViewIcon, setLanguageViewIcon] = useState(<img style={{ width: "25px" }} src={argentinaFlag} />)

    const handleSetting = (setting: string) => {
        switch (setting) {
            case 'ESPAÑOL':
                setLanguage('ESPAÑOL')
                setLanguageViewIcon(<img style={{ width: "25px", borderRadius: "50%" }} src={argentinaFlag} />)
                break;
            case "ENGLISH":
                setLanguage("ENGLISH")
                setLanguageViewIcon(<img style={{ width: "25px" }} src={englishFlag} />)
                break;
            case "PORTUGUÊS":
                setLanguage("PORTUGUÊS")
                setLanguageViewIcon(<img style={{ width: "25px" }} src={portugueseFlag} />)
                break;
            default:
                break;
        }
    };

    useEffect(()=>{
        setLanguageView(language)
        handleSetting(language)
    },[language])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t("language.languages")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            borderRadius: "30px",
                            border: "1px solid var(--cream)",
                            color: "var(--cream)",
                            display: "flex",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "uppercase",
                            height: "40px",
                            letterSpacing: "2px",
                            margin: "10px",
                            texTransform: "uppercase",
                            transition: "all 0.4s",
                            padding: "0 10px",
                            gap: "5px",
                            "&:hover": {
                                border: "none",
                                color: "var(--text-main)",
                                backgroundColor: "var(--cream)",
                            },
                        }}
                    >
                        {languageViewIcon} {languageView}
                        <ExpandMoreIcon />
                    </Stack>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography
                            textAlign="center"
                            sx={{
                                color:
                                    setting !== languageView
                                        ? "var(--text-main)"
                                        : "var(--text-disabled)",
                            }}
                            onClick={() => handleSetting(setting)}
                        >
                            {setting}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default LanguageWrapper;
