import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  PRORRATEO_GASTOS_URL,
  GET_BUCKETS_URL,
  GET_PRORRATEO_RESULTADOS,
  GET_PRORRATION_CRITERIA_URL,
  GET_TASKS_URL,
} from "../../api/axios";
import { IncomingBucketStageOption } from "./types";

export const useGetGastosProrrateo = () => {
  const axiosPrivate = useAxiosPrivate();

  const getProrrateoGastos = async () => {
    const response = await axiosPrivate.get(PRORRATEO_GASTOS_URL);
    return response.data;
  };

  const info: UseQueryResult<[], Error> = useQuery(
    ["getProrrateoGastos"],
    getProrrateoGastos,
    { refetchOnWindowFocus: false }
  );
  return info;
};

export const useGetBuckets = () => {
  const axiosPrivate = useAxiosPrivate();

  const getBuckets = async () => {
    const response = await axiosPrivate.get(`${GET_BUCKETS_URL}?etapas=true`);
    return response.data.filter(
      (bucket: any) => bucket.etapas && bucket.etapas.length
    );
  };
  const info: UseQueryResult<IncomingBucketStageOption[], Error> = useQuery(
    ["getBuckets"],
    getBuckets,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  return info;
};

export const getProrationResults = async (
  axiosPrivate: any,
  controller: any,
  bucket: string | number,
  stage: number,
  handleGetResults: (status: number, data: any) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setIsLoading(true);
    const response = await axiosPrivate.get(
      `${GET_PRORRATEO_RESULTADOS}?bucket=${bucket}&etapa=${stage}&mostrar_detalle=`,
      {
        signal: controller.signal,
      }
    );
    handleGetResults(response.status, response.data);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message !== "canceled") {
        setIsLoading && setIsLoading(false);
        handleGetResults(400, "No fue posible obtener la información");
      }
    }
  }
};

export const useGetProrrationCriteria = () => {
  const axiosPrivate = useAxiosPrivate();
  const getCriteria = async () => {
    const response = await axiosPrivate.get(GET_PRORRATION_CRITERIA_URL);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getProrrationCriteria"],
    getCriteria
  );
  return info;
};

export const useGetProrrationTasksModel = (pendingTasks: boolean) => {
  const axiosPrivate = useAxiosPrivate();

  const getProrrationTasks = async () => {
    const response = pendingTasks
      ? await axiosPrivate.get(
          `${GET_TASKS_URL}/?tipo=prorrateo&validacion_estado=true`
        )
      : await axiosPrivate.get(`${GET_TASKS_URL}/?tipo=prorrateo`);
    return response.data;
  };
  const info: UseQueryResult<[], Error> = useQuery(
    ["getTasksForModel"],
    getProrrationTasks,
    {
      refetchInterval: pendingTasks ? 1000 : false,
      refetchIntervalInBackground: true,
    }
  );
  return info;
};
