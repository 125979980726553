import { ConfirmationModal } from "../../dialog";
import { filterDataByName } from "../../dataModel/utils";
import { MARKET_BASKET_URL, SINGLE_MARKET_BASKET_URL } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import BasicGrid from "../../basicGrid/BasicGrid";
import ComponentLayout from "../../layout/ComponentLayout";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import FixedAddButton from "../../buttons/FixedAddButton";
import MarketBasketCard from "./MarketBasketCard";
import NoCardsError from "../../error/NoCardsError";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoMarketBasketModal from "./ViewInfoMarketBasketModal";

const MarketBasketAnalysisManagement = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [idToDelete, setIdToDelete] = useState<
    number | undefined
  >();
  const [
    openDeleteConfirmationModal,
    toggleDeleteConfirmationModal,
  ] = useDialog();
  const [openViewModal, toggleViewModal] = useDialog();

  /********************************* LLAMADAS PARA PEDIR INFO *****************************/
  const {
    isLoading: isLoadingGetAnalysisViews,
    data: dataAnalysisViews,
    refetch: refetchGetAnalysisViews,
  } = useApiQuery(MARKET_BASKET_URL, false, t("dataModel.getAnalysis.error"));

  const onSuccessAnalysisInfo = (data: any) => {
    localStorage.setItem("Analisis Market Basket Info", JSON.stringify(data));
    toggleInfoModal();
  };

  const {
    data: dataMarketBasket,
    isLoading: isLoadingGetMarketBasket,
    callApi: getMarketBasket,
  } = useApi(
    SINGLE_MARKET_BASKET_URL,
    "GET",
    t("marketBasket.admin.getAnalysis.codes"),
    undefined,
    onSuccessAnalysisInfo,
    undefined,
    false
  );

  /*************************** OBTENER INFO SEGÚN TABVALUE ************************/

  const onSuccessDelete = () => {
    refetchGetAnalysisViews();
    setFilteredData([]);
  };

  const {
    isLoading: isLoadingDelete,
    callApi: deleteById,
  } = useApi(
      `${SINGLE_MARKET_BASKET_URL(idToDelete)}`,
    "DELETE",
    t("marketBasket.admin.deleteValidation.codes"),
    undefined,
    onSuccessDelete
  );

  /*************************** FUNCIONES DE LAS CARDS ***********************************/

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataAnalysisViews, e.target.value, setFilteredData)
  };

  const handleEdit = (e: any, id: number) => {
    localStorage.setItem("configMode", "EDIT");
    localStorage.setItem("Analisis Market Basket Info", id.toString());
    navigate("/market-basket/configuracion-analisis");
  };

  const handleCopy = (e: any, id: number) => {
    localStorage.setItem("configMode", "COPY");
    localStorage.setItem("Analisis Market Basket Info", id.toString());
    navigate("/market-basket/configuracion-analisis");
  };

  const handleInfo = (e: any, id: number) => {
    getMarketBasket(SINGLE_MARKET_BASKET_URL(id.toString()));
  };

  const handleDelete = () => {
    deleteById();
    toggleDeleteConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setIdToDelete(id);
    toggleDeleteConfirmationModal();
  };

  const handleCreate = () => {
    localStorage.setItem("configMode", "CREATE");
    navigate("/market-basket/configuracion-analisis");
  };

  const toggleInfoModal = () => {
    toggleViewModal();
  };

  return (
    <ComponentLayout
      title={t("marketBasket.admin.title")}
      icon={<DataSaverOnIcon />}
    >
      <SimpleBackdrop
        open={isLoadingGetAnalysisViews}
        message={t("general.loading")}
      />
      <SimpleBackdrop
        open={isLoadingDelete}
        message={t("marketBasket.admin.delete.loading")}
      />
      <SimpleBackdrop
        open={isLoadingGetMarketBasket}
        message={t("marketBasket.admin.getAnalysis.loading")}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder={t("general.searchByName")}
        />
      </Stack>
      {filteredData?.length || dataAnalysisViews?.length ? (
        <BasicGrid
          data={
            filteredData && filteredData.length
              ? filteredData
              : dataAnalysisViews
          }
          Card={MarketBasketCard}
          handleEdit={userRolData && userRolData.market_basket.visualizacion_y_edicion && handleEdit}
          handleCopy={userRolData && userRolData.market_basket.visualizacion_y_edicion && handleCopy}
          handleInfo={handleInfo}
          handleDelete={userRolData && userRolData.market_basket.visualizacion_y_edicion && handleClickOnDelete}
          showInfo={true}      
        />
      ) : (
        !isLoadingGetAnalysisViews && <NoCardsError height={undefined}/>
      )}
      {(userRolData && (userRolData.market_basket.visualizacion_y_creacion ||
        userRolData.market_basket.visualizacion_y_edicion)) && (
        <FixedAddButton handleClick={handleCreate} />
      )}
      <ConfirmationModal
        open={openDeleteConfirmationModal}
        handleClose={toggleDeleteConfirmationModal}
        handleAccept={handleDelete}
        title={t(
          "marketBasket.admin.delete.confirmationModal.title"
        )}
        message={t(
          "marketBasket.admin.delete.confirmationModal.message"
        )}
        customMessage={true}
      />
      <ViewInfoMarketBasketModal
        info={dataMarketBasket}
        open={openViewModal}
        handleClose={toggleInfoModal}
        title={dataMarketBasket?.nombre}
      />
    </ComponentLayout>
  );
};

export default MarketBasketAnalysisManagement