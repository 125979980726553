import { Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Typography from "@mui/material/Typography";

const UploadedFile = ({ dataGetParams }) => {
  return (
    <FormSelectLayout title={"Archivo cargado"} required={false}>
      <Stack direction="row" spacing={2} width={"100%"} sx={{ mt: "10px" }}>
        <CheckIcon sx={{ color: "var(--icon-secondary)" }} />
        <Typography sx={{ color: "var(--text-secondary)", fontWeight: "bold" }}>
          {dataGetParams.archivo.name}.{dataGetParams.archivo.type}
        </Typography>
      </Stack>
    </FormSelectLayout>
  );
};

export default UploadedFile;
