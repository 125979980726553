import { divider_measure_card_styles, list_measure_card_styles, select_measure_card_container_styles } from "../../../../styles/app-styles";
import { Divider, List, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ListItemComponent from "./ListItemComponent";
import useLanguage from "../../../../context/LanguageProvider";
interface SelectMeasureCardProps {
  elements: any[];
  title: string;
  handleAddElementToFormula: (id: number, name: string, tipo: string) => void;
  medidaInLast: boolean;
  formula: any;
}

const SelectMeasureCard = ({
  handleAddElementToFormula,
  elements,
  title,
  medidaInLast,
  formula,
}: SelectMeasureCardProps) => {
  const { t } = useLanguage();
  const [filterByName, setFilterByName] = useState("");

  const handleFilterByName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByName(e.target.value);
  };

  const filteredElements = elements
    ? elements.filter((el: any) =>
        el.nombre.toLowerCase().includes(filterByName.toLowerCase())
      )
    : [];

  return (
    <Stack sx={select_measure_card_container_styles}>
      <Typography variant="h5">{title}</Typography>
      <Divider sx={divider_measure_card_styles} />
      <TextField
        type="text"
        placeholder={t('general.findByName')}
        variant="outlined"
        fullWidth
        value={filterByName}
        onChange={handleFilterByName}
      />
      <List sx={list_measure_card_styles}>   
          <ListItemComponent
            elementsList={filteredElements}
            handleAddElementToFormula={handleAddElementToFormula}
            medidaInLast={medidaInLast}
            formula={formula}
          />    
      </List>
    </Stack>
  );
};

export default SelectMeasureCard;
