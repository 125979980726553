import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

interface AddCriteriaButtonProps {
  handleClick: () => void;
}

const FixedAddButton = ({ handleClick }: AddCriteriaButtonProps) => {
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        position: "fixed",
        bottom: "35px",
        right: "35px",
        borderRadius: "100%",
        width: "50px",
        height: "64px",
        backgroundColor: "var(--bg-success)",
        boxShadow: "5px 5px 10px var(--text-info)",
        transition: "all 0.4s",
        ":hover": {
          scale: "1.1",
          backgroundColor: "var(--bg-success)",
          boxShadow: "5px 5px 10px var(--text-info)",
        },
        ":active": { scale: "0.95" },
      }}
    >
      <AddIcon sx={{ fontSize: "46px", fontWeight: "bold" }} />
    </Button>
  );
};

export default FixedAddButton;
