import { checkArraysOverlap, handleRefetchRowsData } from "../../utils";
import { useParameterization } from "../../../../hooks/useParameterization";
import ModelTable from "./ModelTable";

const ExpensesTable = () => {
  const {
    parameterizationState,
    parameterizationDispatch,
    errorRows,
    rows,
    getProrationResults,
  } = useParameterization();

  const rowsResult =
    rows &&
    checkArraysOverlap(rows?.resultado?.map((row: any) => row.hierarchy)) ===
      false &&
    rows.resultado;

  const refetchRowsData = () => {
    handleRefetchRowsData(
      parameterizationState,
      parameterizationDispatch,
      getProrationResults
    );
  };

  return (
    <ModelTable
      error={errorRows}
      rowsResult={rowsResult}
      total={rows && rows.monto_total}
      refetchRowsData={refetchRowsData}
    />
  );
};

export default ExpensesTable;
