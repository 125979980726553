import { Period, Periods } from "./PeriodsFilter";

type FilterType =
  | "ID"
  | "FECHA"
  | "PRORRATEADO"
  | "PREPRORRATEADO"
  | "EXPORTABLE"
  | "POSEE_GASTOS"
  | "HABILITADO";

export const filterPeriods = (
  periods: Periods,
  filter_type: FilterType,
  condition: string | number | boolean
) => {
  const filteredPeriods = periods.filter((period: Period) => {
    switch (filter_type) {
      case "ID":
        return period.id === condition;
      case "FECHA":
        return period.fecha === condition;
      case "PRORRATEADO":
        return period.prorrateado === condition;
      case "PREPRORRATEADO":
        return period.preprorrateado === condition;
      case "EXPORTABLE":
        return period.exportable === condition;
      case "POSEE_GASTOS":
        return period.posee_gastos === condition;
      case "HABILITADO":
        return period.habilitado === condition;
      default:
        return false;
    }
  });
  return filteredPeriods;
};

//Función para extraer el año de un período
export const getYear = (period: Period) => {
  return period?.fecha?.split("-")[1];
};

//Función para detectar si un año está incluido dentro de un array de años
export const isYearInArray = (year: string, years: string[]) => {
  return years.includes(year);
};

//Función que recibe como parametro un año en string, y un array de Period y devuelve los ids de los Period que contienen ese año
export const getIdPeriodsByYear: (year: string, periods: Periods) => number[] =
  (year: string, periods: Periods) => {
    return periods
      .filter((period: Period) => {
        return getYear(period) === year;
      })
      .map((period: Period) => period.id);
  };

  //Función que recibe dos parámetros para ordenar, el cual ordena las fechas de menor a mayor
export const sortByDate = (a: any, b: any) => {
  const [mesA, añoA] = (a && a.fecha) ? a.fecha.split('-') : ['', ''];
  const [mesB, añoB] = (b && b.fecha) ? b.fecha.split('-') : ['', ''];

  // Compara primero por año y luego por mes
  if (añoA < añoB) {
    return -1;
  }
  if (añoA > añoB) {
    return 1;
  }
  // Si los años son iguales, compara por mes
  if (mesA < mesB) {
    return -1;
  }
  if (mesA > mesB) {
    return 1;
  }
  return 0;
}

//Función que recibe como parametro un array de fechas, y devuelve un array con solo aquellos items con gastos.
export const filterDatesWithExpense = (array: any) => {
  return array.filter((item: any) => item.posee_gastos)
}

//Función que recibe un array de Periods y devuelve un array con los year sin repetir
export const getTotalPeriods = (periods: Periods | undefined) => {
  const years: string[] = [];
  periods?.forEach((period: Period) => {
    const year = getYear(period);
    if (!isYearInArray(year, years)) {
      years.push(year);
    }
  });
  return years;
};

//Función que elimina números repetidos de un array
export const removeDuplicates = (array: number[]) => {
  return array.filter((a, b) => array.indexOf(a) === b);
};

//función para eliminar strings repetidos en un array
export const removeDuplicatesString = (array: string[]) => {
  return array.filter((a, b) => array.indexOf(a) === b);
};

//Función que recibe un array de id de Periods y devuelve el array de objetos Period completo
export const getPeriodsById = (periods: Periods, ids: number[]) => {
  return periods.filter((period: Period) => {
    return ids.includes(period.id);
  });
};

//obtiene el array getIdPeriodsByYear y evalúa si está contenido en otro array
export const isIdPeriodsByYearInArray: (
  year: string,
  dates: Periods,
  selectedYears: number[]
) => boolean = (year: string, dates: Periods, selectedYears: number[]) => {
  const idPeriodsByYear = getIdPeriodsByYear(year, dates);
  return idPeriodsByYear.every((id) => selectedYears.includes(id));
};

//Recorrer un array de elementos y crear un array que repita cada elemento dos veces
export const repeatArray = (array: string[]) => {
  const repeatedArray: string[] = [];
  array.forEach((element) => {
    repeatedArray.push(element);
    repeatedArray.push(element);
  });
  return repeatedArray;
};

//Función que recibe como parametro un año en string, y un array de Period y devuelve los Period que contienen ese año
export const getPeriodsByYear: (year: string, periods: Periods) => Period[] = (
  year: string,
  periods: Periods
) => {
  return periods.filter((period: Period) => {
    return period.fecha.split("-")[1] === year;
  });
};

//Función que de un array de Periodos devuelve el array de los ids de esos Periodos
export const getPeriodsId = (periods: Periods) => {
  return periods.map((period: Period) => period.id);
}

