import { useState } from "react";
import Stack from "@mui/material/Stack";
import { max_level_radiobutton_box_styles } from "../../../../styles/app-styles";

interface RadioButtonRowProps {
  id: string | undefined;
  selectedOption: string | undefined;
  onChangeInput: (e: any) => void;
  groupName: string;
  labelText: string;
  disabledInput?: boolean;
}

const RadioButtonRow = ({
  id,
  selectedOption,
  onChangeInput,
  groupName,
  labelText,
  disabledInput,
}: RadioButtonRowProps) => {
  const [checked, setChecked] = useState(false);

  const handleChecked = (e) => {
    setChecked((prev) => !prev);
    onChangeInput(e);
  };

  return (
    <Stack sx={max_level_radiobutton_box_styles}>
      <label
        htmlFor={id}
        style={{
          color: "var(--text-main)",
          fontWeight: 500,          
        }}
      >
        <input
          type="radio"
          className="cc_checkbox"
          name={groupName}
          id={id}
          onChange={(e) => handleChecked(e)}
          checked={selectedOption === id}
          disabled={disabledInput ? disabledInput : false}
        />
        {labelText}
      </label>
    </Stack>
  );
};

export default RadioButtonRow;
