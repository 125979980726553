import { error_no_data_styles } from "../../styles/app-styles";
import Stack from "@mui/material/Stack";
import useLanguage from "../../context/LanguageProvider";

const NoDataError = () => {
  const { t } = useLanguage();

  return (
    <Stack
      sx={error_no_data_styles}
    >
      <p>{t("dashboard.noDataError")}</p>
    </Stack>
  );
};

export default NoDataError;
