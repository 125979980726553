import { Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react'
import useLanguage from '../../../../../../context/LanguageProvider';
import CustomToolbar from '../../../../../customToolbar/CustomToolbar';
import { getColumnsCanceledRoutes } from '../getColumnsCanceledRoutes';
interface ModifiedRouteInfoModalTableProps {
    data: any,
    selectedRows?: any;
    setSelectedRows?: any;
    width?: string,
    informationsColumns?: any
}

const ModifiedRouteInfoModalTable = ({ selectedRows, setSelectedRows, data, width, informationsColumns }: ModifiedRouteInfoModalTableProps) => {

    const [rows, setRows] = useState<any>();
    const { t } = useLanguage();
    useEffect(() => {
        if (data && data.length > 0) {
            const info = data?.flat().map(item => {
                const { filtros, ...resto } = item
                const filters = filtros ? filtros.reduce((acc, filtro) => {
                    acc[filtro.columna] = filtro.valor;
                    return acc;
                }, {}) : {};
                return {
                    ...resto,
                    ...filters,
                }
            })
            setRows(info)
        }
    }, [data])

    return (
        <>
            {
                rows && rows.length > 0 ?
                    <Stack sx={{
                        height: "280px",
                        '& .headerGroup--className': {
                            border: "1px solid white",
                            backgroundColor: 'var(--bg-main)',
                            display: 'flex',
                            width: "100%",
                            justifyContent: 'center',
                            alignItems: 'center'
                        }, '& .headerSubGroup--className': {
                            backgroundColor: 'var(--chart-22)',
                            border: "1px solid white",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        //Classname para alinear la tabla 
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        },
                        width: width ? width : "100%"
                    }
                    } >
                        <DataGridPro
                            rows={rows}
                            columns={getColumnsCanceledRoutes(rows, t, informationsColumns)}
                            rowHeight={35}
                            components={{
                                Toolbar: () => (
                                    <CustomToolbar
                                        columnsButton={false}
                                        filterButton={false}
                                        densitySelector={false}
                                        exportButton={false}
                                    />
                                ),
                            }}
                            disableColumnMenu={true}
                            hideFooter={true}
                            checkboxSelection={selectedRows ? true : false}
                            disableSelectionOnClick
                            sx={{
                                boxShadow: 3,
                                fontSize: "12px",
                                padding: "0 10px 10px 10px",
                                // checkbox background and checkbox color
                                "& .MuiDataGrid-columnHeadersInner": {
                                    backgroundColor: "var(--bg-secondary)",
                                },
                                "& .MuiDataGrid-columnHeadersInner span": {
                                    color: "white",
                                },
                            }}
                            selectionModel={selectedRows}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectedRows(newSelectionModel);
                            }}
                        />
                    </Stack > :
                    <></>}
        </>

    )
}

export default ModifiedRouteInfoModalTable