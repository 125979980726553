import { useRef, useState } from "react";

export default function DrilldownColumnSelect({ initialOption, children }) {
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const columnMultiDrilldownSelectRef = useRef(null);

  return children({
    selectedOption,
    setSelectedOption,
    columnMultiDrilldownSelectRef,
  });
}
