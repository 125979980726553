import "./styles.css";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { PeriodType, PreviewDataType } from "./types";
import { RolConfig } from "../administrator/data";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import {
  centered_container_styles,
  icon_box_styles,
  icon_table_styles,
  table_header_text_styles,
} from "../../styles/app-styles";

/**
 * @name transformDataManagement
 * @description Función que transforma los datos de la tabla archivos por período, o validaciones, de la vista de administración.
 * @param validaciones
 * @param dataColumnas
 * @param dataFilas
 * @param handlePreview
 * @param handleDownload
 * @memberof DataManagement
 * @category Data Management
 */

const transformDataManagement = (
  dataColumnas: any,
  dataFilas: any,
  handlePreview: (data: PreviewDataType) => void,
  handleDownload: (
    type: "FILE" | "PERIOD" | "COLUMN" | "ALL",
    data: PreviewDataType | PeriodType | string | number
  ) => void,
  handleConfirmDeleteModal: (data: string) => void,
  handleDeleteFile: (data: PreviewDataType) => void,
  userRolData: RolConfig,
  t?: any
) => {
  /**
   * @name arrayColumns
   * @description Genera un array que contiene las columnas (títulos) de la tabla. Con renderHeader se customiza el encabezado de cada columna.  Con renderCell se obtiene el valor de cada celda que cruza con la columna mapeada, y se devuelve, según el caso, un elemento JSX.  Para archivos que existen en un determinado período, se devuelve el icono de previsualización y descarga. En caso de no existir el archivo, se devuelve una cruz.  Para validaciones, en caso de existir la misma se devuelve una tild,e en caso de que no, una cruz.
   *
   */

  const arrayColumns = dataColumnas.map((columna: any) => ({
    field: columna.id.toString(),
    headerName: columna.nombre,
    headerClassName: "dm_header_column2",
    description: columna.nombre,
    minWidth: 100,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack sx={{ ...centered_container_styles, spacing: 1 }}>
        <Box sx={table_header_text_styles}>{columna.nombre}</Box>
        {userRolData && userRolData.administracion_de_datos.descargar_archivos && (
          <Tooltip
            title={`${t(
              "dataManagementTexts.transformDataManagement.downloadAllFilesFrom"
            )} ${columna.nombre}`}
          >
            <Stack
              sx={{
                height: "100%",
                justifyContent: "center",
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <DownloadIcon
                sx={icon_table_styles("white", "var(--icon-main)")}
                onClick={() => handleDownload("COLUMN", params.field)}
              />
            </Stack>
          </Tooltip>
        )}
      </Stack>
    ),
    renderCell: (params: any) =>
      params.value ? (
        <Stack sx={centered_container_styles}>
          {
            <>
              {params.value.dato.estado === "SUBIDO" && (
                <Tooltip title={t("Archivo en espera de ser procesado")}>
                  <Box sx={{ padding: "0 2px" }}>
                    <HourglassFullIcon
                      sx={icon_table_styles(
                        "var(--light-violet)",
                        "var(--light-violet)"
                      )}
                    />
                  </Box>
                </Tooltip>
              )}
              {params.value.dato.estado === "PROCESANDO" && (
                <Tooltip title={t("Procesando")}>
                  <Box
                    sx={{
                      padding: "0 2px",
                      "& span": {
                        width: "20px !important",
                        height: "20px !important",
                      },
                    }}
                  >
                    <CircularProgress
                      sx={icon_table_styles(
                        "var(--icon-secondary)",
                        "var(--icon-secondary)"
                      )}
                    />
                  </Box>
                </Tooltip>
              )}
              {params.value.dato.estado === "ERROR" && (
                <Tooltip
                  title={t("Hubo un error y el archivo no pudo ser procesado")}
                >
                  <Box sx={{padding: "0 2px"}}>
                    <SdCardAlertIcon
                      sx={icon_table_styles(
                        "var(--icon-error)",
                        "var(--icon-error)"
                      )}
                    />
                  </Box>
                </Tooltip>
              )}

              {params.value.dato.estado === "FINALIZADO" && (
                <>
                  <Tooltip
                    title={t(
                      "dataManagementTexts.transformDataManagement.preview"
                    )}
                  >
                    <Box sx={icon_box_styles}>
                      <FindInPageIcon
                        sx={icon_table_styles(
                          "var(--icon-secondary)",
                          "var(--light-violet)"
                        )}
                        onClick={() => handlePreview(params.value)}
                      />
                    </Box>
                  </Tooltip>

                  {userRolData &&
                    userRolData.administracion_de_datos.descargar_archivos && (
                      <Tooltip
                        title={t(
                          "dataManagementTexts.transformDataManagement.download"
                        )}
                      >
                        <Box sx={icon_box_styles}>
                          <DownloadIcon
                            sx={icon_table_styles(
                              "var(--icon-secondary)",
                              "var(--icon-main)"
                            )}
                            onClick={() => handleDownload("FILE", params.value)}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  {userRolData &&
                    userRolData.administracion_de_datos.eliminar_archivos && (
                      <Tooltip
                        title={t(
                          "dataManagementTexts.transformDataManagement.delete"
                        )}
                      >
                        <Box sx={icon_box_styles}>
                          <DeleteIcon
                            sx={icon_table_styles(
                              "var(--icon-secondary)",
                              "var(--icon-error)"
                            )}
                            onClick={() => handleDeleteFile(params.value)}
                          />
                        </Box>
                      </Tooltip>
                    )}
                </>
              )}
            </>
          }
        </Stack>
      ) : (
        <Tooltip
          title={t("dataManagementTexts.transformDataManagement.notFound")}
        >
          <Stack sx={centered_container_styles}>
            <Box sx={{ padding: "0 5px" }}>
              <CloseIcon sx={{ color: "var(--icon-error)" }} />
            </Box>
          </Stack>
        </Tooltip>
      ),
  }));

  /**
   * @name periodColumn
   * @description Genera la columna que contiene el período, ya que su valor es diferente para el resto de las columnas.

   */

  const periodColumn = {
    field: "PERIODO",
    headerName: "PERIODO",
    headerClassName: "dm_header_column2",
    description: "PERIODO",
    minWidth: 70,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack sx={{ ...centered_container_styles, spacing: 1 }}>
        <Box sx={table_header_text_styles}>{params.field}</Box>
        {userRolData && userRolData.administracion_de_datos.descargar_archivos && (
          <Tooltip
            title={t(
              "dataManagementTexts.transformDataManagement.downloadAllFiles"
            )}
          >
            <Stack
              sx={{
                height: "100%",
                justifyContent: "center",
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <DownloadIcon
                sx={icon_table_styles("white", "var(--icon-main)")}
                onClick={() => handleDownload("ALL", "")}
              />
            </Stack>
          </Tooltip>
        )}
      </Stack>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" justifyContent="center" alignItems="center">
        {params.value}
        {userRolData && userRolData.administracion_de_datos.descargar_archivos && (
          <Tooltip
            title={`${t(
              "dataManagementTexts.transformDataManagement.downloadAllPeriodFiles"
            )} ${params.value}`}
          >
            <Box sx={icon_box_styles}>
              <DownloadIcon
                sx={icon_table_styles(
                  "var(--icon-secondary)",
                  "var(--icon-main)"
                )}
                onClick={() =>
                  handleDownload("PERIOD", {
                    periodo: params.value,
                    periodo_id: params.id,
                  })
                }
              />
            </Box>
          </Tooltip>
        )}
        {userRolData && userRolData.administracion_de_datos.eliminar_archivos && (
          <Tooltip
            title={t(
              "dataManagementTexts.transformDataManagement.deletePeriod"
            )}
          >
            <Box sx={icon_box_styles}>
              <DeleteIcon
                sx={icon_table_styles(
                  "var(--icon-secondary)",
                  "var(--icon-error)"
                )}
                onClick={() => handleConfirmDeleteModal(params.id)}
              />
            </Box>
          </Tooltip>
        )}
      </Stack>
    ),
  };

  arrayColumns.unshift(periodColumn);

  /**
   * @name arrayRows
   * @description Genera un array que contiene las filas de la tabla.  Las mismas se cruzan con las columnas a través de un objeto (en este caso obj), cuya clave debe coincidir con la clave field de la columna, y el value es el valor que se pasa como parámetro al renderCell de arrayColumns. Este valor es luego utilizado para identificar el archivo que se está seleccionando en caso de querer previsualizarlo o descargarlo.
   * @memberof DataManagement
   * @category Data Management
   */

  const arrayRows = dataFilas
    .filter((fila: any) => fila.archivos.length !== 0)
    .map((fila: any) => {
      const obj: any = {};
      obj["id"] = fila.periodo_id;
      obj["PERIODO"] = fila.periodo;
      fila.archivos.map(
        (dato: any) =>
          (obj[dato.id] = {
            dato: dato,
            periodo: {
              periodo: fila.periodo,
              periodo_id: fila.periodo_id,
            },
          })
      );
      return obj;
    });

  /**
   * @name columns
   * @description Genera el array de todas las columnas que se renderizarán en la tabla, y es el valor de retorno del componente para las columnas.

   * @memberof DataManagement
   * @category Data Management
   */
  const columns: GridColDef[] = arrayColumns.map((col: any) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  /**
   * @name rows
   * @description Genera el array de todas las filas que se renderizarán en la tabla, y es el valor de retorno del componente para las filas.
   * @memberof DataManagement
   * @category Data Management
   */

  const rows = arrayRows.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};

export const transformData = transformDataManagement;
