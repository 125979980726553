import { Typography } from "@mui/material";
import { ConfirmationModal as DeleteIndicatorConfirmationModal } from "../dialog";
import { Tab, TabValue } from "../colorTabs/types";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import AddCriteriaButton from "../criteriaManagement/AddCriteriaButton";
import BasicGrid from "../basicGrid/BasicGrid";
import ColorTabs from "../colorTabs/ColorTabs";
import IndicatorCard from "./Cards/IndicatorCard";
import React from "react";
import SearchInput from "../forms/SearchInput";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";
import {
  GRAFICOS_ARCHIVOS,
  GRAFICO_ARCHIVO,
  INDICADORES_ARCHIVOS,
  INDICADOR_ARCHIVO,
  TABLAS_URL,
  TABLA_ARCHIVO,
} from "../../api/axios";
import { filterDataCharts, filterDataIndicators } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  active_inactive_text,
  cards_error_text,
  inactive_cards_text,
  non_active_container,
  non_active_text,
} from "../../styles/app-styles";
import useInfoContext from "../../hooks/useInfoContext";
import TableCard from "./Cards/TableCard";
import FileChartCard from "./Cards/FileChartCard";
import ViewInfoModal2 from "./viewInfoModal/ViewInfoModal2";
import ViewInfoModal from "./ViewInfoModal";

const IndicatorsManagement = () => {
  const { t, language } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("indicatorsManagementTexts.indicatorsManagement.tabs.indicators")
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [openViewInfoModal, toggleViewInfoModal] = useDialog();
  const [openDeleteConfirmationModal, toggleDeleteConfirmationModal] =
    useDialog();
  const [selectedCard, setSelectedCard] = useState<any>();

  useEffect(() => {
    setTabValue(
      t("indicatorsManagementTexts.indicatorsManagement.tabs.indicators")
    );
  }, [language]);

  useEffect(() => {
    if (location.state && location.state.tab === "archivo/graficos") {
      setTabValue(
        t("indicatorsManagementTexts.indicatorsManagement.tabs.graphics")
      );
    } else if (location.state && location.state.tab === "archivo/indicadores") {
      setTabValue(
        t("indicatorsManagementTexts.indicatorsManagement.tabs.indicators")
      );
    } else if (location.state && location.state.tab === "archivo/tablas") {
      setTabValue(
        t("indicatorsManagementTexts.indicatorsManagement.tabs.tables")
      );
    }
  }, [location]);

  /********************************* LLAMADAS PARA PEDIR INFO *****************************/
  const {
    data: dataGetCharts,
    isLoading: isLoadingCharts,
    isError: isErrorCharts,
    refetch: refetchCharts,
  } = useApiQuery(
    `${GRAFICOS_ARCHIVOS}?tipo=TEMPLATE`,
    false,
    t("report.getKpis.error")
  );

  const {
    data: dataGetKpis,
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
    refetch: refetchIndicators,
  } = useApiQuery(
    `${INDICADORES_ARCHIVOS}?tipo=TEMPLATE`,
    false,
    t("report.getKpis.error")
  );

  const {
    data: dataGetTables,
    isLoading: isLoadingTables,
    isError: isErrorTables,
    refetch: refetchTables,
  } = useApiQuery(
    `${TABLAS_URL}?tipo=TEMPLATE`, //
    false,
    t("report.getKpis.error")
  );

  /*************************** OBTENER INFO SEGÚN TABVALUE ************************/

  const tabs: Tab[] = [
    {
      value: t(
        "indicatorsManagementTexts.indicatorsManagement.tabs.indicators"
      ),
      label: t(
        "indicatorsManagementTexts.indicatorsManagement.tabs.indicators"
      ),
    },
    {
      value: t("indicatorsManagementTexts.indicatorsManagement.tabs.graphics"),
      label: t("indicatorsManagementTexts.indicatorsManagement.tabs.graphics"),
    },
    {
      value: t("indicatorsManagementTexts.indicatorsManagement.tabs.tables"),
      label: t("indicatorsManagementTexts.indicatorsManagement.tabs.tables"),
    },
  ];

  const configByTabValue = (tabValue: string) => {
    switch (tabValue) {
      case t("indicatorsManagementTexts.indicatorsManagement.tabs.indicators"):
        return {
          data: () => dataGetKpis,
          cardInfo: (id: number) =>
            dataGetKpis?.find((indicator: any) => indicator.id === id),
          loading: isLoadingKpis,
          loadingText: t(
            "indicatorsManagementTexts.indicatorsManagement.uploadingIndicators"
          ),
          refetch: () => refetchIndicators(),
          setFilteredData: () => setFilteredData(dataGetKpis),
          filterData: (searchValue: string) =>
            filterDataIndicators(dataGetKpis, searchValue, setFilteredData),
          searchInputPlaceholder: t(
            "indicatorsManagementTexts.indicatorsManagement.searchByNameIndicatorGroup"
          ),
          goToConfig: () => navigate("/configuracion/archivo/indicador"),
          handleDelete: () => deleteIndicator(),
          loadingDelete: isLoadingDeleteIndicator,
          loadingDeleteText: t(
            "indicatorsManagementTexts.indicatorsManagement.deletingIndicator"
          ),
          confirmDeleteMessage: t(
            "indicatorsManagementTexts.indicatorsManagement.deleteTheIndicator"
          ),
          activeCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.activeIndicators"
          ),
          inactiveCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.inactiveIndicators"
          ),
          nonActiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonActiveIndicators"
          ),
          nonInactiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonInactiveIndicators"
          ),
          card: IndicatorCard,
          error: isErrorKpis,
          errorText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonPossibleGetIndicators"
          ),
        };
      case t("indicatorsManagementTexts.indicatorsManagement.tabs.graphics"):
        return {
          data: () => dataGetCharts,
          cardInfo: (id: number) =>
            dataGetCharts?.find((chart: any) => chart.id === id),
          loading: isLoadingCharts,
          loadingText: t(
            "indicatorsManagementTexts.indicatorsManagement.uploadingGráficos"
          ),
          refetch: () => refetchCharts(),
          setFilteredData: () => setFilteredData(dataGetCharts),
          filterData: (searchValue: string) =>
            filterDataCharts(dataGetCharts, searchValue, setFilteredData),
          searchInputPlaceholder: t(
            "indicatorsManagementTexts.indicatorsManagement.searchByNameGraphicCoord"
          ),
          goToConfig: () => navigate("/configuracion/archivo/grafico"),
          handleDelete: () => deleteChart(),
          loadingDelete: isLoadingDeleteChart,
          loadingDeleteText: t(
            "indicatorsManagementTexts.indicatorsManagement.deletingGraphics"
          ),
          confirmDeleteMessage: t(
            "indicatorsManagementTexts.indicatorsManagement.deleteTheGraphic"
          ),
          activeCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.activeGraphics"
          ),
          inactiveCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.inactiveGraphics"
          ),
          nonActiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonActiveGraphics"
          ),
          nonInactiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonInactiveGraphics"
          ),
          card: FileChartCard,
          error: isErrorCharts,
          errorText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonPossibleGetGraphics"
          ),
        };
      case t("indicatorsManagementTexts.indicatorsManagement.tabs.tables"):
        return {
          data: () => dataGetTables,
          cardInfo: (id: number) =>
            dataGetTables?.find((table: any) => table.id === id),
          loading: isLoadingTables,
          loadingText: t(
            "indicatorsManagementTexts.indicatorsManagement.uploadingTables"
          ),
          refetch: () => refetchTables(),
          setFilteredData: () => setFilteredData(dataGetTables),
          filterData: (searchValue: string) =>
            filterDataIndicators(dataGetTables, searchValue, setFilteredData),
          searchInputPlaceholder: t(
            "indicatorsManagementTexts.indicatorsManagement.searchByTableName"
          ),
          goToConfig: () => navigate("/configuracion/archivo/tabla"),
          handleDelete: () => deleteTable(),
          loadingDelete: isLoadingDeleteIndicator,
          loadingDeleteText: t(
            "indicatorsManagementTexts.indicatorsManagement.deletingTable"
          ),
          confirmDeleteMessage: t(
            "indicatorsManagementTexts.indicatorsManagement.deleteTheTable"
          ),
          activeCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.activeTables"
          ),
          inactiveCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.inactiveTables"
          ),
          nonActiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonActiveTables"
          ),
          nonInactiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonInactiveTables"
          ),
          card: TableCard,
          error: isErrorTables,
          errorText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonPossibleGetTables"
          ),
        };
      default:
        return {
          data: () => dataGetKpis,
          cardInfo: (id: number) =>
            dataGetKpis?.find((indicator: any) => indicator.id === id),
          loading: isLoadingKpis,
          loadingText: t(
            "indicatorsManagementTexts.indicatorsManagement.uploadingIndicators"
          ),
          refetch: () => refetchIndicators(),
          setFilteredData: () => setFilteredData(dataGetKpis),
          filterData: (searchValue: string) =>
            filterDataIndicators(dataGetKpis, searchValue, setFilteredData),
          searchInputPlaceholder: t(
            "indicatorsManagementTexts.indicatorsManagement.searchByNameIndicatorGroup"
          ),
          goToConfig: () => navigate("/configuracion/archivo/indicador"),
          handleDelete: () => deleteIndicator(),
          loadingDelete: isLoadingDeleteIndicator,
          loadingDeleteText: t(
            "indicatorsManagementTexts.indicatorsManagement.deletingIndicator"
          ),
          confirmDeleteMessage: t(
            "indicatorsManagementTexts.indicatorsManagement.deleteTheIndicator"
          ),
          activeCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.activeIndicators"
          ),
          inactiveCardsTitle: t(
            "indicatorsManagementTexts.indicatorsManagement.inactiveIndicators"
          ),
          nonActiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonActiveIndicators"
          ),
          nonInactiveText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonInactiveIndicators"
          ),
          card: IndicatorCard,
          error: isErrorKpis,
          errorText: t(
            "indicatorsManagementTexts.indicatorsManagement.nonPossibleGetIndicators"
          ),
        };
    }
  };

  useEffect(() => {
    configByTabValue(tabValue).setFilteredData();
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
  }, [tabValue, dataGetKpis, dataGetCharts, dataGetTables]);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
    configByTabValue(newValue)?.setFilteredData();
  };

  /**************************** BÚSQUEDA ************************************************/

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    configByTabValue(tabValue)?.filterData(e.target.value);
  };

  /*************************** FUNCIONES DE LAS CARDS ***********************************/

  const onSuccessDelete = () => {
    configByTabValue(tabValue).refetch();
    setFilteredData([]);
    setSearchValue("");
  };

  const { isLoading: isLoadingDeleteIndicator, callApi: deleteIndicator } =
    useApi(
      INDICADOR_ARCHIVO(selectedCard?.id as number),
      "DELETE",
      t("indicatorsManagement.deleteIndicator.codes"),
      undefined,
      onSuccessDelete,
      undefined,
      true
    );

  const { isLoading: isLoadingDeleteChart, callApi: deleteChart } = useApi(
    GRAFICO_ARCHIVO(selectedCard?.id as number),
    "DELETE",
    t("chartsManagement.deleteChart.codes"),
    undefined,
    onSuccessDelete,
    undefined,
    true
  );

  const { isLoading: isLoadingDeleteTable, callApi: deleteTable } = useApi(
    TABLA_ARCHIVO(selectedCard?.id as number),
    "DELETE",
    t("tablesManagement.deleteTable.codes"),
    undefined,
    onSuccessDelete,
    undefined,
    true
  );

  const handleDelete = () => {
    configByTabValue(tabValue).handleDelete();
    toggleDeleteConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setSelectedCard(configByTabValue(tabValue).cardInfo(id));
    toggleDeleteConfirmationModal();
  };

  const handleEdit = (e: any, id: number) => {
    localStorage.setItem("configMode", "EDIT");
    localStorage.setItem(
      "cardInfo",
      JSON.stringify(configByTabValue(tabValue).cardInfo(id))
    );
    configByTabValue(tabValue).goToConfig();
  };

  const handleInfo = (e: any, id: number) => {
    setSelectedCard(configByTabValue(tabValue).cardInfo(id));
    toggleViewInfoModal();
  };

  const handleCopy = (e: any, id: number) => {
    localStorage.setItem("configMode", "COPY");
    localStorage.setItem(
      "cardInfo",
      JSON.stringify(configByTabValue(tabValue).cardInfo(id))
    );
    configByTabValue(tabValue).goToConfig();
  };

  const handleAdd = () => {
    localStorage.setItem("configMode", "CREATE");
    configByTabValue(tabValue).goToConfig();
  };

  const activeCardsEmpty =
    configByTabValue(tabValue).data() &&
    !configByTabValue(tabValue).loading &&
    filteredData &&
    filteredData.filter((kpi: any) => kpi.activo).length === 0;

  const activeCards =
    filteredData && filteredData.filter((kpi: any) => kpi.activo);

  const inactiveCardsEmpty =
    configByTabValue(tabValue).data() &&
    !configByTabValue(tabValue).loading &&
    filteredData &&
    filteredData.filter((card: any) => !card.activo).length === 0;

  const inactiveCards =
    filteredData && filteredData.filter((card: any) => !card.activo);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <ColorTabs
        value={tabValue}
        handleChange={handleChange}
        tabs={tabs}
        marginBottom="5px"
        fontSize="14px"
      />
      <SearchInput
        value={searchValue}
        handleChange={handleSearch}
        placeholder={configByTabValue(tabValue).searchInputPlaceholder}
      />
      <>
        <Typography sx={active_inactive_text}>
          {configByTabValue(tabValue).activeCardsTitle}
        </Typography>
        {activeCardsEmpty && (
          <Stack sx={non_active_container}>
            <Typography variant="h6" sx={non_active_text}>
              {configByTabValue(tabValue).nonActiveText}
            </Typography>
          </Stack>
        )}
        <BasicGrid
          data={activeCards}
          Card={configByTabValue(tabValue).card}
          handleEdit={
            userRolData &&
            userRolData.administracion_de_dashboard.visualizacion_y_edicion &&
            handleEdit
          }
          handleCopy={
            userRolData &&
            userRolData.administracion_de_dashboard.visualizacion_y_edicion &&
            handleCopy
          }
          handleInfo={handleInfo}
          handleDelete={
            userRolData &&
            userRolData.administracion_de_dashboard.visualizacion_y_edicion &&
            handleClickOnDelete
          }
          refetchData={configByTabValue(tabValue).refetch}
        />
        <Stack sx={{ marginBottom: "40px !important" }}>
          <Typography sx={inactive_cards_text}>
            {configByTabValue(tabValue).inactiveCardsTitle}
          </Typography>
          {inactiveCardsEmpty && (
            <Stack sx={non_active_container}>
              <Typography variant="h6" sx={non_active_text}>
                {configByTabValue(tabValue).nonInactiveText}
              </Typography>
            </Stack>
          )}
          <BasicGrid
            data={inactiveCards}
            Card={configByTabValue(tabValue).card}
            handleEdit={
              userRolData &&
              userRolData.administracion_de_dashboard.visualizacion_y_edicion &&
              handleEdit
            }
            handleCopy={
              userRolData &&
              userRolData.administracion_de_dashboard.visualizacion_y_edicion &&
              handleCopy
            }
            handleInfo={handleInfo}
            handleDelete={
              userRolData &&
              userRolData.administracion_de_dashboard.visualizacion_y_edicion &&
              handleClickOnDelete
            }
            refetchData={configByTabValue(tabValue).refetch}
          />
        </Stack>
      </>
      {configByTabValue(tabValue).error && (
        <Typography variant="body1" sx={cards_error_text}>
          {configByTabValue(tabValue).errorText}
        </Typography>
      )}
      {userRolData &&
        (userRolData.administracion_de_dashboard.visualizacion_y_creacion ||
          userRolData.administracion_de_dashboard.visualizacion_y_edicion) && (
          <AddCriteriaButton handleClick={handleAdd} />
        )}
      <SimpleBackdrop
        open={configByTabValue(tabValue).loading}
        message={configByTabValue(tabValue).loadingText}
      />
      <SimpleBackdrop
        open={configByTabValue(tabValue).loadingDelete}
        message={configByTabValue(tabValue).loadingDeleteText}
      />
      <ViewInfoModal2
        indicator={selectedCard}
        open={openViewInfoModal}
        handleClose={toggleViewInfoModal}
        handleEdit={handleEdit}
        title={selectedCard?.nombre}
      />
      <DeleteIndicatorConfirmationModal
        open={openDeleteConfirmationModal}
        handleClose={toggleDeleteConfirmationModal}
        handleAccept={handleDelete}
        message={configByTabValue(tabValue).confirmDeleteMessage}
        title={t("indicatorsManagementTexts.indicatorsManagement.confirm")}
      />
    </Stack>
  );
};

export default IndicatorsManagement;
