import { convertArrayToText } from "../../../taskManager/utils";
import { grid_container2_styles } from "../../../../styles/app-styles";
import { Item } from "../../../basicGrid/BasicGrid";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../../../marketBasket/MarketBasketConfig/Shared/GridTitles";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";

const FilterColumnsView = ({ element }) => {
  const { t } = useLanguage();

  const titles = [t("Columnas a utilizar")];
  return (
    <Grid
      sx={{ ...grid_container2_styles, minHeight: "100px" }}
      container
      spacing={1}
    >
      <GridFiveTitles titles={titles} />
      <Grid item xs={0.5}></Grid>
      <Grid item xs={11}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {convertArrayToText(
              element?.columnas?.map((column: any) => {
                return column?.label;
              })
            )}
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={0.5}></Grid>
    </Grid>
  );
};

export default FilterColumnsView;
