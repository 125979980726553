import {
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Checkbox,
  Tooltip,
  Stack,
} from "@mui/material";
import useLanguage from "../../../../context/LanguageProvider";

interface LeftItemsListProps {
  items: any[],
  isMeasureEnabled: (value: any, left: any[], right: any[]) => boolean;
  right: any[];
  checked: any;
  handleToggle: (value: any) => () => void;
}

const LeftItemsList = ({
  items,
  isMeasureEnabled,
  right,
  checked,
  handleToggle,
}: LeftItemsListProps) => {

  const { t } = useLanguage();

  return (
    <List dense component="div" role="list">
      {items && items.length > 0 ? items?.map((value: any, idx: number) => {
        const labelId = `transfer-list-item-${value}-label`;
        const isEnabled = isMeasureEnabled(value, items, right);
        return (
          <Tooltip
            title={
              isEnabled
                ? ""
                : t(
                  "dataModelText.analysisManagement.transferList.measureDisabled"
                )
            }
            key={idx}
          >
            <ListItem
              key={idx}
              role="listitem"
              onClick={isEnabled ? handleToggle(value) : undefined}
              sx={{
                "&:hover": {
                  backgroundColor: isEnabled ? "var(--bg-secondary)" : "",
                  "& .typographyList": {
                    color: isEnabled ? "white" : "",
                  },
                },
                background: checked.indexOf(value) !== -1 ? "var(--bg-main)" : "",
                borderTop: "1px solid var(--bg-light)",
                borderBottom:
                  idx === items.length - 1
                    ? "1px solid var(--bg-light)"
                    : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  disabled={isEnabled ? false : true}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                  sx={{
                    background: !isEnabled
                      ? "var(--bg-disabled)"
                      : "var(--bg-main)",
                  }}
                />
              </ListItemIcon>
              <Typography
                className="typographyList"
                sx={{
                  color: !isEnabled
                    ? "var(--text-info)"
                    : checked.indexOf(value) !== -1
                      ? "white"
                      : "var(--text-main)",
                  fontWeight: "bold",
                }}
              >
                {`${value?.nombre?.toUpperCase()}`}
              </Typography>
            </ListItem>
          </Tooltip>
        );
      }) : <Stack sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Typography sx={{
          color: "var(--text-main)"
        }}>
          {t(
            "dataModelText.analysisManagement.transferList.noMeasuresAvailable"
          )}
        </Typography>
      </Stack>}
    </List>
  );
};

export default LeftItemsList;
