import { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import Dialog from "../../../dialog/Dialog";
import InfoIcon from "@mui/icons-material/Info";
import CustomButton from "../../../buttons/Button";
import useLanguage from "../../../../context/LanguageProvider";
import RightCustomList from "./RightCustomList";
import LeftCustomList from "./LeftCustomList";
import { checkAndSetStoragedBucket } from "../../../parameterization/utils";
import { COMPOUNDS } from "../../constants";

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface TransferListProps {
  left: any[];
  right: any[];
  setLeft: (param: any) => void;
  setRight: (param: any) => void;
}

export default function TransferList({
  left,
  right,
  setLeft,
  setRight,
}: TransferListProps) {
  const { t } = useLanguage();
  const [checked, setChecked] = useState<readonly number[]>([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  /********************************* MANEJO DE DATOS *****************************/

  const handleCheckAllGrouped = (group: string, allChecked: boolean) => {
    const newChecked: any[] = [...checked];
    if (allChecked) {
      setChecked(newChecked.filter((checked) => {
        return checked.sub_tipo !== group
      }))
    } else {
      if (group === COMPOUNDS) {
        // es para chequear las medidas compuestas y que no se "check" aquellas que están deshabilitadas
        const availableCompoundsItems: any[] = [...checked];
        left?.filter(
          (measure: any) => measure.sub_tipo === group
        ).map((value) => {
          if (isMeasureEnabled(value, left, right)) {
            availableCompoundsItems.push(value)
          }
        })
        setChecked([...availableCompoundsItems])
      } else {
        setChecked([...newChecked, ...left.filter(
          (measure: any) => measure.sub_tipo === group
        )])
      }
    }
  }

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    setChecked([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked).sort((a, b) => a.id - b.id));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right).sort((a, b) => a.id - b.id));
    setRight([]);
    setChecked([]);
  };

  const handleToggleInfoModal = () => {
    setOpenInfoModal(!openInfoModal);
  };

  /********************************* VALIDACIÓN DE DATOS *****************************/

  const hasCommonGroupers = (isRightList: boolean, items: any, value: any) => {
    if (isRightList) {
      const currentBuckets =
        value?.bucket_agrupador?.flatMap((bucket) => bucket) || [];
      const itemsBuckets = items
        ?.filter((item) => item.id !== value.id)
        .flatMap((item) => item?.bucket_agrupador?.flatMap((bucket) => bucket));
      const valueGroupers = currentBuckets?.flatMap((item) => {
        return {
          bucketId: item.id,
          agrupadores: item?.agrupadores?.flatMap((item) => item.id),
        };
      });
      let result = false;
      if (valueGroupers && itemsBuckets) {
        valueGroupers?.map((valueBucket) => {
          itemsBuckets?.map((itemBucket) => {
            if (valueBucket?.bucketId === itemBucket?.id) {
              valueBucket?.agrupadores?.sort().forEach((valueItem) => {
                if (
                  itemBucket?.agrupadores
                    ?.flatMap((item) => item.id)
                    .includes(valueItem)
                ) {
                  result = true;
                }
              });
            }
          });
        });
      }
      return result ? "#ff6700" : "var(--text-main)";
    }
    return checked.indexOf(value) !== -1 ? "var(--text-main)" : "white";
  };

  const isMeasureEnabled = (
    measure: any,
    left: any[],
    right: any[]
  ): boolean => {
    if (!measure.medidas) {
      return true;
    }
    if (right) {
      return measure.medidas.every((measureId: number) =>
        right.some((item) => item.id === measureId)
      );
    }
    return measure.medidas.every((measureId: number) =>
      left.some((item) => item.id === measureId)
    );
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <LeftCustomList
            items={left}
            isMeasureEnabled={isMeasureEnabled}
            right={right}
            checked={checked}
            handleToggle={handleToggle}
            handleSelectAllGrouped={handleCheckAllGrouped}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button onClick={handleToggleInfoModal}>
              <InfoIcon color="primary" fontSize="large" sx={{ pb: "2px" }} />
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right?.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <RightCustomList
            items={right}
            isMeasureEnabled={isMeasureEnabled}
            left={left}
            checked={checked}
            handleToggle={handleToggle}
            hasCommonGroupers={hasCommonGroupers}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openInfoModal}
        handleClose={handleToggleInfoModal}
        title={t('dataModelText.analysisManagement.transferList.analysisAdmin')}
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          maxWidth="470px"
        >
          <Typography sx={{
            color: "var(--text-main)", fontSize: "18px", marginTop: "30px",
            maxWidth: "440px",
            textAlign: "center",
          }}>
            {t('dataModelText.analysisManagement.transferList.bucketGrouperInMeasures')}

          </Typography>
          <Typography sx={{
            color: "var(--text-main)", fontSize: "18px", marginTop: "30px",
            maxWidth: "440px",
            textAlign: "center",
          }}>
            {t('dataModelText.analysisManagement.transferList.compositeWithPrimary')}

          </Typography>
          <Stack direction="row" sx={{ marginTop: "25px" }}>
            <CustomButton
              title="Aceptar"
              color="blue"
              type="button"
              onClick={handleToggleInfoModal}
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
