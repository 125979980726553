import { GridColDef } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import {
  header_report_table_styles,
  rendercell_container_report_table_styles,
  rendercell_report_table_styles,
} from "../../../styles/app-styles";
import {
  backgroundColorByFlag,
  flag,
  fontColorByFlag,
  formatValue,
} from "../../report/GraphicsTab/utils";
import Typography from "@mui/material/Typography";

const transformMatchCodeData = (data: any, onNumberClick?: any) => {
  const arrayColumns =
    data &&
    data?.columnas.map((column: any) => ({
      field: column.id.toString(),
      headerName: column.nombre,
      minWidth: column.nombre === "PERIODO" ? 100 : 150,
      overflow: "visible",
      headerClassName: "dm_header_column",   
      resize: true,
      flex: column.nombre === "PERIODO" ? 0.4 : 1,
      renderHeader: (params: any) => (
        <Typography sx={header_report_table_styles}>{column.nombre}</Typography>
      ),
      renderCell: (params: any) => (
        <Stack sx={rendercell_container_report_table_styles}>
          <Stack
            sx={rendercell_report_table_styles(
              fontColorByFlag(flag(params.value)),
              backgroundColorByFlag(flag(params.value)),
              onNumberClick &&
                column.nombre !== "PERIODO" &&
                (params.value === "ERROR" || typeof params.value !== "string")
                ? "pointer"
                : ""
            )}
            onClick={
              onNumberClick &&
              column.nombre !== "PERIODO" &&
              (params.value === "ERROR" || typeof params.value !== "string")
                ? () => onNumberClick(params.field, column.nombre, params.row)
                : undefined
            }
          >
            {formatValue(params.value)}
          </Stack>
        </Stack>
      ),
    }));

  /**
   * @name columns
   * @description Genera el array de todas las columnas que se renderizarán en la tabla, y es el valor de retorno del componente para las columnas.
   * @memberof BucketPerioTable
   * @category BucketPeriodTable
   */
  const columns: GridColDef[] = arrayColumns?.map((col: any) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  /**
   * @name rows
   * @description Genera el array de todas las filas que se renderizarán en la tabla, y es el valor de retorno del componente para las filas.
   * @memberof BucketPerioTable
   * @category BucketPeriodTable
   */

  const rows = data?.data?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};

export const transformData = transformMatchCodeData;
