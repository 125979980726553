import { CustomSelect as Select } from "../forms";
import { useState, useRef, useEffect } from "react";
import { fileOptions, columnOptions } from "./selectOptions";
import { optionalSelectStyles } from "./select.styles";
import { Step4Props } from "./types";
import FormSelectLayout from "../forms/FormSelectLayout";
import { AddButton } from "../buttons";
import { DeleteForever } from "../icons";
import { handleDeleteById, isDuplicatedExternalFilter } from "./utils";
import ExternalRow from "./ExternalRow";
import FormLayout from "../forms/FormLayout";
import data from "./data.json";
import { Box, Typography } from "@mui/material";
import useLanguage from "../../context/LanguageProvider";

const Step4 = ({
  fileColumnSelectOptions,
  fileSelectedOptions,
  externalFileSelectedOptions,
  setExternalFileSelectedOptions,
  externalFileOptionsChangeHandler,
  externalColumnSelectedOptions,
  setExternalColumnSelectedOptions,
  externalColumnOptionsChangeHandler,
  columnFileSelectedOptions,
  setColumnFileSelectedOptions,
  columnFileOptionsChangeHandler,
  filters,
  setFilters,
  setValidationsStep4Completed,
  externalFileSelectRef,
  externalColumnSelectRef,
  columnFileSelectRef,
}: Step4Props) => {
  const [externalError, setExternalError] = useState<string>("");
  const { t } = useLanguage();

  const handleValidationsStep4Complete = () => {
    if (filters && filters.length > 0) {
      setValidationsStep4Completed(true);
    } else {
      setValidationsStep4Completed(false);
    }
  };

  useEffect(() => {
    handleValidationsStep4Complete();
  }, [filters]);

  const handleAddExternalFilter = () => {
    setExternalError("");
    if (
      !externalFileSelectedOptions ||
      !externalColumnSelectedOptions ||
      !columnFileSelectedOptions
    ) {
      setExternalError(data.filterMessages.emptyError);
      return;
    }
    const newFilters: any[] = [];
    if (
      !isDuplicatedExternalFilter(
        filters,
        externalColumnSelectedOptions,
        columnFileSelectedOptions
      )
    ) {
      const filter = {
        id: `${externalFileSelectedOptions?.value}-${externalColumnSelectedOptions?.value}-${columnFileSelectedOptions?.value}`,
        externalFile: externalFileSelectedOptions,
        externalColumn: externalColumnSelectedOptions,
        columnFile: columnFileSelectedOptions,
      };
      newFilters.push(filter);
      filters
        ? setFilters([...filters, ...newFilters])
        : setFilters(newFilters);
      setExternalColumnSelectedOptions(undefined);
      setColumnFileSelectedOptions(undefined);
      externalColumnSelectRef.current.clearValue();
      columnFileSelectRef.current.clearValue();
    } else {
      setExternalError(t("configCriteria.configCriteriaComponent.filterMessages.duplicatedError"));
    }
  };

  const handleDeleteAllFilters = () => {
    setFilters([]);
    setExternalError("");
    setExternalFileSelectedOptions(undefined);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    externalFileSelectRef.current.clearValue();
    externalColumnSelectRef.current.clearValue();
    columnFileSelectRef.current.clearValue();
  };

  return (
    <FormLayout width="38%">
      <div className="cc_filters_container">
        <div className="cc_filters_title">
          <h3>{t("configCriteria.step4.crossoverValues")}</h3>
        </div>
        {externalError && (
          <div className="cc_info_filters_box">
            <p className="cc_info_filters_error">{externalError}</p>
          </div>
        )}
        <div className="cc_external_row_container">
          <div
            className="cc_external_column_file"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              padding: "6px 0",
              borderRadius: "5px",
            }}
          >
            {t("configCriteria.step4.validationFile")}
          </div>
        </div>
        <div className="cc_external_row_container">
          <div className="cc_external_column_file">
            <FormSelectLayout>
              <Select
                reference={externalFileSelectRef}
                styles={optionalSelectStyles(externalFileSelectedOptions)}
                options={fileOptions(fileColumnSelectOptions)}
                form="cc_form"
                className="cc_select"
                name="file"
                isClearable
                onChange={externalFileOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t("configCriteria.step1.selectFile")}
                defaultValue={externalFileSelectedOptions}
              />
            </FormSelectLayout>
          </div>
        </div>

        <div className="cc_external_row_container">
          {filters && filters.length > 0 && (
            <div
              className="cc_external_column_1"
              style={{
                backgroundColor: "#32648e",
                color: "white",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
            ></div>
          )}

          <div
            className="cc_external_column_2"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              padding: "6px 0",
              borderRadius: "5px",
            }}
          >
            {t("configCriteria.step4.columnValidationFile")}
          </div>
          <div
            className="cc_external_column_3"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              padding: "6px 0",
              borderRadius: "5px",
            }}
          >{`${t("configCriteria.step4.columnFile")} ${
            fileSelectedOptions?.label
          }`}</div>
          <div
            className="cc_external_column_4"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          ></div>
        </div>
        <div className="cc_external_row_container">
          {filters && filters.length > 0 && (
            <div className="cc_external_column_1">
              <DeleteForever
                tooltipPlacement="bottom"
                onClick={handleDeleteAllFilters}
              />
            </div>
          )}
          <div className="cc_external_column_2">
            <FormSelectLayout>
              <Select
                reference={externalColumnSelectRef}
                styles={optionalSelectStyles(externalColumnSelectedOptions)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  externalFileSelectedOptions
                )}
                className="cc_select"
                name="column"
                isClearable
                onChange={externalColumnOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={externalColumnSelectedOptions}
              />
            </FormSelectLayout>
          </div>
          <div className="cc_external_column_3">
            <FormSelectLayout>
              <Select
                reference={columnFileSelectRef}
                styles={optionalSelectStyles(columnFileSelectedOptions)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                className="cc_select"
                name="column"
                isClearable
                onChange={columnFileOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={columnFileSelectedOptions}
              />
            </FormSelectLayout>
          </div>
          <div className="cc_external_column_4">
            <AddButton onClick={handleAddExternalFilter} />
          </div>
        </div>
        {filters &&
          filters.map((filter) => (
            <ExternalRow
              key={filter.id}
              id={filter.id}
              externalColumn={filter.externalColumn}
              columnFile={filter.columnFile}
              handleDeleteFilter={handleDeleteById}
              filters={filters}
              setFilters={setFilters}
            />
          ))}
        {!filters?.length && (
          <Box
            sx={{
              width: "95%",
              height: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--text-info)" }}
            >
              {t("configCriteria.step2.noFiltersWarning")}
            </Typography>
          </Box>
        )}
      </div>
    </FormLayout>
  );
};

export default Step4;
