export const NUMERIC_FUNCTIONS = [
  {
      value: "SUMAR",
      label: "SUMA"
  },
  {
      value: "SUMA-PRODUCTO",
      label: "SUMA.PRODUCTO"
  },
  {
    value: "CONTAR",
    label: "CONTAR"
  },
  {
      value: "CONTAR.UNICO",
      label: "CONTAR VALORES UNICOS"
  },
  {
    value: "PROMEDIAR",
    label: "PROMEDIO"
  },
  {
      value: "MAXIMO",
      label: "MÁXIMO"
  },
  {
      value: "MINIMO",
      label: "MÍNIMO"
  },
  {
      value: "DIVIDIR",
      label: "DIVISIÓN"
  }
]