import { Item } from "../../../basicGrid/BasicGrid";
import { optionalSelectStyles } from "../../../configCriteria/select.styles";
import { salesSelectOptions } from "../../utils";
import { text_styles } from "../../../../styles/app-styles";
import { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Select from "../../../forms/Select";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";

interface OptionTabDummyProps {
  groupKey: number;
  fileColumnSelectOptions: any;
  dummyData: any;
  onChangeDummyOption: any;
  stage?: any;
}

const OptionTabDummy = ({
  groupKey,
  fileColumnSelectOptions,
  dummyData,
  onChangeDummyOption,
  stage,
}: OptionTabDummyProps) => {
  const columnSelectRef: any = useRef(null);
  const [counter, setCounter] = useState(0);
  const { t } = useLanguage();

  const onChangeSelectValue = () => {
    let auxCounter = counter;
    auxCounter++;
    setCounter(auxCounter);
  };
  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useSingleSelect(onChangeSelectValue);

  useEffect(() => {
    if (counter !== 0) {
      onChangeDummyOption(groupKey, dummyData, columnSelectedOptions, stage);
    }
  }, [counter]);

  const optionName = stage
    ? `${dummyData.label} (${t(
        "modelParameterizationTexts.onChangeSelectValue.stage"
      )} ${stage})`
    : dummyData.columna_gasto.label;

  return (
    <>
      <Grid item xs={6}>
        <Item>
          <Typography sx={text_styles}>{optionName}</Typography>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Select
          reference={columnSelectRef}
          styles={optionalSelectStyles(columnSelectedOptions)}
          options={salesSelectOptions(fileColumnSelectOptions)}
          className="cc_select"
          onChange={columnOptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "modelParameterizationTexts.onChangeSelectValue.doNotSend"
          )}
          isClearable
        />
      </Grid>
    </>
  );
};

export default OptionTabDummy;
