import { selectStyles } from "../../configCriteria/select.styles";
import { useEffect, useRef } from "react";
import Select from "../../forms/Select";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";

const CurrencySelect = ({
  setCurrencySelectedOptionsState,
  defaultCurrency,
  currencyOptions,
}) => {
  const currencySelectRef = useRef<any>(null);
  const { t } = useLanguage();

  const onCurrencyChange = (currency: any) => {
    setCurrencySelectedOptionsState(currency);
  };

  useEffect(() => {
    if (defaultCurrency && defaultCurrency.length > 0) {
      setCurrencySelectedOptionsState(defaultCurrency);
    }
  }, []);

  const [
    currencySelectedOptions,
    setCurrencySelectedOptions,
    currencyOptionsChangeHandler,
  ] = useMultiSelect(
    defaultCurrency && defaultCurrency.length > 0 ? defaultCurrency : undefined,
    (newValue) => onCurrencyChange(newValue)
  );

  return (
    <Select
      reference={currencySelectRef}
      isClearable
      styles={selectStyles(currencySelectedOptions)}
      options={currencyOptions}
      className="pp_select_schema"
      onChange={currencyOptionsChangeHandler}
      closeMenuOnSelect={false}
      isSearchable
      placeholder={t("general.selectOption")}
      defaultValue={defaultCurrency}
      isMulti
    />
  );
};

export default CurrencySelect;
