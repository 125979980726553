import { TABLAS_URL } from "../../../api/axios";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { useEffect } from "react";
import CustomInput from "../../forms/CustomInput";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizatedTable from "../../virtualizatedTable/VirtualizedTable";

const Step2Tables = ({ rowsLimit, setRowsLimit, idTable }) => {
  const { t } = useLanguage();

  const {
    callApi: getTable,
    data: dataTableResults,
    isLoading: isLoadingTableResults,
  } = useApi(
    `${TABLAS_URL}`,
    "GET",
    t("indicatorsManagement.editIndicator.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    getTable(`${TABLAS_URL}${idTable}/vista_previa`);
  }, []);

  return (
    <FormLayout width="100%" minHeight="200px">
      <Stack width="100%">
        <FormSelectLayout
          title={t("Limitar número de filas")}
          required={false}
          margin={"0px"}
        >
          <CustomInput
            value={rowsLimit}
            setValue={setRowsLimit}
            disabled={false}
            placeholder={t("companyParam.value")}
            type="number"
          />
        </FormSelectLayout>
      </Stack>
      {isLoadingTableResults && <CustomSkeleton height={6} />}
      {!isLoadingTableResults && dataTableResults && dataTableResults && (
        <VirtualizatedTable
          columns={
            transformDataSimpleTable(dataTableResults, undefined, undefined, t)
              .columns
          }
          rows={
            transformDataSimpleTable(dataTableResults, undefined, undefined, t)
              .rows
          }
          rowsPerPageOptions={[5, 10, 20]}
          rowHeight={35}
          tableHeight={"75vh"}
          hideFooter={true}
          toolbar={true}
          columnsButton={true}
          filterButton={true}
          exportButton={true}
          setSelectedRows={() => <></>}
          checkboxSelection={false}
        />
      )}
    </FormLayout>
  );
};

export default Step2Tables;
