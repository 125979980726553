import { useParameterization } from "../../../../hooks/useParameterization";
import { useState } from "react";
import CalculateExpensesOptions from "./CalculateExpensesOptions";
import CalculateGntOptions from "./CalculateGntOptions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useLanguage from "../../../../context/LanguageProvider";
import {
  expand_more_icon_styles,
  reset_button_styles,
} from "../../../../styles/app-styles";

const ResetButton = ({ handleConfirmation }) => {
  const {
    parameterizationState,
    isLoadingRows,
    chartsResultsLoading,
  } = useParameterization();
  const { tabValue } = parameterizationState;
  const { t } = useLanguage();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    !(isLoadingRows || chartsResultsLoading) &&
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (type: string) => {
    handleConfirmation(
      `${t("modelParameterizationTexts.resetButton.toReset")} ` + type,
      "reset"
    );
    handleClose();
  };

  return (
    <>
      <span
        onClick={handleClick}
        style={reset_button_styles(isLoadingRows || chartsResultsLoading)}
      >
        {t("modelParameterizationTexts.resetButton.resetOptions")}
        <span style={expand_more_icon_styles}>
          <ExpandMoreIcon />
        </span>
      </span>
      {tabValue === "GASTOS" ? (
        <CalculateExpensesOptions
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleSelection={handleSelection}
        />
      ) : (
        <CalculateGntOptions
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleSelection={handleSelection}
        />
      )}
    </>
  );
};

export default ResetButton;
