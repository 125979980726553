import Stack from '@mui/material/Stack';
import BarInfiniteDrilldownChart from './BarInfiniteDrilldownChart';
/* import BarInfiniteDrilldownChart from '../../../Charts/BarInfiniteDrilldownChart'; */

const BarrasCharts = ({chartsData}) => {
  return (
    <Stack
    direction="row"
    justifyContent={"space-between"}
    width="100%"
    sx={{ flexWrap: "wrap" }}
  >
    {chartsData.barras.map((item) => {
      return (
        <Stack key={item.id} width="20%">
          <h3>{item?.titulo_grafico}</h3>
          <BarInfiniteDrilldownChart results={item} tooltip={true} />      
        </Stack>
      );
    })}
  </Stack>
  )
}

export default BarrasCharts