import { filterDataByName } from "../utils";
import { Tab, TabValue } from "../../colorTabs/types";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import { VariantType, useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicGrid from "../../basicGrid/BasicGrid";
import CloseIcon from "@mui/icons-material/Close";
import ColorTabs from "../../colorTabs/ColorTabs";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfigCompoundsMeasure from "./configMeasure/ConfigCompoundsMeasure";
import ConfigExpensesMeasure from "./configMeasure/ConfigExpensesMeasure";
import ConfigSalesMeasure from "./configMeasure/ConfigSalesMeasure";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import FixedAddButton from "../../buttons/FixedAddButton";
import IconButton from "@mui/material/IconButton";
import MeasureCard from "./MeasureCard";
import MeasureExpensesAdmin from "./MeasureExpensesAdmin";
import NoDataBox from "../../layout/NoDataBox";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import StraightenIcon from "@mui/icons-material/Straighten";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoCompoundsModal from "./configMeasure/ConfigCompounds/ViewInfoCompoundsModal";
import ViewInfoSalesModal from "./configMeasure/ConfigSales/ViewInfoSalesModal";
import {
  DELETE_MEASURE_URL,
  GET_MEASURES_FOR_IMPORT_URL,
  GET_MEASURE_URL,
  MEASURES_URL,
  POST_IMPORT_MEASURES_URL,
} from "../../../api/axios";
import { back_arrow_styles } from "../../../styles/app-styles";
import useInfoContext from "../../../hooks/useInfoContext";
import ImportButton from "../../importElements/ImportButton";

const MeasureAdmin = () => {
  const { t, language } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("dataModelText.measureManagement.measureAdmin.tabs.expenses")
  );
  const [configMode, setConfigMode] = useState<
    "CREATE" | "EDIT" | "INFO" | "COPY" | ""
  >("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [measureToDelete, setMeasureToDelete] = useState<number | undefined>();
  const [openConfigMeasure, toggleConfigMeasure] = useDialog();
  const [openExpensesConfigMeasure, toggleExpensesConfigMeasure] = useDialog();
  const [dataToShow, setDataToShow] = useState<any>();
  const [
    openDeleteMeasureConfirmationModal,
    handleToggleDeleteMeasureConfirmationModal,
  ] = useDialog();
  const [openViewInfoSalesModal, toggleViewInfoSalesModal] = useDialog();
  const [openViewInfoCompoundsModal, toggleViewInfoCompoundsModal] =
    useDialog();

  useEffect(() => {
    setTabValue(
      t("dataModelText.measureManagement.measureAdmin.tabs.expenses")
    );
    openConfigMeasure && toggleConfigMeasure();
  }, [language]);

  /********************************* LLAMADAS PARA PEDIR INFO *****************************/

  const {
    isLoading: isLoadingGetExpenses,
    data: dataGetExpenses,
    refetch: refetchGetExpenses,
  } = useApiQuery(
    MEASURES_URL("GASTOS"),
    true,
    t("dataModel.getMeasures.error")
  );
  const {
    isLoading: isLoadingGetSales,
    data: dataGetSales,
    refetch: refetchGetSales,
  } = useApiQuery(
    MEASURES_URL("VENTAS"),
    true,
    t("dataModel.getMeasures.error")
  );
  const {
    isLoading: isLoadingGetComposed,
    data: dataGetComposed,
    refetch: refetchGetComposed,
  } = useApiQuery(
    MEASURES_URL("COMPUESTOS"),
    true,
    t("dataModel.getMeasures.error")
  );

  /*************************** OBTENER INFO SEGÚN TABVALUE ************************/

  const tabs: Tab[] = [
    {
      value: t("dataModelText.measureManagement.measureAdmin.tabs.expenses"),
      label: t("dataModelText.measureManagement.measureAdmin.tabs.expenses"),
    },
    {
      value: t("dataModelText.measureManagement.measureAdmin.tabs.sales"),
      label: t("dataModelText.measureManagement.measureAdmin.tabs.sales"),
    },
    {
      value: t("dataModelText.measureManagement.measureAdmin.tabs.compounds"),
      label: t("dataModelText.measureManagement.measureAdmin.tabs.compounds"),
    },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
    configByTabValue(newValue)?.setFilteredData();
  };

  const handleFinish = () => {
    configByTabValue(tabValue).refetch();
    toggleConfigMeasure();
    setFilteredData([]);
    setConfigMode("");
    setDataToShow(undefined);
  };

  const configByTabValue = (tabValue: string) => {
    switch (tabValue) {
      case t("dataModelText.measureManagement.measureAdmin.tabs.expenses"):
        return {
          data: () => dataGetExpenses,
          loading: isLoadingGetExpenses,
          refetch: () => refetchGetExpenses(),
          setFilteredData: () => setFilteredData(dataGetExpenses),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataGetExpenses, searchValue, setFilteredData),
          configAddMeasure: (
            <ConfigExpensesMeasure
              handleFinish={handleFinish}
              configMode={configMode}
              dataGetMeasure={dataGetMeasure}
              setConfigMode={setConfigMode}
              dataGetSales={dataGetSales}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
            />
          ),
          toggleInfoModal: () => <></>,
        };
      case t("dataModelText.measureManagement.measureAdmin.tabs.sales"):
        return {
          data: () => dataGetSales,
          loading: isLoadingGetSales,
          refetch: () => refetchGetSales(),
          setFilteredData: () => setFilteredData(dataGetSales),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataGetSales, searchValue, setFilteredData),
          configAddMeasure: (
            <ConfigSalesMeasure
              handleFinish={handleFinish}
              configMode={configMode}
              dataGetMeasure={dataGetMeasure}
              dataGetSales={dataGetSales}
              setConfigMode={setConfigMode}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
            />
          ),
          toggleInfoModal: () => toggleViewInfoSalesModal(),
        };

      case t("dataModelText.measureManagement.measureAdmin.tabs.compounds"):
        return {
          data: () => dataGetComposed,
          loading: isLoadingGetComposed,
          refetch: () => refetchGetComposed(),
          setFilteredData: () => setFilteredData(dataGetComposed),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataGetComposed, searchValue, setFilteredData),
          configAddMeasure: (
            <ConfigCompoundsMeasure
              handleFinish={handleFinish}
              configMode={configMode}
              dataGetSales={dataGetSales}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
              dataGetMeasure={dataGetMeasure}
              setConfigMode={setConfigMode}
              refetchGetComposed={refetchGetComposed}
            />
          ),
          toggleInfoModal: () => toggleViewInfoCompoundsModal(),
        };
      default:
        return {
          data: () => dataGetSales,
          loading: isLoadingGetSales,
          refetch: () => refetchGetSales(),
          setFilteredData: () => setFilteredData(dataGetSales),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataGetSales, searchValue, setFilteredData),
          configAddMeasure: (
            <ConfigSalesMeasure
              handleFinish={handleFinish}
              configMode={configMode}
              dataGetMeasure={dataGetMeasure}
              dataGetSales={dataGetSales}
              setConfigMode={setConfigMode}
              dataGetExpenses={dataGetExpenses}
              dataGetComposed={dataGetComposed}
            />
          ),
          toggleInfoModal: () => toggleViewInfoSalesModal(),
        };
    }
  };

  const onSuccessGetMeasure = (data: any) => {
    setDataToShow(data);
  };

  useEffect(() => {
    if ((configMode === "EDIT" || configMode === "COPY") && dataToShow) {
      toggleConfigMeasure();
    } else if (configMode === "INFO" && dataToShow) {
      setTimeout(() => {
        configByTabValue(tabValue).toggleInfoModal();
      }, 500);
    }
  }, [configMode, dataToShow]);

  const {
    isLoading: isLoadingGetMeasure,
    data: dataGetMeasure,
    callApi: getMeasure,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getMeasure.codes"),
    undefined,
    onSuccessGetMeasure,
    undefined,
    false
  );

  const onSuccessDeleteMeasure = () => {
    configByTabValue(tabValue).refetch();
    setFilteredData([]);
  };

  const { isLoading: isLoadingDeleteMeasure, callApi: deleteMeasureById } =
    useApi(
      DELETE_MEASURE_URL(measureToDelete),
      "DELETE",
      t("dataModel.deleteMeasure.codes"),
      undefined,
      onSuccessDeleteMeasure
    );

  /*************************** FUNCIONES DE LAS CARDS ***********************************/

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    configByTabValue(tabValue)?.filterDataByName(e.target.value);
  };

  const handleEdit = (e: any, id: number) => {
    setConfigMode("EDIT");
    getMeasure(GET_MEASURE_URL(id));
  };

  const handleCopy = (e: any, id: number) => {
    setConfigMode("COPY");
    getMeasure(GET_MEASURE_URL(id));
  };

  const handleInfo = (e: any, id: number) => {
    setConfigMode("INFO");
    getMeasure(GET_MEASURE_URL(id));
  };

  const handleDelete = () => {
    deleteMeasureById();
    handleToggleDeleteMeasureConfirmationModal();
  };

  const checkIfHasPrimaryMeasure = (id: number) => {
    return (
      dataGetComposed &&
      dataGetComposed?.some((compoundMeasure: any) => {
        return compoundMeasure.medidas.some((measure: any) => {
          return measure === id;
        });
      })
    );
  };

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setMeasureToDelete(id);
    if (checkIfHasPrimaryMeasure(id)) {
      handleClickVariant(
        t(
          "dataModelText.measureManagement.measureAdmin.cannotDeleteMeasureAsBeingUsed"
        ),
        "error"
      );
    } else {
      handleToggleDeleteMeasureConfirmationModal();
    }
  };

  const goToConfigMeasure = () => {
    setConfigMode("CREATE");
    toggleConfigMeasure();
  };

  const handleToggleInfoModal = () => {
    configByTabValue(tabValue).toggleInfoModal();
    setConfigMode("");
    setDataToShow(undefined);
  };

  const isData =
    filteredData?.length !== 0 ||
    configByTabValue(tabValue)?.data()?.length !== 0;

  const isTabExpenses =
    tabValue ===
    t("dataModelText.measureManagement.measureAdmin.tabs.expenses");

    

  const {
    callApi: getElements,
    data: dataGetElements,
    isLoading: isLoadingGetElements,
  } = useApi(
    GET_MEASURES_FOR_IMPORT_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  const onSuccessImportMeasures = () => {
    configByTabValue(tabValue).refetch();
  };

  const {
    isLoading: isLoadingPostImportMeasures,
    callApi: postImportMeasures,
  } = useApi(
    POST_IMPORT_MEASURES_URL,
    "POST",
    undefined,
    undefined,
    onSuccessImportMeasures,
    undefined,
    false
  );

  return (
    <>
      {!openConfigMeasure ? (
        <>
          <SimpleBackdrop
            open={
              isLoadingGetExpenses ||
              isLoadingGetSales ||
              isLoadingGetComposed ||
              isLoadingPostImportMeasures
            }
            message={t(
              "dataModelText.measureManagement.measureAdmin.searchingMeasures"
            )}
          />
          <SimpleBackdrop
            open={isLoadingGetMeasure}
            message={t(
              "dataModelText.measureManagement.measureAdmin.loadingMeasures"
            )}
          />
          <SimpleBackdrop
            open={isLoadingGetElements}
            message={t("general.loading")}
          />
          <SimpleBackdrop
            open={isLoadingDeleteMeasure}
            message={t("dataModel.deleteMeasure.loading")}
          />
          <ComponentLayout
            title={t(
              "dataModelText.measureManagement.measureAdmin.measureAdmin"
            )}
            icon={<StraightenIcon />}
            rightComponent={
              <ImportButton
                postImportElements={postImportMeasures}
                getElements={getElements}
                dataGetElements={dataGetElements}
                GET_ELEMENTS_URL={GET_MEASURES_FOR_IMPORT_URL}
              />
            }
          >
            <ColorTabs
              value={tabValue}
              handleChange={handleChangeTab}
              tabs={tabs}
              marginBottom="0px"
            />
            {!isTabExpenses && (
              <Stack spacing={2} sx={{ width: "100%" }}>
                <SearchInput
                  value={searchValue}
                  handleChange={handleSearch}
                  placeholder={t(
                    "dataModelText.measureManagement.measureAdmin.searchByName"
                  )}
                />
              </Stack>
            )}
            {isTabExpenses ? (
              <MeasureExpensesAdmin
                tabValue={tabValue}
                openExpensesConfigMeasure={openExpensesConfigMeasure}
                toggleExpensesConfigMeasure={toggleExpensesConfigMeasure}
                goToConfigMeasure={goToConfigMeasure}
                dataToShow={dataToShow}
                setDataToShow={setDataToShow}
                handleEdit={handleEdit}
                handleCopy={handleCopy}
              />
            ) : (
              isData && (
                <BasicGrid
                  data={
                    filteredData && filteredData.length
                      ? filteredData
                      : configByTabValue(tabValue).data()
                  }
                  Card={MeasureCard}
                  handleEdit={
                    userRolData
                      .administracion_de_medidas_agrupaciones_y_analisis
                      .visualizacion_y_edicion && handleEdit
                  }
                  handleCopy={
                    userRolData
                      .administracion_de_medidas_agrupaciones_y_analisis
                      .visualizacion_y_edicion && handleCopy
                  }
                  handleInfo={handleInfo}
                  handleDelete={
                    userRolData
                      .administracion_de_medidas_agrupaciones_y_analisis
                      .visualizacion_y_edicion && handleClickOnDelete
                  }
                  showInfo={true}
                />
              )
            )}
            {!isTabExpenses &&
              !configByTabValue(tabValue).loading &&
              !isData && (
                <NoDataBox
                  text={t(
                    "dataModelText.measureManagement.measureAdmin.notMeasuresYet"
                  )}
                />
              )}
            {userRolData &&
              (userRolData.administracion_de_medidas_agrupaciones_y_analisis
                .visualizacion_y_creacion ||
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion) &&
              !isTabExpenses && (
                <FixedAddButton handleClick={goToConfigMeasure} />
              )}
          </ComponentLayout>
        </>
      ) : (
        <ComponentLayout
          title={`${t(
            "dataModelText.measureManagement.measureAdmin.measureAdmin"
          )} - ${tabValue}`}
          icon={<ArrowBackIcon sx={back_arrow_styles} onClick={handleFinish} />}
        >
          {configByTabValue(tabValue).configAddMeasure}
        </ComponentLayout>
      )}
      <ConfirmationModal
        open={openDeleteMeasureConfirmationModal}
        handleClose={handleToggleDeleteMeasureConfirmationModal}
        handleAccept={handleDelete}
        message={t(
          "dataModelText.measureManagement.measureAdmin.removeMeasure"
        )}
        title={t("dataModelText.measureManagement.measureAdmin.confirm")}
      />
      <ViewInfoSalesModal
        info={dataToShow}
        open={openViewInfoSalesModal}
        handleClose={handleToggleInfoModal}
        title={dataToShow?.nombre}
      />
      <ViewInfoCompoundsModal
        info={dataToShow}
        open={openViewInfoCompoundsModal}
        handleClose={handleToggleInfoModal}
        title={dataToShow?.nombre}
        dataGetExpenses={dataGetExpenses}
        dataGetSales={dataGetSales}
        dataGetComposed={dataGetComposed}
      />
    </>
  );
};

export default MeasureAdmin;
