import "../../forms/step.styles.css";
import { Box, Stack, Typography } from "@mui/material";
import { CustomSelect as Select } from "../../forms";
import { form_subtitle_styles } from "../../../styles/app-styles";
import { GET_SIMULATIONS_URL, INDICADORES_GRUPOS } from "../../../api/axios";
import { selectStyles } from "../../configCriteria/select.styles";
import { Step1Props, Group } from "../types";
import { useEffect, useRef, useState } from "react";
import AddButton from "../../buttons/AddButton";
import AddGroupModal from "../AddGroupModal";
import CustomInput from "../../forms/CustomInput";
import Divider from "@mui/material/Divider";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  columnOptions,
  fileOptions,
  functionOptions,
} from "../../configCriteria/selectOptions";
import { useApiQuery } from "../../../hooks/useApiQuery";

const Step1Indicators = ({
  groupRef,
  unityRef,
  setIndicatorFormComplete,
  fileColumnSelectOptions,
  functionSelectOptions,
  fileSelectedOptions,
  fileOptionsChangeHandler,
  unitySelectedOptions,
  unityOptionsChangeHandler,
  indicatorName,
  setIndicatorName,
  columnSelectedOptions,
  denominatorSelectedOptions,
  columnOptionsChangeHandler,
  denominatorOptionsChangeHandler,
  functionSelectedOptions,
  functionOptionsChangeHandler,
  activeIndicator,
  setActiveIndicator,
  columnSelectRef,
  indicatorNameRef,
  fileSelectRef,
  functionSelectRef,
  groupSelectOptions,
  refetchGetGroups,
  groupSelectedOptions,
  groupChangeHandler,
  fileType,
  denominatorSelectRef
}: Step1Props) => {

  const { t } = useLanguage()
  const tabValueEndpoint = localStorage.getItem("tabValueEndpoint") ? localStorage.getItem("tabValueEndpoint") : undefined;
  const analysisId = localStorage.getItem("analysisId") ? localStorage.getItem("analysisId") : undefined;
  const simulationId = localStorage.getItem("simulationId") ? localStorage.getItem("simulationId") : undefined;

  const [openAddGroupModal, setOpenAddGroupModal] = useDialog();
  const unityOptions = [
    {
      value: t("indicatorsManagementTexts.step1.coin").toLowerCase(),
      label: t("indicatorsManagementTexts.step1.coin"),
    },
    {
      value: t("indicatorsManagementTexts.step1.percentage").toLowerCase(),
      label: t("indicatorsManagementTexts.step1.percentage"),
    },
    {
      value: t("indicatorsManagementTexts.step1.units").toLowerCase(),
      label: t("indicatorsManagementTexts.step1.units"),
    },
  ];

  const handleIndicatorFormComplete = () => {
    if (fileType === "TEMPLATE") {
      if (
        !!indicatorName &&
        !!groupSelectedOptions &&
        !!fileSelectedOptions &&
        !!columnSelectedOptions &&
        !!functionSelectedOptions
      ) {
        if (
          functionSelectedOptions.value === "DIVISION" &&
          denominatorSelectedOptions
        ) {
          setIndicatorFormComplete(true);
        } else if (functionSelectedOptions.value !== "DIVISION") {
          setIndicatorFormComplete(true);
        } else {
          setIndicatorFormComplete(false);
        }
      } else {
        setIndicatorFormComplete(false);
      }
    } else {
      if (
        !!indicatorName &&
        !!fileSelectedOptions &&
        !!columnSelectedOptions &&
        !!functionSelectedOptions
      ) {
        if (
          functionSelectedOptions.value === "DIVISION" &&
          denominatorSelectedOptions
        ) {
          setIndicatorFormComplete(true);
        } else if (functionSelectedOptions.value !== "DIVISION") {
          setIndicatorFormComplete(true);
        } else {
          setIndicatorFormComplete(false);
        }
      } else {
        setIndicatorFormComplete(false);
      }
    }
  };

  useEffect(() => {
    handleIndicatorFormComplete();
  }, [
    indicatorName,
    fileSelectedOptions,
    columnSelectedOptions,
    functionSelectedOptions,
    groupSelectedOptions,
    denominatorSelectedOptions
  ]);

  const toggleActiveIndicator = () => {
    setActiveIndicator((prev: boolean) => !prev);
  };

  const formatGroupOptions = (groups: Group[]) => {
    return groups
      ? groups.map((option: Group) => {
        return {
          value: option.id,
          label: option.nombre,
        };
      })
      : [];
  };

  const newGroupNameRef: any = useRef(null);
  const [newGroupName, setNewGroupName] = useState("");

  const onSuccessNewGroup = () => {
    refetchGetGroups();
    setNewGroupName("");
    newGroupNameRef.value = "";
    setOpenAddGroupModal();
  };

  const { isLoading: isLoadingPostGroup, callApi: postNewGroup } = useApi(
    INDICADORES_GRUPOS,
    "POST",
    t("indicatorsManagement.addGroup.codes"),
    undefined,
    onSuccessNewGroup
  );

  return (
    <Box className="cc_step1_container" sx={{ margin: "auto" }}>
      <SimpleBackdrop
        open={isLoadingPostGroup}
        message={t("indicatorsManagementTexts.step1.creatingIndicatorGroup")}
      />
      <FormLayout width="48%">
        <Box>
          <Typography variant="h2" sx={form_subtitle_styles}>
            {t("indicatorsManagementTexts.step1.description")}
          </Typography>
          <label className="cc_step_form_label" htmlFor="indicatorName">
            <span>*</span>
            {t("indicatorsManagementTexts.step1.indicatorName")}
          </label>
          <CustomInput
            type="text"
            name="indicatorName"
            inputRef={indicatorNameRef}
            value={indicatorName}
            setValue={setIndicatorName}
            required={true}
            placeholder={t("indicatorsManagementTexts.step1.indicatorName")}
            autoFocus={true}
            maxLength={30}
          />
          <FormSelectLayout
            title={t("indicatorsManagementTexts.step1.unitOfMeasurement")}
            required={true}
          >
            <Select
              reference={unityRef}
              styles={selectStyles(unitySelectedOptions)}
              options={unityOptions}
              className="cc_select"
              name="Unidad"
              onChange={unityOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "indicatorsManagementTexts.step1.unitOfMeasurement"
              )}
              defaultValue={unitySelectedOptions}
              isClearable
              isSearchable
            />
          </FormSelectLayout>
          {fileType === "TEMPLATE" && (
            <FormSelectLayout title="Grupo" required={true}>
              <Stack direction="row" width="100%">
                <Select
                  reference={groupRef}
                  styles={selectStyles(groupSelectedOptions)}
                  options={formatGroupOptions(groupSelectOptions)}
                  className="cc_select"
                  name="grupo"
                  id="grupo"
                  onChange={groupChangeHandler}
                  closeMenuOnSelect
                  placeholder={t(
                    "indicatorsManagementTexts.step1.chooseCreateGroup"
                  )}
                  defaultValue={groupSelectedOptions}
                  isClearable
                  isSearchable
                />
                <AddButton onClick={setOpenAddGroupModal} />
              </Stack>
            </FormSelectLayout>
          )}
        </Box>
      </FormLayout>
      <FormLayout width="48%">
        <Typography variant="h2" sx={form_subtitle_styles}>
          {t("indicatorsManagementTexts.step1.parameterizationIndicator")}
        </Typography>
        <FormSelectLayout
          title={
            tabValueEndpoint === "SIMULACION" ? t("indicatorsManagementTexts.step1.simulation") :
              tabValueEndpoint === "SALES_FORECAST" ? t("indicatorsManagementTexts.step1.analysis") :
                fileType === "TEMPLATE"
                  ? t("indicatorsManagementTexts.step1.file")
                  : t("indicatorsManagementTexts.step1.analysis")
          }
          required={true}
        >
          <Select
            reference={fileSelectRef}
            styles={selectStyles(fileSelectedOptions)}
            options={fileOptions(fileColumnSelectOptions)}
            className="cc_select"
            name="Archivo"
            onChange={fileOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={
              tabValueEndpoint === "SIMULACION" ? "Elegir simulación" :
                tabValueEndpoint === "SALES_FORECAST" ? t("indicatorsManagementTexts.step1.chooseAnalysis") :
                  fileType === "TEMPLATE"
                    ? t("indicatorsManagementTexts.step1.chooseFile")
                    : t("indicatorsManagementTexts.step1.chooseAnalysis")
            }
            defaultValue={fileSelectedOptions}
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={t("indicatorsManagementTexts.step1.function")}
          required={true}
        >
          <Select
            reference={functionSelectRef}
            styles={selectStyles(functionSelectedOptions)}
            options={functionOptions(
              functionSelectOptions?.filter(
                (funcion) =>
                  funcion !== "SUMA.UNICO" && funcion !== "SUMA.PRODUCTO"
              )
            )}
            className="cc_select"
            name="Función"
            onChange={functionOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("indicatorsManagementTexts.step1.chooseFunction")}
            defaultValue={functionSelectedOptions}
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={
            functionSelectedOptions?.value !== "DIVISION"
              ? t("indicatorsManagementTexts.step1.column")
              : t("indicatorsManagementTexts.step1.numerator")
          }
          required={true}
        >
          <Select
            reference={columnSelectRef}
            styles={selectStyles(columnSelectedOptions)}
            options={columnOptions(
              fileColumnSelectOptions,
              fileSelectedOptions
            )}
            className="cc_select"
            name="Columna"
            onChange={columnOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("indicatorsManagementTexts.step1.chooseColumn")}
            defaultValue={columnSelectedOptions}
            isClearable
          />
        </FormSelectLayout>

        {functionSelectedOptions?.value === "DIVISION" && (
          <FormSelectLayout
            title={t("indicatorsManagementTexts.step1.denominator")}
            required={true}
          >
            <Select
              reference={denominatorSelectRef}
              styles={selectStyles(denominatorSelectedOptions)}
              options={columnOptions(
                fileColumnSelectOptions,
                fileSelectedOptions
              )}
              className="cc_select"
              name="Denominador"
              onChange={denominatorOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t("indicatorsManagementTexts.step1.chooseColumn")}
              defaultValue={denominatorSelectedOptions}
              isClearable
            />
          </FormSelectLayout>
        )}

        <div className="cc_step_form_checkbox cc_checkbox_container">
          <input
            type="checkbox"
            className="cc_checkbox"
            name="indicatorActive"
            onChange={toggleActiveIndicator}
            defaultChecked={activeIndicator}
          />
          <label className="cc_step_form_label" htmlFor="indicatorActive">
            {t("indicatorsManagementTexts.step1.activeIndicator")}
          </label>
        </div>
      </FormLayout>
      <AddGroupModal
        openAddGroupModal={openAddGroupModal}
        setOpenAddGroupModal={setOpenAddGroupModal}
        groupSelectOptions={groupSelectOptions}
        postNewGroup={postNewGroup}
        newGroupNameRef={newGroupNameRef}
        newGroupName={newGroupName}
        setNewGroupName={setNewGroupName}
      />
    </Box>
  );
};

export default Step1Indicators;
