import { Box, Tooltip } from "@mui/material";
import { card_title_container_styles } from "../../styles/app-styles";
import { convertNumberToMoney } from "../parameterization/utils";
import { convertUnit, formatUnits } from "./utils";
import { GET_INDICADOR_CHART } from "../../api/axios";
import Dialog from "../dialog/Dialog";
import LinesChart from "../Charts/LinesChart";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";
interface KpiProps {
  id: number | string;
  valor: number;
  unidad: string;
  kpi: string;
  isAnalysis?: boolean;
  minHeight?: string;
  padding?: string;
}

const Kpi = ({
  valor,
  unidad,
  kpi,
  id,
  isAnalysis,
  minHeight,
  padding,
}: KpiProps) => {
  const { t } = useLanguage();
  const [openChartsModal, handleToggleChartsModal] = useDialog();
  const {
    data: chartResults,
    isLoading: isLoadingChart,
    callApi: getChartResults,
  } = useApi(
    GET_INDICADOR_CHART(id),
    "GET",
    t("indicatorsView.getIndicatorChart.codes"),
    undefined,
    undefined,
    undefined,
    false
  );  

  const handleOpenChart = () => {
    if(id !== "fake"){
      getChartResults();  
    }         
    handleToggleChartsModal();
  
  };

  const fakeData = {
    data: [
      {
        data_serie: [
          { valor_eje_x: "01-2020", valor_eje_y: 20000 },
          { valor_eje_x: "02-2020", valor_eje_y: 23000 },
          { valor_eje_x: "03-2020", valor_eje_y: 21500 },
          { valor_eje_x: "04-2020", valor_eje_y: 24000 },
          { valor_eje_x: "06-2020", valor_eje_y: 23000 },
          { valor_eje_x: "07-2020", valor_eje_y: 22500 },
          { valor_eje_x: "08-2020", valor_eje_y: 25000 },
        ],
      },
    ],
    id: 1,
    nombre_eje_x: "Períodos",
    nombre_eje_y: "SUMA en $",
    tipo: "LINEAS",
    titulo_grafico: "Ejemplo",
  };

  return (
    <>    
      <Stack
        sx={{
          padding: padding
            ? padding
            : isAnalysis
            ? "0px 4px 20px 4px"
            : "2px 4px 2px 4px",
          minHeight: minHeight ? minHeight : isAnalysis ? "130px" : "0",
          ":hover": {
            cursor: !isAnalysis ? "pointer" : "default",
          },
        }}
        alignItems="center"
        justifyContent={isAnalysis ? "center" : "flex-start"}
        onClick={
          kpi !== t("report.expensesTotal") &&
          kpi !== t("report.resultsTotal") &&
          kpi !== t("totalTransferred") &&
          kpi !== t("bucketPeriodsError") &&
          !isAnalysis
            ? () => handleOpenChart()
            : () => <></>
        }
      >        
        <Box sx={card_title_container_styles}>
          <Tooltip
            placement="top"
            title={
              unidad === t("kpi.coin")
                ? convertNumberToMoney(valor)
                : unidad === t("kpi.units")
                ? formatUnits(valor)
                : ""
            }
            sx={{ verticalAlign: "center", fontSize: "16px", zIndex: "100" }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "var(--text-success)",
                fontWeight: "750",
                textTransform: "uppercase",
                fontSize: "1.8rem",
              }}
            >
              {convertUnit(unidad, valor)}
            </Typography>
          </Tooltip>
        </Box>

        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "1.2rem",
            lineHeight: 1.4,
            ":hover": {
              cursor: !isAnalysis ? "pointer" : "default",
              color: !isAnalysis ? "var(--text-success)" : "default",
            },
          }}
          color="var(--violet)"
          /* onClick={
            kpi !== t("kpi.totalInExpenses") &&
            kpi !== t("kpi.totalInResults") &&
            kpi !== t("kpi.totalTransferred") &&
            kpi !== t("kpi.bucketPeriodsError") &&
            !isAnalysis 
              ? () => handleOpenChart()
              : () => <></>
          } */
        >
          {kpi}
        </Typography>
      </Stack>
      <Dialog
        sx={{ width: "90vw" }}
        open={openChartsModal && !isLoadingChart}
        handleClose={handleToggleChartsModal}
        maxWidth="xl"
        title="Ejemplo de gráfico de evolución mensual"
      >
        <Stack sx={{ width: "100%" }}>
          <LinesChart results={ id === "fake" ? fakeData : chartResults} />
        </Stack>
      </Dialog>
    </>
  );
};

export default Kpi;
