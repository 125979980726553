import { convertToText, transformText } from "../../criteriaManagement/utils";
import { getMatchLabelByCode } from "../utils";
import Typography from "@mui/material/Typography/Typography";
import useLanguage from "../../../context/LanguageProvider";
import {
  subtitle_card_typography_styles,
  subtitle_value_styles,
} from "../../../styles/app-styles";
import Box from "@mui/material/Box/Box";
interface CodeMatchingCardContentProps {
  item: any;
}

const CodeMatchingCardContent = ({ item }: CodeMatchingCardContentProps) => {
  const { t } = useLanguage();
  return (
    <>
      <Typography variant="subtitle2" sx={subtitle_card_typography_styles}>
        {item?.izquierda?.archivo?.label}:{" "}
        <span style={subtitle_value_styles}>
          {transformText(convertToText(item.izquierda.columnas, t), 40, t)}
        </span>
      </Typography>
      <Typography variant="subtitle2" sx={subtitle_card_typography_styles}>
        {item?.derecha?.archivo?.label}:{" "}
        {
          <span style={subtitle_value_styles}>
            {item && transformText(convertToText(item?.derecha?.columnas, t), 40, t)}
          </span>
        }
      </Typography>
      <Typography variant="subtitle2" sx={subtitle_card_typography_styles}>
        {t("validations.validationsAdmin.codeMatchingCard.matchIndicator")}{" "}
        {
          <span style={subtitle_value_styles}>
            {getMatchLabelByCode(item, t)}
          </span>
        }
      </Typography>
    </>
  );
};

export default CodeMatchingCardContent;
