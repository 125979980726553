import { useEffect } from "react";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import ViewDashboard from "../../viewDashboardNew/ViewDashboard";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { INCOMING_VALUES_SIMULATIONS, PUT_ORDEN_SIMULATION_ELEMENTS, SIMULATION_PERIODS, SIMULATION_VIEWS } from "../../../api/axios";

const SimulationResult = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const simulationId = localStorage.getItem("simulationId")
    ? localStorage.getItem("simulationId")
    : undefined;
  const analysisId = localStorage.getItem("analysisId") || undefined;
  const simulationView = localStorage.getItem("simulationView")
    ? localStorage.getItem("simulationView")
    : undefined;

  const {
    isLoading,
    setTabValue,
    setAnalysisViews,
    setViewTabValue,
    setAnalysisPeriods,
  } = useViewDashboard();

  analysisId && setTabValue(analysisId);
  simulationView && setViewTabValue(simulationView);

  /****************************** CARGA Y OBTENCIÓN DE DATOS ******************************/

  const {
    isLoading: isLoadingGetAnalysisViews,
    data: dataAnalysisViews,
    refetch: refetchGetAnalysisViews,
  } = useApiQuery(
    SIMULATION_VIEWS("SIMULACION"),
    false,
    t("dataModel.getAnalysis.error")
  );

  useEffect(() => {
    if (dataAnalysisViews) {
      setAnalysisViews({
        isLoading: isLoadingGetAnalysisViews,
        data: dataAnalysisViews,
        refetch: refetchGetAnalysisViews,
      });
    }
  }, [dataAnalysisViews, isLoadingGetAnalysisViews]);

  const {
    isLoading: isLoadingGetAnalysisPeriods,
    callApi: getAnalysisPeriods,
    data: dataGetAnalysisPeriod,
  } = useApi(
    simulationId && SIMULATION_PERIODS("simulaciones", simulationId),
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    setAnalysisPeriods({
      isLoading: isLoadingGetAnalysisPeriods,
      data: dataGetAnalysisPeriod,
      getData: getAnalysisPeriods,
    });
  }, [dataGetAnalysisPeriod, isLoadingGetAnalysisPeriods]);


  /****************************** MANEJO DE FUNCIONES ******************************/

  const handleGoBack = () => {
    navigate("/delta/simulaciones");
    localStorage.removeItem("simulationId");
    localStorage.removeItem("simulationView");
    localStorage.removeItem("analysisId");
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoading || isLoadingGetAnalysisPeriods || isLoadingGetAnalysisViews}
        message={t("general.loading")}
      />
      {dataAnalysisViews && <ComponentLayout
        title={`${t("dashboard.simulation")} - ${dataAnalysisViews && simulationView && dataAnalysisViews
          .flatMap(view => view.vistas)?.find(vista => vista.id == simulationView)?.nombre?.replace("SIMULACION ", "")}`}
        icon={
          <ArrowBackIcon
            sx={{
              fontSize: "35px",
              marginRight: "10px",
              "& :hover": { cursor: "pointer", color: "#f90d4a" },
            }}
            onClick={handleGoBack}
          />
        }
      >
        <ViewDashboard
          thereArePeriodFilter={true}
          thereAreChipFilters={true}
          thereAreViews={false}
          thereAreTabs={false}
          rolEdit={true}
          rolCreate={true}
          actualUrl="/delta/simulacion-resultado"
          ELEMENTS_URL={() => `simulador/vistas/${simulationView}/elementos`}
          ELEMENT_URL={(simulationView, elemento_id) =>
            `simulador/vistas/${simulationView}/elementos/${elemento_id}`
          }
          PERIODS_URL={SIMULATION_PERIODS("simulaciones", simulationId)}
          INCOMING_VALUES={simulationId && INCOMING_VALUES_SIMULATIONS(simulationId)}
          PUT_ORDEN_ELEMENT_URL={PUT_ORDEN_SIMULATION_ELEMENTS}
          dashboardType={"SIMULACION"}
        />
      </ComponentLayout>
      }
    </>
  );
};

export default SimulationResult;
