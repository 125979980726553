import { Stack, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Button } from "../../buttons";
import { ConfirmationModal } from "../../dialog";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useLanguage from "../../../context/LanguageProvider";
import Select from "../../forms/Select";
import { selectStyles } from "../../configCriteria/select.styles";
import FormSelectLayout from "../../forms/FormSelectLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import { useNavigate } from "react-router";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  GET_ANALYSIS_URL,
  GET_RULE_URL,
  GET_SALES_FORECAST_URL,
  GET_SIMULATION_INFORMATION,
  RULES_URL,
} from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useApi from "../../../hooks/useApi";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { transformData } from "./transformDataRuleTable";
import RuleButtons from "./RuleButtons";
import AssignDriverModal from "./AssignDriverModal";
import useDialog from "../../../hooks/useDialog";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";

type VariableDataType = {
  id: number;
  nombre: string;
  variabilidad: number;
  option?: {
    value: number;
    label: string;
  };
};
const ConfigSimulationRule = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const configMode = localStorage.getItem("configMode") || undefined;
  const [newSimulationRuleName, setNewSimulationRuleName] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [variableData, setVariableData] = useState<VariableDataType[]>([]);
  const simulationRuleId = localStorage.getItem("simulationRuleId")
    ? localStorage.getItem("simulationRuleId")
    : undefined;
  const [rowsPreview, setRowsPreview] = useState<GridRowsProp[]>([]);
  const [columnsPreview, setColumnsPreview] = useState<GridColDef[]>([]);
  const [informationData, setInformationData] = useState<any[]>([]);
  const [selectedMeasures, setSelectedMeasures] = useState<any[]>([]);
  const [openAssignDriver, handleToggleAssignDriver] = useDialog();
  const [openDeleteDriverModal, handleToggleDeleteDriverModal] = useDialog();
  const [selectionModelHasDrivers, setSelectionModelsHasDrivers] =
    useState<boolean>(false);
  const [isGetDataAccuracyEmpty, setIsGetDataAccuracyEmpty] =
    useState<boolean>(false);

  /********************************* CARGA Y OBTENCIÓN DE DATOS *****************************/

  const [
    variableSelectedOption,
    setVariableSelectedOption,
    variableOptionChangeHandler,
  ] = useSingleSelect();

  const { isLoading: isLoadingGetSalesForecasts, data: dataGetSalesForecast } =
    useApiQuery(GET_SALES_FORECAST_URL, true, t("dataModel.getAnalysis.error"));

  const {
    isLoading: isLoadingGetRuleById,
    data: dataGetRuleById,
    callApi: getRuleById,
  } = useApi(
    undefined,
    "GET",
    t("simulator.getRules.error"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (simulationRuleId) {
      getRuleById(GET_RULE_URL(Number(simulationRuleId)));
    }
  }, [simulationRuleId]);

  const onAnalysisChange = () => {
    setVariableData([]);
  };

  const [
    salesForecastSelectedOption,
    setSalesForecastSelectedOption,
    salesForecastSelectedChangeHandler,
  ] = useSingleSelect(onAnalysisChange);

  const onSuccessPostPutSimulation = () => {
    handleConfirmationModal();
    closeAndClean();
  };

  const {
    isLoading: isLoadingPostSimulationRule,
    callApi: postSimulationRule,
  } = useApi(
    RULES_URL,
    "POST",
    t("simulator.postRule.codes"),
    undefined,
    onSuccessPostPutSimulation,
    undefined,
    true
  );

  const { isLoading: isLoadingPutSimulationRule, callApi: putSimulationRule } =
    useApi(
      "",
      "PUT",
      t("simulator.putRule.codes"),
      undefined,
      onSuccessPostPutSimulation,
      undefined,
      true
    );

  const {
    isLoading: isLoadingSimulationsRules,
    data: dataGetSimulationsRules,
  } = useApiQuery(RULES_URL, true, t("simulator.getRules.error"));

  const {
    isLoading: isLoadingGetAnalysisMeasures,
    data: dataGetAnalysisMeasures,
    callApi: getAnalysisMeasures,
  } = useApi(
    GET_ANALYSIS_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    false,
    false
  );

  const onSuccessGetDataAccuracy = (data: any) => {
    if (!data) {
      //verificamos si la data no existe es porque ocurrió un problema al generar los valores de correlación y accucary en
      setIsGetDataAccuracyEmpty(true);
      setColumnsPreview([]);
      setRowsPreview([]);
    }
  };

  const {
    isLoading: isLoadingDataGetAccuracy,
    data: dataGetAccuracy,
    callApi: getDataAccuracy,
  } = useApi(
    "",
    "GET",
    undefined,
    undefined,
    onSuccessGetDataAccuracy,
    undefined,
    false
  );

  const {
    isLoading: isLoadingGetSalesForecastById,
    data: dataGetSalesForecastById,
    callApi: getSalesForecastById,
  } = useApi(
    undefined,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingSimulationsInformation,
    data: dataGetSimulationsInformation,
  } = useApiQuery(GET_SIMULATION_INFORMATION, true, undefined);

  useEffect(() => {
    if (
      salesForecastSelectedOption &&
      typeof salesForecastSelectedOption?.value === "number" &&
      dataGetSalesForecast
    ) {
      setColumnsPreview([]);
      setRowsPreview([]);
      getAnalysisMeasures(GET_ANALYSIS_URL(salesForecastSelectedOption?.value));
      getDataAccuracy(
        `simulador/reglas/valores/?analisis=${salesForecastSelectedOption?.value}`
      );
      const salesForecastSelected = dataGetSalesForecast.find(
        (salesForecast) =>
          salesForecast.analisis_id === salesForecastSelectedOption?.value
      );
      if (salesForecastSelected) {
        getSalesForecastById(
          `simulador/sales_forecast/${salesForecastSelected.id}`
        );
      }
    }
  }, [salesForecastSelectedOption, dataGetSalesForecast]);

  useEffect(() => {
    const resultadoCruzado =
      dataGetSalesForecastById &&
      dataGetAnalysisMeasures &&
      dataGetSimulationsInformation &&
      dataGetSimulationsInformation
        .filter((info) => info.sales_forecast)
        .map((item) => {
          const columnOrMedida =
            dataGetSalesForecastById?.columnas_principales?.find(
              (element) => element.informacion === item.id
            )?.columna;
          return {
            id: item.id,
            informacion: item.nombre,
            columna:
              columnOrMedida && typeof columnOrMedida === "string"
                ? columnOrMedida
                : dataGetAnalysisMeasures?.medidas?.find(
                    (item) => item.id === columnOrMedida
                  )?.nombre || null,
          };
        });
    setInformationData(resultadoCruzado);
  }, [
    dataGetSimulationsInformation,
    dataGetSalesForecastById,
    dataGetAnalysisMeasures,
  ]);

  useEffect(() => {
    if (
      informationData &&
      informationData !== null &&
      informationData.length > 0 &&
      dataGetAnalysisMeasures &&
      dataGetAccuracy
    ) {
      const { columns, rows } = transformData(
        t,
        dataGetAnalysisMeasures,
        dataGetAccuracy,
        informationData,
        handleVariableChange,
        variableData
      );
      columns && setColumnsPreview(columns);
      rows && setRowsPreview(rows);
    }
  }, [informationData, dataGetAccuracy, dataGetAnalysisMeasures, variableData]);

  useEffect(() => {
    if (dataGetRuleById) {
      setSalesForecastSelectedOption({
        label: dataGetRuleById?.analisis?.nombre,
        value: dataGetRuleById?.analisis?.id,
      });
      configMode === "COPY"
        ? setNewSimulationRuleName(dataGetRuleById?.nombre + "_copy")
        : setNewSimulationRuleName(dataGetRuleById?.nombre);
      const variableEditData = dataGetRuleById?.analisis?.medidas?.map(
        (medida) => {
          if (medida.variable_dependiente) {
            return {
              id: medida.id,
              nombre: medida.nombre,
              variabilidad: medida.variabilidad,
              option: {
                value: medida.variable_dependiente.id,
                label: medida.variable_dependiente.nombre,
              },
            };
          }
        }
      );
      setVariableData(variableEditData.filter((data) => data !== undefined));
    }
  }, [dataGetRuleById, configMode]);

  useEffect(() => {
    if (variableData && selectedMeasures) {
      setSelectionModelsHasDrivers(
        variableData.some(
          (item) =>
            selectedMeasures.includes(item.id) && item.option !== undefined
        )
      );
    }
  }, [variableData, selectedMeasures]);

  /************** MANEJO DE FUNCIONES ***************/

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          alignItems: "center",
          color: "white",
          display: "flex",
          flexDirection: "row",
          height: "65px",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "var(--bg-main)",
          border: "none",
          borderRadius: "20px 20px 0px 0px",
          position: "relative",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            spacing: 4,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: "600",
              color: "white",
              textTransform: "uppercase",
              fontFamily: "Verdana",
              marginBottom: "8px",
            }}
          >
            {t(
              "simulatorTexts.simulationResultById.simulationResultInfoModal.costVariability"
            )}
          </Typography>
        </Stack>
        <Stack
          sx={{
            spacing: 4,
            width: "100%",
            position: "absolute",
            pr: 5,
          }}
        >
          <RuleButtons
            selectionModel={selectedMeasures}
            handleToggleAssignDriver={handleToggleAssignDriver}
            handleToggleDeleteDriverModal={handleToggleDeleteDriverModal}
            selectionModelHasDrivers={selectionModelHasDrivers}
          />
        </Stack>
      </GridToolbarContainer>
    );
  }

  const prepareColumnsToUseOptions = () => {
    const compoundsMeasures: any[] = [];
    const salesMeasures: any[] = [];
    dataGetAnalysisMeasures?.medidas?.map((medida) => {
      if (medida.medidas) {
        compoundsMeasures.push({
          label: medida.nombre,
          value: medida.id,
        });
      } else if (!medida.bucket_agrupador) {
        salesMeasures.push({
          label: medida.nombre,
          value: medida.id,
        });
      }
    });
    const totalOptions = [
      // {
      //     label: t("simulatorTexts.configSimulationRule.compundsMeasures"),
      //     options: compoundsMeasures,
      // },
      {
        label: t("simulatorTexts.configSimulationRule.salesMeasures"),
        options: salesMeasures,
      },
      {
        label: t("simulatorTexts.configSimulationRule.default"),
        options: [
          {
            value: 1,
            label: t("simulatorTexts.configSimulationRule.quantityRoutes"),
          },
        ],
      },
    ];
    return totalOptions;
  };

  /* FUNCIÓN QUE SIRVE PARA EL SETEO DE LA DATA RESPECTIVAMENTE DE CADA MEDIDA DE GASTO Y SU VARIABILIDAD */
  const handleVariableChange = (newValue, id, option, expenseMeasure) => {
    setVariableData((prevVariableData) => {
      // Verificamos si el id ya existe en el arreglo
      const existingEntryIndex = prevVariableData.findIndex(
        (entry) => entry.id === id
      );
      // Si existe, actualizamos el valor de la variabilidad
      if (existingEntryIndex !== -1) {
        const updatedVariableData = [...prevVariableData];
        updatedVariableData[existingEntryIndex].variabilidad = newValue;
        updatedVariableData[existingEntryIndex].option = option;
        return updatedVariableData;
      }

      // Si no existe, añadimos un nuevo elemento
      return [
        ...prevVariableData,
        { id, nombre: expenseMeasure.nombre, variabilidad: newValue, option },
      ];
    });
  };

  /* FUNCIÓN QUE SIRVE PARA  ASIGNAR DRIVERS*/
  const handleAssignDrivers = () => {
    setVariableData((prevVariableData) => {
      const updatedVariableData = [...prevVariableData];
      selectedMeasures.forEach((id) => {
        const expenseMeasure = dataGetAnalysisMeasures?.medidas?.find(
          (elemento) => elemento.id === id
        );
        if (expenseMeasure) {
          const existingIndex = updatedVariableData.findIndex(
            (data) => data.id === id
          );
          if (existingIndex !== -1) {
            updatedVariableData[existingIndex] = {
              id: id,
              nombre: expenseMeasure.nombre,
              variabilidad: updatedVariableData[existingIndex].variabilidad,
              option: variableSelectedOption
                ? {
                    value:
                      typeof variableSelectedOption.value === "number"
                        ? variableSelectedOption.value
                        : parseInt(variableSelectedOption.value),
                    label: variableSelectedOption.label,
                  }
                : updatedVariableData[existingIndex].option,
            };
          } else {
            updatedVariableData.push({
              id: id,
              nombre: expenseMeasure.nombre,
              variabilidad: 0,
              option: variableSelectedOption
                ? {
                    value:
                      typeof variableSelectedOption.value === "number"
                        ? variableSelectedOption.value
                        : parseInt(variableSelectedOption.value),
                    label: variableSelectedOption.label,
                  }
                : undefined,
            });
          }
        }
      });
      return updatedVariableData;
    });

    handleCloseAssignDriverModal();
  };

  /* FUNCIÓN QUE SIRVE PARA ELIMINAR DRIVERS*/
  const handleDeleteDrivers = () => {
    setVariableData((prevVariableData) => {
      const updatedVariableData = [...prevVariableData];
      selectedMeasures.forEach((id) => {
        const expenseMeasure = dataGetAnalysisMeasures?.medidas?.find(
          (elemento) => elemento.id === id
        );
        if (expenseMeasure) {
          const existingIndex = updatedVariableData.findIndex(
            (data) => data.id === id
          );
          if (existingIndex !== -1) {
            updatedVariableData[existingIndex] = {
              id: id,
              nombre: expenseMeasure.nombre,
              variabilidad: updatedVariableData[existingIndex].variabilidad,
              option: variableSelectedOption
                ? {
                    value:
                      typeof variableSelectedOption.value === "number"
                        ? variableSelectedOption.value
                        : parseInt(variableSelectedOption.value),
                    label: variableSelectedOption.label,
                  }
                : undefined,
            };
          }
        }
      });
      return updatedVariableData;
    });

    handleCloseDeleteDriverModal();
  };

  const handleCloseAssignDriverModal = () => {
    handleToggleAssignDriver();
    setVariableSelectedOption(undefined);
    setSelectedMeasures([]);
  };

  const handleCloseDeleteDriverModal = () => {
    handleToggleDeleteDriverModal();
    setSelectedMeasures([]);
  };

  const handleGoBack = () => {
    navigate("/delta/administracion-reglas");
    localStorage.removeItem("configMode");
    localStorage.removeItem("simulationRuleId");
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSimulationRuleName(e.target.value);
  };

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const closeAndClean = () => {
    setNewSimulationRuleName("");
    localStorage.removeItem("simulationRuleId");
    handleGoBack();
  };

  /************** VALIDACIÓN DE DATOS ***************/

  const handleValidateSimulationRuleName = (
    configMode: string | undefined | null
  ) => {
    if (
      newSimulationRuleName === "" ||
      (configMode !== "EDIT" &&
        dataGetSimulationsRules &&
        dataGetSimulationsRules?.some(
          (rule: any) =>
            rule.nombre.toLowerCase() === newSimulationRuleName.toLowerCase()
        )) ||
      (configMode === "EDIT" &&
        dataGetSimulationsRules &&
        dataGetSimulationsRules
          ?.filter((rule) => rule.nombre !== dataGetRuleById?.nombre)
          .some(
            (rule: any) =>
              rule.nombre.toLowerCase() === newSimulationRuleName.toLowerCase()
          ))
    ) {
      return true;
    }
    return false;
  };

  const validateData = () => {
    if (
      newSimulationRuleName === "" ||
      !salesForecastSelectedOption ||
      variableData
        .filter((data) => data !== undefined)
        .some(
          (data) =>
            data.option === undefined || data.option === null || !data.option
        ) ||
      (configMode !== "EDIT" &&
        dataGetSimulationsRules &&
        dataGetSimulationsRules?.some(
          (rule: any) =>
            rule.nombre.toLowerCase() === newSimulationRuleName.toLowerCase()
        )) ||
      (configMode === "EDIT" &&
        dataGetSimulationsRules &&
        dataGetSimulationsRules
          ?.filter((rule) => rule.nombre !== dataGetRuleById?.nombre)
          .some(
            (rule: any) =>
              rule.nombre.toLowerCase() === newSimulationRuleName.toLowerCase()
          )) ||
      (dataGetAnalysisMeasures &&
        variableData.length !==
          dataGetAnalysisMeasures?.medidas?.filter(
            (elemento) => elemento.bucket_agrupador
          ).length)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleFinish = () => {
    const measuresWithNoVariability =
      dataGetAnalysisMeasures?.medidas?.filter(
        (elemento) => elemento.bucket_agrupador
      ) &&
      dataGetAnalysisMeasures?.medidas
        ?.filter((elemento) => elemento.bucket_agrupador)
        .filter((obj1) => {
          return !variableData?.some((obj2) => obj2.nombre === obj1.nombre);
        });
    const measuresWithNoVariabilityBody =
      measuresWithNoVariability &&
      measuresWithNoVariability?.map((data) => {
        return {
          id: data.id,
          variabilidad: 0,
          variable_dependiente: {
            id:
              data?.option?.label ===
              t("simulatorTexts.configSimulationRule.quantityRoutes")
                ? null
                : data?.option?.value,
            nombre:
              data?.option?.label ===
              t("simulatorTexts.configSimulationRule.quantityRoutes")
                ? "CANTIDAD_RUTAS"
                : data?.option?.label,
          },
        };
      });
    const measuresWithVariability =
      variableData &&
      variableData
        .filter((data) => data != undefined)
        ?.map((data) => {
          return {
            id: data.id,
            variabilidad: data.variabilidad,
            variable_dependiente: {
              id:
                data?.option?.label ===
                t("simulatorTexts.configSimulationRule.quantityRoutes")
                  ? null
                  : data?.option?.value,
              nombre:
                data?.option?.label ===
                t("simulatorTexts.configSimulationRule.quantityRoutes")
                  ? "CANTIDAD_RUTAS"
                  : data?.option?.label,
            },
          };
        });
    const completedInfo = {
      nombre: newSimulationRuleName,
      analisis: {
        id: salesForecastSelectedOption?.value,
        medidas: [...measuresWithNoVariabilityBody, ...measuresWithVariability],
      },
    };

    if (configMode === "CREATE" || configMode === "COPY") {
      postSimulationRule(RULES_URL, completedInfo);
    } else if (configMode === "EDIT") {
      putSimulationRule(GET_RULE_URL(Number(simulationRuleId)), completedInfo);
    }
  };

  return (
    <ComponentLayout
      title={t("simulatorTexts.configSimulationRule.simulationRuleConfig")}
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={handleGoBack}
        />
      }
    >
      <SimpleBackdrop
        open={
          isLoadingGetSalesForecasts ||
          isLoadingGetRuleById ||
          isLoadingPutSimulationRule ||
          isLoadingPostSimulationRule ||
          isLoadingGetAnalysisMeasures ||
          isLoadingDataGetAccuracy ||
          isLoadingGetSalesForecastById ||
          isLoadingSimulationsInformation ||
          isLoadingSimulationsRules
        }
        message={t("simulatorTexts.configSimulationRule.loading")}
      />
      <Stack
        sx={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            gap: "10px",
          }}
        >
          <TextField
            type="text"
            label={t("simulatorTexts.configSimulationRule.ruleName")}
            variant="outlined"
            fullWidth
            value={newSimulationRuleName}
            onChange={handleChangeName}
            error={handleValidateSimulationRuleName(configMode)}
            helperText={
              configMode !== "EDIT" &&
              dataGetSimulationsRules &&
              dataGetSimulationsRules?.some(
                (rule: any) =>
                  rule.nombre.toLowerCase() ===
                  newSimulationRuleName.toLowerCase()
              )
                ? t("simulatorTexts.configSimulationRule.alreadyExisteRuleName")
                : configMode === "EDIT" &&
                  dataGetSimulationsRules &&
                  dataGetSimulationsRules
                    ?.filter((rule) => rule.nombre !== dataGetRuleById?.nombre)
                    .some(
                      (rule: any) =>
                        rule.nombre.toLowerCase() ===
                        newSimulationRuleName.toLowerCase()
                    )
                ? t("simulatorTexts.configSimulationRule.alreadyExisteRuleName")
                : ""
            }
            FormHelperTextProps={{
              sx: {
                color: "var(--text-error)",
                margin: 0,
              },
            }}
          />
          <FormSelectLayout
            title={t("simulatorTexts.configSimulationRule.analysisToUse")}
            required={true}
          >
            <Select
              styles={selectStyles(salesForecastSelectedOption)}
              options={
                dataGetSalesForecast &&
                dataGetSalesForecast.map((salesForecast) => {
                  return {
                    value: salesForecast.analisis_id,
                    label: salesForecast.analisis,
                  };
                })
              }
              className="cc_select"
              name="salesForecastToUse"
              onChange={salesForecastSelectedChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "simulatorTexts.configSimulationRule.chooseAnalysis"
              )}
              value={
                configMode === "EDIT" && dataGetRuleById
                  ? {
                      label: dataGetRuleById?.analisis?.nombre,
                      value: dataGetRuleById?.analisis?.id,
                    }
                  : salesForecastSelectedOption
              }
              defaultValue={
                configMode === "EDIT"
                  ? {
                      label: dataGetRuleById?.analisis?.nombre,
                      value: dataGetRuleById?.analisis?.id,
                    }
                  : salesForecastSelectedOption
              }
              isClearable
            />
          </FormSelectLayout>
        </Stack>
      </Stack>
      {isGetDataAccuracyEmpty ? (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            pt: 2,
            pb: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              color: "var(--text-error)",
            }}
          >
            {t("simulatorTexts.configSimulationRule.noValuesCalculated")}
          </Typography>
        </Stack>
      ) : (
        columnsPreview.length > 0 &&
        rowsPreview.length > 0 && (
          <>
            <Stack
              sx={{
                border: "1px solid var(--bg-light)",
                width: "100%",
                borderRadius: "20px",
                height: 60 + rowsPreview.length * 30,
                boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
                position: "relative",
                mb: "10px",
                mt: "10px",
                pb: "20px",
              }}
            >
              <DataGrid
                rows={rowsPreview}
                columns={columnsPreview}
                disableColumnMenu={true}
                hideFooter={true}
                checkboxSelection={true}
                selectionModel={selectedMeasures}
                isRowSelectable={(params: GridRowParams) =>
                  params.row.id !== "cagrsInfo"
                }
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedMeasures(newSelectionModel);
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
                sx={{
                  "& .MuiDataGrid-sortIcon": {
                    display: "none", // Oculta el icono de ordenamiento
                  },
                  border: "none",
                }}
              />
            </Stack>
          </>
        )
      )}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          mb: "10px",
        }}
      >
        <Button
          title={t("simulatorTexts.configSimulationRule.cancel")}
          color="light-grey"
          type="button"
          onClick={closeAndClean}
        />
        <Button
          title={t("simulatorTexts.configSimulationRule.save")}
          color="blue-greeny"
          type="submit"
          onClick={handleConfirmationModal}
          disabled={validateData()}
        />
      </Stack>
      <AssignDriverModal
        open={openAssignDriver}
        handleClose={handleCloseAssignDriverModal}
        handleFinish={handleAssignDrivers}
        prepareColumnsToUseOptions={prepareColumnsToUseOptions}
        variableSelectedOption={variableSelectedOption}
        variableOptionChangeHandler={variableOptionChangeHandler}
      />
      <ConfirmationModal
        open={openDeleteDriverModal}
        handleClose={handleCloseDeleteDriverModal}
        handleAccept={handleDeleteDrivers}
        message={t(
          "deltaTexts.simulationRules.configSimulationRule.deleteAllCostDriver"
        )}
        title={t(
          "deltaTexts.simulationRules.configSimulationRule.deleteCostDriver"
        )}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleConfirmationModal}
        handleAccept={handleFinish}
        message={t("simulatorTexts.configSimulationRule.save").toLowerCase()}
        title={
          configMode === "EDIT"
            ? t("simulatorTexts.configSimulationRule.editSimulationRule")
            : t("simulatorTexts.configSimulationRule.newSimulationRule")
        }
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <InfoIcon color="primary" fontSize="medium" />
          <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
            {t(
              "simulatorTexts.configSimulationRule.editRuleWillDeleteSimulationsPeriods"
            )}
          </Typography>
        </Stack>
      </ConfirmationModal>
    </ComponentLayout>
  );
};

export default ConfigSimulationRule;
