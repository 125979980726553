import Dialog from "../../../../../dialog/Dialog";
import { Button } from "../../../../../buttons";
import { Stack, Typography } from "@mui/material";
import useLanguage from "../../../../../../context/LanguageProvider";
import { useContext, useEffect, useState } from "react";
import { transformData } from "../../../../../virtualizatedTable/transformData";
import { DataGridPro } from "@mui/x-data-grid-pro";
import useDialog from "../../../../../../hooks/useDialog";
import InfoIcon from "@mui/icons-material/Info";
import { getInformationColumn } from "../../../../utils";
import { CustomToolbar } from "./Step3NewCancelRouteModal";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import AssignRetentionsModal from "./AssignRetentionsModal";
import { getColumnsRetentionRoutes } from "./Step3ModelTable";
interface ModifyCancelRouteModalProps {
    open: boolean;
    handleClose: () => void;
    retentions: any,
    setRetentions: any,
    configToEdit: any;
    setConfigToEdit: any,
    informationsColumns: any,
}

const ModifyCancelRouteModal = ({ handleClose, open, configToEdit, setConfigToEdit, retentions, setRetentions, informationsColumns }: ModifyCancelRouteModalProps) => {
    const { t } = useLanguage();
    const [rowsPreview, setRowsPreview] = useState<any>();
    const [selectedRoutes, setSelectedRoutes] = useState<any[]>([]);
    const [openAssignRetentionsModal, handleToggleAssignRetentionsModal] = useDialog();
    const [retention, setRetention] = useState<number>(0)
    const { simulationState, simulationDispatch } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);

    /***************************** MANEJO DE DATOS ***********************************/

    useEffect(() => {
        if (simulationState && simulationState.configurations) {
            // Obtener todas las rutas
            const allRoutes = simulationState.configurations.filter((config) => config.tipo === "baja").flatMap((data) => data.rutas.map((rutaData) => rutaData));
            setRetentions(allRoutes)
        }
    }, [simulationState])

    useEffect(() => {
        if (configToEdit && configToEdit.rutas) {
            const { rows } = transformData(
                configToEdit.rutas,
                configToEdit.rutas,
                undefined,
                informationsColumns
            )
            setRowsPreview(rows);
        } else {
            setRowsPreview([]);
        }
    }, [configToEdit])

    const handleChangeRetention = (route, value) => {
        const rutaEncontrada = retentions.find((item) => {
            if (item.ruta) {
                return item.ruta === route
            } else {
                return item[getInformationColumn(informationsColumns, "Identificador único de ruta")] === route
            }
        });
        if (rutaEncontrada) {
            const nuevaRetention = retentions.map((item) => {
                if (item.ruta && item.ruta === route || item[getInformationColumn(informationsColumns, "Identificador único de ruta")] === route) {
                    return { ...item, retencion: value }
                } else {
                    return item
                }
            }
            );
            setRetentions(nuevaRetention);
        }
    }

    const handleAssignRetentions = () => {
        const routesSelections = configToEdit.rutas.filter(item => selectedRoutes && selectedRoutes.includes(item._id)).map(route => route[getInformationColumn(informationsColumns, "Identificador único de ruta")])        
        routesSelections.forEach(ruta => {
            // Busca la ruta en el array retentions
            const retencionEncontrada: any = retentions.find(item => item[getInformationColumn(informationsColumns, "Identificador único de ruta")] === ruta);            
            // Si se encuentra la retención, actualiza el valor
            if (retencionEncontrada) {
                retencionEncontrada.retencion = retention; // Puedes cambiar 1 por el valor que desees
            }
        });
        const configurationsFilteres = simulationState.configurations.filter((config) => config.id !== configToEdit.id)
        const newConfigurations = configurationsFilteres.concat(configToEdit)
        simulationDispatch({ type: "SET_CONFIGURATIONS", payload: newConfigurations });
        setRetention(0)
        setSelectedRoutes([])
        handleToggleAssignRetentionsModal()
    }

    const handleOpenAssignRetentionsModal = () => {
        handleToggleAssignRetentionsModal()
    }

    const handleModifyRetentionRoute = () => {
        const configurationsFilteres = simulationState.configurations.filter((config) => config.id !== configToEdit.id)

        const newConfigToEdit = { ...configToEdit };

        newConfigToEdit.rutas = retentions.map((retention) => ({
            ...retention
        }));
        const newConfigurations = configurationsFilteres.concat(newConfigToEdit)
        simulationDispatch({ type: "SET_CONFIGURATIONS", payload: newConfigurations });

    }

    const closeAndClean = () => {
        handleClose()
        setConfigToEdit()
    }

    return (
        <Dialog
            open={open}
            handleClose={closeAndClean}
            title={t("simulatorTexts.canceledRoutes.modifyCancelRouteModal.modifyRetentions")}
            maxWidth="xl"
            actions={
                <>
                    <Button
                        title={t("simulatorTexts.assignVariabilityModal.cancel")}
                        color="grey"
                        type="button"
                        onClick={closeAndClean}
                    />
                    <Button
                        title={t("simulatorTexts.canceledRoutes.modifyCancelRouteModal.modify")}
                        color="blue"
                        type="button"
                        onClick={handleModifyRetentionRoute}
                    />
                </>
            }
        >
            <Stack sx={{
                width: "100%",
                alignItems: "flex-end"
            }}>
                <Button
                    title={t("simulatorTexts.canceledRoutes.modifyCancelRouteModal.assignToVarios")}
                    color="blue-greeny"
                    type="button"
                    onClick={handleOpenAssignRetentionsModal}
                    disabled={selectedRoutes.length < 1} />
            </Stack>
            <Stack
                sx={{
                    pt: 1,
                    pb: 2,
                    width: "900px",
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    height: "350px",
                    flexDirection: "row",
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "var(--bg-secondary)",
                    },
                    "& .MuiDataGrid-columnHeadersInner span": {
                        color: "white",
                    },
                }}
            >
                {rowsPreview &&
                    <>
                        <DataGridPro
                            rows={rowsPreview}
                            columns={getColumnsRetentionRoutes(retentions, handleChangeRetention, t, informationsColumns)}
                            rowsPerPageOptions={[5, 10, 20]}
                            rowHeight={45}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            componentsProps={{
                                panel: {
                                    sx: {
                                        [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                                            display: "none",
                                        },
                                    },
                                },
                            }}
                            disableColumnMenu={true}
                            hideFooter={true}
                            checkboxSelection={true}
                            disableSelectionOnClick
                            selectionModel={selectedRoutes}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectedRoutes(newSelectionModel);
                            }}
                        />
                    </>
                }
            </Stack>
            <Stack sx={{
                width: "100%",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <InfoIcon color="primary" fontSize="medium" />
                <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
                    {t("simulatorTexts.canceledRoutes.modifyCancelRouteModal.retentionPercentageInfo")}
                </Typography>
            </Stack>
            <AssignRetentionsModal
                handleFinish={handleAssignRetentions}
                open={openAssignRetentionsModal}
                handleClose={handleToggleAssignRetentionsModal}
                retention={retention}
                setRetention={setRetention}
            />

        </Dialog >
    )
}

export default ModifyCancelRouteModal