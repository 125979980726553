import { getPeriodsLabelsFromPeriodsIds } from "../utils";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import CheckmarkSelect from "../../periods/CheckmarkSelect";
import CustomButton from "../../buttons/Button";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";

const PeriodFilter = () => {
  const { t } = useLanguage();
  const {
    selectedPeriods,
    setSelectedPeriods,
    analysisPeriods,
    tabValue,
    setOpenFilterModal,
    setFilters,
  } = useViewDashboard();

  const handleToggleFiltersModal = () => {
    setOpenFilterModal((prev) => !prev);
  };

  const handleSelectedPeriods = (info) => {
    setSelectedPeriods(info);
    const periodsLabels = getPeriodsLabelsFromPeriodsIds(info, analysisPeriods.data)    
    setFilters((prev: any) => {
      return {
        ...prev,
        PERIODO: periodsLabels ? periodsLabels : undefined,
      };
    });
  };

  return (
    <Stack
      sx={{
        width: "95%",
        pt: "10px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <CheckmarkSelect
        width={"100%"}
        dates={
          analysisPeriods?.data && analysisPeriods?.data.periodos?.length
            ? analysisPeriods?.data.periodos.map((period) => {
                return {
                  id: period.value,
                  fecha: period.label,
                  posee_gastos: true,
                };
              })
            : []
        }
        selectedPeriods={selectedPeriods}
        setSelectedPeriods={handleSelectedPeriods}
      />
      <CustomButton
        title={t("highCharts.filters")}
        color="blue"
        type="button"
        disabled={tabValue && tabValue !== "SIN ANALISIS" ? false : true}
        onClick={() => handleToggleFiltersModal()}
        margin={false}
      />
    </Stack>
  );
};

export default PeriodFilter;
