import { AddTargetProps } from "./types";
import { Button } from "../buttons";
import { Portal } from "@mui/material";
import { postNewTarget } from "./api";
import { useRef, useState } from "react";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import Dialog from "../dialog/Dialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLanguage from "../../context/LanguageProvider";
import useSnackbar from "../../hooks/useSnackbar";

const AddTargetModal = ({
  openAddTargetModal,
  setOpenAddTargetModal,
  refetchCriteriaData,
  setGetAbsoluteValues,
  expenseTargetSelectOptions,
  refetchCriteriaTarget,
}: AddTargetProps) => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useLanguage();
  const newTargetValueRef: any = useRef(null);
  const newTargetNameRef: any = useRef(null);
  const [newTargetName, setNewTargetName] = useState("");
  const [newTargetError, setNewTargetError] = useState<string>("");
  const [newTargetValue, setNewTargetValue] = useState("");
  const [isLoadingPostNewTarget, setIsLoadingPostNewTarget] = useState(false);
  const [previousDestination, setPreviousDestination] =
    useState<boolean>(false);
  const [
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    setMessageSnackbar,
    severity,
    setSeverity,
    showSnackbar,
  ] = useSnackbar();

  const handleCloseAddTargetModal = () => {
    setOpenAddTargetModal(false);
  };

  const addNewTarget = () => {
    setIsLoadingPostNewTarget(true);
    setOpenAddTargetModal(false);
    postNewTarget(
      axiosPrivate,
      newTargetValue,
      newTargetName,
      previousDestination,
      handleFeedback
    );
  };

  const handleAddNewTarget = () => {
    //Se compara newTargetName con expenseTargetSelectOptions.nombre y verifica si ya existe
    const newTargetNameExists = expenseTargetSelectOptions
      ? expenseTargetSelectOptions?.find(
          (option: any) => option.nombre === newTargetName
        )
      : false;
    if (newTargetNameExists) {
      setNewTargetError(t("configCriteria.addTargetModal.repeatedTargetNameError"));
    } else if (newTargetValue && newTargetName) {
      setNewTargetError("");
      addNewTarget();
    } else {
      setNewTargetError(t("configCriteria.addTargetModal.emptyFieldsError"));
    }
  };

  const togglePreviousDestination = () => {
    setPreviousDestination((prev) => !prev);
  };

  const handleFeedback = (status: number) => {
    if (status === 201) {
      refetchCriteriaTarget();
      refetchCriteriaData();
      setNewTargetValue("");
      setNewTargetName("");
      setGetAbsoluteValues(false);
      newTargetValueRef.value = "";
      newTargetNameRef.value = "";
      setIsLoadingPostNewTarget(false);
      setMessageSnackbar(t("configCriteria.addTargetModal.succesCreatedTarget"));
      setSeverity("success");
      showSnackbar();
    } else {
      setIsLoadingPostNewTarget(false);
      setMessageSnackbar(t("configCriteria.addTargetModal.errorCreatedTarget"));
      setSeverity("error");
      showSnackbar();
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPostNewTarget}
        message={t("configCriteria.addTargetModal.loadingPostTarget")}
      />
      <Dialog
        open={openAddTargetModal}
        handleClose={handleCloseAddTargetModal}
        title={t("configCriteria.addTargetModal.newTarget")}
        actions={
          <>
            <Button
              title={t("configCriteria.addTargetModal.cancel")}
              color="light-grey"
              type="button"
              onClick={handleCloseAddTargetModal}
            />
            <Button
              title={t("configCriteria.addTargetModal.accept")}
              color="blue-greeny"
              type="button"
              onClick={handleAddNewTarget}
            />
          </>
        }
      >
        <form className="cc_form_addTarget_box" id="es-form">
          <div className="cc_addTarget_inputs_container">
            <div className="cc_addTarget_column">
              <label className="cc_step_form_label" htmlFor="newTargetName">
                {t("configCriteria.addTargetModal.name")}
              </label>
              <input
                className={"cc_step_form_input"}
                type="text"
                name="newTargetName"
                ref={newTargetNameRef}
                value={newTargetName}
                onChange={(e) => setNewTargetName(e.target.value)}
                autoComplete="on"
                required
                placeholder={t("configCriteria.addTargetModal.newTargetName")}
              />
            </div>
            <div className="cc_addTarget_column">
              <label className="cc_step_form_label" htmlFor="newTargetValue">
                {t("configCriteria.addTargetModal.target")}
              </label>
              <input
                className={"cc_step_form_input"}
                type="text"
                name="newTargetValue"
                ref={newTargetValueRef}
                value={newTargetValue}
                onChange={(e) =>
                  setNewTargetValue(e.target.value)
                }
                autoComplete="on"
                required
                placeholder={t("configCriteria.addTargetModal.targetPlaceholder")}
                autoFocus
                style={{ minWidth: "360px" }}
              />
            </div>
            <div className="cc_addTarget_column">
              <input
                type="checkbox"
                className="cc_newTarget_checkbox"
                name="function"
                onChange={togglePreviousDestination}
                checked={previousDestination}
              />
              <label className="cc_step_form_label" htmlFor="function">
              {t("configCriteria.addTargetModal.validatePreviousDestinity")}
              </label>
            </div>
          </div>
        </form>
        <p className="cc_newTargetError">{newTargetError}</p>
      </Dialog>
      <Portal>
        <CustomSnackbar
          open={openSnackbar}
          handleClose={handleToggleSnackbar}
          message={messageSnackbar}
          severity={severity}
        />
      </Portal>
    </>
  );
};

export default AddTargetModal;
