import React, { useEffect, useState } from 'react'
import useLanguage from '../../../../../../context/LanguageProvider';
import Dialog from '../../../../../dialog/Dialog';
import { Divider, Stack, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CustomSkeleton from '../../../../../skeleton/CustomSkeleton';
import { transformViewModificationTable } from './transformViewModificationTable';
interface ViewInfoRouteModal {
  config: any,
  open: boolean,
  handleClose: () => void,
  setConfig: any,
}

const ViewInfoModifyRouteModal = ({
  config,
  open,
  handleClose,
  setConfig
}: ViewInfoRouteModal) => {  

  const { t } = useLanguage();
  const [filters, setFilters] = useState<any[]>([])
  const [rowsPreview, setRowsPreview] = useState<any[]>()
  const [columnsPreview, setColumnsPreview] = useState<any[]>()

  useEffect(() => {
    if (config) {
      const filtrosPorRutaUnicos = config.rutas.map((ruta) => {
        return (ruta.filtros)
      }).filter((filtro, index, self) =>
        index === self.findIndex(f => (
          f.columna === filtro.columna && f.valor === filtro.valor
        ))
      );
      setFilters(filtrosPorRutaUnicos.flat())
      // const rutasFiltradas = config.rutas.map((ruta) => {
      //   return (ruta[getInformationColumn(informationsColumns, "Identificador único de ruta")])
      // })
      // setRoutes(rutasFiltradas)

      // const modificacionesFiltradas = config.rutas.map((ruta) => {
      //   const { id, filtros, [getInformationColumn(informationsColumns, "Identificador único de ruta")]: _, ...restroPropiedades } = ruta
      //   return restroPropiedades
      // }).filter((modification, index, self) =>
      //   index === self.findIndex(f => (
      //     f.columna === modification.columna && f.valor === modification.valor
      //   ))
      // );

      // setModications(modificacionesFiltradas)

      const filteredConfig = config.rutas.map((ruta) => {
        const { filtros, ...resto } = ruta;
        return resto
      })

      const { rows, columns } = transformViewModificationTable(
        filteredConfig,
        filteredConfig,
      )

      rows && setRowsPreview(rows)
      columns && setColumnsPreview(columns)
    }
  }, [config])

  const closeAndClean = () => {
    handleClose()
    setConfig()
}

  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      title={t("deltaTexts.simulation.viewInfoModal.routeConfig")}
      maxWidth={"xl"}
    >
      {/* <SimpleBackdrop
open={isLoadingGetAnalysisById}
message={t("simulatorTexts.registerRoutes.registerRoutesAdmin.loading")}
/> */}
      <Stack
        padding="0 50px 15px 50px"
        sx={{
          minHeight: "300px",
          minWidth: "650px",
          width: "100%",
          justifyContent: "center",
          alignItems: "flex-start",
          pb: 1
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
            pt: 1,
            pb: 1
          }}
          color="var(--text-main)"
        >
          <span style={{ color: "var(--text-disabled)" }}>
          {t("deltaTexts.simulation.viewInfoModal.configType")}: {" "}
          </span>
          {t("deltaTexts.simulation.viewInfoModal.modificationRoute")}
        </Typography>
        <Divider sx={{ margin: "5px 0", width: "100%" }} />
        <Typography
          variant="subtitle1"
          sx={{
            width: "100%",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
            wordBreak: "break-word",
            textDecoration: "underline",
            pt: 1,
            pb: 1
          }}
          color="var(--text-main)"
        >
          {t("deltaTexts.simulation.viewInfoModal.filters")}
        </Typography>
        {filters && filters.map((filtro, idx) => {
          return (
            <Typography
              key={idx}
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {filtro?.columna}: {" "}
              <span style={{ color: "var(--text-disabled)", wordBreak: "break-all" }}>
                {filtro?.valor}
              </span>
            </Typography>
          )
        })}
        <Divider sx={{ margin: "5px 0", width: "100%" }} />
        <Typography
          variant="subtitle1"
          sx={{
            width: "100%",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
            wordBreak: "break-word",
            textDecoration: "underline",
            pt: 1,
            pb: 1
          }}
          color="var(--text-main)"
        >
          {t("deltaTexts.simulation.viewInfoModal.modifications")}
        </Typography>
        <Stack sx={{
          height: "100%",
          pb: "20px",
          width: "100%",
          '& .headerGroup--className': {
            backgroundColor: 'var(--bg-main)',
            display: 'flex',
            width: "100%",
            justifyContent: 'center',
            alignItems: 'center',
            border: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "none",
            borderLeft: "none",
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: "solid rgba(224, 224, 224, 1) 1px !important",
          },
          '& .MuiDataGrid-columnHeader--emptyGroup': {
            backgroundColor: 'var(--bg-main)',
            border: "solid rgba(224, 224, 224, 1) 1px",
            borderBottom: "none",
            borderRight: "none"
          },
        }}>
          {rowsPreview && columnsPreview ?
            (
              <DataGridPro
                autoHeight
                rows={rowsPreview}
                columns={columnsPreview}
                disableColumnMenu={true}
                hideFooter
              />
            ) :
            (
              <CustomSkeleton height={5} />
            )
          }
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ViewInfoModifyRouteModal