import { transformData } from "./transformMatchCodeData";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState } from "react";
import BaseModal from "../../dialog/BaseModal";
import ComponentLayout from "../../layout/ComponentLayout";
import CustomizedDialogs from "../../dialog/Dialog";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import NumbersTable from "../../tablesTools/NumbersTable";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import VennChart from "../../Charts/VennChart";
import {
  GET_VALIDATIONS_RESULTS_URL,
  GET_VALIDATION_RESULT_URL,
} from "../../../api/axios";

const MatchCodeDashboard = () => {
  const { t } = useLanguage();
  const [openVennChartModal, toggleVennChartModal] = useDialog();
  const [modalData, setModalData] = useState<any>();
  const [openErrorModal, handleErrorModal] = useDialog();

  const {
    data: dataValidations,
    isLoading: isLoadingValidations,
    isError: isErrorValidations,
  } = useApiQuery(
    GET_VALIDATIONS_RESULTS_URL,
    true,
    t("validations.matchCodeResults.getValidations.error"),
    10000
  );

  const onSuccessGetVennModalInfo = (data: any) => {
    setModalData((prev: any) => ({ ...prev, data }));
    if (data?.error) {
      handleErrorModal();
    } else {
      toggleVennChartModal();
    }
  };

  const {
    data: vennModalInfo,
    isLoading: isLoadingGetVennModalInfo,
    callApi: getVennModalInfo,
  } = useApi(
    GET_VALIDATION_RESULT_URL(""),
    "GET",
    t("validations.matchCodeResults.getVennModalInfo.codes"),
    undefined,
    onSuccessGetVennModalInfo,
    undefined,
    false
  );

  const handleOpenVennChartModal = (
    idValidation: any,
    columnName: string,
    row: any
  ) => {
    setModalData({ idValidation, row, columnName });
    getVennModalInfo(
      `${GET_VALIDATION_RESULT_URL(idValidation)}/?PERIODO=${row[0]}`
    );
  };

  return (
    <ComponentLayout
      title={t("titles.matchesDashboard")}
      icon={<JoinLeftIcon />}
    >
      <SimpleBackdrop
        open={isLoadingGetVennModalInfo}
        message={t("validations.matchCodeResults.getVennModalInfo.loading")}
      />
      <NumbersTable
        data={dataValidations}
        isLoading={isLoadingValidations}
        isError={isErrorValidations}
        transformData={transformData}
        onNumberClick={handleOpenVennChartModal}
      />
      <BaseModal
        open={openVennChartModal}
        title={`${modalData?.columnName} - ${modalData?.row[0]}`}
        cleanAndClose={toggleVennChartModal}
        openConfirmationModal={false}
        toggleConfirmationModal={() => <></>}
        confirmationModalMessage={""}
        disabledAcceptButton={false}
        height="100%"
      >
        <VennChart data={vennModalInfo} />
      </BaseModal>
      <CustomizedDialogs
        open={openErrorModal}
        handleClose={handleErrorModal}
        title="Error"
      >
        <Typography sx={{ minWidth: "500px", color: "var(--text-main)" }}>
          {modalData?.data?.mensaje}
        </Typography>
      </CustomizedDialogs>
    </ComponentLayout>
  );
};

export default MatchCodeDashboard;
