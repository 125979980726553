import Stack from "@mui/material/Stack";
import Dialog from "../../dialog/Dialog";
import useLanguage from "../../../context/LanguageProvider";
import { Typography } from "@mui/material";
import { GridValueGetterParams } from '@mui/x-data-grid';
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns = (t: any) => {
    return [
        {
            field: 'nombre',
            headerName: t("simulatorTexts.viewInfoRuleModal.columns.measure"),
            width: 250,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.nombre || ''}`,
        },
        {
            field: 'variabilidad',
            headerName: t("simulatorTexts.viewInfoRuleModal.columns.variability"),
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.variabilidad || '0'} %`,
        },
        {
            field: 'variable_dependiente',
            headerName: t("simulatorTexts.viewInfoRuleModal.columns.costDriver"),
            type: 'number',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.variable_dependiente ? params.row.variable_dependiente.nombre : "-",
        },
    ];
}

interface ViewInfoRuleModalProps {
    rule: any;
    open: boolean;
    handleClose: () => void;
}

const ViewInfoRuleModal = ({
    rule,
    open,
    handleClose,
}: ViewInfoRuleModalProps) => {

    const rows = rule?.analisis.medidas;
    const { t } = useLanguage();

    return (
        <div>
            <Dialog
                open={open}
                handleClose={handleClose}
                title={t("simulatorTexts.viewInfoRuleModal.infoSimulationRule")}
                maxWidth={"lg"}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    padding="0 100px 30px 100px"
                    position="relative"
                    sx={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                    <Stack sx={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start"
                    }}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                textAlign: "left",
                                textTransform: "uppercase",
                                fontWeight: "700",
                                letterSpacing: "-0.5px",
                            }}
                            color="var(--text-main)"
                        >
                            <span style={{ color: "var(--text-disabled)" }}>
                                {t("simulatorTexts.viewInfoRuleModal.rule")}: {" "}
                            </span>
                            {rule?.nombre}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                textAlign: "left",
                                textTransform: "uppercase",
                                fontWeight: "700",
                                letterSpacing: "-0.5px",

                            }}
                            color="var(--text-main)"
                        >
                            <span style={{ color: "var(--text-disabled)" }}>
                                {t("simulatorTexts.viewInfoRuleModal.analysisToUse")}: {" "}
                            </span>
                            {rule?.analisis?.nombre}
                        </Typography>
                    </Stack>
                    <Stack sx={{ height: `${70 + rows.length * 50}px`, maxHeight: "800px", width: '700px' }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns(t)}
                            hideFooter
                            disableSelectionOnClick
                            sx={{
                                boxShadow: 3,
                                padding: "0 10px 10px 10px",
                            }}
                        />
                    </Stack>
                </Stack>
            </Dialog>
        </div>
    );
};

export default ViewInfoRuleModal;
