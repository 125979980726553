import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { useSnackbar, VariantType } from "notistack";
import useInfoContext from "./useInfoContext";

/**
 * @name useAxiosPrivate
 * @description Hook que devuelve una instancia de axios con la configuración de autenticación.  Este hook adjunta a todas las peticiones o respuestas hechas con axiosPrivate los interceptores de axios, los cuales permiten obtener un nuevo token en caso de que el anterior esté vencido.  Estos interceptores no se remueven solos una vez terminado, y si no los removemos manualmente, se ir´na acumulando; por eso se utiliza una función clean up que se ejecuta una vez terminado el componente.
 * @returns {function}
 * @category Hooks
 */

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth, setAuth }: any = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoggedIn }: any = useInfoContext();

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
        //Acá se chequea si la solicitud se hizo sin autenticación (es decir, si es una petición inicial), si es así, se le agrega el token.  Por otro lado, si ya estaba seteada, sabemos que es un retry
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.access_token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        //Se accede a la solicitud a través de la propiedad config de la solicitud
        const prevRequest = error?.config;
        //Si el error es de autenticación, refrescamos el token, también chequeamos que no se haya enviado ya una solicitud de refresco para no caer en un bucle infinito, por eso seteamos una variable que nos indica si ya se ha enviado una solicitud de refresco como true
        if (
          (error?.response?.status === 403 ||
            error?.response?.status === 401) &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();

          if (!newAccessToken) {
            auth.access_token &&
              handleClickVariant("Ha expirado la sesión.", "warning");
              setLoggedIn(false)
            setAuth({});
          } else {
            //Actualizamos la petición con el nuevo token en la cabecera de autenticación y hacemos la petición nuevamente
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axiosPrivate(prevRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
