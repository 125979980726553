import { Divider, List, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import CustomAccordion from "../../../../accordion/CustomAccordion";
import ListItemComponent from "./ListItemComponent";
import {
  divider_measure_card_styles,
  list_measure_card_styles,
  select_measure_card_container_styles,
} from "../../../../../styles/app-styles";
import useLanguage from "../../../../../context/LanguageProvider";
interface SelectMeasureCardProps {
  measures: any[];
  title: string;
  handleAddMeasureToFormula: (id: number, name: string) => void;
  medidaInLast: boolean;
  formula: any;
  type: string;
}

const SelectMeasureCard = ({
  handleAddMeasureToFormula,
  measures,
  title,
  medidaInLast,
  formula,
  type,
}: SelectMeasureCardProps) => {
  const { t } = useLanguage();
  const [filterByName, setFilterByName] = useState("");

  const handleFilterByName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByName(e.target.value);
  };

  const filteredMeasures = measures
    ? measures.filter((measure: any) =>
        measure.nombre.toLowerCase().includes(filterByName.toLowerCase())
      )
    : [];

  const filteredMeasuresCreated = filteredMeasures?.filter(
    (measure: any) => measure.sub_tipo === "CREADAS"
  );
  const filteredMeasuresBucket = filteredMeasures?.filter(
    (measure: any) => measure.sub_tipo === "BUCKET"
  );
  const filteredMeasuresGrouper = filteredMeasures?.filter(
    (measure: any) => measure.sub_tipo === "BUCKET-AGRUPADOR"
  );

  return (
    <Stack sx={select_measure_card_container_styles}>
      <Typography variant="h5">{title}</Typography>
      <Divider sx={divider_measure_card_styles} />
      <TextField
        type="text"
        placeholder="Buscar por nombre"
        variant="outlined"
        fullWidth
        value={filterByName}
        onChange={handleFilterByName}
      />
      <List sx={list_measure_card_styles}>
        {type === "expenses" && (
          <>
            <CustomAccordion title={t("general.created")}>
              <ListItemComponent
                measuresList={filteredMeasuresCreated}
                handleAddMeasureToFormula={handleAddMeasureToFormula}
                medidaInLast={medidaInLast}
                formula={formula}
              />
            </CustomAccordion>
            <CustomAccordion title={t("general.bucket")}>
              <ListItemComponent
                measuresList={filteredMeasuresBucket}
                handleAddMeasureToFormula={handleAddMeasureToFormula}
                medidaInLast={medidaInLast}
                formula={formula}
              />
            </CustomAccordion>
            <CustomAccordion title={t("general.bucketGrouper")}>
              <ListItemComponent
                measuresList={filteredMeasuresGrouper}
                handleAddMeasureToFormula={handleAddMeasureToFormula}
                medidaInLast={medidaInLast}
                formula={formula}
              />
            </CustomAccordion>
          </>
        )}
        {type !== "expenses" && (
          <ListItemComponent
            measuresList={filteredMeasures}
            handleAddMeasureToFormula={handleAddMeasureToFormula}
            medidaInLast={medidaInLast}
            formula={formula}
          />
        )}
      </List>
    </Stack>
  );
};

export default SelectMeasureCard;
