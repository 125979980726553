import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AddButton from "../../../../buttons/AddButton";
import {
  selectMultiStyles,
  selectStyles,
} from "../../../../configCriteria/select.styles";
import Select from "../../../../forms/Select";
import { DeleteForever } from "../../../../icons";
import { CATEGORIC_FUNCTIONS, NUMERIC_FUNCTIONS } from "../../../constants";
import { filterOptions, findTypeByColumn } from "../../../utils";
import useLanguage from "../../../../../context/LanguageProvider";

interface GroupByRowSelectsProps {
  columns?: any;
  columnSelectRef?: any;
  columnSelectedOption?: any;
  columnOptionsChangeHandler?: any;
  functionSelectRef?: any;
  functionSelectedOption?: any;
  functionOptionsChangeHandler?: any;
  handleAddGroupByRow?: any;
  handleDeleteAllRows?: any;
  groupByRows?: any;
}

const GroupByRowSelects = ({
  columns,
  columnSelectRef,
  columnSelectedOption,
  columnOptionsChangeHandler,
  functionSelectRef,
  functionSelectedOption,
  functionOptionsChangeHandler,
  handleAddGroupByRow,
  handleDeleteAllRows,
  groupByRows,
}: GroupByRowSelectsProps) => {
  const { t } = useLanguage();

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={5.5}>
        <Select
          reference={columnSelectRef}
          styles={selectStyles(columnSelectedOption)}
          options={columns}
          onChange={columnOptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "dataModelText.measureManagement.configMeasure.groupByRowSelects.chooseColumn"
          )}
          isClearable
          defaultValue={columnSelectedOption}
          isDisabled={
            columns === undefined || columns === null || columns.length === 0
          }
        />
      </Grid>
      <Grid item xs={5}>
        <Select
          id="function"
          reference={functionSelectRef}
          styles={selectMultiStyles(functionSelectedOption)}
          options={filterOptions(
            findTypeByColumn(columnSelectedOption, columns) === "CATEGORICA"
              ? CATEGORIC_FUNCTIONS
              : NUMERIC_FUNCTIONS,
            groupByRows
          )}
          className="cc_select"
          name="function"
          onChange={functionOptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "dataModelText.measureManagement.configMeasure.groupByRowSelects.chooseFunction"
          )}
          isDisabled={
            columns === undefined ||
            columns === null ||
            columns.length === 0 ||
            columnSelectedOption === undefined
          }
          isClearable
          defaultValue={functionSelectedOption}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        item
        xs={1}
      >
        <AddButton align={true} onClick={() => handleAddGroupByRow()} />
      </Grid>
    </>
  );
};

export default GroupByRowSelects;
