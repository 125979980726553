import { Paper } from "@mui/material";
import LeftItemsList from "./LeftItemsList";
import CustomAccordion from "../../../accordion/CustomAccordion";
import useLanguage from "../../../../context/LanguageProvider";
import { BUCKET, BUCKET_GROUPER, COMPOUNDS, CREATED, SALES } from "../../constants";
interface LeftCustomListProps {
  items: any;
  isMeasureEnabled: (value: any, left: any[], right: any[]) => boolean;
  right: any[];
  checked: readonly number[];
  handleToggle: (value: any) => () => void;
  handleSelectAllGrouped: any;
}

const LeftCustomList = ({
  items,
  isMeasureEnabled,
  right,
  checked,
  handleToggle,
  handleSelectAllGrouped,
}: LeftCustomListProps) => {

  const { t } = useLanguage();

  const itemsCreated = items?.filter(
    (measure: any) => measure.sub_tipo === CREATED
  );

  const itemsBucket = items?.filter(
    (measure: any) => measure.sub_tipo === BUCKET
  );

  const itemsGrouper = items?.filter(
    (measure: any) => measure.sub_tipo === BUCKET_GROUPER
  );

  const itemsSales = items?.filter(
    (measure: any) => measure.sub_tipo === SALES
  );

  const itemsComposed = items?.filter(
    (measure: any) => measure.sub_tipo === COMPOUNDS
  );

  const availableCompoundsItems: any[] = [];
  itemsComposed && itemsComposed.map((value) => {
    if (isMeasureEnabled(value, itemsComposed, right)) {
      availableCompoundsItems.push(value)
    }
  })

  const allItemsChecked = (checked: any, group: string) => {
    if (group === COMPOUNDS) {
      // es para chequear las medidas compuestas y que no se "check" aquellas que están deshabilitadas
      if (availableCompoundsItems.length < 1) {
        return false
      } else {
        return availableCompoundsItems.every(item =>
          checked.some(checkedItem => checkedItem.id === item.id)
        );
      }
    } else if (group === CREATED) {
      return (itemsCreated && checked) && itemsCreated.every(item =>
        checked.some(checkedItem => checkedItem.id === item.id)
      );
    } else if (group === BUCKET) {
      return (itemsBucket && checked) && itemsBucket.every(item =>
        checked.some(checkedItem => checkedItem.id === item.id)
      );
    } else if (group === SALES) {
      return (itemsSales && checked) && itemsSales.every(item =>
        checked.some(checkedItem => checkedItem.id === item.id)
      );
    } else if (group === BUCKET_GROUPER) {
      return (itemsGrouper && checked) && itemsGrouper.every(item =>
        checked.some(checkedItem => checkedItem.id === item.id)
      );
    }
  }

  return (
    <Paper
      sx={{
        width: 600,
        height: 390,
        overflow: "auto",
        marginBottom: "15px",
        border: "",
      }}
    >
      <>
        <CustomAccordion title={t("general.created")} handleSelectAll={itemsCreated.length > 0 && checked ? handleSelectAllGrouped : undefined} allItemsChecked={allItemsChecked(checked, CREATED)} group={CREATED}>
          <LeftItemsList
            items={itemsCreated}
            isMeasureEnabled={isMeasureEnabled}
            right={right}
            checked={checked}
            handleToggle={handleToggle}
          />
        </CustomAccordion>
        <CustomAccordion title={t("general.bucket")} handleSelectAll={itemsBucket.length > 0 ? handleSelectAllGrouped : undefined} allItemsChecked={allItemsChecked(checked, BUCKET)} group={BUCKET}>
          <LeftItemsList
            items={itemsBucket}
            isMeasureEnabled={isMeasureEnabled}
            right={right}
            checked={checked}
            handleToggle={handleToggle}
          />
        </CustomAccordion>
        <CustomAccordion title={t("general.bucketGrouper")} handleSelectAll={itemsGrouper.length > 0 ? handleSelectAllGrouped : undefined} allItemsChecked={allItemsChecked(checked, BUCKET_GROUPER)} group={BUCKET_GROUPER}>
          <LeftItemsList
            items={itemsGrouper}
            isMeasureEnabled={isMeasureEnabled}
            right={right}
            checked={checked}
            handleToggle={handleToggle}
          />
        </CustomAccordion>
        <CustomAccordion title={t("general.sales")} handleSelectAll={itemsSales.length > 0 ? handleSelectAllGrouped : undefined} allItemsChecked={allItemsChecked(checked, SALES)} group={SALES}>
          <LeftItemsList
            items={itemsSales}
            isMeasureEnabled={isMeasureEnabled}
            right={right}
            checked={checked}
            handleToggle={handleToggle}
          />
        </CustomAccordion>
        <CustomAccordion title={t("general.composed")} handleSelectAll={itemsComposed.length > 0 && availableCompoundsItems.length > 0 ? handleSelectAllGrouped : undefined} allItemsChecked={allItemsChecked(checked, COMPOUNDS)} group={COMPOUNDS}>
          <LeftItemsList
            items={itemsComposed}
            isMeasureEnabled={isMeasureEnabled}
            right={right}
            checked={checked}
            handleToggle={handleToggle}
          />
        </CustomAccordion>
      </>
    </Paper>
  );
};

export default LeftCustomList;
