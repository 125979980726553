import { Box } from "@mui/material";
import { convertArrayToText } from "../../taskManager/utils";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizedTable from "../../virtualizatedTable/VirtualizedTable";
import {
  card_subtitle_styles,
  left_key_text_styles,
} from "../../../styles/app-styles";

const SalesCard = ({ item, showInfo }: any) => {
  const { t } = useLanguage();
  const dataGetSalesParams = item;

  const tranformObjectToTable = (keys) => {
    const rowObject: any = { id: 1 };
    keys.forEach((key) => {
      if (item?.columnas_clave[key]) {
        rowObject[key] = item?.columnas_clave[key];
      }
    });
    return rowObject;
  };

  const COLUMNS1 = [
    "venta_bruta",
    "venta_kg",
    "venta_m3",
    "venta_unidades",
    "venta_cajas",
    "venta_tarimas",
    "cogs",
    "descuentos",
    "devoluciones",
  ];

  const ROWS1 = [tranformObjectToTable(COLUMNS1)];

  const COLUMNS2 = [
    "codigo_cliente",
    "codigo_ruta",
    "codigo_producto",
    "categoria_producto",
    "marca",
    "unidad_negocio",
    "canal",
    "modelo_atencion",
    "tipo_ruta",
    "fecha",
  ];

  const ROWS2 = [tranformObjectToTable(COLUMNS2)];

  return (
    <>
      <Stack sx={{ padding: "3px 3px 0 3px" }}>
        {showInfo && (
          <>
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Nombre: <Box sx={left_key_text_styles}>{item.nombre}</Box>
            </Typography>
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Muestra cargada:{" "}
              <Box sx={left_key_text_styles}>{item.archivo.name}</Box>
            </Typography>
            <br />
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Columnas Transaccionales Clave :
            </Typography>
            {ROWS1?.length && (
              <Stack width="100%">
                <VirtualizedTable
                  columns={
                    transformDataSimpleTable(
                      ROWS1,
                      undefined,
                      undefined,
                      t,
                      120
                    ).columns
                  }
                  rows={
                    transformDataSimpleTable(
                      ROWS1,
                      undefined,
                      undefined,
                      t,
                      120
                    ).rows
                  }
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={25}
                  tableHeight={"80px"}
                  hideFooter={true}
                  toolbar={false}
                  columnsButton={false}
                  filterButton={false}
                  exportButton={false}
                  checkboxSelection={false}
                  setSelectedRows={() => <></>}
                  headerHeight={25}
                />
              </Stack>
            )}
            <br />
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Columnas Descriptivas Clave :
            </Typography>
            {ROWS2?.length && (
              <Stack width="100%">
                <VirtualizedTable
                  columns={
                    transformDataSimpleTable(
                      ROWS2,
                      undefined,
                      undefined,
                      t,
                      120
                    ).columns
                  }
                  rows={
                    transformDataSimpleTable(
                      ROWS2,
                      undefined,
                      undefined,
                      t,
                      120
                    ).rows
                  }
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={25}
                  tableHeight={"80px"}
                  hideFooter={true}
                  toolbar={false}
                  columnsButton={false}
                  filterButton={false}
                  exportButton={false}
                  checkboxSelection={false}
                  setSelectedRows={() => <></>}
                  headerHeight={25}
                />
              </Stack>
            )}
            <br />
            <Typography variant="subtitle2" sx={card_subtitle_styles}>
              Máximo nivel de detalle:
              <br />
              <Box sx={left_key_text_styles}>
                {dataGetSalesParams?.maximo_detalle &&
                  convertArrayToText(dataGetSalesParams.maximo_detalle)}
              </Box>
            </Typography>
          </>
        )}
      </Stack>
    </>
  );
};

export default SalesCard;
