import { ChartOfList, ChartType } from "../types";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import FormLayout from "../../forms/FormLayout";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";

interface Step0ChartsProps {
  selectedChart: any;
  chartType: ChartType;
  setChartType: (chartType: ChartType) => void;
  handleRestart: () => void;
  chartList: ChartOfList[];
  cols?: number;
  height?: string;
}

const Step0Charts = ({
  selectedChart,
  chartType,
  setChartType,
  handleRestart,
  chartList,
  cols,
  height
}: Step0ChartsProps) => {
  useEffect(() => {
    if (chartType === undefined) {
      setChartType(selectedChart?.tipo);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRestart();
    setChartType(event.target.value as ChartType);
  };

  const handleClickImage = (chart: ChartOfList) => {
    handleRestart();
    setChartType(chart.type);
  };

  return (
    <FormLayout width="100%">
      <ImageList
        gap={25}
        sx={{ width: "100%", height: height ? height : 285, padding: "10px" }}
        cols={cols ? cols : 5}
        rowHeight={150}
      >
        {chartList.map((chart: ChartOfList) => {
          return (
            <Stack key={chart.type}>
              <ImageListItem
                sx={{
                  border: "1px solid var(--bg-light)",
                  padding: "8px",
                  transition: "all 0.4s",
                  objectFit: "cover",
                  boxShadow:
                    chartType === chart.type
                      ? "2px 2px 10px 2px rgba(0, 0, 0, 0.2)"
                      : "none",
                  ":hover": {
                    cursor: "pointer",
                    boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.2)",
                  },
                  ":active": { transform: "scale(0.98)" },
                }}
                onClick={() => handleClickImage(chart)}
              >
                <img src={chart.image} style={{width: "100%", height: "100%"}}/>
              </ImageListItem>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="center"
              >
                <Radio
                  checked={chartType === chart.type}
                  onChange={handleChange}
                  value={chart.type}
                  name="radio-buttons"
                />
                <Typography variant="body1" sx={{ color: "var(--text-main)", fontSize: "14px" }}>
                  {chart.type}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </ImageList>
    </FormLayout>
  );
};

export default Step0Charts;
