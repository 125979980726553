import Stack from '@mui/material/Stack'
import { white_shadow_box_styles } from '../../styles/app-styles'

const WhiteShadowBox = ({children}) => {
  return (
    <Stack sx={white_shadow_box_styles}>
        {children}
    </Stack>
  )
}

export default WhiteShadowBox