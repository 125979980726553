import {
  ANALISIS_VISTAS,
  ANALYSIS_PERIODS,
  ELEMENTS_URL,
  ELEMENT_URL,
  GROUPERS_URL,
  INCOMING_VALUES,
  PUT_ORDEN_ELEMENTO,
} from "../../api/axios";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect } from "react";
import { useViewDashboard } from "../../context/ViewDashboardProvider";
import useApi from "../../hooks/useApi";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import ViewDashboard from "./ViewDashboard";
import ComponentLayout from "../layout/ComponentLayout";
import AddchartIcon from "@mui/icons-material/Addchart";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";

const AnalysisDashboard = () => {
  const { userRolData }: any = useInfoContext();
  const { t } = useLanguage();
  const {
    setAnalysisViews,
    setAnalysisPeriods,
    setAgrupations,
    tabValue,
  } = useViewDashboard();

  const {
    isLoading: isLoadingGetAnalysisViews,
    data: dataAnalysisViews,
    refetch: refetchGetAnalysisViews,
  } = useApiQuery(ANALISIS_VISTAS, false, t("dataModel.getAnalysis.error"));

  const {
    isLoading: isLoadingGetAnalysisPeriods,
    callApi: getAnalysisPeriods,
    data: dataGetAnalysisPeriod,
  } = useApi(
    ANALYSIS_PERIODS(tabValue),
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    undefined,
    undefined,
    false
  );

  const { isLoading: isLoadingGetAgrupations, data: dataGetAgrupations } =
    useApiQuery(GROUPERS_URL, true, t("dataModel.getGroups.error"));

  useEffect(() => {
    if (dataAnalysisViews) {
      setAnalysisViews({
        isLoading: isLoadingGetAnalysisViews,
        data: dataAnalysisViews,
        refetch: refetchGetAnalysisViews,
      });
    }
  }, [dataAnalysisViews, isLoadingGetAnalysisViews]);

  useEffect(() => {
    setAnalysisPeriods({
      isLoading: isLoadingGetAnalysisPeriods,
      data: dataGetAnalysisPeriod,
      getData: getAnalysisPeriods,
    });
  }, [dataGetAnalysisPeriod, isLoadingGetAnalysisPeriods]);

  useEffect(() => {
    if (dataGetAgrupations) {
      setAgrupations({
        isLoading: isLoadingGetAgrupations,
        data: dataGetAgrupations,
      });
    }
  }, [dataGetAgrupations, isLoadingGetAgrupations]);

  return (
    <ComponentLayout
      title={t("menuItemsOptions.dashboardCollapseList.analysis.title")}
      icon={<AddchartIcon />}
    >
      <SimpleBackdrop
        open={
          isLoadingGetAnalysisViews ||
          isLoadingGetAnalysisPeriods ||
          isLoadingGetAgrupations
        }
        message={t("general.loading")}
      />
      <ViewDashboard
        thereArePeriodFilter={true}
        thereAreChipFilters={true}
        thereAreViews={true}
        thereAreTabs={true}
        rolEdit={userRolData && userRolData.dashboards.visualizacion_y_edicion}
        rolCreate={
          userRolData && userRolData.dashboards.visualizacion_y_creacion
        }
        actualUrl="/dashboard/highcharts"
        ELEMENTS_URL={ELEMENTS_URL}
        ELEMENT_URL={ELEMENT_URL}
        PERIODS_URL={ANALYSIS_PERIODS(tabValue)}
        INCOMING_VALUES={INCOMING_VALUES(tabValue)}
        PUT_ORDEN_ELEMENT_URL={PUT_ORDEN_ELEMENTO}
        dashboardType={"ANALISIS"}
      />
    </ComponentLayout>
  );
};

export default AnalysisDashboard;
