import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import logoPhi from "../../assets/logoPhi.png";
import useApi from "../../hooks/useApi";
import { RESEND_VERIFICATION_URL, VERIFICATION_URL } from "../../api/axios";
import useInfoContext from "../../hooks/useInfoContext";
import useAuth from "../../hooks/useAuth";
import {
  title_styles,
  verificaction_form_input_styles,
  verification_container_styles,
  verification_description_styles,
  verification_form_box_styles,
  verification_form_styles,
  verification_logo_container,
  verification_message_styles,
  verification_password_container_styles,
  verification_password_icon_styles,
  video_container_styles,
} from "../../styles/app-styles";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";

/**
 * @component
 * @name Verification
 * @description - Componente que muestra el formulario para el two-factor authentication.
 * @returns {JSX.Element}
 * @category Login
 */

const Verification = () => {
  const passwordRef: any = useRef();
  const navigate = useNavigate();
  const { setAuth }: any = useAuth();
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const { loggedIn, setLoggedIn }: any = useInfoContext();

  const { t } = useLanguage();

  const handleSuccesAuthCode = () => {
    setCode("");
    navigate("/empresa");
  };

  const handleSendAuthCode = (e: any) => {
    e.preventDefault();
    submitCode();
  };

  const { isLoading: isLoadingPostCode, callApi: submitCode } = useApi(
    VERIFICATION_URL,
    "POST",
    t('verificationCode.codes'), 
    { codigo: code },
    handleSuccesAuthCode,
    undefined,
    false
  );

  const {
    data: postResendCodeResponse,
    error: errorResendPostCode, //deberíamos gestionar este error
    isLoading: isLoadingResendPostCode,
    callApi: resendCode,
  } = useApi(
    RESEND_VERIFICATION_URL,
    "POST",
    t('verificationCode.resend.codes'),
    undefined,
    undefined,
    undefined,
    true
  );

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
    passwordRef.current.focus();
  }, []);

  const handlePassword = () => {
    passwordRef.current.type = showPassword ? "password" : "text";
    setShowPassword((prev) => !prev);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setAuth({});
    navigate("/login/"); 
  };

  return (
    <Stack sx={verification_container_styles}>
      <Box sx={video_container_styles}>
        <img
          className="login_logo"
          src={"https://i.imgur.com/WdjAwrF.png"}
          alt="logo"
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Box>
      <Stack sx={verification_form_styles}>
        <Box sx={verification_logo_container}>
          <img width="120px" src={logoPhi} alt="logo" />
        </Box>
        <Typography sx={title_styles}>{t("codeVerification.twoStepsVerification")}</Typography>
        <Typography sx={verification_description_styles}>
        {t("codeVerification.completeWithTheCodeSentToEmail")}
        </Typography>
        <form onSubmit={handleSendAuthCode} method="POST">
          <Stack sx={verification_form_box_styles}>
            <Box sx={verification_password_container_styles}>
              <input
                style={verificaction_form_input_styles}
                id="password"
                type="text"
                ref={passwordRef}
                required
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter the code"
              />
              {showPassword ? (
                <VisibilityIcon
                  sx={verification_password_icon_styles}
                  onClick={handlePassword}
                />
              ) : (
                <VisibilityOffIcon
                  sx={verification_password_icon_styles}
                  onClick={handlePassword}
                />
              )}
            </Box>
            {isLoadingPostCode && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
            <Typography sx={verification_message_styles}>
            {t("codeVerification.codeSentToEmail")}
            </Typography>
            {/* <Typography sx={verification_resend_styles} onClick={() => resendCode()}>
            No recibí el código, volver a enviar.
          </Typography> */}
            <Stack direction="row" mb="15px">
              <Button
                title={t("codeVerification.cancel")}
                color="grey"
                type="button"
                onClick={handleLogout}
              />
              <Button
                title={t("codeVerification.enter")}
                color="blue"
                type="submit"
                disabled={isLoadingPostCode}
              />
            </Stack>
          </Stack>
        </form>
      </Stack>  
    </Stack>
  );
};

export default Verification;
