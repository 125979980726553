import {
  draggable_element_styles,
  element_typography_styles,
  view_compact_icon_styles,
} from "../../../styles/app-styles";
import { Item } from "../../basicGrid/BasicGrid";
import { Stack, Tooltip } from "@mui/material";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import CardIcon from "../../companyParameterization/StepperView/Step3/shared/CardIcon";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import useLanguage from "../../../context/LanguageProvider";
import { truncateString } from "../../parameterization/utils";

interface DraggableElementProps {
  element: any;
  toggleDeleteElement: () => void;
  setElement: (element) => void;
  togglePnlModal: () => void;
  handleEditElement: (event, element) => void;
  dragItem: any;
  dragOverItem: any;
  onDropActions: (initialItem, finalItem) => void;
}

const DraggableRowElement = ({
  element,
  toggleDeleteElement,
  setElement,
  togglePnlModal,
  handleEditElement,
  dragItem,
  dragOverItem,
  onDropActions,
}: DraggableElementProps) => {
  const { t } = useLanguage();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [hovered, setHovered] = useState(false);

  const deleteElement = (e) => {
    setElement(element);
    toggleDeleteElement();
    e.stopPropagation();
  };

  /********************************************** Gestión DRAG & DROP **************************************************/

  const dragStart = (e, elem) => {  
      dragItem.current = elem;  
  };

  const dragEnter = (elem) => {
    if (dragOverItem) {
      dragOverItem.current = elem;
    }
  };

  const handleDrop = () => {
    //dragItem == initialItem
    //dragOverItem == finalItem
    if (dragItem.current === undefined || dragOverItem.current === undefined) {
      return;
    } else {
      onDropActions(dragItem.current, dragOverItem.current);
    }
  };

  return (
    <Item
      id="gridItem"
      elevation={1}
      sx={draggable_element_styles()}
      onDragStart={(e) => dragStart(e, element)}
      onDragEnter={(e) => dragEnter(element)}
      onDrop={(e) => handleDrop()}
      onDragOver={(e) => e.preventDefault()}
      draggable={true}
    >
      <Tooltip title={t("dashboard.moveElement")}>
        <span
          className="dragIcon"
          style={{ fontSize: "20px", marginRight: "5px" }}
          onMouseOver={(e) => {
            e.currentTarget.style.cursor = "grab";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.cursor = "default";
          }}
        >
          <DragIndicatorIcon
            sx={{
              ...view_compact_icon_styles,
              fontSize: "20px",
              zIndex: 2,
            }}
          />
        </span>
      </Tooltip>
      <Tooltip title={element.label} placement="top-start">
        <Stack sx={element_typography_styles}>
          <span>{truncateString(element.label, 100)}</span>
        </Stack>
      </Tooltip>
      <Stack direction="row" gap={0.3}>
        <CardIcon
          Icon={EditIcon}
          onClickAction={(e) => handleEditElement(e, element)}
          tooltipText={t("companyParam.editName")}
        />
        {/* <CardIcon
          Icon={DeleteIcon}
          onClickAction={deleteElement}
          tooltipText={t("general.delete")}
        /> */}
      </Stack>
    </Item>
  );
};

export default DraggableRowElement;
