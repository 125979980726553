export const NUMERIC_FUNCTIONS = [
    {
        value: "SUMAR",
        label: "SUMAR"
    },
    {
        value: "PROMEDIAR",
        label: "PROMEDIAR"
    },
    {
        value: "MAXIMO",
        label: "MAXIMO"
    },
    {
        value: "MINIMO",
        label: "MINIMO"
    },
    {
        value: "CONTAR",
        label: "CONTAR"
    },
    {
        value: "CONTAR.UNICO",
        label: "CONTAR VALORES UNICOS"
    },
    {
        value: "PRIMERO",
        label: "PRIMERO"
    },
    {
        value: "ULTIMO",
        label: "ULTIMO"
    },
    {
        value: "VALORES.UNICOS",
        label: "VALORES UNICOS"
    }
]

export const CATEGORIC_FUNCTIONS = [
    {
        value: "CONTAR",
        label: "CONTAR"
    },
    {
        value: "CONTAR.UNICO",
        label: "CONTAR VALORES UNICOS"
    },
    {
        value: "PRIMERO",
        label: "PRIMERO"
    },
    {
        value: "ULTIMO",
        label: "ULTIMO"
    },
    {
        value: "VALORES.UNICOS",
        label: "VALORES UNICOS"
    }
]

type FunctionLabel = {
    [key: string]: string
}

export const FUNCTION_LABELS: FunctionLabel = {
    "SUMAR": "SUMAR",
    "PROMEDIAR": "PROMEDIAR",
    "MAXIMO": "MAXIMO",
    "MINIMO": "MINIMO",
    "CONTAR": "CONTAR",   
    "CONTAR.UNICO": "CONTAR VALORES UNICOS",
    "PRIMERO": "PRIMERO",
    "ULTIMO": "ULTIMO",
    "VALORES.UNICOS": "VALORES UNICOS",
    "DIVIDIR": "DIVISIÓN",
    "SUMA-PRODUCTO": "SUMA.PRODUCTO",
}

export const TYPE_OPTIONS: FunctionLabel = {
    "texto": "Texto",
    "decimal": "Número Decimal",
    "entero": "Número Entero",
    "fecha": "Fecha",
    "boolean": "Boolean",
}

export const CREATED = "CREADAS";
export const BUCKET = "BUCKET";
export const BUCKET_GROUPER = "BUCKET-AGRUPADOR";
export const SALES = "VENTAS";
export const COMPOUNDS = "COMPUESTOS";