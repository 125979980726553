import { stepper_buttons_container_styles } from "../../styles/app-styles";
import Button from "../buttons/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../context/LanguageProvider";
interface StepperButtonsProps {
  handleBack: () => void;
  handleNext: () => void;
  handleRestart: () => void;
  onFinishClick: () => void;
  isBackButtonDisabled?: boolean;
  isFinishButtonDisabled?: boolean;
  isNextButtonDisabled?: boolean;
}

const StepperButtons = ({
  handleBack,
  handleNext,
  handleRestart,
  onFinishClick,
  isBackButtonDisabled,
  isFinishButtonDisabled,
  isNextButtonDisabled,
}: StepperButtonsProps) => {
  const { t } = useLanguage();

  return (
    <Stack
      sx={stepper_buttons_container_styles}
    >
      <Button
        title={t('general.previous')}
        color="blue-greeny"
        type="button"
        onClick={handleBack}
        disabled={isBackButtonDisabled}
      />
     {/* {handleRestart && <Tooltip
        title={t("indicatorsManagementTexts.configCharts.resetAllGraphSteps")}
        arrow
      >
        <span>
          <Button
            title={t("general.cleanAll")}
            color="grey"
            type="button"
            onClick={handleRestart}
          />
        </span>
      </Tooltip>} */}
      <Button
        title={t("general.finish")}
        color="blue"
        type="button"
        onClick={onFinishClick}
        disabled={isFinishButtonDisabled}
      />
      <Button
        title={t("general.next")}
        color="blue-greeny"
        type="button"
        onClick={handleNext}
        disabled={isNextButtonDisabled}
      />
    </Stack>
  );
};

export default StepperButtons;
