import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import { Item } from "../basicGrid/BasicGrid";
import Kpi from "./Kpi";
import KpiError from "./KpiError";
import { kpi_cards_container_styles, kpi_item_box_styles } from "../../styles/app-styles";

interface KpisProps {
  nombre?: string;
  indicadores: Kpi[];
  bgColor?: () => string;
}

export type Kpi = {
  valor: number;
  unidad: string;
  kpi: string;
  id: number;
  activo: boolean;
};

const Kpis = ({ nombre, indicadores, bgColor }: KpisProps) => {
  return (
    <Stack sx={{ width: "100%", margin: "0px !important" }}>
      {nombre ? <Typography
        sx={{
          padding: "5px 40px 0px 35px",
          marginTop: "0px !important",
          alignSelf: "flex-start",
          borderRadius: "10px 10px 0px 0px",
          fontWeight: "700",
          color: "var(--text-main)",
          backgroundColor: bgColor,
        }}
      >
        {nombre}
      </Typography> : <></>}
      <Box
        sx={kpi_cards_container_styles}
      >
        <Grid container spacing={3}>
          {indicadores &&
            indicadores.map((item: any, index: number) => (
              <Grid item xs={3} key={item.id}>
                <Grow in={true} {...{ timeout: 1000 + index * 500 }}>
                  <Item
                    id="gridItem"
                    elevation={12}                
                    sx={kpi_item_box_styles(item.posee_error)}
                  >
                    {item.posee_error ? (
                      <KpiError
                        key={item.id}
                        kpi={item.kpi}
                        lista_periodos_error={item.lista_periodos_error}
                      />
                    ) : (
                      <Kpi
                        id={item.id}
                        key={item.id}
                        valor={item.valor}
                        unidad={item.unidad}
                        kpi={item.kpi}
                      />
                    )}
                  </Item>
                </Grow>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default Kpis;
