import { Button } from "../buttons";
import { POST_NEW_VIEW } from "../../api/axios";
import { useState } from "react";
import { useViewDashboard } from "../../context/ViewDashboardProvider";
import Dialog from "../dialog/Dialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import useLanguage from "../../context/LanguageProvider";

const SaveNameModal = () => {
  const { t } = useLanguage();
  const {
    tabValue,
    openNewViewModal,
    toggleNewViewModal,
    analysisViews,
  } = useViewDashboard();
  const [newName, setNewName] = useState("");
  const viewsByAnalysis = analysisViews?.data?.find(
    (analysis) => analysis.id === tabValue
  );

  const isValidName = (listToCheck: any[], value: string) => {
    return listToCheck
      ? !(
          listToCheck.some(
            (item: any) => item.nombre.toLowerCase() === value.toLowerCase()
          ) || value.toLowerCase() === "default"
        )
      : true;
  };

  const onSuccessPostNewView = () => {
    analysisViews?.refetch();
    setNewName("");
  }

  const { isLoading: isLoadingPostNewView, callApi: postNewView } = useApi(
    POST_NEW_VIEW,
    "POST",
    t("dashboard.newView.codes"),
    undefined,
    onSuccessPostNewView
  );

  const confirmSendView = () => {
    toggleNewViewModal();
    postNewView(POST_NEW_VIEW(tabValue), {
      nombre: newName,
    });
  };

  return (
    <Dialog
      open={openNewViewModal}
      handleClose={toggleNewViewModal}
      maxWidth="md"
      title={t("dashboard.createView")}
    >
      <SimpleBackdrop
        open={isLoadingPostNewView}
        message={t("dashboard.newView.loading")}
      />
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "400px",
          alignItems: "center",
          minHeight: "130px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "20px",
            color: "var(--text-main)",
            maxWidth: "400px",
            textAlign: "start",
          }}
        >{t("dashboard.newView.createView")}</Typography>
        <TextField
          id="filled-textarea"
          label={t("dashboard.insertName")}
          placeholder={t("dashboard.placeholderWriteName")}
          size="small"
          multiline
          variant="outlined"
          helperText={
            !isValidName(viewsByAnalysis?.vistas, newName)
              ? "El nombre ya existe"
              : ""
          }
          error={!isValidName(viewsByAnalysis?.vistas, newName)}
          fullWidth
          value={newName}
          onChange={(event: any) => setNewName(event.target.value)}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
            },
          }}
          required
        />

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={toggleNewViewModal}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={confirmSendView}
            color="blue-greeny"
            disabled={
              !newName || !isValidName(viewsByAnalysis?.vistas, newName)
            }
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SaveNameModal;
