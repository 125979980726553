import { active_inactive_text } from "../../../styles/app-styles";
import { filterDataByName } from "../utils";
import { Tab, TabValue } from "../../colorTabs/types";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import BasicGrid from "../../basicGrid/BasicGrid";
import ColorTabs from "../../colorTabs/ColorTabs";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import EditDefaultNameModal from "./configMeasure/ConfigExpenses/EditDefaultNameModal";
import FixedAddButton from "../../buttons/FixedAddButton";
import MeasureCard from "./MeasureCard";
import NoDataBox from "../../layout/NoDataBox";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoExpensesModal from "./configMeasure/ConfigExpenses/ViewInfoExpensesModal";
import {
  DELETE_MEASURE_URL,
  GET_MEASURE_URL,
  MEASURES_URL,
} from "../../../api/axios";
import useInfoContext from "../../../hooks/useInfoContext";
interface MeasureExpensesAdminProps {
  tabValue: TabValue;
  openExpensesConfigMeasure: boolean;
  toggleExpensesConfigMeasure: () => void;
  goToConfigMeasure: () => void;
  dataToShow: any;
  setDataToShow: any;
  handleEdit: (e: any, id: number) => void;
  handleCopy: (e: any, id: number) => void;
}

const MeasureExpensesAdmin = ({
  tabValue,
  openExpensesConfigMeasure,
  toggleExpensesConfigMeasure,
  goToConfigMeasure,
  dataToShow,
  setDataToShow,
  handleEdit,
  handleCopy,
}: MeasureExpensesAdminProps) => {
  const { t, language } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [expensesTabValue, setExpensesTabValue] = useState<TabValue>(
    t("dataModelText.measureManagement.measureAdmin.tabs.created")
  );
  const [configMode, setConfigMode] = useState<
    "CREATE" | "EDIT" | "INFO" | "COPY" | ""
  >("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [measureToDelete, setMeasureToDelete] = useState<number | undefined>();
  const [
    openDeleteMeasureConfirmationModal,
    handleToggleDeleteMeasureConfirmationModal,
  ] = useDialog();
  const [openViewInfoExpensesModal, toggleViewInfoExpensesModal] = useDialog();
  const [openEditNameDialog, handleToggleEditNameDialog] = useDialog();

  useEffect(() => {
    setExpensesTabValue(
      t("dataModelText.measureManagement.measureAdmin.tabs.created")
    );
    openExpensesConfigMeasure && toggleExpensesConfigMeasure();
  }, [language]);

  /********************************* LLAMADAS PARA PEDIR INFO *****************************/

  const {
    isLoading: isLoadingGetExpenses,
    data: dataGetExpenses,
    refetch: refetchGetExpenses,
  } = useApiQuery(
    MEASURES_URL("GASTOS"),
    true,
    t("dataModel.getMeasures.error")
  );

  const onSuccessGetMeasure = (data: any) => {
    setDataToShow(data);
  };

  const {
    isLoading: isLoadingGetMeasure,
    data: dataGetMeasure,
    callApi: getMeasure,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getMeasure.codes"),
    undefined,
    onSuccessGetMeasure,
    undefined,
    false
  );

  const onSuccessDeleteMeasure = () => {
    configByTabValue(expensesTabValue).refetch();
    setFilteredData([]);
  };

  const { isLoading: isLoadingDeleteMeasure, callApi: deleteMeasureById } =
    useApi(
      DELETE_MEASURE_URL(measureToDelete),
      "DELETE",
      t("dataModel.deleteMeasure.codes"),
      undefined,
      onSuccessDeleteMeasure
    );

  useEffect(() => {
    if (
      (configMode === "EDIT" || configMode === "COPY") &&
      dataToShow &&
      isTabDefault
    ) {
      handleToggleEditNameDialog();
    } else if (configMode === "INFO" && dataToShow) {
      setTimeout(() => {
        configByTabValue(tabValue).toggleInfoModal();
      }, 500);
    } else if (configMode === "") {
      refetchGetExpenses();
    }
  }, [configMode, dataToShow]);

  /********************************* INFO SEGÚN TABVALUE *******************************************/

  const expensesTabs: Tab[] = [
    {
      value: t("dataModelText.measureManagement.measureAdmin.tabs.created"),
      label: t("dataModelText.measureManagement.measureAdmin.tabs.created"),
    },
    {
      value: t("dataModelText.measureManagement.measureAdmin.tabs.default"),
      label: t("dataModelText.measureManagement.measureAdmin.tabs.default"),
    },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
    setExpensesTabValue(newValue);
    configByTabValue(newValue)?.setFilteredData();
  };

  const dataExpensesCreated =dataGetExpenses ? dataGetExpenses?.filter(
    (measure: any) => measure.sub_tipo === "CREADAS"
  ) : []

  const dataExpensesDefault = dataGetExpenses ? dataGetExpenses?.filter(
    (measure: any) =>
      measure.sub_tipo === "BUCKET" || measure.sub_tipo === "BUCKET-AGRUPADOR"
  ) : []

  const dataExpensesBucket = dataGetExpenses ? dataGetExpenses?.filter(
    (measure: any) => measure.sub_tipo === "BUCKET"
  ): []

  const dataExpensesAgrupador = dataGetExpenses ? dataGetExpenses?.filter(
    (measure: any) => measure.sub_tipo === "BUCKET-AGRUPADOR"
  ) : []

  const isDataExpensesCreated =
    (filteredData?.length !== 0 &&
      filteredData?.filter((measure) => measure.sub_tipo === "CREADAS")
        ?.length !== 0) ||
    dataExpensesCreated;

  const dataExpensesCreatedOrFiltered =
    (filteredData?.length &&
      filteredData?.filter((measure) => measure.sub_tipo === "CREADAS")) ||
    dataExpensesCreated;

  const isDataExpensesBucket =
    (filteredData?.length !== 0 &&
      filteredData?.filter((measure) => measure.sub_tipo === "BUCKET")
        ?.length !== 0) ||
    dataExpensesBucket;

  const dataExpensesBucketOrFiltered =
    (filteredData?.length &&
      filteredData?.filter((measure) => measure.sub_tipo === "BUCKET")) ||
    dataExpensesBucket;

  const isDataExpensesAgrupador =
    (filteredData?.length !== 0 &&
      filteredData?.filter((measure) => measure.sub_tipo === "BUCKET-AGRUPADOR")
        ?.length !== 0) ||
    dataExpensesAgrupador;

  const dataExpensesAgrupadorOrFiltered =
    (filteredData?.length &&
      filteredData?.filter(
        (measure) => measure.sub_tipo === "BUCKET-AGRUPADOR"
      )) ||
    dataExpensesAgrupador;

  const isTabCreated =
    expensesTabValue ===
    t("dataModelText.measureManagement.measureAdmin.tabs.created");

  const isTabDefault =
    expensesTabValue ===
    t("dataModelText.measureManagement.measureAdmin.tabs.default");

  const configByTabValue = (expensesTabValue: string) => {
    switch (expensesTabValue) {
      case t("dataModelText.measureManagement.measureAdmin.tabs.created"):
        return {
          data: () => dataExpensesCreated,
          loading: isLoadingGetExpenses,
          refetch: () => refetchGetExpenses(),
          setFilteredData: () => setFilteredData(dataExpensesCreated),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataExpensesCreated, searchValue, setFilteredData),
          toggleInfoModal: () => toggleViewInfoExpensesModal(),
        };
      case t("dataModelText.measureManagement.measureAdmin.tabs.default"):
        return {
          data: () => dataExpensesDefault,
          loading: isLoadingGetExpenses,
          refetch: () => refetchGetExpenses(),
          setFilteredData: () => setFilteredData(dataExpensesDefault),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataExpensesDefault, searchValue, setFilteredData),
          toggleInfoModal: () => toggleViewInfoExpensesModal(),
        };

      default:
        return {
          data: () => dataExpensesCreated,
          loading: isLoadingGetExpenses,
          refetch: () => refetchGetExpenses(),
          setFilteredData: () => setFilteredData(dataExpensesCreated),
          filterDataByName: (searchValue: string) =>
            filterDataByName(dataExpensesCreated, searchValue, setFilteredData),
          toggleInfoModal: () => toggleViewInfoExpensesModal(),
        };
    }
  };

  /************************************** FUNCIONES  *******************************************/

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    configByTabValue(expensesTabValue)?.filterDataByName(e.target.value);
  };

  const handleEditDefaultMeasure = (e: any, id: number) => {
    setConfigMode("EDIT");
    getMeasure(GET_MEASURE_URL(id));
  };

  const handleInfo = (e: any, id: number) => {
    setConfigMode("INFO");
    getMeasure(GET_MEASURE_URL(id));
  };

  const handleDelete = () => {
    deleteMeasureById();
    handleToggleDeleteMeasureConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setMeasureToDelete(id);
    handleToggleDeleteMeasureConfirmationModal();
  };

  const handleToggleInfoModal = () => {
    configByTabValue(expensesTabValue).toggleInfoModal();
    setConfigMode("");
    setDataToShow(undefined);
  };

  return (
    <>
      <>
        <SimpleBackdrop
          open={isLoadingGetExpenses}
          message={t(
            "dataModelText.measureManagement.measureAdmin.searchingMeasures"
          )}
        />
        <SimpleBackdrop
          open={isLoadingGetMeasure}
          message={t(
            "dataModelText.measureManagement.measureAdmin.loadingMeasures"
          )}
        />
        <SimpleBackdrop
          open={isLoadingDeleteMeasure}
          message={t("dataModel.deleteMeasure.loading")}
        />
        <ColorTabs
          value={expensesTabValue}
          handleChange={handleChangeTab}
          tabs={expensesTabs}
          marginBottom="0px"
          fontSize="14px"
        />
        <Stack spacing={2} sx={{ width: "100%" }}>
          <SearchInput
            value={searchValue}
            handleChange={handleSearch}
            placeholder={t(
              "dataModelText.measureManagement.measureAdmin.searchByName"
            )}
          />
        </Stack>
        {isTabCreated && isDataExpensesCreated && (
          <>
            <BasicGrid
              data={dataExpensesCreatedOrFiltered}
              Card={MeasureCard}
              handleEdit={userRolData &&
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion && handleEdit
              }
              handleCopy={userRolData &&
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion && handleCopy
              }
              handleInfo={handleInfo}
              handleDelete={userRolData &&
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion && handleClickOnDelete
              }
              showInfo={true}
            />
            {!configByTabValue(expensesTabValue).loading &&
              !dataExpensesCreatedOrFiltered && (
                <NoDataBox
                  text={t(
                    "dataModelText.measureManagement.measureAdmin.notMeasuresYet"
                  )}
                />
              )}
            {userRolData && (userRolData.administracion_de_medidas_agrupaciones_y_analisis
              .visualizacion_y_creacion ||
              userRolData.administracion_de_medidas_agrupaciones_y_analisis
                .visualizacion_y_edicion) && (
              <FixedAddButton handleClick={goToConfigMeasure} />
            )}
          </>
        )}
        {isTabDefault && (
          <>
            <Typography sx={active_inactive_text}>
              {t("dataModelText.measureManagement.measureAdmin.tabs.bucket")}
            </Typography>
            {isDataExpensesBucket && (
              <BasicGrid
                data={dataExpensesBucketOrFiltered}
                Card={MeasureCard}
                handleEdit={userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion && handleEditDefaultMeasure
                }
                handleCopy={undefined}
                handleInfo={handleInfo}
                handleDelete={userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion && handleClickOnDelete
                }
                showInfo={true}
              />
            )}
            {!configByTabValue(expensesTabValue).loading &&
              !dataExpensesBucketOrFiltered && (
                <NoDataBox
                  text={t(
                    "dataModelText.measureManagement.measureAdmin.notMeasuresYet"
                  )}
                />
              )}
            <Typography sx={active_inactive_text}>
              {t(
                "dataModelText.measureManagement.measureAdmin.tabs.bucketAgrupator"
              )}
            </Typography>
            {isDataExpensesAgrupador && (
              <BasicGrid
                data={dataExpensesAgrupadorOrFiltered}
                Card={MeasureCard}
                handleEdit={userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion && handleEditDefaultMeasure
                }
                handleCopy={undefined}
                handleInfo={handleInfo}
                handleDelete={userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion && handleClickOnDelete
                }
                showInfo={true}
              />
            )}
            {!configByTabValue(expensesTabValue).loading &&
              !dataExpensesAgrupadorOrFiltered && (
                <NoDataBox
                  text={t(
                    "dataModelText.measureManagement.measureAdmin.notMeasuresYet"
                  )}
                />
              )}
          </>
        )}
      </>
      <ConfirmationModal
        open={openDeleteMeasureConfirmationModal}
        handleClose={handleToggleDeleteMeasureConfirmationModal}
        handleAccept={handleDelete}
        message={t(
          "dataModelText.measureManagement.measureAdmin.removeMeasure"
        )}
        title={t("dataModelText.measureManagement.measureAdmin.confirm")}
      />
      <ViewInfoExpensesModal
        info={dataToShow}
        open={openViewInfoExpensesModal}
        handleClose={handleToggleInfoModal}
        title={dataToShow?.nombre}
      />
      <EditDefaultNameModal
        openEditNameDialog={openEditNameDialog}
        handleToggleEditNameDialog={handleToggleEditNameDialog}
        refetchData={refetchGetExpenses}
        measureToEdit={dataToShow}
        configMode={configMode}
        setConfigMode={setConfigMode}
        dataGetExpenses={dataGetExpenses}
      />
    </>
  );
};

export default MeasureExpensesAdmin;
