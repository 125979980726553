import { createContext, useReducer } from "react";
import { GroupersAction, GroupersState } from "./types";

const GroupersContext = createContext<any>({});

export const GroupersProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialGroupersState: GroupersState = {
    id: undefined,
    grouperName: "",
    bases: [],
    step2: {
      archivo: undefined,
      columna: undefined,
      columnasAUsar: []
    },
    grouperList: []
  };

  function groupersReducer(groupersState: GroupersState, action: any) {
    switch (action.type) {
      case "SET_BASES":
        return {
          ...groupersState,
          bases: action.payload,
        };
      case "SET_GROUPER_NAME":
        return {
          ...groupersState,
          grouperName: action.payload,
        };
      case "SET_STEP_2":
        return {
          ...groupersState,
          step2: action.payload,
        };
      case "SET_GROUPER":
        return {
          ...groupersState,
          id: action.payload.id,
          grouperName: action.payload.nombre,
          bases: action.payload.bases,
          step2: {
            archivo: action.payload.step2.archivo,
            columna: action.payload.step2.columna,
            columnasAUsar: action.payload.step2.columnas_a_usar
          }
        };
      case "CLEAN":
        return {
          ...groupersState,
          id: undefined,
          grouperName: "",
          bases: [],
          step2: {
            archivo: undefined,
            columna: undefined,
            columnasAUsar: []
          }
        };
      case "SET_GROUPER_LIST":
        return {
          ...groupersState,
          grouperList: action.payload,
        };
      default:
        throw new Error();
    }
  }

  const [groupersState, groupersDispatch] = useReducer<
    React.Reducer<GroupersState, GroupersAction>
  >(groupersReducer, initialGroupersState);
  return (
    <GroupersContext.Provider value={{ initialGroupersState, groupersReducer, groupersState, groupersDispatch }}>{children}</GroupersContext.Provider>
  );
};

export default GroupersContext;
