import { convertArrayToText } from "../../../taskManager/utils";
import { Item } from "../../../basicGrid/BasicGrid";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import Delete from "../../../icons/Delete";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";

interface RowProps {
  index: number;
  rows: any[];
  setRows: any;
  row: any;
  titles: string[];
  deletable?: boolean;
}

const GroupingRow = ({ rows, setRows, row, titles, index, deletable = true }: RowProps) => {
  const handleDeleteFilter = () => {
    const newRows = rows?.filter((r: any, i) => {
      return i !== index;
    });
    setRows(newRows);
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          {deletable && <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />}
        </Stack>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {convertArrayToText(
              row?.columnas?.map((column: any) => {
                return column?.label;
              })
            )}
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={11 / titles.length}>
        <Item>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {row?.funcion?.label || row?.funcion}
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={0.5}></Grid>
    </>
  );
};

export default GroupingRow;
