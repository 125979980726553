import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react'

const CardHeader: React.FC<{ title: string }> = ({ title }) => (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "28px",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: "var(--text-main)",
          fontWeight: "700",
          textTransform: "uppercase",
          lineHeight: "1.1",
          fontSize: "15px"
        }}
      >
        {title}
      </Typography>
    </Stack>
  );

export default CardHeader