import { grid_container2_styles } from "../../../../styles/app-styles";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../../../marketBasket/MarketBasketConfig/Shared/GridTitles";
import NewColumnRow from "./NewColumnRow";
import useLanguage from "../../../../context/LanguageProvider";

const NewColumnView = ({ element }) => {
  const { t } = useLanguage();

  const titles = [
    t("general.name"),
    t("Valor / Operación"),
    t("Valor fijo / Columnas"),
    `${t("general.dividingColumns")}/${t("general.substractingColumns")}`,
  ];

  return (
    <Grid sx={{...grid_container2_styles, minHeight: "100px"}} container spacing={1}>
      <GridFiveTitles titles={titles} />
      {element &&
        element.agregaciones &&
        element.agregaciones.length > 0 &&
        element.agregaciones.map((row: any, index: number) => (
          <NewColumnRow
            key={index}
            row={row}
            rows={element.agregaciones}
            setRows={() => <></>}
            titles={titles}
            deletable={false}
          />
        ))}
    </Grid>
  );
};

export default NewColumnView;
