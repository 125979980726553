import { GridColumns } from "@mui/x-data-grid-pro";
import { getInformationColumn, roundNumber } from "../../../utils";
import { convertNumberToMoney } from "../../../../parameterization/utils";

export const getColumnTitle = (column, t, codRutaColumn, cedis) => {
    switch (column) {
        case "cedis":
            return (
                <>
                    {cedis ? cedis : t("simulatorTexts.routeAdministration.getColumnsCanceledRoutes.cedis")}
                </>
            );
        case "ruta":
            return (
                <>
                    {codRutaColumn ? codRutaColumn : t("simulatorTexts.routeAdministration.getColumnsCanceledRoutes.route") }
                </>
            );
        case "retencion":
            return (
                <>
                    {t("simulatorTexts.routeAdministration.getColumnsCanceledRoutes.retentionSales")}
                </>
            );
        default:
            return "";
    }
};

export const getColumnsCanceledRoutes = (rows, t: any, informationsColumns?: string) => {
    const uniqueFlags: string = rows.reduce((flags, row) => {
        Object.keys(row).forEach(flag => {
            if (flag !== "id") {
                flags.add(flag);
            }
        });
        return flags;
    }, new Set());

    const columnsNames = [...Array.from(uniqueFlags)].sort((a, b) => {
        if (a === 'cedis') return -1;
        if (b === 'cedis') return 1;
        if (a === 'retencion') return 1;
        if (b === 'retencion') return -1;
        if (a === 'ruta') return -1;
        if (b === 'ruta') return 1;
        return 0;
    });

    const columns: GridColumns = columnsNames.map((column: string) => {
        return {
            field: column,
            headerName: column,
            headerClassName: "dm_header_column",
            renderHeader: () => {
                return (
                    <strong className="dm_column_title">{column === "cedis" || column === "ruta" || column === "retencion" ? getColumnTitle(column, t, getInformationColumn(informationsColumns, "Identificador único de ruta"), getInformationColumn(informationsColumns, "Centro de Distribución")) : column}</strong>
                )
            },
            flex: 1,
            headerAlign: "center",
            maxWidth: column === "cedis" ? 300 : 1500,
            align: "center",
            wrapText: true,
            renderCell: (params: any) => {
                if (column === getInformationColumn(informationsColumns, "Venta Bruta") || column === getInformationColumn(informationsColumns, "Costos de Servir") || column === getInformationColumn(informationsColumns, "Profit to Serve") || column === getInformationColumn(informationsColumns, "Costo TOTAL de Descuentos y Acuerdos") || column === getInformationColumn(informationsColumns, "Costo TOTAL de Ventas y Distribucion") || column === getInformationColumn(informationsColumns, "Costo de Mercaderia Vendida") ) {
                    return <>{convertNumberToMoney(params.value)}</>
                } else if(column === getInformationColumn(informationsColumns, "Clientes por Ruta")){
                    return <>{roundNumber(params.value,2)}</>
                }else if(column === getInformationColumn(informationsColumns, "Volumen en Kilogramos")){
                    return <>{roundNumber(params.value,2)}</>
                }else if(column === getInformationColumn(informationsColumns, "Volumen en Unidades")){
                    return <>{roundNumber(params.value,2)}</>
                }
                else{
                    return <>{params.value ? params.value : "-"}</>
                }
            },
        };
    });

    return columns;
};