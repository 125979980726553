import {
  chips_container_styles,
  cursor_pointer_styles,
  row_sb_100_styles,
} from "../../../../styles/app-styles";
import AddButton from "../../../buttons/AddButton";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import DeleteButton2 from "../../../buttons/DeleteButton2";
import ConfirmationModal from "../../../dialog/ConfirmationModal";
import useLanguage from "../../../../context/LanguageProvider";
interface ChipsBoxProps {
  chipsList: string[] | { value: number; label: string }[];
  editChip: (line: string, mapInfo: any) => void;
  handleDelete: (chipToDelete: any, mapInfo?: any) => void;
  dragItem?: any;
  dragOverItem?: any;
  onDropActions?: (copyListItems: any[], indexList: any[]) => void;
  onClickAddButton: any;
  onDelete?: () => void;
  openConfirmationModal: boolean;
  toggleConfirmationModal: () => void;
  draggable?: boolean;
  mapInfo?: any;
}

const ChipsBox = ({
  chipsList,
  editChip,
  handleDelete,
  dragItem,
  dragOverItem,
  onDropActions,
  onClickAddButton,
  onDelete,
  openConfirmationModal,
  toggleConfirmationModal,
  draggable,
  mapInfo
}: ChipsBoxProps) => {
  const { t } = useLanguage();
  const dragStart = (e, position) => {
    if (dragItem) dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    if(dragOverItem){
      dragOverItem.current = position;
    }
  };

  const drop = (e) => {
    if (dragItem.current === undefined || dragOverItem.current === undefined) {
      return;
    } else {
      const copyListItems = [...chipsList];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = undefined;
      dragOverItem.current = undefined;
      const indexList = copyListItems.map((item: any) => item.id);
      onDropActions && onDropActions(copyListItems, indexList);
    }
  };
  
  return (
    <Stack sx={row_sb_100_styles}>
      <Stack sx={chips_container_styles}>
        {chipsList && chipsList.length > 0 &&
          chipsList.map((item, index) => {
            return (
              <Chip
                key={item.value ? item.value : item}
                icon={
                  <EditIcon
                    onClick={() => editChip(item, mapInfo)}
                    sx={cursor_pointer_styles}
                  />
                }
                label={
                  item.label || (item.value && item.label === "")
                    ? item.label
                    : item
                }
                onDelete={() => handleDelete(item, mapInfo)}
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                draggable={draggable ? draggable : false}
              />
            );
          })}
      </Stack>
      <Stack gap={1}>
        <AddButton onClick={() => onClickAddButton(mapInfo)} />
        {onDelete && <DeleteButton2 onClick={toggleConfirmationModal} />}
      </Stack>
      {onDelete && (
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={onDelete}
          message={t("general.deleteAll")}
          title={t("general.delete")}
        />
      )}
    </Stack>
  );
};

export default ChipsBox;
