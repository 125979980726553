import { useEffect, useRef, useState } from 'react'
import Dialog from '../../dialog/Dialog'
import { Grid, Stack, TextField } from '@mui/material'
import { Button } from '../../buttons'
import useLanguage from '../../../context/LanguageProvider'
import InputTitle from '../../forms/InputTitle'

interface EditRowModalProps {
    open: boolean;
    handleClose: () => void;
    rowToEdit: any;
    waterfallRows: any;
    setWaterfallRows: any;
}

const EditRowModal = ({ open, handleClose, rowToEdit, waterfallRows, setWaterfallRows }: EditRowModalProps) => {

    const { t } = useLanguage()
    const waterfallColumnNameRef: any = useRef(null);
    const [waterfallColumnName, setWaterfallColumnName] = useState("");

    useEffect(() => {
        if (rowToEdit) {
            setWaterfallColumnName(rowToEdit.columnName)
        }
    }, [rowToEdit])

    const isValidName = (value: string) => {
        return waterfallRows
            ? !waterfallRows.some(
                (item: any) => item?.nombre?.toLowerCase() === value?.toLowerCase()
            )
            : true;
    };

    const closeAndClean = () => {
        handleClose()
        waterfallColumnNameRef.current.value = "";
    }
    
    const handleAcceptModifyName = () => {
        if (rowToEdit) {
            const updatedRows = [...waterfallRows];
            const rowIndex = updatedRows.findIndex(item => item === rowToEdit);
            if (rowIndex !== -1) {
                updatedRows[rowIndex] = {
                    ...rowToEdit,
                    columnName: waterfallColumnName
                };
                setWaterfallRows(updatedRows);
                handleClose();
            }
        }
    };

    return (
        <Dialog
            open={open}
            handleClose={handleAcceptModifyName}
            title={t("dashboard.editName")}
            maxWidth={"lg"}
            actions={
                <>
                    <Button
                        title={t("general.cancel")}
                        color="grey"
                        type="button"
                        onClick={closeAndClean}
                    />
                    <Button
                        title={t("general.edit")}
                        color="blue"
                        type="button"
                        onClick={() => handleAcceptModifyName()}
                    />
                </>
            }
            sx={{
                width: "100%"
            }}
        >
            {rowToEdit && <Stack sx={{
                width: "500px",
                p: "20px"
            }}>
                <InputTitle title={t("basicGrid.name")} />
                <Grid item xs={3.5}>

                    <TextField
                        placeholder={t("dataModelText.measureManagement.configMeasure.groupByRowSelects.columnName")}
                        ref={waterfallColumnNameRef}
                        size="small"
                        multiline
                        variant="outlined"
                        helperText={
                            !isValidName(waterfallColumnName) ? t("dataModelText.measureManagement.configMeasure.groupByRowSelects.nameAlreadyExists") : ""
                        }
                        error={
                            waterfallColumnName === "" || !isValidName(waterfallColumnName)
                        }
                        fullWidth
                        value={waterfallColumnName}
                        onChange={(e) => setWaterfallColumnName(e.target.value)}
                        FormHelperTextProps={{
                            sx: {
                                color: "var(--text-error)",
                            },
                        }}
                        required
                    />
                </Grid>
            </Stack>}
        </Dialog>
    )
}

export default EditRowModal