import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Stack, Typography } from "@mui/material";

interface ISimpleBackdrop {
  open: boolean;
  message?: string;
}

export default function SimpleBackdrop({ open, message }: ISimpleBackdrop) {
  return (
    <Box sx={{margin: 0}}>
      <Backdrop sx={{ color: "#fff", zIndex: "100 !important" }} open={open}>
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress   
            sx={{ color: "var(--icon-secondary) !important" }}
          />
          <Typography variant="body2" sx={{ color: "white !important", marginTop: "15px" }}>
            {message}
          </Typography>
        </Stack>
      </Backdrop>
    </Box>
  );
}
