import { Box, Portal, Stack, Tooltip, Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { AddButton, Button } from "../../buttons";
import CustomInput from "../../forms/CustomInput";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileType } from "../types";
import { deleteFileName, postFileName } from "../api";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomSnackbar from "../../snackbar/CustomSnackbar";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import useDialog from "../../../hooks/useDialog";
import NotAllowedToDeleteFileModal from "./NotAllowedToDeleteFileModal";
import NotAllowedToAddFileModal from "./NotAllowedToAddFileModal";
import useLanguage from "../../../context/LanguageProvider";
interface IAllowedFilesModalContent {
  allowedFileNames: FileType[] | undefined;
  refetchAllowedFiles: () => void;
  closeModal: () => void;
  files: any[] | undefined;
  defaultValue?: string;
}

const AllowedFilesModalContent = ({
  allowedFileNames,
  closeModal,
  refetchAllowedFiles,
  files,
  defaultValue,
}: IAllowedFilesModalContent) => {
  const { t } = useLanguage();
  const inputFileNameRef: any = useRef(null);
  const [fileName, setFileName] = useState<string>("");
  const [selectedFileForDelete, setSelectedFileForDelete] =
    useState<FileType>();
  const [messageErrorName, setMessageErrorName] = useState<string | boolean>(
    false
  );
  const [messageErrorFormat, setMessageErrorFormat] = useState<
    string | boolean
  >(false);
  const axiosPrivate = useAxiosPrivate();
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();
  const [
    openNotAllowedToDeleteFileModal,
    handleToggleNotAllowedToDeleteFileModal,
  ] = useDialog();
  const [openNotAllowedToAddFileModal, handleToggleNotAllowedToAddFileModal] =
    useDialog();
  const [
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    setMessageSnackbar,
    severity,
    setSeverity,
    showSnackbar,
  ] = useSnackbar();

  useEffect(() => {
    if (defaultValue) {
      handleChangeValue(defaultValue);
    }
  }, []);

  const handleChangeValue = (value: any) => {
    const fileNameExists = allowedFileNames?.find(
      (file: any) => file.nombre.toLowerCase() === value.toLowerCase()
    );
    const validFormatRegExp = new RegExp("^[a-zA-Z0-9_-]*$");
    const validFormat = validFormatRegExp.test(value) && value.length < 30;

    if (fileNameExists) {
      setMessageErrorName(
        t("dataManagementTexts.allowFilesModalContent.fileNameExists")
      );
    } else {
      setMessageErrorName(false);
    }
    if (!validFormat) {
      setMessageErrorFormat(
        t("dataManagementTexts.allowFilesModalContent.nameRules")
      );
    } else {
      setMessageErrorFormat(false);
    }
    setFileName(value.toUpperCase());
  };

  const handleAddFileName = () => {
    if (!messageErrorFormat && !messageErrorName && fileName.length) {
      if (fileName === "PRE_GASTOS" || fileName === "PRE-GASTOS") {
        handleToggleNotAllowedToAddFileModal();
      } else {
        postFileName(axiosPrivate, fileName, handleAddFileFeedback);
      }
    }
  };

  const handleDeleteFileName = () => {
    if (selectedFileForDelete) {
      deleteFileName(
        axiosPrivate,
        selectedFileForDelete.id,
        handleDeleteFileFeedback
      );
    }
  };

  const handleDeleteFileFeedback = (status: number) => {
    if (status === 200) {
      refetchAllowedFiles();
      handleToggleConfirmationModal();
      setMessageSnackbar(
        t("dataManagementTexts.allowFilesModalContent.nameFileDeleted")
      );
      setSeverity("success");
      showSnackbar();
    } else {
      handleToggleConfirmationModal();
      setMessageSnackbar(
        t("dataManagementTexts.allowFilesModalContent.errorAllowFileDeleting")
      );
      setSeverity("error");
      showSnackbar();
    }
  };

  const handleAddFileFeedback = (status: number) => {
    if (status === 201) {
      refetchAllowedFiles();
      inputFileNameRef.value = "";
      handleChangeValue("");
      setMessageSnackbar(
        t("dataManagementTexts.allowFilesModalContent.successAllowFileDeleting")
      );
      setSeverity("success");
      showSnackbar();
    } else {
      setMessageSnackbar(
        t("dataManagementTexts.allowFilesModalContent.errorAddAllowFile")
      );
      setSeverity("error");
      showSnackbar();
    }
  };

  const handleDeleteConfirmation = (file: FileType) => {
    if (checkIfFileNameCouldBeDeleted(file.nombre)) {
      setSelectedFileForDelete(file);
      handleToggleConfirmationModal();
    } else {
      setSelectedFileForDelete(file);
      handleToggleNotAllowedToDeleteFileModal();
    }
  };

  //Esta función analiza si el nombre de archivo permitido que se quiere eliminar, cuenta con archivos cargados previamente
  const checkIfFileNameCouldBeDeleted = (fileName: string) => {
    if (files) {
      const filesWithFileName = files?.filter((rowFile) => {
        return rowFile.archivos.some((file: any) => {
          return file.nombre === fileName;
        });
      });
      return !(filesWithFileName?.length > 0);
    } else {
      return true;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "440px",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", width: "100%", height: "70px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            "& ::placeholder": { textTransform: "initial" },
            "& input": { textTransform: "uppercase" },
          }}
        >
          <Typography sx={{ color: "var(--text-info)" }}>
            {t("dataManagementTexts.allowFilesModalContent.addNewAllowFile")}:
          </Typography>
          <CustomInput
            name="fileName"
            inputRef={inputFileNameRef}
            value={fileName}
            setValue={handleChangeValue}
            placeholder={t(
              "dataManagementTexts.allowFilesModalContent.fileName"
            )}
            autoFocus={true}
            defaultValue="Holi"
          />
        </Box>
        <Stack
          justifyContent={"space-between"}
          sx={{
            margin: 0,
            justifyContent: "center",
            display: "flex",
            height: "90px",
          }}
        >
          <AddButton
            onClick={handleAddFileName}
            disabled={
              messageErrorFormat || messageErrorName || !fileName ? true : false
            }
          />
        </Stack>
      </Box>
      <Box sx={{ height: "50px" }}>
        {messageErrorName && (
          <Typography sx={{ color: "red" }}>{messageErrorName}</Typography>
        )}
        {messageErrorFormat && (
          <Typography sx={{ color: "red" }}>{messageErrorFormat}</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginLeft: "14px",
        }}
      >
        <Typography
          sx={{
            color: "var(--text-main)",
            fontWeight: "600",
            fontSize: "18px",
            marginBottom: "10PX",
          }}
        >
          {t("dataManagementTexts.allowFilesModalContent.allowedFiles")}
        </Typography>
        {allowedFileNames?.map((file) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0"
              padding="0"
              width={"97%"}
              key={file.id}
              sx={{
                border: "1px solid rgb(0 0 0 / 20%)",
                marginBottom: "5px",
                padding: "3px",
                borderRadius: "5px",
              }}
            >
              <Typography sx={{ color: "var(--text-info)", fontSize: "15px" }}>
                {file.nombre}
              </Typography>
              {file.nombre !== "VENTAS" && file.nombre !== "GASTOS" && (
                <Tooltip
                  title={t(
                    "dataManagementTexts.allowFilesModalContent.deleteAllowedFile"
                  )}
                >
                  <DeleteIcon
                    onClick={() => {
                      handleDeleteConfirmation(file);
                    }}
                    sx={{
                      color: "var(--icon-info) !important",
                      cursor: "pointer",
                      transition: "all 1s linear",
                      "& :hover": { color: "var(--icon-error)" },
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          );
        })}
      </Box>
      <Button
        title={t("dataManagementTexts.allowFilesModalContent.close")}
        color="grey"
        type="button"
        onClick={closeModal}
      />
      <Portal>
        <CustomSnackbar
          open={openSnackbar}
          handleClose={handleToggleSnackbar}
          message={messageSnackbar}
          severity={severity}
        />
      </Portal>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleToggleConfirmationModal}
        handleAccept={handleDeleteFileName}
        message={`${t("dataManagementTexts.allowFilesModalContent.delete")} ${
          selectedFileForDelete?.nombre
        } ${t(
          "dataManagementTexts.allowFilesModalContent.offAllowdFileNames"
        )}`}
        title={t("dataManagementTexts.allowFilesModalContent.deleteFile")}
      />
      <NotAllowedToDeleteFileModal
        open={openNotAllowedToDeleteFileModal}
        handleClose={handleToggleNotAllowedToDeleteFileModal}
        fileName={selectedFileForDelete?.nombre}
      />
      <NotAllowedToAddFileModal
        open={openNotAllowedToAddFileModal}
        handleClose={handleToggleNotAllowedToAddFileModal}
      />
    </Box>
  );
};

export default AllowedFilesModalContent;
