import {
  Chip,
  ListItemAvatar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useLanguage from "../../../context/LanguageProvider";
import CustomButton from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";
import { form_label_asterisc } from "../../../styles/app-styles";
import { useState } from "react";
import { renderFilesFolder } from "./utils";
import useApi from "../../../hooks/useApi";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import ShadowBox from "../../dataModel/groupManagement/ShadowBox";
import { truncateString } from "../../parameterization/utils";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import IconButton from "@mui/material/IconButton";
import useDialog from "../../../hooks/useDialog";
import SelectedPathsDialog from "./SelectedPathsDialog";
import InfoIcon from "@mui/icons-material/Info";
interface AlphaImportDialogProps {
  open: boolean;
  handleClose: () => void;
  dataAlpha: any;
  refetchRowsFiles: any;
  refetchColumnsFiles: any;
}

const AlphaImportDialog = ({
  open,
  handleClose,
  dataAlpha,
  refetchRowsFiles,
  refetchColumnsFiles,
}: AlphaImportDialogProps) => {
  const { t } = useLanguage();
  const [selectedFilesPaths, setSelectedFilesPaths] = useState<any[]>([]);
  const [openSelectedFilesPathsModal, handleToggleSelectedFilesPathsModal] =
    useDialog();
  const [openSelectedFoldersPathsModal, handleToggleSelectedFoldersPathsModal] =
    useDialog();

  /******************************** CARGA Y OBTENCIÓN DE ARCHIVOS *******************************/

  const onSuccessPostAlphaFiles = () => {
    refetchRowsFiles();
    refetchColumnsFiles();
    closeAndClean();
  };

  const { isLoading: isLoadingPostAlphaFiles, callApi: postAlphaFiles } =
    useApi(
      "archivo/subir_datalake",
      "POST",
      t("dataManagement.downloadFile.codes"),
      undefined,
      onSuccessPostAlphaFiles,
      undefined,
      true
    );

  /********************************* MANEJO DE FUNCIONES ***************************************/

  const deletePath = (item) => {
    setSelectedFilesPaths(
      selectedFilesPaths.filter(
        (selectedPath) => !selectedPath.ubicacion.startsWith(item.ubicacion)
      )
    );
  };

  const deletePathFolders = (item) => {
    setSelectedFilesPaths(
      selectedFilesPaths.filter(
        (selectedPath) => selectedPath.ubicacion !== item.ubicacion
      )
    );
  };

  const closeAndClean = () => {
    setSelectedFilesPaths([]);
    handleClose();
  };

  const handleFinish = () => {
    const newConfig = {
      archivos: [],
    };

    // Filtrar archivos asociados a carpetas
    const filterAssociatedFiles = (paths) => {
      return paths.filter((path) => {
        // Si el tipo no es "archivo", mantenlo
        if (path.tipo !== "archivo") return true;

        // Verificar si hay una carpeta cuya ubicación es parte del archivo
        return !paths.some(
          (folder) =>
            folder.tipo === "carpeta" &&
            path.ubicacion.startsWith(folder.ubicacion + "/")
        );
      });
    };

    // Filtrar carpetas para mantener solo las carpetas padre
    const filterParentFolders = (folders) => {
      return folders.filter((folder) => {
        const folderPath = folder.ubicacion.endsWith("/")
          ? folder.ubicacion
          : folder.ubicacion + "/";
        return !folders.some(
          (otherFolder) =>
            otherFolder.tipo === "carpeta" &&
            otherFolder.ubicacion.startsWith(folderPath)
        );
      });
    };

    const filteredFilesAndFolders = filterAssociatedFiles(selectedFilesPaths);
    const relevantsPaths = filterParentFolders(filteredFilesAndFolders);

    newConfig.archivos =
      relevantsPaths &&
      relevantsPaths.map((paths) => {
        if (paths.tipo === "carpeta") {
          return paths.ubicacion + "/";
        } else {
          return paths.ubicacion;
        }
      });
    newConfig && postAlphaFiles("archivo/subir_datalake", newConfig);
  };

  return (
    <Dialog
      maxWidth={"xl"}
      open={open}
      handleClose={closeAndClean}
      title={t("dataManagementTexts.dataManagement.importFromAlpha")}
      sx={{
        height: "800px",
      }}
    >
      <SimpleBackdrop
        open={isLoadingPostAlphaFiles}
        message={t("general.loading")}
      />
      {selectedFilesPaths &&
        selectedFilesPaths.filter((file) => file.tipo === "carpeta").length >
          0 && (
          <>
            <Typography
              sx={{
                color: "var(--text-info)",
                fontWeight: "bold",
                pl: 4,
              }}
            >
              {t("dataManagementTexts.alphaImportTexts.selectedFolders")}:
            </Typography>
            <Stack
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                padding: "10px 30px 10px 30px",
                width: "650px",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {selectedFilesPaths
                  .filter((file) => file.tipo === "carpeta")
                  ?.slice(0, 6)
                  .map((item: any) => {
                    const label = item.ubicacion?.split("/").pop(); // Obtener la parte después del último "/"
                    return (
                      <Tooltip
                        key={item.ubicacion}
                        placement="bottom"
                        title={label}
                      >
                        <Chip
                          label={`${truncateString(label, 4)}`}
                          onDelete={() => deletePathFolders(item)}
                        />
                      </Tooltip>
                    );
                  })}
              </Stack>
              {selectedFilesPaths.filter((file) => file.tipo === "carpeta")
                .length >= 6 && (
                <Tooltip
                  placement="bottom"
                  title={t("buttons.speedDialTooltipOpen.seeMore")}
                >
                  <ListItemAvatar
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <IconButton
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "var(--bg-main)",
                        "&:hover": {
                          backgroundColor: "var(--bg-secondary)",
                        },
                      }}
                      onClick={handleToggleSelectedFoldersPathsModal}
                    >
                      <ZoomInIcon
                        sx={{
                          color: "white",
                        }}
                      />
                    </IconButton>
                  </ListItemAvatar>
                </Tooltip>
              )}
            </Stack>
          </>
        )}
      {selectedFilesPaths &&
        selectedFilesPaths.filter((file) => file.tipo === "archivo").length >
          0 && (
          <>
            <Typography
              sx={{
                color: "var(--text-info)",
                fontWeight: "bold",
                pl: 4,
              }}
            >
              {t("dataManagementTexts.alphaImportTexts.selectedFiles")}:
            </Typography>
            <Stack
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                padding: "10px 30px 10px 30px",
                width: "650px",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {selectedFilesPaths
                  .filter((file) => file.tipo === "archivo")
                  .slice(0, 6)
                  .map((item: any) => {
                    const label = item.ubicacion.split("/").pop(); // Obtener la parte después del último "/"
                    return (
                      <Tooltip
                        key={item.ubicacion}
                        placement="bottom"
                        title={label}
                      >
                        <Chip
                          label={`${truncateString(label, 4)}`}
                          onDelete={() => deletePath(item)}
                        />
                      </Tooltip>
                    );
                  })}
              </Stack>
              {selectedFilesPaths.filter((file) => file.tipo === "archivo")
                .length >= 6 && (
                <Tooltip
                  placement="bottom"
                  title={t("buttons.speedDialTooltipOpen.seeMore")}
                >
                  <ListItemAvatar
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <IconButton
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "var(--bg-main)",
                        "&:hover": {
                          backgroundColor: "var(--bg-secondary)",
                        },
                      }}
                      onClick={handleToggleSelectedFilesPathsModal}
                    >
                      <ZoomInIcon
                        sx={{
                          color: "white",
                        }}
                      />
                    </IconButton>
                  </ListItemAvatar>
                </Tooltip>
              )}
            </Stack>
          </>
        )}
      {dataAlpha && dataAlpha.length > 0 ? (
        <>
          <Typography
            sx={{
              color: "var(--text-info)",
              fontWeight: "bold",
              pl: 4,
            }}
          >
            <span style={form_label_asterisc}>*</span>
            {t("dataManagementTexts.alphaImportTexts.filesToImport")}:
          </Typography>
          <Stack
            sx={{
              height: selectedFilesPaths?.length > 0 ? "400px" : "500px",
              overflowY: "auto",
              width: "100%",
              padding: "10px 30px 10px 30px",
            }}
          >
            <ShadowBox>
              {renderFilesFolder(
                dataAlpha,
                selectedFilesPaths,
                setSelectedFilesPaths,
                t
              )}
            </ShadowBox>
          </Stack>
        </>
      ) : (
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            p: 6,
            gap: "10px",
          }}
        >
          <InfoIcon color="primary" fontSize="medium" />
          <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
            {t("dataManagementTexts.alphaImportTexts.noFilesToImport")}
          </Typography>
        </Stack>
      )}

      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <CustomButton
          title={t("general.cancel")}
          color="blue-greeny"
          type="button"
          onClick={closeAndClean}
        />
        {dataAlpha && dataAlpha.length > 0 && (
          <CustomButton
            title={t("general.import")}
            color="blue"
            type="button"
            onClick={handleFinish}
            disabled={selectedFilesPaths?.length < 1}
          />
        )}
      </Stack>
      <SelectedPathsDialog
        open={openSelectedFilesPathsModal}
        handleClose={handleToggleSelectedFilesPathsModal}
        deletePath={deletePath}
        selectedFilesPaths={
          selectedFilesPaths &&
          selectedFilesPaths
            .filter((file) => file.tipo === "archivo")
            ?.map((file) => file.ubicacion)
        }
        title={t("dataManagementTexts.alphaImportTexts.selectedFiles")}
      />
      <SelectedPathsDialog
        open={openSelectedFoldersPathsModal}
        handleClose={handleToggleSelectedFoldersPathsModal}
        deletePath={deletePathFolders}
        selectedFilesPaths={
          selectedFilesPaths &&
          selectedFilesPaths
            .filter((file) => file.tipo === "carpeta")
            ?.map((file) => file.ubicacion)
        }
        title={t("dataManagementTexts.alphaImportTexts.selectedFolders")}
      />
    </Dialog>
  );
};

export default AlphaImportDialog;
