import { Box } from "@mui/material";
import { Menu } from "../menu";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import {
  layout_container_styles,
  layout_menu_container_styles,
  layout_outlet_container_styles,
} from "../../styles/app-styles";

const Layout = () => {
  const { auth }: any = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth && !auth.empresa) {
      navigate("/empresa");
    } else if (auth && auth.empresa) {
      location.pathname === "" || location.pathname === "/" ?navigate("/inicio") : <Outlet />;
    }
  }, [auth, navigate]);

  return (
    <Box>
      <Box sx={layout_container_styles}>
        <Box sx={layout_menu_container_styles}>{<Menu />}</Box>
        <Box sx={layout_outlet_container_styles}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;