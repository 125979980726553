import { COMPANY_PARAMETERS_PNL_URL } from "../../../../../api/axios";
import { ConfirmationModal } from "../../../../dialog";
import { PnlElementType } from "../../data";
import { useCompanyParamProvider } from "../../../../../context/CompanyParamProvider";
import { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "../../../../dialog/Dialog";
import Fab from "@mui/material/Fab";
import Grid2 from "@mui/material/Unstable_Grid2";
import PnlConfigurationModal from "../ElementConfiguration/PnlConfigurationModal";
import PnlElement from "./PnlElement";
import SaveNameModal from "../../../../dialog/SaveNameModal";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import useApi from "../../../../../hooks/useApi";
import useDialog from "../../../../../hooks/useDialog";
import useLanguage from "../../../../../context/LanguageProvider";
import SimpleBackdrop from "../../../../backdrop/SimpleBackdrop";

const PnlElements = () => {
  const { t } = useLanguage();
  const {
    dataGetPnlParams,
    getPnlParams,
    dataGetPnlLevels,
    selectedLevel,
    setPnlElements,
    pnlElements,
    pnlElementConfig,
    setPnlElementConfig,
  } = useCompanyParamProvider();

  const dragItem = useRef();
  const dragOverItem = useRef();
  const [openConfirmDeleteElement, toggleConfirmDeleteElement] = useDialog();
  const [openPnlModal, togglePnlModal] = useDialog();
  const [openSaveNameModal, toggleSaveNameModal] = useDialog();
  const [element, setElement] = useState<PnlElementType>();
  const [elementName, setElementName] = useState("");
  const relatedLevel = dataGetPnlLevels?.find(
    (item) => item.vinculo === "BUCKET"
  );

  /****************************************** Render de elementos ****************************************/

  const renderElements = (pnlEls) => {
    const listado: any[] = [];
    const funcionRecorrer = (pnlEls) => {
      pnlEls.forEach((el) => {
        listado.push(el);
        if (el.subelementos) {
          funcionRecorrer(el.subelementos);
        }
      });
    };
    funcionRecorrer(pnlEls);
    return listado;
  };

  useEffect(() => {
    if (dataGetPnlParams) {
      setPnlElements(
        renderElements(dataGetPnlParams).map((el) => {
          return { ...el, checked: false };
        })
      );
    }
  }, [dataGetPnlParams, dataGetPnlParams?.length]);

  /***************************************** Crear elemento *****************************************/

  const onClickAddElement = (e) => {
    setPnlElementConfig({
      id: undefined,
      nombre: "",
      orden: 1,
      tipo: undefined,
      subelementos: undefined,
      nivel: 1,
      columnas: undefined,
      archivo: undefined,
      buckets: undefined,
      agrupadores: undefined,
      padre_id: undefined,
      checked: false,
    });
    togglePnlModal();
  };

  const onSuccessPostPnlElement = () => {
    setPnlElementConfig({
      id: undefined,
      nombre: "",
      orden: undefined,
      tipo: undefined,
      subelementos: undefined,
      nivel: undefined,
      columnas: undefined,
      archivo: undefined,
      buckets: undefined,
      agrupadores: undefined,
      padre_id: undefined,
      checked: false,
    });
    getPnlParams();
    setElement(undefined);
  };

  const { isLoading: isLoadingPostPnlElement, callApi: postPnlElement } =
    useApi(
      COMPANY_PARAMETERS_PNL_URL,
      "POST",
      t("companyParam.getParams.codes"),
      undefined,
      onSuccessPostPnlElement,
      undefined
    );

  const addElement = () => {
    const dataTosend = {
      ...pnlElementConfig,
      columnas: pnlElementConfig?.columnas?.map((col) => col.value),
      buckets: pnlElementConfig?.buckets?.map((bucket) => bucket.value),
      agrupadores: pnlElementConfig?.agrupadores?.map((group) => group.value),
    };
    postPnlElement(undefined, dataTosend);
    togglePnlModal();
  };

  /****************************************** Editar elemento ***************************************/

  const { isLoading: isLoadingPutPnlElement, callApi: putPnlElement } = useApi(
    `${COMPANY_PARAMETERS_PNL_URL}/${element?.id}`,
    "PUT",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessPostPnlElement,
    undefined
  );

  const handleEditElement = (event, element) => {
    setElement(element);
    setElementName(element.nombre);
    toggleSaveNameModal();
    event.stopPropagation();
  };

  const handleSaveName = (action) => {
    setElementName(action.payload);
  };

  const isValidName = (namesList, value) => {
    return !namesList.some((item) => item.nombre === value);
  };

  const editElement = () => {
    putPnlElement(`${COMPANY_PARAMETERS_PNL_URL}/${element?.id}`, {
      nombre: elementName,
    });
    toggleSaveNameModal();
  };

  /**************************************** Eliminar elemento ***************************************/

  const { isLoading: isLoadingDeletePnlElement, callApi: deletePnlElement } =
    useApi(
      `${COMPANY_PARAMETERS_PNL_URL}/${element?.id}`,
      "DELETE",
      t("companyParam.getParams.codes"),
      undefined,
      onSuccessPostPnlElement,
      undefined,
      false
    );

  const deleteElement = () => {
    deletePnlElement(`${COMPANY_PARAMETERS_PNL_URL}/${element?.id}`);
    toggleConfirmDeleteElement();
  };

  const onDropActions = (initialItem, finalItem) => {
    if (initialItem.nivel === finalItem.nivel) {
      putPnlElement(`${COMPANY_PARAMETERS_PNL_URL}/${initialItem?.id}`, {
        orden: finalItem.orden,
        padre: finalItem?.padre,
      });
    } else {
      putPnlElement(`${COMPANY_PARAMETERS_PNL_URL}/${initialItem?.id}`, {
        orden: 1,
        padre: finalItem?.id,
      });
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={
          isLoadingDeletePnlElement ||
          isLoadingPutPnlElement ||
          isLoadingPostPnlElement
        }
        message={t("general.loading")}
      />
      <Stack direction={"row"} width={"100%"} justifyContent={"center"}>
        <Fab
          size="small"
          color="primary"
          disabled={relatedLevel ? false : true}
          onClick={(e) => onClickAddElement(e)}
          sx={{
            "&:hover": {
              backgroundColor: "var(--transparent-blue-greeny)",
            },
          }}
        >
          <Tooltip title={t("companyParam.addElement")} placement="top">
            <AddIcon />
          </Tooltip>
        </Fab>
      </Stack>
      <Grid2 xs={12}>
        <Stack justifyContent={"space-between"} gap={2} alignItems={"flex-end"}>
          {pnlElements &&
            pnlElements.length > 0 &&
            pnlElements
              .filter((el: PnlElementType) => el.nivel <= selectedLevel)
              ?.map((pnlEl: PnlElementType) => (
                <PnlElement
                  key={pnlEl.id}
                  element={pnlEl}
                  toggleDeleteElement={toggleConfirmDeleteElement}
                  setElement={setElement}
                  togglePnlModal={togglePnlModal}
                  handleEditElement={handleEditElement}
                  dragItem={dragItem}
                  dragOverItem={dragOverItem}
                  onDropActions={onDropActions}
                />
              ))}

          <ConfirmationModal
            open={openConfirmDeleteElement}
            handleClose={toggleConfirmDeleteElement}
            handleAccept={deleteElement}
            message={t("companyParam.deleteThisElement")}
            title={t("companyParam.deleteElement")}
          />
          {openPnlModal && (
            <Dialog
              open={openPnlModal}
              handleClose={togglePnlModal}
              title={t("companyParam.createPnlElement")}
              maxWidth={"lg"}
            >
              <Stack sx={{ width: "800px", minHeight: "450px" }}>
                <PnlConfigurationModal
                  handleToggleModal={togglePnlModal}
                  handleAddElement={addElement}
                />
              </Stack>
            </Dialog>
          )}
          <SaveNameModal
            open={openSaveNameModal}
            handleClose={toggleSaveNameModal}
            handleAccept={editElement}
            title={t("companyParam.editName")}
            text={t("companyParam.confirmEditName")}
            label={t("general.name")}
            placeholder={t("companyParam.writeNewName")}
            dispatchFunction={(action) => handleSaveName(action)}
            dispatchType={""}
            valueState={elementName}
            namesList={
              dataGetPnlParams && dataGetPnlParams.length > 0
                ? dataGetPnlParams
                : []
            }
            isValidName={isValidName}
          />
        </Stack>
      </Grid2>
    </>
  );
};

export default PnlElements;
