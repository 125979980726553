import { Stack } from "@mui/material";
import { RefObject, useState } from "react";
import { ActionMeta, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { AddButton, Button } from "../buttons";
import FormSelectLayout from "../forms/FormSelectLayout";
import { selectStyles } from "../configCriteria/select.styles";
import { CustomSelect, InputLabel } from "../forms";
import { IdOption, Message, OnChange } from "../configCriteria/types";
import CustomInput from "../forms/CustomInput";
import Select from "../forms/Select";
import { IncomingSchemasOptions, SchemaRow } from "./types";
import SchemaItemRow from "./SchemaItemRow";
import { formatOptions } from "../../utils/util";
import useLanguage from "../../context/LanguageProvider";


interface NewSchemaContentProps {
  inputRef: RefObject<HTMLInputElement>;
  destinityCreatableSelectRef: any;
  destinitySelectOptions: any[] | undefined;
  setCreatableDestinityValueSelected: (value: string )=>void;
  handleChangeDestinityCreatableSelect: (
    value: any,
    action: ActionMeta<any>
  ) => void;
  setSchemaName: (name: string) => void;
  schemaName: string;
  handleClose: () => void;
  handleCreateSchema: any;
  dataSchemas: IncomingSchemasOptions | undefined;
  tab: string;
  criteriaSelectRef: any;
  criteriaSelectedOptionsChangeHandler: any;
  criteriaSelectedOption: any;
  criteriaOptions: any;
  selectedCriteriaTypeChangeHandler: OnChange;
  selectedCriteriaType: any;
  percentageRef: any;
  percentage: string | number;
  setPercentage: ((value: string | number) => void);
  newSchemaRows: SchemaRow[],
  setNewSchemaRows: (rows: SchemaRow[]) => void
  creatableDestinityValueSelected: string,
}

const NewSchemaContent = ({
  inputRef,
  destinityCreatableSelectRef,
  handleClose,
  handleCreateSchema,
  dataSchemas,
  destinitySelectOptions,
  handleChangeDestinityCreatableSelect,
  setCreatableDestinityValueSelected,
  setSchemaName,
  schemaName,
  tab,
  criteriaSelectRef,
  criteriaSelectedOptionsChangeHandler,
  criteriaSelectedOption,
  criteriaOptions,
  selectedCriteriaTypeChangeHandler,
  selectedCriteriaType,
  percentageRef,
  percentage,
  setPercentage,
  newSchemaRows,
  setNewSchemaRows,
  creatableDestinityValueSelected
}: NewSchemaContentProps) => {
  
  const [message, setMessage] = useState<Message>();
  const [messageErrorName, setMessageErrorName] = useState<Message>();
  const { t }  = useLanguage();

  const options = [
    { value: t("preprorationParameterization.newSchemaContent.criteria"), label: t("preprorationParameterization.newSchemaContent.criteria").toUpperCase() },
    { value: t("preprorationParameterization.newSchemaContent.percentage").toLowerCase(), label: t("preprorationParameterization.newSchemaContent.percentage").toUpperCase() },
  ];

  const handleChangeValue = (value: any) => {
    const schemaNameExists = dataSchemas && dataSchemas?.find(
      (schema: any) => schema.nombre.toLowerCase() === value.toLowerCase()
    );
    if (schemaNameExists) {
      setMessageErrorName({
        type: "error",
        text: t("preprorationParameterization.newSchemaContent.schemaWithNameAlreadyExists"),
      });
    } else {
      setMessageErrorName(undefined);
    }
    setSchemaName(value);
  };

  const handleAddSchemaLine = () => {
   
    if (selectedCriteriaType?.value === t("preprorationParameterization.newSchemaContent.criteria")) {
      if (!creatableDestinityValueSelected || !criteriaSelectedOption) {
        setMessage({
          type: "error",
          text: t("preprorationParameterization.newSchemaContent.necessaryCompleteFields"),
        })
        return
      } else {
        if (isDestinityDuplicated(creatableDestinityValueSelected)) {
          setMessage({
            type: "error",
            text: t("preprorationParameterization.newSchemaContent.alreadyCriteriaApplied"),
          })
          return
        } else {
          setNewSchemaRows([...newSchemaRows, { destino: creatableDestinityValueSelected, criterio: criteriaSelectedOption }])
          criteriaSelectRef.current.clearValue()
          setCreatableDestinityValueSelected("")
          destinityCreatableSelectRef.current.clearValue()
          setMessage(undefined);
          return
        }
      }
    } else {
      if (!creatableDestinityValueSelected || !percentageRef.current.value) {
        setMessage({
          type: "error",
          text: t("preprorationParameterization.newSchemaContent.necessaryCompleteFields"),
        })
        return
      } else {
        if (percentageRef.current.value < 1) {
          setMessage({
            type: "error",
            text: t("preprorationParameterization.newSchemaContent.percentageCannotLessOne"),
          })
          return
        }
        if (isDestinityDuplicated(creatableDestinityValueSelected)) {
          setMessage({
            type: "error",
            text: t("preprorationParameterization.newSchemaContent.alreadyPercentageApplied"),
          })
          return
        }
        if (isPercentageGreaterThan100(percentage as string)) {
          setMessage({
            type: "error",
            text: t("preprorationParameterization.newSchemaContent.percentageCannotExceedOneHundred"),
          })
          return
        }
        setNewSchemaRows([...newSchemaRows, { destino: creatableDestinityValueSelected, porcentaje: percentage as number }])
        setPercentage("")
        setCreatableDestinityValueSelected("")
        destinityCreatableSelectRef.current.clearValue()
        setMessage(undefined);
        return
      }
    }
  }

  const isDestinityDuplicated = (destinity: string) => {
    return newSchemaRows.find(line => line.destino === destinity);
  }

  const isPercentageGreaterThan100 = (percentaje: string) => {
    let sum = parseFloat(percentaje);
    newSchemaRows.forEach(function (line) {
      sum += parseFloat(line.porcentaje as string)
    })
    return sum > 100
  }

  const onChangeSelectedCriteriaType = (newValue: SingleValue<IdOption>, actionMeta: ActionMeta<IdOption>) => {

    setNewSchemaRows([]);
    selectedCriteriaTypeChangeHandler(newValue, actionMeta)
  }

  const createSchema = () => {
    if (newSchemaRows.length === 0 || !schemaName) {
      setMessage({
        type: "error",
        text: t("preprorationParameterization.newSchemaContent.necessaryCompletFieldsForSchema"),
      })
      return
    } else {
      if (newSchemaRows[0].porcentaje) {
        let sum = 0;
        
        newSchemaRows.forEach(function (line) {
          sum += parseFloat(line.porcentaje as string)
        })
        if (sum !== 100){
          setMessage({
            type: "error",
            text: t("preprorationParameterization.newSchemaContent.totalPercentageMustBeOneHundred"),
          })
          return
        }else{
          handleCreateSchema()
        }
      }else{
        handleCreateSchema()
      }
    }
  }
 
 
  return (
    <Stack mb="5px" sx={{ width: "500px", minHeight: "40vh"}}>
      <FormSelectLayout title={`${t("preprorationParameterization.newSchemaContent.schemaName")}:`}>
        <CustomInput
          name="SchemaName"
          inputRef={inputRef}
          value={schemaName}
          setValue={handleChangeValue}
          maxLength={50}
          required={true}
          placeholder={`${t("preprorationParameterization.newSchemaContent.schemaName")}...`}
          autoFocus={true}
        />
       {messageErrorName  && (
          <div className="cc_info_filters_box">
            <p className={`cc_info_filters_${messageErrorName.type}`}>{messageErrorName.text}</p>
          </div>
        )} 
         {message && (
          <div className="cc_info_filters_box">
            <p className={`cc_info_filters_${message.type}`}>{message.text}</p>
          </div>
        )}
      </FormSelectLayout>

      <Stack
        direction="row"
        justifyContent={"space-between"}
        mt="1px"
        spacing={1}
        sx={{"& .css-14el2xx-placeholder": {
          fontSize: "14px"
        }}}
      >
        <InputLabel
          title={tab === t("preprorationParameterization.newSchemaContent.bucketExpense") ? t("preprorationParameterization.newSchemaContent.bucketDestination") : t("preprorationParameterization.newSchemaContent.grouperDestination")}
          width="50%"
        >
          <CreatableSelect
            ref={destinityCreatableSelectRef}
            isClearable
            styles={selectStyles(criteriaSelectedOption)}
            options={formatOptions(destinitySelectOptions)}
            formatCreateLabel={userInput => ( `${t("preprorationParameterization.newSchemaContent.add")} ${userInput}`)}
            className="pp_select_schema"
            name="destino"
            id="destino"
            onChange={handleChangeDestinityCreatableSelect}
            closeMenuOnSelect
            isSearchable
            placeholder={t("preprorationParameterization.newSchemaContent.chooseAddValue")}
            createOptionPosition="first"
          />
        </InputLabel>

        <Stack
          justifyContent={"space-between"}
          mt="1px"
          spacing={2}
          sx={{ width: "50%" }}
        >
          <Select
          styles={{singleValue: (provided) => ({
            ...provided,
            color: "white !important",
            fontWeight: "700"
          })}}
            options={options}
            placeholder={t("preprorationParameterization.newSchemaContent.criteria").toUpperCase()}
            defaultValue={selectedCriteriaType}
            closeMenuOnSelect
            onChange={onChangeSelectedCriteriaType}
            isClearable={false}
            isSearchable={false}
            className="selectDestinity"
          />
          {selectedCriteriaType?.value === t("preprorationParameterization.newSchemaContent.criteria") ? (
            <CustomSelect
              reference={criteriaSelectRef}
              styles={selectStyles(criteriaSelectedOption)}
              options={criteriaOptions}
              className="cc_select pp_select_schema"
              name="Select_criteria"
              onChange={criteriaSelectedOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t("preprorationParameterization.newSchemaContent.chooseCriteria")}
              defaultValue={criteriaSelectedOption}
              isClearable
            />
          ) : (
            <CustomInput
              name={t("preprorationParameterization.newSchemaContent.percentage")}
              inputRef={percentageRef}
              type="number"
              value={percentage}
              setValue={setPercentage}
              required={true}
              placeholder={t("preprorationParameterization.newSchemaContent.choosePercentage")}

            />
          )}
        </Stack>
        <Stack justifyContent={"space-between"}
          sx={{ margin: 0, justifyContent: "flex-end", display: "flex", height: "90px" }}>
          <AddButton onClick={handleAddSchemaLine} />
        </Stack>
      </Stack>
      <Stack sx={{ marginTop: "15px" }}>
        {newSchemaRows?.length &&
          newSchemaRows.map((row, i) => (
            <SchemaItemRow
              key={i}
              row={row}
              schemas={newSchemaRows}
              setSchemas={setNewSchemaRows}
            />
          ))
        }
      </Stack>

      <Stack direction="row" justifyContent={"space-between"} mt="28px">
        <Button
          title={t("preprorationParameterization.newSchemaContent.cancel")}
          color="grey"
          type="button"
          onClick={() => handleClose()}
        />
        <Button
          title={t("preprorationParameterization.newSchemaContent.save")}
          color="blue-greeny"
          type="button"
          onClick={() => createSchema()}
          disabled={!schemaName || !newSchemaRows.length || messageErrorName !== undefined}
        />
      </Stack>
    </Stack>
  );
};

export default NewSchemaContent;
