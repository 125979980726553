import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { convertNumberToMoney } from "../parameterization/utils";
import { convertUnit } from "../report/utils";
import { getInformationColumn, roundNumber } from "../delta_phi/utils";

/**
 * @name transformData
 * @description Función que transforma los datos de la tabla virtualizada. Se usa DataGrid Pro de Material-UI.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformData = (
  dataColumnas: any,
  dataFilas: any,
  dataGetView?: any,
  informationsColumns?: any
) => {
  //Saco la columna _id para que no se renderice
  const columnsWithoutId = Object && Object?.keys(dataColumnas[0])?.filter(
    (key) => key !== "_id"
  );

  const findColumnWidth = (column: string) => {
    const columnWidth = dataGetView?.find(
      (view: any) => view.column === column
    );
    return parseInt(columnWidth?.width) ? parseInt(columnWidth?.width) : 120;
  };
  const arrayColumns = columnsWithoutId?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    width: dataGetView ? findColumnWidth(columna) : 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong className="es_column_title">
        {columna === "MONTO_GASTO" //TODO y revisar archivo

          ? convertNumberToMoney(params.field)
          : params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={`${params.value}`}
      >
        <p className="es_cellValue">
          { informationsColumns ? columna === getInformationColumn(informationsColumns, "Costo TOTAL de Ventas y Distribucion") || columna === getInformationColumn(informationsColumns, "Costo de Mercaderia Vendida") || columna === getInformationColumn(informationsColumns, "Costos de Servir") || columna === getInformationColumn(informationsColumns, "Costo TOTAL de Ventas y Distribucion") || columna === getInformationColumn(informationsColumns, "Venta Bruta") || columna === getInformationColumn(informationsColumns, "Profit to Serve") ? convertNumberToMoney(params.value) : columna === getInformationColumn(informationsColumns, "Volumen en Unidades") ? Number(params.value) < 1000 ? roundNumber(Number(params.value), 2): convertUnit("unidades", Number(params.value)) : params.value : params.value}
        </p>
      </Tooltip>
    ),
  }));

  const arrayRows = dataFilas.map((fila: any) => {
    const obj: any = {};
    obj["id"] = fila._id;
    for (const key in fila) {
      if (key !== "_id") {
        obj[key] = fila[key];
      }
    }
    return obj;
  });

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows.map((row: any) => ({
    ...row,
  })); 

  return { columns, rows };
};
