import { Base } from "../../../../context/types";
import { Box, InputLabel, Stack, Typography } from "@mui/material";
import { form_label } from "../../../../styles/app-styles";
import { GET_CRITERIA_FILE_COLUMN_URL } from "../../../../api/axios";
import { IdOption } from "../../../configCriteria/types";
import { selectStyles } from "../../../configCriteria/select.styles";
import { Tooltip } from "@mui/material";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { useContext, useEffect, useRef, useState } from "react";
import BaseModal from "../BaseModal";
import GroupersProvider from "../../../../context/GroupersProvider";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Select from "../../../forms/Select";
import ShadowBox from "../ShadowBox";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  columnOptions,
  fileOptions,
} from "../../../configCriteria/selectOptions";

interface MergeProps {
  open: boolean;
  handleClose: () => void;
  base?: Base;
}

const MergeModal = ({ open, handleClose, base }: MergeProps) => {
  const { t } = useLanguage();
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const fileSelectRef: any = useRef(null);
  const columnsSelectRef: any = useRef(null);
  const columnsPreviewBaseSelectRef: any = useRef(null);
  const columnsNewBaseSelectRef: any = useRef(null);
  const [howMerge, setHowMerge] = useState("");
  const [errorFile, setErrorFile] = useState(false);
  const [firstOcurrence, setFirstOcurrence] = useState(
    base ? base.merge?.eliminar_duplicados === "primera-ocurrencia" : true
  );
  const [lastOcurrence, setLastOcurrence] = useState(
    base ? base.merge?.eliminar_duplicados === "ultima-ocurrencia" : false
  );
  const [dontDelete, setDontDelete] = useState(
    base ? base.merge?.eliminar_duplicados === "no-eliminar" : false
  );
  const { groupersState, groupersDispatch } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const { bases } = groupersState;

  const [fileSelectedOption, setFileSelectedOptions, fileChangeHandler] =
    useSingleSelect(
      () => onFileColumn(),
      base ? (base.archivo as IdOption | undefined) : undefined
    );

  const onFileColumn = () => {
    if (isFileRepeated()) {
      setErrorFile(true);
    } else {
      setErrorFile(false);
    }
    setColumnsSelectedOption(undefined);
    columnsSelectRef.current.clearValue();
  };

  useEffect(() => {
    if (isFileRepeated()) {
      setErrorFile(true);
    } else {
      setErrorFile(false);
    }
  }, [fileSelectedOption]);

  useEffect(() => {
    if (base) {
      setColumnsSelectedOption(base.columnas);
    }
  }, [base?.columnas]);

  useEffect(() => {
    if (base) {
      setColumnsPreviewBaseSelectedOption(base?.merge?.columnas_base_anterior);
    }
  }, [base?.merge?.columnas_base_anterior]);

  const isFileRepeated = () => {
    if (base) {
      return bases.some(
        (b: any) =>
          b.archivo.value === fileSelectedOption?.value && b.id !== base.id
      );
    } else {
      return bases.some(
        (b: any) => b.archivo.value === fileSelectedOption?.value
      );
    }
  };

  const onChangeColumns = () => {
    setColumnsNewBaseSelectedOption(undefined);
    columnsNewBaseSelectRef.current.clearValue();
  };

  const [
    columnsSelectedOption,
    setColumnsSelectedOption,
    columnsChangeHandler,
  ] = useMultiSelect(
    base ? (base.columnas as IdOption[] | undefined) : undefined,
    onChangeColumns
  );

  const [
    columnsPreviewBaseSelectedOption,
    setColumnsPreviewBaseSelectedOption,
    columnsPreviewBaseChangeHandler,
  ] = useMultiSelect(
    base
      ? (base.merge?.columnas_base_anterior as IdOption[] | undefined)
      : undefined
  );

  const [
    columnsNewBaseSelectedOption,
    setColumnsNewBaseSelectedOption,
    columnsNewBaseChangeHandler,
  ] = useMultiSelect(
    base
      ? (base.merge?.columnas_base_nueva as IdOption[] | undefined)
      : undefined
  );

  /****************************** CARGA DE DATOS *******************************/

  const { data: dataGetFileColumn } = useApiQuery(
    GET_CRITERIA_FILE_COLUMN_URL,
    false,
    t("configCriteria.getCriteriaFileColumn.error")
  );

  /******************************* VALIDACIONES FORMULARIO ********************************/

  const isFormCompleted = () => {
    return (
      columnsSelectedOption === undefined ||
      columnsSelectedOption?.length === 0 ||
      columnsPreviewBaseSelectedOption === undefined ||
      columnsPreviewBaseSelectedOption?.length === 0 ||
      columnsNewBaseSelectedOption === undefined ||
      columnsNewBaseSelectedOption?.length === 0 ||
      fileSelectedOption === undefined ||
      fileSelectedOption === null ||
      columnsPreviewBaseSelectedOption.length !==
        columnsNewBaseSelectedOption.length ||
      errorFile
    );
  };

  const saveMerge = () => {
    if (base) {
      const updatedBase = {
        archivo: fileSelectedOption,
        columnas: columnsSelectedOption,
        id: base.id,
        error: false,
        merge: {
          columnas_base_anterior: columnsPreviewBaseSelectedOption,
          columnas_base_nueva: columnsNewBaseSelectedOption,
          eliminar_duplicados: firstOcurrence
            ? "primera-ocurrencia"
            : lastOcurrence
            ? "ultima-ocurrencia"
            : "no-eliminar",
        },
      };
      groupersDispatch({
        type: "SET_BASES",
        payload: bases.map((b: any) =>
          b.id === base.id ? { ...updatedBase, id: base.id } : b
        ),
      });
    } else {
      groupersDispatch({
        type: "SET_BASES",
        payload: [
          ...bases,
          {
            archivo: fileSelectedOption,
            columnas: columnsSelectedOption,
            id: Math.random(),
            error: false,
            merge: {
              columnas_base_anterior: columnsPreviewBaseSelectedOption,
              columnas_base_nueva: columnsNewBaseSelectedOption,
              eliminar_duplicados: firstOcurrence
                ? "primera-ocurrencia"
                : lastOcurrence
                ? "ultima-ocurrencia"
                : "no-eliminar",
            },
          },
        ],
      });
    }
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
    cleanAndClose();
  };

  const prepareColumnsToUseOptions = () => {
    let totalOptions: any[] = [];

    totalOptions = bases
      ?.filter((b: any) => b.id !== base?.id)
      .map((b: any) => {
        return {
          label: b.archivo?.label,
          options: b.columnas?.map((column: any) => {
            return {
              value: column.value,
              label: column.label,
              archivo: b.archivo.value,
            };
          }),
        };
      });

    return totalOptions;
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    if (!base) {
      setColumnsSelectedOption([]);
      setColumnsPreviewBaseSelectedOption([]);
      setColumnsNewBaseSelectedOption(undefined);
      fileSelectRef.current.clearValue();
      setColumnsNewBaseSelectedOption(undefined);
      setLastOcurrence(false);
      setFirstOcurrence(true);
      setDontDelete(false);
      setHowMerge("");
    }
  };

  const toggleDeleteDuplicatedValues = (e) => {
    if (e.target.id === "dontDelete") {
      setDontDelete(true);
      setFirstOcurrence(false);
      setLastOcurrence(false);
    } else if (e.target.id === "firstOcurrence") {
      setDontDelete(false);
      setFirstOcurrence(true);
      setLastOcurrence(false);
    } else if (e.target.id === "lastOcurrence") {
      setDontDelete(false);
      setFirstOcurrence(false);
      setLastOcurrence(true);
    }
  };

  return (
    <BaseModal
      open={open}
      title="Merge"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveMerge}
      confirmationModalMessage={
        base
          ? t("dataModelText.groupManagement.step1.mergeModal.editMerge")
          : t("dataModelText.groupManagement.step1.mergeModal.addMerge")
      }
      disabledAcceptButton={isFormCompleted()}
      height="100%"
    >
      <Stack
        sx={{
          width: "100%",
          alignItems: "center",

          gap: 2,
        }}
      >
        <ShadowBox>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <InputLabel sx={form_label}>
              {t("dataModelText.groupManagement.step1.mergeModal.file")}
            </InputLabel>
            <Select
              reference={fileSelectRef}
              styles={selectStyles(fileSelectedOption)}
              options={fileOptions(dataGetFileColumn)}
              name="Archivo"
              onChange={fileChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "dataModelText.groupManagement.step1.mergeModal.selectFile"
              )}
              isClearable
              isDisabled={false}
              defaultValue={fileSelectedOption}
            />
            {errorFile && (
              <Typography sx={{ color: "var(--red)" }}>
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.alreadyExistBaseWithFile"
                )}
              </Typography>
            )}
          </Box>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <InputLabel sx={form_label}>
              {t("dataModelText.groupManagement.step1.mergeModal.columns")}
            </InputLabel>
            <Select
              isMulti
              reference={columnsSelectRef}
              styles={selectStyles(columnsSelectedOption)}
              options={columnOptions(dataGetFileColumn, fileSelectedOption)}
              name="Columnas"
              onChange={columnsChangeHandler}
              closeMenuOnSelect={false}
              placeholder={t(
                "dataModelText.groupManagement.step1.mergeModal.columns"
              )}
              isClearable
              defaultValue={columnsSelectedOption}
              isDisabled={false}
            />
          </Box>
        </ShadowBox>
        <ShadowBox>
          <Stack direction={"row"} gap={4}>
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <InputLabel sx={form_label}>
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.columnsMergePreviousBase"
                )}
              </InputLabel>
              <Select
                reference={columnsPreviewBaseSelectRef}
                styles={selectStyles(columnsPreviewBaseSelectedOption)}
                options={prepareColumnsToUseOptions()}
                form="cc_form"
                className="cc_select"
                name="Columna"
                onChange={columnsPreviewBaseChangeHandler}
                placeholder={t(
                  "dataModelText.groupManagement.step1.mergeModal.chooseColumns"
                )}
                closeMenuOnSelect={false}
                defaultValue={columnsPreviewBaseSelectedOption}
                data-testid="select_column_component"
                isClearable
                isMulti
              />
            </Box>
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <InputLabel sx={form_label}>
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.columnsMergeNewBase"
                )}
              </InputLabel>
              <Select
                isMulti
                reference={columnsNewBaseSelectRef}
                styles={selectStyles(columnsNewBaseSelectedOption)}
                options={columnsSelectedOption}
                name="Columnas merge - Base nueva"
                onChange={columnsNewBaseChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t(
                  "dataModelText.groupManagement.step1.mergeModal.chooseColumns"
                )}
                isClearable
                defaultValue={columnsNewBaseSelectedOption}
                isDisabled={false}
              />
            </Box>
          </Stack>
        </ShadowBox>
      </Stack>
      <Stack
        sx={{
          gap: "10px",
          mt: "20px",
          width: "100%",
          flexDirection: "column",
          mb: "20px",
        }}
      >
        <ShadowBox>
          <Typography sx={form_label}>
            {t(
              "dataModelText.groupManagement.step1.mergeModal.removeDuplicates"
            )}
          </Typography>
          <Stack direction="row" marginTop="5px">
            <Stack direction="row" marginRight="25px">
              <label
                htmlFor="firstOcurrence"
                style={{
                  color: "var(--text-main)",
                  fontWeight: 500,
                }}
              >
                <input
                  type="radio"
                  className="cc_checkbox"
                  name="duplicatedValues"
                  id="firstOcurrence"
                  onChange={(e) => toggleDeleteDuplicatedValues(e)}
                  checked={firstOcurrence}
                />
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.keepFirstOcurrence"
                )}
              </label>
            </Stack>
            <Stack direction="row" marginRight="35px">
              <label
                style={{
                  color: "var(--text-main)",
                  fontWeight: 500,
                }}
                htmlFor="lastOcurrence"
              >
                <input
                  type="radio"
                  className="cc_checkbox"
                  id="lastOcurrence"
                  name="duplicatedValues"
                  onChange={(e) => toggleDeleteDuplicatedValues(e)}
                  checked={lastOcurrence}
                />
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.keepLastOcurrence"
                )}
              </label>
            </Stack>
            <Stack direction="row">
              <label
                style={{
                  color: "var(--text-main)",
                  fontWeight: 500,
                }}
                htmlFor="dontDelete"
              >
                <input
                  type="radio"
                  className="cc_checkbox"
                  name="duplicatedValues"
                  id="dontDelete"
                  onChange={(e) => toggleDeleteDuplicatedValues(e)}
                  checked={dontDelete}
                />
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.doNotDelete"
                )}
              </label>
              <Tooltip
                title={t(
                  "dataModelText.groupManagement.step1.mergeModal.notRecommended"
                )}
              >
                <ReportGmailerrorredIcon
                  color="primary"
                  sx={{ fontSize: "24px", marginLeft: "5px" }}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </ShadowBox>
      </Stack>
    </BaseModal>
  );
};

export default MergeModal;
