import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

const ListItemComponent = ({measuresList, handleAddMeasureToFormula, medidaInLast, formula}) => {
  return (
    <>
    {measuresList?.map((measure: any) => {
        return (
          <ListItem
            sx={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            key={measure.id}
            disablePadding
          >
            <ListItemButton
              sx={{
                "&:hover": {
                  backgroundColor: "var(--bg-light)",
                },
              }}
              onClick={() =>
                handleAddMeasureToFormula(measure.id, measure.nombre)
              }
              disabled={medidaInLast}
            >
              <ListItemText
                sx={{
                  alignContent: "center",
                  alignItems: "center",
                  color: "var(--text-main)",
                  justifyContent: "center",
                  width: "250px",
                  wordWrap: "break-word",
                }}
                primary={measure.nombre}
              />
              {formula?.filter(
                (formula: any) => formula.dato === measure.id
              ).length !== 0 && (
                <Stack
                  component="span"
                  sx={{
                    alignItems: "center",
                    bgcolor: "var(--bg-secondary)",
                    borderRadius: "50%",
                    color: "white",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    height: 20,
                    justifyContent: "center",
                    width: 20,
                  }}
                >
                  {
                    formula?.filter(
                      (formula: any) => formula.dato === measure.id
                    ).length
                  }
                </Stack>
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
      </>
  )
}

export default ListItemComponent