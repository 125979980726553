import { Stack, Divider, Typography } from "@mui/material";
import { Delete } from "../../icons";
import { handleDeleteById } from "../../configCriteria/utils";
import { divider_styles } from "../../../styles/app-styles";
import { concatItemsCommas } from "../utils";

interface filterValuesRows {
  filters: any[] | undefined;
  setFilters: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

const FilterValueRows = ({ filters, setFilters }: filterValuesRows) => {
  const hasFiltersAdded = filters && filters.length > 0;

  return (
    <>
      {hasFiltersAdded &&
        filters.map((filter, index) => {
          const values = concatItemsCommas(filter.values && filter.values);
          return (
            <Stack key={index}>
              {index === 0 && <Divider sx={divider_styles} />}
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
              >
                <Delete
                  tooltipPlacement="bottom"
                  onClick={() =>
                    handleDeleteById(filter.id, filters, setFilters)
                  }
                />
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--text-main)",
                  }}
                >
                  {filter.column.label}
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--text-main)",
                  }}
                >
                  {values}
                </Typography>
              </Stack>
              <Divider sx={divider_styles} />
            </Stack>
          );
        })}
    </>
  );
};


export default FilterValueRows
