import { bucket_button_styles } from "../../../../styles/app-styles";
import { getText } from "../../utils";
import { Item } from "../../../basicGrid/BasicGrid";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParameterization } from "../../../../hooks/useParameterization";
interface IBucketTab {
  id: number;
  name: string;
  percentage: "-" | number | undefined;
  handleClick: any;
}

const BucketTab = ({ id, name, percentage, handleClick }: IBucketTab) => {
  const { parameterizationState } = useParameterization();
  const { bucketSelectedOption } = parameterizationState;
  const [color, setColor] = useState<string>("grey");
  const [message, setMessage] = useState<string>("");  

  useEffect(() => {
    if(!percentage) return;
    const prop = getText(percentage);    
    setMessage(prop.message);
    if (id === bucketSelectedOption?.value) {
      setColor(prop.lightColor);
    } else {
      setColor(prop.color);
    }
  }, [bucketSelectedOption]);

  return (
    <Item
      id="gridItem"
      elevation={12}
      onClick={() => handleClick(id, name)}
      sx={bucket_button_styles(id, bucketSelectedOption)}
    >
      <Typography
        variant="h4"
        fontSize={16}
        color={id === bucketSelectedOption?.value ? "white" : "var(--text-main)"}
        fontWeight={"bold"}
      >
        {name}
      </Typography>
      <Typography variant="h6" fontSize={14} color={color} fontWeight={"bold"}>
        {message}
      </Typography>
    </Item>
  );
};

export default BucketTab;
