import { IncomingCriteriaOption, IncomingCriteriaOptions, IncomingSchemaOption, IncomingSchemasOptions } from "./types";

export const schemasOptions = (
  schemaSelectOptions: IncomingSchemasOptions | undefined,
  destiny_type: string
) => {
  return schemaSelectOptions ? schemaSelectOptions
    ?.filter((item: any) => {
      return item.tipo_destino === destiny_type;
    })
    ?.map((option: IncomingSchemaOption) => {
      return {
        value: option.id,
        label: option.nombre,
      };
    }) : []
};


export const criteriaOptions = (
  criteriaSelectOptions: IncomingCriteriaOptions | undefined
) => {
  return criteriaSelectOptions    
    ?.map((option: IncomingCriteriaOption) => {
      return {
        value: option.id,
        label: option.nombre,
      };
    });
};