import { stepper_container_styles } from "../../../styles/app-styles";
import { stepperData } from "./data";
import { useCompanyParamProvider } from "../../../context/CompanyParamProvider";
import { useEffect } from "react";
import CustomStepper from "../../Stepper/CustomStepper";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import StepperButtons from "../../forms/StepperButtons";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import {
  COMPANY_PARAMETERS_FILE_URL,
  COMPANY_PARAMETERS_GASTOS_URL,
  COMPANY_PARAMETERS_VENTAS_URL,
  EXCHANGE_RATE_CONFIRMATION_URL,
  EXPENSES_TARGET_PARAMS_URL,
  PARAM_OTHER_FILE_COLUMNS_URL,
  RELATIONSHIPS_PARAMS_URL,
} from "../../../api/axios";

const StepperView = ({ children, setIsEditOpen }) => {
  const { t } = useLanguage();
  const {
    activeStep,
    setActiveStep,
    isStepComplete,
    expensesFileName,
    keyExpensesColumns,
    bucketsList,
    getExpensesParams,
    salesFileName,
    keyTransactionalColumns,
    maxLevelDetail,
    keyDescriptiveColumns,
    getSalesParams,
    getOtherFilesParams,
    fileColumns,
    getColumnsTypesParams,
    filesColumnsTypes,
    getRelationshipsParams,
    relationships,
    expensesTargets,
    getExpenseTargetParams,
    getExchangeParams,
    exchangeTableData,
    getPnlParams,
    currencySelectedOptionState,
    foreignCurrencySelectedOptionState,
    countrySelectedOptionState
  } = useCompanyParamProvider();

  useEffect(() => {
    setActiveStep(0);
  }, []);

  /**************************************** Handlers de botones *************************************************/

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0) {     
      postExchangeRateParams(undefined, {
        tabla: exchangeTableData,
        moneda: currencySelectedOptionState?.value,
        tipo_de_cambio: foreignCurrencySelectedOptionState,
        pais: countrySelectedOptionState?.value
      });
    } else if (activeStep === 1) {
      postExpensesParams(undefined, {
        nombre: expensesFileName,
        columnas_clave: keyExpensesColumns,
        buckets: bucketsList,
      });
    } else if (activeStep === 2) {
      postSalesParams(undefined, {
        nombre: salesFileName,
        columnas_clave: {
          ...keyTransactionalColumns,
          ...keyDescriptiveColumns,
        },
        maximo_detalle:
          typeof maxLevelDetail && maxLevelDetail.label
            ? [maxLevelDetail.label]
            : maxLevelDetail.map((item) => item.label),
      });
    } else if (activeStep === 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 4) {
      const transformedFileColumns = fileColumns.map((item) => {
        return {
          nombre: item.nombre,
          descripcion: item.descripcion.label,
          //importacion: item.importacion
        };
      });
      postOtherFilesParams(undefined, transformedFileColumns);
    } else if (activeStep === 5) {
      const transformedInfo = filesColumnsTypes.map((item) => {
        return {
          archivo_id: item.archivo_id,
          columnas: item.columnas,
        };
      });
      postFilesColumnsTypes(undefined, transformedInfo);
    } else if (activeStep === 6) {
      postRelationships(undefined, relationships);
    } else if (activeStep === 7) {
      postTargets(undefined, expensesTargets);
    }
  };

  const handleRestart = () => {
    setActiveStep(0);
  };

  const onFinishClick = () => {
    setIsEditOpen(false);
    handleNext();
  };

  /***************************************** Validaciones de botones **********************************************/

  const isBackButtonDisabled = activeStep === 0;

  const isFinishButtonDisabled = !isStepComplete[`step${activeStep}`];

  const isNextButtonDisabled =
    activeStep === stepperData(t).steps.length ||
    !isStepComplete[`step${activeStep}`];

  /**************************************** Post de datos ********************************************************/

  const onSuccessPost = () => {
    if (activeStep === 0) {
      getExchangeParams();
    } else if (activeStep === 1) {
      getExpensesParams();
    } else if (activeStep === 2) {
      getSalesParams();
    } else if (activeStep === 3) {
      getPnlParams();
    } else if (activeStep === 4) {
      getOtherFilesParams();
    } else if (activeStep === 5) {
      getColumnsTypesParams();
    } else if (activeStep === 6) {
      getRelationshipsParams();
    } else if (activeStep === 7) {
      getExpenseTargetParams();
      setIsEditOpen(false);
    }
    if (activeStep !== 7) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const {
    isLoading: isLoadingPostExchangeRateParams,
    callApi: postExchangeRateParams,
  } = useApi(
    EXCHANGE_RATE_CONFIRMATION_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const {
    isLoading: isLoadingPostExpensesParams,
    callApi: postExpensesParams,
  } = useApi(
    COMPANY_PARAMETERS_GASTOS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPostSalesParams, callApi: postSalesParams } =
    useApi(
      COMPANY_PARAMETERS_VENTAS_URL,
      "POST",
      t("companyParam.postParam.codes"),
      undefined,
      onSuccessPost,
      undefined,
      true
    );
  
  const {
    isLoading: isLoadingPostOtherFilesParams,
    callApi: postOtherFilesParams,
  } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const {
    isLoading: isLoadingPostFilesColumnsTypes,
    callApi: postFilesColumnsTypes,
  } = useApi(
    PARAM_OTHER_FILE_COLUMNS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPostRelationships, callApi: postRelationships } =
    useApi(
      RELATIONSHIPS_PARAMS_URL,
      "POST",
      t("companyParam.postParam.codes"),
      undefined,
      onSuccessPost,
      undefined,
      true
    );

  const { isLoading: isLoadingPostTargets, callApi: postTargets } = useApi(
    EXPENSES_TARGET_PARAMS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  return (
    <Stack sx={stepper_container_styles}>
      <SimpleBackdrop
        open={
          isLoadingPostExchangeRateParams ||
          isLoadingPostExpensesParams ||
          isLoadingPostSalesParams ||   
          isLoadingPostOtherFilesParams ||
          isLoadingPostFilesColumnsTypes ||
          isLoadingPostRelationships ||
          isLoadingPostTargets
        }
        message={`${t("companyParam.postParam.loading")}`}
      />
      <CustomStepper activeStep={activeStep} data={stepperData(t)} />
      {children}
      <StepperButtons
        handleBack={handleBack}
        handleNext={handleNext}
        handleRestart={handleRestart}
        onFinishClick={onFinishClick}
        isBackButtonDisabled={isBackButtonDisabled}
        isFinishButtonDisabled={isFinishButtonDisabled}
        isNextButtonDisabled={isNextButtonDisabled}
      />
    </Stack>
  );
};

export default StepperView;
