import { Button } from "../../buttons";
import { Stack } from "@mui/material";
import ComponentLayout from "../../layout/ComponentLayout";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import PeriodsFilter from "../../periods/PeriodsFilter";
import StagesSettingsView from "./StagesSettingsView";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  container_100_styles,
  stages_container,
} from "../../../styles/app-styles";

const StagesSettings = () => {
  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();
  const { t } = useLanguage();
  const handleClosePeriodsModal = () => {
    handleTogglePeriodsDialog();
  };

  return (
    <ComponentLayout
      title={t("modelParameterizationTexts.stagesSettings.stagesSettingTitle")}
      icon={<LinearScaleIcon />}
      rightComponent={
        <Button
          title={t("modelParameterizationTexts.stagesSettings.periods")}
          color="blue"
          type="button"
          onClick={() => handleTogglePeriodsDialog()}
        />
      }
    >
      <Stack sx={container_100_styles}>
        <Stack spacing={1} sx={stages_container}></Stack>
        <StagesSettingsView />
        <PeriodsFilter
          openPeriodsDialog={openPeriodsDialog}
          handleTogglePeriodsDialog={handleClosePeriodsModal}
        />
      </Stack>
    </ComponentLayout>
  );
};

export default StagesSettings;
