import { Typography, Button, Box } from "@mui/material";
interface ToggleSortProps{
    firstState : string, 
    secondState: string, 
    state: string, 
    setState: any,
    firstTitle: string,
    secondTitle: string,
}

const ToggleSort = ({firstState, secondState, state, setState, firstTitle, secondTitle} : ToggleSortProps) => {

    const handleState = (string : string) => {
        setState && setState(string)
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
            <Box
                sx={{
                    borderRadius: "30px",
                    border: "1px solid var(--bg-success)",
                    position: "relative",
                    backgroundColor: "white",
                    boxShadow: "1px 1px 2px rgb(165, 165, 165) inset",
                }}
            >
                <Box
                    sx={{
                        width: "150px",
                        height: "45px",
                        borderRadius: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "var(--bg-success)",
                        position: "absolute",
                        boxShadow: "1px 0 2px black",
                        transition: "all 0.5s ease",
                        transform: `translateX(${state && state === firstState ? 0 : "150px"
                            })`,
                    }}
                />
                <Button
                    sx={{
                        color:
                            state && state === firstState
                                ? "#ffffff"
                                : "var(--text-success)",
                        borderRadius: "30px",
                        width: "150px",
                        height: "45px",
                        transition: "all 0.2s 0.1s ease",
                    }}
                    onClick={() => handleState(firstState)}
                >
                    <Typography
                        sx={{
                            textTransform: "uppercase",
                            display: "inline",
                            fontSize: "13px",
                            letterSpacing: "2px",
                            "&:hover": {
                                transition: "all 0.4s",
                                fontSize: "14px",
                            },
                        }}
                    >
                        {firstTitle && firstTitle}
                    </Typography>
                </Button>
                <Button
                    sx={{
                        color:
                            state && state === secondState
                                ? "#ffffff"
                                : "var(--text-success)",
                        borderRadius: "30px",
                        width: "150px",
                        height: "45px",
                        transition: "all 0.2s 0.1s ease",
                    }}
                    onClick={() => handleState(secondState)}
                >
                    <Typography
                        sx={{
                            textTransform: "uppercase",
                            fontWeight: "500",
                            display: "inline",
                            fontSize: "13px",
                            letterSpacing: "2px",
                            "&:hover": {
                                transition: "all 0.4s",
                                fontSize: "14px",
                            },
                        }}
                    >
                        {secondTitle && secondTitle}
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}

export default ToggleSort