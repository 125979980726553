import {
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from "@mui/x-data-grid-pro";
import Button, { ButtonProps } from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { hasKeyBucketId, isRowIdAGrouper, truncateString } from "./utils";
import useLanguage from "../../context/LanguageProvider";
import useInfoContext from "../../hooks/useInfoContext";

export const isNavigationKey = (key: string) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

export const CustomGridTreeDataGroupingCell = (props: any) => {
  const {
    id,
    field,
    rowNode,
    handleOpenEditDialog,
    isExpanded,
    rows,
    addActions,
    hideEditIcon
  } = props;
  const { userRolData }: any = useInfoContext();

  const apiRef = useGridApiContext();
  const { t } = useLanguage();
  useEffect(() => {
    apiRef.current.setRowChildrenExpansion(id, isExpanded);
  }, [isExpanded]);

  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown: ButtonProps["onKeyDown"] = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent("cellNavigationKeyDown", props, event);
    }
  };

  const handleClick: ButtonProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const bucketColumWidth = apiRef.current.getAllColumns()[1].width;
  const bucketLength = (bucketColumWidth as number) > 350 ? 35 : 24;

  return (
    <Box sx={{ ml: rowNode.depth * 4, width: "100%" }}>
      <div style={{ width: "100%" }}>
        {filteredDescendantCount > 0 || isRowIdAGrouper(rowNode.id, rows) ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              width: "100%",
              marginLeft: "10px",
            }}
          >
            <Button
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              tabIndex={-1}
              size="small"
            >
              <Tooltip title={rowNode.groupingKey}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  {truncateString(rowNode.groupingKey, bucketLength)}
                </span>
              </Tooltip>
              {filteredDescendantCount > 0 && (
                <>
                  <span style={{ fontSize: "15px" }}>
                    ({filteredDescendantCount})
                  </span>
                  {!rowNode.childrenExpanded ? (
                    <ChevronRightIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </>
              )}
            </Button>
            {userRolData && userRolData.parametrizacion_de_preprorrateo_y_prorrateo
              .visualizacion_y_edicion && 
              !hideEditIcon ? ((isRowIdAGrouper(rowNode.id, rows) && 
                filteredDescendantCount === 0) ||
                hasKeyBucketId(rows, rowNode.id)) && 
              !addActions && (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={t(
                    "modelParameterizationTexts.customGridTreeDataGroupingCell.editName"
                  )}
                >
                  <EditIcon
                    fontSize="small"
                    sx={{
                      marginRight: "5px",
                      color: "#a6a6a8",
                      "& :hover": { color: "var(--icon-main)" },
                    }}
                    onClick={() =>
                      handleOpenEditDialog(
                        parseInt(id.toString()),
                        rowNode.groupingKey
                      )
                    }
                  />
                </Tooltip>
              ) : <></>}
          </div>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
};
