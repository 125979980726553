import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import { Tooltip } from "@mui/material";
import useLanguage from "../../../context/LanguageProvider";

const SimulationRuleCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
}: any) => {
  const { t } = useLanguage();

  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "40px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--text-main)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "15px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {item?.nombre}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "5px 0" }} />
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
          }}
          color="var(--text-main)"
        >
          {t("simulatorTexts.simulationRuleCard.analysisToUse")} {" "}
          <span style={{ color: "var(--text-disabled)", wordBreak: "break-all" }}>
            {item?.analisis}
          </span>
        </Typography>
        <SpeedDialTooltipOpen
          id={item.id}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
};

export default SimulationRuleCard;