import { bucket_button_styles, home_background_styles } from "../../styles/app-styles";
import { Item } from "../basicGrid/BasicGrid";
import { menuItemsOptions, redirectMenuItem, returnItemRole } from "../menu/utils";
import { Typography, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/Fondo.png";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useApi from "../../hooks/useApi";
import { VIEW_ACCESS_URL } from "../../api/axios";

const Home = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { userRolData }: any = useInfoContext();

  const { callApi: postAccessView } = useApi(
    VIEW_ACCESS_URL,
    "POST",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const handleClick = (item) => {
    if (item.url !== "") {
      postAccessView(undefined, {
        vista: item.title,
      });
    }    
    redirectMenuItem(item, navigate, ()=><></>)
  };

  return (
    <Stack
      sx={home_background_styles(backgroundImage)}
    >
      <Typography
        variant="h3"
        color="white"
        sx={{ marginBottom: "70px", mt: "70px" }}
      >
        {t("general.home")}
      </Typography>

      <Grid container spacing={4} justifyContent="center" width={"80%"}>
        {menuItemsOptions(t).map((item, index) => {
          return (
            returnItemRole(item, userRolData, t) && item.url !== "" && (
              <Grid key={index} item md={3} xl={3}>
                <Item
                  id="gridItem"
                  elevation={12}
                  onClick={() => handleClick(item)}
                  sx={{
                    ...bucket_button_styles(1, 2),
                    height: "90px",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <>
                    <Typography
                      color={"var(--text-main)"}
                    >
                      {<item.icon style={{ fontSize: 36}} />}
                    </Typography>
                  </>
                  <Typography
                    variant="h4"
                    fontSize={16}
                    color={"var(--text-main)"}
                    fontWeight={"bold"}
                  >
                    {item.title}
                  </Typography>                 
                </Item>
              </Grid>
            )
          );
        })}
      </Grid>
    </Stack>
  );
};

export default Home;
