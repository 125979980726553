import { grid_container2_styles } from "../../../../styles/app-styles";
import { mergeAndDeleteRepeatedColumns } from "../../utils";
import { Message } from "../../../configCriteria/types";
import { FILTER_VALUES_URL, ITEM_TABLA_URL, TABLAS_URL } from "../../../../api/axios";
import { useEffect, useState } from "react";
import Button from "../../../buttons/Button";
import FormLayout from "../../../forms/FormLayout";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../../../marketBasket/MarketBasketConfig/Shared/GridTitles";
import MessageBlock from "../../../forms/MessageBlock";
import NoLinesComponent from "../../../forms/NoLinesComponent";
import Stack from "@mui/material/Stack";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import FilterRowsSelects from "./FilterRowsSelects";
import FilterRowsRow from "./FilterRowsRow";
import { FILTER_FUNCTIONS } from "../../data";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

const FilterRowsElement = ({
  dataGetTable,
  handleToggleModal,
  element,
  getTable,
  setElementToEdit
}) => {
  const { t } = useLanguage();
  const [message, setMessage] = useState<Message>({
    type: "success",
    text: "",
  });
  const [titles, setTitles] = useState([
    t("general.column"),
    t("Operador"),
    t("Valores"),
  ]);
  const [rows, setRows] = useState(element?.filtros.map(
    (row: any) => {
      return {
        columna: row.columna,        
        operador: FILTER_FUNCTIONS(t)?.find((f) => f.value === row.operador),
        valores: row.valores.map((v) => {
          return {
            value: v,
            label: v,
          };
        }),
      };
    }
  ));

  const onSuccessPost = () => {
    getTable(`${TABLAS_URL}${dataGetTable.id}`);
    setElementToEdit(undefined);
    handleToggleModal();
  };

  const { isLoading: isLoadingPostItem, callApi: postItem } = useApi(
    `${TABLAS_URL}${dataGetTable.id}/item`, //?tipo=ANALISIS
    "POST",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPutItem, callApi: putItem } = useApi(
    ITEM_TABLA_URL(dataGetTable?.id,element?.id), //?tipo=ANALISIS
    "PUT",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const handleApply = () => {
    const completedInfo = {
      tipo: "FILTRAR",
      filtros: rows.map((row: any) => {
        return {
          columna: row?.columna?.value,
          operador: row.operador?.value,
          valores: row.valores?.map(
            (v) => v.value
          ),      
        };
      }),
    };
    if(element && element.id) {
      putItem(undefined, completedInfo)
    } else {
    postItem(undefined, completedInfo);
    }
  };

  const isValidApply = () => {
    return rows && rows.length > 0;
  };

  const handleCancel = () => {
    setElementToEdit(undefined);
    handleToggleModal()
  }

  return (
    <>
    <SimpleBackdrop
        open={isLoadingPostItem || isLoadingPutItem}
        message={t("general.loading")}
      />
      <FormLayout width="100%">
        <MessageBlock message={message} />
        <Grid sx={grid_container2_styles} container spacing={1}>
          <GridFiveTitles titles={titles} />
          <FilterRowsSelects
            titles={titles}
            totalColumnsOptions={mergeAndDeleteRepeatedColumns(dataGetTable)}
            rows={rows}
            setRows={setRows}
            setMessage={setMessage}
            dataGetTable={dataGetTable}
          />
          {rows && rows.length ? (
            rows.map((row: any, index: number) => (
              <FilterRowsRow
                key={index}
                index={index}
                row={row}
                rows={rows}
                setRows={setRows}
                titles={titles}
              />
            ))
          ) : (
            <NoLinesComponent height={"200px"} />
          )}
        </Grid>
      </FormLayout>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!isValidApply()}
        />
      </Stack>
    </>
  );
};

export default FilterRowsElement