import { AddButton } from "../../buttons";
import { DeleteForever } from "../../icons";
import { InputLabel } from "../../forms";
import { OnChange } from "../../configCriteria/types";
import { Stack, Grid } from "@mui/material";
import FilterValueRows from "./FilterValueRows";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import ShadowBox from "../groupManagement/ShadowBox";
import useLanguage from "../../../context/LanguageProvider";
import VirtualSelect from "../../configCriteria/VirtualSelect";
interface FilterFormProps {
  filters: any[] | undefined;
  setFilters: any;
  setColumnFilterSelectedOptions: React.Dispatch<React.SetStateAction<any>>;
  columnFilterSelectedRef: React.RefObject<any>;
  columnLabels: any;
  columnFilterOptionsChangeHandler: OnChange;
  columnFilterSelectedOption: any;
  analysisSelectedOption: any;
  selectedValues: string[] | null;
  incomingValuesOptions: string[];
  setValuesSelectedOptions: any;
  setSelectedValues: React.Dispatch<React.SetStateAction<string[] | null>>;
  isLoadingIncomingValuesOptions: boolean;
  valuesSelectedOptions: any;
  selectOneOption: boolean;
  title?: string
}

const FilterForm = ({
  filters,
  setFilters,
  setColumnFilterSelectedOptions,
  columnFilterSelectedRef,
  columnLabels,
  columnFilterOptionsChangeHandler,
  columnFilterSelectedOption,
  analysisSelectedOption,
  selectedValues,
  incomingValuesOptions,
  setValuesSelectedOptions,
  setSelectedValues,
  isLoadingIncomingValuesOptions,
  valuesSelectedOptions,
  selectOneOption,
  title
}: FilterFormProps) => {
  const { t } = useLanguage();

  const handleDeleteAllFilters = () => {
    setFilters([]);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    columnFilterSelectedRef?.current?.clearValue();
    setSelectedValues([]);
  };

  const handleAddFilter = () => {
    if (!columnFilterSelectedOption) {
      // Si no hay una opción de columna seleccionada, no se puede agregar un filtro.
      return;
    }
    const newFilter = {
      id: columnFilterSelectedOption?.value,
      column: columnFilterSelectedOption,
      values:
        valuesSelectedOptions && valuesSelectedOptions.length > 0
          ? valuesSelectedOptions
          : undefined,
    };
    const updatedFilters = filters
      ? [
          ...filters.filter(
            (filter) => filter.column.value !== columnFilterSelectedOption.value
          ),
          newFilter,
        ]
      : [newFilter];
    setFilters(updatedFilters);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    setSelectedValues([]);
    columnFilterSelectedRef.current.clearValue();
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
      }}
    >
      <ShadowBox>
        <FormSelectLayout
          title={
            title ? title :
            !selectOneOption
              ? t("dataModelText.downloadReport.filters")
              : "Filtros adicionales"
          }
          required={false}
        >
          <Stack
            sx={{
              flexDirection: "row",
              width: "100%",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {filters && filters.length > 0 && (
              <Stack
                sx={{
                  position: "absolute",
                  left: -65,
                }}
              >
                <FormSelectLayout>
                  <InputLabel title="" transparent={true}>
                    <DeleteForever
                      tooltipPlacement="bottom"
                      onClick={handleDeleteAllFilters}
                      width="30px"
                    />
                  </InputLabel>
                </FormSelectLayout>
              </Stack>
            )}
            <InputLabel title={t("configCriteria.step1.column")}>
              <Select
                reference={columnFilterSelectedRef}
                options={columnLabels}
                className="cc_select"
                name="column"
                onChange={columnFilterOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={columnFilterSelectedOption}
                isClearable
                isDisabled={analysisSelectedOption ? false : true}
              />
            </InputLabel>
            <InputLabel title={t("configCriteria.step2.values")}>
              <VirtualSelect
                incomingValuesOptions={
                  selectedValues !== null && incomingValuesOptions?.length > 0
                    ? incomingValuesOptions.filter(
                        (value) => !selectedValues.includes(value)
                      )
                    : []
                }
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setValuesSelectedOptions={setValuesSelectedOptions}
                isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions}
                isDisabled={
                  analysisSelectedOption && columnFilterSelectedOption
                    ? false
                    : true
                }
                selectOneOption={selectOneOption}
              />
            </InputLabel>
            <Stack
              /* sx={{
                right: -70,
                position: "absolute",
              }} */
            >
              <InputLabel title="" transparent={true}>
                <AddButton
                  onClick={handleAddFilter}
                  disabled={
                    (selectedValues !== null && selectedValues.length < 1) ||
                    (!columnFilterSelectedOption &&
                      columnFilterSelectedOption === null)
                  }
                />
              </InputLabel>
            </Stack>
          </Stack>
          <Stack
            sx={{
              maxHeight: "100px",
              overflowY: "auto",
              width: "100%",
              mt: 1,
              mb: -1,
            }}
          >
            <FilterValueRows filters={filters} setFilters={setFilters} />
          </Stack>
        </FormSelectLayout>
      </ShadowBox>
    </Grid>
  );
};

export default FilterForm;
