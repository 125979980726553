import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import { IconButton } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import useLanguage from "../../../context/LanguageProvider";
import { truncateString } from "../../parameterization/utils";

const AnalysisCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
}: any) => {

  const { t } = useLanguage();

  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--text-main)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "15px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>
        <Divider sx={{ margin: "5px 0" }} />
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
          }}
          color="var(--text-main)"
        >
          {t('dataModelText.analysisManagement.analysisCard.grouper')}
          {item.agrupacion?.length >= 17 ? (
            <Tooltip title={item.agrupacion}>
              <span
                style={{
                  color: "var(--text-info)",
                  textAlign: "left",
                  textTransform: "uppercase",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                }}
              >
                {truncateString(`  ${item.agrupacion}`, 20)}
              </span>
            </Tooltip>
          ) : (
            <span
              style={{
                color: "var(--text-info)",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "500",
                letterSpacing: "-0.5px",
              }}
            >
              {truncateString(`  ${item.agrupacion}`, 18)}
            </span>
          )}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "left",
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "-0.5px",
            height: "55px",
          }}
          color="var(--text-main)"
        >
          {t('dataModelText.analysisManagement.analysisCard.measures')}{" "}
          {
            <span
              style={{
                color: "var(--text-info)",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "400",
                whiteSpace: "normal",
                fontSize: "0.875rem",
              }}
            >
              {truncateString(
                item.medidas
                  .map((medida: string) => medida.toUpperCase())
                  .join(", "), // Unir medidas con coma
                48 // Límite de caracteres
              )}
            </span>
          }
        </Typography>
        <Stack direction="row" justifyContent={"space-between"}>
          <div>
            <Tooltip
              arrow
              placement="right"
              title={item.calculado ? t('dataModelText.analysisManagement.analysisCard.calculated') : t('dataModelText.analysisManagement.analysisCard.noCalculated')}
            >
              <IconButton
                sx={{
                  backgroundColor: "var(--cream)",
                }}
              >
                {item.calculado ? (
                  <DoneOutlineIcon
                    sx={{
                      color: "var(--icon-success)",
                    }}
                  />
                ) : (
                  <CloseIcon
                    sx={{
                      color: "var(--icon-error)",
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AnalysisCard;
