import { GridColumns } from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";
import { OutlinedInput } from "@material-ui/core";
import { Typography } from "@mui/material";
import { getInformationColumn, roundNumber } from "../../../../utils";
import { convertNumberToMoney } from "../../../../../parameterization/utils";


export const getColumnsRetentionRoutes = (retention, setRetention, t, informationsColumns) => {
    const columnsNames: string[] =
        informationsColumns && informationsColumns.map((column) => {
            if (column.informacion === "Identificador único de ruta" || column.informacion === "Venta Bruta" || column.informacion === "Clientes por Ruta") {
                return column.columna
            } else {
                return undefined
            }
        }).filter(item => item !== undefined) || []
    const aditionalColumns = [
        "retencion"
    ]
    const combinedColumns = columnsNames.concat(aditionalColumns)
    const columns: GridColumns = combinedColumns.map((column: string) => {
        return {
            field: column,
            headerName: column,
            headerClassName: "dm_header_column",
            renderHeader: () => {
                return (
                    <strong className="dm_column_title">{column === "retencion" ? t("simulatorTexts.canceledRoutes.step3ModelTable.salesRetention") : column}</strong>
                )
            },
            flex: 1,
            headerAlign: "center",
            minWidth: column === "retencion" ? 230 : 100,
            align: "center",
            wrapText: true,
            renderCell: (params: any) => {
                if (informationsColumns && column === getInformationColumn(informationsColumns, "Clientes por Ruta")) {
                    return <>{roundNumber(params.value, 1)}</>
                } else if (column === "retencion") {
                    const esNumeroValido = (valor: string): boolean => {
                        const regex = /^(\d+([.,]\d{0,3})?)?$/; // Acepta números enteros o con hasta dos decimales
                        return regex.test(valor);
                    };
                    const handleRetencionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                        const nuevoValor = event.target.value;
                        if (nuevoValor === '') {
                            setRetention(params.row[getInformationColumn(informationsColumns, "Identificador único de ruta")], 0);
                        } else if (Number(nuevoValor) > 100) {
                            setRetention(params.row[getInformationColumn(informationsColumns, "Identificador único de ruta")], 100)
                        } else if (esNumeroValido(nuevoValor)) {
                            const valorSinCeroInicial = nuevoValor.replace(/^0(?=[0-9])/, '');
                            setRetention(params.row[getInformationColumn(informationsColumns, "Identificador único de ruta")], valorSinCeroInicial);
                        }
                    };
                    return (
                        <Stack
                            sx={{
                                backgroundColor: "var(--bg-light)",
                                borderBottom: "1px solid red",
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: "none",
                                    borderBottom: "5px solid var(--bg-main)",
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'var(--text-success)',
                                    textAlign: 'center',
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                },
                                '& .MuiTypography-colorTextSecondary': {
                                    color: 'var(--text-success)',
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                }
                            }}
                        >
                            <OutlinedInput
                                endAdornment={
                                    <Typography sx={{
                                        color: 'var(--text-success)',
                                        fontWeight: 'bold',
                                    }}>%</Typography>
                                }
                                value={retention && getInformationColumn(informationsColumns, "Identificador único de ruta") ? retention.find(route => {
                                    if (route.ruta) {
                                        return route.ruta === params.row[getInformationColumn(informationsColumns, "Identificador único de ruta")]
                                    } else {
                                        return route[getInformationColumn(informationsColumns, "Identificador único de ruta")] === params.row[getInformationColumn(informationsColumns, "Identificador único de ruta")]
                                    }
                                }
                                )?.retencion : "-"}
                                onChange={handleRetencionChange}
                            />
                        </Stack>
                    )
                } else if (column === getInformationColumn(informationsColumns, "Venta Bruta") || column === getInformationColumn(informationsColumns, "Costos de Servir") ||
                    getInformationColumn(informationsColumns, "Profit to Serve")) {
                    return <>{convertNumberToMoney(params.value)}</>
                } else {
                    return <>{params.value}</>
                }
            },
        };
    })
    return columns;
};