import { Item } from "../../../basicGrid/BasicGrid";
import Button from "../../../buttons/Button";
import Kpi from "../../../report/Kpi";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import {
  example_dialog_container_styles,
  kpi_item_box_styles,
} from "../../../../styles/app-styles";

const InfoModal = ({ handleToggleModal, column }) => {
  const { t } = useLanguage();

  const handleCancel = () => {
    handleToggleModal();
  };

  return (
    <Stack sx={example_dialog_container_styles}>
      <Stack sx={{ width: "100%", alignItems: "flex-start" }}>
        {column !== "metrica"
          ? t('marketBasket.config.clickOnIndicator')
          : `${t('marketBasket.config.indicatorExample')}:`}
      </Stack>

      <Item
        id="gridItem"
        elevation={12}
        sx={{ ...kpi_item_box_styles(false), width: "300px" }}
      >
        <Kpi
          id={"fake"}
          valor={105.63}
          unidad={"moneda"}
          kpi={"Precio promedio"}
          isAnalysis={column === "metrica" ? true : false}
          minHeight={"0"}
          padding={"0"}
        />
      </Item>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.close")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
      </Stack>
    </Stack>
  );
};

export default InfoModal;
