import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import useLanguage from "../../context/LanguageProvider";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import CustomNoRowsOverlay from "./NoRows";
import { datagrid_styles } from "../../styles/app-styles";
import CustomToolbar from "../customToolbar/CustomToolbar";
interface NumbersTableProps {
  data: any;
  isLoading: boolean;
  isError: boolean;
  transformData: any;
  onNumberClick?: any;
}

const NumbersTable = ({ data, isLoading, isError, transformData, onNumberClick }: NumbersTableProps) => {
  const { t } = useLanguage();

  const gridOptions = {
    autoHeight: true, 
  };

  return (
    <Stack
      width="100%"
      height={120 + transformData(data)?.rows?.length * 30}
    >
      {isLoading ? (
        <CustomSkeleton height={6} />
      ) : isError ? (
        <p>{t("general.error")}</p>
      ) : (
        <Stack style={{ height: "100%", width: "100%" }}>
          <DataGridPro            
            {...gridOptions}
            rowHeight={30}
            rows={onNumberClick ? transformData(data, onNumberClick).rows : transformData(data).rows}
            columns={onNumberClick ? transformData(data, onNumberClick)?.columns :  transformData(data)?.columns }
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Toolbar: () => (
                <CustomToolbar
                  toolbar={true}
                  filterButton={true}
                  columnsButton={true}
                />
              ),
            }}
            disableColumnMenu={true}
            hideFooter={true}
            checkboxSelection={false}
            disableSelectionOnClick
            sx={datagrid_styles}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default NumbersTable;
