import { useEffect } from "react";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import useLanguage from "../../../context/LanguageProvider";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import ViewDashboard from "../../viewDashboardNew/ViewDashboard";
import { INCOMING_VALUES_SALES_FORECAST, PUT_ORDEN_SIMULATION_ELEMENTS, SIMULATION_PERIODS, SIMULATION_VIEWS } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useApi from "../../../hooks/useApi";
import useInfoContext from "../../../hooks/useInfoContext";

const SalesForecastResult = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const navigate = useNavigate();
  const analysisId = localStorage.getItem("analysisId") || undefined
  const salesForecastView = localStorage.getItem("salesForecastView")
    ? localStorage.getItem("salesForecastView")
    : undefined;
  const salesForecastId = localStorage.getItem("salesForecastId")
    ? localStorage.getItem("salesForecastId")
    : undefined;

  const {
    isLoading,
    setTabValue,
    setAnalysisViews,
    setViewTabValue,
    setAnalysisPeriods
  } = useViewDashboard();

  /****************************** CARGA Y OBTENCIÓN DE DATOS ******************************/

  salesForecastView && setViewTabValue(salesForecastView)
  analysisId && setTabValue(analysisId)

  const {
    isLoading: isLoadingGetAnalysisViews,
    data: dataAnalysisViews,
    refetch: refetchGetAnalysisViews,
  } = useApiQuery(SIMULATION_VIEWS("SALES_FORECAST"), false, t("dataModel.getAnalysis.error"));



  useEffect(() => {
    if (dataAnalysisViews) {
      setAnalysisViews({
        isLoading: isLoadingGetAnalysisViews,
        data: dataAnalysisViews,
        refetch: refetchGetAnalysisViews,
      });
    }
  }, [dataAnalysisViews, isLoadingGetAnalysisViews]);

  const {
    isLoading: isLoadingGetAnalysisPeriods,
    callApi: getAnalysisPeriods,
    data: dataGetAnalysisPeriod,
  } = useApi(
    SIMULATION_PERIODS("sales_forecast", salesForecastId),
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    setAnalysisPeriods({
      isLoading: isLoadingGetAnalysisPeriods,
      data: dataGetAnalysisPeriod,
      getData: getAnalysisPeriods,
    });
  }, [dataGetAnalysisPeriod, isLoadingGetAnalysisPeriods]);

  /****************************** MANEJO DE FUNCIONES ******************************/

  const handleGoBack = () => {
    navigate("/delta/sales-forecast");
    localStorage.removeItem("analysisId");
    localStorage.removeItem("salesForecastView");
    localStorage.removeItem("salesForecastId");
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoading || isLoadingGetAnalysisViews || isLoadingGetAnalysisPeriods}
        message={t("general.loading")}
      />
      {
        dataAnalysisViews && <ComponentLayout
          title={`SALES FORECAST - ${dataAnalysisViews && salesForecastView && dataAnalysisViews
            .flatMap(view => view.vistas)?.find(vista => vista.id == salesForecastView)?.nombre?.replace("SALES FORECAST ", "")}`}
          icon={
            <ArrowBackIcon
              sx={{
                fontSize: "35px",
                marginRight: "10px",
                "& :hover": { cursor: "pointer", color: "#f90d4a" },
              }}
              onClick={handleGoBack}
            />
          }
        >
          <SimpleBackdrop
            open={isLoading}
            message={t("general.loading")}
          />
          <ViewDashboard
            thereArePeriodFilter={true}
            thereAreChipFilters={true}
            thereAreViews={false}
            thereAreTabs={false}
            rolEdit={userRolData && userRolData.simulador.visualizacion_y_edicion}
            rolCreate={
              userRolData && userRolData.simulador.visualizacion_y_creacion
            }
            actualUrl="/delta/sales-forecast-resultado"
            ELEMENTS_URL={() => `simulador/vistas/${salesForecastView}/elementos`}
            ELEMENT_URL={(salesForecastView, elemento_id) =>
              `simulador/vistas/${salesForecastView}/elementos/${elemento_id}`}
            PERIODS_URL={SIMULATION_PERIODS("sales_forecast", salesForecastId)}
            INCOMING_VALUES={salesForecastId && INCOMING_VALUES_SALES_FORECAST(salesForecastId)}
            PUT_ORDEN_ELEMENT_URL={PUT_ORDEN_SIMULATION_ELEMENTS}
            dashboardType={"SALES_FORECAST"}
          />
        </ComponentLayout>
      }
    </>
  );
};

export default SalesForecastResult;
