import { optionalSelectStyles, selectStyles } from "../../configCriteria/select.styles";
import { useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";

const ElementComponent = ({
  element,
  dataUsers,
  configMode,
  setUsersByDashboard,
}) => {
  const { t } = useLanguage();
  const userSelectRef: any = useRef(null);
  const [expanded, setExpanded] = useState<string | false>(false);

  const onUsersChange = (selectedOptions) => {
    setUsersByDashboard((prev) => {
      const newUsers = prev.map((dash) => {
        if (dash.id === element.id) {
          return {
            ...dash,
            usuarios: selectedOptions,
          };
        }
        return dash;
      });
      return newUsers;
    });
  };

  const [
    usersSelectedOptions,
    setUsersSelectedOptions,
    usersOptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" ? element?.usuarios : undefined,
    onUsersChange
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        backgroundColor: "white",
        m: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between" /* , gap: 2 */,
        }}
      >
        <span
          style={{
            width: "100%",
            color: "var(--text-main) !important",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {element.nombre}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <FormSelectLayout title={t("Usuarios habilitados")} required={false}>
          <Select
            reference={userSelectRef}
            styles={optionalSelectStyles(usersSelectedOptions)}
            options={dataUsers}
            className="cc_select"
            onChange={usersOptionsChangeHandler}
            closeMenuOnSelect={false}
            isMulti
            placeholder={t("administratorPanel.users.multipleOptions")}
            defaultValue={usersSelectedOptions?.map((option) => option)}
            isClearable
          />
        </FormSelectLayout>
      </AccordionDetails>
    </Accordion>
  );
};

export default ElementComponent;
