import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { COMPANY_URL, GET_ALL_COMPANIES_ADMIN_URL, GET_ALL_COMPANIES_URL } from "../../api/axios";


export const useGetCompanies = () => {
    const axiosPrivate = useAxiosPrivate();
  
    const getAllCompanies = async () => {
      const response = await axiosPrivate.get(GET_ALL_COMPANIES_URL);
      return response.data;
    };
    const info: UseQueryResult<[], Error> = useQuery(
      ["getAllCompanies"],
      getAllCompanies
    );
  
    return info;
  };

  export const useGetCompaniesAdmin = () => {
    const axiosPrivate = useAxiosPrivate();
  
    const getAllCompaniesAdmin = async () => {
      const response = await axiosPrivate.get(GET_ALL_COMPANIES_ADMIN_URL);
      return response.data;
    };
    const info: UseQueryResult<[], Error> = useQuery(
      ["getAllCompaniesAdmin"],
      getAllCompaniesAdmin
    );
  
    return info;
  };

  export const getUserCompany = async (
    axiosPrivate: any,  
    handleGetCompanyResponse: any
  ) => {
    try {
      const response = await axiosPrivate.get(COMPANY_URL);
      handleGetCompanyResponse(response.status, response.data);    
    } catch (error) {
      handleGetCompanyResponse(400, error);     
    }
  };