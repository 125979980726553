import {
  checkIfHaveCriteria,
  transformRowsToDeleteCriteria,
} from "../../utils";
import { ConfirmationModal } from "../../../dialog";
import { DELETE_CRITERIA_URL } from "../../../../api/axios";
import { useParameterization } from "../../../../hooks/useParameterization";
import { useState } from "react";
import Button from "@mui/material/Button";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import {
  criteria_buttons_container,
  criteria_buttons_styles,
  criteria_buttons_tooltip_styles,
} from "../../../../styles/app-styles";

interface ICriteriaButtons {
  rows: any;
  refetchRowsData: () => void;
}

const CriteriaButtons = ({ rows, refetchRowsData }: ICriteriaButtons) => {
  const { t } = useLanguage();
  const { parameterizationState, handleToggleCriteriaModal, refetchBuckets } =
    useParameterization();
  const { tabValue, selectionModel, selectedStage, isExpenseTargetEqual } =
    parameterizationState;
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();
  const [modalMessage, setModalMessage] = useState("");

  /************************************ Eliminar criterios **************************************/

  const haveRowsCriteria =
    rows && checkIfHaveCriteria(selectionModel, rows).length !== 0;

  const handleDelete = () => {
    const selectedRows = rows && checkIfHaveCriteria(selectionModel, rows);
    const message =
      selectedRows.length > 1
        ? `${t(
            "modelParameterizationTexts.customToolbar.confirmationWithRowsFirstPart"
          )} ${selectedRows.length} ${t(
            "modelParameterizationTexts.customToolbar.confirmationWithRowsSecondPart"
          )} `
        : t(
            "modelParameterizationTexts.customToolbar.confirmationsWithoutRows"
          );
    setModalMessage(message);
    handleToggleConfirmationModal();
  };

  const handleAccept = () => {
    const selectedRows = rows && checkIfHaveCriteria(selectionModel, rows);
    handleToggleConfirmationModal();
    deleteGrouperCriteria(
      undefined,
      transformRowsToDeleteCriteria(selectedRows)
    );
  };

  const onSuccessDeleteCriteria = () => {
    setTimeout(() => {
      refetchBuckets();
      refetchRowsData();
    }, 3000);
  };

  const { isLoading: isLoadingDeleteCriteria, callApi: deleteGrouperCriteria } =
    useApi(
      DELETE_CRITERIA_URL,
      "DELETE",
      selectionModel.length !== 1
        ? t("modelParameterization.deleteCriteria.pruralCodes")
        : t("modelParameterization.deleteCriteria.singularCodes"),
      undefined,
      onSuccessDeleteCriteria
    );

  /************************************ Asignar criterios **************************************/

  const shouldAssignCriteria = !(
    selectionModel.length === 0 ||
    selectedStage.etapa === 0 ||
    (tabValue ===
      t("modelParameterizationTexts.step2.tabValueExpensesNotTransferred") &&
      !isExpenseTargetEqual)
  );

  const getAssignTooltipTitle =
    selectionModel.length === 0
      ? t("modelParameterizationTexts.customToolbar.noRowsSelected")
      : !isExpenseTargetEqual
      ? t("modelParameterizationTexts.customToolbar.difReceivers")
      : t("modelParameterizationTexts.customToolbar.noCriteriaStage0");

  const RenderDeleteButton = ({ children }) => {
    return haveRowsCriteria ? (
      children
    ) : (
      <Tooltip
        placement="top-start"
        title={t(
          "modelParameterizationTexts.customToolbar.noRowsSelectedWithCriterias"
        )}
        sx={criteria_buttons_tooltip_styles}
      >
        <div>{children}</div>
      </Tooltip>
    );
  };

  const RenderAssignCriteriaButton = ({ children }) => {
    return shouldAssignCriteria ? (
      children
    ) : (
      <Tooltip
        placement="top"
        title={getAssignTooltipTitle}
        sx={criteria_buttons_tooltip_styles}
      >
        <div>{children}</div>
      </Tooltip>
    );
  };

  return (
    <Stack sx={criteria_buttons_container}>
      <RenderDeleteButton>
        <Button
          variant="text"
          onClick={haveRowsCriteria ? handleDelete : () => <></>}
          disabled={haveRowsCriteria ? !rows || rows.length === 0 : true}
          sx={criteria_buttons_styles}
        >
          {t("modelParameterizationTexts.customToolbar.deleteCriterias")}
        </Button>
      </RenderDeleteButton>
      <RenderAssignCriteriaButton>
        <Button
          variant="text"
          onClick={
            shouldAssignCriteria ? handleToggleCriteriaModal : () => <></>
          }
          sx={criteria_buttons_styles}
          disabled={!shouldAssignCriteria}
        >
          {t("modelParameterizationTexts.customToolbar.assingCriteria")}
        </Button>
      </RenderAssignCriteriaButton>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleToggleConfirmationModal}
        handleAccept={handleAccept}
        message={modalMessage}
        customMessage
        title={t("modelParameterizationTexts.customToolbar.confirm")}
      />
      <SimpleBackdrop
        open={isLoadingDeleteCriteria}
        message={
          selectionModel.length !== 1
            ? t("modelParameterization.deleteCriteria.singularLoading")
            : t("modelParameterization.deleteCriteria.pluralLoading")
        }
      />
    </Stack>
  );
};

export default CriteriaButtons;
