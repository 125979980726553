import Button from "../buttons/Button";
import Dialog from "../dialog/Dialog";
import ImportElementsModal from "./ImportElementsModal";
import Stack from "@mui/material/Stack";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";

interface ImportButtonProps {
  postImportElements: any;
  getElements: any;
  dataGetElements: any;
  GET_ELEMENTS_URL: (companyId: any) => string;
  message?: string;
}

const ImportButton = ({
  postImportElements,
  getElements,
  dataGetElements,
  GET_ELEMENTS_URL,
  message,
}: ImportButtonProps) => {
  const { t } = useLanguage();
  const [openExportDialog, toggleExportDialog] = useDialog();
  const [openConfirmationGetItemModal, toggleConfirmationGetItemModal] =
    useDialog();

  return (
    <>
      <Dialog
        open={openExportDialog}
        handleClose={toggleExportDialog}
        title={t('importElements.importElements')}
        maxWidth={"lg"}
      >
        <Stack sx={{ width: "800px" }}>
          <ImportElementsModal
            toggleItemsListModal={toggleExportDialog}
            openConfirmationModal={openConfirmationGetItemModal}
            toggleConfirmationModal={toggleConfirmationGetItemModal}
            postImportElements={postImportElements}
            getElements={getElements}
            GET_ELEMENTS_URL={GET_ELEMENTS_URL}
            dataGetElements={dataGetElements}
            message={message}
          />
        </Stack>
      </Dialog>
      <Button
        title={t('importElements.importElements')}
        color="blue-greeny"
        type="button"
        onClick={() => toggleExportDialog()}
      />
    </>
  );
};

export default ImportButton;
