import { DOWNLOAD_TEMPLATE_PARAMS_URL } from "../../../../api/axios";
import { form_subtitle_styles } from "../../../../styles/app-styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import Button from "../../../buttons/Button";
import DropzoneInput from "../Shared/DropzoneInput";
import Grid2 from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useInfoContext from "../../../../hooks/useInfoContext";

const UploadFileModal = ({ handleToggleModal, handleApply }) => {
  const { t } = useLanguage();
  const { company }: any = useInfoContext();
  const {
    exchangeFileToUpload,
    setExchangeFileToUpload,
    currencySelectedOptionState,
    foreignCurrencySelectedOptionState,
  } = useCompanyParamProvider();

  const {
    isLoading: isLoadingTemplate,
    callApi: getTemplate,
  } = useApi(
    DOWNLOAD_TEMPLATE_PARAMS_URL,
    "POST",
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  );

  const handleDownloadFile = () => {
    getTemplate(
      undefined,
      {
        moneda: currencySelectedOptionState?.value,
        tipo_de_cambio: foreignCurrencySelectedOptionState?.map(
          (currency) => currency.value
        ),
      },
      {
        fileName: `TIPO-DE-CAMBIO-${company?.label}`,
        fileType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );
  };

  return (
    <Stack
      sx={{
        width: "750px",
        minHeight: "400px",
        justifyContent: "flex-start",
      }}
    >
      <SimpleBackdrop
        open={isLoadingTemplate}
        message={`${t("general.loading")}`}
      />
      <Grid2 container direction={"row"} xs={12} gap={2}>
        <Grid2 xs={12} sx={{ alignItems: "center" }}>
          <Stack width={"100%"} alignItems="center">
            <Typography sx={{ ...form_subtitle_styles }}>
              {t("companyParam.templateDownload")}
            </Typography>
            <Typography sx={{ color: "var(--text-main)" }}>
              {t("companyParam.downloadTemplateDescription")}
            </Typography>
            <Button
              title={t("companyParam.downloadTemplate")}
              color="blue-greeny"
              type="button"
              onClick={handleDownloadFile}
              disabled={
                !currencySelectedOptionState ||
                foreignCurrencySelectedOptionState.length === 0
              }
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12}>
          <Stack width={"100%"} alignItems="center">
            <Typography sx={{ ...form_subtitle_styles }}>
              {t("companyParam.fileUpload")}
            </Typography>
            <DropzoneInput
              fileToUpload={exchangeFileToUpload}
              setFileToUpload={setExchangeFileToUpload}
              postFile={() => <></>}
              dropzoneLabel={""}
              required={false}
              acceptedTypes={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              }}
            />
          </Stack>
        </Grid2>
      </Grid2>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", alignSelf: "flex-end" }}
      >
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleToggleModal}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!exchangeFileToUpload || exchangeFileToUpload.length === 0}
        />
      </Stack>
    </Stack>
  );
};

export default UploadFileModal;
