import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  step_message_container_styles,
  step_message_styles,
} from "../../styles/app-styles";

interface IMessageBlock {
  message: {
    text: string;
    type: string;
  };
}

const MessageBlock = ({ message }: IMessageBlock) => {
  return (
    <>
      {message && message.text !== "" && (
        <Box sx={{ ...step_message_container_styles, margin: "10px 0" }}>
          <Typography sx={step_message_styles(message?.type)}>
            {message.text}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default MessageBlock;
