import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import {
  list_item_formula_span_styles,
  list_item_formula_text_styles,
} from "../../../../styles/app-styles";

const ListItemComponent = ({
  elementsList,
  handleAddElementToFormula,
  medidaInLast,
  formula,
}) => {
  return (
    <>
      {elementsList?.map((element: any) => {
        return (
          <ListItem
            sx={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            key={element.nombre}
            disablePadding
          >
            <ListItemButton
              sx={{
                "&:hover": {
                  backgroundColor: "var(--bg-light)",
                },
              }}
              onClick={() =>
                handleAddElementToFormula(
                  element.nombre,
                  element.nombre,
                  element.tipo
                )
              }
              disabled={medidaInLast}
            >
              <ListItemText
                sx={list_item_formula_text_styles}
                primary={element.nombre}
              />
              {formula?.filter((formula: any) => formula.dato === element.id)
                .length !== 0 && (
                <Stack component="span" sx={list_item_formula_span_styles}>
                  {
                    formula?.filter(
                      (formula: any) => formula.dato === element.id
                    ).length
                  }
                </Stack>
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};

export default ListItemComponent;
