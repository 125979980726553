import BarInfiniteDrilldownChart from "../../Charts/BarInfiniteDrilldownChart";
import Boxplot from "../../Charts/Boxplot";
import LinesChart from "../../Charts/LinesChart";
import PieDrilldownChart from "../../Charts/PieDrilldownChart";
import ScatterPlot from "../../Charts/ScatterPlot";
import TreemapNewDrilldownChart from "../../Charts/TreemapNewDrilldownChart";
import WaterfallChart from "../../Charts/Waterfall";

const RenderChart = ({ dataGetElement, item }) => {
  const Chart = () => {
      switch (dataGetElement.tipo) {
        case "WATERFALL":
          return <WaterfallChart results={dataGetElement} />;
        case "SCATTERPLOT":
          return <ScatterPlot results={dataGetElement} />;
        case "LINEAS":
          return <LinesChart results={dataGetElement} />;
        case "BARRAS CON DRILLDOWN":
          return <BarInfiniteDrilldownChart results={dataGetElement} stack={true}/>;
        case "TORTA CON DRILLDOWN":
          return <PieDrilldownChart results={dataGetElement} />;
        case "TREEMAP CON DRILLDOWN":
          return <TreemapNewDrilldownChart results={dataGetElement} />;
          case "BOXPLOT":
          return <Boxplot results={dataGetElement} />;
        default:
          return null;
      }
  }


  return Chart();
};

export default RenderChart;
