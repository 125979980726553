import { useEffect, useState } from "react";
import { IdOption, Step1Props } from "./types";
import Divider from "@mui/material/Divider";
import "../forms/step.styles.css";
import {
  expenseTargetOptions,
  fileOptions,
  columnOptions,
  functionOptions,
} from "./selectOptions";
import { selectStyles, selectMultiStyles } from "./select.styles";
import FormSelectLayout from "../forms/FormSelectLayout";
import FormLayout from "../forms/FormLayout";
import { AddButton } from "../buttons/index";
import { InputLabel, CustomSelect as Select } from "../forms";
import CustomInput from "../forms/CustomInput";
import { formatOptions } from "../../utils/util";
import useDialog from "../../hooks/useDialog";
import AddTargetModal from "./AddTargetModal";
import { ActionMeta, SingleValue } from "react-select";
import { ConfirmationModal } from "../dialog";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { form_label, form_subtitle_styles } from "../../styles/app-styles";
import useLanguage from "../../context/LanguageProvider";

const Step1 = ({
  configMode,
  setStep1Complete,
  handleChangeBaseFileOnEdit,
  bucketSelectOptions,
  expenseTargetSelectOptions,
  fileColumnSelectOptions,
  functionSelectOptions,
  refetchCriteriaData,
  fileSelectedOptions,
  fileOptionsChangeHandler,
  bucketSelectedOptions,
  bucketSelectedOptionsChangeHandler,
  criteriaName,
  setCriteriaName,
  expenseTargetSelectedOptions,
  expenseTargetChangeHandler,
  columnSelectedOptions,
  columnOptionsChangeHandler,
  functionSelectedOptions,
  functionOptionsChangeHandler,
  getAbsoluteValues,
  setGetAbsoluteValues,
  uniqueSelectedOptions,
  uniqueOptionsChangeHandler,
  multiplySelectedOptions,
  multiplyOptionsChangeHandler,
  columnSelectRef,
  criteriaNameRef,
  bucketSelectRef,
  expenseTargetSelectRef,
  fileSelectRef,
  functionSelectRef,
  uniqueSelectRef,
  multiplySelectRef,
  criteriaType,
  refetchCriteriaTarget,
  convertNegativeToZero,
  setConvertNegativeToZero,
  getNegativeValues,
  setGetNegativeValues,
}: Step1Props) => {
  const [openAddTargetModal, setOpenAddTargetModal] = useDialog();

  const [openFileChangeConfirmationModal, setOpenFileChangeConfirmationModal] =
    useDialog();

  const [changesInFile, setChangesInFile] = useState(0);
  const [selectedFile, setSelectedFile] = useState(fileSelectedOptions);
  const [fileToChange, setFileToChange] = useState<SingleValue<IdOption>>();
  const [actionToChange, setActionToChange] = useState<ActionMeta<IdOption>>();

  const { t } = useLanguage();

  const isModelPage = location.pathname === "/parametrizacion-modelo/calculo";

  const onFileChange = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (
      (configMode === "EDIT" || configMode === "COPY") &&
      changesInFile === 0
    ) {
      setActionToChange(actionMeta);
      setFileToChange(newValue);
      setOpenFileChangeConfirmationModal();
    } else {
      fileOptionsChangeHandler(newValue, actionMeta);
      setSelectedFile(newValue);
    }
  };

  const handleAcceptFileChangeConfirmationModal = () => {
    setOpenFileChangeConfirmationModal();
    setChangesInFile(changesInFile + 1);
    handleChangeBaseFileOnEdit();
    if (actionToChange) {
      fileOptionsChangeHandler(
        fileToChange as SingleValue<IdOption>,
        actionToChange
      );
      setSelectedFile(fileToChange);
    }
  };

  const handleStep1Complete = () => {
    if (
      !!criteriaName &&
      !!fileSelectedOptions &&
      !!columnSelectedOptions &&
      !!functionSelectedOptions
    ) {
      if (
        functionSelectedOptions.value === "SUMA.UNICO" &&
        uniqueSelectedOptions
      ) {
        setStep1Complete(true);
      } else if (
        functionSelectedOptions.value ===    t("configCriteria.step1.sumProductFunction") &&
        !!multiplySelectedOptions &&
        multiplySelectedOptions.length > 0
      ) {
        setStep1Complete(true);
      } else if (
        functionSelectedOptions.value !== "SUMA.UNICO" &&
        functionSelectedOptions.value !==    t("configCriteria.step1.sumProductFunction")
      ) {
        setStep1Complete(true);
      } else {
        setStep1Complete(false);
      }
    } else {
      setStep1Complete(false);
    }
  };

  useEffect(() => {
    handleStep1Complete();
  }, [
    criteriaName,
    fileSelectedOptions,
    columnSelectedOptions,
    functionSelectedOptions,
    uniqueSelectedOptions,
    multiplySelectedOptions,
    getAbsoluteValues,
  ]);

  useEffect(() => {
    if (
      functionSelectedOptions?.value ===
        t("configCriteria.step1.countFunction") ||
      functionSelectedOptions?.value ===
        t("configCriteria.step1.countUniqueFunction")
    ) {
      setGetNegativeValues(true),
        setGetAbsoluteValues(false),
        setConvertNegativeToZero(false);
    }
  }, [functionSelectedOptions]);

  const toggleNegativeValues = (e) => {
    if (e.target.id === "getNegativeValues") {
      setGetNegativeValues(true);
      setGetAbsoluteValues(false);
      setConvertNegativeToZero(false);
    } else if (e.target.id === "getAbsoluteValues") {
      setGetNegativeValues(false);
      setGetAbsoluteValues(true);
      setConvertNegativeToZero(false);
    } else if (e.target.id === "convertNegativeToZero") {
      setGetNegativeValues(false);
      setGetAbsoluteValues(false);
      setConvertNegativeToZero(true);
    }
  };

  return (
    <div
      className={isModelPage ? "cc_step1_containerModel" : "cc_step1_container"}
    >
      <FormLayout width="45%">
        <Box>
          <Typography variant="h2" sx={form_subtitle_styles}>
            {t("configCriteria.step1.description")}
          </Typography>
          <FormSelectLayout
            title={t("configCriteria.step1.criteriaName")}
            required={true}
          >
            <CustomInput
              type="text"
              name="criteriaName"
              inputRef={criteriaNameRef}
              value={criteriaName}
              setValue={setCriteriaName}
              required={true}
              placeholder={t("configCriteria.step1.criteriaName")}
              autoFocus={true}
              maxLength={100}
            />
          </FormSelectLayout>
        </Box>

        {criteriaType === t("tabs.proration") && (
          <>
            <Divider sx={{ marginTop: "15px" }} />
            <Typography variant="h2" sx={form_subtitle_styles}>
              {t("configCriteria.step1.applyIn")}
            </Typography>
            <FormSelectLayout title={t("configCriteria.step1.bucket")}>
              <Select
                id={t("configCriteria.step1.bucket")}
                reference={bucketSelectRef}
                styles={selectMultiStyles(bucketSelectedOptions)}
                options={formatOptions(bucketSelectOptions)}
                className="cc_select"
                name="bucket"
                onChange={bucketSelectedOptionsChangeHandler}
                closeMenuOnSelect={false}
                isMulti
                placeholder={t("configCriteria.step1.all")}
                defaultValue={bucketSelectedOptions?.map((option) => option)}
                data-testid="select_bucket_component"
                isClearable
              />
            </FormSelectLayout>
            <FormSelectLayout title={t("configCriteria.step1.targetExpenses")}>
              <div className="cc_select cc_add_target_box ">
                <Select
                  reference={expenseTargetSelectRef}
                  styles={selectMultiStyles(expenseTargetSelectedOptions)}
                  options={expenseTargetOptions(expenseTargetSelectOptions)}
                  form="cc_form"
                  className="cc_expense_target_select"
                  name={t("configCriteria.step1.targetExpenses")}
                  onChange={expenseTargetChangeHandler}
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder={t("configCriteria.step1.all")}
                  defaultValue={expenseTargetSelectedOptions?.map(
                    (option) => option
                  )}
                  data-testid="select_target_component"
                  isClearable
                />
                <AddButton onClick={setOpenAddTargetModal} />
              </div>
            </FormSelectLayout>
          </>
        )}
      </FormLayout>
      <FormLayout width="45%">
        <Typography variant="h2" sx={form_subtitle_styles}>
          {t("configCriteria.step1.calcParameterization")}
        </Typography>
        <FormSelectLayout
          title={t("configCriteria.step1.file")}
          required={true}
        >
          <Select
            reference={fileSelectRef}
            value={selectedFile}
            styles={selectStyles(fileSelectedOptions)}
            options={fileOptions(fileColumnSelectOptions)}
            form="cc_form"
            className="cc_select"
            name={t("configCriteria.step1.file")}
            onChange={onFileChange}
            closeMenuOnSelect
            placeholder={t("configCriteria.step1.selectFile")}
            defaultValue={fileSelectedOptions}
            data-testid="select_file_component"
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={t("configCriteria.step1.column")}
          required={true}
        >
          <Select
            reference={columnSelectRef}
            styles={selectStyles(columnSelectedOptions)}
            options={columnOptions(
              fileColumnSelectOptions,
              fileSelectedOptions
            )}
            form="cc_form"
            className="cc_select"
            name={t("configCriteria.step1.column")}
            onChange={columnOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("configCriteria.step1.selectColumn")}
            defaultValue={columnSelectedOptions}
            data-testid="select_column_component"
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={t("configCriteria.step1.function")}
          required={true}
        >
          <Select
            reference={functionSelectRef}
            styles={selectStyles(functionSelectedOptions)}
            options={functionOptions(functionSelectOptions)}
            form="cc_form"
            className="cc_select"
            name={t("configCriteria.step1.function")}
            onChange={functionOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("configCriteria.step1.selectFunction")}
            defaultValue={functionSelectedOptions}
            data-testid="select_function_component"
            isClearable
          />
        </FormSelectLayout>
        {functionSelectedOptions?.value !==
          t("configCriteria.step1.countFunction") &&
          functionSelectedOptions?.value !==
            t("configCriteria.step1.countUniqueFunction") && (
            <div className="cc_step_form_checkbox cc_checkbox_container">
              <Stack>
                <Stack direction="row">
                  <label
                    htmlFor="getNegativeValues"
                    style={{
                      color:
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countFunction") ||
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countUniqueFunction")
                          ? "var(--text-info)"
                          : "var(--text-main)",
                      fontWeight: 500,
                    }}
                  >
                    <input
                      type="radio"
                      className="cc_checkbox"
                      name="getValues"
                      id="getNegativeValues"
                      onChange={(e) => toggleNegativeValues(e)}
                      checked={getNegativeValues}
                      disabled={
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countFunction") ||
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countUniqueFunction")
                      }
                    />
                    {t("configCriteria.step1.notModifyValues")}
                  </label>
                </Stack>
                <Stack direction="row">
                  <label
                    style={{
                      color:
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countFunction") ||
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countUniqueFunction")
                          ? "var(--text-info)"
                          : "var(--text-main)",
                      fontWeight: 500,
                    }}
                    htmlFor="getAbsoluteValues"
                  >
                    <input
                      type="radio"
                      className="cc_checkbox"
                      id="getAbsoluteValues"
                      name="getValues"
                      onChange={(e) => toggleNegativeValues(e)}
                      checked={getAbsoluteValues}
                      disabled={
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countFunction") ||
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countUniqueFunction")
                      }
                    />
                   {t("configCriteria.step1.convertNegativesToPositives")}
                  </label>
                </Stack>
                <Stack direction="row">
                  <label
                    style={{
                      color:
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countFunction") ||
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countUniqueFunction")
                          ? "var(--text-info)"
                          : "var(--text-main)",
                      fontWeight: 500,
                    }}
                    htmlFor="convertNegativeToZero"
                  >
                    <input
                      type="radio"
                      className="cc_checkbox"
                      name="getValues"
                      id="convertNegativeToZero"
                      onChange={(e) => toggleNegativeValues(e)}
                      checked={convertNegativeToZero}
                      disabled={
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countFunction") ||
                        functionSelectedOptions?.value ===
                          t("configCriteria.step1.countUniqueFunction")
                      }
                    />
                     {t("configCriteria.step1.convertNegativesToZero")}
                  </label>
                </Stack>
              </Stack>
            </div>
          )}
        {functionSelectedOptions?.value === t("configCriteria.step1.sumUniqueFunction") && (
          <>
            <Divider sx={{ marginTop: "25px" }} />
            <Typography variant="h2" sx={form_subtitle_styles}>
           { t("configCriteria.step1.sumUniqueFunctionLabel")}
            </Typography>
            <FormSelectLayout title= { t("configCriteria.step1.criteriaUniqueColumn")}required={true}>
              <Select
                reference={uniqueSelectRef}
                styles={selectStyles(uniqueSelectedOptions)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                form="cc_form"
                className="cc_select"
                name="unique"
                onChange={uniqueOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={ t("configCriteria.step1.selectFile")}
                defaultValue={uniqueSelectedOptions}
                isClearable
              />
            </FormSelectLayout>
          </>
        )}
     
        {functionSelectedOptions?.value === t("configCriteria.step1.sumProductFunction") && (
          <>
            <Divider sx={{ marginTop: "25px" }} />
            <Typography variant="h2" sx={form_subtitle_styles}>
             {   t("configCriteria.step1.sumProductFunctionLabel")}
            </Typography>
            <FormSelectLayout title={t("configCriteria.step1.columnToMultiply")} required={true}>
              <Select
                reference={multiplySelectRef}
                styles={selectStyles(multiplySelectedOptions)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                form="cc_form"
                className="cc_select"
                name="multiply"
                onChange={multiplyOptionsChangeHandler}
                closeMenuOnSelect={false}
                isMulti
                placeholder={t("configCriteria.step1.selectOptions")}
                defaultValue={multiplySelectedOptions?.map((option) => option)}
                isClearable
              />
            </FormSelectLayout>
          </>
        )}
        <AddTargetModal
          openAddTargetModal={openAddTargetModal}
          setOpenAddTargetModal={setOpenAddTargetModal}
          refetchCriteriaData={refetchCriteriaData}
          setGetAbsoluteValues={setGetAbsoluteValues}
          expenseTargetSelectOptions={expenseTargetSelectOptions}
          refetchCriteriaTarget={refetchCriteriaTarget}
        />
        <ConfirmationModal
          open={openFileChangeConfirmationModal}
          handleClose={setOpenFileChangeConfirmationModal}
          handleAccept={handleAcceptFileChangeConfirmationModal}
          message={t("configCriteria.step1.changeFileWarning")}
          customMessage
          title={t("configCriteria.step1.editFileLabel")}
        />
      </FormLayout>
    </div>
  );
};

export default Step1;
