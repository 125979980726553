import Select, {
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  StylesConfig,
} from "react-select";
import { IdOption } from "../configCriteria/types";
import useLanguage from "../../context/LanguageProvider";

interface CustomSelectProps {
  id?: string;
  reference?: any;
  value?: PropsValue<IdOption> | undefined;
  styles?: StylesConfig<IdOption, true, GroupBase<IdOption>> | undefined;
  options: OptionsOrGroups<IdOption, GroupBase<IdOption>> | undefined;
  form?: string;
  className?: string;
  name?: string;
  onChange: any;
  placeholder: string;
  defaultValue?: PropsValue<IdOption> | undefined;
  closeMenuOnSelect: boolean;
  isMulti?: true | undefined;
  isDisabled?: boolean | undefined;
  isClearable?: boolean | undefined;
  isSearchable?: boolean | undefined;
  isLoadingGetData?: boolean;
}
const CustomSelect = ({
  id,
  reference,
  value,
  styles,
  options,
  form,
  className,
  name,
  onChange,
  placeholder,
  defaultValue,
  closeMenuOnSelect,
  isMulti,
  isDisabled,
  isClearable,
  isSearchable,
  isLoadingGetData
}: CustomSelectProps) => {
  const { t } = useLanguage();
  return (
    <Select
      value={value}
      maxMenuHeight={150}
      id={id}
      ref={reference}
      styles={styles}
      options={options}
      form={form}
      className={className}
      name={name}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      closeMenuOnSelect={closeMenuOnSelect}
      isSearchable={isSearchable}
      placeholder={placeholder}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      noOptionsMessage={isLoadingGetData ? ()=> t("forms.select.loading") : () => t("forms.select.noOptionsAvailable")}
    />
  );
};

export default CustomSelect;
