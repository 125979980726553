export const updateSelectedOptions = (
  index,
  column,
  newOptions,
  setSelectedOptions
) => {
  setSelectedOptions((prevSelectedOptions) => {
    let updatedOptions = [...prevSelectedOptions];
    if (newOptions.length === 0) {
      updatedOptions = prevSelectedOptions.filter(
        (option) => option.column_id !== column.value
      );
    } else {
      const existingOptionIndex = updatedOptions.findIndex(
        (option) => option.columnId === column.value
      );
      if (existingOptionIndex !== -1) {
        /* updatedOptions = prevSelectedOptions.filter((option) => option.column_id !== column.value); */
        //Actualiza los valores de las opciones existentes
        updatedOptions[existingOptionIndex] = {
          ...updatedOptions[existingOptionIndex],
          values: newOptions.map((option) => option.value),
        };
      } else {
        //Añado una nueva opción
        updatedOptions.push({
          column_id: column.value,
          column_label: column.label,
          values: newOptions.map((option) => option.value),
        });
      }
    }
    //Eliminar valores vacíos de values
    const filteredOptions = updatedOptions.filter(
      (option) => option.values.length > 0
    );
    return filteredOptions;
  });
};

export const getAnalysisById = (tabValue, dataAnalysisViews) => {
  const analysis = dataAnalysisViews?.find(
    (analysis: any) => analysis.id === tabValue
  );
  return analysis;
};

export const getViewById = (tabValue, viewTabValue, dataAnalysisViews) => {
  const analysis = getAnalysisById(tabValue, dataAnalysisViews);
  const view = analysis?.vistas?.find((view: any) => view.id === viewTabValue);
  return view;
};

export const getElementById = (
  tabValue,
  viewTabValue,
  dataAnalysisViews,
  id
) => {
  const view = getViewById(tabValue, viewTabValue, dataAnalysisViews);
  const element = view?.elementos?.find((element: any) => element.id === id);
  return element;
};

export const getPeriodsLabelsFromPeriodsIds = (
  periodsIds,
  dataGetAnalysisPeriod
) => {
  const periodsLabels = periodsIds.map((periodId: any) => {
    const periodData = dataGetAnalysisPeriod.periodos.find(
      (period: any) => period.value === periodId
    );
    const periodLabel = periodData.label;
    return periodLabel;
  });
  return periodsLabels;
};
