import { useState } from "react";

type useSnackbarType = () => [
  boolean,
  () => void,
  string,
  React.Dispatch<React.SetStateAction<string>>,
  "success" | "info" | "warning" | "error",
  React.Dispatch<React.SetStateAction<"success" | "info" | "warning" | "error">>,
  () => void
];

const useSnackbar: useSnackbarType = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] =
    useState<"success" | "info" | "warning" | "error">("success");

  const handleToggle: () => void = () => {    
    setOpen((prev: boolean) => !prev);
  };
  const showSnackbar: () => void = () => {
    setOpen(true);
  } 

  return [open, handleToggle, message, setMessage, severity, setSeverity, showSnackbar];
};

export default useSnackbar;
