const uploadFileInChunks: (
  axiosPrivate: any,
  file: any,
  UPLOAD_FILES_URL: any,
  handleUploadFileResponse: any,
  isRestartChecked?: any,
  onProgress?: any
) => Promise<void> = async (
  axiosPrivate,
  file,
  UPLOAD_FILES_URL,
  handleUploadFileResponse,
  isRestartChecked = false,
  onProgress
) => {
  const chunkSize = 1024 * 1024 * 10; // 10 MB
  const totalChunks = Math.ceil(file.size / chunkSize);
  let chunkIndex = 0;

  for (chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start: number = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();

    formData.append("file", chunk);
    formData.append("filename", file.name);
    formData.append("offset", start.toString());
    formData.append("total_size", file.size);
    formData.append("reiniciar_calculo", isRestartChecked.toString()); //Ver de parametrizar

    try {
      const response = await axiosPrivate.post(UPLOAD_FILES_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (chunkIndex === totalChunks - 1 && response.status === 200) {
        handleUploadFileResponse(response.status, response.data);
      } else if (response.status !== 200) {
        chunkIndex = totalChunks;
        handleUploadFileResponse(response.status, response.data);
      }
    } catch (error: any) { 
      chunkIndex = totalChunks;
      if (error && error?.response && error?.response?.status === 400) {       
        handleUploadFileResponse(error.response.status, error.response.data);
      }
      if (error.code === "ERR_NETWORK" || error?.response?.status === 502 || error?.response?.status === 500) {
        handleUploadFileResponse(error.response.status, error.response.data);
      } 
    }

    // Incrementar el progreso basado en el número de chunks subidos
    onProgress && onProgress(((chunkIndex + 1) / totalChunks) * 100);
  }
};

export default uploadFileInChunks;
