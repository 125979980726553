import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "../../dialog/Dialog";
import EditCriteriaButton from "../../buttons/EditCriteriaButton";
import { convertToText } from "../../criteriaManagement/utils";
import useLanguage from "../../../context/LanguageProvider";
import Box from "@mui/material/Box/Box";
import { info_section_container_styles } from "../../../styles/app-styles";
import useInfoContext from "../../../hooks/useInfoContext";

interface ViewInfoProps {
  grouper: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: string) => void;
}

const ViewInfoModal = ({
  grouper,
  open,
  handleClose,
  handleEdit,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();

  const handleEditAndClose = (e: any, id: number) => {
    handleEdit && handleEdit(e, id as unknown as string);
    handleClose();
  };

  return (
    <div>
      {grouper ? (
        <Dialog open={open} handleClose={handleClose} title={grouper?.nombre}>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="0 100px 30px 100px"
            position="relative"
          >
            <Stack sx={info_section_container_styles}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                {t(
                  "dataModelText.groupManagement.viewGrouperInfo.generalInfoGroup"
                )}
              </Typography>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("dataModelText.groupManagement.viewGrouperInfo.name")}{" "}
                </span>{" "}
                {grouper?.nombre}
              </p>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("dataModelText.groupManagement.viewGrouperInfo.base")}{" "}
                </span>{" "}
                {grouper?.bases[0]?.archivo?.label}
              </p>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("dataModelText.groupManagement.viewGrouperInfo.columns")}{" "}
                </span>{" "}
                {convertToText(grouper?.bases[0]?.columnas, t)}
              </p>
            </Stack>
            {grouper?.bases.slice(1).map((base: any) => {
              return (
                <Stack key={base.id} sx={info_section_container_styles}>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t("dataModelText.groupManagement.viewGrouperInfo.base")}{" "}
                    </span>{" "}
                    {base?.archivo?.label}
                  </p>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t(
                        "dataModelText.groupManagement.viewGrouperInfo.columns"
                      )}{" "}
                    </span>{" "}
                    {convertToText(base?.columnas, t)}
                  </p>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t(
                        "dataModelText.groupManagement.viewGrouperInfo.mergeColumnsPreviousBase"
                      )}{" "}
                    </span>{" "}
                    {convertToText(base?.merge.columnas_base_anterior, t)}
                  </p>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t(
                        "dataModelText.groupManagement.viewGrouperInfo.mergeColumnsCurrentBase"
                      )}{" "}
                    </span>{" "}
                    {convertToText(base?.merge.columnas_base_nueva, t)}
                  </p>
                  <p style={{ color: "var(--text-main)", margin: "5px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {t(
                        "dataModelText.groupManagement.viewGrouperInfo.removeDuplicates"
                      )}{" "}
                    </span>{" "}
                    {base?.merge.eliminar_duplicados}
                  </p>
                </Stack>
              );
            })}

            <Stack sx={info_section_container_styles}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                {t(
                  "dataModelText.groupManagement.viewGrouperInfo.finalColumns"
                )}
              </Typography>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("dataModelText.groupManagement.viewGrouperInfo.base")}{" "}
                </span>{" "}
                {grouper?.step2.archivo.label}
              </p>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("dataModelText.groupManagement.viewGrouperInfo.column")}{" "}
                </span>{" "}
                {grouper?.step2.columna.label}
              </p>
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t(
                    "dataModelText.groupManagement.viewGrouperInfo.usedColumns"
                  )}{" "}
                </span>{" "}
                {convertToText(grouper?.step2.columnas_a_usar, t)}
              </p>
            </Stack>
            {userRolData &&
              userRolData.administracion_de_medidas_agrupaciones_y_analisis
                .visualizacion_y_edicion && (
                <EditCriteriaButton
                  handleClick={handleEditAndClose}
                  selectedCriteria={grouper}
                />
              )}
          </Stack>
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ViewInfoModal;
