import { ActionMeta } from "react-select";
import { FILTER_FUNCTIONS } from "../../data";
import { FILTER_VALUES_URL } from "../../../../api/axios";
import { formatFilterRowsRow, validateFilterRowsRow } from "../../utils";
import { getValueLabelFromSingleOptions } from "../../../companyParameterization/utils";
import { row_delete_container_styles } from "../../../../styles/app-styles";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useEffect, useRef, useState } from "react";
import AddButton from "../../../buttons/AddButton";
import CreatableSelect from "react-select/creatable";
import DeleteForever from "../../../icons/DeleteForever";
import Grid from "@mui/material/Grid/Grid";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
interface NewColumnSelectsProps {
  titles: string[];
  totalColumnsOptions: any[];
  rows: any[];
  setRows: any;
  setMessage: any;
  dataGetTable: any;
}

const FilterRowsSelects = ({
  titles,
  totalColumnsOptions,
  rows,
  setMessage,
  setRows,
  dataGetTable,
}: NewColumnSelectsProps) => {
  const { t } = useLanguage();
  const column1SelectRef: any = useRef(null);
  const column2SelectRef: any = useRef(null);
  const columns3SelectRef: any = useRef(null);

  const onColumn1Change = () => {
    setColumns3SelectedOptions(undefined);
    columns3SelectRef?.current?.clearValue();
  };

  const [
    column1SelectedOption,
    setColumn1SelectedOption,
    column1OptionChangeHandler,
  ] = useSingleSelect(() => onColumn1Change());

  const [
    column2SelectedOption,
    setColumn2SelectedOption,
    column2OptionsChangeHandler,
  ] = useSingleSelect();

  const [columns3SelectedOptions, setColumns3SelectedOptions] = useState<any>();

  const cleanSelects = () => {
    setColumn1SelectedOption(undefined);
    setColumn2SelectedOption(undefined);
    setColumns3SelectedOptions(undefined);
    column1SelectRef?.current?.clearValue();
    column2SelectRef?.current?.clearValue();
    columns3SelectRef?.current?.clearValue();
  };
  const columns3OptionsChangeHandler = (
    value: any,
    action: ActionMeta<any>
  ) => {    
    switch (action.action) {
      case "select-option":
        column2SelectedOption?.value === ">" ||
        column2SelectedOption?.value === ">=" ||
        column2SelectedOption?.value === "<" ||
        column2SelectedOption?.value === "<="
          ? setColumns3SelectedOptions([value[0]])
          : setColumns3SelectedOptions(value);
        break;
      case "clear":
        setColumns3SelectedOptions(undefined);
        break;
      default:
        break;
    }
  };

  const handleCreate = (inputValue: any) => {
    columns3SelectRef.current.setValue([
      { value: inputValue, label: inputValue },
    ]);
    setColumns3SelectedOptions([{ value: inputValue, label: inputValue }]);
  };

  useEffect(() => {
    if (column1SelectedOption) {
      postFilterValues(undefined, {
        columna: column1SelectedOption?.value,
        filtros: rows?.map((row: any) => {
          return {
            columna: row?.columna?.value,
            operador: row?.operador?.value,
            valores: row?.valores?.map((v) => v.value),
          };
        }),
      });
    }
  }, [rows, column1SelectedOption]);

  const {
    isLoading: isLoadingFilterValues,
    callApi: postFilterValues,
    data: dataFilterValues,
  } = useApi(
    FILTER_VALUES_URL(dataGetTable.id), 
    "POST",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    undefined /* onSuccessPostFilterValues */,
    undefined,
    true
  );

  const handleAddRow = () => {
    if (
      validateFilterRowsRow(
        t,
        rows,
        setMessage,
        column1SelectedOption,
        column2SelectedOption,
        columns3SelectedOptions
      )
    ) {
      const newRow = formatFilterRowsRow(
        column1SelectedOption,
        column2SelectedOption,
        columns3SelectedOptions
      );
      const newRows = rows && rows.length > 0 ? [...rows] : [];
      newRows.push(newRow);
      setRows(newRows);
      setMessage({
        type: "",
        text: "",
      });
      cleanSelects();
    }
    return true;
  };

  const handleDeleteAllRows = () => {
    setRows([]);
    setMessage({
      type: "",
      text: "",
    });
    cleanSelects();
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Select
          reference={column1SelectRef}
          styles={selectStyles(column1SelectedOption)}
          options={totalColumnsOptions}
          onChange={column1OptionChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={column1SelectedOption}
        />
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Select
          reference={column2SelectRef}
          styles={selectStyles(column2SelectedOption)}
          options={FILTER_FUNCTIONS(t)}
          onChange={column2OptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.selectOption")}
          isClearable
          defaultValue={column2SelectedOption}
        />
      </Grid>
      <Grid item xs={11 / titles.length}>
        <CreatableSelect
          ref={columns3SelectRef}
          isClearable
          formatCreateLabel={(userInput) =>
            `${t("expensesSettings.modalContent.add")} ${userInput}`
          }
          styles={selectStyles(columns3SelectedOptions)}
          options={getValueLabelFromSingleOptions(dataFilterValues)}
          className="es_form_input"
          onChange={columns3OptionsChangeHandler}
          closeMenuOnSelect={false}
          isSearchable
          placeholder={t(
            "preprorationParameterization.newSchemaContent.chooseAddValue"
          )}
          onCreateOption={handleCreate}
          createOptionPosition="first"
          isMulti
        />
      </Grid>
      <Grid item xs={0.5}>
        <AddButton align={true} onClick={() => handleAddRow()} />
      </Grid>
    </>
  );
};

export default FilterRowsSelects;
