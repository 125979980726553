import { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import CustomNoRowsOverlay from "../../tablesTools/NoRows";
import CustomToolbar from "../../customToolbar/CustomToolbar";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getColumnsForSumTable, transformDataForSumTable } from "./transformData";
import { GET_PREPRORRATION_SUM_TABLE_URL } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useLanguage from "../../../context/LanguageProvider";
interface PreprorrationSumTableProps {
  tabValue: string;
}

const PreprorrationSumTable = ({tabValue}: PreprorrationSumTableProps) => {
  const { t } = useLanguage()
  //Reemplazar por la url correcta cuando este
  const {
    data: dataGetTable,
    isLoading: isLoadingGetTable,
    isError: isErrorGetTable,
    refetch: refetchGetTable,
    isFetching: isFetchingGetTable,
  } = useApiQuery(
    GET_PREPRORRATION_SUM_TABLE_URL,
    true,
    t("reportTexts.preprorrationSumTable.failedDownload")
  );

  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (dataGetTable) {
      const { rows, columns } = transformDataForSumTable(dataGetTable, t);
      setColumns(columns)
      setRows(rows);
    }else{
      setColumns(getColumnsForSumTable(t));
    }
  }, [dataGetTable]);

  return (
    <>
      {isLoadingGetTable ? (
        <CustomSkeleton height={6} />
      ) : (
        dataGetTable !== undefined && (
          <DataGridPro
            rows={rows ? rows : []}
            columns={columns}
            rowsPerPageOptions={[6, 15, 20, 50]}
            components={{
              Toolbar: () => (
                <CustomToolbar
                  toolbar={true}
                  filterButton={true}
                  densitySelector={true}
                  exportButton={true}
                  fileName={`${t("reportTexts.preprorrationSumTable.preProrrated")} - ${tabValue}`}
                />
              ),
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            hideFooter
            checkboxSelection={false}
            disableSelectionOnClick
            sx={{
              boxShadow: 3,
              fontSize: "12px",
              padding: "0 10px 10px 10px",
            }}
          />
        )
      )}
    </>
  );
};

export default PreprorrationSumTable;
