import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { MultiValue } from "react-select";
import { IdOption } from "../configCriteria/types";

interface ChipFiltersProps {
  filters: MultiValue<IdOption> |  { value: number; label: string }[] | undefined;
  handleDeleteFilter: (
    e: any,
    filter: { value: number; label: string }
  ) => void;
}

const ChipFilters = ({ filters, handleDeleteFilter }: ChipFiltersProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginTop: "15px", justifyContent: "flex-start", width: "100%" }}
    >
      {filters &&
        filters.map((filter) => {
          return (
            <Chip
              key={filter.value}
              label={filter.label}
              onDelete={(e) => handleDeleteFilter(e, filter)}
            />
          );
        })}
    </Stack>
  );
};

export default ChipFilters;
