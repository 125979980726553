import { TabValue } from "../colorTabs/types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AnalysisManagement from "./AnalysisManagement";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import FilesManagement from "./FilesManagement";
import useLanguage from "../../context/LanguageProvider";
import useInfoContext from "../../hooks/useInfoContext";

const DashboardManagement = () => {
  useEffect(() => {
    localStorage.removeItem("newElementConfig");
    localStorage.removeItem("previousUrl");
  });

  const location = useLocation();
  const { t, language } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [tabValueA, setTabValueA] = useState<TabValue>(
    t("indicatorsManagementTexts.indicatorsManagement.tabs.files")
  );
  useEffect(() => {
    setTabValueA(
      t("indicatorsManagementTexts.indicatorsManagement.tabs.files")
    );
  }, [language]);

  useEffect(() => {
    if (
      location.state &&
      (location.state.tab === "analisis/graficos" ||
        location.state.tab === "analisis/indicadores")
    ) {
      setTabValueA(
        t("indicatorsManagementTexts.indicatorsManagement.tabs.analysis")
      );
    } else {
      setTabValueA(
        t("indicatorsManagementTexts.indicatorsManagement.tabs.files")
      );
    }
  }, [location]);

  const createTabsA = (simulationRole: boolean) => {
    const baseTabs = [
      {
        value: t("indicatorsManagementTexts.indicatorsManagement.tabs.files"),
        label: t("indicatorsManagementTexts.indicatorsManagement.tabs.files"),
      },
      {
        value: t(
          "indicatorsManagementTexts.indicatorsManagement.tabs.analysis"
        ),
        label: t(
          "indicatorsManagementTexts.indicatorsManagement.tabs.analysis"
        ),
      },
    ];

    const tabs = simulationRole
      ? baseTabs.concat(
          {
            value: t(
              "indicatorsManagementTexts.indicatorsManagement.tabs.salesForecast"
            ),
            label: t(
              "indicatorsManagementTexts.indicatorsManagement.tabs.salesForecast"
            ),
          },
          {
            value: t(
              "indicatorsManagementTexts.indicatorsManagement.tabs.simulation"
            ),
            label: t(
              "indicatorsManagementTexts.indicatorsManagement.tabs.simulation"
            ),
          }
        )
      : baseTabs;

    return tabs;
  };

  const tabsA = createTabsA(
    userRolData?.simulador.visualizacion ||
      userRolData?.simulador.visualizacion_y_creacion ||
      userRolData?.simulador.visualizacion_y_edicion
  );

  const handleChangeA = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValueA(newValue);
  };

  const renderByTab = (tabValue: TabValue) => {
    switch (tabValue) {
      case t("indicatorsManagementTexts.indicatorsManagement.tabs.files"):
        return <FilesManagement />;
      case t("indicatorsManagementTexts.indicatorsManagement.tabs.analysis"):
        localStorage.setItem("tabValueEndpoint", "ANALISIS");
        return <AnalysisManagement tabValueEndpoint={"ANALISIS"} />;
      case t(
        "indicatorsManagementTexts.indicatorsManagement.tabs.salesForecast"
      ):
        localStorage.setItem("tabValueEndpoint", "SALES_FORECAST");
        return <AnalysisManagement tabValueEndpoint={"SALES_FORECAST"} />;
      case t("indicatorsManagementTexts.indicatorsManagement.tabs.simulation"):
        localStorage.setItem("tabValueEndpoint", "SIMULACION");
        return <AnalysisManagement tabValueEndpoint={"SIMULACION"} />;
      default:
        return <FilesManagement />;
    }
  };

  return (
    <ComponentLayout
      title={t("indicatorsManagementTexts.indicatorsManagement.indicatorAdmin")}
      icon={<AnalyticsIcon />}
    >
      <ColorTabs
        value={tabValueA}
        handleChange={handleChangeA}
        tabs={tabsA}
        marginBottom="10px"
      />
      {renderByTab(tabValueA)}
    </ComponentLayout>
  );
};

export default DashboardManagement;
