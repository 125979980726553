import { DashboardType } from "./types";

export const findDashboardById = (
  dashboards: DashboardType[] | undefined,
  id: string
) => {
  return (
    dashboards &&
    dashboards.find((dashboard: DashboardType) => dashboard.id === parseInt(id))
  );
};

export const transformDashboardData = (
  dashboardName: string,
  dashboardLink: string
) => {
  const dashboardData = {
    descripcion: dashboardName,
    source: dashboardLink,
  };
  return dashboardData;
};
