import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useMarketBasketProvider } from "../../../../context/MarketBasketProvider";
import TreemapCharts from "./TreemapCharts";
import BarrasCharts from "./BarrasCharts";

const ChartsList = () => {
  const { mbUpdatedResults, mbAnalysisResults, counter } =
    useMarketBasketProvider();
  const [treemapChartRender, setTreemapChartRender] = useState<any>(null);
  const [barrasChartRender, setBarrasChartRender] = useState<any>(null);

  const isBarrasData =
    mbAnalysisResults &&
    mbAnalysisResults.barras &&
    mbAnalysisResults.barras.length > 0;
    const isBarrasUpdatedData =
    mbUpdatedResults &&
    mbUpdatedResults.barras &&
    mbUpdatedResults.barras.length > 0; 
  const isTreemapData =
    mbAnalysisResults &&
    mbAnalysisResults.treemap &&
    mbAnalysisResults.treemap.length > 0;
  const isTreemapUpdatedData =
    mbUpdatedResults &&
    mbUpdatedResults.treemap &&
    mbUpdatedResults.treemap.length > 0;
       

  const graficoTreemap = (data) => {
    return <TreemapCharts chartsData={data} />;
  };

  const graficoBarras = (data) => {
    return <BarrasCharts chartsData={data} />;
  }

  useEffect(() => {   
    if (isTreemapUpdatedData) {      
      setTreemapChartRender(graficoTreemap(mbUpdatedResults));
    } else if (isTreemapData) {      
      setTreemapChartRender(graficoTreemap(mbAnalysisResults));
    }
    if(isBarrasUpdatedData){
      setBarrasChartRender(graficoBarras(mbUpdatedResults));
    } else if(isBarrasData){
      setBarrasChartRender(graficoBarras(mbAnalysisResults));
    }
  }, [mbAnalysisResults, mbUpdatedResults, counter]);

  return (
    <Box>
      {barrasChartRender}
      {treemapChartRender}
    </Box>
  );
};

export default ChartsList

