import {
    IconButton,
  } from "@mui/material";
  import {  useContext } from "react";
  import GroupersProvider from "../../../../context/GroupersProvider";
  import AddIcon from "@mui/icons-material/Add";
import useLanguage from "../../../../context/LanguageProvider";
  
  interface AddBaseButtonProps {
    handleAddBase: ()=> void;
  }
  
  const AddBaseButton = ({handleAddBase}: AddBaseButtonProps) => {
    const { t } = useLanguage();
    const { groupersState } =
      useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
    const { bases } = groupersState;

    const isDisabled = () => {
     return   bases.length < 1 || bases[0].columnas.length === 0
    }
  
    return (
        <IconButton
          onClick={handleAddBase}
          disabled={
           isDisabled()
          }
          sx={{
            borderRadius: "30px",
            border:
            isDisabled()
                ? "var(--bg-info)"
                : "1px solid var(--bg-main)",
            color: "var(--cream) !important",
            backgroundColor:
            isDisabled()
                ? "var(--bg-disabled) !important"
                : "var(--bg-main)",
            display: "flex",
            fontSize: "14px",
            cursor:
            isDisabled()
                ? "not-allowed !important"
                : "normal",
            fontWeight: "100",
            textTransform: "uppercase",
            height: "45px",
            letterSpacing: "2px",
            margin: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            texTransform: "uppercase",
            transition: "all 0.4s",
            width: "160px",
            padding: "15px",
            "&:hover": {
              border: "1px solid var(--bg-main)",
              color: "var(--text-main)",
              backgroundColor: "white",
              cursor:
              isDisabled()
                  ? "not-allowed !important"
                  : "pointer",
              fontWeight: "bold",
            },
            "&:active": {
              transform: "scale(0.95)",
              transition: "0.3s all",
            },
          }}
        >
          {t('dataModelText.groupManagement.step1.addBaseButton.newBase')}
          <AddIcon />
        </IconButton>
    );
  };
  
  export default AddBaseButton;
  