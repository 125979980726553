import { Grid, Stack } from "@mui/material";
import TableDataManagement from "../../dataManagement/table";

const TablePreview = ({ columnsPreview }) => {
  const rows = [
    {
      id: 1,
    },
  ];

  const notColumnPreview = !columnsPreview || columnsPreview?.length < 1;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "110%",
        pt: 2,
      }}
    >
      {notColumnPreview ? (
        <></>
      ) : (
        <Stack alignItems="center" width="100%" gap={2}>
          <TableDataManagement
            columns={columnsPreview}
            rows={rows}
            rowHeight={0}
            tableHeight={100}
            hideFooter={true}
            columnsButton={false}
            filterButton={false}
            densitySelector={false}
            exportButton={false}
          />
        </Stack>
      )}
    </Grid>
  );
};

export default TablePreview;
