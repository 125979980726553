import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { Button, Stack } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
/**
 * @name transformConfigurationsData
 * @description Función que transforma los datos de la tabla.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformConfigurationsData = (
    t: any,
    data: any,
    buttonsActions: any | undefined
) => {

    const columnsNames = ["TIPO", "CANTIDAD_RUTAS", "ACCIÓN"];

    const arrayColumns = columnsNames?.map((columna) => ({
            field: columna,
            headerClassName: "es_header_column",
            headerName: columna,
            description: columna,
            width: columna === "CANTIDAD_RUTAS" ? 300 : columna === "ACCIÓN" ? 610 : 450,
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong
                    style={{
                        color: "white",
                        textAlign: "center",
                        textTransform: "uppercase",
                    }}
                >
                    {params.field}
                </strong>
            ),
            renderCell: (params: any) => {
                if (columna === t("columns.action").toUpperCase()) {
                    return (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Button
                                startIcon={<ZoomInIcon />}
                                variant="text"
                                sx={{ ":hover": { color: "var(--text-success)" } }}
                                onClick={() =>
                                    buttonsActions.handleInfoConfig(params.row.id)
                                }
                            >
                                {t("taskManager.actions.viewDetail")}
                            </Button>
                            <Button
                                startIcon={<EditIcon />}
                                variant="text"
                                sx={{ ":hover": { color: "var(--text-success)" } }}
                                onClick={() =>
                                    buttonsActions.handleEditConfig(params.row.id)
                                }
                            >                                    
                                {t("taskManager.actions.edit")}
                            </Button>
                            <Button
                                startIcon={<DeleteIcon />}
                                variant="text"
                                sx={{ ":hover": { color: "var(--text-success)" } }}
                                onClick={() =>
                                    buttonsActions.handleDeleteConfig(params.row.id)
                                }
                            >                                    
                                {t("taskManager.actions.delete")}
                            </Button>
                        </Stack>
                    );
                } else {
                    return (
                        <Stack direction="row" alignItems="center">
                            {
                                columna === "TIPO" ?
                                    params.row?.tipo === "baja"
                                        ? "BAJA"
                                        : params.row?.tipo === "alta" ?
                                            "ALTA" :
                                            "MODIFICACIÓN"
                                    :
                                    columna === "CANTIDAD_RUTAS" ?
                                        params.row && params.row?.tipo !== "alta" ?
                                            params.row.rutas.length :
                                            params.row.cantidad : ""
                            }
                        </Stack>
                    )
                }
            }
    }));

    const arrayRows =
        data && data.length > 0
            ? data?.map((fila: any) => {
                const obj: any = {};
                obj["id"] = fila.id;
                for (const key in fila) {
                    if (key !== "id") {
                        obj[key] = fila[key];
                    }
                }
                return obj;
            })
            : [];

    const columns: GridColDef[] = arrayColumns.map((col) => ({
        ...col,
        headerAlign: "center",
        align: "center",
    }));

    const rows = arrayRows?.map((row: any) => ({
        ...row,
    }));

    return { columns, rows };
};
